import { b as buildFormatLongFn, a as buildLocalizeFn, c as buildMatchFn, d as buildMatchPatternFn, i as isSameWeek, t as toDate, f as formatDistance$2E, e as formatRelative$2E, l as localize$1k, m as match$1j, g as locale, h as enUS, j as dateFns } from "./index-6d31ecbf.js";
import { E as isPositiveNumber, F as isNumber, v as isDefined, m as isElementReady } from "./element-d66cb8c2.js";
import { g as getElement } from "./index-bc9b1baf.js";
import "./MutationObserverSafe-8cdfc100.js";
var af = {};
var formatDistance$2D = {};
formatDistance$2D.formatDistance = void 0;
var formatDistanceLocale$1i = {
  lessThanXSeconds: {
    one: "minder as 'n sekonde",
    other: "minder as {{count}} sekondes"
  },
  xSeconds: {
    one: "1 sekonde",
    other: "{{count}} sekondes"
  },
  halfAMinute: "'n halwe minuut",
  lessThanXMinutes: {
    one: "minder as 'n minuut",
    other: "minder as {{count}} minute"
  },
  xMinutes: {
    one: "'n minuut",
    other: "{{count}} minute"
  },
  aboutXHours: {
    one: "ongeveer 1 uur",
    other: "ongeveer {{count}} ure"
  },
  xHours: {
    one: "1 uur",
    other: "{{count}} ure"
  },
  xDays: {
    one: "1 dag",
    other: "{{count}} dae"
  },
  aboutXWeeks: {
    one: "ongeveer 1 week",
    other: "ongeveer {{count}} weke"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weke"
  },
  aboutXMonths: {
    one: "ongeveer 1 maand",
    other: "ongeveer {{count}} maande"
  },
  xMonths: {
    one: "1 maand",
    other: "{{count}} maande"
  },
  aboutXYears: {
    one: "ongeveer 1 jaar",
    other: "ongeveer {{count}} jaar"
  },
  xYears: {
    one: "1 jaar",
    other: "{{count}} jaar"
  },
  overXYears: {
    one: "meer as 1 jaar",
    other: "meer as {{count}} jaar"
  },
  almostXYears: {
    one: "byna 1 jaar",
    other: "byna {{count}} jaar"
  }
};
var formatDistance$2C = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$1i[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "oor " + r;
    } else {
      return r + " gelede";
    }
  }
  return r;
};
formatDistance$2D.formatDistance = formatDistance$2C;
var formatLong$1r = {};
formatLong$1r.formatLong = void 0;
var _index$5K = buildFormatLongFn;
var dateFormats$1r = {
  full: "EEEE, d MMMM yyyy",
  long: "d MMMM yyyy",
  medium: "d MMM yyyy",
  short: "yyyy/MM/dd"
};
var timeFormats$1r = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$1r = {
  full: "{{date}} 'om' {{time}}",
  long: "{{date}} 'om' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1r.formatLong = {
  date: (0, _index$5K.buildFormatLongFn)({
    formats: dateFormats$1r,
    defaultWidth: "full"
  }),
  time: (0, _index$5K.buildFormatLongFn)({
    formats: timeFormats$1r,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$5K.buildFormatLongFn)({
    formats: dateTimeFormats$1r,
    defaultWidth: "full"
  })
};
var formatRelative$2D = {};
formatRelative$2D.formatRelative = void 0;
var formatRelativeLocale$1j = {
  lastWeek: "'verlede' eeee 'om' p",
  yesterday: "'gister om' p",
  today: "'vandag om' p",
  tomorrow: "'môre om' p",
  nextWeek: "eeee 'om' p",
  other: "P"
};
var formatRelative$2C = function (e, a, t, r) {
  return formatRelativeLocale$1j[e];
};
formatRelative$2D.formatRelative = formatRelative$2C;
var localize$1j = {};
localize$1j.localize = void 0;
var _index$5J = buildLocalizeFn;
var eraValues$1j = {
  narrow: ["vC", "nC"],
  abbreviated: ["vC", "nC"],
  wide: ["voor Christus", "na Christus"]
};
var quarterValues$1j = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["K1", "K2", "K3", "K4"],
  wide: ["1ste kwartaal", "2de kwartaal", "3de kwartaal", "4de kwartaal"]
};
var monthValues$1j = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["Jan", "Feb", "Mrt", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Des"],
  wide: ["Januarie", "Februarie", "Maart", "April", "Mei", "Junie", "Julie", "Augustus", "September", "Oktober", "November", "Desember"]
};
var dayValues$1j = {
  narrow: ["S", "M", "D", "W", "D", "V", "S"],
  short: ["So", "Ma", "Di", "Wo", "Do", "Vr", "Sa"],
  abbreviated: ["Son", "Maa", "Din", "Woe", "Don", "Vry", "Sat"],
  wide: ["Sondag", "Maandag", "Dinsdag", "Woensdag", "Donderdag", "Vrydag", "Saterdag"]
};
var dayPeriodValues$1j = {
  narrow: {
    am: "vm",
    pm: "nm",
    midnight: "middernag",
    noon: "middaguur",
    morning: "oggend",
    afternoon: "middag",
    evening: "laat middag",
    night: "aand"
  },
  abbreviated: {
    am: "vm",
    pm: "nm",
    midnight: "middernag",
    noon: "middaguur",
    morning: "oggend",
    afternoon: "middag",
    evening: "laat middag",
    night: "aand"
  },
  wide: {
    am: "vm",
    pm: "nm",
    midnight: "middernag",
    noon: "middaguur",
    morning: "oggend",
    afternoon: "middag",
    evening: "laat middag",
    night: "aand"
  }
};
var formattingDayPeriodValues$13 = {
  narrow: {
    am: "vm",
    pm: "nm",
    midnight: "middernag",
    noon: "uur die middag",
    morning: "uur die oggend",
    afternoon: "uur die middag",
    evening: "uur die aand",
    night: "uur die aand"
  },
  abbreviated: {
    am: "vm",
    pm: "nm",
    midnight: "middernag",
    noon: "uur die middag",
    morning: "uur die oggend",
    afternoon: "uur die middag",
    evening: "uur die aand",
    night: "uur die aand"
  },
  wide: {
    am: "vm",
    pm: "nm",
    midnight: "middernag",
    noon: "uur die middag",
    morning: "uur die oggend",
    afternoon: "uur die middag",
    evening: "uur die aand",
    night: "uur die aand"
  }
};
var ordinalNumber$1j = function (e) {
  var a = Number(e);
  var t = a % 100;
  if (t < 20) {
    switch (t) {
      case 1:
      case 8:
        return a + "ste";
      default:
        return a + "de";
    }
  }
  return a + "ste";
};
localize$1j.localize = {
  ordinalNumber: ordinalNumber$1j,
  era: (0, _index$5J.buildLocalizeFn)({
    values: eraValues$1j,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$5J.buildLocalizeFn)({
    values: quarterValues$1j,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$5J.buildLocalizeFn)({
    values: monthValues$1j,
    defaultWidth: "wide"
  }),
  day: (0, _index$5J.buildLocalizeFn)({
    values: dayValues$1j,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$5J.buildLocalizeFn)({
    values: dayPeriodValues$1j,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$13,
    defaultFormattingWidth: "wide"
  })
};
var match$1i = {};
match$1i.match = void 0;
var _index$5I = buildMatchFn;
var _index2$2Q = buildMatchPatternFn;
var matchOrdinalNumberPattern$1i = /^(\d+)(ste|de)?/i;
var parseOrdinalNumberPattern$1i = /\d+/i;
var matchEraPatterns$1i = {
  narrow: /^([vn]\.? ?C\.?)/,
  abbreviated: /^([vn]\. ?C\.?)/,
  wide: /^((voor|na) Christus)/
};
var parseEraPatterns$1i = {
  any: [/^v/, /^n/]
};
var matchQuarterPatterns$1i = {
  narrow: /^[1234]/i,
  abbreviated: /^K[1234]/i,
  wide: /^[1234](st|d)e kwartaal/i
};
var parseQuarterPatterns$1i = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$1i = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(Jan|Feb|Mrt|Apr|Mei|Jun|Jul|Aug|Sep|Okt|Nov|Dec)\.?/i,
  wide: /^(Januarie|Februarie|Maart|April|Mei|Junie|Julie|Augustus|September|Oktober|November|Desember)/i
};
var parseMonthPatterns$1i = {
  narrow: [/^J/i, /^F/i, /^M/i, /^A/i, /^M/i, /^J/i, /^J/i, /^A/i, /^S/i, /^O/i, /^N/i, /^D/i],
  any: [/^Jan/i, /^Feb/i, /^Mrt/i, /^Apr/i, /^Mei/i, /^Jun/i, /^Jul/i, /^Aug/i, /^Sep/i, /^Okt/i, /^Nov/i, /^Dec/i]
};
var matchDayPatterns$1i = {
  narrow: /^[smdwv]/i,
  short: /^(So|Ma|Di|Wo|Do|Vr|Sa)/i,
  abbreviated: /^(Son|Maa|Din|Woe|Don|Vry|Sat)/i,
  wide: /^(Sondag|Maandag|Dinsdag|Woensdag|Donderdag|Vrydag|Saterdag)/i
};
var parseDayPatterns$1i = {
  narrow: [/^S/i, /^M/i, /^D/i, /^W/i, /^D/i, /^V/i, /^S/i],
  any: [/^So/i, /^Ma/i, /^Di/i, /^Wo/i, /^Do/i, /^Vr/i, /^Sa/i]
};
var matchDayPeriodPatterns$1i = {
  any: /^(vm|nm|middernag|(?:uur )?die (oggend|middag|aand))/i
};
var parseDayPeriodPatterns$1i = {
  any: {
    am: /^vm/i,
    pm: /^nm/i,
    midnight: /^middernag/i,
    noon: /^middaguur/i,
    morning: /oggend/i,
    afternoon: /middag/i,
    evening: /laat middag/i,
    night: /aand/i
  }
};
match$1i.match = {
  ordinalNumber: (0, _index2$2Q.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$1i,
    parsePattern: parseOrdinalNumberPattern$1i,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$5I.buildMatchFn)({
    matchPatterns: matchEraPatterns$1i,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$1i,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$5I.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$1i,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$1i,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$5I.buildMatchFn)({
    matchPatterns: matchMonthPatterns$1i,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$1i,
    defaultParseWidth: "any"
  }),
  day: (0, _index$5I.buildMatchFn)({
    matchPatterns: matchDayPatterns$1i,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$1i,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$5I.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$1i,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$1i,
    defaultParseWidth: "any"
  })
};
af.af = void 0;
var _index$5H = formatDistance$2D;
var _index2$2P = formatLong$1r;
var _index3$1u = formatRelative$2D;
var _index4$1t = localize$1j;
var _index5$1t = match$1i;
af.af = {
  code: "af",
  formatDistance: _index$5H.formatDistance,
  formatLong: _index2$2P.formatLong,
  formatRelative: _index3$1u.formatRelative,
  localize: _index4$1t.localize,
  match: _index5$1t.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var ar = {};
var formatDistance$2B = {};
formatDistance$2B.formatDistance = void 0;
var formatDistanceLocale$1h = {
  lessThanXSeconds: {
    one: "أقل من ثانية",
    two: "أقل من ثانيتين",
    threeToTen: "أقل من {{count}} ثواني",
    other: "أقل من {{count}} ثانية"
  },
  xSeconds: {
    one: "ثانية واحدة",
    two: "ثانيتان",
    threeToTen: "{{count}} ثواني",
    other: "{{count}} ثانية"
  },
  halfAMinute: "نصف دقيقة",
  lessThanXMinutes: {
    one: "أقل من دقيقة",
    two: "أقل من دقيقتين",
    threeToTen: "أقل من {{count}} دقائق",
    other: "أقل من {{count}} دقيقة"
  },
  xMinutes: {
    one: "دقيقة واحدة",
    two: "دقيقتان",
    threeToTen: "{{count}} دقائق",
    other: "{{count}} دقيقة"
  },
  aboutXHours: {
    one: "ساعة واحدة تقريباً",
    two: "ساعتين تقريبا",
    threeToTen: "{{count}} ساعات تقريباً",
    other: "{{count}} ساعة تقريباً"
  },
  xHours: {
    one: "ساعة واحدة",
    two: "ساعتان",
    threeToTen: "{{count}} ساعات",
    other: "{{count}} ساعة"
  },
  xDays: {
    one: "يوم واحد",
    two: "يومان",
    threeToTen: "{{count}} أيام",
    other: "{{count}} يوم"
  },
  aboutXWeeks: {
    one: "أسبوع واحد تقريبا",
    two: "أسبوعين تقريبا",
    threeToTen: "{{count}} أسابيع تقريبا",
    other: "{{count}} أسبوعا تقريبا"
  },
  xWeeks: {
    one: "أسبوع واحد",
    two: "أسبوعان",
    threeToTen: "{{count}} أسابيع",
    other: "{{count}} أسبوعا"
  },
  aboutXMonths: {
    one: "شهر واحد تقريباً",
    two: "شهرين تقريبا",
    threeToTen: "{{count}} أشهر تقريبا",
    other: "{{count}} شهرا تقريباً"
  },
  xMonths: {
    one: "شهر واحد",
    two: "شهران",
    threeToTen: "{{count}} أشهر",
    other: "{{count}} شهرا"
  },
  aboutXYears: {
    one: "سنة واحدة تقريباً",
    two: "سنتين تقريبا",
    threeToTen: "{{count}} سنوات تقريباً",
    other: "{{count}} سنة تقريباً"
  },
  xYears: {
    one: "سنة واحد",
    two: "سنتان",
    threeToTen: "{{count}} سنوات",
    other: "{{count}} سنة"
  },
  overXYears: {
    one: "أكثر من سنة",
    two: "أكثر من سنتين",
    threeToTen: "أكثر من {{count}} سنوات",
    other: "أكثر من {{count}} سنة"
  },
  almostXYears: {
    one: "ما يقارب سنة واحدة",
    two: "ما يقارب سنتين",
    threeToTen: "ما يقارب {{count}} سنوات",
    other: "ما يقارب {{count}} سنة"
  }
};
var formatDistance$2A = function (e, a, t) {
  var r = formatDistanceLocale$1h[e];
  var n;
  if (typeof r === "string") {
    n = r;
  } else if (a === 1) {
    n = r.one;
  } else if (a === 2) {
    n = r.two;
  } else if (a <= 10) {
    n = r.threeToTen.replace("{{count}}", String(a));
  } else {
    n = r.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "خلال " + n;
    } else {
      return "منذ " + n;
    }
  }
  return n;
};
formatDistance$2B.formatDistance = formatDistance$2A;
var formatLong$1q = {};
formatLong$1q.formatLong = void 0;
var _index$5G = buildFormatLongFn;
var dateFormats$1q = {
  full: "EEEE، do MMMM y",
  long: "do MMMM y",
  medium: "d MMM y",
  short: "dd/MM/yyyy"
};
var timeFormats$1q = {
  full: "HH:mm:ss",
  long: "HH:mm:ss",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$1q = {
  full: "{{date}} 'عند الساعة' {{time}}",
  long: "{{date}} 'عند الساعة' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1q.formatLong = {
  date: (0, _index$5G.buildFormatLongFn)({
    formats: dateFormats$1q,
    defaultWidth: "full"
  }),
  time: (0, _index$5G.buildFormatLongFn)({
    formats: timeFormats$1q,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$5G.buildFormatLongFn)({
    formats: dateTimeFormats$1q,
    defaultWidth: "full"
  })
};
var formatRelative$2B = {};
formatRelative$2B.formatRelative = void 0;
var formatRelativeLocale$1i = {
  lastWeek: "eeee 'الماضي عند الساعة' p",
  yesterday: "'الأمس عند الساعة' p",
  today: "'اليوم عند الساعة' p",
  tomorrow: "'غدا عند الساعة' p",
  nextWeek: "eeee 'القادم عند الساعة' p",
  other: "P"
};
var formatRelative$2A = function (e) {
  return formatRelativeLocale$1i[e];
};
formatRelative$2B.formatRelative = formatRelative$2A;
var localize$1i = {};
localize$1i.localize = void 0;
var _index$5F = buildLocalizeFn;
var eraValues$1i = {
  narrow: ["ق", "ب"],
  abbreviated: ["ق.م.", "ب.م."],
  wide: ["قبل الميلاد", "بعد الميلاد"]
};
var quarterValues$1i = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["ر1", "ر2", "ر3", "ر4"],
  wide: ["الربع الأول", "الربع الثاني", "الربع الثالث", "الربع الرابع"]
};
var monthValues$1i = {
  narrow: ["ي", "ف", "م", "أ", "م", "ي", "ي", "أ", "س", "أ", "ن", "د"],
  abbreviated: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"],
  wide: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"]
};
var dayValues$1i = {
  narrow: ["ح", "ن", "ث", "ر", "خ", "ج", "س"],
  short: ["أحد", "اثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
  abbreviated: ["أحد", "اثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
  wide: ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"]
};
var dayPeriodValues$1i = {
  narrow: {
    am: "ص",
    pm: "م",
    morning: "الصباح",
    noon: "الظهر",
    afternoon: "بعد الظهر",
    evening: "المساء",
    night: "الليل",
    midnight: "منتصف الليل"
  },
  abbreviated: {
    am: "ص",
    pm: "م",
    morning: "الصباح",
    noon: "الظهر",
    afternoon: "بعد الظهر",
    evening: "المساء",
    night: "الليل",
    midnight: "منتصف الليل"
  },
  wide: {
    am: "ص",
    pm: "م",
    morning: "الصباح",
    noon: "الظهر",
    afternoon: "بعد الظهر",
    evening: "المساء",
    night: "الليل",
    midnight: "منتصف الليل"
  }
};
var formattingDayPeriodValues$12 = {
  narrow: {
    am: "ص",
    pm: "م",
    morning: "في الصباح",
    noon: "الظهر",
    afternoon: "بعد الظهر",
    evening: "في المساء",
    night: "في الليل",
    midnight: "منتصف الليل"
  },
  abbreviated: {
    am: "ص",
    pm: "م",
    morning: "في الصباح",
    noon: "الظهر",
    afternoon: "بعد الظهر",
    evening: "في المساء",
    night: "في الليل",
    midnight: "منتصف الليل"
  },
  wide: {
    am: "ص",
    pm: "م",
    morning: "في الصباح",
    noon: "الظهر",
    afternoon: "بعد الظهر",
    evening: "في المساء",
    night: "في الليل",
    midnight: "منتصف الليل"
  }
};
var ordinalNumber$1i = function (e) {
  return String(e);
};
localize$1i.localize = {
  ordinalNumber: ordinalNumber$1i,
  era: (0, _index$5F.buildLocalizeFn)({
    values: eraValues$1i,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$5F.buildLocalizeFn)({
    values: quarterValues$1i,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$5F.buildLocalizeFn)({
    values: monthValues$1i,
    defaultWidth: "wide"
  }),
  day: (0, _index$5F.buildLocalizeFn)({
    values: dayValues$1i,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$5F.buildLocalizeFn)({
    values: dayPeriodValues$1i,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$12,
    defaultFormattingWidth: "wide"
  })
};
var match$1h = {};
match$1h.match = void 0;
var _index$5E = buildMatchPatternFn;
var _index2$2O = buildMatchFn;
var matchOrdinalNumberPattern$1h = /^(\d+)(th|st|nd|rd)?/i;
var parseOrdinalNumberPattern$1h = /\d+/i;
var matchEraPatterns$1h = {
  narrow: /[قب]/,
  abbreviated: /[قب]\.م\./,
  wide: /(قبل|بعد) الميلاد/
};
var parseEraPatterns$1h = {
  any: [/قبل/, /بعد/]
};
var matchQuarterPatterns$1h = {
  narrow: /^[1234]/i,
  abbreviated: /ر[1234]/,
  wide: /الربع (الأول|الثاني|الثالث|الرابع)/
};
var parseQuarterPatterns$1h = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$1h = {
  narrow: /^[أيفمسند]/,
  abbreviated: /^(يناير|فبراير|مارس|أبريل|مايو|يونيو|يوليو|أغسطس|سبتمبر|أكتوبر|نوفمبر|ديسمبر)/,
  wide: /^(يناير|فبراير|مارس|أبريل|مايو|يونيو|يوليو|أغسطس|سبتمبر|أكتوبر|نوفمبر|ديسمبر)/
};
var parseMonthPatterns$1h = {
  narrow: [/^ي/i, /^ف/i, /^م/i, /^أ/i, /^م/i, /^ي/i, /^ي/i, /^أ/i, /^س/i, /^أ/i, /^ن/i, /^د/i],
  any: [/^يناير/i, /^فبراير/i, /^مارس/i, /^أبريل/i, /^مايو/i, /^يونيو/i, /^يوليو/i, /^أغسطس/i, /^سبتمبر/i, /^أكتوبر/i, /^نوفمبر/i, /^ديسمبر/i]
};
var matchDayPatterns$1h = {
  narrow: /^[حنثرخجس]/i,
  short: /^(أحد|اثنين|ثلاثاء|أربعاء|خميس|جمعة|سبت)/i,
  abbreviated: /^(أحد|اثنين|ثلاثاء|أربعاء|خميس|جمعة|سبت)/i,
  wide: /^(الأحد|الاثنين|الثلاثاء|الأربعاء|الخميس|الجمعة|السبت)/i
};
var parseDayPatterns$1h = {
  narrow: [/^ح/i, /^ن/i, /^ث/i, /^ر/i, /^خ/i, /^ج/i, /^س/i],
  wide: [/^الأحد/i, /^الاثنين/i, /^الثلاثاء/i, /^الأربعاء/i, /^الخميس/i, /^الجمعة/i, /^السبت/i],
  any: [/^أح/i, /^اث/i, /^ث/i, /^أر/i, /^خ/i, /^ج/i, /^س/i]
};
var matchDayPeriodPatterns$1h = {
  narrow: /^(ص|م|منتصف الليل|الظهر|بعد الظهر|في الصباح|في المساء|في الليل)/,
  any: /^(ص|م|منتصف الليل|الظهر|بعد الظهر|في الصباح|في المساء|في الليل)/
};
var parseDayPeriodPatterns$1h = {
  any: {
    am: /^ص/,
    pm: /^م/,
    midnight: /منتصف الليل/,
    noon: /الظهر/,
    afternoon: /بعد الظهر/,
    morning: /في الصباح/,
    evening: /في المساء/,
    night: /في الليل/
  }
};
match$1h.match = {
  ordinalNumber: (0, _index$5E.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$1h,
    parsePattern: parseOrdinalNumberPattern$1h,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index2$2O.buildMatchFn)({
    matchPatterns: matchEraPatterns$1h,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$1h,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index2$2O.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$1h,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$1h,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index2$2O.buildMatchFn)({
    matchPatterns: matchMonthPatterns$1h,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$1h,
    defaultParseWidth: "any"
  }),
  day: (0, _index2$2O.buildMatchFn)({
    matchPatterns: matchDayPatterns$1h,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$1h,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index2$2O.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$1h,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$1h,
    defaultParseWidth: "any"
  })
};
ar.ar = void 0;
var _index$5D = formatDistance$2B;
var _index2$2N = formatLong$1q;
var _index3$1t = formatRelative$2B;
var _index4$1s = localize$1i;
var _index5$1s = match$1h;
ar.ar = {
  code: "ar",
  formatDistance: _index$5D.formatDistance,
  formatLong: _index2$2N.formatLong,
  formatRelative: _index3$1t.formatRelative,
  localize: _index4$1s.localize,
  match: _index5$1s.match,
  options: {
    weekStartsOn: 6,
    firstWeekContainsDate: 1
  }
};
var arDZ = {};
var formatDistance$2z = {};
formatDistance$2z.formatDistance = void 0;
var formatDistanceLocale$1g = {
  lessThanXSeconds: {
    one: "أقل من ثانية واحدة",
    two: "أقل من ثانتين",
    threeToTen: "أقل من {{count}} ثواني",
    other: "أقل من {{count}} ثانية"
  },
  xSeconds: {
    one: "ثانية واحدة",
    two: "ثانتين",
    threeToTen: "{{count}} ثواني",
    other: "{{count}} ثانية"
  },
  halfAMinute: "نصف دقيقة",
  lessThanXMinutes: {
    one: "أقل من دقيقة",
    two: "أقل من دقيقتين",
    threeToTen: "أقل من {{count}} دقائق",
    other: "أقل من {{count}} دقيقة"
  },
  xMinutes: {
    one: "دقيقة واحدة",
    two: "دقيقتين",
    threeToTen: "{{count}} دقائق",
    other: "{{count}} دقيقة"
  },
  aboutXHours: {
    one: "ساعة واحدة تقريباً",
    two: "ساعتين تقريباً",
    threeToTen: "{{count}} ساعات تقريباً",
    other: "{{count}} ساعة تقريباً"
  },
  xHours: {
    one: "ساعة واحدة",
    two: "ساعتين",
    threeToTen: "{{count}} ساعات",
    other: "{{count}} ساعة"
  },
  xDays: {
    one: "يوم واحد",
    two: "يومين",
    threeToTen: "{{count}} أيام",
    other: "{{count}} يوم"
  },
  aboutXWeeks: {
    one: "أسبوع واحد تقريباً",
    two: "أسبوعين تقريباً",
    threeToTen: "{{count}} أسابيع تقريباً",
    other: "{{count}} أسبوع تقريباً"
  },
  xWeeks: {
    one: "أسبوع واحد",
    two: "أسبوعين",
    threeToTen: "{{count}} أسابيع",
    other: "{{count}} أسبوع"
  },
  aboutXMonths: {
    one: "شهر واحد تقريباً",
    two: "شهرين تقريباً",
    threeToTen: "{{count}} أشهر تقريباً",
    other: "{{count}} شهر تقريباً"
  },
  xMonths: {
    one: "شهر واحد",
    two: "شهرين",
    threeToTen: "{{count}} أشهر",
    other: "{{count}} شهر"
  },
  aboutXYears: {
    one: "عام واحد تقريباً",
    two: "عامين تقريباً",
    threeToTen: "{{count}} أعوام تقريباً",
    other: "{{count}} عام تقريباً"
  },
  xYears: {
    one: "عام واحد",
    two: "عامين",
    threeToTen: "{{count}} أعوام",
    other: "{{count}} عام"
  },
  overXYears: {
    one: "أكثر من عام",
    two: "أكثر من عامين",
    threeToTen: "أكثر من {{count}} أعوام",
    other: "أكثر من {{count}} عام"
  },
  almostXYears: {
    one: "عام واحد تقريباً",
    two: "عامين تقريباً",
    threeToTen: "{{count}} أعوام تقريباً",
    other: "{{count}} عام تقريباً"
  }
};
var formatDistance$2y = function (e, a, t) {
  t = t || {};
  var r = formatDistanceLocale$1g[e];
  var n;
  if (typeof r === "string") {
    n = r;
  } else if (a === 1) {
    n = r.one;
  } else if (a === 2) {
    n = r.two;
  } else if (a <= 10) {
    n = r.threeToTen.replace("{{count}}", String(a));
  } else {
    n = r.other.replace("{{count}}", String(a));
  }
  if (t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "في خلال " + n;
    } else {
      return "منذ " + n;
    }
  }
  return n;
};
formatDistance$2z.formatDistance = formatDistance$2y;
var formatLong$1p = {};
formatLong$1p.formatLong = void 0;
var _index$5C = buildFormatLongFn;
var dateFormats$1p = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
};
var timeFormats$1p = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$1p = {
  full: "{{date}} 'عند' {{time}}",
  long: "{{date}} 'عند' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1p.formatLong = {
  date: (0, _index$5C.buildFormatLongFn)({
    formats: dateFormats$1p,
    defaultWidth: "full"
  }),
  time: (0, _index$5C.buildFormatLongFn)({
    formats: timeFormats$1p,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$5C.buildFormatLongFn)({
    formats: dateTimeFormats$1p,
    defaultWidth: "full"
  })
};
var formatRelative$2z = {};
formatRelative$2z.formatRelative = void 0;
var formatRelativeLocale$1h = {
  lastWeek: "'أخر' eeee 'عند' p",
  yesterday: "'أمس عند' p",
  today: "'اليوم عند' p",
  tomorrow: "'غداً عند' p",
  nextWeek: "eeee 'عند' p",
  other: "P"
};
var formatRelative$2y = function (e, a, t, r) {
  return formatRelativeLocale$1h[e];
};
formatRelative$2z.formatRelative = formatRelative$2y;
var localize$1h = {};
localize$1h.localize = void 0;
var _index$5B = buildLocalizeFn;
var eraValues$1h = {
  narrow: ["ق", "ب"],
  abbreviated: ["ق.م.", "ب.م."],
  wide: ["قبل الميلاد", "بعد الميلاد"]
};
var quarterValues$1h = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["ر1", "ر2", "ر3", "ر4"],
  wide: ["الربع الأول", "الربع الثاني", "الربع الثالث", "الربع الرابع"]
};
var monthValues$1h = {
  narrow: ["ج", "ف", "م", "أ", "م", "ج", "ج", "أ", "س", "أ", "ن", "د"],
  abbreviated: ["جانـ", "فيفـ", "مارس", "أفريل", "مايـ", "جوانـ", "جويـ", "أوت", "سبتـ", "أكتـ", "نوفـ", "ديسـ"],
  wide: ["جانفي", "فيفري", "مارس", "أفريل", "ماي", "جوان", "جويلية", "أوت", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"]
};
var dayValues$1h = {
  narrow: ["ح", "ن", "ث", "ر", "خ", "ج", "س"],
  short: ["أحد", "اثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
  abbreviated: ["أحد", "اثنـ", "ثلا", "أربـ", "خميـ", "جمعة", "سبت"],
  wide: ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"]
};
var dayPeriodValues$1h = {
  narrow: {
    am: "ص",
    pm: "م",
    midnight: "ن",
    noon: "ظ",
    morning: "صباحاً",
    afternoon: "بعد الظهر",
    evening: "مساءاً",
    night: "ليلاً"
  },
  abbreviated: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهر",
    morning: "صباحاً",
    afternoon: "بعد الظهر",
    evening: "مساءاً",
    night: "ليلاً"
  },
  wide: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهر",
    morning: "صباحاً",
    afternoon: "بعد الظهر",
    evening: "مساءاً",
    night: "ليلاً"
  }
};
var formattingDayPeriodValues$11 = {
  narrow: {
    am: "ص",
    pm: "م",
    midnight: "ن",
    noon: "ظ",
    morning: "في الصباح",
    afternoon: "بعد الظـهر",
    evening: "في المساء",
    night: "في الليل"
  },
  abbreviated: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهر",
    morning: "في الصباح",
    afternoon: "بعد الظهر",
    evening: "في المساء",
    night: "في الليل"
  },
  wide: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهر",
    morning: "صباحاً",
    afternoon: "بعد الظـهر",
    evening: "في المساء",
    night: "في الليل"
  }
};
var ordinalNumber$1h = function (e) {
  return String(e);
};
localize$1h.localize = {
  ordinalNumber: ordinalNumber$1h,
  era: (0, _index$5B.buildLocalizeFn)({
    values: eraValues$1h,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$5B.buildLocalizeFn)({
    values: quarterValues$1h,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return Number(e) - 1;
    }
  }),
  month: (0, _index$5B.buildLocalizeFn)({
    values: monthValues$1h,
    defaultWidth: "wide"
  }),
  day: (0, _index$5B.buildLocalizeFn)({
    values: dayValues$1h,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$5B.buildLocalizeFn)({
    values: dayPeriodValues$1h,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$11,
    defaultFormattingWidth: "wide"
  })
};
var match$1g = {};
match$1g.match = void 0;
var _index$5A = buildMatchPatternFn;
var _index2$2M = buildMatchFn;
var matchOrdinalNumberPattern$1g = /^(\d+)(th|st|nd|rd)?/i;
var parseOrdinalNumberPattern$1g = /\d+/i;
var matchEraPatterns$1g = {
  narrow: /^(ق|ب)/i,
  abbreviated: /^(ق\.?\s?م\.?|ق\.?\s?م\.?\s?|a\.?\s?d\.?|c\.?\s?)/i,
  wide: /^(قبل الميلاد|قبل الميلاد|بعد الميلاد|بعد الميلاد)/i
};
var parseEraPatterns$1g = {
  any: [/^قبل/i, /^بعد/i]
};
var matchQuarterPatterns$1g = {
  narrow: /^[1234]/i,
  abbreviated: /^ر[1234]/i,
  wide: /^الربع [1234]/i
};
var parseQuarterPatterns$1g = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$1g = {
  narrow: /^[جفمأسند]/i,
  abbreviated: /^(جان|فيف|مار|أفر|ماي|جوا|جوي|أوت|سبت|أكت|نوف|ديس)/i,
  wide: /^(جانفي|فيفري|مارس|أفريل|ماي|جوان|جويلية|أوت|سبتمبر|أكتوبر|نوفمبر|ديسمبر)/i
};
var parseMonthPatterns$1g = {
  narrow: [/^ج/i, /^ف/i, /^م/i, /^أ/i, /^م/i, /^ج/i, /^ج/i, /^أ/i, /^س/i, /^أ/i, /^ن/i, /^د/i],
  any: [/^جان/i, /^فيف/i, /^مار/i, /^أفر/i, /^ماي/i, /^جوا/i, /^جوي/i, /^أوت/i, /^سبت/i, /^أكت/i, /^نوف/i, /^ديس/i]
};
var matchDayPatterns$1g = {
  narrow: /^[حنثرخجس]/i,
  short: /^(أحد|اثنين|ثلاثاء|أربعاء|خميس|جمعة|سبت)/i,
  abbreviated: /^(أحد|اثن|ثلا|أرب|خمي|جمعة|سبت)/i,
  wide: /^(الأحد|الاثنين|الثلاثاء|الأربعاء|الخميس|الجمعة|السبت)/i
};
var parseDayPatterns$1g = {
  narrow: [/^ح/i, /^ن/i, /^ث/i, /^ر/i, /^خ/i, /^ج/i, /^س/i],
  wide: [/^الأحد/i, /^الاثنين/i, /^الثلاثاء/i, /^الأربعاء/i, /^الخميس/i, /^الجمعة/i, /^السبت/i],
  any: [/^أح/i, /^اث/i, /^ث/i, /^أر/i, /^خ/i, /^ج/i, /^س/i]
};
var matchDayPeriodPatterns$1g = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
};
var parseDayPeriodPatterns$1g = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
};
match$1g.match = {
  ordinalNumber: (0, _index$5A.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$1g,
    parsePattern: parseOrdinalNumberPattern$1g,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index2$2M.buildMatchFn)({
    matchPatterns: matchEraPatterns$1g,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$1g,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index2$2M.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$1g,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$1g,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return Number(e) + 1;
    }
  }),
  month: (0, _index2$2M.buildMatchFn)({
    matchPatterns: matchMonthPatterns$1g,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$1g,
    defaultParseWidth: "any"
  }),
  day: (0, _index2$2M.buildMatchFn)({
    matchPatterns: matchDayPatterns$1g,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$1g,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index2$2M.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$1g,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$1g,
    defaultParseWidth: "any"
  })
};
arDZ.arDZ = void 0;
var _index$5z = formatDistance$2z;
var _index2$2L = formatLong$1p;
var _index3$1s = formatRelative$2z;
var _index4$1r = localize$1h;
var _index5$1r = match$1g;
arDZ.arDZ = {
  code: "ar-DZ",
  formatDistance: _index$5z.formatDistance,
  formatLong: _index2$2L.formatLong,
  formatRelative: _index3$1s.formatRelative,
  localize: _index4$1r.localize,
  match: _index5$1r.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var arEG = {};
var formatDistance$2x = {};
formatDistance$2x.formatDistance = void 0;
var formatDistanceLocale$1f = {
  lessThanXSeconds: {
    one: "أقل من ثانية",
    two: "أقل من ثانيتين",
    threeToTen: "أقل من {{count}} ثواني",
    other: "أقل من {{count}} ثانية"
  },
  xSeconds: {
    one: "ثانية",
    two: "ثانيتين",
    threeToTen: "{{count}} ثواني",
    other: "{{count}} ثانية"
  },
  halfAMinute: "نص دقيقة",
  lessThanXMinutes: {
    one: "أقل من دقيقة",
    two: "أقل من دقيقتين",
    threeToTen: "أقل من {{count}} دقايق",
    other: "أقل من {{count}} دقيقة"
  },
  xMinutes: {
    one: "دقيقة",
    two: "دقيقتين",
    threeToTen: "{{count}} دقايق",
    other: "{{count}} دقيقة"
  },
  aboutXHours: {
    one: "حوالي ساعة",
    two: "حوالي ساعتين",
    threeToTen: "حوالي {{count}} ساعات",
    other: "حوالي {{count}} ساعة"
  },
  xHours: {
    one: "ساعة",
    two: "ساعتين",
    threeToTen: "{{count}} ساعات",
    other: "{{count}} ساعة"
  },
  xDays: {
    one: "يوم",
    two: "يومين",
    threeToTen: "{{count}} أيام",
    other: "{{count}} يوم"
  },
  aboutXWeeks: {
    one: "حوالي أسبوع",
    two: "حوالي أسبوعين",
    threeToTen: "حوالي {{count}} أسابيع",
    other: "حوالي {{count}} أسبوع"
  },
  xWeeks: {
    one: "أسبوع",
    two: "أسبوعين",
    threeToTen: "{{count}} أسابيع",
    other: "{{count}} أسبوع"
  },
  aboutXMonths: {
    one: "حوالي شهر",
    two: "حوالي شهرين",
    threeToTen: "حوالي {{count}} أشهر",
    other: "حوالي {{count}} شهر"
  },
  xMonths: {
    one: "شهر",
    two: "شهرين",
    threeToTen: "{{count}} أشهر",
    other: "{{count}} شهر"
  },
  aboutXYears: {
    one: "حوالي سنة",
    two: "حوالي سنتين",
    threeToTen: "حوالي {{count}} سنين",
    other: "حوالي {{count}} سنة"
  },
  xYears: {
    one: "عام",
    two: "عامين",
    threeToTen: "{{count}} أعوام",
    other: "{{count}} عام"
  },
  overXYears: {
    one: "أكثر من سنة",
    two: "أكثر من سنتين",
    threeToTen: "أكثر من {{count}} سنين",
    other: "أكثر من {{count}} سنة"
  },
  almostXYears: {
    one: "عام تقريبًا",
    two: "عامين تقريبًا",
    threeToTen: "{{count}} أعوام تقريبًا",
    other: "{{count}} عام تقريبًا"
  }
};
var formatDistance$2w = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$1f[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else if (a === 2) {
    r = n.two;
  } else if (a <= 10) {
    r = n.threeToTen.replace("{{count}}", String(a));
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "في خلال ".concat(r);
    } else {
      return "منذ ".concat(r);
    }
  }
  return r;
};
formatDistance$2x.formatDistance = formatDistance$2w;
var formatLong$1o = {};
formatLong$1o.formatLong = void 0;
var _index$5y = buildFormatLongFn;
var dateFormats$1o = {
  full: "EEEE، do MMMM y",
  long: "do MMMM y",
  medium: "dd/MMM/y",
  short: "d/MM/y"
};
var timeFormats$1o = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$1o = {
  full: "{{date}} 'الساعة' {{time}}",
  long: "{{date}} 'الساعة' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1o.formatLong = {
  date: (0, _index$5y.buildFormatLongFn)({
    formats: dateFormats$1o,
    defaultWidth: "full"
  }),
  time: (0, _index$5y.buildFormatLongFn)({
    formats: timeFormats$1o,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$5y.buildFormatLongFn)({
    formats: dateTimeFormats$1o,
    defaultWidth: "full"
  })
};
var formatRelative$2x = {};
formatRelative$2x.formatRelative = void 0;
var formatRelativeLocale$1g = {
  lastWeek: "eeee 'اللي جاي الساعة' p",
  yesterday: "'إمبارح الساعة' p",
  today: "'النهاردة الساعة' p",
  tomorrow: "'بكرة الساعة' p",
  nextWeek: "eeee 'الساعة' p",
  other: "P"
};
var formatRelative$2w = function (e, a, t, r) {
  return formatRelativeLocale$1g[e];
};
formatRelative$2x.formatRelative = formatRelative$2w;
var localize$1g = {};
localize$1g.localize = void 0;
var _index$5x = buildLocalizeFn;
var eraValues$1g = {
  narrow: ["ق", "ب"],
  abbreviated: ["ق.م", "ب.م"],
  wide: ["قبل الميلاد", "بعد الميلاد"]
};
var quarterValues$1g = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["ر1", "ر2", "ر3", "ر4"],
  wide: ["الربع الأول", "الربع الثاني", "الربع الثالث", "الربع الرابع"]
};
var monthValues$1g = {
  narrow: ["ي", "ف", "م", "أ", "م", "ي", "ي", "أ", "س", "أ", "ن", "د"],
  abbreviated: ["ينا", "فبر", "مارس", "أبريل", "مايو", "يونـ", "يولـ", "أغسـ", "سبتـ", "أكتـ", "نوفـ", "ديسـ"],
  wide: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"]
};
var dayValues$1g = {
  narrow: ["ح", "ن", "ث", "ر", "خ", "ج", "س"],
  short: ["أحد", "اثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
  abbreviated: ["أحد", "اثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
  wide: ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"]
};
var dayPeriodValues$1g = {
  narrow: {
    am: "ص",
    pm: "م",
    midnight: "ن",
    noon: "ظ",
    morning: "صباحاً",
    afternoon: "بعد الظهر",
    evening: "مساءً",
    night: "ليلاً"
  },
  abbreviated: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهراً",
    morning: "صباحاً",
    afternoon: "بعد الظهر",
    evening: "مساءً",
    night: "ليلاً"
  },
  wide: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهراً",
    morning: "صباحاً",
    afternoon: "بعد الظهر",
    evening: "مساءً",
    night: "ليلاً"
  }
};
var formattingDayPeriodValues$10 = {
  narrow: {
    am: "ص",
    pm: "م",
    midnight: "ن",
    noon: "ظ",
    morning: "في الصباح",
    afternoon: "بعد الظهر",
    evening: "في المساء",
    night: "في الليل"
  },
  abbreviated: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهراً",
    morning: "في الصباح",
    afternoon: "بعد الظهر",
    evening: "في المساء",
    night: "في الليل"
  },
  wide: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    morning: "في الصباح",
    noon: "ظهراً",
    afternoon: "بعد الظهر",
    evening: "في المساء",
    night: "في الليل"
  }
};
var ordinalNumber$1g = function (e, a) {
  return String(e);
};
localize$1g.localize = {
  ordinalNumber: ordinalNumber$1g,
  era: (0, _index$5x.buildLocalizeFn)({
    values: eraValues$1g,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$5x.buildLocalizeFn)({
    values: quarterValues$1g,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$5x.buildLocalizeFn)({
    values: monthValues$1g,
    defaultWidth: "wide"
  }),
  day: (0, _index$5x.buildLocalizeFn)({
    values: dayValues$1g,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$5x.buildLocalizeFn)({
    values: dayPeriodValues$1g,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$10,
    defaultFormattingWidth: "wide"
  })
};
var match$1f = {};
match$1f.match = void 0;
var _index$5w = buildMatchFn;
var _index2$2K = buildMatchPatternFn;
var matchOrdinalNumberPattern$1f = /^(\d+)/;
var parseOrdinalNumberPattern$1f = /\d+/i;
var matchEraPatterns$1f = {
  narrow: /^(ق|ب)/g,
  abbreviated: /^(ق.م|ب.م)/g,
  wide: /^(قبل الميلاد|بعد الميلاد)/g
};
var parseEraPatterns$1f = {
  any: [/^ق/g, /^ب/g]
};
var matchQuarterPatterns$1f = {
  narrow: /^[1234]/,
  abbreviated: /^ر[1234]/,
  wide: /^الربع (الأول|الثاني|الثالث|الرابع)/
};
var parseQuarterPatterns$1f = {
  wide: [/الربع الأول/, /الربع الثاني/, /الربع الثالث/, /الربع الرابع/],
  any: [/1/, /2/, /3/, /4/]
};
var matchMonthPatterns$1f = {
  narrow: /^(ي|ف|م|أ|س|ن|د)/,
  abbreviated: /^(ينا|فبر|مارس|أبريل|مايو|يونـ|يولـ|أغسـ|سبتـ|أكتـ|نوفـ|ديسـ)/,
  wide: /^(يناير|فبراير|مارس|أبريل|مايو|يونيو|يوليو|أغسطس|سبتمبر|أكتوبر|نوفمبر|ديسمبر)/
};
var parseMonthPatterns$1f = {
  narrow: [/^ي/, /^ف/, /^م/, /^أ/, /^م/, /^ي/, /^ي/, /^أ/, /^س/, /^أ/, /^ن/, /^د/],
  any: [/^ينا/, /^فبر/, /^مارس/, /^أبريل/, /^مايو/, /^يون/, /^يول/, /^أغس/, /^سبت/, /^أكت/, /^نوف/, /^ديس/]
};
var matchDayPatterns$1f = {
  narrow: /^(ح|ن|ث|ر|خ|ج|س)/,
  short: /^(أحد|اثنين|ثلاثاء|أربعاء|خميس|جمعة|سبت)/,
  abbreviated: /^(أحد|اثنين|ثلاثاء|أربعاء|خميس|جمعة|سبت)/,
  wide: /^(الأحد|الاثنين|الثلاثاء|الأربعاء|الخميس|الجمعة|السبت)/
};
var parseDayPatterns$1f = {
  narrow: [/^ح/, /^ن/, /^ث/, /^ر/, /^خ/, /^ج/, /^س/],
  any: [/أحد/, /اثنين/, /ثلاثاء/, /أربعاء/, /خميس/, /جمعة/, /سبت/]
};
var matchDayPeriodPatterns$1f = {
  narrow: /^(ص|م|ن|ظ|في الصباح|بعد الظهر|في المساء|في الليل)/,
  abbreviated: /^(ص|م|نصف الليل|ظهراً|في الصباح|بعد الظهر|في المساء|في الليل)/,
  wide: /^(ص|م|نصف الليل|في الصباح|ظهراً|بعد الظهر|في المساء|في الليل)/,
  any: /^(ص|م|صباح|ظهر|مساء|ليل)/
};
var parseDayPeriodPatterns$1f = {
  any: {
    am: /^ص/,
    pm: /^م/,
    midnight: /^ن/,
    noon: /^ظ/,
    morning: /^ص/,
    afternoon: /^بعد/,
    evening: /^م/,
    night: /^ل/
  }
};
match$1f.match = {
  ordinalNumber: (0, _index2$2K.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$1f,
    parsePattern: parseOrdinalNumberPattern$1f,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$5w.buildMatchFn)({
    matchPatterns: matchEraPatterns$1f,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$1f,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$5w.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$1f,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$1f,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$5w.buildMatchFn)({
    matchPatterns: matchMonthPatterns$1f,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$1f,
    defaultParseWidth: "any"
  }),
  day: (0, _index$5w.buildMatchFn)({
    matchPatterns: matchDayPatterns$1f,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$1f,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$5w.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$1f,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$1f,
    defaultParseWidth: "any"
  })
};
arEG.arEG = void 0;
var _index$5v = formatDistance$2x;
var _index2$2J = formatLong$1o;
var _index3$1r = formatRelative$2x;
var _index4$1q = localize$1g;
var _index5$1q = match$1f;
arEG.arEG = {
  code: "ar-EG",
  formatDistance: _index$5v.formatDistance,
  formatLong: _index2$2J.formatLong,
  formatRelative: _index3$1r.formatRelative,
  localize: _index4$1q.localize,
  match: _index5$1q.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var arMA = {};
var formatDistance$2v = {};
formatDistance$2v.formatDistance = void 0;
var formatDistanceLocale$1e = {
  lessThanXSeconds: {
    one: "أقل من ثانية واحدة",
    two: "أقل من ثانتين",
    threeToTen: "أقل من {{count}} ثواني",
    other: "أقل من {{count}} ثانية"
  },
  xSeconds: {
    one: "ثانية واحدة",
    two: "ثانتين",
    threeToTen: "{{count}} ثواني",
    other: "{{count}} ثانية"
  },
  halfAMinute: "نصف دقيقة",
  lessThanXMinutes: {
    one: "أقل من دقيقة",
    two: "أقل من دقيقتين",
    threeToTen: "أقل من {{count}} دقائق",
    other: "أقل من {{count}} دقيقة"
  },
  xMinutes: {
    one: "دقيقة واحدة",
    two: "دقيقتين",
    threeToTen: "{{count}} دقائق",
    other: "{{count}} دقيقة"
  },
  aboutXHours: {
    one: "ساعة واحدة تقريباً",
    two: "ساعتين تقريباً",
    threeToTen: "{{count}} ساعات تقريباً",
    other: "{{count}} ساعة تقريباً"
  },
  xHours: {
    one: "ساعة واحدة",
    two: "ساعتين",
    threeToTen: "{{count}} ساعات",
    other: "{{count}} ساعة"
  },
  xDays: {
    one: "يوم واحد",
    two: "يومين",
    threeToTen: "{{count}} أيام",
    other: "{{count}} يوم"
  },
  aboutXWeeks: {
    one: "أسبوع واحد تقريباً",
    two: "أسبوعين تقريباً",
    threeToTen: "{{count}} أسابيع تقريباً",
    other: "{{count}} أسبوع تقريباً"
  },
  xWeeks: {
    one: "أسبوع واحد",
    two: "أسبوعين",
    threeToTen: "{{count}} أسابيع",
    other: "{{count}} أسبوع"
  },
  aboutXMonths: {
    one: "شهر واحد تقريباً",
    two: "شهرين تقريباً",
    threeToTen: "{{count}} أشهر تقريباً",
    other: "{{count}} شهر تقريباً"
  },
  xMonths: {
    one: "شهر واحد",
    two: "شهرين",
    threeToTen: "{{count}} أشهر",
    other: "{{count}} شهر"
  },
  aboutXYears: {
    one: "عام واحد تقريباً",
    two: "عامين تقريباً",
    threeToTen: "{{count}} أعوام تقريباً",
    other: "{{count}} عام تقريباً"
  },
  xYears: {
    one: "عام واحد",
    two: "عامين",
    threeToTen: "{{count}} أعوام",
    other: "{{count}} عام"
  },
  overXYears: {
    one: "أكثر من عام",
    two: "أكثر من عامين",
    threeToTen: "أكثر من {{count}} أعوام",
    other: "أكثر من {{count}} عام"
  },
  almostXYears: {
    one: "عام واحد تقريباً",
    two: "عامين تقريباً",
    threeToTen: "{{count}} أعوام تقريباً",
    other: "{{count}} عام تقريباً"
  }
};
var formatDistance$2u = function (e, a, t) {
  t = t || {};
  var r = formatDistanceLocale$1e[e];
  var n;
  if (typeof r === "string") {
    n = r;
  } else if (a === 1) {
    n = r.one;
  } else if (a === 2) {
    n = r.two;
  } else if (a <= 10) {
    n = r.threeToTen.replace("{{count}}", String(a));
  } else {
    n = r.other.replace("{{count}}", String(a));
  }
  if (t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "في خلال " + n;
    } else {
      return "منذ " + n;
    }
  }
  return n;
};
formatDistance$2v.formatDistance = formatDistance$2u;
var formatLong$1n = {};
formatLong$1n.formatLong = void 0;
var _index$5u = buildFormatLongFn;
var dateFormats$1n = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
};
var timeFormats$1n = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$1n = {
  full: "{{date}} 'عند' {{time}}",
  long: "{{date}} 'عند' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1n.formatLong = {
  date: (0, _index$5u.buildFormatLongFn)({
    formats: dateFormats$1n,
    defaultWidth: "full"
  }),
  time: (0, _index$5u.buildFormatLongFn)({
    formats: timeFormats$1n,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$5u.buildFormatLongFn)({
    formats: dateTimeFormats$1n,
    defaultWidth: "full"
  })
};
var formatRelative$2v = {};
formatRelative$2v.formatRelative = void 0;
var formatRelativeLocale$1f = {
  lastWeek: "'أخر' eeee 'عند' p",
  yesterday: "'أمس عند' p",
  today: "'اليوم عند' p",
  tomorrow: "'غداً عند' p",
  nextWeek: "eeee 'عند' p",
  other: "P"
};
var formatRelative$2u = function (e, a, t, r) {
  return formatRelativeLocale$1f[e];
};
formatRelative$2v.formatRelative = formatRelative$2u;
var localize$1f = {};
localize$1f.localize = void 0;
var _index$5t = buildLocalizeFn;
var eraValues$1f = {
  narrow: ["ق", "ب"],
  abbreviated: ["ق.م.", "ب.م."],
  wide: ["قبل الميلاد", "بعد الميلاد"]
};
var quarterValues$1f = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["ر1", "ر2", "ر3", "ر4"],
  wide: ["الربع الأول", "الربع الثاني", "الربع الثالث", "الربع الرابع"]
};
var monthValues$1f = {
  narrow: ["ي", "ف", "م", "أ", "م", "ي", "ي", "غ", "ش", "أ", "ن", "د"],
  abbreviated: ["ينا", "فبر", "مارس", "أبريل", "ماي", "يونـ", "يولـ", "غشت", "شتنـ", "أكتـ", "نونـ", "دجنـ"],
  wide: ["يناير", "فبراير", "مارس", "أبريل", "ماي", "يونيو", "يوليوز", "غشت", "شتنبر", "أكتوبر", "نونبر", "دجنبر"]
};
var dayValues$1f = {
  narrow: ["ح", "ن", "ث", "ر", "خ", "ج", "س"],
  short: ["أحد", "اثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
  abbreviated: ["أحد", "اثنـ", "ثلا", "أربـ", "خميـ", "جمعة", "سبت"],
  wide: ["الأحد", "الإثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"]
};
var dayPeriodValues$1f = {
  narrow: {
    am: "ص",
    pm: "م",
    midnight: "ن",
    noon: "ظ",
    morning: "صباحاً",
    afternoon: "بعد الظهر",
    evening: "مساءاً",
    night: "ليلاً"
  },
  abbreviated: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهر",
    morning: "صباحاً",
    afternoon: "بعد الظهر",
    evening: "مساءاً",
    night: "ليلاً"
  },
  wide: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهر",
    morning: "صباحاً",
    afternoon: "بعد الظهر",
    evening: "مساءاً",
    night: "ليلاً"
  }
};
var formattingDayPeriodValues$$ = {
  narrow: {
    am: "ص",
    pm: "م",
    midnight: "ن",
    noon: "ظ",
    morning: "في الصباح",
    afternoon: "بعد الظـهر",
    evening: "في المساء",
    night: "في الليل"
  },
  abbreviated: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهر",
    morning: "في الصباح",
    afternoon: "بعد الظهر",
    evening: "في المساء",
    night: "في الليل"
  },
  wide: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهر",
    morning: "صباحاً",
    afternoon: "بعد الظـهر",
    evening: "في المساء",
    night: "في الليل"
  }
};
var ordinalNumber$1f = function (e) {
  return String(e);
};
localize$1f.localize = {
  ordinalNumber: ordinalNumber$1f,
  era: (0, _index$5t.buildLocalizeFn)({
    values: eraValues$1f,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$5t.buildLocalizeFn)({
    values: quarterValues$1f,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return Number(e) - 1;
    }
  }),
  month: (0, _index$5t.buildLocalizeFn)({
    values: monthValues$1f,
    defaultWidth: "wide"
  }),
  day: (0, _index$5t.buildLocalizeFn)({
    values: dayValues$1f,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$5t.buildLocalizeFn)({
    values: dayPeriodValues$1f,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$$,
    defaultFormattingWidth: "wide"
  })
};
var match$1e = {};
match$1e.match = void 0;
var _index$5s = buildMatchPatternFn;
var _index2$2I = buildMatchFn;
var matchOrdinalNumberPattern$1e = /^(\d+)(th|st|nd|rd)?/i;
var parseOrdinalNumberPattern$1e = /\d+/i;
var matchEraPatterns$1e = {
  narrow: /^(ق|ب)/i,
  abbreviated: /^(ق\.?\s?م\.?|ق\.?\s?م\.?\s?|a\.?\s?d\.?|c\.?\s?)/i,
  wide: /^(قبل الميلاد|قبل الميلاد|بعد الميلاد|بعد الميلاد)/i
};
var parseEraPatterns$1e = {
  any: [/^قبل/i, /^بعد/i]
};
var matchQuarterPatterns$1e = {
  narrow: /^[1234]/i,
  abbreviated: /^ر[1234]/i,
  wide: /^الربع [1234]/i
};
var parseQuarterPatterns$1e = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$1e = {
  narrow: /^[يفمأمسند]/i,
  abbreviated: /^(ين|ف|مار|أب|ماي|يون|يول|غش|شت|أك|ن|د)/i,
  wide: /^(ين|ف|مار|أب|ماي|يون|يول|غش|شت|أك|ن|د)/i
};
var parseMonthPatterns$1e = {
  narrow: [/^ي/i, /^ف/i, /^م/i, /^أ/i, /^م/i, /^ي/i, /^ي/i, /^غ/i, /^ش/i, /^أ/i, /^ن/i, /^د/i],
  any: [/^ين/i, /^فب/i, /^مار/i, /^أب/i, /^ماي/i, /^يون/i, /^يول/i, /^غشت/i, /^ش/i, /^أك/i, /^ن/i, /^د/i]
};
var matchDayPatterns$1e = {
  narrow: /^[حنثرخجس]/i,
  short: /^(أحد|إثنين|ثلاثاء|أربعاء|خميس|جمعة|سبت)/i,
  abbreviated: /^(أحد|إثن|ثلا|أرب|خمي|جمعة|سبت)/i,
  wide: /^(الأحد|الإثنين|الثلاثاء|الأربعاء|الخميس|الجمعة|السبت)/i
};
var parseDayPatterns$1e = {
  narrow: [/^ح/i, /^ن/i, /^ث/i, /^ر/i, /^خ/i, /^ج/i, /^س/i],
  wide: [/^الأحد/i, /^الإثنين/i, /^الثلاثاء/i, /^الأربعاء/i, /^الخميس/i, /^الجمعة/i, /^السبت/i],
  any: [/^أح/i, /^إث/i, /^ث/i, /^أر/i, /^خ/i, /^ج/i, /^س/i]
};
var matchDayPeriodPatterns$1e = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
};
var parseDayPeriodPatterns$1e = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
};
match$1e.match = {
  ordinalNumber: (0, _index$5s.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$1e,
    parsePattern: parseOrdinalNumberPattern$1e,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index2$2I.buildMatchFn)({
    matchPatterns: matchEraPatterns$1e,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$1e,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index2$2I.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$1e,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$1e,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return Number(e) + 1;
    }
  }),
  month: (0, _index2$2I.buildMatchFn)({
    matchPatterns: matchMonthPatterns$1e,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$1e,
    defaultParseWidth: "any"
  }),
  day: (0, _index2$2I.buildMatchFn)({
    matchPatterns: matchDayPatterns$1e,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$1e,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index2$2I.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$1e,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$1e,
    defaultParseWidth: "any"
  })
};
arMA.arMA = void 0;
var _index$5r = formatDistance$2v;
var _index2$2H = formatLong$1n;
var _index3$1q = formatRelative$2v;
var _index4$1p = localize$1f;
var _index5$1p = match$1e;
arMA.arMA = {
  code: "ar-MA",
  formatDistance: _index$5r.formatDistance,
  formatLong: _index2$2H.formatLong,
  formatRelative: _index3$1q.formatRelative,
  localize: _index4$1p.localize,
  match: _index5$1p.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var arSA = {};
var formatDistance$2t = {};
formatDistance$2t.formatDistance = void 0;
var formatDistanceLocale$1d = {
  lessThanXSeconds: {
    one: "أقل من ثانية واحدة",
    two: "أقل من ثانتين",
    threeToTen: "أقل من {{count}} ثواني",
    other: "أقل من {{count}} ثانية"
  },
  xSeconds: {
    one: "ثانية واحدة",
    two: "ثانتين",
    threeToTen: "{{count}} ثواني",
    other: "{{count}} ثانية"
  },
  halfAMinute: "نصف دقيقة",
  lessThanXMinutes: {
    one: "أقل من دقيقة",
    two: "أقل من دقيقتين",
    threeToTen: "أقل من {{count}} دقائق",
    other: "أقل من {{count}} دقيقة"
  },
  xMinutes: {
    one: "دقيقة واحدة",
    two: "دقيقتين",
    threeToTen: "{{count}} دقائق",
    other: "{{count}} دقيقة"
  },
  aboutXHours: {
    one: "ساعة واحدة تقريباً",
    two: "ساعتين تقريباً",
    threeToTen: "{{count}} ساعات تقريباً",
    other: "{{count}} ساعة تقريباً"
  },
  xHours: {
    one: "ساعة واحدة",
    two: "ساعتين",
    threeToTen: "{{count}} ساعات",
    other: "{{count}} ساعة"
  },
  xDays: {
    one: "يوم واحد",
    two: "يومين",
    threeToTen: "{{count}} أيام",
    other: "{{count}} يوم"
  },
  aboutXWeeks: {
    one: "أسبوع واحد تقريباً",
    two: "أسبوعين تقريباً",
    threeToTen: "{{count}} أسابيع تقريباً",
    other: "{{count}} أسبوع تقريباً"
  },
  xWeeks: {
    one: "أسبوع واحد",
    two: "أسبوعين",
    threeToTen: "{{count}} أسابيع",
    other: "{{count}} أسبوع"
  },
  aboutXMonths: {
    one: "شهر واحد تقريباً",
    two: "شهرين تقريباً",
    threeToTen: "{{count}} أشهر تقريباً",
    other: "{{count}} شهر تقريباً"
  },
  xMonths: {
    one: "شهر واحد",
    two: "شهرين",
    threeToTen: "{{count}} أشهر",
    other: "{{count}} شهر"
  },
  aboutXYears: {
    one: "عام واحد تقريباً",
    two: "عامين تقريباً",
    threeToTen: "{{count}} أعوام تقريباً",
    other: "{{count}} عام تقريباً"
  },
  xYears: {
    one: "عام واحد",
    two: "عامين",
    threeToTen: "{{count}} أعوام",
    other: "{{count}} عام"
  },
  overXYears: {
    one: "أكثر من عام",
    two: "أكثر من عامين",
    threeToTen: "أكثر من {{count}} أعوام",
    other: "أكثر من {{count}} عام"
  },
  almostXYears: {
    one: "عام واحد تقريباً",
    two: "عامين تقريباً",
    threeToTen: "{{count}} أعوام تقريباً",
    other: "{{count}} عام تقريباً"
  }
};
var formatDistance$2s = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$1d[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else if (a === 2) {
    r = n.two;
  } else if (a <= 10) {
    r = n.threeToTen.replace("{{count}}", String(a));
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "في خلال " + r;
    } else {
      return "منذ " + r;
    }
  }
  return r;
};
formatDistance$2t.formatDistance = formatDistance$2s;
var formatLong$1m = {};
formatLong$1m.formatLong = void 0;
var _index$5q = buildFormatLongFn;
var dateFormats$1m = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
};
var timeFormats$1m = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$1m = {
  full: "{{date}} 'عند' {{time}}",
  long: "{{date}} 'عند' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1m.formatLong = {
  date: (0, _index$5q.buildFormatLongFn)({
    formats: dateFormats$1m,
    defaultWidth: "full"
  }),
  time: (0, _index$5q.buildFormatLongFn)({
    formats: timeFormats$1m,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$5q.buildFormatLongFn)({
    formats: dateTimeFormats$1m,
    defaultWidth: "full"
  })
};
var formatRelative$2t = {};
formatRelative$2t.formatRelative = void 0;
var formatRelativeLocale$1e = {
  lastWeek: "'أخر' eeee 'عند' p",
  yesterday: "'أمس عند' p",
  today: "'اليوم عند' p",
  tomorrow: "'غداً عند' p",
  nextWeek: "eeee 'عند' p",
  other: "P"
};
var formatRelative$2s = function (e, a, t, r) {
  return formatRelativeLocale$1e[e];
};
formatRelative$2t.formatRelative = formatRelative$2s;
var localize$1e = {};
localize$1e.localize = void 0;
var _index$5p = buildLocalizeFn;
var eraValues$1e = {
  narrow: ["ق", "ب"],
  abbreviated: ["ق.م.", "ب.م."],
  wide: ["قبل الميلاد", "بعد الميلاد"]
};
var quarterValues$1e = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["ر1", "ر2", "ر3", "ر4"],
  wide: ["الربع الأول", "الربع الثاني", "الربع الثالث", "الربع الرابع"]
};
var monthValues$1e = {
  narrow: ["ي", "ف", "م", "أ", "م", "ي", "ي", "أ", "س", "أ", "ن", "د"],
  abbreviated: ["ينا", "فبر", "مارس", "أبريل", "مايو", "يونـ", "يولـ", "أغسـ", "سبتـ", "أكتـ", "نوفـ", "ديسـ"],
  wide: ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"]
};
var dayValues$1e = {
  narrow: ["ح", "ن", "ث", "ر", "خ", "ج", "س"],
  short: ["أحد", "اثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
  abbreviated: ["أحد", "اثنـ", "ثلا", "أربـ", "خميـ", "جمعة", "سبت"],
  wide: ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"]
};
var dayPeriodValues$1e = {
  narrow: {
    am: "ص",
    pm: "م",
    midnight: "ن",
    noon: "ظ",
    morning: "صباحاً",
    afternoon: "بعد الظهر",
    evening: "مساءاً",
    night: "ليلاً"
  },
  abbreviated: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهر",
    morning: "صباحاً",
    afternoon: "بعد الظهر",
    evening: "مساءاً",
    night: "ليلاً"
  },
  wide: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهر",
    morning: "صباحاً",
    afternoon: "بعد الظهر",
    evening: "مساءاً",
    night: "ليلاً"
  }
};
var formattingDayPeriodValues$_ = {
  narrow: {
    am: "ص",
    pm: "م",
    midnight: "ن",
    noon: "ظ",
    morning: "في الصباح",
    afternoon: "بعد الظـهر",
    evening: "في المساء",
    night: "في الليل"
  },
  abbreviated: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهر",
    morning: "في الصباح",
    afternoon: "بعد الظهر",
    evening: "في المساء",
    night: "في الليل"
  },
  wide: {
    am: "ص",
    pm: "م",
    midnight: "نصف الليل",
    noon: "ظهر",
    morning: "صباحاً",
    afternoon: "بعد الظـهر",
    evening: "في المساء",
    night: "في الليل"
  }
};
var ordinalNumber$1e = function (e) {
  return String(e);
};
localize$1e.localize = {
  ordinalNumber: ordinalNumber$1e,
  era: (0, _index$5p.buildLocalizeFn)({
    values: eraValues$1e,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$5p.buildLocalizeFn)({
    values: quarterValues$1e,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$5p.buildLocalizeFn)({
    values: monthValues$1e,
    defaultWidth: "wide"
  }),
  day: (0, _index$5p.buildLocalizeFn)({
    values: dayValues$1e,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$5p.buildLocalizeFn)({
    values: dayPeriodValues$1e,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$_,
    defaultFormattingWidth: "wide"
  })
};
var match$1d = {};
match$1d.match = void 0;
var _index$5o = buildMatchFn;
var _index2$2G = buildMatchPatternFn;
var matchOrdinalNumberPattern$1d = /^(\d+)(th|st|nd|rd)?/i;
var parseOrdinalNumberPattern$1d = /\d+/i;
var matchEraPatterns$1d = {
  narrow: /^(ق|ب)/i,
  abbreviated: /^(ق\.?\s?م\.?|ق\.?\s?م\.?\s?|a\.?\s?d\.?|c\.?\s?)/i,
  wide: /^(قبل الميلاد|قبل الميلاد|بعد الميلاد|بعد الميلاد)/i
};
var parseEraPatterns$1d = {
  any: [/^قبل/i, /^بعد/i]
};
var matchQuarterPatterns$1d = {
  narrow: /^[1234]/i,
  abbreviated: /^ر[1234]/i,
  wide: /^الربع [1234]/i
};
var parseQuarterPatterns$1d = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$1d = {
  narrow: /^[يفمأمسند]/i,
  abbreviated: /^(ين|ف|مار|أب|ماي|يون|يول|أغ|س|أك|ن|د)/i,
  wide: /^(ين|ف|مار|أب|ماي|يون|يول|أغ|س|أك|ن|د)/i
};
var parseMonthPatterns$1d = {
  narrow: [/^ي/i, /^ف/i, /^م/i, /^أ/i, /^م/i, /^ي/i, /^ي/i, /^أ/i, /^س/i, /^أ/i, /^ن/i, /^د/i],
  any: [/^ين/i, /^ف/i, /^مار/i, /^أب/i, /^ماي/i, /^يون/i, /^يول/i, /^أغ/i, /^س/i, /^أك/i, /^ن/i, /^د/i]
};
var matchDayPatterns$1d = {
  narrow: /^[حنثرخجس]/i,
  short: /^(أحد|اثنين|ثلاثاء|أربعاء|خميس|جمعة|سبت)/i,
  abbreviated: /^(أحد|اثن|ثلا|أرب|خمي|جمعة|سبت)/i,
  wide: /^(الأحد|الاثنين|الثلاثاء|الأربعاء|الخميس|الجمعة|السبت)/i
};
var parseDayPatterns$1d = {
  narrow: [/^ح/i, /^ن/i, /^ث/i, /^ر/i, /^خ/i, /^ج/i, /^س/i],
  wide: [/^الأحد/i, /^الاثنين/i, /^الثلاثاء/i, /^الأربعاء/i, /^الخميس/i, /^الجمعة/i, /^السبت/i],
  any: [/^أح/i, /^اث/i, /^ث/i, /^أر/i, /^خ/i, /^ج/i, /^س/i]
};
var matchDayPeriodPatterns$1d = {
  narrow: /^(a|p|mi|n|(in the|at) (morning|afternoon|evening|night))/i,
  any: /^([ap]\.?\s?m\.?|midnight|noon|(in the|at) (morning|afternoon|evening|night))/i
};
var parseDayPeriodPatterns$1d = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mi/i,
    noon: /^no/i,
    morning: /morning/i,
    afternoon: /afternoon/i,
    evening: /evening/i,
    night: /night/i
  }
};
match$1d.match = {
  ordinalNumber: (0, _index2$2G.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$1d,
    parsePattern: parseOrdinalNumberPattern$1d,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$5o.buildMatchFn)({
    matchPatterns: matchEraPatterns$1d,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$1d,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$5o.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$1d,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$1d,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$5o.buildMatchFn)({
    matchPatterns: matchMonthPatterns$1d,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$1d,
    defaultParseWidth: "any"
  }),
  day: (0, _index$5o.buildMatchFn)({
    matchPatterns: matchDayPatterns$1d,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$1d,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$5o.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$1d,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$1d,
    defaultParseWidth: "any"
  })
};
arSA.arSA = void 0;
var _index$5n = formatDistance$2t;
var _index2$2F = formatLong$1m;
var _index3$1p = formatRelative$2t;
var _index4$1o = localize$1e;
var _index5$1o = match$1d;
arSA.arSA = {
  code: "ar-SA",
  formatDistance: _index$5n.formatDistance,
  formatLong: _index2$2F.formatLong,
  formatRelative: _index3$1p.formatRelative,
  localize: _index4$1o.localize,
  match: _index5$1o.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var arTN = {};
var formatDistance$2r = {};
formatDistance$2r.formatDistance = void 0;
var formatDistanceLocale$1c = {
  lessThanXSeconds: {
    one: "أقل من ثانية",
    two: "أقل من زوز ثواني",
    threeToTen: "أقل من {{count}} ثواني",
    other: "أقل من {{count}} ثانية"
  },
  xSeconds: {
    one: "ثانية",
    two: "زوز ثواني",
    threeToTen: "{{count}} ثواني",
    other: "{{count}} ثانية"
  },
  halfAMinute: "نص دقيقة",
  lessThanXMinutes: {
    one: "أقل من دقيقة",
    two: "أقل من دقيقتين",
    threeToTen: "أقل من {{count}} دقايق",
    other: "أقل من {{count}} دقيقة"
  },
  xMinutes: {
    one: "دقيقة",
    two: "دقيقتين",
    threeToTen: "{{count}} دقايق",
    other: "{{count}} دقيقة"
  },
  aboutXHours: {
    one: "ساعة تقريب",
    two: "ساعتين تقريب",
    threeToTen: "{{count}} سوايع تقريب",
    other: "{{count}} ساعة تقريب"
  },
  xHours: {
    one: "ساعة",
    two: "ساعتين",
    threeToTen: "{{count}} سوايع",
    other: "{{count}} ساعة"
  },
  xDays: {
    one: "نهار",
    two: "نهارين",
    threeToTen: "{{count}} أيام",
    other: "{{count}} يوم"
  },
  aboutXWeeks: {
    one: "جمعة تقريب",
    two: "جمعتين تقريب",
    threeToTen: "{{count}} جماع تقريب",
    other: "{{count}} جمعة تقريب"
  },
  xWeeks: {
    one: "جمعة",
    two: "جمعتين",
    threeToTen: "{{count}} جماع",
    other: "{{count}} جمعة"
  },
  aboutXMonths: {
    one: "شهر تقريب",
    two: "شهرين تقريب",
    threeToTen: "{{count}} أشهرة تقريب",
    other: "{{count}} شهر تقريب"
  },
  xMonths: {
    one: "شهر",
    two: "شهرين",
    threeToTen: "{{count}} أشهرة",
    other: "{{count}} شهر"
  },
  aboutXYears: {
    one: "عام تقريب",
    two: "عامين تقريب",
    threeToTen: "{{count}} أعوام تقريب",
    other: "{{count}} عام تقريب"
  },
  xYears: {
    one: "عام",
    two: "عامين",
    threeToTen: "{{count}} أعوام",
    other: "{{count}} عام"
  },
  overXYears: {
    one: "أكثر من عام",
    two: "أكثر من عامين",
    threeToTen: "أكثر من {{count}} أعوام",
    other: "أكثر من {{count}} عام"
  },
  almostXYears: {
    one: "عام تقريب",
    two: "عامين تقريب",
    threeToTen: "{{count}} أعوام تقريب",
    other: "{{count}} عام تقريب"
  }
};
var formatDistance$2q = function (e, a, t) {
  var r = formatDistanceLocale$1c[e];
  var n;
  if (typeof r === "string") {
    n = r;
  } else if (a === 1) {
    n = r.one;
  } else if (a === 2) {
    n = r.two;
  } else if (a <= 10) {
    n = r.threeToTen.replace("{{count}}", String(a));
  } else {
    n = r.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "في " + n;
    } else {
      return "عندو " + n;
    }
  }
  return n;
};
formatDistance$2r.formatDistance = formatDistance$2q;
var formatLong$1l = {};
formatLong$1l.formatLong = void 0;
var _index$5m = buildFormatLongFn;
var dateFormats$1l = {
  full: "EEEE، do MMMM y",
  long: "do MMMM y",
  medium: "d MMM y",
  short: "dd/MM/yyyy"
};
var timeFormats$1l = {
  full: "HH:mm:ss",
  long: "HH:mm:ss",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$1l = {
  full: "{{date}} 'مع' {{time}}",
  long: "{{date}} 'مع' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1l.formatLong = {
  date: (0, _index$5m.buildFormatLongFn)({
    formats: dateFormats$1l,
    defaultWidth: "full"
  }),
  time: (0, _index$5m.buildFormatLongFn)({
    formats: timeFormats$1l,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$5m.buildFormatLongFn)({
    formats: dateTimeFormats$1l,
    defaultWidth: "full"
  })
};
var formatRelative$2r = {};
formatRelative$2r.formatRelative = void 0;
var formatRelativeLocale$1d = {
  lastWeek: "eeee 'إلي فات مع' p",
  yesterday: "'البارح مع' p",
  today: "'اليوم مع' p",
  tomorrow: "'غدوة مع' p",
  nextWeek: "eeee 'الجمعة الجاية مع' p 'نهار'",
  other: "P"
};
var formatRelative$2q = function (e) {
  return formatRelativeLocale$1d[e];
};
formatRelative$2r.formatRelative = formatRelative$2q;
var localize$1d = {};
localize$1d.localize = void 0;
var _index$5l = buildLocalizeFn;
var eraValues$1d = {
  narrow: ["ق", "ب"],
  abbreviated: ["ق.م.", "ب.م."],
  wide: ["قبل الميلاد", "بعد الميلاد"]
};
var quarterValues$1d = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["ر1", "ر2", "ر3", "ر4"],
  wide: ["الربع الأول", "الربع الثاني", "الربع الثالث", "الربع الرابع"]
};
var monthValues$1d = {
  narrow: ["د", "ن", "أ", "س", "أ", "ج", "ج", "م", "أ", "م", "ف", "ج"],
  abbreviated: ["جانفي", "فيفري", "مارس", "أفريل", "ماي", "جوان", "جويلية", "أوت", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"],
  wide: ["جانفي", "فيفري", "مارس", "أفريل", "ماي", "جوان", "جويلية", "أوت", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"]
};
var dayValues$1d = {
  narrow: ["ح", "ن", "ث", "ر", "خ", "ج", "س"],
  short: ["أحد", "اثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
  abbreviated: ["أحد", "اثنين", "ثلاثاء", "أربعاء", "خميس", "جمعة", "سبت"],
  wide: ["الأحد", "الاثنين", "الثلاثاء", "الأربعاء", "الخميس", "الجمعة", "السبت"]
};
var dayPeriodValues$1d = {
  narrow: {
    am: "ص",
    pm: "ع",
    morning: "الصباح",
    noon: "القايلة",
    afternoon: "بعد القايلة",
    evening: "العشية",
    night: "الليل",
    midnight: "نص الليل"
  },
  abbreviated: {
    am: "ص",
    pm: "ع",
    morning: "الصباح",
    noon: "القايلة",
    afternoon: "بعد القايلة",
    evening: "العشية",
    night: "الليل",
    midnight: "نص الليل"
  },
  wide: {
    am: "ص",
    pm: "ع",
    morning: "الصباح",
    noon: "القايلة",
    afternoon: "بعد القايلة",
    evening: "العشية",
    night: "الليل",
    midnight: "نص الليل"
  }
};
var formattingDayPeriodValues$Z = {
  narrow: {
    am: "ص",
    pm: "ع",
    morning: "في الصباح",
    noon: "في القايلة",
    afternoon: "بعد القايلة",
    evening: "في العشية",
    night: "في الليل",
    midnight: "نص الليل"
  },
  abbreviated: {
    am: "ص",
    pm: "ع",
    morning: "في الصباح",
    noon: "في القايلة",
    afternoon: "بعد القايلة",
    evening: "في العشية",
    night: "في الليل",
    midnight: "نص الليل"
  },
  wide: {
    am: "ص",
    pm: "ع",
    morning: "في الصباح",
    noon: "في القايلة",
    afternoon: "بعد القايلة",
    evening: "في العشية",
    night: "في الليل",
    midnight: "نص الليل"
  }
};
var ordinalNumber$1d = function (e) {
  return String(e);
};
localize$1d.localize = {
  ordinalNumber: ordinalNumber$1d,
  era: (0, _index$5l.buildLocalizeFn)({
    values: eraValues$1d,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$5l.buildLocalizeFn)({
    values: quarterValues$1d,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$5l.buildLocalizeFn)({
    values: monthValues$1d,
    defaultWidth: "wide"
  }),
  day: (0, _index$5l.buildLocalizeFn)({
    values: dayValues$1d,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$5l.buildLocalizeFn)({
    values: dayPeriodValues$1d,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$Z,
    defaultFormattingWidth: "wide"
  })
};
var match$1c = {};
match$1c.match = void 0;
var _index$5k = buildMatchPatternFn;
var _index2$2E = buildMatchFn;
var matchOrdinalNumberPattern$1c = /^(\d+)(th|st|nd|rd)?/i;
var parseOrdinalNumberPattern$1c = /\d+/i;
var matchEraPatterns$1c = {
  narrow: /[قب]/,
  abbreviated: /[قب]\.م\./,
  wide: /(قبل|بعد) الميلاد/
};
var parseEraPatterns$1c = {
  any: [/قبل/, /بعد/]
};
var matchQuarterPatterns$1c = {
  narrow: /^[1234]/i,
  abbreviated: /ر[1234]/,
  wide: /الربع (الأول|الثاني|الثالث|الرابع)/
};
var parseQuarterPatterns$1c = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$1c = {
  narrow: /^[جفمأسند]/,
  abbreviated: /^(جانفي|فيفري|مارس|أفريل|ماي|جوان|جويلية|أوت|سبتمبر|أكتوبر|نوفمبر|ديسمبر)/,
  wide: /^(جانفي|فيفري|مارس|أفريل|ماي|جوان|جويلية|أوت|سبتمبر|أكتوبر|نوفمبر|ديسمبر)/
};
var parseMonthPatterns$1c = {
  narrow: [/^ج/i, /^ف/i, /^م/i, /^أ/i, /^م/i, /^ج/i, /^ج/i, /^أ/i, /^س/i, /^أ/i, /^ن/i, /^د/i],
  any: [/^جانفي/i, /^فيفري/i, /^مارس/i, /^أفريل/i, /^ماي/i, /^جوان/i, /^جويلية/i, /^أوت/i, /^سبتمبر/i, /^أكتوبر/i, /^نوفمبر/i, /^ديسمبر/i]
};
var matchDayPatterns$1c = {
  narrow: /^[حنثرخجس]/i,
  short: /^(أحد|اثنين|ثلاثاء|أربعاء|خميس|جمعة|سبت)/i,
  abbreviated: /^(أحد|اثنين|ثلاثاء|أربعاء|خميس|جمعة|سبت)/i,
  wide: /^(الأحد|الاثنين|الثلاثاء|الأربعاء|الخميس|الجمعة|السبت)/i
};
var parseDayPatterns$1c = {
  narrow: [/^ح/i, /^ن/i, /^ث/i, /^ر/i, /^خ/i, /^ج/i, /^س/i],
  wide: [/^الأحد/i, /^الاثنين/i, /^الثلاثاء/i, /^الأربعاء/i, /^الخميس/i, /^الجمعة/i, /^السبت/i],
  any: [/^أح/i, /^اث/i, /^ث/i, /^أر/i, /^خ/i, /^ج/i, /^س/i]
};
var matchDayPeriodPatterns$1c = {
  narrow: /^(ص|ع|ن ل|ل|(في|مع) (صباح|قايلة|عشية|ليل))/,
  any: /^([صع]|نص الليل|قايلة|(في|مع) (صباح|قايلة|عشية|ليل))/
};
var parseDayPeriodPatterns$1c = {
  any: {
    am: /^ص/,
    pm: /^ع/,
    midnight: /نص الليل/,
    noon: /قايلة/,
    afternoon: /بعد القايلة/,
    morning: /صباح/,
    evening: /عشية/,
    night: /ليل/
  }
};
match$1c.match = {
  ordinalNumber: (0, _index$5k.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$1c,
    parsePattern: parseOrdinalNumberPattern$1c,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index2$2E.buildMatchFn)({
    matchPatterns: matchEraPatterns$1c,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$1c,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index2$2E.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$1c,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$1c,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index2$2E.buildMatchFn)({
    matchPatterns: matchMonthPatterns$1c,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$1c,
    defaultParseWidth: "any"
  }),
  day: (0, _index2$2E.buildMatchFn)({
    matchPatterns: matchDayPatterns$1c,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$1c,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index2$2E.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$1c,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$1c,
    defaultParseWidth: "any"
  })
};
arTN.arTN = void 0;
var _index$5j = formatDistance$2r;
var _index2$2D = formatLong$1l;
var _index3$1o = formatRelative$2r;
var _index4$1n = localize$1d;
var _index5$1n = match$1c;
arTN.arTN = {
  code: "ar-TN",
  formatDistance: _index$5j.formatDistance,
  formatLong: _index2$2D.formatLong,
  formatRelative: _index3$1o.formatRelative,
  localize: _index4$1n.localize,
  match: _index5$1n.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var az = {};
var formatDistance$2p = {};
formatDistance$2p.formatDistance = void 0;
var formatDistanceLocale$1b = {
  lessThanXSeconds: {
    one: "bir saniyədən az",
    other: "{{count}} bir saniyədən az"
  },
  xSeconds: {
    one: "1 saniyə",
    other: "{{count}} saniyə"
  },
  halfAMinute: "yarım dəqiqə",
  lessThanXMinutes: {
    one: "bir dəqiqədən az",
    other: "{{count}} bir dəqiqədən az"
  },
  xMinutes: {
    one: "bir dəqiqə",
    other: "{{count}} dəqiqə"
  },
  aboutXHours: {
    one: "təxminən 1 saat",
    other: "təxminən {{count}} saat"
  },
  xHours: {
    one: "1 saat",
    other: "{{count}} saat"
  },
  xDays: {
    one: "1 gün",
    other: "{{count}} gün"
  },
  aboutXWeeks: {
    one: "təxminən 1 həftə",
    other: "təxminən {{count}} həftə"
  },
  xWeeks: {
    one: "1 həftə",
    other: "{{count}} həftə"
  },
  aboutXMonths: {
    one: "təxminən 1 ay",
    other: "təxminən {{count}} ay"
  },
  xMonths: {
    one: "1 ay",
    other: "{{count}} ay"
  },
  aboutXYears: {
    one: "təxminən 1 il",
    other: "təxminən {{count}} il"
  },
  xYears: {
    one: "1 il",
    other: "{{count}} il"
  },
  overXYears: {
    one: "1 ildən çox",
    other: "{{count}} ildən çox"
  },
  almostXYears: {
    one: "demək olar ki 1 il",
    other: "demək olar ki {{count}} il"
  }
};
var formatDistance$2o = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$1b[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + " sonra";
    } else {
      return r + " əvvəl";
    }
  }
  return r;
};
formatDistance$2p.formatDistance = formatDistance$2o;
var formatLong$1k = {};
formatLong$1k.formatLong = void 0;
var _index$5i = buildFormatLongFn;
var dateFormats$1k = {
  full: "EEEE, do MMMM y 'il'",
  long: "do MMMM y 'il'",
  medium: "d MMM y 'il'",
  short: "dd.MM.yyyy"
};
var timeFormats$1k = {
  full: "H:mm:ss zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$1k = {
  full: "{{date}} {{time}} - 'də'",
  long: "{{date}} {{time}} - 'də'",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1k.formatLong = {
  date: (0, _index$5i.buildFormatLongFn)({
    formats: dateFormats$1k,
    defaultWidth: "full"
  }),
  time: (0, _index$5i.buildFormatLongFn)({
    formats: timeFormats$1k,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$5i.buildFormatLongFn)({
    formats: dateTimeFormats$1k,
    defaultWidth: "full"
  })
};
var formatRelative$2p = {};
formatRelative$2p.formatRelative = void 0;
var formatRelativeLocale$1c = {
  lastWeek: "'sonuncu' eeee p -'də'",
  yesterday: "'dünən' p -'də'",
  today: "'bugün' p -'də'",
  tomorrow: "'sabah' p -'də'",
  nextWeek: "eeee p -'də'",
  other: "P"
};
var formatRelative$2o = function (e, a, t, r) {
  return formatRelativeLocale$1c[e];
};
formatRelative$2p.formatRelative = formatRelative$2o;
var localize$1c = {};
localize$1c.localize = void 0;
var _index$5h = buildLocalizeFn;
var eraValues$1c = {
  narrow: ["e.ə", "b.e"],
  abbreviated: ["e.ə", "b.e"],
  wide: ["eramızdan əvvəl", "bizim era"]
};
var quarterValues$1c = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["K1", "K2", "K3", "K4"],
  wide: ["1ci kvartal", "2ci kvartal", "3cü kvartal", "4cü kvartal"]
};
var monthValues$1c = {
  narrow: ["Y", "F", "M", "A", "M", "İ", "İ", "A", "S", "O", "N", "D"],
  abbreviated: ["Yan", "Fev", "Mar", "Apr", "May", "İyun", "İyul", "Avq", "Sen", "Okt", "Noy", "Dek"],
  wide: ["Yanvar", "Fevral", "Mart", "Aprel", "May", "İyun", "İyul", "Avqust", "Sentyabr", "Oktyabr", "Noyabr", "Dekabr"]
};
var dayValues$1c = {
  narrow: ["B.", "B.e", "Ç.a", "Ç.", "C.a", "C.", "Ş."],
  short: ["B.", "B.e", "Ç.a", "Ç.", "C.a", "C.", "Ş."],
  abbreviated: ["Baz", "Baz.e", "Çər.a", "Çər", "Cüm.a", "Cüm", "Şə"],
  wide: ["Bazar", "Bazar ertəsi", "Çərşənbə axşamı", "Çərşənbə", "Cümə axşamı", "Cümə", "Şənbə"]
};
var dayPeriodValues$1c = {
  narrow: {
    am: "am",
    pm: "pm",
    midnight: "gecəyarı",
    noon: "gün",
    morning: "səhər",
    afternoon: "gündüz",
    evening: "axşam",
    night: "gecə"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "gecəyarı",
    noon: "gün",
    morning: "səhər",
    afternoon: "gündüz",
    evening: "axşam",
    night: "gecə"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "gecəyarı",
    noon: "gün",
    morning: "səhər",
    afternoon: "gündüz",
    evening: "axşam",
    night: "gecə"
  }
};
var formattingDayPeriodValues$Y = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "gecəyarı",
    noon: "gün",
    morning: "səhər",
    afternoon: "gündüz",
    evening: "axşam",
    night: "gecə"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "gecəyarı",
    noon: "gün",
    morning: "səhər",
    afternoon: "gündüz",
    evening: "axşam",
    night: "gecə"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "gecəyarı",
    noon: "gün",
    morning: "səhər",
    afternoon: "gündüz",
    evening: "axşam",
    night: "gecə"
  }
};
var suffixes$1 = {
  1: "-inci",
  5: "-inci",
  8: "-inci",
  70: "-inci",
  80: "-inci",
  2: "-nci",
  7: "-nci",
  20: "-nci",
  50: "-nci",
  3: "-üncü",
  4: "-üncü",
  100: "-üncü",
  6: "-ncı",
  9: "-uncu",
  10: "-uncu",
  30: "-uncu",
  60: "-ıncı",
  90: "-ıncı"
};
var getSuffix = function (e) {
  if (e === 0) {
    return e + "-ıncı";
  }
  var a = e % 10;
  var t = e % 100 - a;
  var r = e >= 100 ? 100 : null;
  if (suffixes$1[a]) {
    return suffixes$1[a];
  } else if (suffixes$1[t]) {
    return suffixes$1[t];
  } else if (r !== null) {
    return suffixes$1[r];
  }
  return "";
};
var ordinalNumber$1c = function (e, a) {
  var t = Number(e);
  var r = getSuffix(t);
  return t + r;
};
localize$1c.localize = {
  ordinalNumber: ordinalNumber$1c,
  era: (0, _index$5h.buildLocalizeFn)({
    values: eraValues$1c,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$5h.buildLocalizeFn)({
    values: quarterValues$1c,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$5h.buildLocalizeFn)({
    values: monthValues$1c,
    defaultWidth: "wide"
  }),
  day: (0, _index$5h.buildLocalizeFn)({
    values: dayValues$1c,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$5h.buildLocalizeFn)({
    values: dayPeriodValues$1c,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$Y,
    defaultFormattingWidth: "wide"
  })
};
var match$1b = {};
match$1b.match = void 0;
var _index$5g = buildMatchFn;
var _index2$2C = buildMatchPatternFn;
var matchOrdinalNumberPattern$1b = /^(\d+)(-?(ci|inci|nci|uncu|üncü|ncı))?/i;
var parseOrdinalNumberPattern$1b = /\d+/i;
var matchEraPatterns$1b = {
  narrow: /^(b|a)$/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)$/i,
  wide: /^(bizim eradan əvvəl|bizim era)$/i
};
var parseEraPatterns$1b = {
  any: [/^b$/i, /^(a|c)$/i]
};
var matchQuarterPatterns$1b = {
  narrow: /^[1234]$/i,
  abbreviated: /^K[1234]$/i,
  wide: /^[1234](ci)? kvartal$/i
};
var parseQuarterPatterns$1b = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$1b = {
  narrow: /^[(?-i)yfmaisond]$/i,
  abbreviated: /^(Yan|Fev|Mar|Apr|May|İyun|İyul|Avq|Sen|Okt|Noy|Dek)$/i,
  wide: /^(Yanvar|Fevral|Mart|Aprel|May|İyun|İyul|Avgust|Sentyabr|Oktyabr|Noyabr|Dekabr)$/i
};
var parseMonthPatterns$1b = {
  narrow: [/^[(?-i)y]$/i, /^[(?-i)f]$/i, /^[(?-i)m]$/i, /^[(?-i)a]$/i, /^[(?-i)m]$/i, /^[(?-i)i]$/i, /^[(?-i)i]$/i, /^[(?-i)a]$/i, /^[(?-i)s]$/i, /^[(?-i)o]$/i, /^[(?-i)n]$/i, /^[(?-i)d]$/i],
  abbreviated: [/^Yan$/i, /^Fev$/i, /^Mar$/i, /^Apr$/i, /^May$/i, /^İyun$/i, /^İyul$/i, /^Avg$/i, /^Sen$/i, /^Okt$/i, /^Noy$/i, /^Dek$/i],
  wide: [/^Yanvar$/i, /^Fevral$/i, /^Mart$/i, /^Aprel$/i, /^May$/i, /^İyun$/i, /^İyul$/i, /^Avgust$/i, /^Sentyabr$/i, /^Oktyabr$/i, /^Noyabr$/i, /^Dekabr$/i]
};
var matchDayPatterns$1b = {
  narrow: /^(B\.|B\.e|Ç\.a|Ç\.|C\.a|C\.|Ş\.)$/i,
  short: /^(B\.|B\.e|Ç\.a|Ç\.|C\.a|C\.|Ş\.)$/i,
  abbreviated: /^(Baz\.e|Çər|Çər\.a|Cüm|Cüm\.a|Şə)$/i,
  wide: /^(Bazar|Bazar ertəsi|Çərşənbə axşamı|Çərşənbə|Cümə axşamı|Cümə|Şənbə)$/i
};
var parseDayPatterns$1b = {
  narrow: [/^B\.$/i, /^B\.e$/i, /^Ç\.a$/i, /^Ç\.$/i, /^C\.a$/i, /^C\.$/i, /^Ş\.$/i],
  abbreviated: [/^Baz$/i, /^Baz\.e$/i, /^Çər\.a$/i, /^Çər$/i, /^Cüm\.a$/i, /^Cüm$/i, /^Şə$/i],
  wide: [/^Bazar$/i, /^Bazar ertəsi$/i, /^Çərşənbə axşamı$/i, /^Çərşənbə$/i, /^Cümə axşamı$/i, /^Cümə$/i, /^Şənbə$/i],
  any: [/^B\.$/i, /^B\.e$/i, /^Ç\.a$/i, /^Ç\.$/i, /^C\.a$/i, /^C\.$/i, /^Ş\.$/i]
};
var matchDayPeriodPatterns$1b = {
  narrow: /^(a|p|gecəyarı|gün|səhər|gündüz|axşam|gecə)$/i,
  any: /^(am|pm|a\.m\.|p\.m\.|AM|PM|gecəyarı|gün|səhər|gündüz|axşam|gecə)$/i
};
var parseDayPeriodPatterns$1b = {
  any: {
    am: /^a$/i,
    pm: /^p$/i,
    midnight: /^gecəyarı$/i,
    noon: /^gün$/i,
    morning: /səhər$/i,
    afternoon: /gündüz$/i,
    evening: /axşam$/i,
    night: /gecə$/i
  }
};
match$1b.match = {
  ordinalNumber: (0, _index2$2C.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$1b,
    parsePattern: parseOrdinalNumberPattern$1b,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$5g.buildMatchFn)({
    matchPatterns: matchEraPatterns$1b,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$1b,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$5g.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$1b,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$1b,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$5g.buildMatchFn)({
    matchPatterns: matchMonthPatterns$1b,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$1b,
    defaultParseWidth: "narrow"
  }),
  day: (0, _index$5g.buildMatchFn)({
    matchPatterns: matchDayPatterns$1b,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$1b,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$5g.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$1b,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$1b,
    defaultParseWidth: "any"
  })
};
az.az = void 0;
var _index$5f = formatDistance$2p;
var _index2$2B = formatLong$1k;
var _index3$1n = formatRelative$2p;
var _index4$1m = localize$1c;
var _index5$1m = match$1b;
az.az = {
  code: "az",
  formatDistance: _index$5f.formatDistance,
  formatLong: _index2$2B.formatLong,
  formatRelative: _index3$1n.formatRelative,
  localize: _index4$1m.localize,
  match: _index5$1m.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var be = {};
var formatDistance$2n = {};
formatDistance$2n.formatDistance = void 0;
function declension$6(e, a) {
  if (e.one !== undefined && a === 1) {
    return e.one;
  }
  var t = a % 10;
  var r = a % 100;
  if (t === 1 && r !== 11) {
    return e.singularNominative.replace("{{count}}", String(a));
  } else if (t >= 2 && t <= 4 && (r < 10 || r > 20)) {
    return e.singularGenitive.replace("{{count}}", String(a));
  } else {
    return e.pluralGenitive.replace("{{count}}", String(a));
  }
}
function buildLocalizeTokenFn$4(e) {
  return function (a, t) {
    if (t && t.addSuffix) {
      if (t.comparison && t.comparison > 0) {
        if (e.future) {
          return declension$6(e.future, a);
        } else {
          return "праз " + declension$6(e.regular, a);
        }
      } else {
        if (e.past) {
          return declension$6(e.past, a);
        } else {
          return declension$6(e.regular, a) + " таму";
        }
      }
    } else {
      return declension$6(e.regular, a);
    }
  };
}
var halfAMinute$1 = function (e, a) {
  if (a && a.addSuffix) {
    if (a.comparison && a.comparison > 0) {
      return "праз паўхвіліны";
    } else {
      return "паўхвіліны таму";
    }
  }
  return "паўхвіліны";
};
var formatDistanceLocale$1a = {
  lessThanXSeconds: buildLocalizeTokenFn$4({
    regular: {
      one: "менш за секунду",
      singularNominative: "менш за {{count}} секунду",
      singularGenitive: "менш за {{count}} секунды",
      pluralGenitive: "менш за {{count}} секунд"
    },
    future: {
      one: "менш, чым праз секунду",
      singularNominative: "менш, чым праз {{count}} секунду",
      singularGenitive: "менш, чым праз {{count}} секунды",
      pluralGenitive: "менш, чым праз {{count}} секунд"
    }
  }),
  xSeconds: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "{{count}} секунда",
      singularGenitive: "{{count}} секунды",
      pluralGenitive: "{{count}} секунд"
    },
    past: {
      singularNominative: "{{count}} секунду таму",
      singularGenitive: "{{count}} секунды таму",
      pluralGenitive: "{{count}} секунд таму"
    },
    future: {
      singularNominative: "праз {{count}} секунду",
      singularGenitive: "праз {{count}} секунды",
      pluralGenitive: "праз {{count}} секунд"
    }
  }),
  halfAMinute: halfAMinute$1,
  lessThanXMinutes: buildLocalizeTokenFn$4({
    regular: {
      one: "менш за хвіліну",
      singularNominative: "менш за {{count}} хвіліну",
      singularGenitive: "менш за {{count}} хвіліны",
      pluralGenitive: "менш за {{count}} хвілін"
    },
    future: {
      one: "менш, чым праз хвіліну",
      singularNominative: "менш, чым праз {{count}} хвіліну",
      singularGenitive: "менш, чым праз {{count}} хвіліны",
      pluralGenitive: "менш, чым праз {{count}} хвілін"
    }
  }),
  xMinutes: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "{{count}} хвіліна",
      singularGenitive: "{{count}} хвіліны",
      pluralGenitive: "{{count}} хвілін"
    },
    past: {
      singularNominative: "{{count}} хвіліну таму",
      singularGenitive: "{{count}} хвіліны таму",
      pluralGenitive: "{{count}} хвілін таму"
    },
    future: {
      singularNominative: "праз {{count}} хвіліну",
      singularGenitive: "праз {{count}} хвіліны",
      pluralGenitive: "праз {{count}} хвілін"
    }
  }),
  aboutXHours: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "каля {{count}} гадзіны",
      singularGenitive: "каля {{count}} гадзін",
      pluralGenitive: "каля {{count}} гадзін"
    },
    future: {
      singularNominative: "прыблізна праз {{count}} гадзіну",
      singularGenitive: "прыблізна праз {{count}} гадзіны",
      pluralGenitive: "прыблізна праз {{count}} гадзін"
    }
  }),
  xHours: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "{{count}} гадзіна",
      singularGenitive: "{{count}} гадзіны",
      pluralGenitive: "{{count}} гадзін"
    },
    past: {
      singularNominative: "{{count}} гадзіну таму",
      singularGenitive: "{{count}} гадзіны таму",
      pluralGenitive: "{{count}} гадзін таму"
    },
    future: {
      singularNominative: "праз {{count}} гадзіну",
      singularGenitive: "праз {{count}} гадзіны",
      pluralGenitive: "праз {{count}} гадзін"
    }
  }),
  xDays: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "{{count}} дзень",
      singularGenitive: "{{count}} дні",
      pluralGenitive: "{{count}} дзён"
    }
  }),
  aboutXWeeks: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "каля {{count}} тыдні",
      singularGenitive: "каля {{count}} тыдняў",
      pluralGenitive: "каля {{count}} тыдняў"
    },
    future: {
      singularNominative: "прыблізна праз {{count}} тыдзень",
      singularGenitive: "прыблізна праз {{count}} тыдні",
      pluralGenitive: "прыблізна праз {{count}} тыдняў"
    }
  }),
  xWeeks: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "{{count}} тыдзень",
      singularGenitive: "{{count}} тыдні",
      pluralGenitive: "{{count}} тыдняў"
    }
  }),
  aboutXMonths: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "каля {{count}} месяца",
      singularGenitive: "каля {{count}} месяцаў",
      pluralGenitive: "каля {{count}} месяцаў"
    },
    future: {
      singularNominative: "прыблізна праз {{count}} месяц",
      singularGenitive: "прыблізна праз {{count}} месяцы",
      pluralGenitive: "прыблізна праз {{count}} месяцаў"
    }
  }),
  xMonths: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "{{count}} месяц",
      singularGenitive: "{{count}} месяцы",
      pluralGenitive: "{{count}} месяцаў"
    }
  }),
  aboutXYears: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "каля {{count}} года",
      singularGenitive: "каля {{count}} гадоў",
      pluralGenitive: "каля {{count}} гадоў"
    },
    future: {
      singularNominative: "прыблізна праз {{count}} год",
      singularGenitive: "прыблізна праз {{count}} гады",
      pluralGenitive: "прыблізна праз {{count}} гадоў"
    }
  }),
  xYears: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "{{count}} год",
      singularGenitive: "{{count}} гады",
      pluralGenitive: "{{count}} гадоў"
    }
  }),
  overXYears: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "больш за {{count}} год",
      singularGenitive: "больш за {{count}} гады",
      pluralGenitive: "больш за {{count}} гадоў"
    },
    future: {
      singularNominative: "больш, чым праз {{count}} год",
      singularGenitive: "больш, чым праз {{count}} гады",
      pluralGenitive: "больш, чым праз {{count}} гадоў"
    }
  }),
  almostXYears: buildLocalizeTokenFn$4({
    regular: {
      singularNominative: "амаль {{count}} год",
      singularGenitive: "амаль {{count}} гады",
      pluralGenitive: "амаль {{count}} гадоў"
    },
    future: {
      singularNominative: "амаль праз {{count}} год",
      singularGenitive: "амаль праз {{count}} гады",
      pluralGenitive: "амаль праз {{count}} гадоў"
    }
  })
};
var formatDistance$2m = function (e, a, t) {
  t = t || {};
  return formatDistanceLocale$1a[e](a, t);
};
formatDistance$2n.formatDistance = formatDistance$2m;
var formatLong$1j = {};
formatLong$1j.formatLong = void 0;
var _index$5e = buildFormatLongFn;
var dateFormats$1j = {
  full: "EEEE, d MMMM y 'г.'",
  long: "d MMMM y 'г.'",
  medium: "d MMM y 'г.'",
  short: "dd.MM.y"
};
var timeFormats$1j = {
  full: "H:mm:ss zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$1j = {
  any: "{{date}}, {{time}}"
};
formatLong$1j.formatLong = {
  date: (0, _index$5e.buildFormatLongFn)({
    formats: dateFormats$1j,
    defaultWidth: "full"
  }),
  time: (0, _index$5e.buildFormatLongFn)({
    formats: timeFormats$1j,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$5e.buildFormatLongFn)({
    formats: dateTimeFormats$1j,
    defaultWidth: "any"
  })
};
var formatRelative$2n = {};
formatRelative$2n.formatRelative = void 0;
var _index$5d = isSameWeek;
var _index2$2A = toDate;
var accusativeWeekdays$7 = ["нядзелю", "панядзелак", "аўторак", "сераду", "чацвер", "пятніцу", "суботу"];
function lastWeek$8(e) {
  var a = accusativeWeekdays$7[e];
  switch (e) {
    case 0:
    case 3:
    case 5:
    case 6:
      return "'у мінулую " + a + " а' p";
    case 1:
    case 2:
    case 4:
      return "'у мінулы " + a + " а' p";
  }
}
function thisWeek$8(e) {
  var a = accusativeWeekdays$7[e];
  return "'у " + a + " а' p";
}
function nextWeek$8(e) {
  var a = accusativeWeekdays$7[e];
  switch (e) {
    case 0:
    case 3:
    case 5:
    case 6:
      return "'у наступную " + a + " а' p";
    case 1:
    case 2:
    case 4:
      return "'у наступны " + a + " а' p";
  }
}
var lastWeekFormat$2 = function (e, a, t) {
  var r = (0, _index2$2A.toDate)(e);
  var n = r.getDay();
  if ((0, _index$5d.isSameWeek)(r, a, t)) {
    return thisWeek$8(n);
  } else {
    return lastWeek$8(n);
  }
};
var nextWeekFormat$2 = function (e, a, t) {
  var r = (0, _index2$2A.toDate)(e);
  var n = r.getDay();
  if ((0, _index$5d.isSameWeek)(r, a, t)) {
    return thisWeek$8(n);
  } else {
    return nextWeek$8(n);
  }
};
var formatRelativeLocale$1b = {
  lastWeek: lastWeekFormat$2,
  yesterday: "'учора а' p",
  today: "'сёння а' p",
  tomorrow: "'заўтра а' p",
  nextWeek: nextWeekFormat$2,
  other: "P"
};
var formatRelative$2m = function (e, a, t, r) {
  var n = formatRelativeLocale$1b[e];
  if (typeof n === "function") {
    return n(a, t, r);
  }
  return n;
};
formatRelative$2n.formatRelative = formatRelative$2m;
var localize$1b = {};
localize$1b.localize = void 0;
var _index$5c = buildLocalizeFn;
var eraValues$1b = {
  narrow: ["да н.э.", "н.э."],
  abbreviated: ["да н. э.", "н. э."],
  wide: ["да нашай эры", "нашай эры"]
};
var quarterValues$1b = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1-ы кв.", "2-і кв.", "3-і кв.", "4-ы кв."],
  wide: ["1-ы квартал", "2-і квартал", "3-і квартал", "4-ы квартал"]
};
var monthValues$1b = {
  narrow: ["С", "Л", "С", "К", "М", "Ч", "Л", "Ж", "В", "К", "Л", "С"],
  abbreviated: ["студз.", "лют.", "сак.", "крас.", "май", "чэрв.", "ліп.", "жн.", "вер.", "кастр.", "ліст.", "снеж."],
  wide: ["студзень", "люты", "сакавік", "красавік", "май", "чэрвень", "ліпень", "жнівень", "верасень", "кастрычнік", "лістапад", "снежань"]
};
var formattingMonthValues$i = {
  narrow: ["С", "Л", "С", "К", "М", "Ч", "Л", "Ж", "В", "К", "Л", "С"],
  abbreviated: ["студз.", "лют.", "сак.", "крас.", "мая", "чэрв.", "ліп.", "жн.", "вер.", "кастр.", "ліст.", "снеж."],
  wide: ["студзеня", "лютага", "сакавіка", "красавіка", "мая", "чэрвеня", "ліпеня", "жніўня", "верасня", "кастрычніка", "лістапада", "снежня"]
};
var dayValues$1b = {
  narrow: ["Н", "П", "А", "С", "Ч", "П", "С"],
  short: ["нд", "пн", "аў", "ср", "чц", "пт", "сб"],
  abbreviated: ["нядз", "пан", "аўт", "сер", "чац", "пят", "суб"],
  wide: ["нядзеля", "панядзелак", "аўторак", "серада", "чацвер", "пятніца", "субота"]
};
var dayPeriodValues$1b = {
  narrow: {
    am: "ДП",
    pm: "ПП",
    midnight: "поўн.",
    noon: "поўд.",
    morning: "ран.",
    afternoon: "дзень",
    evening: "веч.",
    night: "ноч"
  },
  abbreviated: {
    am: "ДП",
    pm: "ПП",
    midnight: "поўн.",
    noon: "поўд.",
    morning: "ран.",
    afternoon: "дзень",
    evening: "веч.",
    night: "ноч"
  },
  wide: {
    am: "ДП",
    pm: "ПП",
    midnight: "поўнач",
    noon: "поўдзень",
    morning: "раніца",
    afternoon: "дзень",
    evening: "вечар",
    night: "ноч"
  }
};
var formattingDayPeriodValues$X = {
  narrow: {
    am: "ДП",
    pm: "ПП",
    midnight: "поўн.",
    noon: "поўд.",
    morning: "ран.",
    afternoon: "дня",
    evening: "веч.",
    night: "ночы"
  },
  abbreviated: {
    am: "ДП",
    pm: "ПП",
    midnight: "поўн.",
    noon: "поўд.",
    morning: "ран.",
    afternoon: "дня",
    evening: "веч.",
    night: "ночы"
  },
  wide: {
    am: "ДП",
    pm: "ПП",
    midnight: "поўнач",
    noon: "поўдзень",
    morning: "раніцы",
    afternoon: "дня",
    evening: "вечара",
    night: "ночы"
  }
};
var ordinalNumber$1b = function (e, a) {
  var t = String(a === null || a === void 0 ? void 0 : a.unit);
  var r = Number(e);
  var n;
  if (t === "date") {
    n = "-га";
  } else if (t === "hour" || t === "minute" || t === "second") {
    n = "-я";
  } else {
    n = (r % 10 === 2 || r % 10 === 3) && r % 100 !== 12 && r % 100 !== 13 ? "-і" : "-ы";
  }
  return r + n;
};
localize$1b.localize = {
  ordinalNumber: ordinalNumber$1b,
  era: (0, _index$5c.buildLocalizeFn)({
    values: eraValues$1b,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$5c.buildLocalizeFn)({
    values: quarterValues$1b,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$5c.buildLocalizeFn)({
    values: monthValues$1b,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$i,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$5c.buildLocalizeFn)({
    values: dayValues$1b,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$5c.buildLocalizeFn)({
    values: dayPeriodValues$1b,
    defaultWidth: "any",
    formattingValues: formattingDayPeriodValues$X,
    defaultFormattingWidth: "wide"
  })
};
var match$1a = {};
match$1a.match = void 0;
var _index$5b = buildMatchFn;
var _index2$2z = buildMatchPatternFn;
var matchOrdinalNumberPattern$1a = /^(\d+)(-?(е|я|га|і|ы|ае|ая|яя|шы|гі|ці|ты|мы))?/i;
var parseOrdinalNumberPattern$1a = /\d+/i;
var matchEraPatterns$1a = {
  narrow: /^((да )?н\.?\s?э\.?)/i,
  abbreviated: /^((да )?н\.?\s?э\.?)/i,
  wide: /^(да нашай эры|нашай эры|наша эра)/i
};
var parseEraPatterns$1a = {
  any: [/^д/i, /^н/i]
};
var matchQuarterPatterns$1a = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234](-?[ыі]?)? кв.?/i,
  wide: /^[1234](-?[ыі]?)? квартал/i
};
var parseQuarterPatterns$1a = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$1a = {
  narrow: /^[слкмчжв]/i,
  abbreviated: /^(студз|лют|сак|крас|ма[йя]|чэрв|ліп|жн|вер|кастр|ліст|снеж)\.?/i,
  wide: /^(студзен[ья]|лют(ы|ага)|сакавіка?|красавіка?|ма[йя]|чэрвен[ья]|ліпен[ья]|жні(вень|ўня)|верас(ень|ня)|кастрычніка?|лістапада?|снеж(ань|ня))/i
};
var parseMonthPatterns$1a = {
  narrow: [/^с/i, /^л/i, /^с/i, /^к/i, /^м/i, /^ч/i, /^л/i, /^ж/i, /^в/i, /^к/i, /^л/i, /^с/i],
  any: [/^ст/i, /^лю/i, /^са/i, /^кр/i, /^ма/i, /^ч/i, /^ліп/i, /^ж/i, /^в/i, /^ка/i, /^ліс/i, /^сн/i]
};
var matchDayPatterns$1a = {
  narrow: /^[нпасч]/i,
  short: /^(нд|ня|пн|па|аў|ат|ср|се|чц|ча|пт|пя|сб|су)\.?/i,
  abbreviated: /^(нядз?|ндз|пнд|пан|аўт|срд|сер|чцв|чац|птн|пят|суб).?/i,
  wide: /^(нядзел[яі]|панядзел(ак|ка)|аўтор(ак|ка)|серад[аы]|чацв(ер|ярга)|пятніц[аы]|субот[аы])/i
};
var parseDayPatterns$1a = {
  narrow: [/^н/i, /^п/i, /^а/i, /^с/i, /^ч/i, /^п/i, /^с/i],
  any: [/^н/i, /^п[ан]/i, /^а/i, /^с[ер]/i, /^ч/i, /^п[ят]/i, /^с[уб]/i]
};
var matchDayPeriodPatterns$1a = {
  narrow: /^([дп]п|поўн\.?|поўд\.?|ран\.?|дзень|дня|веч\.?|ночы?)/i,
  abbreviated: /^([дп]п|поўн\.?|поўд\.?|ран\.?|дзень|дня|веч\.?|ночы?)/i,
  wide: /^([дп]п|поўнач|поўдзень|раніц[аы]|дзень|дня|вечара?|ночы?)/i
};
var parseDayPeriodPatterns$1a = {
  any: {
    am: /^дп/i,
    pm: /^пп/i,
    midnight: /^поўн/i,
    noon: /^поўд/i,
    morning: /^р/i,
    afternoon: /^д[зн]/i,
    evening: /^в/i,
    night: /^н/i
  }
};
match$1a.match = {
  ordinalNumber: (0, _index2$2z.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$1a,
    parsePattern: parseOrdinalNumberPattern$1a,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$5b.buildMatchFn)({
    matchPatterns: matchEraPatterns$1a,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$1a,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$5b.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$1a,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$1a,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$5b.buildMatchFn)({
    matchPatterns: matchMonthPatterns$1a,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$1a,
    defaultParseWidth: "any"
  }),
  day: (0, _index$5b.buildMatchFn)({
    matchPatterns: matchDayPatterns$1a,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$1a,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$5b.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$1a,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$1a,
    defaultParseWidth: "any"
  })
};
be.be = void 0;
var _index$5a = formatDistance$2n;
var _index2$2y = formatLong$1j;
var _index3$1m = formatRelative$2n;
var _index4$1l = localize$1b;
var _index5$1l = match$1a;
be.be = {
  code: "be",
  formatDistance: _index$5a.formatDistance,
  formatLong: _index2$2y.formatLong,
  formatRelative: _index3$1m.formatRelative,
  localize: _index4$1l.localize,
  match: _index5$1l.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var beTarask = {};
var formatDistance$2l = {};
formatDistance$2l.formatDistance = void 0;
function declension$5(e, a) {
  if (e.one !== undefined && a === 1) {
    return e.one;
  }
  var t = a % 10;
  var r = a % 100;
  if (t === 1 && r !== 11) {
    return e.singularNominative.replace("{{count}}", String(a));
  } else if (t >= 2 && t <= 4 && (r < 10 || r > 20)) {
    return e.singularGenitive.replace("{{count}}", String(a));
  } else {
    return e.pluralGenitive.replace("{{count}}", String(a));
  }
}
function buildLocalizeTokenFn$3(e) {
  return function (a, t) {
    if (t && t.addSuffix) {
      if (t.comparison && t.comparison > 0) {
        if (e.future) {
          return declension$5(e.future, a);
        } else {
          return "праз " + declension$5(e.regular, a);
        }
      } else {
        if (e.past) {
          return declension$5(e.past, a);
        } else {
          return declension$5(e.regular, a) + " таму";
        }
      }
    } else {
      return declension$5(e.regular, a);
    }
  };
}
var halfAMinute = function (e, a) {
  if (a && a.addSuffix) {
    if (a.comparison && a.comparison > 0) {
      return "праз паўхвіліны";
    } else {
      return "паўхвіліны таму";
    }
  }
  return "паўхвіліны";
};
var formatDistanceLocale$19 = {
  lessThanXSeconds: buildLocalizeTokenFn$3({
    regular: {
      one: "менш за секунду",
      singularNominative: "менш за {{count}} секунду",
      singularGenitive: "менш за {{count}} секунды",
      pluralGenitive: "менш за {{count}} секунд"
    },
    future: {
      one: "менш, чым праз секунду",
      singularNominative: "менш, чым праз {{count}} секунду",
      singularGenitive: "менш, чым праз {{count}} секунды",
      pluralGenitive: "менш, чым праз {{count}} секунд"
    }
  }),
  xSeconds: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "{{count}} секунда",
      singularGenitive: "{{count}} секунды",
      pluralGenitive: "{{count}} секунд"
    },
    past: {
      singularNominative: "{{count}} секунду таму",
      singularGenitive: "{{count}} секунды таму",
      pluralGenitive: "{{count}} секунд таму"
    },
    future: {
      singularNominative: "праз {{count}} секунду",
      singularGenitive: "праз {{count}} секунды",
      pluralGenitive: "праз {{count}} секунд"
    }
  }),
  halfAMinute: halfAMinute,
  lessThanXMinutes: buildLocalizeTokenFn$3({
    regular: {
      one: "менш за хвіліну",
      singularNominative: "менш за {{count}} хвіліну",
      singularGenitive: "менш за {{count}} хвіліны",
      pluralGenitive: "менш за {{count}} хвілін"
    },
    future: {
      one: "менш, чым праз хвіліну",
      singularNominative: "менш, чым праз {{count}} хвіліну",
      singularGenitive: "менш, чым праз {{count}} хвіліны",
      pluralGenitive: "менш, чым праз {{count}} хвілін"
    }
  }),
  xMinutes: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "{{count}} хвіліна",
      singularGenitive: "{{count}} хвіліны",
      pluralGenitive: "{{count}} хвілін"
    },
    past: {
      singularNominative: "{{count}} хвіліну таму",
      singularGenitive: "{{count}} хвіліны таму",
      pluralGenitive: "{{count}} хвілін таму"
    },
    future: {
      singularNominative: "праз {{count}} хвіліну",
      singularGenitive: "праз {{count}} хвіліны",
      pluralGenitive: "праз {{count}} хвілін"
    }
  }),
  aboutXHours: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "каля {{count}} гадзіны",
      singularGenitive: "каля {{count}} гадзін",
      pluralGenitive: "каля {{count}} гадзін"
    },
    future: {
      singularNominative: "прыблізна праз {{count}} гадзіну",
      singularGenitive: "прыблізна праз {{count}} гадзіны",
      pluralGenitive: "прыблізна праз {{count}} гадзін"
    }
  }),
  xHours: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "{{count}} гадзіна",
      singularGenitive: "{{count}} гадзіны",
      pluralGenitive: "{{count}} гадзін"
    },
    past: {
      singularNominative: "{{count}} гадзіну таму",
      singularGenitive: "{{count}} гадзіны таму",
      pluralGenitive: "{{count}} гадзін таму"
    },
    future: {
      singularNominative: "праз {{count}} гадзіну",
      singularGenitive: "праз {{count}} гадзіны",
      pluralGenitive: "праз {{count}} гадзін"
    }
  }),
  xDays: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "{{count}} дзень",
      singularGenitive: "{{count}} дні",
      pluralGenitive: "{{count}} дзён"
    }
  }),
  aboutXWeeks: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "каля {{count}} тыдні",
      singularGenitive: "каля {{count}} тыдняў",
      pluralGenitive: "каля {{count}} тыдняў"
    },
    future: {
      singularNominative: "прыблізна праз {{count}} тыдзень",
      singularGenitive: "прыблізна праз {{count}} тыдні",
      pluralGenitive: "прыблізна праз {{count}} тыдняў"
    }
  }),
  xWeeks: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "{{count}} тыдзень",
      singularGenitive: "{{count}} тыдні",
      pluralGenitive: "{{count}} тыдняў"
    }
  }),
  aboutXMonths: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "каля {{count}} месяца",
      singularGenitive: "каля {{count}} месяцаў",
      pluralGenitive: "каля {{count}} месяцаў"
    },
    future: {
      singularNominative: "прыблізна праз {{count}} месяц",
      singularGenitive: "прыблізна праз {{count}} месяцы",
      pluralGenitive: "прыблізна праз {{count}} месяцаў"
    }
  }),
  xMonths: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "{{count}} месяц",
      singularGenitive: "{{count}} месяцы",
      pluralGenitive: "{{count}} месяцаў"
    }
  }),
  aboutXYears: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "каля {{count}} года",
      singularGenitive: "каля {{count}} гадоў",
      pluralGenitive: "каля {{count}} гадоў"
    },
    future: {
      singularNominative: "прыблізна праз {{count}} год",
      singularGenitive: "прыблізна праз {{count}} гады",
      pluralGenitive: "прыблізна праз {{count}} гадоў"
    }
  }),
  xYears: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "{{count}} год",
      singularGenitive: "{{count}} гады",
      pluralGenitive: "{{count}} гадоў"
    }
  }),
  overXYears: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "больш за {{count}} год",
      singularGenitive: "больш за {{count}} гады",
      pluralGenitive: "больш за {{count}} гадоў"
    },
    future: {
      singularNominative: "больш, чым праз {{count}} год",
      singularGenitive: "больш, чым праз {{count}} гады",
      pluralGenitive: "больш, чым праз {{count}} гадоў"
    }
  }),
  almostXYears: buildLocalizeTokenFn$3({
    regular: {
      singularNominative: "амаль {{count}} год",
      singularGenitive: "амаль {{count}} гады",
      pluralGenitive: "амаль {{count}} гадоў"
    },
    future: {
      singularNominative: "амаль праз {{count}} год",
      singularGenitive: "амаль праз {{count}} гады",
      pluralGenitive: "амаль праз {{count}} гадоў"
    }
  })
};
var formatDistance$2k = function (e, a, t) {
  t = t || {};
  return formatDistanceLocale$19[e](a, t);
};
formatDistance$2l.formatDistance = formatDistance$2k;
var formatLong$1i = {};
formatLong$1i.formatLong = void 0;
var _index$59 = buildFormatLongFn;
var dateFormats$1i = {
  full: "EEEE, d MMMM y 'г.'",
  long: "d MMMM y 'г.'",
  medium: "d MMM y 'г.'",
  short: "dd.MM.y"
};
var timeFormats$1i = {
  full: "H:mm:ss zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$1i = {
  any: "{{date}}, {{time}}"
};
formatLong$1i.formatLong = {
  date: (0, _index$59.buildFormatLongFn)({
    formats: dateFormats$1i,
    defaultWidth: "full"
  }),
  time: (0, _index$59.buildFormatLongFn)({
    formats: timeFormats$1i,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$59.buildFormatLongFn)({
    formats: dateTimeFormats$1i,
    defaultWidth: "any"
  })
};
var formatRelative$2l = {};
formatRelative$2l.formatRelative = void 0;
var _index$58 = isSameWeek;
var _index2$2x = toDate;
var accusativeWeekdays$6 = ["нядзелю", "панядзелак", "аўторак", "сераду", "чацьвер", "пятніцу", "суботу"];
function lastWeek$7(e) {
  var a = accusativeWeekdays$6[e];
  switch (e) {
    case 0:
    case 3:
    case 5:
    case 6:
      return "'у мінулую " + a + " а' p";
    case 1:
    case 2:
    case 4:
      return "'у мінулы " + a + " а' p";
  }
}
function thisWeek$7(e) {
  var a = accusativeWeekdays$6[e];
  return "'у " + a + " а' p";
}
function nextWeek$7(e) {
  var a = accusativeWeekdays$6[e];
  switch (e) {
    case 0:
    case 3:
    case 5:
    case 6:
      return "'у наступную " + a + " а' p";
    case 1:
    case 2:
    case 4:
      return "'у наступны " + a + " а' p";
  }
}
var lastWeekFormat$1 = function (e, a, t) {
  var r = (0, _index2$2x.toDate)(e);
  var n = r.getDay();
  if ((0, _index$58.isSameWeek)(r, a, t)) {
    return thisWeek$7(n);
  } else {
    return lastWeek$7(n);
  }
};
var nextWeekFormat$1 = function (e, a, t) {
  var r = (0, _index2$2x.toDate)(e);
  var n = r.getDay();
  if ((0, _index$58.isSameWeek)(r, a, t)) {
    return thisWeek$7(n);
  } else {
    return nextWeek$7(n);
  }
};
var formatRelativeLocale$1a = {
  lastWeek: lastWeekFormat$1,
  yesterday: "'учора а' p",
  today: "'сёньня а' p",
  tomorrow: "'заўтра а' p",
  nextWeek: nextWeekFormat$1,
  other: "P"
};
var formatRelative$2k = function (e, a, t, r) {
  var n = formatRelativeLocale$1a[e];
  if (typeof n === "function") {
    return n(a, t, r);
  }
  return n;
};
formatRelative$2l.formatRelative = formatRelative$2k;
var localize$1a = {};
localize$1a.localize = void 0;
var _index$57 = buildLocalizeFn;
var eraValues$1a = {
  narrow: ["да н.э.", "н.э."],
  abbreviated: ["да н. э.", "н. э."],
  wide: ["да нашай эры", "нашай эры"]
};
var quarterValues$1a = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1-ы кв.", "2-і кв.", "3-і кв.", "4-ы кв."],
  wide: ["1-ы квартал", "2-і квартал", "3-і квартал", "4-ы квартал"]
};
var monthValues$1a = {
  narrow: ["С", "Л", "С", "К", "Т", "Ч", "Л", "Ж", "В", "К", "Л", "С"],
  abbreviated: ["студз.", "лют.", "сак.", "крас.", "трав.", "чэрв.", "ліп.", "жн.", "вер.", "кастр.", "ліст.", "сьнеж."],
  wide: ["студзень", "люты", "сакавік", "красавік", "травень", "чэрвень", "ліпень", "жнівень", "верасень", "кастрычнік", "лістапад", "сьнежань"]
};
var formattingMonthValues$h = {
  narrow: ["С", "Л", "С", "К", "Т", "Ч", "Л", "Ж", "В", "К", "Л", "С"],
  abbreviated: ["студз.", "лют.", "сак.", "крас.", "трав.", "чэрв.", "ліп.", "жн.", "вер.", "кастр.", "ліст.", "сьнеж."],
  wide: ["студзеня", "лютага", "сакавіка", "красавіка", "траўня", "чэрвеня", "ліпеня", "жніўня", "верасня", "кастрычніка", "лістапада", "сьнежня"]
};
var dayValues$1a = {
  narrow: ["Н", "П", "А", "С", "Ч", "П", "С"],
  short: ["нд", "пн", "аў", "ср", "чц", "пт", "сб"],
  abbreviated: ["нядз", "пан", "аўт", "сер", "чаць", "пят", "суб"],
  wide: ["нядзеля", "панядзелак", "аўторак", "серада", "чацьвер", "пятніца", "субота"]
};
var dayPeriodValues$1a = {
  narrow: {
    am: "ДП",
    pm: "ПП",
    midnight: "поўн.",
    noon: "поўд.",
    morning: "ран.",
    afternoon: "дзень",
    evening: "веч.",
    night: "ноч"
  },
  abbreviated: {
    am: "ДП",
    pm: "ПП",
    midnight: "поўн.",
    noon: "поўд.",
    morning: "ран.",
    afternoon: "дзень",
    evening: "веч.",
    night: "ноч"
  },
  wide: {
    am: "ДП",
    pm: "ПП",
    midnight: "поўнач",
    noon: "поўдзень",
    morning: "раніца",
    afternoon: "дзень",
    evening: "вечар",
    night: "ноч"
  }
};
var formattingDayPeriodValues$W = {
  narrow: {
    am: "ДП",
    pm: "ПП",
    midnight: "поўн.",
    noon: "поўд.",
    morning: "ран.",
    afternoon: "дня",
    evening: "веч.",
    night: "ночы"
  },
  abbreviated: {
    am: "ДП",
    pm: "ПП",
    midnight: "поўн.",
    noon: "поўд.",
    morning: "ран.",
    afternoon: "дня",
    evening: "веч.",
    night: "ночы"
  },
  wide: {
    am: "ДП",
    pm: "ПП",
    midnight: "поўнач",
    noon: "поўдзень",
    morning: "раніцы",
    afternoon: "дня",
    evening: "вечара",
    night: "ночы"
  }
};
var ordinalNumber$1a = function (e, a) {
  var t = String(a === null || a === void 0 ? void 0 : a.unit);
  var r = Number(e);
  var n;
  if (t === "date") {
    n = "-га";
  } else if (t === "hour" || t === "minute" || t === "second") {
    n = "-я";
  } else {
    n = (r % 10 === 2 || r % 10 === 3) && r % 100 !== 12 && r % 100 !== 13 ? "-і" : "-ы";
  }
  return r + n;
};
localize$1a.localize = {
  ordinalNumber: ordinalNumber$1a,
  era: (0, _index$57.buildLocalizeFn)({
    values: eraValues$1a,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$57.buildLocalizeFn)({
    values: quarterValues$1a,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$57.buildLocalizeFn)({
    values: monthValues$1a,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$h,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$57.buildLocalizeFn)({
    values: dayValues$1a,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$57.buildLocalizeFn)({
    values: dayPeriodValues$1a,
    defaultWidth: "any",
    formattingValues: formattingDayPeriodValues$W,
    defaultFormattingWidth: "wide"
  })
};
var match$19 = {};
match$19.match = void 0;
var _index$56 = buildMatchFn;
var _index2$2w = buildMatchPatternFn;
var matchOrdinalNumberPattern$19 = /^(\d+)(-?(е|я|га|і|ы|ае|ая|яя|шы|гі|ці|ты|мы))?/i;
var parseOrdinalNumberPattern$19 = /\d+/i;
var matchEraPatterns$19 = {
  narrow: /^((да )?н\.?\s?э\.?)/i,
  abbreviated: /^((да )?н\.?\s?э\.?)/i,
  wide: /^(да нашай эры|нашай эры|наша эра)/i
};
var parseEraPatterns$19 = {
  any: [/^д/i, /^н/i]
};
var matchQuarterPatterns$19 = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234](-?[ыі]?)? кв.?/i,
  wide: /^[1234](-?[ыі]?)? квартал/i
};
var parseQuarterPatterns$19 = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$19 = {
  narrow: /^[слкмчжв]/i,
  abbreviated: /^(студз|лют|сак|крас|тр(ав)?|чэрв|ліп|жн|вер|кастр|ліст|сьнеж)\.?/i,
  wide: /^(студзен[ья]|лют(ы|ага)|сакавіка?|красавіка?|тра(вень|ўня)|чэрвен[ья]|ліпен[ья]|жні(вень|ўня)|верас(ень|ня)|кастрычніка?|лістапада?|сьнеж(ань|ня))/i
};
var parseMonthPatterns$19 = {
  narrow: [/^с/i, /^л/i, /^с/i, /^к/i, /^т/i, /^ч/i, /^л/i, /^ж/i, /^в/i, /^к/i, /^л/i, /^с/i],
  any: [/^ст/i, /^лю/i, /^са/i, /^кр/i, /^тр/i, /^ч/i, /^ліп/i, /^ж/i, /^в/i, /^ка/i, /^ліс/i, /^сн/i]
};
var matchDayPatterns$19 = {
  narrow: /^[нпасч]/i,
  short: /^(нд|ня|пн|па|аў|ат|ср|се|чц|ча|пт|пя|сб|су)\.?/i,
  abbreviated: /^(нядз?|ндз|пнд|пан|аўт|срд|сер|чцьв|чаць|птн|пят|суб).?/i,
  wide: /^(нядзел[яі]|панядзел(ак|ка)|аўтор(ак|ка)|серад[аы]|чацьв(ер|ярга)|пятніц[аы]|субот[аы])/i
};
var parseDayPatterns$19 = {
  narrow: [/^н/i, /^п/i, /^а/i, /^с/i, /^ч/i, /^п/i, /^с/i],
  any: [/^н/i, /^п[ан]/i, /^а/i, /^с[ер]/i, /^ч/i, /^п[ят]/i, /^с[уб]/i]
};
var matchDayPeriodPatterns$19 = {
  narrow: /^([дп]п|поўн\.?|поўд\.?|ран\.?|дзень|дня|веч\.?|ночы?)/i,
  abbreviated: /^([дп]п|поўн\.?|поўд\.?|ран\.?|дзень|дня|веч\.?|ночы?)/i,
  wide: /^([дп]п|поўнач|поўдзень|раніц[аы]|дзень|дня|вечара?|ночы?)/i
};
var parseDayPeriodPatterns$19 = {
  any: {
    am: /^дп/i,
    pm: /^пп/i,
    midnight: /^поўн/i,
    noon: /^поўд/i,
    morning: /^р/i,
    afternoon: /^д[зн]/i,
    evening: /^в/i,
    night: /^н/i
  }
};
match$19.match = {
  ordinalNumber: (0, _index2$2w.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$19,
    parsePattern: parseOrdinalNumberPattern$19,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$56.buildMatchFn)({
    matchPatterns: matchEraPatterns$19,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$19,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$56.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$19,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$19,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$56.buildMatchFn)({
    matchPatterns: matchMonthPatterns$19,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$19,
    defaultParseWidth: "any"
  }),
  day: (0, _index$56.buildMatchFn)({
    matchPatterns: matchDayPatterns$19,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$19,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$56.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$19,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$19,
    defaultParseWidth: "any"
  })
};
beTarask.beTarask = void 0;
var _index$55 = formatDistance$2l;
var _index2$2v = formatLong$1i;
var _index3$1l = formatRelative$2l;
var _index4$1k = localize$1a;
var _index5$1k = match$19;
beTarask.beTarask = {
  code: "be-tarask",
  formatDistance: _index$55.formatDistance,
  formatLong: _index2$2v.formatLong,
  formatRelative: _index3$1l.formatRelative,
  localize: _index4$1k.localize,
  match: _index5$1k.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var bg = {};
var formatDistance$2j = {};
formatDistance$2j.formatDistance = void 0;
var formatDistanceLocale$18 = {
  lessThanXSeconds: {
    one: "по-малко от секунда",
    other: "по-малко от {{count}} секунди"
  },
  xSeconds: {
    one: "1 секунда",
    other: "{{count}} секунди"
  },
  halfAMinute: "половин минута",
  lessThanXMinutes: {
    one: "по-малко от минута",
    other: "по-малко от {{count}} минути"
  },
  xMinutes: {
    one: "1 минута",
    other: "{{count}} минути"
  },
  aboutXHours: {
    one: "около час",
    other: "около {{count}} часа"
  },
  xHours: {
    one: "1 час",
    other: "{{count}} часа"
  },
  xDays: {
    one: "1 ден",
    other: "{{count}} дни"
  },
  aboutXWeeks: {
    one: "около седмица",
    other: "около {{count}} седмици"
  },
  xWeeks: {
    one: "1 седмица",
    other: "{{count}} седмици"
  },
  aboutXMonths: {
    one: "около месец",
    other: "около {{count}} месеца"
  },
  xMonths: {
    one: "1 месец",
    other: "{{count}} месеца"
  },
  aboutXYears: {
    one: "около година",
    other: "около {{count}} години"
  },
  xYears: {
    one: "1 година",
    other: "{{count}} години"
  },
  overXYears: {
    one: "над година",
    other: "над {{count}} години"
  },
  almostXYears: {
    one: "почти година",
    other: "почти {{count}} години"
  }
};
var formatDistance$2i = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$18[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "след " + r;
    } else {
      return "преди " + r;
    }
  }
  return r;
};
formatDistance$2j.formatDistance = formatDistance$2i;
var formatLong$1h = {};
formatLong$1h.formatLong = void 0;
var _index$54 = buildFormatLongFn;
var dateFormats$1h = {
  full: "EEEE, dd MMMM yyyy",
  long: "dd MMMM yyyy",
  medium: "dd MMM yyyy",
  short: "dd/MM/yyyy"
};
var timeFormats$1h = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$1h = {
  any: "{{date}} {{time}}"
};
formatLong$1h.formatLong = {
  date: (0, _index$54.buildFormatLongFn)({
    formats: dateFormats$1h,
    defaultWidth: "full"
  }),
  time: (0, _index$54.buildFormatLongFn)({
    formats: timeFormats$1h,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$54.buildFormatLongFn)({
    formats: dateTimeFormats$1h,
    defaultWidth: "any"
  })
};
var formatRelative$2j = {};
formatRelative$2j.formatRelative = void 0;
var _index$53 = isSameWeek;
var _index2$2u = toDate;
var weekdays$3 = ["неделя", "понеделник", "вторник", "сряда", "четвъртък", "петък", "събота"];
function lastWeek$6(e) {
  var a = weekdays$3[e];
  switch (e) {
    case 0:
    case 3:
    case 6:
      return "'миналата " + a + " в' p";
    case 1:
    case 2:
    case 4:
    case 5:
      return "'миналия " + a + " в' p";
  }
}
function thisWeek$6(e) {
  var a = weekdays$3[e];
  if (e === 2) {
    return "'във " + a + " в' p";
  } else {
    return "'в " + a + " в' p";
  }
}
function nextWeek$6(e) {
  var a = weekdays$3[e];
  switch (e) {
    case 0:
    case 3:
    case 6:
      return "'следващата " + a + " в' p";
    case 1:
    case 2:
    case 4:
    case 5:
      return "'следващия " + a + " в' p";
  }
}
var lastWeekFormatToken = function (e, a, t) {
  var r = (0, _index2$2u.toDate)(e);
  var n = r.getDay();
  if ((0, _index$53.isSameWeek)(r, a, t)) {
    return thisWeek$6(n);
  } else {
    return lastWeek$6(n);
  }
};
var nextWeekFormatToken = function (e, a, t) {
  var r = (0, _index2$2u.toDate)(e);
  var n = r.getDay();
  if ((0, _index$53.isSameWeek)(r, a, t)) {
    return thisWeek$6(n);
  } else {
    return nextWeek$6(n);
  }
};
var formatRelativeLocale$19 = {
  lastWeek: lastWeekFormatToken,
  yesterday: "'вчера в' p",
  today: "'днес в' p",
  tomorrow: "'утре в' p",
  nextWeek: nextWeekFormatToken,
  other: "P"
};
var formatRelative$2i = function (e, a, t, r) {
  var n = formatRelativeLocale$19[e];
  if (typeof n === "function") {
    return n(a, t, r);
  }
  return n;
};
formatRelative$2j.formatRelative = formatRelative$2i;
var localize$19 = {};
localize$19.localize = void 0;
var _index$52 = buildLocalizeFn;
var eraValues$19 = {
  narrow: ["пр.н.е.", "н.е."],
  abbreviated: ["преди н. е.", "н. е."],
  wide: ["преди новата ера", "новата ера"]
};
var quarterValues$19 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1-во тримес.", "2-ро тримес.", "3-то тримес.", "4-то тримес."],
  wide: ["1-во тримесечие", "2-ро тримесечие", "3-то тримесечие", "4-то тримесечие"]
};
var monthValues$19 = {
  abbreviated: ["яну", "фев", "мар", "апр", "май", "юни", "юли", "авг", "сеп", "окт", "ное", "дек"],
  wide: ["януари", "февруари", "март", "април", "май", "юни", "юли", "август", "септември", "октомври", "ноември", "декември"]
};
var dayValues$19 = {
  narrow: ["Н", "П", "В", "С", "Ч", "П", "С"],
  short: ["нд", "пн", "вт", "ср", "чт", "пт", "сб"],
  abbreviated: ["нед", "пон", "вто", "сря", "чет", "пет", "съб"],
  wide: ["неделя", "понеделник", "вторник", "сряда", "четвъртък", "петък", "събота"]
};
var dayPeriodValues$19 = {
  wide: {
    am: "преди обяд",
    pm: "след обяд",
    midnight: "в полунощ",
    noon: "на обяд",
    morning: "сутринта",
    afternoon: "следобед",
    evening: "вечерта",
    night: "през нощта"
  }
};
function isFeminine(e) {
  return e === "year" || e === "week" || e === "minute" || e === "second";
}
function isNeuter(e) {
  return e === "quarter";
}
function numberWithSuffix(e, a, t, r, n) {
  var i = isNeuter(a) ? n : isFeminine(a) ? r : t;
  return e + "-" + i;
}
var ordinalNumber$19 = function (e, a) {
  var t = Number(e);
  var r = a === null || a === void 0 ? void 0 : a.unit;
  if (t === 0) {
    return numberWithSuffix(0, r, "ев", "ева", "ево");
  } else if (t % 1e3 === 0) {
    return numberWithSuffix(t, r, "ен", "на", "но");
  } else if (t % 100 === 0) {
    return numberWithSuffix(t, r, "тен", "тна", "тно");
  }
  var n = t % 100;
  if (n > 20 || n < 10) {
    switch (n % 10) {
      case 1:
        return numberWithSuffix(t, r, "ви", "ва", "во");
      case 2:
        return numberWithSuffix(t, r, "ри", "ра", "ро");
      case 7:
      case 8:
        return numberWithSuffix(t, r, "ми", "ма", "мо");
    }
  }
  return numberWithSuffix(t, r, "ти", "та", "то");
};
localize$19.localize = {
  ordinalNumber: ordinalNumber$19,
  era: (0, _index$52.buildLocalizeFn)({
    values: eraValues$19,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$52.buildLocalizeFn)({
    values: quarterValues$19,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$52.buildLocalizeFn)({
    values: monthValues$19,
    defaultWidth: "wide"
  }),
  day: (0, _index$52.buildLocalizeFn)({
    values: dayValues$19,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$52.buildLocalizeFn)({
    values: dayPeriodValues$19,
    defaultWidth: "wide"
  })
};
var match$18 = {};
match$18.match = void 0;
var _index$51 = buildMatchFn;
var _index2$2t = buildMatchPatternFn;
var matchOrdinalNumberPattern$18 = /^(\d+)(-?[врмт][аи]|-?т?(ен|на)|-?(ев|ева))?/i;
var parseOrdinalNumberPattern$18 = /\d+/i;
var matchEraPatterns$18 = {
  narrow: /^((пр)?н\.?\s?е\.?)/i,
  abbreviated: /^((пр)?н\.?\s?е\.?)/i,
  wide: /^(преди новата ера|новата ера|нова ера)/i
};
var parseEraPatterns$18 = {
  any: [/^п/i, /^н/i]
};
var matchQuarterPatterns$18 = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234](-?[врт]?o?)? тримес.?/i,
  wide: /^[1234](-?[врт]?о?)? тримесечие/i
};
var parseQuarterPatterns$18 = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchDayPatterns$18 = {
  narrow: /^[нпвсч]/i,
  short: /^(нд|пн|вт|ср|чт|пт|сб)/i,
  abbreviated: /^(нед|пон|вто|сря|чет|пет|съб)/i,
  wide: /^(неделя|понеделник|вторник|сряда|четвъртък|петък|събота)/i
};
var parseDayPatterns$18 = {
  narrow: [/^н/i, /^п/i, /^в/i, /^с/i, /^ч/i, /^п/i, /^с/i],
  any: [/^н[ед]/i, /^п[он]/i, /^вт/i, /^ср/i, /^ч[ет]/i, /^п[ет]/i, /^с[ъб]/i]
};
var matchMonthPatterns$18 = {
  abbreviated: /^(яну|фев|мар|апр|май|юни|юли|авг|сеп|окт|ное|дек)/i,
  wide: /^(януари|февруари|март|април|май|юни|юли|август|септември|октомври|ноември|декември)/i
};
var parseMonthPatterns$18 = {
  any: [/^я/i, /^ф/i, /^мар/i, /^ап/i, /^май/i, /^юн/i, /^юл/i, /^ав/i, /^се/i, /^окт/i, /^но/i, /^де/i]
};
var matchDayPeriodPatterns$18 = {
  any: /^(преди о|след о|в по|на о|през|веч|сут|следо)/i
};
var parseDayPeriodPatterns$18 = {
  any: {
    am: /^преди о/i,
    pm: /^след о/i,
    midnight: /^в пол/i,
    noon: /^на об/i,
    morning: /^сут/i,
    afternoon: /^следо/i,
    evening: /^веч/i,
    night: /^през н/i
  }
};
match$18.match = {
  ordinalNumber: (0, _index2$2t.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$18,
    parsePattern: parseOrdinalNumberPattern$18,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$51.buildMatchFn)({
    matchPatterns: matchEraPatterns$18,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$18,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$51.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$18,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$18,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$51.buildMatchFn)({
    matchPatterns: matchMonthPatterns$18,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$18,
    defaultParseWidth: "any"
  }),
  day: (0, _index$51.buildMatchFn)({
    matchPatterns: matchDayPatterns$18,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$18,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$51.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$18,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$18,
    defaultParseWidth: "any"
  })
};
bg.bg = void 0;
var _index$50 = formatDistance$2j;
var _index2$2s = formatLong$1h;
var _index3$1k = formatRelative$2j;
var _index4$1j = localize$19;
var _index5$1j = match$18;
bg.bg = {
  code: "bg",
  formatDistance: _index$50.formatDistance,
  formatLong: _index2$2s.formatLong,
  formatRelative: _index3$1k.formatRelative,
  localize: _index4$1j.localize,
  match: _index5$1j.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var bn = {};
var formatDistance$2h = {};
var localize$18 = {};
localize$18.localize = void 0;
localize$18.numberToLocale = numberToLocale$1;
var _index$4$ = buildLocalizeFn;
var numberValues$1 = {
  locale: {
    1: "১",
    2: "২",
    3: "৩",
    4: "৪",
    5: "৫",
    6: "৬",
    7: "৭",
    8: "৮",
    9: "৯",
    0: "০"
  },
  number: {
    "১": "1",
    "২": "2",
    "৩": "3",
    "৪": "4",
    "৫": "5",
    "৬": "6",
    "৭": "7",
    "৮": "8",
    "৯": "9",
    "০": "0"
  }
};
var eraValues$18 = {
  narrow: ["খ্রিঃপূঃ", "খ্রিঃ"],
  abbreviated: ["খ্রিঃপূর্ব", "খ্রিঃ"],
  wide: ["খ্রিস্টপূর্ব", "খ্রিস্টাব্দ"]
};
var quarterValues$18 = {
  narrow: ["১", "২", "৩", "৪"],
  abbreviated: ["১ত্রৈ", "২ত্রৈ", "৩ত্রৈ", "৪ত্রৈ"],
  wide: ["১ম ত্রৈমাসিক", "২য় ত্রৈমাসিক", "৩য় ত্রৈমাসিক", "৪র্থ ত্রৈমাসিক"]
};
var monthValues$18 = {
  narrow: ["জানু", "ফেব্রু", "মার্চ", "এপ্রিল", "মে", "জুন", "জুলাই", "আগস্ট", "সেপ্ট", "অক্টো", "নভে", "ডিসে"],
  abbreviated: ["জানু", "ফেব্রু", "মার্চ", "এপ্রিল", "মে", "জুন", "জুলাই", "আগস্ট", "সেপ্ট", "অক্টো", "নভে", "ডিসে"],
  wide: ["জানুয়ারি", "ফেব্রুয়ারি", "মার্চ", "এপ্রিল", "মে", "জুন", "জুলাই", "আগস্ট", "সেপ্টেম্বর", "অক্টোবর", "নভেম্বর", "ডিসেম্বর"]
};
var dayValues$18 = {
  narrow: ["র", "সো", "ম", "বু", "বৃ", "শু", "শ"],
  short: ["রবি", "সোম", "মঙ্গল", "বুধ", "বৃহ", "শুক্র", "শনি"],
  abbreviated: ["রবি", "সোম", "মঙ্গল", "বুধ", "বৃহ", "শুক্র", "শনি"],
  wide: ["রবিবার", "সোমবার", "মঙ্গলবার", "বুধবার", "বৃহস্পতিবার ", "শুক্রবার", "শনিবার"]
};
var dayPeriodValues$18 = {
  narrow: {
    am: "পূ",
    pm: "অপ",
    midnight: "মধ্যরাত",
    noon: "মধ্যাহ্ন",
    morning: "সকাল",
    afternoon: "বিকাল",
    evening: "সন্ধ্যা",
    night: "রাত"
  },
  abbreviated: {
    am: "পূর্বাহ্ন",
    pm: "অপরাহ্ন",
    midnight: "মধ্যরাত",
    noon: "মধ্যাহ্ন",
    morning: "সকাল",
    afternoon: "বিকাল",
    evening: "সন্ধ্যা",
    night: "রাত"
  },
  wide: {
    am: "পূর্বাহ্ন",
    pm: "অপরাহ্ন",
    midnight: "মধ্যরাত",
    noon: "মধ্যাহ্ন",
    morning: "সকাল",
    afternoon: "বিকাল",
    evening: "সন্ধ্যা",
    night: "রাত"
  }
};
var formattingDayPeriodValues$V = {
  narrow: {
    am: "পূ",
    pm: "অপ",
    midnight: "মধ্যরাত",
    noon: "মধ্যাহ্ন",
    morning: "সকাল",
    afternoon: "বিকাল",
    evening: "সন্ধ্যা",
    night: "রাত"
  },
  abbreviated: {
    am: "পূর্বাহ্ন",
    pm: "অপরাহ্ন",
    midnight: "মধ্যরাত",
    noon: "মধ্যাহ্ন",
    morning: "সকাল",
    afternoon: "বিকাল",
    evening: "সন্ধ্যা",
    night: "রাত"
  },
  wide: {
    am: "পূর্বাহ্ন",
    pm: "অপরাহ্ন",
    midnight: "মধ্যরাত",
    noon: "মধ্যাহ্ন",
    morning: "সকাল",
    afternoon: "বিকাল",
    evening: "সন্ধ্যা",
    night: "রাত"
  }
};
function dateOrdinalNumber(e, a) {
  if (e > 18 && e <= 31) {
    return a + "শে";
  } else {
    switch (e) {
      case 1:
        return a + "লা";
      case 2:
      case 3:
        return a + "রা";
      case 4:
        return a + "ঠা";
      default:
        return a + "ই";
    }
  }
}
var ordinalNumber$18 = function (e, a) {
  var t = Number(e);
  var r = numberToLocale$1(t);
  var n = a === null || a === void 0 ? void 0 : a.unit;
  if (n === "date") {
    return dateOrdinalNumber(t, r);
  }
  if (t > 10 || t === 0) return r + "তম";
  var i = t % 10;
  switch (i) {
    case 2:
    case 3:
      return r + "য়";
    case 4:
      return r + "র্থ";
    case 6:
      return r + "ষ্ঠ";
    default:
      return r + "ম";
  }
};
function numberToLocale$1(e) {
  return e.toString().replace(/\d/g, function (e) {
    return numberValues$1.locale[e];
  });
}
localize$18.localize = {
  ordinalNumber: ordinalNumber$18,
  era: (0, _index$4$.buildLocalizeFn)({
    values: eraValues$18,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$4$.buildLocalizeFn)({
    values: quarterValues$18,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$4$.buildLocalizeFn)({
    values: monthValues$18,
    defaultWidth: "wide"
  }),
  day: (0, _index$4$.buildLocalizeFn)({
    values: dayValues$18,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$4$.buildLocalizeFn)({
    values: dayPeriodValues$18,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$V,
    defaultFormattingWidth: "wide"
  })
};
formatDistance$2h.formatDistance = void 0;
var _index$4_ = localize$18;
var formatDistanceLocale$17 = {
  lessThanXSeconds: {
    one: "প্রায় ১ সেকেন্ড",
    other: "প্রায় {{count}} সেকেন্ড"
  },
  xSeconds: {
    one: "১ সেকেন্ড",
    other: "{{count}} সেকেন্ড"
  },
  halfAMinute: "আধ মিনিট",
  lessThanXMinutes: {
    one: "প্রায় ১ মিনিট",
    other: "প্রায় {{count}} মিনিট"
  },
  xMinutes: {
    one: "১ মিনিট",
    other: "{{count}} মিনিট"
  },
  aboutXHours: {
    one: "প্রায় ১ ঘন্টা",
    other: "প্রায় {{count}} ঘন্টা"
  },
  xHours: {
    one: "১ ঘন্টা",
    other: "{{count}} ঘন্টা"
  },
  xDays: {
    one: "১ দিন",
    other: "{{count}} দিন"
  },
  aboutXWeeks: {
    one: "প্রায় ১ সপ্তাহ",
    other: "প্রায় {{count}} সপ্তাহ"
  },
  xWeeks: {
    one: "১ সপ্তাহ",
    other: "{{count}} সপ্তাহ"
  },
  aboutXMonths: {
    one: "প্রায় ১ মাস",
    other: "প্রায় {{count}} মাস"
  },
  xMonths: {
    one: "১ মাস",
    other: "{{count}} মাস"
  },
  aboutXYears: {
    one: "প্রায় ১ বছর",
    other: "প্রায় {{count}} বছর"
  },
  xYears: {
    one: "১ বছর",
    other: "{{count}} বছর"
  },
  overXYears: {
    one: "১ বছরের বেশি",
    other: "{{count}} বছরের বেশি"
  },
  almostXYears: {
    one: "প্রায় ১ বছর",
    other: "প্রায় {{count}} বছর"
  }
};
var formatDistance$2g = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$17[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", (0, _index$4_.numberToLocale)(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + " এর মধ্যে";
    } else {
      return r + " আগে";
    }
  }
  return r;
};
formatDistance$2h.formatDistance = formatDistance$2g;
var formatLong$1g = {};
formatLong$1g.formatLong = void 0;
var _index$4Z = buildFormatLongFn;
var dateFormats$1g = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
};
var timeFormats$1g = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$1g = {
  full: "{{date}} {{time}} 'সময়'",
  long: "{{date}} {{time}} 'সময়'",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1g.formatLong = {
  date: (0, _index$4Z.buildFormatLongFn)({
    formats: dateFormats$1g,
    defaultWidth: "full"
  }),
  time: (0, _index$4Z.buildFormatLongFn)({
    formats: timeFormats$1g,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4Z.buildFormatLongFn)({
    formats: dateTimeFormats$1g,
    defaultWidth: "full"
  })
};
var formatRelative$2h = {};
formatRelative$2h.formatRelative = void 0;
var formatRelativeLocale$18 = {
  lastWeek: "'গত' eeee 'সময়' p",
  yesterday: "'গতকাল' 'সময়' p",
  today: "'আজ' 'সময়' p",
  tomorrow: "'আগামীকাল' 'সময়' p",
  nextWeek: "eeee 'সময়' p",
  other: "P"
};
var formatRelative$2g = function (e, a, t, r) {
  return formatRelativeLocale$18[e];
};
formatRelative$2h.formatRelative = formatRelative$2g;
var match$17 = {};
match$17.match = void 0;
var _index$4Y = buildMatchFn;
var _index2$2r = buildMatchPatternFn;
var matchOrdinalNumberPattern$17 = /^(\d+)(ম|য়|র্থ|ষ্ঠ|শে|ই|তম)?/i;
var parseOrdinalNumberPattern$17 = /\d+/i;
var matchEraPatterns$17 = {
  narrow: /^(খ্রিঃপূঃ|খ্রিঃ)/i,
  abbreviated: /^(খ্রিঃপূর্ব|খ্রিঃ)/i,
  wide: /^(খ্রিস্টপূর্ব|খ্রিস্টাব্দ)/i
};
var parseEraPatterns$17 = {
  narrow: [/^খ্রিঃপূঃ/i, /^খ্রিঃ/i],
  abbreviated: [/^খ্রিঃপূর্ব/i, /^খ্রিঃ/i],
  wide: [/^খ্রিস্টপূর্ব/i, /^খ্রিস্টাব্দ/i]
};
var matchQuarterPatterns$17 = {
  narrow: /^[১২৩৪]/i,
  abbreviated: /^[১২৩৪]ত্রৈ/i,
  wide: /^[১২৩৪](ম|য়|র্থ)? ত্রৈমাসিক/i
};
var parseQuarterPatterns$17 = {
  any: [/১/i, /২/i, /৩/i, /৪/i]
};
var matchMonthPatterns$17 = {
  narrow: /^(জানু|ফেব্রু|মার্চ|এপ্রিল|মে|জুন|জুলাই|আগস্ট|সেপ্ট|অক্টো|নভে|ডিসে)/i,
  abbreviated: /^(জানু|ফেব্রু|মার্চ|এপ্রিল|মে|জুন|জুলাই|আগস্ট|সেপ্ট|অক্টো|নভে|ডিসে)/i,
  wide: /^(জানুয়ারি|ফেব্রুয়ারি|মার্চ|এপ্রিল|মে|জুন|জুলাই|আগস্ট|সেপ্টেম্বর|অক্টোবর|নভেম্বর|ডিসেম্বর)/i
};
var parseMonthPatterns$17 = {
  any: [/^জানু/i, /^ফেব্রু/i, /^মার্চ/i, /^এপ্রিল/i, /^মে/i, /^জুন/i, /^জুলাই/i, /^আগস্ট/i, /^সেপ্ট/i, /^অক্টো/i, /^নভে/i, /^ডিসে/i]
};
var matchDayPatterns$17 = {
  narrow: /^(র|সো|ম|বু|বৃ|শু|শ)+/i,
  short: /^(রবি|সোম|মঙ্গল|বুধ|বৃহ|শুক্র|শনি)+/i,
  abbreviated: /^(রবি|সোম|মঙ্গল|বুধ|বৃহ|শুক্র|শনি)+/i,
  wide: /^(রবিবার|সোমবার|মঙ্গলবার|বুধবার|বৃহস্পতিবার |শুক্রবার|শনিবার)+/i
};
var parseDayPatterns$17 = {
  narrow: [/^র/i, /^সো/i, /^ম/i, /^বু/i, /^বৃ/i, /^শু/i, /^শ/i],
  short: [/^রবি/i, /^সোম/i, /^মঙ্গল/i, /^বুধ/i, /^বৃহ/i, /^শুক্র/i, /^শনি/i],
  abbreviated: [/^রবি/i, /^সোম/i, /^মঙ্গল/i, /^বুধ/i, /^বৃহ/i, /^শুক্র/i, /^শনি/i],
  wide: [/^রবিবার/i, /^সোমবার/i, /^মঙ্গলবার/i, /^বুধবার/i, /^বৃহস্পতিবার /i, /^শুক্রবার/i, /^শনিবার/i]
};
var matchDayPeriodPatterns$17 = {
  narrow: /^(পূ|অপ|মধ্যরাত|মধ্যাহ্ন|সকাল|বিকাল|সন্ধ্যা|রাত)/i,
  abbreviated: /^(পূর্বাহ্ন|অপরাহ্ন|মধ্যরাত|মধ্যাহ্ন|সকাল|বিকাল|সন্ধ্যা|রাত)/i,
  wide: /^(পূর্বাহ্ন|অপরাহ্ন|মধ্যরাত|মধ্যাহ্ন|সকাল|বিকাল|সন্ধ্যা|রাত)/i
};
var parseDayPeriodPatterns$17 = {
  any: {
    am: /^পূ/i,
    pm: /^অপ/i,
    midnight: /^মধ্যরাত/i,
    noon: /^মধ্যাহ্ন/i,
    morning: /সকাল/i,
    afternoon: /বিকাল/i,
    evening: /সন্ধ্যা/i,
    night: /রাত/i
  }
};
match$17.match = {
  ordinalNumber: (0, _index2$2r.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$17,
    parsePattern: parseOrdinalNumberPattern$17,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$4Y.buildMatchFn)({
    matchPatterns: matchEraPatterns$17,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$17,
    defaultParseWidth: "wide"
  }),
  quarter: (0, _index$4Y.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$17,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$17,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$4Y.buildMatchFn)({
    matchPatterns: matchMonthPatterns$17,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$17,
    defaultParseWidth: "any"
  }),
  day: (0, _index$4Y.buildMatchFn)({
    matchPatterns: matchDayPatterns$17,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$17,
    defaultParseWidth: "wide"
  }),
  dayPeriod: (0, _index$4Y.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$17,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$17,
    defaultParseWidth: "any"
  })
};
bn.bn = void 0;
var _index$4X = formatDistance$2h;
var _index2$2q = formatLong$1g;
var _index3$1j = formatRelative$2h;
var _index4$1i = localize$18;
var _index5$1i = match$17;
bn.bn = {
  code: "bn",
  formatDistance: _index$4X.formatDistance,
  formatLong: _index2$2q.formatLong,
  formatRelative: _index3$1j.formatRelative,
  localize: _index4$1i.localize,
  match: _index5$1i.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var bs = {};
var formatDistance$2f = {};
formatDistance$2f.formatDistance = void 0;
var formatDistanceLocale$16 = {
  lessThanXSeconds: {
    one: {
      standalone: "manje od 1 sekunde",
      withPrepositionAgo: "manje od 1 sekunde",
      withPrepositionIn: "manje od 1 sekundu"
    },
    dual: "manje od {{count}} sekunde",
    other: "manje od {{count}} sekundi"
  },
  xSeconds: {
    one: {
      standalone: "1 sekunda",
      withPrepositionAgo: "1 sekunde",
      withPrepositionIn: "1 sekundu"
    },
    dual: "{{count}} sekunde",
    other: "{{count}} sekundi"
  },
  halfAMinute: "pola minute",
  lessThanXMinutes: {
    one: {
      standalone: "manje od 1 minute",
      withPrepositionAgo: "manje od 1 minute",
      withPrepositionIn: "manje od 1 minutu"
    },
    dual: "manje od {{count}} minute",
    other: "manje od {{count}} minuta"
  },
  xMinutes: {
    one: {
      standalone: "1 minuta",
      withPrepositionAgo: "1 minute",
      withPrepositionIn: "1 minutu"
    },
    dual: "{{count}} minute",
    other: "{{count}} minuta"
  },
  aboutXHours: {
    one: {
      standalone: "oko 1 sat",
      withPrepositionAgo: "oko 1 sat",
      withPrepositionIn: "oko 1 sat"
    },
    dual: "oko {{count}} sata",
    other: "oko {{count}} sati"
  },
  xHours: {
    one: {
      standalone: "1 sat",
      withPrepositionAgo: "1 sat",
      withPrepositionIn: "1 sat"
    },
    dual: "{{count}} sata",
    other: "{{count}} sati"
  },
  xDays: {
    one: {
      standalone: "1 dan",
      withPrepositionAgo: "1 dan",
      withPrepositionIn: "1 dan"
    },
    dual: "{{count}} dana",
    other: "{{count}} dana"
  },
  aboutXWeeks: {
    one: {
      standalone: "oko 1 sedmicu",
      withPrepositionAgo: "oko 1 sedmicu",
      withPrepositionIn: "oko 1 sedmicu"
    },
    dual: "oko {{count}} sedmice",
    other: "oko {{count}} sedmice"
  },
  xWeeks: {
    one: {
      standalone: "1 sedmicu",
      withPrepositionAgo: "1 sedmicu",
      withPrepositionIn: "1 sedmicu"
    },
    dual: "{{count}} sedmice",
    other: "{{count}} sedmice"
  },
  aboutXMonths: {
    one: {
      standalone: "oko 1 mjesec",
      withPrepositionAgo: "oko 1 mjesec",
      withPrepositionIn: "oko 1 mjesec"
    },
    dual: "oko {{count}} mjeseca",
    other: "oko {{count}} mjeseci"
  },
  xMonths: {
    one: {
      standalone: "1 mjesec",
      withPrepositionAgo: "1 mjesec",
      withPrepositionIn: "1 mjesec"
    },
    dual: "{{count}} mjeseca",
    other: "{{count}} mjeseci"
  },
  aboutXYears: {
    one: {
      standalone: "oko 1 godinu",
      withPrepositionAgo: "oko 1 godinu",
      withPrepositionIn: "oko 1 godinu"
    },
    dual: "oko {{count}} godine",
    other: "oko {{count}} godina"
  },
  xYears: {
    one: {
      standalone: "1 godina",
      withPrepositionAgo: "1 godine",
      withPrepositionIn: "1 godinu"
    },
    dual: "{{count}} godine",
    other: "{{count}} godina"
  },
  overXYears: {
    one: {
      standalone: "preko 1 godinu",
      withPrepositionAgo: "preko 1 godinu",
      withPrepositionIn: "preko 1 godinu"
    },
    dual: "preko {{count}} godine",
    other: "preko {{count}} godina"
  },
  almostXYears: {
    one: {
      standalone: "gotovo 1 godinu",
      withPrepositionAgo: "gotovo 1 godinu",
      withPrepositionIn: "gotovo 1 godinu"
    },
    dual: "gotovo {{count}} godine",
    other: "gotovo {{count}} godina"
  }
};
var formatDistance$2e = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$16[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    if (t === null || t === void 0 ? void 0 : t.addSuffix) {
      if (t.comparison && t.comparison > 0) {
        r = n.one.withPrepositionIn;
      } else {
        r = n.one.withPrepositionAgo;
      }
    } else {
      r = n.one.standalone;
    }
  } else if (a % 10 > 1 && a % 10 < 5 && String(a).substr(-2, 1) !== "1") {
    r = n.dual.replace("{{count}}", String(a));
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "za " + r;
    } else {
      return "prije " + r;
    }
  }
  return r;
};
formatDistance$2f.formatDistance = formatDistance$2e;
var formatLong$1f = {};
formatLong$1f.formatLong = void 0;
var _index$4W = buildFormatLongFn;
var dateFormats$1f = {
  full: "EEEE, d. MMMM yyyy.",
  long: "d. MMMM yyyy.",
  medium: "d. MMM yy.",
  short: "dd. MM. yy."
};
var timeFormats$1f = {
  full: "HH:mm:ss (zzzz)",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$1f = {
  full: "{{date}} 'u' {{time}}",
  long: "{{date}} 'u' {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$1f.formatLong = {
  date: (0, _index$4W.buildFormatLongFn)({
    formats: dateFormats$1f,
    defaultWidth: "full"
  }),
  time: (0, _index$4W.buildFormatLongFn)({
    formats: timeFormats$1f,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4W.buildFormatLongFn)({
    formats: dateTimeFormats$1f,
    defaultWidth: "full"
  })
};
var formatRelative$2f = {};
formatRelative$2f.formatRelative = void 0;
var formatRelativeLocale$17 = {
  lastWeek: function (e) {
    switch (e.getDay()) {
      case 0:
        return "'prošle nedjelje u' p";
      case 3:
        return "'prošle srijede u' p";
      case 6:
        return "'prošle subote u' p";
      default:
        return "'prošli' EEEE 'u' p";
    }
  },
  yesterday: "'juče u' p",
  today: "'danas u' p",
  tomorrow: "'sutra u' p",
  nextWeek: function (e) {
    switch (e.getDay()) {
      case 0:
        return "'sljedeće nedjelje u' p";
      case 3:
        return "'sljedeću srijedu u' p";
      case 6:
        return "'sljedeću subotu u' p";
      default:
        return "'sljedeći' EEEE 'u' p";
    }
  },
  other: "P"
};
var formatRelative$2e = function (e, a, t, r) {
  var n = formatRelativeLocale$17[e];
  if (typeof n === "function") {
    return n(a);
  }
  return n;
};
formatRelative$2f.formatRelative = formatRelative$2e;
var localize$17 = {};
localize$17.localize = void 0;
var _index$4V = buildLocalizeFn;
var eraValues$17 = {
  narrow: ["pr.n.e.", "AD"],
  abbreviated: ["pr. Hr.", "po. Hr."],
  wide: ["Prije Hrista", "Poslije Hrista"]
};
var quarterValues$17 = {
  narrow: ["1.", "2.", "3.", "4."],
  abbreviated: ["1. kv.", "2. kv.", "3. kv.", "4. kv."],
  wide: ["1. kvartal", "2. kvartal", "3. kvartal", "4. kvartal"]
};
var monthValues$17 = {
  narrow: ["1.", "2.", "3.", "4.", "5.", "6.", "7.", "8.", "9.", "10.", "11.", "12."],
  abbreviated: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "avg", "sep", "okt", "nov", "dec"],
  wide: ["januar", "februar", "mart", "april", "maj", "juni", "juli", "avgust", "septembar", "oktobar", "novembar", "decembar"]
};
var formattingMonthValues$g = {
  narrow: ["1.", "2.", "3.", "4.", "5.", "6.", "7.", "8.", "9.", "10.", "11.", "12."],
  abbreviated: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "avg", "sep", "okt", "nov", "dec"],
  wide: ["januar", "februar", "mart", "april", "maj", "juni", "juli", "avgust", "septembar", "oktobar", "novembar", "decembar"]
};
var dayValues$17 = {
  narrow: ["N", "P", "U", "S", "Č", "P", "S"],
  short: ["ned", "pon", "uto", "sre", "čet", "pet", "sub"],
  abbreviated: ["ned", "pon", "uto", "sre", "čet", "pet", "sub"],
  wide: ["nedjelja", "ponedjeljak", "utorak", "srijeda", "četvrtak", "petak", "subota"]
};
var dayPeriodValues$17 = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutru",
    afternoon: "popodne",
    evening: "uveče",
    night: "noću"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutru",
    afternoon: "popodne",
    evening: "uveče",
    night: "noću"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutru",
    afternoon: "poslije podne",
    evening: "uveče",
    night: "noću"
  }
};
var formattingDayPeriodValues$U = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutru",
    afternoon: "popodne",
    evening: "uveče",
    night: "noću"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutru",
    afternoon: "popodne",
    evening: "uveče",
    night: "noću"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutru",
    afternoon: "poslije podne",
    evening: "uveče",
    night: "noću"
  }
};
var ordinalNumber$17 = function (e, a) {
  var t = Number(e);
  return String(t) + ".";
};
localize$17.localize = {
  ordinalNumber: ordinalNumber$17,
  era: (0, _index$4V.buildLocalizeFn)({
    values: eraValues$17,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$4V.buildLocalizeFn)({
    values: quarterValues$17,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$4V.buildLocalizeFn)({
    values: monthValues$17,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$g,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$4V.buildLocalizeFn)({
    values: dayValues$17,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$4V.buildLocalizeFn)({
    values: dayPeriodValues$17,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$U,
    defaultFormattingWidth: "wide"
  })
};
var match$16 = {};
match$16.match = void 0;
var _index$4U = buildMatchFn;
var _index2$2p = buildMatchPatternFn;
var matchOrdinalNumberPattern$16 = /^(\d+)\./i;
var parseOrdinalNumberPattern$16 = /\d+/i;
var matchEraPatterns$16 = {
  narrow: /^(pr\.n\.e\.|AD)/i,
  abbreviated: /^(pr\.\s?Hr\.|po\.\s?Hr\.)/i,
  wide: /^(Prije Hrista|prije nove ere|Poslije Hrista|nova era)/i
};
var parseEraPatterns$16 = {
  any: [/^pr/i, /^(po|nova)/i]
};
var matchQuarterPatterns$16 = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234]\.\s?kv\.?/i,
  wide: /^[1234]\. kvartal/i
};
var parseQuarterPatterns$16 = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$16 = {
  narrow: /^(10|11|12|[123456789])\./i,
  abbreviated: /^(jan|feb|mar|apr|maj|jun|jul|avg|sep|okt|nov|dec)/i,
  wide: /^((januar|januara)|(februar|februara)|(mart|marta)|(april|aprila)|(maj|maja)|(juni|juna)|(juli|jula)|(avgust|avgusta)|(septembar|septembra)|(oktobar|oktobra)|(novembar|novembra)|(decembar|decembra))/i
};
var parseMonthPatterns$16 = {
  narrow: [/^1/i, /^2/i, /^3/i, /^4/i, /^5/i, /^6/i, /^7/i, /^8/i, /^9/i, /^10/i, /^11/i, /^12/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^maj/i, /^jun/i, /^jul/i, /^avg/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$16 = {
  narrow: /^[npusčc]/i,
  short: /^(ned|pon|uto|sre|(čet|cet)|pet|sub)/i,
  abbreviated: /^(ned|pon|uto|sre|(čet|cet)|pet|sub)/i,
  wide: /^(nedjelja|ponedjeljak|utorak|srijeda|(četvrtak|cetvrtak)|petak|subota)/i
};
var parseDayPatterns$16 = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
};
var matchDayPeriodPatterns$16 = {
  any: /^(am|pm|ponoc|ponoć|(po)?podne|uvece|uveče|noću|poslije podne|ujutru)/i
};
var parseDayPeriodPatterns$16 = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^pono/i,
    noon: /^pod/i,
    morning: /jutro/i,
    afternoon: /(poslije\s|po)+podne/i,
    evening: /(uvece|uveče)/i,
    night: /(nocu|noću)/i
  }
};
match$16.match = {
  ordinalNumber: (0, _index2$2p.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$16,
    parsePattern: parseOrdinalNumberPattern$16,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$4U.buildMatchFn)({
    matchPatterns: matchEraPatterns$16,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$16,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$4U.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$16,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$16,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$4U.buildMatchFn)({
    matchPatterns: matchMonthPatterns$16,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$16,
    defaultParseWidth: "any"
  }),
  day: (0, _index$4U.buildMatchFn)({
    matchPatterns: matchDayPatterns$16,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$16,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$4U.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$16,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$16,
    defaultParseWidth: "any"
  })
};
bs.bs = void 0;
var _index$4T = formatDistance$2f;
var _index2$2o = formatLong$1f;
var _index3$1i = formatRelative$2f;
var _index4$1h = localize$17;
var _index5$1h = match$16;
bs.bs = {
  code: "bs",
  formatDistance: _index$4T.formatDistance,
  formatLong: _index2$2o.formatLong,
  formatRelative: _index3$1i.formatRelative,
  localize: _index4$1h.localize,
  match: _index5$1h.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var ca = {};
var formatDistance$2d = {};
formatDistance$2d.formatDistance = void 0;
var formatDistanceLocale$15 = {
  lessThanXSeconds: {
    one: "menys d'un segon",
    eleven: "menys d'onze segons",
    other: "menys de {{count}} segons"
  },
  xSeconds: {
    one: "1 segon",
    other: "{{count}} segons"
  },
  halfAMinute: "mig minut",
  lessThanXMinutes: {
    one: "menys d'un minut",
    eleven: "menys d'onze minuts",
    other: "menys de {{count}} minuts"
  },
  xMinutes: {
    one: "1 minut",
    other: "{{count}} minuts"
  },
  aboutXHours: {
    one: "aproximadament una hora",
    other: "aproximadament {{count}} hores"
  },
  xHours: {
    one: "1 hora",
    other: "{{count}} hores"
  },
  xDays: {
    one: "1 dia",
    other: "{{count}} dies"
  },
  aboutXWeeks: {
    one: "aproximadament una setmana",
    other: "aproximadament {{count}} setmanes"
  },
  xWeeks: {
    one: "1 setmana",
    other: "{{count}} setmanes"
  },
  aboutXMonths: {
    one: "aproximadament un mes",
    other: "aproximadament {{count}} mesos"
  },
  xMonths: {
    one: "1 mes",
    other: "{{count}} mesos"
  },
  aboutXYears: {
    one: "aproximadament un any",
    other: "aproximadament {{count}} anys"
  },
  xYears: {
    one: "1 any",
    other: "{{count}} anys"
  },
  overXYears: {
    one: "més d'un any",
    eleven: "més d'onze anys",
    other: "més de {{count}} anys"
  },
  almostXYears: {
    one: "gairebé un any",
    other: "gairebé {{count}} anys"
  }
};
var formatDistance$2c = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$15[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else if (a === 11 && n.eleven) {
    r = n.eleven;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "en " + r;
    } else {
      return "fa " + r;
    }
  }
  return r;
};
formatDistance$2d.formatDistance = formatDistance$2c;
var formatLong$1e = {};
formatLong$1e.formatLong = void 0;
var _index$4S = buildFormatLongFn;
var dateFormats$1e = {
  full: "EEEE, d 'de' MMMM y",
  long: "d 'de' MMMM y",
  medium: "d MMM y",
  short: "dd/MM/y"
};
var timeFormats$1e = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$1e = {
  full: "{{date}} 'a les' {{time}}",
  long: "{{date}} 'a les' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1e.formatLong = {
  date: (0, _index$4S.buildFormatLongFn)({
    formats: dateFormats$1e,
    defaultWidth: "full"
  }),
  time: (0, _index$4S.buildFormatLongFn)({
    formats: timeFormats$1e,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4S.buildFormatLongFn)({
    formats: dateTimeFormats$1e,
    defaultWidth: "full"
  })
};
var formatRelative$2d = {};
formatRelative$2d.formatRelative = void 0;
var formatRelativeLocale$16 = {
  lastWeek: "'el' eeee 'passat a la' LT",
  yesterday: "'ahir a la' p",
  today: "'avui a la' p",
  tomorrow: "'demà a la' p",
  nextWeek: "eeee 'a la' p",
  other: "P"
};
var formatRelativeLocalePlural$3 = {
  lastWeek: "'el' eeee 'passat a les' p",
  yesterday: "'ahir a les' p",
  today: "'avui a les' p",
  tomorrow: "'demà a les' p",
  nextWeek: "eeee 'a les' p",
  other: "P"
};
var formatRelative$2c = function (e, a, t, r) {
  if (a.getHours() !== 1) {
    return formatRelativeLocalePlural$3[e];
  }
  return formatRelativeLocale$16[e];
};
formatRelative$2d.formatRelative = formatRelative$2c;
var localize$16 = {};
localize$16.localize = void 0;
var _index$4R = buildLocalizeFn;
var eraValues$16 = {
  narrow: ["aC", "dC"],
  abbreviated: ["a. de C.", "d. de C."],
  wide: ["abans de Crist", "després de Crist"]
};
var quarterValues$16 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["T1", "T2", "T3", "T4"],
  wide: ["1r trimestre", "2n trimestre", "3r trimestre", "4t trimestre"]
};
var monthValues$16 = {
  narrow: ["GN", "FB", "MÇ", "AB", "MG", "JN", "JL", "AG", "ST", "OC", "NV", "DS"],
  abbreviated: ["gen.", "febr.", "març", "abr.", "maig", "juny", "jul.", "ag.", "set.", "oct.", "nov.", "des."],
  wide: ["gener", "febrer", "març", "abril", "maig", "juny", "juliol", "agost", "setembre", "octubre", "novembre", "desembre"]
};
var dayValues$16 = {
  narrow: ["dg.", "dl.", "dt.", "dm.", "dj.", "dv.", "ds."],
  short: ["dg.", "dl.", "dt.", "dm.", "dj.", "dv.", "ds."],
  abbreviated: ["dg.", "dl.", "dt.", "dm.", "dj.", "dv.", "ds."],
  wide: ["diumenge", "dilluns", "dimarts", "dimecres", "dijous", "divendres", "dissabte"]
};
var dayPeriodValues$16 = {
  narrow: {
    am: "am",
    pm: "pm",
    midnight: "mitjanit",
    noon: "migdia",
    morning: "matí",
    afternoon: "tarda",
    evening: "vespre",
    night: "nit"
  },
  abbreviated: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "mitjanit",
    noon: "migdia",
    morning: "matí",
    afternoon: "tarda",
    evening: "vespre",
    night: "nit"
  },
  wide: {
    am: "ante meridiem",
    pm: "post meridiem",
    midnight: "mitjanit",
    noon: "migdia",
    morning: "matí",
    afternoon: "tarda",
    evening: "vespre",
    night: "nit"
  }
};
var formattingDayPeriodValues$T = {
  narrow: {
    am: "am",
    pm: "pm",
    midnight: "de la mitjanit",
    noon: "del migdia",
    morning: "del matí",
    afternoon: "de la tarda",
    evening: "del vespre",
    night: "de la nit"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "de la mitjanit",
    noon: "del migdia",
    morning: "del matí",
    afternoon: "de la tarda",
    evening: "del vespre",
    night: "de la nit"
  },
  wide: {
    am: "ante meridiem",
    pm: "post meridiem",
    midnight: "de la mitjanit",
    noon: "del migdia",
    morning: "del matí",
    afternoon: "de la tarda",
    evening: "del vespre",
    night: "de la nit"
  }
};
var ordinalNumber$16 = function (e, a) {
  var t = Number(e);
  var r = t % 100;
  if (r > 20 || r < 10) {
    switch (r % 10) {
      case 1:
        return t + "r";
      case 2:
        return t + "n";
      case 3:
        return t + "r";
      case 4:
        return t + "t";
    }
  }
  return t + "è";
};
localize$16.localize = {
  ordinalNumber: ordinalNumber$16,
  era: (0, _index$4R.buildLocalizeFn)({
    values: eraValues$16,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$4R.buildLocalizeFn)({
    values: quarterValues$16,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$4R.buildLocalizeFn)({
    values: monthValues$16,
    defaultWidth: "wide"
  }),
  day: (0, _index$4R.buildLocalizeFn)({
    values: dayValues$16,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$4R.buildLocalizeFn)({
    values: dayPeriodValues$16,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$T,
    defaultFormattingWidth: "wide"
  })
};
var match$15 = {};
match$15.match = void 0;
var _index$4Q = buildMatchFn;
var _index2$2n = buildMatchPatternFn;
var matchOrdinalNumberPattern$15 = /^(\d+)(è|r|n|r|t)?/i;
var parseOrdinalNumberPattern$15 = /\d+/i;
var matchEraPatterns$15 = {
  narrow: /^(aC|dC)/i,
  abbreviated: /^(a. de C.|d. de C.)/i,
  wide: /^(abans de Crist|despr[eé]s de Crist)/i
};
var parseEraPatterns$15 = {
  narrow: [/^aC/i, /^dC/i],
  abbreviated: [/^(a. de C.)/i, /^(d. de C.)/i],
  wide: [/^(abans de Crist)/i, /^(despr[eé]s de Crist)/i]
};
var matchQuarterPatterns$15 = {
  narrow: /^[1234]/i,
  abbreviated: /^T[1234]/i,
  wide: /^[1234](è|r|n|r|t)? trimestre/i
};
var parseQuarterPatterns$15 = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$15 = {
  narrow: /^(GN|FB|MÇ|AB|MG|JN|JL|AG|ST|OC|NV|DS)/i,
  abbreviated: /^(gen.|febr.|març|abr.|maig|juny|jul.|ag.|set.|oct.|nov.|des.)/i,
  wide: /^(gener|febrer|març|abril|maig|juny|juliol|agost|setembre|octubre|novembre|desembre)/i
};
var parseMonthPatterns$15 = {
  narrow: [/^GN/i, /^FB/i, /^MÇ/i, /^AB/i, /^MG/i, /^JN/i, /^JL/i, /^AG/i, /^ST/i, /^OC/i, /^NV/i, /^DS/i],
  abbreviated: [/^gen./i, /^febr./i, /^març/i, /^abr./i, /^maig/i, /^juny/i, /^jul./i, /^ag./i, /^set./i, /^oct./i, /^nov./i, /^des./i],
  wide: [/^gener/i, /^febrer/i, /^març/i, /^abril/i, /^maig/i, /^juny/i, /^juliol/i, /^agost/i, /^setembre/i, /^octubre/i, /^novembre/i, /^desembre/i]
};
var matchDayPatterns$15 = {
  narrow: /^(dg\.|dl\.|dt\.|dm\.|dj\.|dv\.|ds\.)/i,
  short: /^(dg\.|dl\.|dt\.|dm\.|dj\.|dv\.|ds\.)/i,
  abbreviated: /^(dg\.|dl\.|dt\.|dm\.|dj\.|dv\.|ds\.)/i,
  wide: /^(diumenge|dilluns|dimarts|dimecres|dijous|divendres|dissabte)/i
};
var parseDayPatterns$15 = {
  narrow: [/^dg./i, /^dl./i, /^dt./i, /^dm./i, /^dj./i, /^dv./i, /^ds./i],
  abbreviated: [/^dg./i, /^dl./i, /^dt./i, /^dm./i, /^dj./i, /^dv./i, /^ds./i],
  wide: [/^diumenge/i, /^dilluns/i, /^dimarts/i, /^dimecres/i, /^dijous/i, /^divendres/i, /^disssabte/i]
};
var matchDayPeriodPatterns$15 = {
  narrow: /^(a|p|mn|md|(del|de la) (matí|tarda|vespre|nit))/i,
  abbreviated: /^([ap]\.?\s?m\.?|mitjanit|migdia|(del|de la) (matí|tarda|vespre|nit))/i,
  wide: /^(ante meridiem|post meridiem|mitjanit|migdia|(del|de la) (matí|tarda|vespre|nit))/i
};
var parseDayPeriodPatterns$15 = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mitjanit/i,
    noon: /^migdia/i,
    morning: /matí/i,
    afternoon: /tarda/i,
    evening: /vespre/i,
    night: /nit/i
  }
};
match$15.match = {
  ordinalNumber: (0, _index2$2n.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$15,
    parsePattern: parseOrdinalNumberPattern$15,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$4Q.buildMatchFn)({
    matchPatterns: matchEraPatterns$15,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$15,
    defaultParseWidth: "wide"
  }),
  quarter: (0, _index$4Q.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$15,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$15,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$4Q.buildMatchFn)({
    matchPatterns: matchMonthPatterns$15,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$15,
    defaultParseWidth: "wide"
  }),
  day: (0, _index$4Q.buildMatchFn)({
    matchPatterns: matchDayPatterns$15,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$15,
    defaultParseWidth: "wide"
  }),
  dayPeriod: (0, _index$4Q.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$15,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$15,
    defaultParseWidth: "any"
  })
};
ca.ca = void 0;
var _index$4P = formatDistance$2d;
var _index2$2m = formatLong$1e;
var _index3$1h = formatRelative$2d;
var _index4$1g = localize$16;
var _index5$1g = match$15;
ca.ca = {
  code: "ca",
  formatDistance: _index$4P.formatDistance,
  formatLong: _index2$2m.formatLong,
  formatRelative: _index3$1h.formatRelative,
  localize: _index4$1g.localize,
  match: _index5$1g.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var ckb = {};
var formatDistance$2b = {};
formatDistance$2b.formatDistance = void 0;
var formatDistanceLocale$14 = {
  lessThanXSeconds: {
    one: "کەمتر لە یەک چرکە",
    other: "کەمتر لە {{count}} چرکە"
  },
  xSeconds: {
    one: "1 چرکە",
    other: "{{count}} چرکە"
  },
  halfAMinute: "نیو کاتژمێر",
  lessThanXMinutes: {
    one: "کەمتر لە یەک خولەک",
    other: "کەمتر لە {{count}} خولەک"
  },
  xMinutes: {
    one: "1 خولەک",
    other: "{{count}} خولەک"
  },
  aboutXHours: {
    one: "دەوروبەری 1 کاتژمێر",
    other: "دەوروبەری {{count}} کاتژمێر"
  },
  xHours: {
    one: "1 کاتژمێر",
    other: "{{count}} کاتژمێر"
  },
  xDays: {
    one: "1 ڕۆژ",
    other: "{{count}} ژۆژ"
  },
  aboutXWeeks: {
    one: "دەوروبەری 1 هەفتە",
    other: "دوروبەری {{count}} هەفتە"
  },
  xWeeks: {
    one: "1 هەفتە",
    other: "{{count}} هەفتە"
  },
  aboutXMonths: {
    one: "داوروبەری 1 مانگ",
    other: "دەوروبەری {{count}} مانگ"
  },
  xMonths: {
    one: "1 مانگ",
    other: "{{count}} مانگ"
  },
  aboutXYears: {
    one: "دەوروبەری  1 ساڵ",
    other: "دەوروبەری {{count}} ساڵ"
  },
  xYears: {
    one: "1 ساڵ",
    other: "{{count}} ساڵ"
  },
  overXYears: {
    one: "زیاتر لە ساڵێک",
    other: "زیاتر لە {{count}} ساڵ"
  },
  almostXYears: {
    one: "بەنزیکەیی ساڵێک  ",
    other: "بەنزیکەیی {{count}} ساڵ"
  }
};
var formatDistance$2a = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$14[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", a.toString());
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "لە ماوەی " + r + "دا";
    } else {
      return r + "پێش ئێستا";
    }
  }
  return r;
};
formatDistance$2b.formatDistance = formatDistance$2a;
var formatLong$1d = {};
formatLong$1d.formatLong = void 0;
var _index$4O = buildFormatLongFn;
var dateFormats$1d = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
};
var timeFormats$1d = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$1d = {
  full: "{{date}} 'کاتژمێر' {{time}}",
  long: "{{date}} 'کاتژمێر' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1d.formatLong = {
  date: (0, _index$4O.buildFormatLongFn)({
    formats: dateFormats$1d,
    defaultWidth: "full"
  }),
  time: (0, _index$4O.buildFormatLongFn)({
    formats: timeFormats$1d,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4O.buildFormatLongFn)({
    formats: dateTimeFormats$1d,
    defaultWidth: "full"
  })
};
var formatRelative$2b = {};
formatRelative$2b.formatRelative = void 0;
var formatRelativeLocale$15 = {
  lastWeek: "'هەفتەی ڕابردوو' eeee 'کاتژمێر' p",
  yesterday: "'دوێنێ کاتژمێر' p",
  today: "'ئەمڕۆ کاتژمێر' p",
  tomorrow: "'بەیانی کاتژمێر' p",
  nextWeek: "eeee 'کاتژمێر' p",
  other: "P"
};
var formatRelative$2a = function (e, a, t, r) {
  return formatRelativeLocale$15[e];
};
formatRelative$2b.formatRelative = formatRelative$2a;
var localize$15 = {};
localize$15.localize = void 0;
var _index$4N = buildLocalizeFn;
var eraValues$15 = {
  narrow: ["پ", "د"],
  abbreviated: ["پ-ز", "د-ز"],
  wide: ["پێش زاین", "دوای زاین"]
};
var quarterValues$15 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["چ1م", "چ2م", "چ3م", "چ4م"],
  wide: ["چارەگی یەکەم", "چارەگی دووەم", "چارەگی سێیەم", "چارەگی چوارەم"]
};
var monthValues$15 = {
  narrow: ["ک-د", "ش", "ئا", "ن", "م", "ح", "ت", "ئا", "ئە", "تش-ی", "تش-د", "ک-ی"],
  abbreviated: ["کان-دوو", "شوب", "ئاد", "نیس", "مایس", "حوز", "تەم", "ئاب", "ئەل", "تش-یەک", "تش-دوو", "کان-یەک"],
  wide: ["کانوونی دووەم", "شوبات", "ئادار", "نیسان", "مایس", "حوزەیران", "تەمموز", "ئاب", "ئەیلول", "تشرینی یەکەم", "تشرینی دووەم", "کانوونی یەکەم"]
};
var dayValues$15 = {
  narrow: ["ی-ش", "د-ش", "س-ش", "چ-ش", "پ-ش", "هە", "ش"],
  short: ["یە-شە", "دوو-شە", "سێ-شە", "چو-شە", "پێ-شە", "هەی", "شە"],
  abbreviated: ["یەک-شەم", "دوو-شەم", "سێ-شەم", "چوار-شەم", "پێنج-شەم", "هەینی", "شەمە"],
  wide: ["یەک شەمە", "دوو شەمە", "سێ شەمە", "چوار شەمە", "پێنج شەمە", "هەینی", "شەمە"]
};
var dayPeriodValues$15 = {
  narrow: {
    am: "پ",
    pm: "د",
    midnight: "ن-ش",
    noon: "ن",
    morning: "بەیانی",
    afternoon: "دوای نیوەڕۆ",
    evening: "ئێوارە",
    night: "شەو"
  },
  abbreviated: {
    am: "پ-ن",
    pm: "د-ن",
    midnight: "نیوە شەو",
    noon: "نیوەڕۆ",
    morning: "بەیانی",
    afternoon: "دوای نیوەڕۆ",
    evening: "ئێوارە",
    night: "شەو"
  },
  wide: {
    am: "پێش نیوەڕۆ",
    pm: "دوای نیوەڕۆ",
    midnight: "نیوە شەو",
    noon: "نیوەڕۆ",
    morning: "بەیانی",
    afternoon: "دوای نیوەڕۆ",
    evening: "ئێوارە",
    night: "شەو"
  }
};
var formattingDayPeriodValues$S = {
  narrow: {
    am: "پ",
    pm: "د",
    midnight: "ن-ش",
    noon: "ن",
    morning: "لە بەیانیدا",
    afternoon: "لە دوای نیوەڕۆدا",
    evening: "لە ئێوارەدا",
    night: "لە شەودا"
  },
  abbreviated: {
    am: "پ-ن",
    pm: "د-ن",
    midnight: "نیوە شەو",
    noon: "نیوەڕۆ",
    morning: "لە بەیانیدا",
    afternoon: "لە دوای نیوەڕۆدا",
    evening: "لە ئێوارەدا",
    night: "لە شەودا"
  },
  wide: {
    am: "پێش نیوەڕۆ",
    pm: "دوای نیوەڕۆ",
    midnight: "نیوە شەو",
    noon: "نیوەڕۆ",
    morning: "لە بەیانیدا",
    afternoon: "لە دوای نیوەڕۆدا",
    evening: "لە ئێوارەدا",
    night: "لە شەودا"
  }
};
var ordinalNumber$15 = function (e, a) {
  return String(e);
};
localize$15.localize = {
  ordinalNumber: ordinalNumber$15,
  era: (0, _index$4N.buildLocalizeFn)({
    values: eraValues$15,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$4N.buildLocalizeFn)({
    values: quarterValues$15,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$4N.buildLocalizeFn)({
    values: monthValues$15,
    defaultWidth: "wide"
  }),
  day: (0, _index$4N.buildLocalizeFn)({
    values: dayValues$15,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$4N.buildLocalizeFn)({
    values: dayPeriodValues$15,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$S,
    defaultFormattingWidth: "wide"
  })
};
var match$14 = {};
match$14.match = void 0;
var _index$4M = buildMatchFn;
var _index2$2l = buildMatchPatternFn;
var matchOrdinalNumberPattern$14 = /^(\d+)(th|st|nd|rd)?/i;
var parseOrdinalNumberPattern$14 = /\d+/i;
var matchEraPatterns$14 = {
  narrow: /^(پ|د)/i,
  abbreviated: /^(پ-ز|د.ز)/i,
  wide: /^(پێش زاین| دوای زاین)/i
};
var parseEraPatterns$14 = {
  any: [/^د/g, /^پ/g]
};
var matchQuarterPatterns$14 = {
  narrow: /^[1234]/i,
  abbreviated: /^م[1234]چ/i,
  wide: /^(یەکەم|دووەم|سێیەم| چوارەم) (چارەگی)? quarter/i
};
var parseQuarterPatterns$14 = {
  wide: [/چارەگی یەکەم/, /چارەگی دووەم/, /چارەگی سيیەم/, /چارەگی چوارەم/],
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$14 = {
  narrow: /^(ک-د|ش|ئا|ن|م|ح|ت|ئە|تش-ی|تش-د|ک-ی)/i,
  abbreviated: /^(کان-دوو|شوب|ئاد|نیس|مایس|حوز|تەم|ئاب|ئەل|تش-یەک|تش-دوو|کان-یەک)/i,
  wide: /^(کانوونی دووەم|شوبات|ئادار|نیسان|مایس|حوزەیران|تەمموز|ئاب|ئەیلول|تشرینی یەکەم|تشرینی دووەم|کانوونی یەکەم)/i
};
var parseMonthPatterns$14 = {
  narrow: [/^ک-د/i, /^ش/i, /^ئا/i, /^ن/i, /^م/i, /^ح/i, /^ت/i, /^ئا/i, /^ئە/i, /^تش-ی/i, /^تش-د/i, /^ک-ی/i],
  any: [/^کان-دوو/i, /^شوب/i, /^ئاد/i, /^نیس/i, /^مایس/i, /^حوز/i, /^تەم/i, /^ئاب/i, /^ئەل/i, /^تش-یەک/i, /^تش-دوو/i, /^|کان-یەک/i]
};
var matchDayPatterns$14 = {
  narrow: /^(ش|ی|د|س|چ|پ|هە)/i,
  short: /^(یە-شە|دوو-شە|سێ-شە|چو-شە|پێ-شە|هە|شە)/i,
  abbreviated: /^(یەک-شەم|دوو-شەم|سێ-شەم|چوار-شەم|پێنخ-شەم|هەینی|شەمە)/i,
  wide: /^(یەک شەمە|دوو شەمە|سێ شەمە|چوار شەمە|پێنج شەمە|هەینی|شەمە)/i
};
var parseDayPatterns$14 = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
};
var matchDayPeriodPatterns$14 = {
  narrow: /^(پ|د|ن-ش|ن| (بەیانی|دوای نیوەڕۆ|ئێوارە|شەو))/i,
  abbreviated: /^(پ-ن|د-ن|نیوە شەو|نیوەڕۆ|بەیانی|دوای نیوەڕۆ|ئێوارە|شەو)/,
  wide: /^(پێش نیوەڕۆ|دوای نیوەڕۆ|نیوەڕۆ|نیوە شەو|لەبەیانیدا|لەدواینیوەڕۆدا|لە ئێوارەدا|لە شەودا)/,
  any: /^(پ|د|بەیانی|نیوەڕۆ|ئێوارە|شەو)/
};
var parseDayPeriodPatterns$14 = {
  any: {
    am: /^د/i,
    pm: /^پ/i,
    midnight: /^ن-ش/i,
    noon: /^ن/i,
    morning: /بەیانی/i,
    afternoon: /دواینیوەڕۆ/i,
    evening: /ئێوارە/i,
    night: /شەو/i
  }
};
match$14.match = {
  ordinalNumber: (0, _index2$2l.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$14,
    parsePattern: parseOrdinalNumberPattern$14,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$4M.buildMatchFn)({
    matchPatterns: matchEraPatterns$14,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$14,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$4M.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$14,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$14,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$4M.buildMatchFn)({
    matchPatterns: matchMonthPatterns$14,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$14,
    defaultParseWidth: "any"
  }),
  day: (0, _index$4M.buildMatchFn)({
    matchPatterns: matchDayPatterns$14,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$14,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$4M.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$14,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$14,
    defaultParseWidth: "any"
  })
};
ckb.ckb = void 0;
var _index$4L = formatDistance$2b;
var _index2$2k = formatLong$1d;
var _index3$1g = formatRelative$2b;
var _index4$1f = localize$15;
var _index5$1f = match$14;
ckb.ckb = {
  code: "ckb",
  formatDistance: _index$4L.formatDistance,
  formatLong: _index2$2k.formatLong,
  formatRelative: _index3$1g.formatRelative,
  localize: _index4$1f.localize,
  match: _index5$1f.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var cs = {};
var formatDistance$29 = {};
formatDistance$29.formatDistance = void 0;
var formatDistanceLocale$13 = {
  lessThanXSeconds: {
    one: {
      regular: "méně než 1 sekunda",
      past: "před méně než 1 sekundou",
      future: "za méně než 1 sekundu"
    },
    few: {
      regular: "méně než {{count}} sekundy",
      past: "před méně než {{count}} sekundami",
      future: "za méně než {{count}} sekundy"
    },
    many: {
      regular: "méně než {{count}} sekund",
      past: "před méně než {{count}} sekundami",
      future: "za méně než {{count}} sekund"
    }
  },
  xSeconds: {
    one: {
      regular: "1 sekunda",
      past: "před 1 sekundou",
      future: "za 1 sekundu"
    },
    few: {
      regular: "{{count}} sekundy",
      past: "před {{count}} sekundami",
      future: "za {{count}} sekundy"
    },
    many: {
      regular: "{{count}} sekund",
      past: "před {{count}} sekundami",
      future: "za {{count}} sekund"
    }
  },
  halfAMinute: {
    type: "other",
    other: {
      regular: "půl minuty",
      past: "před půl minutou",
      future: "za půl minuty"
    }
  },
  lessThanXMinutes: {
    one: {
      regular: "méně než 1 minuta",
      past: "před méně než 1 minutou",
      future: "za méně než 1 minutu"
    },
    few: {
      regular: "méně než {{count}} minuty",
      past: "před méně než {{count}} minutami",
      future: "za méně než {{count}} minuty"
    },
    many: {
      regular: "méně než {{count}} minut",
      past: "před méně než {{count}} minutami",
      future: "za méně než {{count}} minut"
    }
  },
  xMinutes: {
    one: {
      regular: "1 minuta",
      past: "před 1 minutou",
      future: "za 1 minutu"
    },
    few: {
      regular: "{{count}} minuty",
      past: "před {{count}} minutami",
      future: "za {{count}} minuty"
    },
    many: {
      regular: "{{count}} minut",
      past: "před {{count}} minutami",
      future: "za {{count}} minut"
    }
  },
  aboutXHours: {
    one: {
      regular: "přibližně 1 hodina",
      past: "přibližně před 1 hodinou",
      future: "přibližně za 1 hodinu"
    },
    few: {
      regular: "přibližně {{count}} hodiny",
      past: "přibližně před {{count}} hodinami",
      future: "přibližně za {{count}} hodiny"
    },
    many: {
      regular: "přibližně {{count}} hodin",
      past: "přibližně před {{count}} hodinami",
      future: "přibližně za {{count}} hodin"
    }
  },
  xHours: {
    one: {
      regular: "1 hodina",
      past: "před 1 hodinou",
      future: "za 1 hodinu"
    },
    few: {
      regular: "{{count}} hodiny",
      past: "před {{count}} hodinami",
      future: "za {{count}} hodiny"
    },
    many: {
      regular: "{{count}} hodin",
      past: "před {{count}} hodinami",
      future: "za {{count}} hodin"
    }
  },
  xDays: {
    one: {
      regular: "1 den",
      past: "před 1 dnem",
      future: "za 1 den"
    },
    few: {
      regular: "{{count}} dny",
      past: "před {{count}} dny",
      future: "za {{count}} dny"
    },
    many: {
      regular: "{{count}} dní",
      past: "před {{count}} dny",
      future: "za {{count}} dní"
    }
  },
  aboutXWeeks: {
    one: {
      regular: "přibližně 1 týden",
      past: "přibližně před 1 týdnem",
      future: "přibližně za 1 týden"
    },
    few: {
      regular: "přibližně {{count}} týdny",
      past: "přibližně před {{count}} týdny",
      future: "přibližně za {{count}} týdny"
    },
    many: {
      regular: "přibližně {{count}} týdnů",
      past: "přibližně před {{count}} týdny",
      future: "přibližně za {{count}} týdnů"
    }
  },
  xWeeks: {
    one: {
      regular: "1 týden",
      past: "před 1 týdnem",
      future: "za 1 týden"
    },
    few: {
      regular: "{{count}} týdny",
      past: "před {{count}} týdny",
      future: "za {{count}} týdny"
    },
    many: {
      regular: "{{count}} týdnů",
      past: "před {{count}} týdny",
      future: "za {{count}} týdnů"
    }
  },
  aboutXMonths: {
    one: {
      regular: "přibližně 1 měsíc",
      past: "přibližně před 1 měsícem",
      future: "přibližně za 1 měsíc"
    },
    few: {
      regular: "přibližně {{count}} měsíce",
      past: "přibližně před {{count}} měsíci",
      future: "přibližně za {{count}} měsíce"
    },
    many: {
      regular: "přibližně {{count}} měsíců",
      past: "přibližně před {{count}} měsíci",
      future: "přibližně za {{count}} měsíců"
    }
  },
  xMonths: {
    one: {
      regular: "1 měsíc",
      past: "před 1 měsícem",
      future: "za 1 měsíc"
    },
    few: {
      regular: "{{count}} měsíce",
      past: "před {{count}} měsíci",
      future: "za {{count}} měsíce"
    },
    many: {
      regular: "{{count}} měsíců",
      past: "před {{count}} měsíci",
      future: "za {{count}} měsíců"
    }
  },
  aboutXYears: {
    one: {
      regular: "přibližně 1 rok",
      past: "přibližně před 1 rokem",
      future: "přibližně za 1 rok"
    },
    few: {
      regular: "přibližně {{count}} roky",
      past: "přibližně před {{count}} roky",
      future: "přibližně za {{count}} roky"
    },
    many: {
      regular: "přibližně {{count}} roků",
      past: "přibližně před {{count}} roky",
      future: "přibližně za {{count}} roků"
    }
  },
  xYears: {
    one: {
      regular: "1 rok",
      past: "před 1 rokem",
      future: "za 1 rok"
    },
    few: {
      regular: "{{count}} roky",
      past: "před {{count}} roky",
      future: "za {{count}} roky"
    },
    many: {
      regular: "{{count}} roků",
      past: "před {{count}} roky",
      future: "za {{count}} roků"
    }
  },
  overXYears: {
    one: {
      regular: "více než 1 rok",
      past: "před více než 1 rokem",
      future: "za více než 1 rok"
    },
    few: {
      regular: "více než {{count}} roky",
      past: "před více než {{count}} roky",
      future: "za více než {{count}} roky"
    },
    many: {
      regular: "více než {{count}} roků",
      past: "před více než {{count}} roky",
      future: "za více než {{count}} roků"
    }
  },
  almostXYears: {
    one: {
      regular: "skoro 1 rok",
      past: "skoro před 1 rokem",
      future: "skoro za 1 rok"
    },
    few: {
      regular: "skoro {{count}} roky",
      past: "skoro před {{count}} roky",
      future: "skoro za {{count}} roky"
    },
    many: {
      regular: "skoro {{count}} roků",
      past: "skoro před {{count}} roky",
      future: "skoro za {{count}} roků"
    }
  }
};
var formatDistance$28 = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$13[e];
  if (n.type === "other") {
    r = n.other;
  } else if (a === 1) {
    r = n.one;
  } else if (a > 1 && a < 5) {
    r = n.few;
  } else {
    r = n.many;
  }
  var i = (t === null || t === void 0 ? void 0 : t.addSuffix) === true;
  var o = t === null || t === void 0 ? void 0 : t.comparison;
  var d;
  if (i && o === -1) {
    d = r.past;
  } else if (i && o === 1) {
    d = r.future;
  } else {
    d = r.regular;
  }
  return d.replace("{{count}}", String(a));
};
formatDistance$29.formatDistance = formatDistance$28;
var formatLong$1c = {};
formatLong$1c.formatLong = void 0;
var _index$4K = buildFormatLongFn;
var dateFormats$1c = {
  full: "EEEE, d. MMMM yyyy",
  long: "d. MMMM yyyy",
  medium: "d. M. yyyy",
  short: "dd.MM.yyyy"
};
var timeFormats$1c = {
  full: "H:mm:ss zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$1c = {
  full: "{{date}} 'v' {{time}}",
  long: "{{date}} 'v' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1c.formatLong = {
  date: (0, _index$4K.buildFormatLongFn)({
    formats: dateFormats$1c,
    defaultWidth: "full"
  }),
  time: (0, _index$4K.buildFormatLongFn)({
    formats: timeFormats$1c,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4K.buildFormatLongFn)({
    formats: dateTimeFormats$1c,
    defaultWidth: "full"
  })
};
var formatRelative$29 = {};
formatRelative$29.formatRelative = void 0;
var accusativeWeekdays$5 = ["neděli", "pondělí", "úterý", "středu", "čtvrtek", "pátek", "sobotu"];
var formatRelativeLocale$14 = {
  lastWeek: "'poslední' eeee 've' p",
  yesterday: "'včera v' p",
  today: "'dnes v' p",
  tomorrow: "'zítra v' p",
  nextWeek: function (e) {
    var a = e.getDay();
    return "'v " + accusativeWeekdays$5[a] + " o' p";
  },
  other: "P"
};
var formatRelative$28 = function (e, a) {
  var t = formatRelativeLocale$14[e];
  if (typeof t === "function") {
    return t(a);
  }
  return t;
};
formatRelative$29.formatRelative = formatRelative$28;
var localize$14 = {};
localize$14.localize = void 0;
var _index$4J = buildLocalizeFn;
var eraValues$14 = {
  narrow: ["př. n. l.", "n. l."],
  abbreviated: ["př. n. l.", "n. l."],
  wide: ["před naším letopočtem", "našeho letopočtu"]
};
var quarterValues$14 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1. čtvrtletí", "2. čtvrtletí", "3. čtvrtletí", "4. čtvrtletí"],
  wide: ["1. čtvrtletí", "2. čtvrtletí", "3. čtvrtletí", "4. čtvrtletí"]
};
var monthValues$14 = {
  narrow: ["L", "Ú", "B", "D", "K", "Č", "Č", "S", "Z", "Ř", "L", "P"],
  abbreviated: ["led", "úno", "bře", "dub", "kvě", "čvn", "čvc", "srp", "zář", "říj", "lis", "pro"],
  wide: ["leden", "únor", "březen", "duben", "květen", "červen", "červenec", "srpen", "září", "říjen", "listopad", "prosinec"]
};
var formattingMonthValues$f = {
  narrow: ["L", "Ú", "B", "D", "K", "Č", "Č", "S", "Z", "Ř", "L", "P"],
  abbreviated: ["led", "úno", "bře", "dub", "kvě", "čvn", "čvc", "srp", "zář", "říj", "lis", "pro"],
  wide: ["ledna", "února", "března", "dubna", "května", "června", "července", "srpna", "září", "října", "listopadu", "prosince"]
};
var dayValues$14 = {
  narrow: ["ne", "po", "út", "st", "čt", "pá", "so"],
  short: ["ne", "po", "út", "st", "čt", "pá", "so"],
  abbreviated: ["ned", "pon", "úte", "stř", "čtv", "pát", "sob"],
  wide: ["neděle", "pondělí", "úterý", "středa", "čtvrtek", "pátek", "sobota"]
};
var dayPeriodValues$14 = {
  narrow: {
    am: "dop.",
    pm: "odp.",
    midnight: "půlnoc",
    noon: "poledne",
    morning: "ráno",
    afternoon: "odpoledne",
    evening: "večer",
    night: "noc"
  },
  abbreviated: {
    am: "dop.",
    pm: "odp.",
    midnight: "půlnoc",
    noon: "poledne",
    morning: "ráno",
    afternoon: "odpoledne",
    evening: "večer",
    night: "noc"
  },
  wide: {
    am: "dopoledne",
    pm: "odpoledne",
    midnight: "půlnoc",
    noon: "poledne",
    morning: "ráno",
    afternoon: "odpoledne",
    evening: "večer",
    night: "noc"
  }
};
var formattingDayPeriodValues$R = {
  narrow: {
    am: "dop.",
    pm: "odp.",
    midnight: "půlnoc",
    noon: "poledne",
    morning: "ráno",
    afternoon: "odpoledne",
    evening: "večer",
    night: "noc"
  },
  abbreviated: {
    am: "dop.",
    pm: "odp.",
    midnight: "půlnoc",
    noon: "poledne",
    morning: "ráno",
    afternoon: "odpoledne",
    evening: "večer",
    night: "noc"
  },
  wide: {
    am: "dopoledne",
    pm: "odpoledne",
    midnight: "půlnoc",
    noon: "poledne",
    morning: "ráno",
    afternoon: "odpoledne",
    evening: "večer",
    night: "noc"
  }
};
var ordinalNumber$14 = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$14.localize = {
  ordinalNumber: ordinalNumber$14,
  era: (0, _index$4J.buildLocalizeFn)({
    values: eraValues$14,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$4J.buildLocalizeFn)({
    values: quarterValues$14,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$4J.buildLocalizeFn)({
    values: monthValues$14,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$f,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$4J.buildLocalizeFn)({
    values: dayValues$14,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$4J.buildLocalizeFn)({
    values: dayPeriodValues$14,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$R,
    defaultFormattingWidth: "wide"
  })
};
var match$13 = {};
match$13.match = void 0;
var _index$4I = buildMatchFn;
var _index2$2j = buildMatchPatternFn;
var matchOrdinalNumberPattern$13 = /^(\d+)\.?/i;
var parseOrdinalNumberPattern$13 = /\d+/i;
var matchEraPatterns$13 = {
  narrow: /^(p[řr](\.|ed) Kr\.|p[řr](\.|ed) n\. l\.|po Kr\.|n\. l\.)/i,
  abbreviated: /^(p[řr](\.|ed) Kr\.|p[řr](\.|ed) n\. l\.|po Kr\.|n\. l\.)/i,
  wide: /^(p[řr](\.|ed) Kristem|p[řr](\.|ed) na[šs][íi]m letopo[čc]tem|po Kristu|na[šs]eho letopo[čc]tu)/i
};
var parseEraPatterns$13 = {
  any: [/^p[řr]/i, /^(po|n)/i]
};
var matchQuarterPatterns$13 = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234]\. [čc]tvrtlet[íi]/i,
  wide: /^[1234]\. [čc]tvrtlet[íi]/i
};
var parseQuarterPatterns$13 = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$13 = {
  narrow: /^[lúubdkčcszřrlp]/i,
  abbreviated: /^(led|[úu]no|b[řr]e|dub|kv[ěe]|[čc]vn|[čc]vc|srp|z[áa][řr]|[řr][íi]j|lis|pro)/i,
  wide: /^(leden|ledna|[úu]nora?|b[řr]ezen|b[řr]ezna|duben|dubna|kv[ěe]ten|kv[ěe]tna|[čc]erven(ec|ce)?|[čc]ervna|srpen|srpna|z[áa][řr][íi]|[řr][íi]jen|[řr][íi]jna|listopad(a|u)?|prosinec|prosince)/i
};
var parseMonthPatterns$13 = {
  narrow: [/^l/i, /^[úu]/i, /^b/i, /^d/i, /^k/i, /^[čc]/i, /^[čc]/i, /^s/i, /^z/i, /^[řr]/i, /^l/i, /^p/i],
  any: [/^led/i, /^[úu]n/i, /^b[řr]e/i, /^dub/i, /^kv[ěe]/i, /^[čc]vn|[čc]erven(?!\w)|[čc]ervna/i, /^[čc]vc|[čc]erven(ec|ce)/i, /^srp/i, /^z[áa][řr]/i, /^[řr][íi]j/i, /^lis/i, /^pro/i]
};
var matchDayPatterns$13 = {
  narrow: /^[npuúsčps]/i,
  short: /^(ne|po|[úu]t|st|[čc]t|p[áa]|so)/i,
  abbreviated: /^(ned|pon|[úu]te|st[rř]|[čc]tv|p[áa]t|sob)/i,
  wide: /^(ned[ěe]le|pond[ěe]l[íi]|[úu]ter[ýy]|st[řr]eda|[čc]tvrtek|p[áa]tek|sobota)/i
};
var parseDayPatterns$13 = {
  narrow: [/^n/i, /^p/i, /^[úu]/i, /^s/i, /^[čc]/i, /^p/i, /^s/i],
  any: [/^ne/i, /^po/i, /^[úu]t/i, /^st/i, /^[čc]t/i, /^p[áa]/i, /^so/i]
};
var matchDayPeriodPatterns$13 = {
  any: /^dopoledne|dop\.?|odpoledne|odp\.?|p[ůu]lnoc|poledne|r[áa]no|odpoledne|ve[čc]er|(v )?noci?/i
};
var parseDayPeriodPatterns$13 = {
  any: {
    am: /^dop/i,
    pm: /^odp/i,
    midnight: /^p[ůu]lnoc/i,
    noon: /^poledne/i,
    morning: /r[áa]no/i,
    afternoon: /odpoledne/i,
    evening: /ve[čc]er/i,
    night: /noc/i
  }
};
match$13.match = {
  ordinalNumber: (0, _index2$2j.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$13,
    parsePattern: parseOrdinalNumberPattern$13,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$4I.buildMatchFn)({
    matchPatterns: matchEraPatterns$13,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$13,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$4I.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$13,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$13,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$4I.buildMatchFn)({
    matchPatterns: matchMonthPatterns$13,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$13,
    defaultParseWidth: "any"
  }),
  day: (0, _index$4I.buildMatchFn)({
    matchPatterns: matchDayPatterns$13,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$13,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$4I.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$13,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$13,
    defaultParseWidth: "any"
  })
};
cs.cs = void 0;
var _index$4H = formatDistance$29;
var _index2$2i = formatLong$1c;
var _index3$1f = formatRelative$29;
var _index4$1e = localize$14;
var _index5$1e = match$13;
cs.cs = {
  code: "cs",
  formatDistance: _index$4H.formatDistance,
  formatLong: _index2$2i.formatLong,
  formatRelative: _index3$1f.formatRelative,
  localize: _index4$1e.localize,
  match: _index5$1e.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var cy = {};
var formatDistance$27 = {};
formatDistance$27.formatDistance = void 0;
var formatDistanceLocale$12 = {
  lessThanXSeconds: {
    one: "llai na eiliad",
    other: "llai na {{count}} eiliad"
  },
  xSeconds: {
    one: "1 eiliad",
    other: "{{count}} eiliad"
  },
  halfAMinute: "hanner munud",
  lessThanXMinutes: {
    one: "llai na munud",
    two: "llai na 2 funud",
    other: "llai na {{count}} munud"
  },
  xMinutes: {
    one: "1 munud",
    two: "2 funud",
    other: "{{count}} munud"
  },
  aboutXHours: {
    one: "tua 1 awr",
    other: "tua {{count}} awr"
  },
  xHours: {
    one: "1 awr",
    other: "{{count}} awr"
  },
  xDays: {
    one: "1 diwrnod",
    two: "2 ddiwrnod",
    other: "{{count}} diwrnod"
  },
  aboutXWeeks: {
    one: "tua 1 wythnos",
    two: "tua pythefnos",
    other: "tua {{count}} wythnos"
  },
  xWeeks: {
    one: "1 wythnos",
    two: "pythefnos",
    other: "{{count}} wythnos"
  },
  aboutXMonths: {
    one: "tua 1 mis",
    two: "tua 2 fis",
    other: "tua {{count}} mis"
  },
  xMonths: {
    one: "1 mis",
    two: "2 fis",
    other: "{{count}} mis"
  },
  aboutXYears: {
    one: "tua 1 flwyddyn",
    two: "tua 2 flynedd",
    other: "tua {{count}} mlynedd"
  },
  xYears: {
    one: "1 flwyddyn",
    two: "2 flynedd",
    other: "{{count}} mlynedd"
  },
  overXYears: {
    one: "dros 1 flwyddyn",
    two: "dros 2 flynedd",
    other: "dros {{count}} mlynedd"
  },
  almostXYears: {
    one: "bron 1 flwyddyn",
    two: "bron 2 flynedd",
    other: "bron {{count}} mlynedd"
  }
};
var formatDistance$26 = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$12[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else if (a === 2 && !!n.two) {
    r = n.two;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "mewn " + r;
    } else {
      return r + " yn ôl";
    }
  }
  return r;
};
formatDistance$27.formatDistance = formatDistance$26;
var formatLong$1b = {};
formatLong$1b.formatLong = void 0;
var _index$4G = buildFormatLongFn;
var dateFormats$1b = {
  full: "EEEE, d MMMM yyyy",
  long: "d MMMM yyyy",
  medium: "d MMM yyyy",
  short: "dd/MM/yyyy"
};
var timeFormats$1b = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$1b = {
  full: "{{date}} 'am' {{time}}",
  long: "{{date}} 'am' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$1b.formatLong = {
  date: (0, _index$4G.buildFormatLongFn)({
    formats: dateFormats$1b,
    defaultWidth: "full"
  }),
  time: (0, _index$4G.buildFormatLongFn)({
    formats: timeFormats$1b,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4G.buildFormatLongFn)({
    formats: dateTimeFormats$1b,
    defaultWidth: "full"
  })
};
var formatRelative$27 = {};
formatRelative$27.formatRelative = void 0;
var formatRelativeLocale$13 = {
  lastWeek: "eeee 'diwethaf am' p",
  yesterday: "'ddoe am' p",
  today: "'heddiw am' p",
  tomorrow: "'yfory am' p",
  nextWeek: "eeee 'am' p",
  other: "P"
};
var formatRelative$26 = function (e, a, t, r) {
  return formatRelativeLocale$13[e];
};
formatRelative$27.formatRelative = formatRelative$26;
var localize$13 = {};
localize$13.localize = void 0;
var _index$4F = buildLocalizeFn;
var eraValues$13 = {
  narrow: ["C", "O"],
  abbreviated: ["CC", "OC"],
  wide: ["Cyn Crist", "Ar ôl Crist"]
};
var quarterValues$13 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Ch1", "Ch2", "Ch3", "Ch4"],
  wide: ["Chwarter 1af", "2ail chwarter", "3ydd chwarter", "4ydd chwarter"]
};
var monthValues$13 = {
  narrow: ["I", "Ch", "Ma", "E", "Mi", "Me", "G", "A", "Md", "H", "T", "Rh"],
  abbreviated: ["Ion", "Chwe", "Maw", "Ebr", "Mai", "Meh", "Gor", "Aws", "Med", "Hyd", "Tach", "Rhag"],
  wide: ["Ionawr", "Chwefror", "Mawrth", "Ebrill", "Mai", "Mehefin", "Gorffennaf", "Awst", "Medi", "Hydref", "Tachwedd", "Rhagfyr"]
};
var dayValues$13 = {
  narrow: ["S", "Ll", "M", "M", "I", "G", "S"],
  short: ["Su", "Ll", "Ma", "Me", "Ia", "Gw", "Sa"],
  abbreviated: ["Sul", "Llun", "Maw", "Mer", "Iau", "Gwe", "Sad"],
  wide: ["dydd Sul", "dydd Llun", "dydd Mawrth", "dydd Mercher", "dydd Iau", "dydd Gwener", "dydd Sadwrn"]
};
var dayPeriodValues$13 = {
  narrow: {
    am: "b",
    pm: "h",
    midnight: "hn",
    noon: "hd",
    morning: "bore",
    afternoon: "prynhawn",
    evening: "gyda'r nos",
    night: "nos"
  },
  abbreviated: {
    am: "yb",
    pm: "yh",
    midnight: "hanner nos",
    noon: "hanner dydd",
    morning: "bore",
    afternoon: "prynhawn",
    evening: "gyda'r nos",
    night: "nos"
  },
  wide: {
    am: "y.b.",
    pm: "y.h.",
    midnight: "hanner nos",
    noon: "hanner dydd",
    morning: "bore",
    afternoon: "prynhawn",
    evening: "gyda'r nos",
    night: "nos"
  }
};
var formattingDayPeriodValues$Q = {
  narrow: {
    am: "b",
    pm: "h",
    midnight: "hn",
    noon: "hd",
    morning: "yn y bore",
    afternoon: "yn y prynhawn",
    evening: "gyda'r nos",
    night: "yn y nos"
  },
  abbreviated: {
    am: "yb",
    pm: "yh",
    midnight: "hanner nos",
    noon: "hanner dydd",
    morning: "yn y bore",
    afternoon: "yn y prynhawn",
    evening: "gyda'r nos",
    night: "yn y nos"
  },
  wide: {
    am: "y.b.",
    pm: "y.h.",
    midnight: "hanner nos",
    noon: "hanner dydd",
    morning: "yn y bore",
    afternoon: "yn y prynhawn",
    evening: "gyda'r nos",
    night: "yn y nos"
  }
};
var ordinalNumber$13 = function (e, a) {
  var t = Number(e);
  if (t < 20) {
    switch (t) {
      case 0:
        return t + "fed";
      case 1:
        return t + "af";
      case 2:
        return t + "ail";
      case 3:
      case 4:
        return t + "ydd";
      case 5:
      case 6:
        return t + "ed";
      case 7:
      case 8:
      case 9:
      case 10:
      case 12:
      case 15:
      case 18:
        return t + "fed";
      case 11:
      case 13:
      case 14:
      case 16:
      case 17:
      case 19:
        return t + "eg";
    }
  } else if (t >= 50 && t <= 60 || t === 80 || t >= 100) {
    return t + "fed";
  }
  return t + "ain";
};
localize$13.localize = {
  ordinalNumber: ordinalNumber$13,
  era: (0, _index$4F.buildLocalizeFn)({
    values: eraValues$13,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$4F.buildLocalizeFn)({
    values: quarterValues$13,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$4F.buildLocalizeFn)({
    values: monthValues$13,
    defaultWidth: "wide"
  }),
  day: (0, _index$4F.buildLocalizeFn)({
    values: dayValues$13,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$4F.buildLocalizeFn)({
    values: dayPeriodValues$13,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$Q,
    defaultFormattingWidth: "wide"
  })
};
var match$12 = {};
match$12.match = void 0;
var _index$4E = buildMatchFn;
var _index2$2h = buildMatchPatternFn;
var matchOrdinalNumberPattern$12 = /^(\d+)(af|ail|ydd|ed|fed|eg|ain)?/i;
var parseOrdinalNumberPattern$12 = /\d+/i;
var matchEraPatterns$12 = {
  narrow: /^(c|o)/i,
  abbreviated: /^(c\.?\s?c\.?|o\.?\s?c\.?)/i,
  wide: /^(cyn christ|ar ôl crist|ar ol crist)/i
};
var parseEraPatterns$12 = {
  wide: [/^c/i, /^(ar ôl crist|ar ol crist)/i],
  any: [/^c/i, /^o/i]
};
var matchQuarterPatterns$12 = {
  narrow: /^[1234]/i,
  abbreviated: /^ch[1234]/i,
  wide: /^(chwarter 1af)|([234](ail|ydd)? chwarter)/i
};
var parseQuarterPatterns$12 = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$12 = {
  narrow: /^(i|ch|m|e|g|a|h|t|rh)/i,
  abbreviated: /^(ion|chwe|maw|ebr|mai|meh|gor|aws|med|hyd|tach|rhag)/i,
  wide: /^(ionawr|chwefror|mawrth|ebrill|mai|mehefin|gorffennaf|awst|medi|hydref|tachwedd|rhagfyr)/i
};
var parseMonthPatterns$12 = {
  narrow: [/^i/i, /^ch/i, /^m/i, /^e/i, /^m/i, /^m/i, /^g/i, /^a/i, /^m/i, /^h/i, /^t/i, /^rh/i],
  any: [/^io/i, /^ch/i, /^maw/i, /^e/i, /^mai/i, /^meh/i, /^g/i, /^a/i, /^med/i, /^h/i, /^t/i, /^rh/i]
};
var matchDayPatterns$12 = {
  narrow: /^(s|ll|m|i|g)/i,
  short: /^(su|ll|ma|me|ia|gw|sa)/i,
  abbreviated: /^(sul|llun|maw|mer|iau|gwe|sad)/i,
  wide: /^dydd (sul|llun|mawrth|mercher|iau|gwener|sadwrn)/i
};
var parseDayPatterns$12 = {
  narrow: [/^s/i, /^ll/i, /^m/i, /^m/i, /^i/i, /^g/i, /^s/i],
  wide: [/^dydd su/i, /^dydd ll/i, /^dydd ma/i, /^dydd me/i, /^dydd i/i, /^dydd g/i, /^dydd sa/i],
  any: [/^su/i, /^ll/i, /^ma/i, /^me/i, /^i/i, /^g/i, /^sa/i]
};
var matchDayPeriodPatterns$12 = {
  narrow: /^(b|h|hn|hd|(yn y|y|yr|gyda'r) (bore|prynhawn|nos|hwyr))/i,
  any: /^(y\.?\s?[bh]\.?|hanner nos|hanner dydd|(yn y|y|yr|gyda'r) (bore|prynhawn|nos|hwyr))/i
};
var parseDayPeriodPatterns$12 = {
  any: {
    am: /^b|(y\.?\s?b\.?)/i,
    pm: /^h|(y\.?\s?h\.?)|(yr hwyr)/i,
    midnight: /^hn|hanner nos/i,
    noon: /^hd|hanner dydd/i,
    morning: /bore/i,
    afternoon: /prynhawn/i,
    evening: /^gyda'r nos$/i,
    night: /blah/i
  }
};
match$12.match = {
  ordinalNumber: (0, _index2$2h.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$12,
    parsePattern: parseOrdinalNumberPattern$12,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$4E.buildMatchFn)({
    matchPatterns: matchEraPatterns$12,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$12,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$4E.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$12,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$12,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$4E.buildMatchFn)({
    matchPatterns: matchMonthPatterns$12,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$12,
    defaultParseWidth: "any"
  }),
  day: (0, _index$4E.buildMatchFn)({
    matchPatterns: matchDayPatterns$12,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$12,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$4E.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$12,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$12,
    defaultParseWidth: "any"
  })
};
cy.cy = void 0;
var _index$4D = formatDistance$27;
var _index2$2g = formatLong$1b;
var _index3$1e = formatRelative$27;
var _index4$1d = localize$13;
var _index5$1d = match$12;
cy.cy = {
  code: "cy",
  formatDistance: _index$4D.formatDistance,
  formatLong: _index2$2g.formatLong,
  formatRelative: _index3$1e.formatRelative,
  localize: _index4$1d.localize,
  match: _index5$1d.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var da = {};
var formatDistance$25 = {};
formatDistance$25.formatDistance = void 0;
var formatDistanceLocale$11 = {
  lessThanXSeconds: {
    one: "mindre end ét sekund",
    other: "mindre end {{count}} sekunder"
  },
  xSeconds: {
    one: "1 sekund",
    other: "{{count}} sekunder"
  },
  halfAMinute: "ét halvt minut",
  lessThanXMinutes: {
    one: "mindre end ét minut",
    other: "mindre end {{count}} minutter"
  },
  xMinutes: {
    one: "1 minut",
    other: "{{count}} minutter"
  },
  aboutXHours: {
    one: "cirka 1 time",
    other: "cirka {{count}} timer"
  },
  xHours: {
    one: "1 time",
    other: "{{count}} timer"
  },
  xDays: {
    one: "1 dag",
    other: "{{count}} dage"
  },
  aboutXWeeks: {
    one: "cirka 1 uge",
    other: "cirka {{count}} uger"
  },
  xWeeks: {
    one: "1 uge",
    other: "{{count}} uger"
  },
  aboutXMonths: {
    one: "cirka 1 måned",
    other: "cirka {{count}} måneder"
  },
  xMonths: {
    one: "1 måned",
    other: "{{count}} måneder"
  },
  aboutXYears: {
    one: "cirka 1 år",
    other: "cirka {{count}} år"
  },
  xYears: {
    one: "1 år",
    other: "{{count}} år"
  },
  overXYears: {
    one: "over 1 år",
    other: "over {{count}} år"
  },
  almostXYears: {
    one: "næsten 1 år",
    other: "næsten {{count}} år"
  }
};
var formatDistance$24 = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$11[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "om " + r;
    } else {
      return r + " siden";
    }
  }
  return r;
};
formatDistance$25.formatDistance = formatDistance$24;
var formatLong$1a = {};
formatLong$1a.formatLong = void 0;
var _index$4C = buildFormatLongFn;
var dateFormats$1a = {
  full: "EEEE 'den' d. MMMM y",
  long: "d. MMMM y",
  medium: "d. MMM y",
  short: "dd/MM/y"
};
var timeFormats$1a = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$1a = {
  full: "{{date}} 'kl'. {{time}}",
  long: "{{date}} 'kl'. {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$1a.formatLong = {
  date: (0, _index$4C.buildFormatLongFn)({
    formats: dateFormats$1a,
    defaultWidth: "full"
  }),
  time: (0, _index$4C.buildFormatLongFn)({
    formats: timeFormats$1a,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4C.buildFormatLongFn)({
    formats: dateTimeFormats$1a,
    defaultWidth: "full"
  })
};
var formatRelative$25 = {};
formatRelative$25.formatRelative = void 0;
var formatRelativeLocale$12 = {
  lastWeek: "'sidste' eeee 'kl.' p",
  yesterday: "'i går kl.' p",
  today: "'i dag kl.' p",
  tomorrow: "'i morgen kl.' p",
  nextWeek: "'på' eeee 'kl.' p",
  other: "P"
};
var formatRelative$24 = function (e, a, t, r) {
  return formatRelativeLocale$12[e];
};
formatRelative$25.formatRelative = formatRelative$24;
var localize$12 = {};
localize$12.localize = void 0;
var _index$4B = buildLocalizeFn;
var eraValues$12 = {
  narrow: ["fvt", "vt"],
  abbreviated: ["f.v.t.", "v.t."],
  wide: ["før vesterlandsk tidsregning", "vesterlandsk tidsregning"]
};
var quarterValues$12 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1. kvt.", "2. kvt.", "3. kvt.", "4. kvt."],
  wide: ["1. kvartal", "2. kvartal", "3. kvartal", "4. kvartal"]
};
var monthValues$12 = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["jan.", "feb.", "mar.", "apr.", "maj", "jun.", "jul.", "aug.", "sep.", "okt.", "nov.", "dec."],
  wide: ["januar", "februar", "marts", "april", "maj", "juni", "juli", "august", "september", "oktober", "november", "december"]
};
var dayValues$12 = {
  narrow: ["S", "M", "T", "O", "T", "F", "L"],
  short: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
  abbreviated: ["søn.", "man.", "tir.", "ons.", "tor.", "fre.", "lør."],
  wide: ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"]
};
var dayPeriodValues$12 = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "midnat",
    noon: "middag",
    morning: "morgen",
    afternoon: "eftermiddag",
    evening: "aften",
    night: "nat"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnat",
    noon: "middag",
    morning: "morgen",
    afternoon: "eftermiddag",
    evening: "aften",
    night: "nat"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnat",
    noon: "middag",
    morning: "morgen",
    afternoon: "eftermiddag",
    evening: "aften",
    night: "nat"
  }
};
var formattingDayPeriodValues$P = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "midnat",
    noon: "middag",
    morning: "om morgenen",
    afternoon: "om eftermiddagen",
    evening: "om aftenen",
    night: "om natten"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "midnat",
    noon: "middag",
    morning: "om morgenen",
    afternoon: "om eftermiddagen",
    evening: "om aftenen",
    night: "om natten"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnat",
    noon: "middag",
    morning: "om morgenen",
    afternoon: "om eftermiddagen",
    evening: "om aftenen",
    night: "om natten"
  }
};
var ordinalNumber$12 = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$12.localize = {
  ordinalNumber: ordinalNumber$12,
  era: (0, _index$4B.buildLocalizeFn)({
    values: eraValues$12,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$4B.buildLocalizeFn)({
    values: quarterValues$12,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$4B.buildLocalizeFn)({
    values: monthValues$12,
    defaultWidth: "wide"
  }),
  day: (0, _index$4B.buildLocalizeFn)({
    values: dayValues$12,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$4B.buildLocalizeFn)({
    values: dayPeriodValues$12,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$P,
    defaultFormattingWidth: "wide"
  })
};
var match$11 = {};
match$11.match = void 0;
var _index$4A = buildMatchFn;
var _index2$2f = buildMatchPatternFn;
var matchOrdinalNumberPattern$11 = /^(\d+)(\.)?/i;
var parseOrdinalNumberPattern$11 = /\d+/i;
var matchEraPatterns$11 = {
  narrow: /^(fKr|fvt|eKr|vt)/i,
  abbreviated: /^(f\.Kr\.?|f\.v\.t\.?|e\.Kr\.?|v\.t\.)/i,
  wide: /^(f.Kr.|før vesterlandsk tidsregning|e.Kr.|vesterlandsk tidsregning)/i
};
var parseEraPatterns$11 = {
  any: [/^f/i, /^(v|e)/i]
};
var matchQuarterPatterns$11 = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234]. kvt\./i,
  wide: /^[1234]\.? kvartal/i
};
var parseQuarterPatterns$11 = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$11 = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan.|feb.|mar.|apr.|maj|jun.|jul.|aug.|sep.|okt.|nov.|dec.)/i,
  wide: /^(januar|februar|marts|april|maj|juni|juli|august|september|oktober|november|december)/i
};
var parseMonthPatterns$11 = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^maj/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$11 = {
  narrow: /^[smtofl]/i,
  short: /^(søn.|man.|tir.|ons.|tor.|fre.|lør.)/i,
  abbreviated: /^(søn|man|tir|ons|tor|fre|lør)/i,
  wide: /^(søndag|mandag|tirsdag|onsdag|torsdag|fredag|lørdag)/i
};
var parseDayPatterns$11 = {
  narrow: [/^s/i, /^m/i, /^t/i, /^o/i, /^t/i, /^f/i, /^l/i],
  any: [/^s/i, /^m/i, /^ti/i, /^o/i, /^to/i, /^f/i, /^l/i]
};
var matchDayPeriodPatterns$11 = {
  narrow: /^(a|p|midnat|middag|(om) (morgenen|eftermiddagen|aftenen|natten))/i,
  any: /^([ap]\.?\s?m\.?|midnat|middag|(om) (morgenen|eftermiddagen|aftenen|natten))/i
};
var parseDayPeriodPatterns$11 = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /midnat/i,
    noon: /middag/i,
    morning: /morgen/i,
    afternoon: /eftermiddag/i,
    evening: /aften/i,
    night: /nat/i
  }
};
match$11.match = {
  ordinalNumber: (0, _index2$2f.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$11,
    parsePattern: parseOrdinalNumberPattern$11,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$4A.buildMatchFn)({
    matchPatterns: matchEraPatterns$11,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$11,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$4A.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$11,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$11,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$4A.buildMatchFn)({
    matchPatterns: matchMonthPatterns$11,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$11,
    defaultParseWidth: "any"
  }),
  day: (0, _index$4A.buildMatchFn)({
    matchPatterns: matchDayPatterns$11,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$11,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$4A.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$11,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$11,
    defaultParseWidth: "any"
  })
};
da.da = void 0;
var _index$4z = formatDistance$25;
var _index2$2e = formatLong$1a;
var _index3$1d = formatRelative$25;
var _index4$1c = localize$12;
var _index5$1c = match$11;
da.da = {
  code: "da",
  formatDistance: _index$4z.formatDistance,
  formatLong: _index2$2e.formatLong,
  formatRelative: _index3$1d.formatRelative,
  localize: _index4$1c.localize,
  match: _index5$1c.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var de = {};
var formatDistance$23 = {};
formatDistance$23.formatDistance = void 0;
var formatDistanceLocale$10 = {
  lessThanXSeconds: {
    standalone: {
      one: "weniger als 1 Sekunde",
      other: "weniger als {{count}} Sekunden"
    },
    withPreposition: {
      one: "weniger als 1 Sekunde",
      other: "weniger als {{count}} Sekunden"
    }
  },
  xSeconds: {
    standalone: {
      one: "1 Sekunde",
      other: "{{count}} Sekunden"
    },
    withPreposition: {
      one: "1 Sekunde",
      other: "{{count}} Sekunden"
    }
  },
  halfAMinute: {
    standalone: "eine halbe Minute",
    withPreposition: "einer halben Minute"
  },
  lessThanXMinutes: {
    standalone: {
      one: "weniger als 1 Minute",
      other: "weniger als {{count}} Minuten"
    },
    withPreposition: {
      one: "weniger als 1 Minute",
      other: "weniger als {{count}} Minuten"
    }
  },
  xMinutes: {
    standalone: {
      one: "1 Minute",
      other: "{{count}} Minuten"
    },
    withPreposition: {
      one: "1 Minute",
      other: "{{count}} Minuten"
    }
  },
  aboutXHours: {
    standalone: {
      one: "etwa 1 Stunde",
      other: "etwa {{count}} Stunden"
    },
    withPreposition: {
      one: "etwa 1 Stunde",
      other: "etwa {{count}} Stunden"
    }
  },
  xHours: {
    standalone: {
      one: "1 Stunde",
      other: "{{count}} Stunden"
    },
    withPreposition: {
      one: "1 Stunde",
      other: "{{count}} Stunden"
    }
  },
  xDays: {
    standalone: {
      one: "1 Tag",
      other: "{{count}} Tage"
    },
    withPreposition: {
      one: "1 Tag",
      other: "{{count}} Tagen"
    }
  },
  aboutXWeeks: {
    standalone: {
      one: "etwa 1 Woche",
      other: "etwa {{count}} Wochen"
    },
    withPreposition: {
      one: "etwa 1 Woche",
      other: "etwa {{count}} Wochen"
    }
  },
  xWeeks: {
    standalone: {
      one: "1 Woche",
      other: "{{count}} Wochen"
    },
    withPreposition: {
      one: "1 Woche",
      other: "{{count}} Wochen"
    }
  },
  aboutXMonths: {
    standalone: {
      one: "etwa 1 Monat",
      other: "etwa {{count}} Monate"
    },
    withPreposition: {
      one: "etwa 1 Monat",
      other: "etwa {{count}} Monaten"
    }
  },
  xMonths: {
    standalone: {
      one: "1 Monat",
      other: "{{count}} Monate"
    },
    withPreposition: {
      one: "1 Monat",
      other: "{{count}} Monaten"
    }
  },
  aboutXYears: {
    standalone: {
      one: "etwa 1 Jahr",
      other: "etwa {{count}} Jahre"
    },
    withPreposition: {
      one: "etwa 1 Jahr",
      other: "etwa {{count}} Jahren"
    }
  },
  xYears: {
    standalone: {
      one: "1 Jahr",
      other: "{{count}} Jahre"
    },
    withPreposition: {
      one: "1 Jahr",
      other: "{{count}} Jahren"
    }
  },
  overXYears: {
    standalone: {
      one: "mehr als 1 Jahr",
      other: "mehr als {{count}} Jahre"
    },
    withPreposition: {
      one: "mehr als 1 Jahr",
      other: "mehr als {{count}} Jahren"
    }
  },
  almostXYears: {
    standalone: {
      one: "fast 1 Jahr",
      other: "fast {{count}} Jahre"
    },
    withPreposition: {
      one: "fast 1 Jahr",
      other: "fast {{count}} Jahren"
    }
  }
};
var formatDistance$22 = function (e, a, t) {
  var r;
  var n = (t === null || t === void 0 ? void 0 : t.addSuffix) ? formatDistanceLocale$10[e].withPreposition : formatDistanceLocale$10[e].standalone;
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "in " + r;
    } else {
      return "vor " + r;
    }
  }
  return r;
};
formatDistance$23.formatDistance = formatDistance$22;
var formatLong$19 = {};
formatLong$19.formatLong = void 0;
var _index$4y = buildFormatLongFn;
var dateFormats$19 = {
  full: "EEEE, do MMMM y",
  long: "do MMMM y",
  medium: "do MMM y",
  short: "dd.MM.y"
};
var timeFormats$19 = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$19 = {
  full: "{{date}} 'um' {{time}}",
  long: "{{date}} 'um' {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$19.formatLong = {
  date: (0, _index$4y.buildFormatLongFn)({
    formats: dateFormats$19,
    defaultWidth: "full"
  }),
  time: (0, _index$4y.buildFormatLongFn)({
    formats: timeFormats$19,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4y.buildFormatLongFn)({
    formats: dateTimeFormats$19,
    defaultWidth: "full"
  })
};
var formatRelative$23 = {};
formatRelative$23.formatRelative = void 0;
var formatRelativeLocale$11 = {
  lastWeek: "'letzten' eeee 'um' p",
  yesterday: "'gestern um' p",
  today: "'heute um' p",
  tomorrow: "'morgen um' p",
  nextWeek: "eeee 'um' p",
  other: "P"
};
var formatRelative$22 = function (e, a, t, r) {
  return formatRelativeLocale$11[e];
};
formatRelative$23.formatRelative = formatRelative$22;
var localize$11 = {};
localize$11.localize = void 0;
var _index$4x = buildLocalizeFn;
var eraValues$11 = {
  narrow: ["v.Chr.", "n.Chr."],
  abbreviated: ["v.Chr.", "n.Chr."],
  wide: ["vor Christus", "nach Christus"]
};
var quarterValues$11 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1. Quartal", "2. Quartal", "3. Quartal", "4. Quartal"]
};
var monthValues$11 = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
  wide: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
};
var formattingMonthValues$e = {
  narrow: monthValues$11.narrow,
  abbreviated: ["Jan.", "Feb.", "März", "Apr.", "Mai", "Juni", "Juli", "Aug.", "Sep.", "Okt.", "Nov.", "Dez."],
  wide: monthValues$11.wide
};
var dayValues$11 = {
  narrow: ["S", "M", "D", "M", "D", "F", "S"],
  short: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  abbreviated: ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."],
  wide: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
};
var dayPeriodValues$11 = {
  narrow: {
    am: "vm.",
    pm: "nm.",
    midnight: "Mitternacht",
    noon: "Mittag",
    morning: "Morgen",
    afternoon: "Nachm.",
    evening: "Abend",
    night: "Nacht"
  },
  abbreviated: {
    am: "vorm.",
    pm: "nachm.",
    midnight: "Mitternacht",
    noon: "Mittag",
    morning: "Morgen",
    afternoon: "Nachmittag",
    evening: "Abend",
    night: "Nacht"
  },
  wide: {
    am: "vormittags",
    pm: "nachmittags",
    midnight: "Mitternacht",
    noon: "Mittag",
    morning: "Morgen",
    afternoon: "Nachmittag",
    evening: "Abend",
    night: "Nacht"
  }
};
var formattingDayPeriodValues$O = {
  narrow: {
    am: "vm.",
    pm: "nm.",
    midnight: "Mitternacht",
    noon: "Mittag",
    morning: "morgens",
    afternoon: "nachm.",
    evening: "abends",
    night: "nachts"
  },
  abbreviated: {
    am: "vorm.",
    pm: "nachm.",
    midnight: "Mitternacht",
    noon: "Mittag",
    morning: "morgens",
    afternoon: "nachmittags",
    evening: "abends",
    night: "nachts"
  },
  wide: {
    am: "vormittags",
    pm: "nachmittags",
    midnight: "Mitternacht",
    noon: "Mittag",
    morning: "morgens",
    afternoon: "nachmittags",
    evening: "abends",
    night: "nachts"
  }
};
var ordinalNumber$11 = function (e) {
  var a = Number(e);
  return a + ".";
};
localize$11.localize = {
  ordinalNumber: ordinalNumber$11,
  era: (0, _index$4x.buildLocalizeFn)({
    values: eraValues$11,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$4x.buildLocalizeFn)({
    values: quarterValues$11,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$4x.buildLocalizeFn)({
    values: monthValues$11,
    formattingValues: formattingMonthValues$e,
    defaultWidth: "wide"
  }),
  day: (0, _index$4x.buildLocalizeFn)({
    values: dayValues$11,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$4x.buildLocalizeFn)({
    values: dayPeriodValues$11,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$O,
    defaultFormattingWidth: "wide"
  })
};
var match$10 = {};
match$10.match = void 0;
var _index$4w = buildMatchFn;
var _index2$2d = buildMatchPatternFn;
var matchOrdinalNumberPattern$10 = /^(\d+)(\.)?/i;
var parseOrdinalNumberPattern$10 = /\d+/i;
var matchEraPatterns$10 = {
  narrow: /^(v\.? ?Chr\.?|n\.? ?Chr\.?)/i,
  abbreviated: /^(v\.? ?Chr\.?|n\.? ?Chr\.?)/i,
  wide: /^(vor Christus|vor unserer Zeitrechnung|nach Christus|unserer Zeitrechnung)/i
};
var parseEraPatterns$10 = {
  any: [/^v/i, /^n/i]
};
var matchQuarterPatterns$10 = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](\.)? Quartal/i
};
var parseQuarterPatterns$10 = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$10 = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(j[aä]n|feb|mär[z]?|apr|mai|jun[i]?|jul[i]?|aug|sep|okt|nov|dez)\.?/i,
  wide: /^(januar|februar|märz|april|mai|juni|juli|august|september|oktober|november|dezember)/i
};
var parseMonthPatterns$10 = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^j[aä]/i, /^f/i, /^mär/i, /^ap/i, /^mai/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$10 = {
  narrow: /^[smdmf]/i,
  short: /^(so|mo|di|mi|do|fr|sa)/i,
  abbreviated: /^(son?|mon?|die?|mit?|don?|fre?|sam?)\.?/i,
  wide: /^(sonntag|montag|dienstag|mittwoch|donnerstag|freitag|samstag)/i
};
var parseDayPatterns$10 = {
  any: [/^so/i, /^mo/i, /^di/i, /^mi/i, /^do/i, /^f/i, /^sa/i]
};
var matchDayPeriodPatterns$10 = {
  narrow: /^(vm\.?|nm\.?|Mitternacht|Mittag|morgens|nachm\.?|abends|nachts)/i,
  abbreviated: /^(vorm\.?|nachm\.?|Mitternacht|Mittag|morgens|nachm\.?|abends|nachts)/i,
  wide: /^(vormittags|nachmittags|Mitternacht|Mittag|morgens|nachmittags|abends|nachts)/i
};
var parseDayPeriodPatterns$10 = {
  any: {
    am: /^v/i,
    pm: /^n/i,
    midnight: /^Mitte/i,
    noon: /^Mitta/i,
    morning: /morgens/i,
    afternoon: /nachmittags/i,
    evening: /abends/i,
    night: /nachts/i
  }
};
match$10.match = {
  ordinalNumber: (0, _index2$2d.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$10,
    parsePattern: parseOrdinalNumberPattern$10,
    valueCallback: function (e) {
      return parseInt(e);
    }
  }),
  era: (0, _index$4w.buildMatchFn)({
    matchPatterns: matchEraPatterns$10,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$10,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$4w.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$10,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$10,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$4w.buildMatchFn)({
    matchPatterns: matchMonthPatterns$10,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$10,
    defaultParseWidth: "any"
  }),
  day: (0, _index$4w.buildMatchFn)({
    matchPatterns: matchDayPatterns$10,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$10,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$4w.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$10,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$10,
    defaultParseWidth: "any"
  })
};
de.de = void 0;
var _index$4v = formatDistance$23;
var _index2$2c = formatLong$19;
var _index3$1c = formatRelative$23;
var _index4$1b = localize$11;
var _index5$1b = match$10;
de.de = {
  code: "de",
  formatDistance: _index$4v.formatDistance,
  formatLong: _index2$2c.formatLong,
  formatRelative: _index3$1c.formatRelative,
  localize: _index4$1b.localize,
  match: _index5$1b.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var deAT = {};
var localize$10 = {};
localize$10.localize = void 0;
var _index$4u = buildLocalizeFn;
var eraValues$10 = {
  narrow: ["v.Chr.", "n.Chr."],
  abbreviated: ["v.Chr.", "n.Chr."],
  wide: ["vor Christus", "nach Christus"]
};
var quarterValues$10 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1. Quartal", "2. Quartal", "3. Quartal", "4. Quartal"]
};
var monthValues$10 = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["Jän", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
  wide: ["Jänner", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
};
var formattingMonthValues$d = {
  narrow: monthValues$10.narrow,
  abbreviated: ["Jän.", "Feb.", "März", "Apr.", "Mai", "Juni", "Juli", "Aug.", "Sep.", "Okt.", "Nov.", "Dez."],
  wide: monthValues$10.wide
};
var dayValues$10 = {
  narrow: ["S", "M", "D", "M", "D", "F", "S"],
  short: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
  abbreviated: ["So.", "Mo.", "Di.", "Mi.", "Do.", "Fr.", "Sa."],
  wide: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"]
};
var dayPeriodValues$10 = {
  narrow: {
    am: "vm.",
    pm: "nm.",
    midnight: "Mitternacht",
    noon: "Mittag",
    morning: "Morgen",
    afternoon: "Nachm.",
    evening: "Abend",
    night: "Nacht"
  },
  abbreviated: {
    am: "vorm.",
    pm: "nachm.",
    midnight: "Mitternacht",
    noon: "Mittag",
    morning: "Morgen",
    afternoon: "Nachmittag",
    evening: "Abend",
    night: "Nacht"
  },
  wide: {
    am: "vormittags",
    pm: "nachmittags",
    midnight: "Mitternacht",
    noon: "Mittag",
    morning: "Morgen",
    afternoon: "Nachmittag",
    evening: "Abend",
    night: "Nacht"
  }
};
var formattingDayPeriodValues$N = {
  narrow: {
    am: "vm.",
    pm: "nm.",
    midnight: "Mitternacht",
    noon: "Mittag",
    morning: "morgens",
    afternoon: "nachm.",
    evening: "abends",
    night: "nachts"
  },
  abbreviated: {
    am: "vorm.",
    pm: "nachm.",
    midnight: "Mitternacht",
    noon: "Mittag",
    morning: "morgens",
    afternoon: "nachmittags",
    evening: "abends",
    night: "nachts"
  },
  wide: {
    am: "vormittags",
    pm: "nachmittags",
    midnight: "Mitternacht",
    noon: "Mittag",
    morning: "morgens",
    afternoon: "nachmittags",
    evening: "abends",
    night: "nachts"
  }
};
var ordinalNumber$10 = function (e) {
  var a = Number(e);
  return a + ".";
};
localize$10.localize = {
  ordinalNumber: ordinalNumber$10,
  era: (0, _index$4u.buildLocalizeFn)({
    values: eraValues$10,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$4u.buildLocalizeFn)({
    values: quarterValues$10,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$4u.buildLocalizeFn)({
    values: monthValues$10,
    formattingValues: formattingMonthValues$d,
    defaultWidth: "wide"
  }),
  day: (0, _index$4u.buildLocalizeFn)({
    values: dayValues$10,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$4u.buildLocalizeFn)({
    values: dayPeriodValues$10,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$N,
    defaultFormattingWidth: "wide"
  })
};
deAT.deAT = void 0;
var _index$4t = formatDistance$23;
var _index2$2b = formatLong$19;
var _index3$1b = formatRelative$23;
var _index4$1a = match$10;
var _index5$1a = localize$10;
deAT.deAT = {
  code: "de-AT",
  formatDistance: _index$4t.formatDistance,
  formatLong: _index2$2b.formatLong,
  formatRelative: _index3$1b.formatRelative,
  localize: _index5$1a.localize,
  match: _index4$1a.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var el = {};
var formatDistance$21 = {};
formatDistance$21.formatDistance = void 0;
var formatDistanceLocale$$ = {
  lessThanXSeconds: {
    one: "λιγότερο από ένα δευτερόλεπτο",
    other: "λιγότερο από {{count}} δευτερόλεπτα"
  },
  xSeconds: {
    one: "1 δευτερόλεπτο",
    other: "{{count}} δευτερόλεπτα"
  },
  halfAMinute: "μισό λεπτό",
  lessThanXMinutes: {
    one: "λιγότερο από ένα λεπτό",
    other: "λιγότερο από {{count}} λεπτά"
  },
  xMinutes: {
    one: "1 λεπτό",
    other: "{{count}} λεπτά"
  },
  aboutXHours: {
    one: "περίπου 1 ώρα",
    other: "περίπου {{count}} ώρες"
  },
  xHours: {
    one: "1 ώρα",
    other: "{{count}} ώρες"
  },
  xDays: {
    one: "1 ημέρα",
    other: "{{count}} ημέρες"
  },
  aboutXWeeks: {
    one: "περίπου 1 εβδομάδα",
    other: "περίπου {{count}} εβδομάδες"
  },
  xWeeks: {
    one: "1 εβδομάδα",
    other: "{{count}} εβδομάδες"
  },
  aboutXMonths: {
    one: "περίπου 1 μήνας",
    other: "περίπου {{count}} μήνες"
  },
  xMonths: {
    one: "1 μήνας",
    other: "{{count}} μήνες"
  },
  aboutXYears: {
    one: "περίπου 1 χρόνο",
    other: "περίπου {{count}} χρόνια"
  },
  xYears: {
    one: "1 χρόνο",
    other: "{{count}} χρόνια"
  },
  overXYears: {
    one: "πάνω από 1 χρόνο",
    other: "πάνω από {{count}} χρόνια"
  },
  almostXYears: {
    one: "περίπου 1 χρόνο",
    other: "περίπου {{count}} χρόνια"
  }
};
var formatDistance$20 = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$$[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "σε " + r;
    } else {
      return r + " πριν";
    }
  }
  return r;
};
formatDistance$21.formatDistance = formatDistance$20;
var formatLong$18 = {};
formatLong$18.formatLong = void 0;
var _index$4s = buildFormatLongFn;
var dateFormats$18 = {
  full: "EEEE, d MMMM y",
  long: "d MMMM y",
  medium: "d MMM y",
  short: "d/M/yy"
};
var timeFormats$18 = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$18 = {
  full: "{{date}} - {{time}}",
  long: "{{date}} - {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$18.formatLong = {
  date: (0, _index$4s.buildFormatLongFn)({
    formats: dateFormats$18,
    defaultWidth: "full"
  }),
  time: (0, _index$4s.buildFormatLongFn)({
    formats: timeFormats$18,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4s.buildFormatLongFn)({
    formats: dateTimeFormats$18,
    defaultWidth: "full"
  })
};
var formatRelative$21 = {};
formatRelative$21.formatRelative = void 0;
var formatRelativeLocale$10 = {
  lastWeek: function (e) {
    switch (e.getDay()) {
      case 6:
        return "'το προηγούμενο' eeee 'στις' p";
      default:
        return "'την προηγούμενη' eeee 'στις' p";
    }
  },
  yesterday: "'χθες στις' p",
  today: "'σήμερα στις' p",
  tomorrow: "'αύριο στις' p",
  nextWeek: "eeee 'στις' p",
  other: "P"
};
var formatRelative$20 = function (e, a) {
  var t = formatRelativeLocale$10[e];
  if (typeof t === "function") return t(a);
  return t;
};
formatRelative$21.formatRelative = formatRelative$20;
var localize$$ = {};
localize$$.localize = void 0;
var _index$4r = buildLocalizeFn;
var eraValues$$ = {
  narrow: ["πΧ", "μΧ"],
  abbreviated: ["π.Χ.", "μ.Χ."],
  wide: ["προ Χριστού", "μετά Χριστόν"]
};
var quarterValues$$ = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Τ1", "Τ2", "Τ3", "Τ4"],
  wide: ["1ο τρίμηνο", "2ο τρίμηνο", "3ο τρίμηνο", "4ο τρίμηνο"]
};
var monthValues$$ = {
  narrow: ["Ι", "Φ", "Μ", "Α", "Μ", "Ι", "Ι", "Α", "Σ", "Ο", "Ν", "Δ"],
  abbreviated: ["Ιαν", "Φεβ", "Μάρ", "Απρ", "Μάι", "Ιούν", "Ιούλ", "Αύγ", "Σεπ", "Οκτ", "Νοέ", "Δεκ"],
  wide: ["Ιανουάριος", "Φεβρουάριος", "Μάρτιος", "Απρίλιος", "Μάιος", "Ιούνιος", "Ιούλιος", "Αύγουστος", "Σεπτέμβριος", "Οκτώβριος", "Νοέμβριος", "Δεκέμβριος"]
};
var formattingMonthValues$c = {
  narrow: ["Ι", "Φ", "Μ", "Α", "Μ", "Ι", "Ι", "Α", "Σ", "Ο", "Ν", "Δ"],
  abbreviated: ["Ιαν", "Φεβ", "Μαρ", "Απρ", "Μαΐ", "Ιουν", "Ιουλ", "Αυγ", "Σεπ", "Οκτ", "Νοε", "Δεκ"],
  wide: ["Ιανουαρίου", "Φεβρουαρίου", "Μαρτίου", "Απριλίου", "Μαΐου", "Ιουνίου", "Ιουλίου", "Αυγούστου", "Σεπτεμβρίου", "Οκτωβρίου", "Νοεμβρίου", "Δεκεμβρίου"]
};
var dayValues$$ = {
  narrow: ["Κ", "Δ", "T", "Τ", "Π", "Π", "Σ"],
  short: ["Κυ", "Δε", "Τρ", "Τε", "Πέ", "Πα", "Σά"],
  abbreviated: ["Κυρ", "Δευ", "Τρί", "Τετ", "Πέμ", "Παρ", "Σάβ"],
  wide: ["Κυριακή", "Δευτέρα", "Τρίτη", "Τετάρτη", "Πέμπτη", "Παρασκευή", "Σάββατο"]
};
var dayPeriodValues$$ = {
  narrow: {
    am: "πμ",
    pm: "μμ",
    midnight: "μεσάνυχτα",
    noon: "μεσημέρι",
    morning: "πρωί",
    afternoon: "απόγευμα",
    evening: "βράδυ",
    night: "νύχτα"
  },
  abbreviated: {
    am: "π.μ.",
    pm: "μ.μ.",
    midnight: "μεσάνυχτα",
    noon: "μεσημέρι",
    morning: "πρωί",
    afternoon: "απόγευμα",
    evening: "βράδυ",
    night: "νύχτα"
  },
  wide: {
    am: "π.μ.",
    pm: "μ.μ.",
    midnight: "μεσάνυχτα",
    noon: "μεσημέρι",
    morning: "πρωί",
    afternoon: "απόγευμα",
    evening: "βράδυ",
    night: "νύχτα"
  }
};
var ordinalNumber$$ = function (e, a) {
  var t = Number(e);
  var r = a === null || a === void 0 ? void 0 : a.unit;
  var n;
  if (r === "year" || r === "month") {
    n = "ος";
  } else if (r === "week" || r === "dayOfYear" || r === "day" || r === "hour" || r === "date") {
    n = "η";
  } else {
    n = "ο";
  }
  return t + n;
};
localize$$.localize = {
  ordinalNumber: ordinalNumber$$,
  era: (0, _index$4r.buildLocalizeFn)({
    values: eraValues$$,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$4r.buildLocalizeFn)({
    values: quarterValues$$,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$4r.buildLocalizeFn)({
    values: monthValues$$,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$c,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$4r.buildLocalizeFn)({
    values: dayValues$$,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$4r.buildLocalizeFn)({
    values: dayPeriodValues$$,
    defaultWidth: "wide"
  })
};
var match$$ = {};
match$$.match = void 0;
var _index$4q = buildMatchFn;
var _index2$2a = buildMatchPatternFn;
var matchOrdinalNumberPattern$$ = /^(\d+)(ος|η|ο)?/i;
var parseOrdinalNumberPattern$$ = /\d+/i;
var matchEraPatterns$$ = {
  narrow: /^(πΧ|μΧ)/i,
  abbreviated: /^(π\.?\s?χ\.?|π\.?\s?κ\.?\s?χ\.?|μ\.?\s?χ\.?|κ\.?\s?χ\.?)/i,
  wide: /^(προ Χριστο(ύ|υ)|πριν απ(ό|ο) την Κοιν(ή|η) Χρονολογ(ί|ι)α|μετ(ά|α) Χριστ(ό|ο)ν|Κοιν(ή|η) Χρονολογ(ί|ι)α)/i
};
var parseEraPatterns$$ = {
  any: [/^π/i, /^(μ|κ)/i]
};
var matchQuarterPatterns$$ = {
  narrow: /^[1234]/i,
  abbreviated: /^τ[1234]/i,
  wide: /^[1234]ο? τρ(ί|ι)μηνο/i
};
var parseQuarterPatterns$$ = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$$ = {
  narrow: /^[ιφμαμιιασονδ]/i,
  abbreviated: /^(ιαν|φεβ|μ[άα]ρ|απρ|μ[άα][ιΐ]|ιο[ύυ]ν|ιο[ύυ]λ|α[ύυ]γ|σεπ|οκτ|νο[έε]|δεκ)/i,
  wide: /^(μ[άα][ιΐ]|α[ύυ]γο[υύ]στ)(ος|ου)|(ιανου[άα]ρ|φεβρου[άα]ρ|μ[άα]ρτ|απρ[ίι]λ|ιο[ύυ]ν|ιο[ύυ]λ|σεπτ[έε]μβρ|οκτ[ώω]βρ|νο[έε]μβρ|δεκ[έε]μβρ)(ιος|ίου)/i
};
var parseMonthPatterns$$ = {
  narrow: [/^ι/i, /^φ/i, /^μ/i, /^α/i, /^μ/i, /^ι/i, /^ι/i, /^α/i, /^σ/i, /^ο/i, /^ν/i, /^δ/i],
  any: [/^ια/i, /^φ/i, /^μ[άα]ρ/i, /^απ/i, /^μ[άα][ιΐ]/i, /^ιο[ύυ]ν/i, /^ιο[ύυ]λ/i, /^α[ύυ]/i, /^σ/i, /^ο/i, /^ν/i, /^δ/i]
};
var matchDayPatterns$$ = {
  narrow: /^[κδτπσ]/i,
  short: /^(κυ|δε|τρ|τε|π[εέ]|π[αά]|σ[αά])/i,
  abbreviated: /^(κυρ|δευ|τρι|τετ|πεμ|παρ|σαβ)/i,
  wide: /^(κυριακ(ή|η)|δευτ(έ|ε)ρα|τρ(ί|ι)τη|τετ(ά|α)ρτη|π(έ|ε)μπτη|παρασκευ(ή|η)|σ(ά|α)ββατο)/i
};
var parseDayPatterns$$ = {
  narrow: [/^κ/i, /^δ/i, /^τ/i, /^τ/i, /^π/i, /^π/i, /^σ/i],
  any: [/^κ/i, /^δ/i, /^τρ/i, /^τε/i, /^π[εέ]/i, /^π[αά]/i, /^σ/i]
};
var matchDayPeriodPatterns$$ = {
  narrow: /^(πμ|μμ|μεσ(ά|α)νυχτα|μεσημ(έ|ε)ρι|πρω(ί|ι)|απ(ό|ο)γευμα|βρ(ά|α)δυ|ν(ύ|υ)χτα)/i,
  any: /^([πμ]\.?\s?μ\.?|μεσ(ά|α)νυχτα|μεσημ(έ|ε)ρι|πρω(ί|ι)|απ(ό|ο)γευμα|βρ(ά|α)δυ|ν(ύ|υ)χτα)/i
};
var parseDayPeriodPatterns$$ = {
  any: {
    am: /^πμ|π\.\s?μ\./i,
    pm: /^μμ|μ\.\s?μ\./i,
    midnight: /^μεσάν/i,
    noon: /^μεσημ(έ|ε)/i,
    morning: /πρω(ί|ι)/i,
    afternoon: /απ(ό|ο)γευμα/i,
    evening: /βρ(ά|α)δυ/i,
    night: /ν(ύ|υ)χτα/i
  }
};
match$$.match = {
  ordinalNumber: (0, _index2$2a.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$$,
    parsePattern: parseOrdinalNumberPattern$$,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$4q.buildMatchFn)({
    matchPatterns: matchEraPatterns$$,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$$,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$4q.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$$,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$$,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$4q.buildMatchFn)({
    matchPatterns: matchMonthPatterns$$,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$$,
    defaultParseWidth: "any"
  }),
  day: (0, _index$4q.buildMatchFn)({
    matchPatterns: matchDayPatterns$$,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$$,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$4q.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$$,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$$,
    defaultParseWidth: "any"
  })
};
el.el = void 0;
var _index$4p = formatDistance$21;
var _index2$29 = formatLong$18;
var _index3$1a = formatRelative$21;
var _index4$19 = localize$$;
var _index5$19 = match$$;
el.el = {
  code: "el",
  formatDistance: _index$4p.formatDistance,
  formatLong: _index2$29.formatLong,
  formatRelative: _index3$1a.formatRelative,
  localize: _index4$19.localize,
  match: _index5$19.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var enAU = {};
var formatLong$17 = {};
formatLong$17.formatLong = void 0;
var _index$4o = buildFormatLongFn;
var dateFormats$17 = {
  full: "EEEE, d MMMM yyyy",
  long: "d MMMM yyyy",
  medium: "d MMM yyyy",
  short: "dd/MM/yyyy"
};
var timeFormats$17 = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$17 = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$17.formatLong = {
  date: (0, _index$4o.buildFormatLongFn)({
    formats: dateFormats$17,
    defaultWidth: "full"
  }),
  time: (0, _index$4o.buildFormatLongFn)({
    formats: timeFormats$17,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4o.buildFormatLongFn)({
    formats: dateTimeFormats$17,
    defaultWidth: "full"
  })
};
enAU.enAU = void 0;
var _index$4n = formatDistance$2E;
var _index2$28 = formatLong$17;
var _index3$19 = formatRelative$2E;
var _index4$18 = localize$1k;
var _index5$18 = match$1j;
enAU.enAU = {
  code: "en-AU",
  formatDistance: _index$4n.formatDistance,
  formatLong: _index2$28.formatLong,
  formatRelative: _index3$19.formatRelative,
  localize: _index4$18.localize,
  match: _index5$18.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var enCA = {};
var formatDistance$1$ = {};
formatDistance$1$.formatDistance = void 0;
var formatDistanceLocale$_ = {
  lessThanXSeconds: {
    one: "less than a second",
    other: "less than {{count}} seconds"
  },
  xSeconds: {
    one: "a second",
    other: "{{count}} seconds"
  },
  halfAMinute: "half a minute",
  lessThanXMinutes: {
    one: "less than a minute",
    other: "less than {{count}} minutes"
  },
  xMinutes: {
    one: "a minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "about an hour",
    other: "about {{count}} hours"
  },
  xHours: {
    one: "an hour",
    other: "{{count}} hours"
  },
  xDays: {
    one: "a day",
    other: "{{count}} days"
  },
  aboutXWeeks: {
    one: "about a week",
    other: "about {{count}} weeks"
  },
  xWeeks: {
    one: "a week",
    other: "{{count}} weeks"
  },
  aboutXMonths: {
    one: "about a month",
    other: "about {{count}} months"
  },
  xMonths: {
    one: "a month",
    other: "{{count}} months"
  },
  aboutXYears: {
    one: "about a year",
    other: "about {{count}} years"
  },
  xYears: {
    one: "a year",
    other: "{{count}} years"
  },
  overXYears: {
    one: "over a year",
    other: "over {{count}} years"
  },
  almostXYears: {
    one: "almost a year",
    other: "almost {{count}} years"
  }
};
var formatDistance$1_ = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$_[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", a.toString());
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "in " + r;
    } else {
      return r + " ago";
    }
  }
  return r;
};
formatDistance$1$.formatDistance = formatDistance$1_;
var formatLong$16 = {};
formatLong$16.formatLong = void 0;
var _index$4m = buildFormatLongFn;
var dateFormats$16 = {
  full: "EEEE, MMMM do, yyyy",
  long: "MMMM do, yyyy",
  medium: "MMM d, yyyy",
  short: "yyyy-MM-dd"
};
var timeFormats$16 = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$16 = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$16.formatLong = {
  date: (0, _index$4m.buildFormatLongFn)({
    formats: dateFormats$16,
    defaultWidth: "full"
  }),
  time: (0, _index$4m.buildFormatLongFn)({
    formats: timeFormats$16,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4m.buildFormatLongFn)({
    formats: dateTimeFormats$16,
    defaultWidth: "full"
  })
};
enCA.enCA = void 0;
var _index$4l = formatRelative$2E;
var _index2$27 = localize$1k;
var _index3$18 = match$1j;
var _index4$17 = formatDistance$1$;
var _index5$17 = formatLong$16;
enCA.enCA = {
  code: "en-CA",
  formatDistance: _index4$17.formatDistance,
  formatLong: _index5$17.formatLong,
  formatRelative: _index$4l.formatRelative,
  localize: _index2$27.localize,
  match: _index3$18.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var enGB = {};
var formatLong$15 = {};
formatLong$15.formatLong = void 0;
var _index$4k = buildFormatLongFn;
var dateFormats$15 = {
  full: "EEEE, d MMMM yyyy",
  long: "d MMMM yyyy",
  medium: "d MMM yyyy",
  short: "dd/MM/yyyy"
};
var timeFormats$15 = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$15 = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$15.formatLong = {
  date: (0, _index$4k.buildFormatLongFn)({
    formats: dateFormats$15,
    defaultWidth: "full"
  }),
  time: (0, _index$4k.buildFormatLongFn)({
    formats: timeFormats$15,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4k.buildFormatLongFn)({
    formats: dateTimeFormats$15,
    defaultWidth: "full"
  })
};
enGB.enGB = void 0;
var _index$4j = formatDistance$2E;
var _index2$26 = formatRelative$2E;
var _index3$17 = localize$1k;
var _index4$16 = match$1j;
var _index5$16 = formatLong$15;
enGB.enGB = {
  code: "en-GB",
  formatDistance: _index$4j.formatDistance,
  formatLong: _index5$16.formatLong,
  formatRelative: _index2$26.formatRelative,
  localize: _index3$17.localize,
  match: _index4$16.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var enIE = {};
enIE.enIE = void 0;
var _index$4i = formatDistance$2E;
var _index2$25 = formatRelative$2E;
var _index3$16 = localize$1k;
var _index4$15 = match$1j;
var _index5$15 = formatLong$15;
enIE.enIE = {
  code: "en-IE",
  formatDistance: _index$4i.formatDistance,
  formatLong: _index5$15.formatLong,
  formatRelative: _index2$25.formatRelative,
  localize: _index3$16.localize,
  match: _index4$15.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var enIN = {};
var formatLong$14 = {};
formatLong$14.formatLong = void 0;
var _index$4h = buildFormatLongFn;
var dateFormats$14 = {
  full: "EEEE, d MMMM yyyy",
  long: "d MMMM, yyyy",
  medium: "d MMM, yyyy",
  short: "dd/MM/yyyy"
};
var timeFormats$14 = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$14 = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$14.formatLong = {
  date: (0, _index$4h.buildFormatLongFn)({
    formats: dateFormats$14,
    defaultWidth: "full"
  }),
  time: (0, _index$4h.buildFormatLongFn)({
    formats: timeFormats$14,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4h.buildFormatLongFn)({
    formats: dateTimeFormats$14,
    defaultWidth: "full"
  })
};
enIN.enIN = void 0;
var _index$4g = formatDistance$2E;
var _index2$24 = formatRelative$2E;
var _index3$15 = localize$1k;
var _index4$14 = match$1j;
var _index5$14 = formatLong$14;
enIN.enIN = {
  code: "en-IN",
  formatDistance: _index$4g.formatDistance,
  formatLong: _index5$14.formatLong,
  formatRelative: _index2$24.formatRelative,
  localize: _index3$15.localize,
  match: _index4$14.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var enNZ = {};
var formatLong$13 = {};
formatLong$13.formatLong = void 0;
var _index$4f = buildFormatLongFn;
var dateFormats$13 = {
  full: "EEEE, d MMMM yyyy",
  long: "d MMMM yyyy",
  medium: "d MMM yyyy",
  short: "dd/MM/yyyy"
};
var timeFormats$13 = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$13 = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$13.formatLong = {
  date: (0, _index$4f.buildFormatLongFn)({
    formats: dateFormats$13,
    defaultWidth: "full"
  }),
  time: (0, _index$4f.buildFormatLongFn)({
    formats: timeFormats$13,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4f.buildFormatLongFn)({
    formats: dateTimeFormats$13,
    defaultWidth: "full"
  })
};
enNZ.enNZ = void 0;
var _index$4e = formatDistance$2E;
var _index2$23 = formatRelative$2E;
var _index3$14 = localize$1k;
var _index4$13 = match$1j;
var _index5$13 = formatLong$13;
enNZ.enNZ = {
  code: "en-NZ",
  formatDistance: _index$4e.formatDistance,
  formatLong: _index5$13.formatLong,
  formatRelative: _index2$23.formatRelative,
  localize: _index3$14.localize,
  match: _index4$13.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var enZA = {};
var formatLong$12 = {};
formatLong$12.formatLong = void 0;
var _index$4d = buildFormatLongFn;
var dateFormats$12 = {
  full: "EEEE, dd MMMM yyyy",
  long: "dd MMMM yyyy",
  medium: "dd MMM yyyy",
  short: "yyyy/MM/dd"
};
var timeFormats$12 = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$12 = {
  full: "{{date}} 'at' {{time}}",
  long: "{{date}} 'at' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$12.formatLong = {
  date: (0, _index$4d.buildFormatLongFn)({
    formats: dateFormats$12,
    defaultWidth: "full"
  }),
  time: (0, _index$4d.buildFormatLongFn)({
    formats: timeFormats$12,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4d.buildFormatLongFn)({
    formats: dateTimeFormats$12,
    defaultWidth: "full"
  })
};
enZA.enZA = void 0;
var _index$4c = formatDistance$2E;
var _index2$22 = formatRelative$2E;
var _index3$13 = localize$1k;
var _index4$12 = match$1j;
var _index5$12 = formatLong$12;
enZA.enZA = {
  code: "en-ZA",
  formatDistance: _index$4c.formatDistance,
  formatLong: _index5$12.formatLong,
  formatRelative: _index2$22.formatRelative,
  localize: _index3$13.localize,
  match: _index4$12.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var eo = {};
var formatDistance$1Z = {};
formatDistance$1Z.formatDistance = void 0;
var formatDistanceLocale$Z = {
  lessThanXSeconds: {
    one: "malpli ol sekundo",
    other: "malpli ol {{count}} sekundoj"
  },
  xSeconds: {
    one: "1 sekundo",
    other: "{{count}} sekundoj"
  },
  halfAMinute: "duonminuto",
  lessThanXMinutes: {
    one: "malpli ol minuto",
    other: "malpli ol {{count}} minutoj"
  },
  xMinutes: {
    one: "1 minuto",
    other: "{{count}} minutoj"
  },
  aboutXHours: {
    one: "proksimume 1 horo",
    other: "proksimume {{count}} horoj"
  },
  xHours: {
    one: "1 horo",
    other: "{{count}} horoj"
  },
  xDays: {
    one: "1 tago",
    other: "{{count}} tagoj"
  },
  aboutXMonths: {
    one: "proksimume 1 monato",
    other: "proksimume {{count}} monatoj"
  },
  xWeeks: {
    one: "1 semajno",
    other: "{{count}} semajnoj"
  },
  aboutXWeeks: {
    one: "proksimume 1 semajno",
    other: "proksimume {{count}} semajnoj"
  },
  xMonths: {
    one: "1 monato",
    other: "{{count}} monatoj"
  },
  aboutXYears: {
    one: "proksimume 1 jaro",
    other: "proksimume {{count}} jaroj"
  },
  xYears: {
    one: "1 jaro",
    other: "{{count}} jaroj"
  },
  overXYears: {
    one: "pli ol 1 jaro",
    other: "pli ol {{count}} jaroj"
  },
  almostXYears: {
    one: "preskaŭ 1 jaro",
    other: "preskaŭ {{count}} jaroj"
  }
};
var formatDistance$1Y = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$Z[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if ((t === null || t === void 0 ? void 0 : t.comparison) && t.comparison > 0) {
      return "post " + r;
    } else {
      return "antaŭ " + r;
    }
  }
  return r;
};
formatDistance$1Z.formatDistance = formatDistance$1Y;
var formatLong$11 = {};
formatLong$11.formatLong = void 0;
var _index$4b = buildFormatLongFn;
var dateFormats$11 = {
  full: "EEEE, do 'de' MMMM y",
  long: "y-MMMM-dd",
  medium: "y-MMM-dd",
  short: "yyyy-MM-dd"
};
var timeFormats$11 = {
  full: "Ho 'horo kaj' m:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$11 = {
  any: "{{date}} {{time}}"
};
formatLong$11.formatLong = {
  date: (0, _index$4b.buildFormatLongFn)({
    formats: dateFormats$11,
    defaultWidth: "full"
  }),
  time: (0, _index$4b.buildFormatLongFn)({
    formats: timeFormats$11,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$4b.buildFormatLongFn)({
    formats: dateTimeFormats$11,
    defaultWidth: "any"
  })
};
var formatRelative$1$ = {};
formatRelative$1$.formatRelative = void 0;
var formatRelativeLocale$$ = {
  lastWeek: "'pasinta' eeee 'je' p",
  yesterday: "'hieraŭ je' p",
  today: "'hodiaŭ je' p",
  tomorrow: "'morgaŭ je' p",
  nextWeek: "eeee 'je' p",
  other: "P"
};
var formatRelative$1_ = function (e, a, t, r) {
  return formatRelativeLocale$$[e];
};
formatRelative$1$.formatRelative = formatRelative$1_;
var localize$_ = {};
localize$_.localize = void 0;
var _index$4a = buildLocalizeFn;
var eraValues$_ = {
  narrow: ["aK", "pK"],
  abbreviated: ["a.K.E.", "p.K.E."],
  wide: ["antaŭ Komuna Erao", "Komuna Erao"]
};
var quarterValues$_ = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["K1", "K2", "K3", "K4"],
  wide: ["1-a kvaronjaro", "2-a kvaronjaro", "3-a kvaronjaro", "4-a kvaronjaro"]
};
var monthValues$_ = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aŭg", "sep", "okt", "nov", "dec"],
  wide: ["januaro", "februaro", "marto", "aprilo", "majo", "junio", "julio", "aŭgusto", "septembro", "oktobro", "novembro", "decembro"]
};
var dayValues$_ = {
  narrow: ["D", "L", "M", "M", "Ĵ", "V", "S"],
  short: ["di", "lu", "ma", "me", "ĵa", "ve", "sa"],
  abbreviated: ["dim", "lun", "mar", "mer", "ĵaŭ", "ven", "sab"],
  wide: ["dimanĉo", "lundo", "mardo", "merkredo", "ĵaŭdo", "vendredo", "sabato"]
};
var dayPeriodValues$_ = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "noktomezo",
    noon: "tagmezo",
    morning: "matene",
    afternoon: "posttagmeze",
    evening: "vespere",
    night: "nokte"
  },
  abbreviated: {
    am: "a.t.m.",
    pm: "p.t.m.",
    midnight: "noktomezo",
    noon: "tagmezo",
    morning: "matene",
    afternoon: "posttagmeze",
    evening: "vespere",
    night: "nokte"
  },
  wide: {
    am: "antaŭtagmeze",
    pm: "posttagmeze",
    midnight: "noktomezo",
    noon: "tagmezo",
    morning: "matene",
    afternoon: "posttagmeze",
    evening: "vespere",
    night: "nokte"
  }
};
var ordinalNumber$_ = function (e) {
  var a = Number(e);
  return a + "-a";
};
localize$_.localize = {
  ordinalNumber: ordinalNumber$_,
  era: (0, _index$4a.buildLocalizeFn)({
    values: eraValues$_,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$4a.buildLocalizeFn)({
    values: quarterValues$_,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return Number(e) - 1;
    }
  }),
  month: (0, _index$4a.buildLocalizeFn)({
    values: monthValues$_,
    defaultWidth: "wide"
  }),
  day: (0, _index$4a.buildLocalizeFn)({
    values: dayValues$_,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$4a.buildLocalizeFn)({
    values: dayPeriodValues$_,
    defaultWidth: "wide"
  })
};
var match$_ = {};
match$_.match = void 0;
var _index$49 = buildMatchPatternFn;
var _index2$21 = buildMatchFn;
var matchOrdinalNumberPattern$_ = /^(\d+)(-?a)?/i;
var parseOrdinalNumberPattern$_ = /\d+/i;
var matchEraPatterns$_ = {
  narrow: /^([ap]k)/i,
  abbreviated: /^([ap]\.?\s?k\.?\s?e\.?)/i,
  wide: /^((antaǔ |post )?komuna erao)/i
};
var parseEraPatterns$_ = {
  any: [/^a/i, /^[kp]/i]
};
var matchQuarterPatterns$_ = {
  narrow: /^[1234]/i,
  abbreviated: /^k[1234]/i,
  wide: /^[1234](-?a)? kvaronjaro/i
};
var parseQuarterPatterns$_ = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$_ = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|maj|jun|jul|a(ŭ|ux|uh|u)g|sep|okt|nov|dec)/i,
  wide: /^(januaro|februaro|marto|aprilo|majo|junio|julio|a(ŭ|ux|uh|u)gusto|septembro|oktobro|novembro|decembro)/i
};
var parseMonthPatterns$_ = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^maj/i, /^jun/i, /^jul/i, /^a(u|ŭ)/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$_ = {
  narrow: /^[dlmĵjvs]/i,
  short: /^(di|lu|ma|me|(ĵ|jx|jh|j)a|ve|sa)/i,
  abbreviated: /^(dim|lun|mar|mer|(ĵ|jx|jh|j)a(ŭ|ux|uh|u)|ven|sab)/i,
  wide: /^(diman(ĉ|cx|ch|c)o|lundo|mardo|merkredo|(ĵ|jx|jh|j)a(ŭ|ux|uh|u)do|vendredo|sabato)/i
};
var parseDayPatterns$_ = {
  narrow: [/^d/i, /^l/i, /^m/i, /^m/i, /^(j|ĵ)/i, /^v/i, /^s/i],
  any: [/^d/i, /^l/i, /^ma/i, /^me/i, /^(j|ĵ)/i, /^v/i, /^s/i]
};
var matchDayPeriodPatterns$_ = {
  narrow: /^([ap]|(posttagmez|noktomez|tagmez|maten|vesper|nokt)[eo])/i,
  abbreviated: /^([ap][.\s]?t[.\s]?m[.\s]?|(posttagmez|noktomez|tagmez|maten|vesper|nokt)[eo])/i,
  wide: /^(anta(ŭ|ux)tagmez|posttagmez|noktomez|tagmez|maten|vesper|nokt)[eo]/i
};
var parseDayPeriodPatterns$_ = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^noktom/i,
    noon: /^t/i,
    morning: /^m/i,
    afternoon: /^posttagmeze/i,
    evening: /^v/i,
    night: /^n/i
  }
};
match$_.match = {
  ordinalNumber: (0, _index$49.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$_,
    parsePattern: parseOrdinalNumberPattern$_,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index2$21.buildMatchFn)({
    matchPatterns: matchEraPatterns$_,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$_,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index2$21.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$_,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$_,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index2$21.buildMatchFn)({
    matchPatterns: matchMonthPatterns$_,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$_,
    defaultParseWidth: "any"
  }),
  day: (0, _index2$21.buildMatchFn)({
    matchPatterns: matchDayPatterns$_,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$_,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index2$21.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$_,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$_,
    defaultParseWidth: "any"
  })
};
eo.eo = void 0;
var _index$48 = formatDistance$1Z;
var _index2$20 = formatLong$11;
var _index3$12 = formatRelative$1$;
var _index4$11 = localize$_;
var _index5$11 = match$_;
eo.eo = {
  code: "eo",
  formatDistance: _index$48.formatDistance,
  formatLong: _index2$20.formatLong,
  formatRelative: _index3$12.formatRelative,
  localize: _index4$11.localize,
  match: _index5$11.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var es = {};
var formatDistance$1X = {};
formatDistance$1X.formatDistance = void 0;
var formatDistanceLocale$Y = {
  lessThanXSeconds: {
    one: "menos de un segundo",
    other: "menos de {{count}} segundos"
  },
  xSeconds: {
    one: "1 segundo",
    other: "{{count}} segundos"
  },
  halfAMinute: "medio minuto",
  lessThanXMinutes: {
    one: "menos de un minuto",
    other: "menos de {{count}} minutos"
  },
  xMinutes: {
    one: "1 minuto",
    other: "{{count}} minutos"
  },
  aboutXHours: {
    one: "alrededor de 1 hora",
    other: "alrededor de {{count}} horas"
  },
  xHours: {
    one: "1 hora",
    other: "{{count}} horas"
  },
  xDays: {
    one: "1 día",
    other: "{{count}} días"
  },
  aboutXWeeks: {
    one: "alrededor de 1 semana",
    other: "alrededor de {{count}} semanas"
  },
  xWeeks: {
    one: "1 semana",
    other: "{{count}} semanas"
  },
  aboutXMonths: {
    one: "alrededor de 1 mes",
    other: "alrededor de {{count}} meses"
  },
  xMonths: {
    one: "1 mes",
    other: "{{count}} meses"
  },
  aboutXYears: {
    one: "alrededor de 1 año",
    other: "alrededor de {{count}} años"
  },
  xYears: {
    one: "1 año",
    other: "{{count}} años"
  },
  overXYears: {
    one: "más de 1 año",
    other: "más de {{count}} años"
  },
  almostXYears: {
    one: "casi 1 año",
    other: "casi {{count}} años"
  }
};
var formatDistance$1W = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$Y[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", a.toString());
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "en " + r;
    } else {
      return "hace " + r;
    }
  }
  return r;
};
formatDistance$1X.formatDistance = formatDistance$1W;
var formatLong$10 = {};
formatLong$10.formatLong = void 0;
var _index$47 = buildFormatLongFn;
var dateFormats$10 = {
  full: "EEEE, d 'de' MMMM 'de' y",
  long: "d 'de' MMMM 'de' y",
  medium: "d MMM y",
  short: "dd/MM/y"
};
var timeFormats$10 = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$10 = {
  full: "{{date}} 'a las' {{time}}",
  long: "{{date}} 'a las' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$10.formatLong = {
  date: (0, _index$47.buildFormatLongFn)({
    formats: dateFormats$10,
    defaultWidth: "full"
  }),
  time: (0, _index$47.buildFormatLongFn)({
    formats: timeFormats$10,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$47.buildFormatLongFn)({
    formats: dateTimeFormats$10,
    defaultWidth: "full"
  })
};
var formatRelative$1Z = {};
formatRelative$1Z.formatRelative = void 0;
var formatRelativeLocale$_ = {
  lastWeek: "'el' eeee 'pasado a la' p",
  yesterday: "'ayer a la' p",
  today: "'hoy a la' p",
  tomorrow: "'mañana a la' p",
  nextWeek: "eeee 'a la' p",
  other: "P"
};
var formatRelativeLocalePlural$2 = {
  lastWeek: "'el' eeee 'pasado a las' p",
  yesterday: "'ayer a las' p",
  today: "'hoy a las' p",
  tomorrow: "'mañana a las' p",
  nextWeek: "eeee 'a las' p",
  other: "P"
};
var formatRelative$1Y = function (e, a, t, r) {
  if (a.getHours() !== 1) {
    return formatRelativeLocalePlural$2[e];
  } else {
    return formatRelativeLocale$_[e];
  }
};
formatRelative$1Z.formatRelative = formatRelative$1Y;
var localize$Z = {};
localize$Z.localize = void 0;
var _index$46 = buildLocalizeFn;
var eraValues$Z = {
  narrow: ["AC", "DC"],
  abbreviated: ["AC", "DC"],
  wide: ["antes de cristo", "después de cristo"]
};
var quarterValues$Z = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["T1", "T2", "T3", "T4"],
  wide: ["1º trimestre", "2º trimestre", "3º trimestre", "4º trimestre"]
};
var monthValues$Z = {
  narrow: ["e", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
  abbreviated: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"],
  wide: ["enero", "febrero", "marzo", "abril", "mayo", "junio", "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"]
};
var dayValues$Z = {
  narrow: ["d", "l", "m", "m", "j", "v", "s"],
  short: ["do", "lu", "ma", "mi", "ju", "vi", "sá"],
  abbreviated: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
  wide: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"]
};
var dayPeriodValues$Z = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mn",
    noon: "md",
    morning: "mañana",
    afternoon: "tarde",
    evening: "tarde",
    night: "noche"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "medianoche",
    noon: "mediodia",
    morning: "mañana",
    afternoon: "tarde",
    evening: "tarde",
    night: "noche"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "medianoche",
    noon: "mediodia",
    morning: "mañana",
    afternoon: "tarde",
    evening: "tarde",
    night: "noche"
  }
};
var formattingDayPeriodValues$M = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mn",
    noon: "md",
    morning: "de la mañana",
    afternoon: "de la tarde",
    evening: "de la tarde",
    night: "de la noche"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "medianoche",
    noon: "mediodia",
    morning: "de la mañana",
    afternoon: "de la tarde",
    evening: "de la tarde",
    night: "de la noche"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "medianoche",
    noon: "mediodia",
    morning: "de la mañana",
    afternoon: "de la tarde",
    evening: "de la tarde",
    night: "de la noche"
  }
};
var ordinalNumber$Z = function (e, a) {
  var t = Number(e);
  return t + "º";
};
localize$Z.localize = {
  ordinalNumber: ordinalNumber$Z,
  era: (0, _index$46.buildLocalizeFn)({
    values: eraValues$Z,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$46.buildLocalizeFn)({
    values: quarterValues$Z,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return Number(e) - 1;
    }
  }),
  month: (0, _index$46.buildLocalizeFn)({
    values: monthValues$Z,
    defaultWidth: "wide"
  }),
  day: (0, _index$46.buildLocalizeFn)({
    values: dayValues$Z,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$46.buildLocalizeFn)({
    values: dayPeriodValues$Z,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$M,
    defaultFormattingWidth: "wide"
  })
};
var match$Z = {};
match$Z.match = void 0;
var _index$45 = buildMatchPatternFn;
var _index2$1$ = buildMatchFn;
var matchOrdinalNumberPattern$Z = /^(\d+)(º)?/i;
var parseOrdinalNumberPattern$Z = /\d+/i;
var matchEraPatterns$Z = {
  narrow: /^(ac|dc|a|d)/i,
  abbreviated: /^(a\.?\s?c\.?|a\.?\s?e\.?\s?c\.?|d\.?\s?c\.?|e\.?\s?c\.?)/i,
  wide: /^(antes de cristo|antes de la era com[uú]n|despu[eé]s de cristo|era com[uú]n)/i
};
var parseEraPatterns$Z = {
  any: [/^ac/i, /^dc/i],
  wide: [/^(antes de cristo|antes de la era com[uú]n)/i, /^(despu[eé]s de cristo|era com[uú]n)/i]
};
var matchQuarterPatterns$Z = {
  narrow: /^[1234]/i,
  abbreviated: /^T[1234]/i,
  wide: /^[1234](º)? trimestre/i
};
var parseQuarterPatterns$Z = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$Z = {
  narrow: /^[efmajsond]/i,
  abbreviated: /^(ene|feb|mar|abr|may|jun|jul|ago|sep|oct|nov|dic)/i,
  wide: /^(enero|febrero|marzo|abril|mayo|junio|julio|agosto|septiembre|octubre|noviembre|diciembre)/i
};
var parseMonthPatterns$Z = {
  narrow: [/^e/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^en/i, /^feb/i, /^mar/i, /^abr/i, /^may/i, /^jun/i, /^jul/i, /^ago/i, /^sep/i, /^oct/i, /^nov/i, /^dic/i]
};
var matchDayPatterns$Z = {
  narrow: /^[dlmjvs]/i,
  short: /^(do|lu|ma|mi|ju|vi|s[áa])/i,
  abbreviated: /^(dom|lun|mar|mi[ée]|jue|vie|s[áa]b)/i,
  wide: /^(domingo|lunes|martes|mi[ée]rcoles|jueves|viernes|s[áa]bado)/i
};
var parseDayPatterns$Z = {
  narrow: [/^d/i, /^l/i, /^m/i, /^m/i, /^j/i, /^v/i, /^s/i],
  any: [/^do/i, /^lu/i, /^ma/i, /^mi/i, /^ju/i, /^vi/i, /^sa/i]
};
var matchDayPeriodPatterns$Z = {
  narrow: /^(a|p|mn|md|(de la|a las) (mañana|tarde|noche))/i,
  any: /^([ap]\.?\s?m\.?|medianoche|mediodia|(de la|a las) (mañana|tarde|noche))/i
};
var parseDayPeriodPatterns$Z = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mn/i,
    noon: /^md/i,
    morning: /mañana/i,
    afternoon: /tarde/i,
    evening: /tarde/i,
    night: /noche/i
  }
};
match$Z.match = {
  ordinalNumber: (0, _index$45.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$Z,
    parsePattern: parseOrdinalNumberPattern$Z,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index2$1$.buildMatchFn)({
    matchPatterns: matchEraPatterns$Z,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$Z,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index2$1$.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$Z,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$Z,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index2$1$.buildMatchFn)({
    matchPatterns: matchMonthPatterns$Z,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$Z,
    defaultParseWidth: "any"
  }),
  day: (0, _index2$1$.buildMatchFn)({
    matchPatterns: matchDayPatterns$Z,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$Z,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index2$1$.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$Z,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$Z,
    defaultParseWidth: "any"
  })
};
es.es = void 0;
var _index$44 = formatDistance$1X;
var _index2$1_ = formatLong$10;
var _index3$11 = formatRelative$1Z;
var _index4$10 = localize$Z;
var _index5$10 = match$Z;
es.es = {
  code: "es",
  formatDistance: _index$44.formatDistance,
  formatLong: _index2$1_.formatLong,
  formatRelative: _index3$11.formatRelative,
  localize: _index4$10.localize,
  match: _index5$10.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var et = {};
var formatDistance$1V = {};
formatDistance$1V.formatDistance = void 0;
var formatDistanceLocale$X = {
  lessThanXSeconds: {
    standalone: {
      one: "vähem kui üks sekund",
      other: "vähem kui {{count}} sekundit"
    },
    withPreposition: {
      one: "vähem kui ühe sekundi",
      other: "vähem kui {{count}} sekundi"
    }
  },
  xSeconds: {
    standalone: {
      one: "üks sekund",
      other: "{{count}} sekundit"
    },
    withPreposition: {
      one: "ühe sekundi",
      other: "{{count}} sekundi"
    }
  },
  halfAMinute: {
    standalone: "pool minutit",
    withPreposition: "poole minuti"
  },
  lessThanXMinutes: {
    standalone: {
      one: "vähem kui üks minut",
      other: "vähem kui {{count}} minutit"
    },
    withPreposition: {
      one: "vähem kui ühe minuti",
      other: "vähem kui {{count}} minuti"
    }
  },
  xMinutes: {
    standalone: {
      one: "üks minut",
      other: "{{count}} minutit"
    },
    withPreposition: {
      one: "ühe minuti",
      other: "{{count}} minuti"
    }
  },
  aboutXHours: {
    standalone: {
      one: "umbes üks tund",
      other: "umbes {{count}} tundi"
    },
    withPreposition: {
      one: "umbes ühe tunni",
      other: "umbes {{count}} tunni"
    }
  },
  xHours: {
    standalone: {
      one: "üks tund",
      other: "{{count}} tundi"
    },
    withPreposition: {
      one: "ühe tunni",
      other: "{{count}} tunni"
    }
  },
  xDays: {
    standalone: {
      one: "üks päev",
      other: "{{count}} päeva"
    },
    withPreposition: {
      one: "ühe päeva",
      other: "{{count}} päeva"
    }
  },
  aboutXWeeks: {
    standalone: {
      one: "umbes üks nädal",
      other: "umbes {{count}} nädalat"
    },
    withPreposition: {
      one: "umbes ühe nädala",
      other: "umbes {{count}} nädala"
    }
  },
  xWeeks: {
    standalone: {
      one: "üks nädal",
      other: "{{count}} nädalat"
    },
    withPreposition: {
      one: "ühe nädala",
      other: "{{count}} nädala"
    }
  },
  aboutXMonths: {
    standalone: {
      one: "umbes üks kuu",
      other: "umbes {{count}} kuud"
    },
    withPreposition: {
      one: "umbes ühe kuu",
      other: "umbes {{count}} kuu"
    }
  },
  xMonths: {
    standalone: {
      one: "üks kuu",
      other: "{{count}} kuud"
    },
    withPreposition: {
      one: "ühe kuu",
      other: "{{count}} kuu"
    }
  },
  aboutXYears: {
    standalone: {
      one: "umbes üks aasta",
      other: "umbes {{count}} aastat"
    },
    withPreposition: {
      one: "umbes ühe aasta",
      other: "umbes {{count}} aasta"
    }
  },
  xYears: {
    standalone: {
      one: "üks aasta",
      other: "{{count}} aastat"
    },
    withPreposition: {
      one: "ühe aasta",
      other: "{{count}} aasta"
    }
  },
  overXYears: {
    standalone: {
      one: "rohkem kui üks aasta",
      other: "rohkem kui {{count}} aastat"
    },
    withPreposition: {
      one: "rohkem kui ühe aasta",
      other: "rohkem kui {{count}} aasta"
    }
  },
  almostXYears: {
    standalone: {
      one: "peaaegu üks aasta",
      other: "peaaegu {{count}} aastat"
    },
    withPreposition: {
      one: "peaaegu ühe aasta",
      other: "peaaegu {{count}} aasta"
    }
  }
};
var formatDistance$1U = function (e, a, t) {
  var r = (t === null || t === void 0 ? void 0 : t.addSuffix) ? formatDistanceLocale$X[e].withPreposition : formatDistanceLocale$X[e].standalone;
  var n;
  if (typeof r === "string") {
    n = r;
  } else if (a === 1) {
    n = r.one;
  } else {
    n = r.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return n + " pärast";
    } else {
      return n + " eest";
    }
  }
  return n;
};
formatDistance$1V.formatDistance = formatDistance$1U;
var formatLong$$ = {};
formatLong$$.formatLong = void 0;
var _index$43 = buildFormatLongFn;
var dateFormats$$ = {
  full: "EEEE, d. MMMM y",
  long: "d. MMMM y",
  medium: "d. MMM y",
  short: "dd.MM.y"
};
var timeFormats$$ = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$$ = {
  full: "{{date}} 'kell' {{time}}",
  long: "{{date}} 'kell' {{time}}",
  medium: "{{date}}. {{time}}",
  short: "{{date}}. {{time}}"
};
formatLong$$.formatLong = {
  date: (0, _index$43.buildFormatLongFn)({
    formats: dateFormats$$,
    defaultWidth: "full"
  }),
  time: (0, _index$43.buildFormatLongFn)({
    formats: timeFormats$$,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$43.buildFormatLongFn)({
    formats: dateTimeFormats$$,
    defaultWidth: "full"
  })
};
var formatRelative$1X = {};
formatRelative$1X.formatRelative = void 0;
var formatRelativeLocale$Z = {
  lastWeek: "'eelmine' eeee 'kell' p",
  yesterday: "'eile kell' p",
  today: "'täna kell' p",
  tomorrow: "'homme kell' p",
  nextWeek: "'järgmine' eeee 'kell' p",
  other: "P"
};
var formatRelative$1W = function (e, a, t, r) {
  return formatRelativeLocale$Z[e];
};
formatRelative$1X.formatRelative = formatRelative$1W;
var localize$Y = {};
localize$Y.localize = void 0;
var _index$42 = buildLocalizeFn;
var eraValues$Y = {
  narrow: ["e.m.a", "m.a.j"],
  abbreviated: ["e.m.a", "m.a.j"],
  wide: ["enne meie ajaarvamist", "meie ajaarvamise järgi"]
};
var quarterValues$Y = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["K1", "K2", "K3", "K4"],
  wide: ["1. kvartal", "2. kvartal", "3. kvartal", "4. kvartal"]
};
var monthValues$Y = {
  narrow: ["J", "V", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["jaan", "veebr", "märts", "apr", "mai", "juuni", "juuli", "aug", "sept", "okt", "nov", "dets"],
  wide: ["jaanuar", "veebruar", "märts", "aprill", "mai", "juuni", "juuli", "august", "september", "oktoober", "november", "detsember"]
};
var dayValues$Y = {
  narrow: ["P", "E", "T", "K", "N", "R", "L"],
  short: ["P", "E", "T", "K", "N", "R", "L"],
  abbreviated: ["pühap.", "esmasp.", "teisip.", "kolmap.", "neljap.", "reede.", "laup."],
  wide: ["pühapäev", "esmaspäev", "teisipäev", "kolmapäev", "neljapäev", "reede", "laupäev"]
};
var dayPeriodValues$Y = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "kesköö",
    noon: "keskpäev",
    morning: "hommik",
    afternoon: "pärastlõuna",
    evening: "õhtu",
    night: "öö"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "kesköö",
    noon: "keskpäev",
    morning: "hommik",
    afternoon: "pärastlõuna",
    evening: "õhtu",
    night: "öö"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "kesköö",
    noon: "keskpäev",
    morning: "hommik",
    afternoon: "pärastlõuna",
    evening: "õhtu",
    night: "öö"
  }
};
var formattingDayPeriodValues$L = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "keskööl",
    noon: "keskpäeval",
    morning: "hommikul",
    afternoon: "pärastlõunal",
    evening: "õhtul",
    night: "öösel"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "keskööl",
    noon: "keskpäeval",
    morning: "hommikul",
    afternoon: "pärastlõunal",
    evening: "õhtul",
    night: "öösel"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "keskööl",
    noon: "keskpäeval",
    morning: "hommikul",
    afternoon: "pärastlõunal",
    evening: "õhtul",
    night: "öösel"
  }
};
var ordinalNumber$Y = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$Y.localize = {
  ordinalNumber: ordinalNumber$Y,
  era: (0, _index$42.buildLocalizeFn)({
    values: eraValues$Y,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$42.buildLocalizeFn)({
    values: quarterValues$Y,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$42.buildLocalizeFn)({
    values: monthValues$Y,
    defaultWidth: "wide",
    formattingValues: monthValues$Y,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$42.buildLocalizeFn)({
    values: dayValues$Y,
    defaultWidth: "wide",
    formattingValues: dayValues$Y,
    defaultFormattingWidth: "wide"
  }),
  dayPeriod: (0, _index$42.buildLocalizeFn)({
    values: dayPeriodValues$Y,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$L,
    defaultFormattingWidth: "wide"
  })
};
var match$Y = {};
match$Y.match = void 0;
var _index$41 = buildMatchFn;
var _index2$1Z = buildMatchPatternFn;
var matchOrdinalNumberPattern$Y = /^\d+\./i;
var parseOrdinalNumberPattern$Y = /\d+/i;
var matchEraPatterns$Y = {
  narrow: /^(e\.m\.a|m\.a\.j|eKr|pKr)/i,
  abbreviated: /^(e\.m\.a|m\.a\.j|eKr|pKr)/i,
  wide: /^(enne meie ajaarvamist|meie ajaarvamise järgi|enne Kristust|pärast Kristust)/i
};
var parseEraPatterns$Y = {
  any: [/^e/i, /^(m|p)/i]
};
var matchQuarterPatterns$Y = {
  narrow: /^[1234]/i,
  abbreviated: /^K[1234]/i,
  wide: /^[1234](\.)? kvartal/i
};
var parseQuarterPatterns$Y = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$Y = {
  narrow: /^[jvmasond]/i,
  abbreviated: /^(jaan|veebr|märts|apr|mai|juuni|juuli|aug|sept|okt|nov|dets)/i,
  wide: /^(jaanuar|veebruar|märts|aprill|mai|juuni|juuli|august|september|oktoober|november|detsember)/i
};
var parseMonthPatterns$Y = {
  narrow: [/^j/i, /^v/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^v/i, /^mär/i, /^ap/i, /^mai/i, /^juun/i, /^juul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$Y = {
  narrow: /^[petknrl]/i,
  short: /^[petknrl]/i,
  abbreviated: /^(püh?|esm?|tei?|kolm?|nel?|ree?|laup?)\.?/i,
  wide: /^(pühapäev|esmaspäev|teisipäev|kolmapäev|neljapäev|reede|laupäev)/i
};
var parseDayPatterns$Y = {
  any: [/^p/i, /^e/i, /^t/i, /^k/i, /^n/i, /^r/i, /^l/i]
};
var matchDayPeriodPatterns$Y = {
  any: /^(am|pm|keskööl?|keskpäev(al)?|hommik(ul)?|pärastlõunal?|õhtul?|öö(sel)?)/i
};
var parseDayPeriodPatterns$Y = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^keskö/i,
    noon: /^keskp/i,
    morning: /hommik/i,
    afternoon: /pärastlõuna/i,
    evening: /õhtu/i,
    night: /öö/i
  }
};
match$Y.match = {
  ordinalNumber: (0, _index2$1Z.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$Y,
    parsePattern: parseOrdinalNumberPattern$Y,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$41.buildMatchFn)({
    matchPatterns: matchEraPatterns$Y,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$Y,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$41.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$Y,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$Y,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$41.buildMatchFn)({
    matchPatterns: matchMonthPatterns$Y,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$Y,
    defaultParseWidth: "any"
  }),
  day: (0, _index$41.buildMatchFn)({
    matchPatterns: matchDayPatterns$Y,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$Y,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$41.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$Y,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$Y,
    defaultParseWidth: "any"
  })
};
et.et = void 0;
var _index$40 = formatDistance$1V;
var _index2$1Y = formatLong$$;
var _index3$10 = formatRelative$1X;
var _index4$$ = localize$Y;
var _index5$$ = match$Y;
et.et = {
  code: "et",
  formatDistance: _index$40.formatDistance,
  formatLong: _index2$1Y.formatLong,
  formatRelative: _index3$10.formatRelative,
  localize: _index4$$.localize,
  match: _index5$$.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var eu = {};
var formatDistance$1T = {};
formatDistance$1T.formatDistance = void 0;
var formatDistanceLocale$W = {
  lessThanXSeconds: {
    one: "segundo bat baino gutxiago",
    other: "{{count}} segundo baino gutxiago"
  },
  xSeconds: {
    one: "1 segundo",
    other: "{{count}} segundo"
  },
  halfAMinute: "minutu erdi",
  lessThanXMinutes: {
    one: "minutu bat baino gutxiago",
    other: "{{count}} minutu baino gutxiago"
  },
  xMinutes: {
    one: "1 minutu",
    other: "{{count}} minutu"
  },
  aboutXHours: {
    one: "1 ordu gutxi gorabehera",
    other: "{{count}} ordu gutxi gorabehera"
  },
  xHours: {
    one: "1 ordu",
    other: "{{count}} ordu"
  },
  xDays: {
    one: "1 egun",
    other: "{{count}} egun"
  },
  aboutXWeeks: {
    one: "aste 1 inguru",
    other: "{{count}} aste inguru"
  },
  xWeeks: {
    one: "1 aste",
    other: "{{count}} astean"
  },
  aboutXMonths: {
    one: "1 hilabete gutxi gorabehera",
    other: "{{count}} hilabete gutxi gorabehera"
  },
  xMonths: {
    one: "1 hilabete",
    other: "{{count}} hilabete"
  },
  aboutXYears: {
    one: "1 urte gutxi gorabehera",
    other: "{{count}} urte gutxi gorabehera"
  },
  xYears: {
    one: "1 urte",
    other: "{{count}} urte"
  },
  overXYears: {
    one: "1 urte baino gehiago",
    other: "{{count}} urte baino gehiago"
  },
  almostXYears: {
    one: "ia 1 urte",
    other: "ia {{count}} urte"
  }
};
var formatDistance$1S = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$W[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "en " + r;
    } else {
      return "duela " + r;
    }
  }
  return r;
};
formatDistance$1T.formatDistance = formatDistance$1S;
var formatLong$_ = {};
formatLong$_.formatLong = void 0;
var _index$3$ = buildFormatLongFn;
var dateFormats$_ = {
  full: "EEEE, y'ko' MMMM'ren' d'a' y'ren'",
  long: "y'ko' MMMM'ren' d'a'",
  medium: "y MMM d",
  short: "yy/MM/dd"
};
var timeFormats$_ = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$_ = {
  full: "{{date}} 'tan' {{time}}",
  long: "{{date}} 'tan' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$_.formatLong = {
  date: (0, _index$3$.buildFormatLongFn)({
    formats: dateFormats$_,
    defaultWidth: "full"
  }),
  time: (0, _index$3$.buildFormatLongFn)({
    formats: timeFormats$_,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3$.buildFormatLongFn)({
    formats: dateTimeFormats$_,
    defaultWidth: "full"
  })
};
var formatRelative$1V = {};
formatRelative$1V.formatRelative = void 0;
var formatRelativeLocale$Y = {
  lastWeek: "'joan den' eeee, LT",
  yesterday: "'atzo,' p",
  today: "'gaur,' p",
  tomorrow: "'bihar,' p",
  nextWeek: "eeee, p",
  other: "P"
};
var formatRelativeLocalePlural$1 = {
  lastWeek: "'joan den' eeee, p",
  yesterday: "'atzo,' p",
  today: "'gaur,' p",
  tomorrow: "'bihar,' p",
  nextWeek: "eeee, p",
  other: "P"
};
var formatRelative$1U = function (e, a) {
  if (a.getHours() !== 1) {
    return formatRelativeLocalePlural$1[e];
  }
  return formatRelativeLocale$Y[e];
};
formatRelative$1V.formatRelative = formatRelative$1U;
var localize$X = {};
localize$X.localize = void 0;
var _index$3_ = buildLocalizeFn;
var eraValues$X = {
  narrow: ["k.a.", "k.o."],
  abbreviated: ["k.a.", "k.o."],
  wide: ["kristo aurretik", "kristo ondoren"]
};
var quarterValues$X = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1H", "2H", "3H", "4H"],
  wide: ["1. hiruhilekoa", "2. hiruhilekoa", "3. hiruhilekoa", "4. hiruhilekoa"]
};
var monthValues$X = {
  narrow: ["u", "o", "m", "a", "m", "e", "u", "a", "i", "u", "a", "a"],
  abbreviated: ["urt", "ots", "mar", "api", "mai", "eka", "uzt", "abu", "ira", "urr", "aza", "abe"],
  wide: ["urtarrila", "otsaila", "martxoa", "apirila", "maiatza", "ekaina", "uztaila", "abuztua", "iraila", "urria", "azaroa", "abendua"]
};
var dayValues$X = {
  narrow: ["i", "a", "a", "a", "o", "o", "l"],
  short: ["ig", "al", "as", "az", "og", "or", "lr"],
  abbreviated: ["iga", "ast", "ast", "ast", "ost", "ost", "lar"],
  wide: ["igandea", "astelehena", "asteartea", "asteazkena", "osteguna", "ostirala", "larunbata"]
};
var dayPeriodValues$X = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "ge",
    noon: "eg",
    morning: "goiza",
    afternoon: "arratsaldea",
    evening: "arratsaldea",
    night: "gaua"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "gauerdia",
    noon: "eguerdia",
    morning: "goiza",
    afternoon: "arratsaldea",
    evening: "arratsaldea",
    night: "gaua"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "gauerdia",
    noon: "eguerdia",
    morning: "goiza",
    afternoon: "arratsaldea",
    evening: "arratsaldea",
    night: "gaua"
  }
};
var formattingDayPeriodValues$K = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "ge",
    noon: "eg",
    morning: "goizean",
    afternoon: "arratsaldean",
    evening: "arratsaldean",
    night: "gauean"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "gauerdia",
    noon: "eguerdia",
    morning: "goizean",
    afternoon: "arratsaldean",
    evening: "arratsaldean",
    night: "gauean"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "gauerdia",
    noon: "eguerdia",
    morning: "goizean",
    afternoon: "arratsaldean",
    evening: "arratsaldean",
    night: "gauean"
  }
};
var ordinalNumber$X = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$X.localize = {
  ordinalNumber: ordinalNumber$X,
  era: (0, _index$3_.buildLocalizeFn)({
    values: eraValues$X,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$3_.buildLocalizeFn)({
    values: quarterValues$X,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$3_.buildLocalizeFn)({
    values: monthValues$X,
    defaultWidth: "wide"
  }),
  day: (0, _index$3_.buildLocalizeFn)({
    values: dayValues$X,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$3_.buildLocalizeFn)({
    values: dayPeriodValues$X,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$K,
    defaultFormattingWidth: "wide"
  })
};
var match$X = {};
match$X.match = void 0;
var _index$3Z = buildMatchFn;
var _index2$1X = buildMatchPatternFn;
var matchOrdinalNumberPattern$X = /^(\d+)(.)?/i;
var parseOrdinalNumberPattern$X = /\d+/i;
var matchEraPatterns$X = {
  narrow: /^(k.a.|k.o.)/i,
  abbreviated: /^(k.a.|k.o.)/i,
  wide: /^(kristo aurretik|kristo ondoren)/i
};
var parseEraPatterns$X = {
  narrow: [/^k.a./i, /^k.o./i],
  abbreviated: [/^(k.a.)/i, /^(k.o.)/i],
  wide: [/^(kristo aurretik)/i, /^(kristo ondoren)/i]
};
var matchQuarterPatterns$X = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234]H/i,
  wide: /^[1234](.)? hiruhilekoa/i
};
var parseQuarterPatterns$X = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$X = {
  narrow: /^[uomaei]/i,
  abbreviated: /^(urt|ots|mar|api|mai|eka|uzt|abu|ira|urr|aza|abe)/i,
  wide: /^(urtarrila|otsaila|martxoa|apirila|maiatza|ekaina|uztaila|abuztua|iraila|urria|azaroa|abendua)/i
};
var parseMonthPatterns$X = {
  narrow: [/^u/i, /^o/i, /^m/i, /^a/i, /^m/i, /^e/i, /^u/i, /^a/i, /^i/i, /^u/i, /^a/i, /^a/i],
  any: [/^urt/i, /^ots/i, /^mar/i, /^api/i, /^mai/i, /^eka/i, /^uzt/i, /^abu/i, /^ira/i, /^urr/i, /^aza/i, /^abe/i]
};
var matchDayPatterns$X = {
  narrow: /^[iaol]/i,
  short: /^(ig|al|as|az|og|or|lr)/i,
  abbreviated: /^(iga|ast|ast|ast|ost|ost|lar)/i,
  wide: /^(igandea|astelehena|asteartea|asteazkena|osteguna|ostirala|larunbata)/i
};
var parseDayPatterns$X = {
  narrow: [/^i/i, /^a/i, /^a/i, /^a/i, /^o/i, /^o/i, /^l/i],
  short: [/^ig/i, /^al/i, /^as/i, /^az/i, /^og/i, /^or/i, /^lr/i],
  abbreviated: [/^iga/i, /^ast/i, /^ast/i, /^ast/i, /^ost/i, /^ost/i, /^lar/i],
  wide: [/^igandea/i, /^astelehena/i, /^asteartea/i, /^asteazkena/i, /^osteguna/i, /^ostirala/i, /^larunbata/i]
};
var matchDayPeriodPatterns$X = {
  narrow: /^(a|p|ge|eg|((goiza|goizean)|arratsaldea|(gaua|gauean)))/i,
  any: /^([ap]\.?\s?m\.?|gauerdia|eguerdia|((goiza|goizean)|arratsaldea|(gaua|gauean)))/i
};
var parseDayPeriodPatterns$X = {
  narrow: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^ge/i,
    noon: /^eg/i,
    morning: /goiz/i,
    afternoon: /arratsaldea/i,
    evening: /arratsaldea/i,
    night: /gau/i
  },
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^gauerdia/i,
    noon: /^eguerdia/i,
    morning: /goiz/i,
    afternoon: /arratsaldea/i,
    evening: /arratsaldea/i,
    night: /gau/i
  }
};
match$X.match = {
  ordinalNumber: (0, _index2$1X.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$X,
    parsePattern: parseOrdinalNumberPattern$X,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$3Z.buildMatchFn)({
    matchPatterns: matchEraPatterns$X,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$X,
    defaultParseWidth: "wide"
  }),
  quarter: (0, _index$3Z.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$X,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$X,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$3Z.buildMatchFn)({
    matchPatterns: matchMonthPatterns$X,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$X,
    defaultParseWidth: "any"
  }),
  day: (0, _index$3Z.buildMatchFn)({
    matchPatterns: matchDayPatterns$X,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$X,
    defaultParseWidth: "wide"
  }),
  dayPeriod: (0, _index$3Z.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$X,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$X,
    defaultParseWidth: "any"
  })
};
eu.eu = void 0;
var _index$3Y = formatDistance$1T;
var _index2$1W = formatLong$_;
var _index3$$ = formatRelative$1V;
var _index4$_ = localize$X;
var _index5$_ = match$X;
eu.eu = {
  code: "eu",
  formatDistance: _index$3Y.formatDistance,
  formatLong: _index2$1W.formatLong,
  formatRelative: _index3$$.formatRelative,
  localize: _index4$_.localize,
  match: _index5$_.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var faIR = {};
var formatDistance$1R = {};
formatDistance$1R.formatDistance = void 0;
var formatDistanceLocale$V = {
  lessThanXSeconds: {
    one: "کمتر از یک ثانیه",
    other: "کمتر از {{count}} ثانیه"
  },
  xSeconds: {
    one: "1 ثانیه",
    other: "{{count}} ثانیه"
  },
  halfAMinute: "نیم دقیقه",
  lessThanXMinutes: {
    one: "کمتر از یک دقیقه",
    other: "کمتر از {{count}} دقیقه"
  },
  xMinutes: {
    one: "1 دقیقه",
    other: "{{count}} دقیقه"
  },
  aboutXHours: {
    one: "حدود 1 ساعت",
    other: "حدود {{count}} ساعت"
  },
  xHours: {
    one: "1 ساعت",
    other: "{{count}} ساعت"
  },
  xDays: {
    one: "1 روز",
    other: "{{count}} روز"
  },
  aboutXWeeks: {
    one: "حدود 1 هفته",
    other: "حدود {{count}} هفته"
  },
  xWeeks: {
    one: "1 هفته",
    other: "{{count}} هفته"
  },
  aboutXMonths: {
    one: "حدود 1 ماه",
    other: "حدود {{count}} ماه"
  },
  xMonths: {
    one: "1 ماه",
    other: "{{count}} ماه"
  },
  aboutXYears: {
    one: "حدود 1 سال",
    other: "حدود {{count}} سال"
  },
  xYears: {
    one: "1 سال",
    other: "{{count}} سال"
  },
  overXYears: {
    one: "بیشتر از 1 سال",
    other: "بیشتر از {{count}} سال"
  },
  almostXYears: {
    one: "نزدیک 1 سال",
    other: "نزدیک {{count}} سال"
  }
};
var formatDistance$1Q = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$V[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "در " + r;
    } else {
      return r + " قبل";
    }
  }
  return r;
};
formatDistance$1R.formatDistance = formatDistance$1Q;
var formatLong$Z = {};
formatLong$Z.formatLong = void 0;
var _index$3X = buildFormatLongFn;
var dateFormats$Z = {
  full: "EEEE do MMMM y",
  long: "do MMMM y",
  medium: "d MMM y",
  short: "yyyy/MM/dd"
};
var timeFormats$Z = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$Z = {
  full: "{{date}} 'در' {{time}}",
  long: "{{date}} 'در' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$Z.formatLong = {
  date: (0, _index$3X.buildFormatLongFn)({
    formats: dateFormats$Z,
    defaultWidth: "full"
  }),
  time: (0, _index$3X.buildFormatLongFn)({
    formats: timeFormats$Z,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3X.buildFormatLongFn)({
    formats: dateTimeFormats$Z,
    defaultWidth: "full"
  })
};
var formatRelative$1T = {};
formatRelative$1T.formatRelative = void 0;
var formatRelativeLocale$X = {
  lastWeek: "eeee 'گذشته در' p",
  yesterday: "'دیروز در' p",
  today: "'امروز در' p",
  tomorrow: "'فردا در' p",
  nextWeek: "eeee 'در' p",
  other: "P"
};
var formatRelative$1S = function (e, a, t, r) {
  return formatRelativeLocale$X[e];
};
formatRelative$1T.formatRelative = formatRelative$1S;
var localize$W = {};
localize$W.localize = void 0;
var _index$3W = buildLocalizeFn;
var eraValues$W = {
  narrow: ["ق", "ب"],
  abbreviated: ["ق.م.", "ب.م."],
  wide: ["قبل از میلاد", "بعد از میلاد"]
};
var quarterValues$W = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["س‌م1", "س‌م2", "س‌م3", "س‌م4"],
  wide: ["سه‌ماهه 1", "سه‌ماهه 2", "سه‌ماهه 3", "سه‌ماهه 4"]
};
var monthValues$W = {
  narrow: ["ژ", "ف", "م", "آ", "م", "ج", "ج", "آ", "س", "ا", "ن", "د"],
  abbreviated: ["ژانـ", "فور", "مارس", "آپر", "می", "جون", "جولـ", "آگو", "سپتـ", "اکتـ", "نوامـ", "دسامـ"],
  wide: ["ژانویه", "فوریه", "مارس", "آپریل", "می", "جون", "جولای", "آگوست", "سپتامبر", "اکتبر", "نوامبر", "دسامبر"]
};
var dayValues$W = {
  narrow: ["ی", "د", "س", "چ", "پ", "ج", "ش"],
  short: ["1ش", "2ش", "3ش", "4ش", "5ش", "ج", "ش"],
  abbreviated: ["یکشنبه", "دوشنبه", "سه‌شنبه", "چهارشنبه", "پنجشنبه", "جمعه", "شنبه"],
  wide: ["یکشنبه", "دوشنبه", "سه‌شنبه", "چهارشنبه", "پنجشنبه", "جمعه", "شنبه"]
};
var dayPeriodValues$W = {
  narrow: {
    am: "ق",
    pm: "ب",
    midnight: "ن",
    noon: "ظ",
    morning: "ص",
    afternoon: "ب.ظ.",
    evening: "ع",
    night: "ش"
  },
  abbreviated: {
    am: "ق.ظ.",
    pm: "ب.ظ.",
    midnight: "نیمه‌شب",
    noon: "ظهر",
    morning: "صبح",
    afternoon: "بعدازظهر",
    evening: "عصر",
    night: "شب"
  },
  wide: {
    am: "قبل‌ازظهر",
    pm: "بعدازظهر",
    midnight: "نیمه‌شب",
    noon: "ظهر",
    morning: "صبح",
    afternoon: "بعدازظهر",
    evening: "عصر",
    night: "شب"
  }
};
var formattingDayPeriodValues$J = {
  narrow: {
    am: "ق",
    pm: "ب",
    midnight: "ن",
    noon: "ظ",
    morning: "ص",
    afternoon: "ب.ظ.",
    evening: "ع",
    night: "ش"
  },
  abbreviated: {
    am: "ق.ظ.",
    pm: "ب.ظ.",
    midnight: "نیمه‌شب",
    noon: "ظهر",
    morning: "صبح",
    afternoon: "بعدازظهر",
    evening: "عصر",
    night: "شب"
  },
  wide: {
    am: "قبل‌ازظهر",
    pm: "بعدازظهر",
    midnight: "نیمه‌شب",
    noon: "ظهر",
    morning: "صبح",
    afternoon: "بعدازظهر",
    evening: "عصر",
    night: "شب"
  }
};
var ordinalNumber$W = function (e, a) {
  return String(e);
};
localize$W.localize = {
  ordinalNumber: ordinalNumber$W,
  era: (0, _index$3W.buildLocalizeFn)({
    values: eraValues$W,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$3W.buildLocalizeFn)({
    values: quarterValues$W,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$3W.buildLocalizeFn)({
    values: monthValues$W,
    defaultWidth: "wide"
  }),
  day: (0, _index$3W.buildLocalizeFn)({
    values: dayValues$W,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$3W.buildLocalizeFn)({
    values: dayPeriodValues$W,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$J,
    defaultFormattingWidth: "wide"
  })
};
var match$W = {};
match$W.match = void 0;
var _index$3V = buildMatchFn;
var _index2$1V = buildMatchPatternFn;
var matchOrdinalNumberPattern$W = /^(\d+)(th|st|nd|rd)?/i;
var parseOrdinalNumberPattern$W = /\d+/i;
var matchEraPatterns$W = {
  narrow: /^(ق|ب)/i,
  abbreviated: /^(ق\.?\s?م\.?|ق\.?\s?د\.?\s?م\.?|م\.?\s?|د\.?\s?م\.?)/i,
  wide: /^(قبل از میلاد|قبل از دوران مشترک|میلادی|دوران مشترک|بعد از میلاد)/i
};
var parseEraPatterns$W = {
  any: [/^قبل/i, /^بعد/i]
};
var matchQuarterPatterns$W = {
  narrow: /^[1234]/i,
  abbreviated: /^س‌م[1234]/i,
  wide: /^سه‌ماهه [1234]/i
};
var parseQuarterPatterns$W = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$W = {
  narrow: /^[جژفمآاماسند]/i,
  abbreviated: /^(جنو|ژانـ|ژانویه|فوریه|فور|مارس|آوریل|آپر|مه|می|ژوئن|جون|جول|جولـ|ژوئیه|اوت|آگو|سپتمبر|سپتامبر|اکتبر|اکتوبر|نوامبر|نوامـ|دسامبر|دسامـ|دسم)/i,
  wide: /^(ژانویه|جنوری|فبروری|فوریه|مارچ|مارس|آپریل|اپریل|ایپریل|آوریل|مه|می|ژوئن|جون|جولای|ژوئیه|آگست|اگست|آگوست|اوت|سپتمبر|سپتامبر|اکتبر|اکتوبر|نوامبر|نومبر|دسامبر|دسمبر)/i
};
var parseMonthPatterns$W = {
  narrow: [/^(ژ|ج)/i, /^ف/i, /^م/i, /^(آ|ا)/i, /^م/i, /^(ژ|ج)/i, /^(ج|ژ)/i, /^(آ|ا)/i, /^س/i, /^ا/i, /^ن/i, /^د/i],
  any: [/^ژا/i, /^ف/i, /^ما/i, /^آپ/i, /^(می|مه)/i, /^(ژوئن|جون)/i, /^(ژوئی|جول)/i, /^(اوت|آگ)/i, /^س/i, /^(اوک|اک)/i, /^ن/i, /^د/i]
};
var matchDayPatterns$W = {
  narrow: /^[شیدسچپج]/i,
  short: /^(ش|ج|1ش|2ش|3ش|4ش|5ش)/i,
  abbreviated: /^(یکشنبه|دوشنبه|سه‌شنبه|چهارشنبه|پنج‌شنبه|جمعه|شنبه)/i,
  wide: /^(یکشنبه|دوشنبه|سه‌شنبه|چهارشنبه|پنج‌شنبه|جمعه|شنبه)/i
};
var parseDayPatterns$W = {
  narrow: [/^ی/i, /^دو/i, /^س/i, /^چ/i, /^پ/i, /^ج/i, /^ش/i],
  any: [/^(ی|1ش|یکشنبه)/i, /^(د|2ش|دوشنبه)/i, /^(س|3ش|سه‌شنبه)/i, /^(چ|4ش|چهارشنبه)/i, /^(پ|5ش|پنجشنبه)/i, /^(ج|جمعه)/i, /^(ش|شنبه)/i]
};
var matchDayPeriodPatterns$W = {
  narrow: /^(ب|ق|ن|ظ|ص|ب.ظ.|ع|ش)/i,
  abbreviated: /^(ق.ظ.|ب.ظ.|نیمه‌شب|ظهر|صبح|بعدازظهر|عصر|شب)/i,
  wide: /^(قبل‌ازظهر|نیمه‌شب|ظهر|صبح|بعدازظهر|عصر|شب)/i
};
var parseDayPeriodPatterns$W = {
  any: {
    am: /^(ق|ق.ظ.|قبل‌ازظهر)/i,
    pm: /^(ب|ب.ظ.|بعدازظهر)/i,
    midnight: /^(‌نیمه‌شب|ن)/i,
    noon: /^(ظ|ظهر)/i,
    morning: /(ص|صبح)/i,
    afternoon: /(ب|ب.ظ.|بعدازظهر)/i,
    evening: /(ع|عصر)/i,
    night: /(ش|شب)/i
  }
};
match$W.match = {
  ordinalNumber: (0, _index2$1V.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$W,
    parsePattern: parseOrdinalNumberPattern$W,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$3V.buildMatchFn)({
    matchPatterns: matchEraPatterns$W,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$W,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$3V.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$W,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$W,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$3V.buildMatchFn)({
    matchPatterns: matchMonthPatterns$W,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$W,
    defaultParseWidth: "any"
  }),
  day: (0, _index$3V.buildMatchFn)({
    matchPatterns: matchDayPatterns$W,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$W,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$3V.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$W,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$W,
    defaultParseWidth: "any"
  })
};
faIR.faIR = void 0;
var _index$3U = formatDistance$1R;
var _index2$1U = formatLong$Z;
var _index3$_ = formatRelative$1T;
var _index4$Z = localize$W;
var _index5$Z = match$W;
faIR.faIR = {
  code: "fa-IR",
  formatDistance: _index$3U.formatDistance,
  formatLong: _index2$1U.formatLong,
  formatRelative: _index3$_.formatRelative,
  localize: _index4$Z.localize,
  match: _index5$Z.match,
  options: {
    weekStartsOn: 6,
    firstWeekContainsDate: 1
  }
};
var fi = {};
var formatDistance$1P = {};
formatDistance$1P.formatDistance = void 0;
function futureSeconds(e) {
  return e.replace(/sekuntia?/, "sekunnin");
}
function futureMinutes(e) {
  return e.replace(/minuuttia?/, "minuutin");
}
function futureHours(e) {
  return e.replace(/tuntia?/, "tunnin");
}
function futureDays(e) {
  return e.replace(/päivää?/, "päivän");
}
function futureWeeks(e) {
  return e.replace(/(viikko|viikkoa)/, "viikon");
}
function futureMonths(e) {
  return e.replace(/(kuukausi|kuukautta)/, "kuukauden");
}
function futureYears(e) {
  return e.replace(/(vuosi|vuotta)/, "vuoden");
}
var formatDistanceLocale$U = {
  lessThanXSeconds: {
    one: "alle sekunti",
    other: "alle {{count}} sekuntia",
    futureTense: futureSeconds
  },
  xSeconds: {
    one: "sekunti",
    other: "{{count}} sekuntia",
    futureTense: futureSeconds
  },
  halfAMinute: {
    one: "puoli minuuttia",
    other: "puoli minuuttia",
    futureTense: function (e) {
      return "puolen minuutin";
    }
  },
  lessThanXMinutes: {
    one: "alle minuutti",
    other: "alle {{count}} minuuttia",
    futureTense: futureMinutes
  },
  xMinutes: {
    one: "minuutti",
    other: "{{count}} minuuttia",
    futureTense: futureMinutes
  },
  aboutXHours: {
    one: "noin tunti",
    other: "noin {{count}} tuntia",
    futureTense: futureHours
  },
  xHours: {
    one: "tunti",
    other: "{{count}} tuntia",
    futureTense: futureHours
  },
  xDays: {
    one: "päivä",
    other: "{{count}} päivää",
    futureTense: futureDays
  },
  aboutXWeeks: {
    one: "noin viikko",
    other: "noin {{count}} viikkoa",
    futureTense: futureWeeks
  },
  xWeeks: {
    one: "viikko",
    other: "{{count}} viikkoa",
    futureTense: futureWeeks
  },
  aboutXMonths: {
    one: "noin kuukausi",
    other: "noin {{count}} kuukautta",
    futureTense: futureMonths
  },
  xMonths: {
    one: "kuukausi",
    other: "{{count}} kuukautta",
    futureTense: futureMonths
  },
  aboutXYears: {
    one: "noin vuosi",
    other: "noin {{count}} vuotta",
    futureTense: futureYears
  },
  xYears: {
    one: "vuosi",
    other: "{{count}} vuotta",
    futureTense: futureYears
  },
  overXYears: {
    one: "yli vuosi",
    other: "yli {{count}} vuotta",
    futureTense: futureYears
  },
  almostXYears: {
    one: "lähes vuosi",
    other: "lähes {{count}} vuotta",
    futureTense: futureYears
  }
};
var formatDistance$1O = function (e, a, t) {
  var r = formatDistanceLocale$U[e];
  var n = a === 1 ? r.one : r.other.replace("{{count}}", String(a));
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r.futureTense(n) + " kuluttua";
    } else {
      return n + " sitten";
    }
  }
  return n;
};
formatDistance$1P.formatDistance = formatDistance$1O;
var formatLong$Y = {};
formatLong$Y.formatLong = void 0;
var _index$3T = buildFormatLongFn;
var dateFormats$Y = {
  full: "eeee d. MMMM y",
  long: "d. MMMM y",
  medium: "d. MMM y",
  short: "d.M.y"
};
var timeFormats$Y = {
  full: "HH.mm.ss zzzz",
  long: "HH.mm.ss z",
  medium: "HH.mm.ss",
  short: "HH.mm"
};
var dateTimeFormats$Y = {
  full: "{{date}} 'klo' {{time}}",
  long: "{{date}} 'klo' {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$Y.formatLong = {
  date: (0, _index$3T.buildFormatLongFn)({
    formats: dateFormats$Y,
    defaultWidth: "full"
  }),
  time: (0, _index$3T.buildFormatLongFn)({
    formats: timeFormats$Y,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3T.buildFormatLongFn)({
    formats: dateTimeFormats$Y,
    defaultWidth: "full"
  })
};
var formatRelative$1R = {};
formatRelative$1R.formatRelative = void 0;
var formatRelativeLocale$W = {
  lastWeek: "'viime' eeee 'klo' p",
  yesterday: "'eilen klo' p",
  today: "'tänään klo' p",
  tomorrow: "'huomenna klo' p",
  nextWeek: "'ensi' eeee 'klo' p",
  other: "P"
};
var formatRelative$1Q = function (e, a, t, r) {
  return formatRelativeLocale$W[e];
};
formatRelative$1R.formatRelative = formatRelative$1Q;
var localize$V = {};
localize$V.localize = void 0;
var _index$3S = buildLocalizeFn;
var eraValues$V = {
  narrow: ["eaa.", "jaa."],
  abbreviated: ["eaa.", "jaa."],
  wide: ["ennen ajanlaskun alkua", "jälkeen ajanlaskun alun"]
};
var quarterValues$V = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1. kvartaali", "2. kvartaali", "3. kvartaali", "4. kvartaali"]
};
var monthValues$V = {
  narrow: ["T", "H", "M", "H", "T", "K", "H", "E", "S", "L", "M", "J"],
  abbreviated: ["tammi", "helmi", "maalis", "huhti", "touko", "kesä", "heinä", "elo", "syys", "loka", "marras", "joulu"],
  wide: ["tammikuu", "helmikuu", "maaliskuu", "huhtikuu", "toukokuu", "kesäkuu", "heinäkuu", "elokuu", "syyskuu", "lokakuu", "marraskuu", "joulukuu"]
};
var formattingMonthValues$b = {
  narrow: monthValues$V.narrow,
  abbreviated: monthValues$V.abbreviated,
  wide: ["tammikuuta", "helmikuuta", "maaliskuuta", "huhtikuuta", "toukokuuta", "kesäkuuta", "heinäkuuta", "elokuuta", "syyskuuta", "lokakuuta", "marraskuuta", "joulukuuta"]
};
var dayValues$V = {
  narrow: ["S", "M", "T", "K", "T", "P", "L"],
  short: ["su", "ma", "ti", "ke", "to", "pe", "la"],
  abbreviated: ["sunn.", "maan.", "tiis.", "kesk.", "torst.", "perj.", "la"],
  wide: ["sunnuntai", "maanantai", "tiistai", "keskiviikko", "torstai", "perjantai", "lauantai"]
};
var formattingDayValues$3 = {
  narrow: dayValues$V.narrow,
  short: dayValues$V.short,
  abbreviated: dayValues$V.abbreviated,
  wide: ["sunnuntaina", "maanantaina", "tiistaina", "keskiviikkona", "torstaina", "perjantaina", "lauantaina"]
};
var dayPeriodValues$V = {
  narrow: {
    am: "ap",
    pm: "ip",
    midnight: "keskiyö",
    noon: "keskipäivä",
    morning: "ap",
    afternoon: "ip",
    evening: "illalla",
    night: "yöllä"
  },
  abbreviated: {
    am: "ap",
    pm: "ip",
    midnight: "keskiyö",
    noon: "keskipäivä",
    morning: "ap",
    afternoon: "ip",
    evening: "illalla",
    night: "yöllä"
  },
  wide: {
    am: "ap",
    pm: "ip",
    midnight: "keskiyöllä",
    noon: "keskipäivällä",
    morning: "aamupäivällä",
    afternoon: "iltapäivällä",
    evening: "illalla",
    night: "yöllä"
  }
};
var ordinalNumber$V = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$V.localize = {
  ordinalNumber: ordinalNumber$V,
  era: (0, _index$3S.buildLocalizeFn)({
    values: eraValues$V,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$3S.buildLocalizeFn)({
    values: quarterValues$V,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$3S.buildLocalizeFn)({
    values: monthValues$V,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$b,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$3S.buildLocalizeFn)({
    values: dayValues$V,
    defaultWidth: "wide",
    formattingValues: formattingDayValues$3,
    defaultFormattingWidth: "wide"
  }),
  dayPeriod: (0, _index$3S.buildLocalizeFn)({
    values: dayPeriodValues$V,
    defaultWidth: "wide"
  })
};
var match$V = {};
match$V.match = void 0;
var _index$3R = buildMatchFn;
var _index2$1T = buildMatchPatternFn;
var matchOrdinalNumberPattern$V = /^(\d+)(\.)/i;
var parseOrdinalNumberPattern$V = /\d+/i;
var matchEraPatterns$V = {
  narrow: /^(e|j)/i,
  abbreviated: /^(eaa.|jaa.)/i,
  wide: /^(ennen ajanlaskun alkua|jälkeen ajanlaskun alun)/i
};
var parseEraPatterns$V = {
  any: [/^e/i, /^j/i]
};
var matchQuarterPatterns$V = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234]\.? kvartaali/i
};
var parseQuarterPatterns$V = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$V = {
  narrow: /^[thmkeslj]/i,
  abbreviated: /^(tammi|helmi|maalis|huhti|touko|kesä|heinä|elo|syys|loka|marras|joulu)/i,
  wide: /^(tammikuu|helmikuu|maaliskuu|huhtikuu|toukokuu|kesäkuu|heinäkuu|elokuu|syyskuu|lokakuu|marraskuu|joulukuu)(ta)?/i
};
var parseMonthPatterns$V = {
  narrow: [/^t/i, /^h/i, /^m/i, /^h/i, /^t/i, /^k/i, /^h/i, /^e/i, /^s/i, /^l/i, /^m/i, /^j/i],
  any: [/^ta/i, /^hel/i, /^maa/i, /^hu/i, /^to/i, /^k/i, /^hei/i, /^e/i, /^s/i, /^l/i, /^mar/i, /^j/i]
};
var matchDayPatterns$V = {
  narrow: /^[smtkpl]/i,
  short: /^(su|ma|ti|ke|to|pe|la)/i,
  abbreviated: /^(sunn.|maan.|tiis.|kesk.|torst.|perj.|la)/i,
  wide: /^(sunnuntai|maanantai|tiistai|keskiviikko|torstai|perjantai|lauantai)(na)?/i
};
var parseDayPatterns$V = {
  narrow: [/^s/i, /^m/i, /^t/i, /^k/i, /^t/i, /^p/i, /^l/i],
  any: [/^s/i, /^m/i, /^ti/i, /^k/i, /^to/i, /^p/i, /^l/i]
};
var matchDayPeriodPatterns$V = {
  narrow: /^(ap|ip|keskiyö|keskipäivä|aamupäivällä|iltapäivällä|illalla|yöllä)/i,
  any: /^(ap|ip|keskiyöllä|keskipäivällä|aamupäivällä|iltapäivällä|illalla|yöllä)/i
};
var parseDayPeriodPatterns$V = {
  any: {
    am: /^ap/i,
    pm: /^ip/i,
    midnight: /^keskiyö/i,
    noon: /^keskipäivä/i,
    morning: /aamupäivällä/i,
    afternoon: /iltapäivällä/i,
    evening: /illalla/i,
    night: /yöllä/i
  }
};
match$V.match = {
  ordinalNumber: (0, _index2$1T.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$V,
    parsePattern: parseOrdinalNumberPattern$V,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$3R.buildMatchFn)({
    matchPatterns: matchEraPatterns$V,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$V,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$3R.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$V,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$V,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$3R.buildMatchFn)({
    matchPatterns: matchMonthPatterns$V,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$V,
    defaultParseWidth: "any"
  }),
  day: (0, _index$3R.buildMatchFn)({
    matchPatterns: matchDayPatterns$V,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$V,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$3R.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$V,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$V,
    defaultParseWidth: "any"
  })
};
fi.fi = void 0;
var _index$3Q = formatDistance$1P;
var _index2$1S = formatLong$Y;
var _index3$Z = formatRelative$1R;
var _index4$Y = localize$V;
var _index5$Y = match$V;
fi.fi = {
  code: "fi",
  formatDistance: _index$3Q.formatDistance,
  formatLong: _index2$1S.formatLong,
  formatRelative: _index3$Z.formatRelative,
  localize: _index4$Y.localize,
  match: _index5$Y.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var fr = {};
var formatDistance$1N = {};
formatDistance$1N.formatDistance = void 0;
var formatDistanceLocale$T = {
  lessThanXSeconds: {
    one: "moins d’une seconde",
    other: "moins de {{count}} secondes"
  },
  xSeconds: {
    one: "1 seconde",
    other: "{{count}} secondes"
  },
  halfAMinute: "30 secondes",
  lessThanXMinutes: {
    one: "moins d’une minute",
    other: "moins de {{count}} minutes"
  },
  xMinutes: {
    one: "1 minute",
    other: "{{count}} minutes"
  },
  aboutXHours: {
    one: "environ 1 heure",
    other: "environ {{count}} heures"
  },
  xHours: {
    one: "1 heure",
    other: "{{count}} heures"
  },
  xDays: {
    one: "1 jour",
    other: "{{count}} jours"
  },
  aboutXWeeks: {
    one: "environ 1 semaine",
    other: "environ {{count}} semaines"
  },
  xWeeks: {
    one: "1 semaine",
    other: "{{count}} semaines"
  },
  aboutXMonths: {
    one: "environ 1 mois",
    other: "environ {{count}} mois"
  },
  xMonths: {
    one: "1 mois",
    other: "{{count}} mois"
  },
  aboutXYears: {
    one: "environ 1 an",
    other: "environ {{count}} ans"
  },
  xYears: {
    one: "1 an",
    other: "{{count}} ans"
  },
  overXYears: {
    one: "plus d’un an",
    other: "plus de {{count}} ans"
  },
  almostXYears: {
    one: "presqu’un an",
    other: "presque {{count}} ans"
  }
};
var formatDistance$1M = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$T[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "dans " + r;
    } else {
      return "il y a " + r;
    }
  }
  return r;
};
formatDistance$1N.formatDistance = formatDistance$1M;
var formatLong$X = {};
formatLong$X.formatLong = void 0;
var _index$3P = buildFormatLongFn;
var dateFormats$X = {
  full: "EEEE d MMMM y",
  long: "d MMMM y",
  medium: "d MMM y",
  short: "dd/MM/y"
};
var timeFormats$X = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$X = {
  full: "{{date}} 'à' {{time}}",
  long: "{{date}} 'à' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$X.formatLong = {
  date: (0, _index$3P.buildFormatLongFn)({
    formats: dateFormats$X,
    defaultWidth: "full"
  }),
  time: (0, _index$3P.buildFormatLongFn)({
    formats: timeFormats$X,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3P.buildFormatLongFn)({
    formats: dateTimeFormats$X,
    defaultWidth: "full"
  })
};
var formatRelative$1P = {};
formatRelative$1P.formatRelative = void 0;
var formatRelativeLocale$V = {
  lastWeek: "eeee 'dernier à' p",
  yesterday: "'hier à' p",
  today: "'aujourd’hui à' p",
  tomorrow: "'demain à' p'",
  nextWeek: "eeee 'prochain à' p",
  other: "P"
};
var formatRelative$1O = function (e, a, t, r) {
  return formatRelativeLocale$V[e];
};
formatRelative$1P.formatRelative = formatRelative$1O;
var localize$U = {};
localize$U.localize = void 0;
var _index$3O = buildLocalizeFn;
var eraValues$U = {
  narrow: ["av. J.-C", "ap. J.-C"],
  abbreviated: ["av. J.-C", "ap. J.-C"],
  wide: ["avant Jésus-Christ", "après Jésus-Christ"]
};
var quarterValues$U = {
  narrow: ["T1", "T2", "T3", "T4"],
  abbreviated: ["1er trim.", "2ème trim.", "3ème trim.", "4ème trim."],
  wide: ["1er trimestre", "2ème trimestre", "3ème trimestre", "4ème trimestre"]
};
var monthValues$U = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["janv.", "févr.", "mars", "avr.", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
  wide: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"]
};
var dayValues$U = {
  narrow: ["D", "L", "M", "M", "J", "V", "S"],
  short: ["di", "lu", "ma", "me", "je", "ve", "sa"],
  abbreviated: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
  wide: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"]
};
var dayPeriodValues$U = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "minuit",
    noon: "midi",
    morning: "mat.",
    afternoon: "ap.m.",
    evening: "soir",
    night: "mat."
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "minuit",
    noon: "midi",
    morning: "matin",
    afternoon: "après-midi",
    evening: "soir",
    night: "matin"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "minuit",
    noon: "midi",
    morning: "du matin",
    afternoon: "de l’après-midi",
    evening: "du soir",
    night: "du matin"
  }
};
var ordinalNumber$U = function (e, a) {
  var t = Number(e);
  var r = a === null || a === void 0 ? void 0 : a.unit;
  if (t === 0) return "0";
  var n = ["year", "week", "hour", "minute", "second"];
  var i;
  if (t === 1) {
    i = r && n.includes(r) ? "ère" : "er";
  } else {
    i = "ème";
  }
  return t + i;
};
var LONG_MONTHS_TOKENS = ["MMM", "MMMM"];
localize$U.localize = {
  preprocessor: function (e, a) {
    if (e.getDate() === 1) return a;
    var t = a.some(function (e) {
      return e.isToken && LONG_MONTHS_TOKENS.includes(e.value);
    });
    if (!t) return a;
    return a.map(function (e) {
      return e.isToken && e.value === "do" ? {
        isToken: true,
        value: "d"
      } : e;
    });
  },
  ordinalNumber: ordinalNumber$U,
  era: (0, _index$3O.buildLocalizeFn)({
    values: eraValues$U,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$3O.buildLocalizeFn)({
    values: quarterValues$U,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$3O.buildLocalizeFn)({
    values: monthValues$U,
    defaultWidth: "wide"
  }),
  day: (0, _index$3O.buildLocalizeFn)({
    values: dayValues$U,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$3O.buildLocalizeFn)({
    values: dayPeriodValues$U,
    defaultWidth: "wide"
  })
};
var match$U = {};
match$U.match = void 0;
var _index$3N = buildMatchFn;
var _index2$1R = buildMatchPatternFn;
var matchOrdinalNumberPattern$U = /^(\d+)(ième|ère|ème|er|e)?/i;
var parseOrdinalNumberPattern$U = /\d+/i;
var matchEraPatterns$U = {
  narrow: /^(av\.J\.C|ap\.J\.C|ap\.J\.-C)/i,
  abbreviated: /^(av\.J\.-C|av\.J-C|apr\.J\.-C|apr\.J-C|ap\.J-C)/i,
  wide: /^(avant Jésus-Christ|après Jésus-Christ)/i
};
var parseEraPatterns$U = {
  any: [/^av/i, /^ap/i]
};
var matchQuarterPatterns$U = {
  narrow: /^T?[1234]/i,
  abbreviated: /^[1234](er|ème|e)? trim\.?/i,
  wide: /^[1234](er|ème|e)? trimestre/i
};
var parseQuarterPatterns$U = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$U = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(janv|févr|mars|avr|mai|juin|juill|juil|août|sept|oct|nov|déc)\.?/i,
  wide: /^(janvier|février|mars|avril|mai|juin|juillet|août|septembre|octobre|novembre|décembre)/i
};
var parseMonthPatterns$U = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^av/i, /^ma/i, /^juin/i, /^juil/i, /^ao/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$U = {
  narrow: /^[lmjvsd]/i,
  short: /^(di|lu|ma|me|je|ve|sa)/i,
  abbreviated: /^(dim|lun|mar|mer|jeu|ven|sam)\.?/i,
  wide: /^(dimanche|lundi|mardi|mercredi|jeudi|vendredi|samedi)/i
};
var parseDayPatterns$U = {
  narrow: [/^d/i, /^l/i, /^m/i, /^m/i, /^j/i, /^v/i, /^s/i],
  any: [/^di/i, /^lu/i, /^ma/i, /^me/i, /^je/i, /^ve/i, /^sa/i]
};
var matchDayPeriodPatterns$U = {
  narrow: /^(a|p|minuit|midi|mat\.?|ap\.?m\.?|soir|nuit)/i,
  any: /^([ap]\.?\s?m\.?|du matin|de l'après[-\s]midi|du soir|de la nuit)/i
};
var parseDayPeriodPatterns$U = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^min/i,
    noon: /^mid/i,
    morning: /mat/i,
    afternoon: /ap/i,
    evening: /soir/i,
    night: /nuit/i
  }
};
match$U.match = {
  ordinalNumber: (0, _index2$1R.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$U,
    parsePattern: parseOrdinalNumberPattern$U,
    valueCallback: function (e) {
      return parseInt(e);
    }
  }),
  era: (0, _index$3N.buildMatchFn)({
    matchPatterns: matchEraPatterns$U,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$U,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$3N.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$U,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$U,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$3N.buildMatchFn)({
    matchPatterns: matchMonthPatterns$U,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$U,
    defaultParseWidth: "any"
  }),
  day: (0, _index$3N.buildMatchFn)({
    matchPatterns: matchDayPatterns$U,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$U,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$3N.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$U,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$U,
    defaultParseWidth: "any"
  })
};
fr.fr = void 0;
var _index$3M = formatDistance$1N;
var _index2$1Q = formatLong$X;
var _index3$Y = formatRelative$1P;
var _index4$X = localize$U;
var _index5$X = match$U;
fr.fr = {
  code: "fr",
  formatDistance: _index$3M.formatDistance,
  formatLong: _index2$1Q.formatLong,
  formatRelative: _index3$Y.formatRelative,
  localize: _index4$X.localize,
  match: _index5$X.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var frCA = {};
var formatLong$W = {};
formatLong$W.formatLong = void 0;
var _index$3L = buildFormatLongFn;
var dateFormats$W = {
  full: "EEEE d MMMM y",
  long: "d MMMM y",
  medium: "d MMM y",
  short: "yy-MM-dd"
};
var timeFormats$W = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$W = {
  full: "{{date}} 'à' {{time}}",
  long: "{{date}} 'à' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$W.formatLong = {
  date: (0, _index$3L.buildFormatLongFn)({
    formats: dateFormats$W,
    defaultWidth: "full"
  }),
  time: (0, _index$3L.buildFormatLongFn)({
    formats: timeFormats$W,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3L.buildFormatLongFn)({
    formats: dateTimeFormats$W,
    defaultWidth: "full"
  })
};
frCA.frCA = void 0;
var _index$3K = formatDistance$1N;
var _index2$1P = formatRelative$1P;
var _index3$X = localize$U;
var _index4$W = match$U;
var _index5$W = formatLong$W;
frCA.frCA = {
  code: "fr-CA",
  formatDistance: _index$3K.formatDistance,
  formatLong: _index5$W.formatLong,
  formatRelative: _index2$1P.formatRelative,
  localize: _index3$X.localize,
  match: _index4$W.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var frCH = {};
var formatLong$V = {};
formatLong$V.formatLong = void 0;
var _index$3J = buildFormatLongFn;
var dateFormats$V = {
  full: "EEEE d MMMM y",
  long: "d MMMM y",
  medium: "d MMM y",
  short: "dd.MM.y"
};
var timeFormats$V = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$V = {
  full: "{{date}} 'à' {{time}}",
  long: "{{date}} 'à' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$V.formatLong = {
  date: (0, _index$3J.buildFormatLongFn)({
    formats: dateFormats$V,
    defaultWidth: "full"
  }),
  time: (0, _index$3J.buildFormatLongFn)({
    formats: timeFormats$V,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3J.buildFormatLongFn)({
    formats: dateTimeFormats$V,
    defaultWidth: "full"
  })
};
var formatRelative$1N = {};
formatRelative$1N.formatRelative = void 0;
var formatRelativeLocale$U = {
  lastWeek: "eeee 'la semaine dernière à' p",
  yesterday: "'hier à' p",
  today: "'aujourd’hui à' p",
  tomorrow: "'demain à' p'",
  nextWeek: "eeee 'la semaine prochaine à' p",
  other: "P"
};
var formatRelative$1M = function (e, a, t, r) {
  return formatRelativeLocale$U[e];
};
formatRelative$1N.formatRelative = formatRelative$1M;
frCH.frCH = void 0;
var _index$3I = formatDistance$1N;
var _index2$1O = localize$U;
var _index3$W = match$U;
var _index4$V = formatLong$V;
var _index5$V = formatRelative$1N;
frCH.frCH = {
  code: "fr-CH",
  formatDistance: _index$3I.formatDistance,
  formatLong: _index4$V.formatLong,
  formatRelative: _index5$V.formatRelative,
  localize: _index2$1O.localize,
  match: _index3$W.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var fy = {};
var formatDistance$1L = {};
formatDistance$1L.formatDistance = void 0;
var formatDistanceLocale$S = {
  lessThanXSeconds: {
    one: "minder as 1 sekonde",
    other: "minder as {{count}} sekonden"
  },
  xSeconds: {
    one: "1 sekonde",
    other: "{{count}} sekonden"
  },
  halfAMinute: "oardel minút",
  lessThanXMinutes: {
    one: "minder as 1 minút",
    other: "minder as {{count}} minuten"
  },
  xMinutes: {
    one: "1 minút",
    other: "{{count}} minuten"
  },
  aboutXHours: {
    one: "sawat 1 oere",
    other: "sawat {{count}} oere"
  },
  xHours: {
    one: "1 oere",
    other: "{{count}} oere"
  },
  xDays: {
    one: "1 dei",
    other: "{{count}} dagen"
  },
  aboutXWeeks: {
    one: "sawat 1 wike",
    other: "sawat {{count}} wiken"
  },
  xWeeks: {
    one: "1 wike",
    other: "{{count}} wiken"
  },
  aboutXMonths: {
    one: "sawat 1 moanne",
    other: "sawat {{count}} moannen"
  },
  xMonths: {
    one: "1 moanne",
    other: "{{count}} moannen"
  },
  aboutXYears: {
    one: "sawat 1 jier",
    other: "sawat {{count}} jier"
  },
  xYears: {
    one: "1 jier",
    other: "{{count}} jier"
  },
  overXYears: {
    one: "mear as 1 jier",
    other: "mear as {{count}}s jier"
  },
  almostXYears: {
    one: "hast 1 jier",
    other: "hast {{count}} jier"
  }
};
var formatDistance$1K = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$S[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "oer " + r;
    } else {
      return r + " lyn";
    }
  }
  return r;
};
formatDistance$1L.formatDistance = formatDistance$1K;
var formatLong$U = {};
formatLong$U.formatLong = void 0;
var _index$3H = buildFormatLongFn;
var dateFormats$U = {
  full: "EEEE d MMMM y",
  long: "d MMMM y",
  medium: "d MMM y",
  short: "dd-MM-y"
};
var timeFormats$U = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$U = {
  full: "{{date}} 'om' {{time}}",
  long: "{{date}} 'om' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$U.formatLong = {
  date: (0, _index$3H.buildFormatLongFn)({
    formats: dateFormats$U,
    defaultWidth: "full"
  }),
  time: (0, _index$3H.buildFormatLongFn)({
    formats: timeFormats$U,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3H.buildFormatLongFn)({
    formats: dateTimeFormats$U,
    defaultWidth: "full"
  })
};
var formatRelative$1L = {};
formatRelative$1L.formatRelative = void 0;
var formatRelativeLocale$T = {
  lastWeek: "'ôfrûne' eeee 'om' p",
  yesterday: "'juster om' p",
  today: "'hjoed om' p",
  tomorrow: "'moarn om' p",
  nextWeek: "eeee 'om' p",
  other: "P"
};
var formatRelative$1K = function (e, a, t, r) {
  return formatRelativeLocale$T[e];
};
formatRelative$1L.formatRelative = formatRelative$1K;
var localize$T = {};
localize$T.localize = void 0;
var _index$3G = buildLocalizeFn;
var eraValues$T = {
  narrow: ["f.K.", "n.K."],
  abbreviated: ["f.Kr.", "n.Kr."],
  wide: ["foar Kristus", "nei Kristus"]
};
var quarterValues$T = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["K1", "K2", "K3", "K4"],
  wide: ["1e fearnsjier", "2e fearnsjier", "3e fearnsjier", "4e fearnsjier"]
};
var monthValues$T = {
  narrow: ["j", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
  abbreviated: ["jan.", "feb.", "mrt.", "apr.", "mai.", "jun.", "jul.", "aug.", "sep.", "okt.", "nov.", "des."],
  wide: ["jannewaris", "febrewaris", "maart", "april", "maaie", "juny", "july", "augustus", "septimber", "oktober", "novimber", "desimber"]
};
var dayValues$T = {
  narrow: ["s", "m", "t", "w", "t", "f", "s"],
  short: ["si", "mo", "ti", "wo", "to", "fr", "so"],
  abbreviated: ["snein", "moa", "tii", "woa", "ton", "fre", "sneon"],
  wide: ["snein", "moandei", "tiisdei", "woansdei", "tongersdei", "freed", "sneon"]
};
var dayPeriodValues$T = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "middernacht",
    noon: "middei",
    morning: "moarns",
    afternoon: "middeis",
    evening: "jûns",
    night: "nachts"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "middernacht",
    noon: "middei",
    morning: "moarns",
    afternoon: "middeis",
    evening: "jûns",
    night: "nachts"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "middernacht",
    noon: "middei",
    morning: "moarns",
    afternoon: "middeis",
    evening: "jûns",
    night: "nachts"
  }
};
var ordinalNumber$T = function (e, a) {
  var t = Number(e);
  return t + "e";
};
localize$T.localize = {
  ordinalNumber: ordinalNumber$T,
  era: (0, _index$3G.buildLocalizeFn)({
    values: eraValues$T,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$3G.buildLocalizeFn)({
    values: quarterValues$T,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$3G.buildLocalizeFn)({
    values: monthValues$T,
    defaultWidth: "wide"
  }),
  day: (0, _index$3G.buildLocalizeFn)({
    values: dayValues$T,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$3G.buildLocalizeFn)({
    values: dayPeriodValues$T,
    defaultWidth: "wide"
  })
};
var match$T = {};
match$T.match = void 0;
var _index$3F = buildMatchFn;
var _index2$1N = buildMatchPatternFn;
var matchOrdinalNumberPattern$T = /^(\d+)e?/i;
var parseOrdinalNumberPattern$T = /\d+/i;
var matchEraPatterns$T = {
  narrow: /^([fn]\.? ?K\.?)/,
  abbreviated: /^([fn]\. ?Kr\.?)/,
  wide: /^((foar|nei) Kristus)/
};
var parseEraPatterns$T = {
  any: [/^f/, /^n/]
};
var matchQuarterPatterns$T = {
  narrow: /^[1234]/i,
  abbreviated: /^K[1234]/i,
  wide: /^[1234]e fearnsjier/i
};
var parseQuarterPatterns$T = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$T = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan.|feb.|mrt.|apr.|mai.|jun.|jul.|aug.|sep.|okt.|nov.|des.)/i,
  wide: /^(jannewaris|febrewaris|maart|april|maaie|juny|july|augustus|septimber|oktober|novimber|desimber)/i
};
var parseMonthPatterns$T = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^jan/i, /^feb/i, /^m(r|a)/i, /^apr/i, /^mai/i, /^jun/i, /^jul/i, /^aug/i, /^sep/i, /^okt/i, /^nov/i, /^des/i]
};
var matchDayPatterns$T = {
  narrow: /^[smtwf]/i,
  short: /^(si|mo|ti|wo|to|fr|so)/i,
  abbreviated: /^(snein|moa|tii|woa|ton|fre|sneon)/i,
  wide: /^(snein|moandei|tiisdei|woansdei|tongersdei|freed|sneon)/i
};
var parseDayPatterns$T = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^sn/i, /^mo/i, /^ti/i, /^wo/i, /^to/i, /^fr/i, /^sn/i]
};
var matchDayPeriodPatterns$T = {
  any: /^(am|pm|middernacht|middeis|moarns|middei|jûns|nachts)/i
};
var parseDayPeriodPatterns$T = {
  any: {
    am: /^am/i,
    pm: /^pm/i,
    midnight: /^middernacht/i,
    noon: /^middei/i,
    morning: /moarns/i,
    afternoon: /^middeis/i,
    evening: /jûns/i,
    night: /nachts/i
  }
};
match$T.match = {
  ordinalNumber: (0, _index2$1N.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$T,
    parsePattern: parseOrdinalNumberPattern$T,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$3F.buildMatchFn)({
    matchPatterns: matchEraPatterns$T,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$T,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$3F.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$T,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$T,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$3F.buildMatchFn)({
    matchPatterns: matchMonthPatterns$T,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$T,
    defaultParseWidth: "any"
  }),
  day: (0, _index$3F.buildMatchFn)({
    matchPatterns: matchDayPatterns$T,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$T,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$3F.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$T,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$T,
    defaultParseWidth: "any"
  })
};
fy.fy = void 0;
var _index$3E = formatDistance$1L;
var _index2$1M = formatLong$U;
var _index3$V = formatRelative$1L;
var _index4$U = localize$T;
var _index5$U = match$T;
fy.fy = {
  code: "fy",
  formatDistance: _index$3E.formatDistance,
  formatLong: _index2$1M.formatLong,
  formatRelative: _index3$V.formatRelative,
  localize: _index4$U.localize,
  match: _index5$U.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var gd = {};
var formatDistance$1J = {};
formatDistance$1J.formatDistance = void 0;
var formatDistanceLocale$R = {
  lessThanXSeconds: {
    one: "nas lugha na diog",
    other: "nas lugha na {{count}} diogan"
  },
  xSeconds: {
    one: "1 diog",
    two: "2 dhiog",
    twenty: "20 diog",
    other: "{{count}} diogan"
  },
  halfAMinute: "leth mhionaid",
  lessThanXMinutes: {
    one: "nas lugha na mionaid",
    other: "nas lugha na {{count}} mionaidean"
  },
  xMinutes: {
    one: "1 mionaid",
    two: "2 mhionaid",
    twenty: "20 mionaid",
    other: "{{count}} mionaidean"
  },
  aboutXHours: {
    one: "mu uair de thìde",
    other: "mu {{count}} uairean de thìde"
  },
  xHours: {
    one: "1 uair de thìde",
    two: "2 uair de thìde",
    twenty: "20 uair de thìde",
    other: "{{count}} uairean de thìde"
  },
  xDays: {
    one: "1 là",
    other: "{{count}} là"
  },
  aboutXWeeks: {
    one: "mu 1 seachdain",
    other: "mu {{count}} seachdainean"
  },
  xWeeks: {
    one: "1 seachdain",
    other: "{{count}} seachdainean"
  },
  aboutXMonths: {
    one: "mu mhìos",
    other: "mu {{count}} mìosan"
  },
  xMonths: {
    one: "1 mìos",
    other: "{{count}} mìosan"
  },
  aboutXYears: {
    one: "mu bhliadhna",
    other: "mu {{count}} bliadhnaichean"
  },
  xYears: {
    one: "1 bhliadhna",
    other: "{{count}} bliadhna"
  },
  overXYears: {
    one: "còrr is bliadhna",
    other: "còrr is {{count}} bliadhnaichean"
  },
  almostXYears: {
    one: "cha mhòr bliadhna",
    other: "cha mhòr {{count}} bliadhnaichean"
  }
};
var formatDistance$1I = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$R[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else if (a === 2 && !!n.two) {
    r = n.two;
  } else if (a === 20 && !!n.twenty) {
    r = n.twenty;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "ann an " + r;
    } else {
      return "o chionn " + r;
    }
  }
  return r;
};
formatDistance$1J.formatDistance = formatDistance$1I;
var formatLong$T = {};
formatLong$T.formatLong = void 0;
var _index$3D = buildFormatLongFn;
var dateFormats$T = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
};
var timeFormats$T = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$T = {
  full: "{{date}} 'aig' {{time}}",
  long: "{{date}} 'aig' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$T.formatLong = {
  date: (0, _index$3D.buildFormatLongFn)({
    formats: dateFormats$T,
    defaultWidth: "full"
  }),
  time: (0, _index$3D.buildFormatLongFn)({
    formats: timeFormats$T,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3D.buildFormatLongFn)({
    formats: dateTimeFormats$T,
    defaultWidth: "full"
  })
};
var formatRelative$1J = {};
formatRelative$1J.formatRelative = void 0;
var formatRelativeLocale$S = {
  lastWeek: "'mu dheireadh' eeee 'aig' p",
  yesterday: "'an-dè aig' p",
  today: "'an-diugh aig' p",
  tomorrow: "'a-màireach aig' p",
  nextWeek: "eeee 'aig' p",
  other: "P"
};
var formatRelative$1I = function (e, a, t, r) {
  return formatRelativeLocale$S[e];
};
formatRelative$1J.formatRelative = formatRelative$1I;
var localize$S = {};
localize$S.localize = void 0;
var _index$3C = buildLocalizeFn;
var eraValues$S = {
  narrow: ["R", "A"],
  abbreviated: ["RC", "AD"],
  wide: ["ro Chrìosta", "anno domini"]
};
var quarterValues$S = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["C1", "C2", "C3", "C4"],
  wide: ["a' chiad chairteal", "an dàrna cairteal", "an treas cairteal", "an ceathramh cairteal"]
};
var monthValues$S = {
  narrow: ["F", "G", "M", "G", "C", "Ò", "I", "L", "S", "D", "S", "D"],
  abbreviated: ["Faoi", "Gear", "Màrt", "Gibl", "Cèit", "Ògmh", "Iuch", "Lùn", "Sult", "Dàmh", "Samh", "Dùbh"],
  wide: ["Am Faoilleach", "An Gearran", "Am Màrt", "An Giblean", "An Cèitean", "An t-Ògmhios", "An t-Iuchar", "An Lùnastal", "An t-Sultain", "An Dàmhair", "An t-Samhain", "An Dùbhlachd"]
};
var dayValues$S = {
  narrow: ["D", "L", "M", "C", "A", "H", "S"],
  short: ["Dò", "Lu", "Mà", "Ci", "Ar", "Ha", "Sa"],
  abbreviated: ["Did", "Dil", "Dim", "Dic", "Dia", "Dih", "Dis"],
  wide: ["Didòmhnaich", "Diluain", "Dimàirt", "Diciadain", "Diardaoin", "Dihaoine", "Disathairne"]
};
var dayPeriodValues$S = {
  narrow: {
    am: "m",
    pm: "f",
    midnight: "m.o.",
    noon: "m.l.",
    morning: "madainn",
    afternoon: "feasgar",
    evening: "feasgar",
    night: "oidhche"
  },
  abbreviated: {
    am: "M.",
    pm: "F.",
    midnight: "meadhan oidhche",
    noon: "meadhan là",
    morning: "madainn",
    afternoon: "feasgar",
    evening: "feasgar",
    night: "oidhche"
  },
  wide: {
    am: "m.",
    pm: "f.",
    midnight: "meadhan oidhche",
    noon: "meadhan là",
    morning: "madainn",
    afternoon: "feasgar",
    evening: "feasgar",
    night: "oidhche"
  }
};
var formattingDayPeriodValues$I = {
  narrow: {
    am: "m",
    pm: "f",
    midnight: "m.o.",
    noon: "m.l.",
    morning: "sa mhadainn",
    afternoon: "feasgar",
    evening: "feasgar",
    night: "air an oidhche"
  },
  abbreviated: {
    am: "M.",
    pm: "F.",
    midnight: "meadhan oidhche",
    noon: "meadhan là",
    morning: "sa mhadainn",
    afternoon: "feasgar",
    evening: "feasgar",
    night: "air an oidhche"
  },
  wide: {
    am: "m.",
    pm: "f.",
    midnight: "meadhan oidhche",
    noon: "meadhan là",
    morning: "sa mhadainn",
    afternoon: "feasgar",
    evening: "feasgar",
    night: "air an oidhche"
  }
};
var ordinalNumber$S = function (e) {
  var a = Number(e);
  var t = a % 100;
  if (t > 20 || t < 10) {
    switch (t % 10) {
      case 1:
        return a + "d";
      case 2:
        return a + "na";
    }
  }
  if (t === 12) {
    return a + "na";
  }
  return a + "mh";
};
localize$S.localize = {
  ordinalNumber: ordinalNumber$S,
  era: (0, _index$3C.buildLocalizeFn)({
    values: eraValues$S,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$3C.buildLocalizeFn)({
    values: quarterValues$S,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$3C.buildLocalizeFn)({
    values: monthValues$S,
    defaultWidth: "wide"
  }),
  day: (0, _index$3C.buildLocalizeFn)({
    values: dayValues$S,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$3C.buildLocalizeFn)({
    values: dayPeriodValues$S,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$I,
    defaultFormattingWidth: "wide"
  })
};
var match$S = {};
match$S.match = void 0;
var _index$3B = buildMatchFn;
var _index2$1L = buildMatchPatternFn;
var matchOrdinalNumberPattern$S = /^(\d+)(d|na|tr|mh)?/i;
var parseOrdinalNumberPattern$S = /\d+/i;
var matchEraPatterns$S = {
  narrow: /^(r|a)/i,
  abbreviated: /^(r\.?\s?c\.?|r\.?\s?a\.?\s?c\.?|a\.?\s?d\.?|a\.?\s?c\.?)/i,
  wide: /^(ro Chrìosta|ron aois choitchinn|anno domini|aois choitcheann)/i
};
var parseEraPatterns$S = {
  any: [/^b/i, /^(a|c)/i]
};
var matchQuarterPatterns$S = {
  narrow: /^[1234]/i,
  abbreviated: /^c[1234]/i,
  wide: /^[1234](cd|na|tr|mh)? cairteal/i
};
var parseQuarterPatterns$S = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$S = {
  narrow: /^[fgmcòilsd]/i,
  abbreviated: /^(faoi|gear|màrt|gibl|cèit|ògmh|iuch|lùn|sult|dàmh|samh|dùbh)/i,
  wide: /^(am faoilleach|an gearran|am màrt|an giblean|an cèitean|an t-Ògmhios|an t-Iuchar|an lùnastal|an t-Sultain|an dàmhair|an t-Samhain|an dùbhlachd)/i
};
var parseMonthPatterns$S = {
  narrow: [/^f/i, /^g/i, /^m/i, /^g/i, /^c/i, /^ò/i, /^i/i, /^l/i, /^s/i, /^d/i, /^s/i, /^d/i],
  any: [/^fa/i, /^ge/i, /^mà/i, /^gi/i, /^c/i, /^ò/i, /^i/i, /^l/i, /^su/i, /^d/i, /^sa/i, /^d/i]
};
var matchDayPatterns$S = {
  narrow: /^[dlmcahs]/i,
  short: /^(dò|lu|mà|ci|ar|ha|sa)/i,
  abbreviated: /^(did|dil|dim|dic|dia|dih|dis)/i,
  wide: /^(didòmhnaich|diluain|dimàirt|diciadain|diardaoin|dihaoine|disathairne)/i
};
var parseDayPatterns$S = {
  narrow: [/^d/i, /^l/i, /^m/i, /^c/i, /^a/i, /^h/i, /^s/i],
  any: [/^d/i, /^l/i, /^m/i, /^c/i, /^a/i, /^h/i, /^s/i]
};
var matchDayPeriodPatterns$S = {
  narrow: /^(a|p|mi|n|(san|aig) (madainn|feasgar|feasgar|oidhche))/i,
  any: /^([ap]\.?\s?m\.?|meadhan oidhche|meadhan là|(san|aig) (madainn|feasgar|feasgar|oidhche))/i
};
var parseDayPeriodPatterns$S = {
  any: {
    am: /^m/i,
    pm: /^f/i,
    midnight: /^meadhan oidhche/i,
    noon: /^meadhan là/i,
    morning: /sa mhadainn/i,
    afternoon: /feasgar/i,
    evening: /feasgar/i,
    night: /air an oidhche/i
  }
};
match$S.match = {
  ordinalNumber: (0, _index2$1L.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$S,
    parsePattern: parseOrdinalNumberPattern$S,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$3B.buildMatchFn)({
    matchPatterns: matchEraPatterns$S,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$S,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$3B.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$S,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$S,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$3B.buildMatchFn)({
    matchPatterns: matchMonthPatterns$S,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$S,
    defaultParseWidth: "any"
  }),
  day: (0, _index$3B.buildMatchFn)({
    matchPatterns: matchDayPatterns$S,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$S,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$3B.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$S,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$S,
    defaultParseWidth: "any"
  })
};
gd.gd = void 0;
var _index$3A = formatDistance$1J;
var _index2$1K = formatLong$T;
var _index3$U = formatRelative$1J;
var _index4$T = localize$S;
var _index5$T = match$S;
gd.gd = {
  code: "gd",
  formatDistance: _index$3A.formatDistance,
  formatLong: _index2$1K.formatLong,
  formatRelative: _index3$U.formatRelative,
  localize: _index4$T.localize,
  match: _index5$T.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var gl = {};
var formatDistance$1H = {};
formatDistance$1H.formatDistance = void 0;
var formatDistanceLocale$Q = {
  lessThanXSeconds: {
    one: "menos dun segundo",
    other: "menos de {{count}} segundos"
  },
  xSeconds: {
    one: "1 segundo",
    other: "{{count}} segundos"
  },
  halfAMinute: "medio minuto",
  lessThanXMinutes: {
    one: "menos dun minuto",
    other: "menos de {{count}} minutos"
  },
  xMinutes: {
    one: "1 minuto",
    other: "{{count}} minutos"
  },
  aboutXHours: {
    one: "arredor dunha hora",
    other: "arredor de {{count}} horas"
  },
  xHours: {
    one: "1 hora",
    other: "{{count}} horas"
  },
  xDays: {
    one: "1 día",
    other: "{{count}} días"
  },
  aboutXWeeks: {
    one: "arredor dunha semana",
    other: "arredor de {{count}} semanas"
  },
  xWeeks: {
    one: "1 semana",
    other: "{{count}} semanas"
  },
  aboutXMonths: {
    one: "arredor de 1 mes",
    other: "arredor de {{count}} meses"
  },
  xMonths: {
    one: "1 mes",
    other: "{{count}} meses"
  },
  aboutXYears: {
    one: "arredor dun ano",
    other: "arredor de {{count}} anos"
  },
  xYears: {
    one: "1 ano",
    other: "{{count}} anos"
  },
  overXYears: {
    one: "máis dun ano",
    other: "máis de {{count}} anos"
  },
  almostXYears: {
    one: "case un ano",
    other: "case {{count}} anos"
  }
};
var formatDistance$1G = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$Q[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "en " + r;
    } else {
      return "hai " + r;
    }
  }
  return r;
};
formatDistance$1H.formatDistance = formatDistance$1G;
var formatLong$S = {};
formatLong$S.formatLong = void 0;
var _index$3z = buildFormatLongFn;
var dateFormats$S = {
  full: "EEEE, d 'de' MMMM y",
  long: "d 'de' MMMM y",
  medium: "d MMM y",
  short: "dd/MM/y"
};
var timeFormats$S = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$S = {
  full: "{{date}} 'ás' {{time}}",
  long: "{{date}} 'ás' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$S.formatLong = {
  date: (0, _index$3z.buildFormatLongFn)({
    formats: dateFormats$S,
    defaultWidth: "full"
  }),
  time: (0, _index$3z.buildFormatLongFn)({
    formats: timeFormats$S,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3z.buildFormatLongFn)({
    formats: dateTimeFormats$S,
    defaultWidth: "full"
  })
};
var formatRelative$1H = {};
formatRelative$1H.formatRelative = void 0;
var formatRelativeLocale$R = {
  lastWeek: "'o' eeee 'pasado á' LT",
  yesterday: "'onte á' p",
  today: "'hoxe á' p",
  tomorrow: "'mañá á' p",
  nextWeek: "eeee 'á' p",
  other: "P"
};
var formatRelativeLocalePlural = {
  lastWeek: "'o' eeee 'pasado ás' p",
  yesterday: "'onte ás' p",
  today: "'hoxe ás' p",
  tomorrow: "'mañá ás' p",
  nextWeek: "eeee 'ás' p",
  other: "P"
};
var formatRelative$1G = function (e, a, t, r) {
  if (a.getHours() !== 1) {
    return formatRelativeLocalePlural[e];
  }
  return formatRelativeLocale$R[e];
};
formatRelative$1H.formatRelative = formatRelative$1G;
var localize$R = {};
localize$R.localize = void 0;
var _index$3y = buildLocalizeFn;
var eraValues$R = {
  narrow: ["AC", "DC"],
  abbreviated: ["AC", "DC"],
  wide: ["antes de cristo", "despois de cristo"]
};
var quarterValues$R = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["T1", "T2", "T3", "T4"],
  wide: ["1º trimestre", "2º trimestre", "3º trimestre", "4º trimestre"]
};
var monthValues$R = {
  narrow: ["e", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
  abbreviated: ["xan", "feb", "mar", "abr", "mai", "xun", "xul", "ago", "set", "out", "nov", "dec"],
  wide: ["xaneiro", "febreiro", "marzo", "abril", "maio", "xuño", "xullo", "agosto", "setembro", "outubro", "novembro", "decembro"]
};
var dayValues$R = {
  narrow: ["d", "l", "m", "m", "j", "v", "s"],
  short: ["do", "lu", "ma", "me", "xo", "ve", "sa"],
  abbreviated: ["dom", "lun", "mar", "mer", "xov", "ven", "sab"],
  wide: ["domingo", "luns", "martes", "mércores", "xoves", "venres", "sábado"]
};
var dayPeriodValues$R = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mn",
    noon: "md",
    morning: "mañá",
    afternoon: "tarde",
    evening: "tarde",
    night: "noite"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "medianoite",
    noon: "mediodía",
    morning: "mañá",
    afternoon: "tarde",
    evening: "tardiña",
    night: "noite"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "medianoite",
    noon: "mediodía",
    morning: "mañá",
    afternoon: "tarde",
    evening: "tardiña",
    night: "noite"
  }
};
var formattingDayPeriodValues$H = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mn",
    noon: "md",
    morning: "da mañá",
    afternoon: "da tarde",
    evening: "da tardiña",
    night: "da noite"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "medianoite",
    noon: "mediodía",
    morning: "da mañá",
    afternoon: "da tarde",
    evening: "da tardiña",
    night: "da noite"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "medianoite",
    noon: "mediodía",
    morning: "da mañá",
    afternoon: "da tarde",
    evening: "da tardiña",
    night: "da noite"
  }
};
var ordinalNumber$R = function (e, a) {
  var t = Number(e);
  return t + "º";
};
localize$R.localize = {
  ordinalNumber: ordinalNumber$R,
  era: (0, _index$3y.buildLocalizeFn)({
    values: eraValues$R,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$3y.buildLocalizeFn)({
    values: quarterValues$R,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$3y.buildLocalizeFn)({
    values: monthValues$R,
    defaultWidth: "wide"
  }),
  day: (0, _index$3y.buildLocalizeFn)({
    values: dayValues$R,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$3y.buildLocalizeFn)({
    values: dayPeriodValues$R,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$H,
    defaultFormattingWidth: "wide"
  })
};
var match$R = {};
match$R.match = void 0;
var _index$3x = buildMatchFn;
var _index2$1J = buildMatchPatternFn;
var matchOrdinalNumberPattern$R = /^(\d+)(º)?/i;
var parseOrdinalNumberPattern$R = /\d+/i;
var matchEraPatterns$R = {
  narrow: /^(ac|dc|a|d)/i,
  abbreviated: /^(a\.?\s?c\.?|a\.?\s?e\.?\s?c\.?|d\.?\s?c\.?|e\.?\s?c\.?)/i,
  wide: /^(antes de cristo|antes da era com[uú]n|despois de cristo|era com[uú]n)/i
};
var parseEraPatterns$R = {
  any: [/^ac/i, /^dc/i],
  wide: [/^(antes de cristo|antes da era com[uú]n)/i, /^(despois de cristo|era com[uú]n)/i]
};
var matchQuarterPatterns$R = {
  narrow: /^[1234]/i,
  abbreviated: /^T[1234]/i,
  wide: /^[1234](º)? trimestre/i
};
var parseQuarterPatterns$R = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$R = {
  narrow: /^[xfmasond]/i,
  abbreviated: /^(xan|feb|mar|abr|mai|xun|xul|ago|set|out|nov|dec)/i,
  wide: /^(xaneiro|febreiro|marzo|abril|maio|xuño|xullo|agosto|setembro|outubro|novembro|decembro)/i
};
var parseMonthPatterns$R = {
  narrow: [/^x/i, /^f/i, /^m/i, /^a/i, /^m/i, /^x/i, /^x/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^xan/i, /^feb/i, /^mar/i, /^abr/i, /^mai/i, /^xun/i, /^xul/i, /^ago/i, /^set/i, /^out/i, /^nov/i, /^dec/i]
};
var matchDayPatterns$R = {
  narrow: /^[dlmxvs]/i,
  short: /^(do|lu|ma|me|xo|ve|sa)/i,
  abbreviated: /^(dom|lun|mar|mer|xov|ven|sab)/i,
  wide: /^(domingo|luns|martes|m[eé]rcores|xoves|venres|s[áa]bado)/i
};
var parseDayPatterns$R = {
  narrow: [/^d/i, /^l/i, /^m/i, /^m/i, /^x/i, /^v/i, /^s/i],
  any: [/^do/i, /^lu/i, /^ma/i, /^me/i, /^xo/i, /^ve/i, /^sa/i]
};
var matchDayPeriodPatterns$R = {
  narrow: /^(a|p|mn|md|(da|[aá]s) (mañ[aá]|tarde|noite))/i,
  any: /^([ap]\.?\s?m\.?|medianoite|mediod[ií]a|(da|[aá]s) (mañ[aá]|tarde|noite))/i
};
var parseDayPeriodPatterns$R = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mn/i,
    noon: /^md/i,
    morning: /mañ[aá]/i,
    afternoon: /tarde/i,
    evening: /tardiña/i,
    night: /noite/i
  }
};
match$R.match = {
  ordinalNumber: (0, _index2$1J.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$R,
    parsePattern: parseOrdinalNumberPattern$R,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$3x.buildMatchFn)({
    matchPatterns: matchEraPatterns$R,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$R,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$3x.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$R,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$R,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$3x.buildMatchFn)({
    matchPatterns: matchMonthPatterns$R,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$R,
    defaultParseWidth: "any"
  }),
  day: (0, _index$3x.buildMatchFn)({
    matchPatterns: matchDayPatterns$R,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$R,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$3x.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$R,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$R,
    defaultParseWidth: "any"
  })
};
gl.gl = void 0;
var _index$3w = formatDistance$1H;
var _index2$1I = formatLong$S;
var _index3$T = formatRelative$1H;
var _index4$S = localize$R;
var _index5$S = match$R;
gl.gl = {
  code: "gl",
  formatDistance: _index$3w.formatDistance,
  formatLong: _index2$1I.formatLong,
  formatRelative: _index3$T.formatRelative,
  localize: _index4$S.localize,
  match: _index5$S.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var gu = {};
var formatDistance$1F = {};
formatDistance$1F.formatDistance = void 0;
var formatDistanceLocale$P = {
  lessThanXSeconds: {
    one: "હમણાં",
    other: "​આશરે {{count}} સેકંડ"
  },
  xSeconds: {
    one: "1 સેકંડ",
    other: "{{count}} સેકંડ"
  },
  halfAMinute: "અડધી મિનિટ",
  lessThanXMinutes: {
    one: "આ મિનિટ",
    other: "​આશરે {{count}} મિનિટ"
  },
  xMinutes: {
    one: "1 મિનિટ",
    other: "{{count}} મિનિટ"
  },
  aboutXHours: {
    one: "​આશરે 1 કલાક",
    other: "​આશરે {{count}} કલાક"
  },
  xHours: {
    one: "1 કલાક",
    other: "{{count}} કલાક"
  },
  xDays: {
    one: "1 દિવસ",
    other: "{{count}} દિવસ"
  },
  aboutXWeeks: {
    one: "આશરે 1 અઠવાડિયું",
    other: "આશરે {{count}} અઠવાડિયા"
  },
  xWeeks: {
    one: "1 અઠવાડિયું",
    other: "{{count}} અઠવાડિયા"
  },
  aboutXMonths: {
    one: "આશરે 1 મહિનો",
    other: "આશરે {{count}} મહિના"
  },
  xMonths: {
    one: "1 મહિનો",
    other: "{{count}} મહિના"
  },
  aboutXYears: {
    one: "આશરે 1 વર્ષ",
    other: "આશરે {{count}} વર્ષ"
  },
  xYears: {
    one: "1 વર્ષ",
    other: "{{count}} વર્ષ"
  },
  overXYears: {
    one: "1 વર્ષથી વધુ",
    other: "{{count}} વર્ષથી વધુ"
  },
  almostXYears: {
    one: "લગભગ 1 વર્ષ",
    other: "લગભગ {{count}} વર્ષ"
  }
};
var formatDistance$1E = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$P[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + "માં";
    } else {
      return r + " પહેલાં";
    }
  }
  return r;
};
formatDistance$1F.formatDistance = formatDistance$1E;
var formatLong$R = {};
formatLong$R.formatLong = void 0;
var _index$3v = buildFormatLongFn;
var dateFormats$R = {
  full: "EEEE, d MMMM, y",
  long: "d MMMM, y",
  medium: "d MMM, y",
  short: "d/M/yy"
};
var timeFormats$R = {
  full: "hh:mm:ss a zzzz",
  long: "hh:mm:ss a z",
  medium: "hh:mm:ss a",
  short: "hh:mm a"
};
var dateTimeFormats$R = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$R.formatLong = {
  date: (0, _index$3v.buildFormatLongFn)({
    formats: dateFormats$R,
    defaultWidth: "full"
  }),
  time: (0, _index$3v.buildFormatLongFn)({
    formats: timeFormats$R,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3v.buildFormatLongFn)({
    formats: dateTimeFormats$R,
    defaultWidth: "full"
  })
};
var formatRelative$1F = {};
formatRelative$1F.formatRelative = void 0;
var formatRelativeLocale$Q = {
  lastWeek: "'પાછલા' eeee p",
  yesterday: "'ગઈકાલે' p",
  today: "'આજે' p",
  tomorrow: "'આવતીકાલે' p",
  nextWeek: "eeee p",
  other: "P"
};
var formatRelative$1E = function (e, a, t, r) {
  return formatRelativeLocale$Q[e];
};
formatRelative$1F.formatRelative = formatRelative$1E;
var localize$Q = {};
localize$Q.localize = void 0;
var _index$3u = buildLocalizeFn;
var eraValues$Q = {
  narrow: ["ઈસપૂ", "ઈસ"],
  abbreviated: ["ઈ.સ.પૂર્વે", "ઈ.સ."],
  wide: ["ઈસવીસન પૂર્વે", "ઈસવીસન"]
};
var quarterValues$Q = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1લો ત્રિમાસ", "2જો ત્રિમાસ", "3જો ત્રિમાસ", "4થો ત્રિમાસ"]
};
var monthValues$Q = {
  narrow: ["જા", "ફે", "મા", "એ", "મે", "જૂ", "જુ", "ઓ", "સ", "ઓ", "ન", "ડિ"],
  abbreviated: ["જાન્યુ", "ફેબ્રુ", "માર્ચ", "એપ્રિલ", "મે", "જૂન", "જુલાઈ", "ઑગસ્ટ", "સપ્ટે", "ઓક્ટો", "નવે", "ડિસે"],
  wide: ["જાન્યુઆરી", "ફેબ્રુઆરી", "માર્ચ", "એપ્રિલ", "મે", "જૂન", "જુલાઇ", "ઓગસ્ટ", "સપ્ટેમ્બર", "ઓક્ટોબર", "નવેમ્બર", "ડિસેમ્બર"]
};
var dayValues$Q = {
  narrow: ["ર", "સો", "મં", "બુ", "ગુ", "શુ", "શ"],
  short: ["ર", "સો", "મં", "બુ", "ગુ", "શુ", "શ"],
  abbreviated: ["રવિ", "સોમ", "મંગળ", "બુધ", "ગુરુ", "શુક્ર", "શનિ"],
  wide: ["રવિવાર", "સોમવાર", "મંગળવાર", "બુધવાર", "ગુરુવાર", "શુક્રવાર", "શનિવાર"]
};
var dayPeriodValues$Q = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "મ.રાત્રિ",
    noon: "બ.",
    morning: "સવારે",
    afternoon: "બપોરે",
    evening: "સાંજે",
    night: "રાત્રે"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "​મધ્યરાત્રિ",
    noon: "બપોરે",
    morning: "સવારે",
    afternoon: "બપોરે",
    evening: "સાંજે",
    night: "રાત્રે"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "​મધ્યરાત્રિ",
    noon: "બપોરે",
    morning: "સવારે",
    afternoon: "બપોરે",
    evening: "સાંજે",
    night: "રાત્રે"
  }
};
var formattingDayPeriodValues$G = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "મ.રાત્રિ",
    noon: "બપોરે",
    morning: "સવારે",
    afternoon: "બપોરે",
    evening: "સાંજે",
    night: "રાત્રે"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "મધ્યરાત્રિ",
    noon: "બપોરે",
    morning: "સવારે",
    afternoon: "બપોરે",
    evening: "સાંજે",
    night: "રાત્રે"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "​મધ્યરાત્રિ",
    noon: "બપોરે",
    morning: "સવારે",
    afternoon: "બપોરે",
    evening: "સાંજે",
    night: "રાત્રે"
  }
};
var ordinalNumber$Q = function (e, a) {
  return String(e);
};
localize$Q.localize = {
  ordinalNumber: ordinalNumber$Q,
  era: (0, _index$3u.buildLocalizeFn)({
    values: eraValues$Q,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$3u.buildLocalizeFn)({
    values: quarterValues$Q,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$3u.buildLocalizeFn)({
    values: monthValues$Q,
    defaultWidth: "wide"
  }),
  day: (0, _index$3u.buildLocalizeFn)({
    values: dayValues$Q,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$3u.buildLocalizeFn)({
    values: dayPeriodValues$Q,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$G,
    defaultFormattingWidth: "wide"
  })
};
var match$Q = {};
match$Q.match = void 0;
var _index$3t = buildMatchFn;
var _index2$1H = buildMatchPatternFn;
var matchOrdinalNumberPattern$Q = /^(\d+)(લ|જ|થ|ઠ્ઠ|મ)?/i;
var parseOrdinalNumberPattern$Q = /\d+/i;
var matchEraPatterns$Q = {
  narrow: /^(ઈસપૂ|ઈસ)/i,
  abbreviated: /^(ઈ\.સ\.પૂર્વે|ઈ\.સ\.)/i,
  wide: /^(ઈસવીસન\sપૂર્વે|ઈસવીસન)/i
};
var parseEraPatterns$Q = {
  any: [/^ઈસપૂ/i, /^ઈસ/i]
};
var matchQuarterPatterns$Q = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](લો|જો|થો)? ત્રિમાસ/i
};
var parseQuarterPatterns$Q = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$Q = {
  narrow: /^[જાફેમાએમેજૂજુઓસઓનડિ]/i,
  abbreviated: /^(જાન્યુ|ફેબ્રુ|માર્ચ|એપ્રિલ|મે|જૂન|જુલાઈ|ઑગસ્ટ|સપ્ટે|ઓક્ટો|નવે|ડિસે)/i,
  wide: /^(જાન્યુઆરી|ફેબ્રુઆરી|માર્ચ|એપ્રિલ|મે|જૂન|જુલાઇ|ઓગસ્ટ|સપ્ટેમ્બર|ઓક્ટોબર|નવેમ્બર|ડિસેમ્બર)/i
};
var parseMonthPatterns$Q = {
  narrow: [/^જા/i, /^ફે/i, /^મા/i, /^એ/i, /^મે/i, /^જૂ/i, /^જુ/i, /^ઑગ/i, /^સ/i, /^ઓક્ટો/i, /^ન/i, /^ડિ/i],
  any: [/^જા/i, /^ફે/i, /^મા/i, /^એ/i, /^મે/i, /^જૂ/i, /^જુ/i, /^ઑગ/i, /^સ/i, /^ઓક્ટો/i, /^ન/i, /^ડિ/i]
};
var matchDayPatterns$Q = {
  narrow: /^(ર|સો|મં|બુ|ગુ|શુ|શ)/i,
  short: /^(ર|સો|મં|બુ|ગુ|શુ|શ)/i,
  abbreviated: /^(રવિ|સોમ|મંગળ|બુધ|ગુરુ|શુક્ર|શનિ)/i,
  wide: /^(રવિવાર|સોમવાર|મંગળવાર|બુધવાર|ગુરુવાર|શુક્રવાર|શનિવાર)/i
};
var parseDayPatterns$Q = {
  narrow: [/^ર/i, /^સો/i, /^મં/i, /^બુ/i, /^ગુ/i, /^શુ/i, /^શ/i],
  any: [/^ર/i, /^સો/i, /^મં/i, /^બુ/i, /^ગુ/i, /^શુ/i, /^શ/i]
};
var matchDayPeriodPatterns$Q = {
  narrow: /^(a|p|મ\.?|સ|બ|સાં|રા)/i,
  any: /^(a|p|મ\.?|સ|બ|સાં|રા)/i
};
var parseDayPeriodPatterns$Q = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^મ\.?/i,
    noon: /^બ/i,
    morning: /સ/i,
    afternoon: /બ/i,
    evening: /સાં/i,
    night: /રા/i
  }
};
match$Q.match = {
  ordinalNumber: (0, _index2$1H.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$Q,
    parsePattern: parseOrdinalNumberPattern$Q,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$3t.buildMatchFn)({
    matchPatterns: matchEraPatterns$Q,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$Q,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$3t.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$Q,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$Q,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$3t.buildMatchFn)({
    matchPatterns: matchMonthPatterns$Q,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$Q,
    defaultParseWidth: "any"
  }),
  day: (0, _index$3t.buildMatchFn)({
    matchPatterns: matchDayPatterns$Q,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$Q,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$3t.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$Q,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$Q,
    defaultParseWidth: "any"
  })
};
gu.gu = void 0;
var _index$3s = formatDistance$1F;
var _index2$1G = formatLong$R;
var _index3$S = formatRelative$1F;
var _index4$R = localize$Q;
var _index5$R = match$Q;
gu.gu = {
  code: "gu",
  formatDistance: _index$3s.formatDistance,
  formatLong: _index2$1G.formatLong,
  formatRelative: _index3$S.formatRelative,
  localize: _index4$R.localize,
  match: _index5$R.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var he = {};
var formatDistance$1D = {};
formatDistance$1D.formatDistance = void 0;
var formatDistanceLocale$O = {
  lessThanXSeconds: {
    one: "פחות משנייה",
    two: "פחות משתי שניות",
    other: "פחות מ־{{count}} שניות"
  },
  xSeconds: {
    one: "שנייה",
    two: "שתי שניות",
    other: "{{count}} שניות"
  },
  halfAMinute: "חצי דקה",
  lessThanXMinutes: {
    one: "פחות מדקה",
    two: "פחות משתי דקות",
    other: "פחות מ־{{count}} דקות"
  },
  xMinutes: {
    one: "דקה",
    two: "שתי דקות",
    other: "{{count}} דקות"
  },
  aboutXHours: {
    one: "כשעה",
    two: "כשעתיים",
    other: "כ־{{count}} שעות"
  },
  xHours: {
    one: "שעה",
    two: "שעתיים",
    other: "{{count}} שעות"
  },
  xDays: {
    one: "יום",
    two: "יומיים",
    other: "{{count}} ימים"
  },
  aboutXWeeks: {
    one: "כשבוע",
    two: "כשבועיים",
    other: "כ־{{count}} שבועות"
  },
  xWeeks: {
    one: "שבוע",
    two: "שבועיים",
    other: "{{count}} שבועות"
  },
  aboutXMonths: {
    one: "כחודש",
    two: "כחודשיים",
    other: "כ־{{count}} חודשים"
  },
  xMonths: {
    one: "חודש",
    two: "חודשיים",
    other: "{{count}} חודשים"
  },
  aboutXYears: {
    one: "כשנה",
    two: "כשנתיים",
    other: "כ־{{count}} שנים"
  },
  xYears: {
    one: "שנה",
    two: "שנתיים",
    other: "{{count}} שנים"
  },
  overXYears: {
    one: "יותר משנה",
    two: "יותר משנתיים",
    other: "יותר מ־{{count}} שנים"
  },
  almostXYears: {
    one: "כמעט שנה",
    two: "כמעט שנתיים",
    other: "כמעט {{count}} שנים"
  }
};
var formatDistance$1C = function (e, a, t) {
  if (e === "xDays" && (t === null || t === void 0 ? void 0 : t.addSuffix) && a <= 2) {
    if (t.comparison && t.comparison > 0) {
      return a === 1 ? "מחר" : "מחרתיים";
    }
    return a === 1 ? "אתמול" : "שלשום";
  }
  var r;
  var n = formatDistanceLocale$O[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else if (a === 2) {
    r = n.two;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "בעוד " + r;
    } else {
      return "לפני " + r;
    }
  }
  return r;
};
formatDistance$1D.formatDistance = formatDistance$1C;
var formatLong$Q = {};
formatLong$Q.formatLong = void 0;
var _index$3r = buildFormatLongFn;
var dateFormats$Q = {
  full: "EEEE, d בMMMM y",
  long: "d בMMMM y",
  medium: "d בMMM y",
  short: "d.M.y"
};
var timeFormats$Q = {
  full: "H:mm:ss zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$Q = {
  full: "{{date}} 'בשעה' {{time}}",
  long: "{{date}} 'בשעה' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$Q.formatLong = {
  date: (0, _index$3r.buildFormatLongFn)({
    formats: dateFormats$Q,
    defaultWidth: "full"
  }),
  time: (0, _index$3r.buildFormatLongFn)({
    formats: timeFormats$Q,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3r.buildFormatLongFn)({
    formats: dateTimeFormats$Q,
    defaultWidth: "full"
  })
};
var formatRelative$1D = {};
formatRelative$1D.formatRelative = void 0;
var formatRelativeLocale$P = {
  lastWeek: "eeee 'שעבר בשעה' p",
  yesterday: "'אתמול בשעה' p",
  today: "'היום בשעה' p",
  tomorrow: "'מחר בשעה' p",
  nextWeek: "eeee 'בשעה' p",
  other: "P"
};
var formatRelative$1C = function (e, a, t, r) {
  return formatRelativeLocale$P[e];
};
formatRelative$1D.formatRelative = formatRelative$1C;
var localize$P = {};
localize$P.localize = void 0;
var _index$3q = buildLocalizeFn;
var eraValues$P = {
  narrow: ["לפנה״ס", "לספירה"],
  abbreviated: ["לפנה״ס", "לספירה"],
  wide: ["לפני הספירה", "לספירה"]
};
var quarterValues$P = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["רבעון 1", "רבעון 2", "רבעון 3", "רבעון 4"]
};
var monthValues$P = {
  narrow: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
  abbreviated: ["ינו׳", "פבר׳", "מרץ", "אפר׳", "מאי", "יוני", "יולי", "אוג׳", "ספט׳", "אוק׳", "נוב׳", "דצמ׳"],
  wide: ["ינואר", "פברואר", "מרץ", "אפריל", "מאי", "יוני", "יולי", "אוגוסט", "ספטמבר", "אוקטובר", "נובמבר", "דצמבר"]
};
var dayValues$P = {
  narrow: ["א׳", "ב׳", "ג׳", "ד׳", "ה׳", "ו׳", "ש׳"],
  short: ["א׳", "ב׳", "ג׳", "ד׳", "ה׳", "ו׳", "ש׳"],
  abbreviated: ["יום א׳", "יום ב׳", "יום ג׳", "יום ד׳", "יום ה׳", "יום ו׳", "שבת"],
  wide: ["יום ראשון", "יום שני", "יום שלישי", "יום רביעי", "יום חמישי", "יום שישי", "יום שבת"]
};
var dayPeriodValues$P = {
  narrow: {
    am: "לפנה״צ",
    pm: "אחה״צ",
    midnight: "חצות",
    noon: "צהריים",
    morning: "בוקר",
    afternoon: "אחר הצהריים",
    evening: "ערב",
    night: "לילה"
  },
  abbreviated: {
    am: "לפנה״צ",
    pm: "אחה״צ",
    midnight: "חצות",
    noon: "צהריים",
    morning: "בוקר",
    afternoon: "אחר הצהריים",
    evening: "ערב",
    night: "לילה"
  },
  wide: {
    am: "לפנה״צ",
    pm: "אחה״צ",
    midnight: "חצות",
    noon: "צהריים",
    morning: "בוקר",
    afternoon: "אחר הצהריים",
    evening: "ערב",
    night: "לילה"
  }
};
var formattingDayPeriodValues$F = {
  narrow: {
    am: "לפנה״צ",
    pm: "אחה״צ",
    midnight: "חצות",
    noon: "צהריים",
    morning: "בבוקר",
    afternoon: "בצהריים",
    evening: "בערב",
    night: "בלילה"
  },
  abbreviated: {
    am: "לפנה״צ",
    pm: "אחה״צ",
    midnight: "חצות",
    noon: "צהריים",
    morning: "בבוקר",
    afternoon: "אחר הצהריים",
    evening: "בערב",
    night: "בלילה"
  },
  wide: {
    am: "לפנה״צ",
    pm: "אחה״צ",
    midnight: "חצות",
    noon: "צהריים",
    morning: "בבוקר",
    afternoon: "אחר הצהריים",
    evening: "בערב",
    night: "בלילה"
  }
};
var ordinalNumber$P = function (e, a) {
  var t = Number(e);
  if (t <= 0 || t > 10) return String(t);
  var r = String(a === null || a === void 0 ? void 0 : a.unit);
  var n = ["year", "hour", "minute", "second"].indexOf(r) >= 0;
  var i = ["ראשון", "שני", "שלישי", "רביעי", "חמישי", "שישי", "שביעי", "שמיני", "תשיעי", "עשירי"];
  var o = ["ראשונה", "שנייה", "שלישית", "רביעית", "חמישית", "שישית", "שביעית", "שמינית", "תשיעית", "עשירית"];
  var d = t - 1;
  return n ? o[d] : i[d];
};
localize$P.localize = {
  ordinalNumber: ordinalNumber$P,
  era: (0, _index$3q.buildLocalizeFn)({
    values: eraValues$P,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$3q.buildLocalizeFn)({
    values: quarterValues$P,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$3q.buildLocalizeFn)({
    values: monthValues$P,
    defaultWidth: "wide"
  }),
  day: (0, _index$3q.buildLocalizeFn)({
    values: dayValues$P,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$3q.buildLocalizeFn)({
    values: dayPeriodValues$P,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$F,
    defaultFormattingWidth: "wide"
  })
};
var match$P = {};
match$P.match = void 0;
var _index$3p = buildMatchFn;
var _index2$1F = buildMatchPatternFn;
var matchOrdinalNumberPattern$P = /^(\d+|(ראשון|שני|שלישי|רביעי|חמישי|שישי|שביעי|שמיני|תשיעי|עשירי|ראשונה|שנייה|שלישית|רביעית|חמישית|שישית|שביעית|שמינית|תשיעית|עשירית))/i;
var parseOrdinalNumberPattern$P = /^(\d+|רא|שנ|של|רב|ח|שי|שב|שמ|ת|ע)/i;
var matchEraPatterns$P = {
  narrow: /^ל(ספירה|פנה״ס)/i,
  abbreviated: /^ל(ספירה|פנה״ס)/i,
  wide: /^ל(פני ה)?ספירה/i
};
var parseEraPatterns$P = {
  any: [/^לפ/i, /^לס/i]
};
var matchQuarterPatterns$P = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^רבעון [1234]/i
};
var parseQuarterPatterns$P = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$P = {
  narrow: /^\d+/i,
  abbreviated: /^(ינו|פבר|מרץ|אפר|מאי|יוני|יולי|אוג|ספט|אוק|נוב|דצמ)׳?/i,
  wide: /^(ינואר|פברואר|מרץ|אפריל|מאי|יוני|יולי|אוגוסט|ספטמבר|אוקטובר|נובמבר|דצמבר)/i
};
var parseMonthPatterns$P = {
  narrow: [/^1$/i, /^2/i, /^3/i, /^4/i, /^5/i, /^6/i, /^7/i, /^8/i, /^9/i, /^10/i, /^11/i, /^12/i],
  any: [/^ינ/i, /^פ/i, /^מר/i, /^אפ/i, /^מא/i, /^יונ/i, /^יול/i, /^אוג/i, /^ס/i, /^אוק/i, /^נ/i, /^ד/i]
};
var matchDayPatterns$P = {
  narrow: /^[אבגדהוש]׳/i,
  short: /^[אבגדהוש]׳/i,
  abbreviated: /^(שבת|יום (א|ב|ג|ד|ה|ו)׳)/i,
  wide: /^יום (ראשון|שני|שלישי|רביעי|חמישי|שישי|שבת)/i
};
var parseDayPatterns$P = {
  abbreviated: [/א׳$/i, /ב׳$/i, /ג׳$/i, /ד׳$/i, /ה׳$/i, /ו׳$/i, /^ש/i],
  wide: [/ן$/i, /ני$/i, /לישי$/i, /עי$/i, /מישי$/i, /שישי$/i, /ת$/i],
  any: [/^א/i, /^ב/i, /^ג/i, /^ד/i, /^ה/i, /^ו/i, /^ש/i]
};
var matchDayPeriodPatterns$P = {
  any: /^(אחר ה|ב)?(חצות|צהריים|בוקר|ערב|לילה|אחה״צ|לפנה״צ)/i
};
var parseDayPeriodPatterns$P = {
  any: {
    am: /^לפ/i,
    pm: /^אחה/i,
    midnight: /^ח/i,
    noon: /^צ/i,
    morning: /בוקר/i,
    afternoon: /בצ|אחר/i,
    evening: /ערב/i,
    night: /לילה/i
  }
};
var ordinalName = ["רא", "שנ", "של", "רב", "ח", "שי", "שב", "שמ", "ת", "ע"];
match$P.match = {
  ordinalNumber: (0, _index2$1F.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$P,
    parsePattern: parseOrdinalNumberPattern$P,
    valueCallback: function (e) {
      var a = parseInt(e, 10);
      return isNaN(a) ? ordinalName.indexOf(e) + 1 : a;
    }
  }),
  era: (0, _index$3p.buildMatchFn)({
    matchPatterns: matchEraPatterns$P,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$P,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$3p.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$P,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$P,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$3p.buildMatchFn)({
    matchPatterns: matchMonthPatterns$P,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$P,
    defaultParseWidth: "any"
  }),
  day: (0, _index$3p.buildMatchFn)({
    matchPatterns: matchDayPatterns$P,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$P,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$3p.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$P,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$P,
    defaultParseWidth: "any"
  })
};
he.he = void 0;
var _index$3o = formatDistance$1D;
var _index2$1E = formatLong$Q;
var _index3$R = formatRelative$1D;
var _index4$Q = localize$P;
var _index5$Q = match$P;
he.he = {
  code: "he",
  formatDistance: _index$3o.formatDistance,
  formatLong: _index2$1E.formatLong,
  formatRelative: _index3$R.formatRelative,
  localize: _index4$Q.localize,
  match: _index5$Q.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var hi = {};
var formatDistance$1B = {};
var localize$O = {};
localize$O.localeToNumber = localeToNumber;
localize$O.localize = void 0;
localize$O.numberToLocale = numberToLocale;
var _index$3n = buildLocalizeFn;
var numberValues = {
  locale: {
    1: "१",
    2: "२",
    3: "३",
    4: "४",
    5: "५",
    6: "६",
    7: "७",
    8: "८",
    9: "९",
    0: "०"
  },
  number: {
    "१": "1",
    "२": "2",
    "३": "3",
    "४": "4",
    "५": "5",
    "६": "6",
    "७": "7",
    "८": "8",
    "९": "9",
    "०": "0"
  }
};
var eraValues$O = {
  narrow: ["ईसा-पूर्व", "ईस्वी"],
  abbreviated: ["ईसा-पूर्व", "ईस्वी"],
  wide: ["ईसा-पूर्व", "ईसवी सन"]
};
var quarterValues$O = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["ति1", "ति2", "ति3", "ति4"],
  wide: ["पहली तिमाही", "दूसरी तिमाही", "तीसरी तिमाही", "चौथी तिमाही"]
};
var monthValues$O = {
  narrow: ["ज", "फ़", "मा", "अ", "मई", "जू", "जु", "अग", "सि", "अक्टू", "न", "दि"],
  abbreviated: ["जन", "फ़र", "मार्च", "अप्रैल", "मई", "जून", "जुल", "अग", "सित", "अक्टू", "नव", "दिस"],
  wide: ["जनवरी", "फ़रवरी", "मार्च", "अप्रैल", "मई", "जून", "जुलाई", "अगस्त", "सितंबर", "अक्टूबर", "नवंबर", "दिसंबर"]
};
var dayValues$O = {
  narrow: ["र", "सो", "मं", "बु", "गु", "शु", "श"],
  short: ["र", "सो", "मं", "बु", "गु", "शु", "श"],
  abbreviated: ["रवि", "सोम", "मंगल", "बुध", "गुरु", "शुक्र", "शनि"],
  wide: ["रविवार", "सोमवार", "मंगलवार", "बुधवार", "गुरुवार", "शुक्रवार", "शनिवार"]
};
var dayPeriodValues$O = {
  narrow: {
    am: "पूर्वाह्न",
    pm: "अपराह्न",
    midnight: "मध्यरात्रि",
    noon: "दोपहर",
    morning: "सुबह",
    afternoon: "दोपहर",
    evening: "शाम",
    night: "रात"
  },
  abbreviated: {
    am: "पूर्वाह्न",
    pm: "अपराह्न",
    midnight: "मध्यरात्रि",
    noon: "दोपहर",
    morning: "सुबह",
    afternoon: "दोपहर",
    evening: "शाम",
    night: "रात"
  },
  wide: {
    am: "पूर्वाह्न",
    pm: "अपराह्न",
    midnight: "मध्यरात्रि",
    noon: "दोपहर",
    morning: "सुबह",
    afternoon: "दोपहर",
    evening: "शाम",
    night: "रात"
  }
};
var formattingDayPeriodValues$E = {
  narrow: {
    am: "पूर्वाह्न",
    pm: "अपराह्न",
    midnight: "मध्यरात्रि",
    noon: "दोपहर",
    morning: "सुबह",
    afternoon: "दोपहर",
    evening: "शाम",
    night: "रात"
  },
  abbreviated: {
    am: "पूर्वाह्न",
    pm: "अपराह्न",
    midnight: "मध्यरात्रि",
    noon: "दोपहर",
    morning: "सुबह",
    afternoon: "दोपहर",
    evening: "शाम",
    night: "रात"
  },
  wide: {
    am: "पूर्वाह्न",
    pm: "अपराह्न",
    midnight: "मध्यरात्रि",
    noon: "दोपहर",
    morning: "सुबह",
    afternoon: "दोपहर",
    evening: "शाम",
    night: "रात"
  }
};
var ordinalNumber$O = function (e, a) {
  var t = Number(e);
  return numberToLocale(t);
};
function localeToNumber(e) {
  var a = e.toString().replace(/[१२३४५६७८९०]/g, function (e) {
    return numberValues.number[e];
  });
  return Number(a);
}
function numberToLocale(e) {
  return e.toString().replace(/\d/g, function (e) {
    return numberValues.locale[e];
  });
}
localize$O.localize = {
  ordinalNumber: ordinalNumber$O,
  era: (0, _index$3n.buildLocalizeFn)({
    values: eraValues$O,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$3n.buildLocalizeFn)({
    values: quarterValues$O,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$3n.buildLocalizeFn)({
    values: monthValues$O,
    defaultWidth: "wide"
  }),
  day: (0, _index$3n.buildLocalizeFn)({
    values: dayValues$O,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$3n.buildLocalizeFn)({
    values: dayPeriodValues$O,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$E,
    defaultFormattingWidth: "wide"
  })
};
formatDistance$1B.formatDistance = void 0;
var _index$3m = localize$O;
var formatDistanceLocale$N = {
  lessThanXSeconds: {
    one: "१ सेकंड से कम",
    other: "{{count}} सेकंड से कम"
  },
  xSeconds: {
    one: "१ सेकंड",
    other: "{{count}} सेकंड"
  },
  halfAMinute: "आधा मिनट",
  lessThanXMinutes: {
    one: "१ मिनट से कम",
    other: "{{count}} मिनट से कम"
  },
  xMinutes: {
    one: "१ मिनट",
    other: "{{count}} मिनट"
  },
  aboutXHours: {
    one: "लगभग १ घंटा",
    other: "लगभग {{count}} घंटे"
  },
  xHours: {
    one: "१ घंटा",
    other: "{{count}} घंटे"
  },
  xDays: {
    one: "१ दिन",
    other: "{{count}} दिन"
  },
  aboutXWeeks: {
    one: "लगभग १ सप्ताह",
    other: "लगभग {{count}} सप्ताह"
  },
  xWeeks: {
    one: "१ सप्ताह",
    other: "{{count}} सप्ताह"
  },
  aboutXMonths: {
    one: "लगभग १ महीना",
    other: "लगभग {{count}} महीने"
  },
  xMonths: {
    one: "१ महीना",
    other: "{{count}} महीने"
  },
  aboutXYears: {
    one: "लगभग १ वर्ष",
    other: "लगभग {{count}} वर्ष"
  },
  xYears: {
    one: "१ वर्ष",
    other: "{{count}} वर्ष"
  },
  overXYears: {
    one: "१ वर्ष से अधिक",
    other: "{{count}} वर्ष से अधिक"
  },
  almostXYears: {
    one: "लगभग १ वर्ष",
    other: "लगभग {{count}} वर्ष"
  }
};
var formatDistance$1A = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$N[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", (0, _index$3m.numberToLocale)(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + "मे ";
    } else {
      return r + " पहले";
    }
  }
  return r;
};
formatDistance$1B.formatDistance = formatDistance$1A;
var formatLong$P = {};
formatLong$P.formatLong = void 0;
var _index$3l = buildFormatLongFn;
var dateFormats$P = {
  full: "EEEE, do MMMM, y",
  long: "do MMMM, y",
  medium: "d MMM, y",
  short: "dd/MM/yyyy"
};
var timeFormats$P = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$P = {
  full: "{{date}} 'को' {{time}}",
  long: "{{date}} 'को' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$P.formatLong = {
  date: (0, _index$3l.buildFormatLongFn)({
    formats: dateFormats$P,
    defaultWidth: "full"
  }),
  time: (0, _index$3l.buildFormatLongFn)({
    formats: timeFormats$P,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3l.buildFormatLongFn)({
    formats: dateTimeFormats$P,
    defaultWidth: "full"
  })
};
var formatRelative$1B = {};
formatRelative$1B.formatRelative = void 0;
var formatRelativeLocale$O = {
  lastWeek: "'पिछले' eeee p",
  yesterday: "'कल' p",
  today: "'आज' p",
  tomorrow: "'कल' p",
  nextWeek: "eeee 'को' p",
  other: "P"
};
var formatRelative$1A = function (e, a, t, r) {
  return formatRelativeLocale$O[e];
};
formatRelative$1B.formatRelative = formatRelative$1A;
var match$O = {};
match$O.match = void 0;
var _index$3k = buildMatchFn;
var _index2$1D = buildMatchPatternFn;
var _index3$Q = localize$O;
var matchOrdinalNumberPattern$O = /^[०१२३४५६७८९]+/i;
var parseOrdinalNumberPattern$O = /^[०१२३४५६७८९]+/i;
var matchEraPatterns$O = {
  narrow: /^(ईसा-पूर्व|ईस्वी)/i,
  abbreviated: /^(ईसा\.?\s?पूर्व\.?|ईसा\.?)/i,
  wide: /^(ईसा-पूर्व|ईसवी पूर्व|ईसवी सन|ईसवी)/i
};
var parseEraPatterns$O = {
  any: [/^b/i, /^(a|c)/i]
};
var matchQuarterPatterns$O = {
  narrow: /^[1234]/i,
  abbreviated: /^ति[1234]/i,
  wide: /^[1234](पहली|दूसरी|तीसरी|चौथी)? तिमाही/i
};
var parseQuarterPatterns$O = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$O = {
  narrow: /^[जफ़माअप्मईजूनजुअगसिअक्तनदि]/i,
  abbreviated: /^(जन|फ़र|मार्च|अप्|मई|जून|जुल|अग|सित|अक्तू|नव|दिस)/i,
  wide: /^(जनवरी|फ़रवरी|मार्च|अप्रैल|मई|जून|जुलाई|अगस्त|सितंबर|अक्तूबर|नवंबर|दिसंबर)/i
};
var parseMonthPatterns$O = {
  narrow: [/^ज/i, /^फ़/i, /^मा/i, /^अप्/i, /^मई/i, /^जू/i, /^जु/i, /^अग/i, /^सि/i, /^अक्तू/i, /^न/i, /^दि/i],
  any: [/^जन/i, /^फ़/i, /^मा/i, /^अप्/i, /^मई/i, /^जू/i, /^जु/i, /^अग/i, /^सि/i, /^अक्तू/i, /^नव/i, /^दिस/i]
};
var matchDayPatterns$O = {
  narrow: /^[रविसोममंगलबुधगुरुशुक्रशनि]/i,
  short: /^(रवि|सोम|मंगल|बुध|गुरु|शुक्र|शनि)/i,
  abbreviated: /^(रवि|सोम|मंगल|बुध|गुरु|शुक्र|शनि)/i,
  wide: /^(रविवार|सोमवार|मंगलवार|बुधवार|गुरुवार|शुक्रवार|शनिवार)/i
};
var parseDayPatterns$O = {
  narrow: [/^रवि/i, /^सोम/i, /^मंगल/i, /^बुध/i, /^गुरु/i, /^शुक्र/i, /^शनि/i],
  any: [/^रवि/i, /^सोम/i, /^मंगल/i, /^बुध/i, /^गुरु/i, /^शुक्र/i, /^शनि/i]
};
var matchDayPeriodPatterns$O = {
  narrow: /^(पू|अ|म|द.\?|सु|दो|शा|रा)/i,
  any: /^(पूर्वाह्न|अपराह्न|म|द.\?|सु|दो|शा|रा)/i
};
var parseDayPeriodPatterns$O = {
  any: {
    am: /^पूर्वाह्न/i,
    pm: /^अपराह्न/i,
    midnight: /^मध्य/i,
    noon: /^दो/i,
    morning: /सु/i,
    afternoon: /दो/i,
    evening: /शा/i,
    night: /रा/i
  }
};
match$O.match = {
  ordinalNumber: (0, _index2$1D.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$O,
    parsePattern: parseOrdinalNumberPattern$O,
    valueCallback: _index3$Q.localeToNumber
  }),
  era: (0, _index$3k.buildMatchFn)({
    matchPatterns: matchEraPatterns$O,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$O,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$3k.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$O,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$O,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$3k.buildMatchFn)({
    matchPatterns: matchMonthPatterns$O,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$O,
    defaultParseWidth: "any"
  }),
  day: (0, _index$3k.buildMatchFn)({
    matchPatterns: matchDayPatterns$O,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$O,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$3k.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$O,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$O,
    defaultParseWidth: "any"
  })
};
hi.hi = void 0;
var _index$3j = formatDistance$1B;
var _index2$1C = formatLong$P;
var _index3$P = formatRelative$1B;
var _index4$P = localize$O;
var _index5$P = match$O;
hi.hi = {
  code: "hi",
  formatDistance: _index$3j.formatDistance,
  formatLong: _index2$1C.formatLong,
  formatRelative: _index3$P.formatRelative,
  localize: _index4$P.localize,
  match: _index5$P.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 4
  }
};
var hr = {};
var formatDistance$1z = {};
formatDistance$1z.formatDistance = void 0;
var formatDistanceLocale$M = {
  lessThanXSeconds: {
    one: {
      standalone: "manje od 1 sekunde",
      withPrepositionAgo: "manje od 1 sekunde",
      withPrepositionIn: "manje od 1 sekundu"
    },
    dual: "manje od {{count}} sekunde",
    other: "manje od {{count}} sekundi"
  },
  xSeconds: {
    one: {
      standalone: "1 sekunda",
      withPrepositionAgo: "1 sekunde",
      withPrepositionIn: "1 sekundu"
    },
    dual: "{{count}} sekunde",
    other: "{{count}} sekundi"
  },
  halfAMinute: "pola minute",
  lessThanXMinutes: {
    one: {
      standalone: "manje od 1 minute",
      withPrepositionAgo: "manje od 1 minute",
      withPrepositionIn: "manje od 1 minutu"
    },
    dual: "manje od {{count}} minute",
    other: "manje od {{count}} minuta"
  },
  xMinutes: {
    one: {
      standalone: "1 minuta",
      withPrepositionAgo: "1 minute",
      withPrepositionIn: "1 minutu"
    },
    dual: "{{count}} minute",
    other: "{{count}} minuta"
  },
  aboutXHours: {
    one: {
      standalone: "oko 1 sat",
      withPrepositionAgo: "oko 1 sat",
      withPrepositionIn: "oko 1 sat"
    },
    dual: "oko {{count}} sata",
    other: "oko {{count}} sati"
  },
  xHours: {
    one: {
      standalone: "1 sat",
      withPrepositionAgo: "1 sat",
      withPrepositionIn: "1 sat"
    },
    dual: "{{count}} sata",
    other: "{{count}} sati"
  },
  xDays: {
    one: {
      standalone: "1 dan",
      withPrepositionAgo: "1 dan",
      withPrepositionIn: "1 dan"
    },
    dual: "{{count}} dana",
    other: "{{count}} dana"
  },
  aboutXWeeks: {
    one: {
      standalone: "oko 1 tjedan",
      withPrepositionAgo: "oko 1 tjedan",
      withPrepositionIn: "oko 1 tjedan"
    },
    dual: "oko {{count}} tjedna",
    other: "oko {{count}} tjedana"
  },
  xWeeks: {
    one: {
      standalone: "1 tjedan",
      withPrepositionAgo: "1 tjedan",
      withPrepositionIn: "1 tjedan"
    },
    dual: "{{count}} tjedna",
    other: "{{count}} tjedana"
  },
  aboutXMonths: {
    one: {
      standalone: "oko 1 mjesec",
      withPrepositionAgo: "oko 1 mjesec",
      withPrepositionIn: "oko 1 mjesec"
    },
    dual: "oko {{count}} mjeseca",
    other: "oko {{count}} mjeseci"
  },
  xMonths: {
    one: {
      standalone: "1 mjesec",
      withPrepositionAgo: "1 mjesec",
      withPrepositionIn: "1 mjesec"
    },
    dual: "{{count}} mjeseca",
    other: "{{count}} mjeseci"
  },
  aboutXYears: {
    one: {
      standalone: "oko 1 godinu",
      withPrepositionAgo: "oko 1 godinu",
      withPrepositionIn: "oko 1 godinu"
    },
    dual: "oko {{count}} godine",
    other: "oko {{count}} godina"
  },
  xYears: {
    one: {
      standalone: "1 godina",
      withPrepositionAgo: "1 godine",
      withPrepositionIn: "1 godinu"
    },
    dual: "{{count}} godine",
    other: "{{count}} godina"
  },
  overXYears: {
    one: {
      standalone: "preko 1 godinu",
      withPrepositionAgo: "preko 1 godinu",
      withPrepositionIn: "preko 1 godinu"
    },
    dual: "preko {{count}} godine",
    other: "preko {{count}} godina"
  },
  almostXYears: {
    one: {
      standalone: "gotovo 1 godinu",
      withPrepositionAgo: "gotovo 1 godinu",
      withPrepositionIn: "gotovo 1 godinu"
    },
    dual: "gotovo {{count}} godine",
    other: "gotovo {{count}} godina"
  }
};
var formatDistance$1y = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$M[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    if (t === null || t === void 0 ? void 0 : t.addSuffix) {
      if (t.comparison && t.comparison > 0) {
        r = n.one.withPrepositionIn;
      } else {
        r = n.one.withPrepositionAgo;
      }
    } else {
      r = n.one.standalone;
    }
  } else if (a % 10 > 1 && a % 10 < 5 && String(a).substr(-2, 1) !== "1") {
    r = n.dual.replace("{{count}}", String(a));
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "za " + r;
    } else {
      return "prije " + r;
    }
  }
  return r;
};
formatDistance$1z.formatDistance = formatDistance$1y;
var formatLong$O = {};
formatLong$O.formatLong = void 0;
var _index$3i = buildFormatLongFn;
var dateFormats$O = {
  full: "EEEE, d. MMMM y.",
  long: "d. MMMM y.",
  medium: "d. MMM y.",
  short: "dd. MM. y."
};
var timeFormats$O = {
  full: "HH:mm:ss (zzzz)",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$O = {
  full: "{{date}} 'u' {{time}}",
  long: "{{date}} 'u' {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$O.formatLong = {
  date: (0, _index$3i.buildFormatLongFn)({
    formats: dateFormats$O,
    defaultWidth: "full"
  }),
  time: (0, _index$3i.buildFormatLongFn)({
    formats: timeFormats$O,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3i.buildFormatLongFn)({
    formats: dateTimeFormats$O,
    defaultWidth: "full"
  })
};
var formatRelative$1z = {};
formatRelative$1z.formatRelative = void 0;
var formatRelativeLocale$N = {
  lastWeek: function (e) {
    switch (e.getDay()) {
      case 0:
        return "'prošlu nedjelju u' p";
      case 3:
        return "'prošlu srijedu u' p";
      case 6:
        return "'prošlu subotu u' p";
      default:
        return "'prošli' EEEE 'u' p";
    }
  },
  yesterday: "'jučer u' p",
  today: "'danas u' p",
  tomorrow: "'sutra u' p",
  nextWeek: function (e) {
    switch (e.getDay()) {
      case 0:
        return "'iduću nedjelju u' p";
      case 3:
        return "'iduću srijedu u' p";
      case 6:
        return "'iduću subotu u' p";
      default:
        return "'prošli' EEEE 'u' p";
    }
  },
  other: "P"
};
var formatRelative$1y = function (e, a, t, r) {
  var n = formatRelativeLocale$N[e];
  if (typeof n === "function") {
    return n(a);
  }
  return n;
};
formatRelative$1z.formatRelative = formatRelative$1y;
var localize$N = {};
localize$N.localize = void 0;
var _index$3h = buildLocalizeFn;
var eraValues$N = {
  narrow: ["pr.n.e.", "AD"],
  abbreviated: ["pr. Kr.", "po. Kr."],
  wide: ["Prije Krista", "Poslije Krista"]
};
var quarterValues$N = {
  narrow: ["1.", "2.", "3.", "4."],
  abbreviated: ["1. kv.", "2. kv.", "3. kv.", "4. kv."],
  wide: ["1. kvartal", "2. kvartal", "3. kvartal", "4. kvartal"]
};
var monthValues$N = {
  narrow: ["1.", "2.", "3.", "4.", "5.", "6.", "7.", "8.", "9.", "10.", "11.", "12."],
  abbreviated: ["sij", "velj", "ožu", "tra", "svi", "lip", "srp", "kol", "ruj", "lis", "stu", "pro"],
  wide: ["siječanj", "veljača", "ožujak", "travanj", "svibanj", "lipanj", "srpanj", "kolovoz", "rujan", "listopad", "studeni", "prosinac"]
};
var formattingMonthValues$a = {
  narrow: ["1.", "2.", "3.", "4.", "5.", "6.", "7.", "8.", "9.", "10.", "11.", "12."],
  abbreviated: ["sij", "velj", "ožu", "tra", "svi", "lip", "srp", "kol", "ruj", "lis", "stu", "pro"],
  wide: ["siječnja", "veljače", "ožujka", "travnja", "svibnja", "lipnja", "srpnja", "kolovoza", "rujna", "listopada", "studenog", "prosinca"]
};
var dayValues$N = {
  narrow: ["N", "P", "U", "S", "Č", "P", "S"],
  short: ["ned", "pon", "uto", "sri", "čet", "pet", "sub"],
  abbreviated: ["ned", "pon", "uto", "sri", "čet", "pet", "sub"],
  wide: ["nedjelja", "ponedjeljak", "utorak", "srijeda", "četvrtak", "petak", "subota"]
};
var formattingDayPeriodValues$D = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutro",
    afternoon: "popodne",
    evening: "navečer",
    night: "noću"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutro",
    afternoon: "popodne",
    evening: "navečer",
    night: "noću"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutro",
    afternoon: "poslije podne",
    evening: "navečer",
    night: "noću"
  }
};
var dayPeriodValues$N = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutro",
    afternoon: "popodne",
    evening: "navečer",
    night: "noću"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutro",
    afternoon: "popodne",
    evening: "navečer",
    night: "noću"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutro",
    afternoon: "poslije podne",
    evening: "navečer",
    night: "noću"
  }
};
var ordinalNumber$N = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$N.localize = {
  ordinalNumber: ordinalNumber$N,
  era: (0, _index$3h.buildLocalizeFn)({
    values: eraValues$N,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$3h.buildLocalizeFn)({
    values: quarterValues$N,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$3h.buildLocalizeFn)({
    values: monthValues$N,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$a,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$3h.buildLocalizeFn)({
    values: dayValues$N,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$3h.buildLocalizeFn)({
    values: dayPeriodValues$N,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$D,
    defaultFormattingWidth: "wide"
  })
};
var match$N = {};
match$N.match = void 0;
var _index$3g = buildMatchFn;
var _index2$1B = buildMatchPatternFn;
var matchOrdinalNumberPattern$N = /^(\d+)\./i;
var parseOrdinalNumberPattern$N = /\d+/i;
var matchEraPatterns$N = {
  narrow: /^(pr\.n\.e\.|AD)/i,
  abbreviated: /^(pr\.\s?Kr\.|po\.\s?Kr\.)/i,
  wide: /^(Prije Krista|prije nove ere|Poslije Krista|nova era)/i
};
var parseEraPatterns$N = {
  any: [/^pr/i, /^(po|nova)/i]
};
var matchQuarterPatterns$N = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234]\.\s?kv\.?/i,
  wide: /^[1234]\. kvartal/i
};
var parseQuarterPatterns$N = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$N = {
  narrow: /^(10|11|12|[123456789])\./i,
  abbreviated: /^(sij|velj|(ožu|ozu)|tra|svi|lip|srp|kol|ruj|lis|stu|pro)/i,
  wide: /^((siječanj|siječnja|sijecanj|sijecnja)|(veljača|veljače|veljaca|veljace)|(ožujak|ožujka|ozujak|ozujka)|(travanj|travnja)|(svibanj|svibnja)|(lipanj|lipnja)|(srpanj|srpnja)|(kolovoz|kolovoza)|(rujan|rujna)|(listopad|listopada)|(studeni|studenog)|(prosinac|prosinca))/i
};
var parseMonthPatterns$N = {
  narrow: [/1/i, /2/i, /3/i, /4/i, /5/i, /6/i, /7/i, /8/i, /9/i, /10/i, /11/i, /12/i],
  abbreviated: [/^sij/i, /^velj/i, /^(ožu|ozu)/i, /^tra/i, /^svi/i, /^lip/i, /^srp/i, /^kol/i, /^ruj/i, /^lis/i, /^stu/i, /^pro/i],
  wide: [/^sij/i, /^velj/i, /^(ožu|ozu)/i, /^tra/i, /^svi/i, /^lip/i, /^srp/i, /^kol/i, /^ruj/i, /^lis/i, /^stu/i, /^pro/i]
};
var matchDayPatterns$N = {
  narrow: /^[npusčc]/i,
  short: /^(ned|pon|uto|sri|(čet|cet)|pet|sub)/i,
  abbreviated: /^(ned|pon|uto|sri|(čet|cet)|pet|sub)/i,
  wide: /^(nedjelja|ponedjeljak|utorak|srijeda|(četvrtak|cetvrtak)|petak|subota)/i
};
var parseDayPatterns$N = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
};
var matchDayPeriodPatterns$N = {
  any: /^(am|pm|ponoc|ponoć|(po)?podne|navecer|navečer|noću|poslije podne|ujutro)/i
};
var parseDayPeriodPatterns$N = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^pono/i,
    noon: /^pod/i,
    morning: /jutro/i,
    afternoon: /(poslije\s|po)+podne/i,
    evening: /(navece|naveče)/i,
    night: /(nocu|noću)/i
  }
};
match$N.match = {
  ordinalNumber: (0, _index2$1B.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$N,
    parsePattern: parseOrdinalNumberPattern$N,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$3g.buildMatchFn)({
    matchPatterns: matchEraPatterns$N,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$N,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$3g.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$N,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$N,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$3g.buildMatchFn)({
    matchPatterns: matchMonthPatterns$N,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$N,
    defaultParseWidth: "wide"
  }),
  day: (0, _index$3g.buildMatchFn)({
    matchPatterns: matchDayPatterns$N,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$N,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$3g.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$N,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$N,
    defaultParseWidth: "any"
  })
};
hr.hr = void 0;
var _index$3f = formatDistance$1z;
var _index2$1A = formatLong$O;
var _index3$O = formatRelative$1z;
var _index4$O = localize$N;
var _index5$O = match$N;
hr.hr = {
  code: "hr",
  formatDistance: _index$3f.formatDistance,
  formatLong: _index2$1A.formatLong,
  formatRelative: _index3$O.formatRelative,
  localize: _index4$O.localize,
  match: _index5$O.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var ht = {};
var formatDistance$1x = {};
formatDistance$1x.formatDistance = void 0;
var formatDistanceLocale$L = {
  lessThanXSeconds: {
    one: "mwens pase yon segond",
    other: "mwens pase {{count}} segond"
  },
  xSeconds: {
    one: "1 segond",
    other: "{{count}} segond"
  },
  halfAMinute: "30 segond",
  lessThanXMinutes: {
    one: "mwens pase yon minit",
    other: "mwens pase {{count}} minit"
  },
  xMinutes: {
    one: "1 minit",
    other: "{{count}} minit"
  },
  aboutXHours: {
    one: "anviwon inè",
    other: "anviwon {{count}} è"
  },
  xHours: {
    one: "1 lè",
    other: "{{count}} lè"
  },
  xDays: {
    one: "1 jou",
    other: "{{count}} jou"
  },
  aboutXWeeks: {
    one: "anviwon 1 semèn",
    other: "anviwon {{count}} semèn"
  },
  xWeeks: {
    one: "1 semèn",
    other: "{{count}} semèn"
  },
  aboutXMonths: {
    one: "anviwon 1 mwa",
    other: "anviwon {{count}} mwa"
  },
  xMonths: {
    one: "1 mwa",
    other: "{{count}} mwa"
  },
  aboutXYears: {
    one: "anviwon 1 an",
    other: "anviwon {{count}} an"
  },
  xYears: {
    one: "1 an",
    other: "{{count}} an"
  },
  overXYears: {
    one: "plis pase 1 an",
    other: "plis pase {{count}} an"
  },
  almostXYears: {
    one: "prèske 1 an",
    other: "prèske {{count}} an"
  }
};
var formatDistance$1w = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$L[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "nan " + r;
    } else {
      return "sa fè " + r;
    }
  }
  return r;
};
formatDistance$1x.formatDistance = formatDistance$1w;
var formatLong$N = {};
formatLong$N.formatLong = void 0;
var _index$3e = buildFormatLongFn;
var dateFormats$N = {
  full: "EEEE d MMMM y",
  long: "d MMMM y",
  medium: "d MMM y",
  short: "dd/MM/y"
};
var timeFormats$N = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$N = {
  full: "{{date}} 'nan lè' {{time}}",
  long: "{{date}} 'nan lè' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$N.formatLong = {
  date: (0, _index$3e.buildFormatLongFn)({
    formats: dateFormats$N,
    defaultWidth: "full"
  }),
  time: (0, _index$3e.buildFormatLongFn)({
    formats: timeFormats$N,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3e.buildFormatLongFn)({
    formats: dateTimeFormats$N,
    defaultWidth: "full"
  })
};
var formatRelative$1x = {};
formatRelative$1x.formatRelative = void 0;
var formatRelativeLocale$M = {
  lastWeek: "eeee 'pase nan lè' p",
  yesterday: "'yè nan lè' p",
  today: "'jodi a' p",
  tomorrow: "'demen nan lè' p'",
  nextWeek: "eeee 'pwochen nan lè' p",
  other: "P"
};
var formatRelative$1w = function (e, a, t, r) {
  return formatRelativeLocale$M[e];
};
formatRelative$1x.formatRelative = formatRelative$1w;
var localize$M = {};
localize$M.localize = void 0;
var _index$3d = buildLocalizeFn;
var eraValues$M = {
  narrow: ["av. J.-K", "ap. J.-K"],
  abbreviated: ["av. J.-K", "ap. J.-K"],
  wide: ["anvan Jezi Kris", "apre Jezi Kris"]
};
var quarterValues$M = {
  narrow: ["T1", "T2", "T3", "T4"],
  abbreviated: ["1ye trim.", "2yèm trim.", "3yèm trim.", "4yèm trim."],
  wide: ["1ye trimès", "2yèm trimès", "3yèm trimès", "4yèm trimès"]
};
var monthValues$M = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "O", "S", "O", "N", "D"],
  abbreviated: ["janv.", "fevr.", "mas", "avr.", "me", "jen", "jiyè", "out", "sept.", "okt.", "nov.", "des."],
  wide: ["janvye", "fevrye", "mas", "avril", "me", "jen", "jiyè", "out", "septanm", "oktòb", "novanm", "desanm"]
};
var dayValues$M = {
  narrow: ["D", "L", "M", "M", "J", "V", "S"],
  short: ["di", "le", "ma", "mè", "je", "va", "sa"],
  abbreviated: ["dim.", "len.", "mad.", "mèk.", "jed.", "van.", "sam."],
  wide: ["dimanch", "lendi", "madi", "mèkredi", "jedi", "vandredi", "samdi"]
};
var dayPeriodValues$M = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "minwit",
    noon: "midi",
    morning: "mat.",
    afternoon: "ap.m.",
    evening: "swa",
    night: "mat."
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "minwit",
    noon: "midi",
    morning: "maten",
    afternoon: "aprèmidi",
    evening: "swa",
    night: "maten"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "minwit",
    noon: "midi",
    morning: "nan maten",
    afternoon: "nan aprèmidi",
    evening: "nan aswè",
    night: "nan maten"
  }
};
var ordinalNumber$M = function (e, a) {
  var t = Number(e);
  if (t === 0) return String(t);
  var r = t === 1 ? "ye" : "yèm";
  return t + r;
};
localize$M.localize = {
  ordinalNumber: ordinalNumber$M,
  era: (0, _index$3d.buildLocalizeFn)({
    values: eraValues$M,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$3d.buildLocalizeFn)({
    values: quarterValues$M,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$3d.buildLocalizeFn)({
    values: monthValues$M,
    defaultWidth: "wide"
  }),
  day: (0, _index$3d.buildLocalizeFn)({
    values: dayValues$M,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$3d.buildLocalizeFn)({
    values: dayPeriodValues$M,
    defaultWidth: "wide"
  })
};
var match$M = {};
match$M.match = void 0;
var _index$3c = buildMatchFn;
var _index2$1z = buildMatchPatternFn;
var matchOrdinalNumberPattern$M = /^(\d+)(ye|yèm)?/i;
var parseOrdinalNumberPattern$M = /\d+/i;
var matchEraPatterns$M = {
  narrow: /^(av\.J\.K|ap\.J\.K|ap\.J\.-K)/i,
  abbreviated: /^(av\.J\.-K|av\.J-K|apr\.J\.-K|apr\.J-K|ap\.J-K)/i,
  wide: /^(avan Jezi Kris|apre Jezi Kris)/i
};
var parseEraPatterns$M = {
  any: [/^av/i, /^ap/i]
};
var matchQuarterPatterns$M = {
  narrow: /^[1234]/i,
  abbreviated: /^t[1234]/i,
  wide: /^[1234](ye|yèm)? trimès/i
};
var parseQuarterPatterns$M = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$M = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(janv|fevr|mas|avr|me|jen|jiyè|out|sept|okt|nov|des)\.?/i,
  wide: /^(janvye|fevrye|mas|avril|me|jen|jiyè|out|septanm|oktòb|novanm|desanm)/i
};
var parseMonthPatterns$M = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^o/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^ma/i, /^av/i, /^me/i, /^je/i, /^ji/i, /^ou/i, /^s/i, /^ok/i, /^n/i, /^d/i]
};
var matchDayPatterns$M = {
  narrow: /^[lmjvsd]/i,
  short: /^(di|le|ma|me|je|va|sa)/i,
  abbreviated: /^(dim|len|mad|mèk|jed|van|sam)\.?/i,
  wide: /^(dimanch|lendi|madi|mèkredi|jedi|vandredi|samdi)/i
};
var parseDayPatterns$M = {
  narrow: [/^d/i, /^l/i, /^m/i, /^m/i, /^j/i, /^v/i, /^s/i],
  any: [/^di/i, /^le/i, /^ma/i, /^mè/i, /^je/i, /^va/i, /^sa/i]
};
var matchDayPeriodPatterns$M = {
  narrow: /^(a|p|minwit|midi|mat\.?|ap\.?m\.?|swa)/i,
  any: /^([ap]\.?\s?m\.?|nan maten|nan aprèmidi|nan aswè)/i
};
var parseDayPeriodPatterns$M = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^min/i,
    noon: /^mid/i,
    morning: /mat/i,
    afternoon: /ap/i,
    evening: /sw/i,
    night: /nwit/i
  }
};
match$M.match = {
  ordinalNumber: (0, _index2$1z.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$M,
    parsePattern: parseOrdinalNumberPattern$M,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$3c.buildMatchFn)({
    matchPatterns: matchEraPatterns$M,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$M,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$3c.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$M,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$M,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$3c.buildMatchFn)({
    matchPatterns: matchMonthPatterns$M,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$M,
    defaultParseWidth: "any"
  }),
  day: (0, _index$3c.buildMatchFn)({
    matchPatterns: matchDayPatterns$M,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$M,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$3c.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$M,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$M,
    defaultParseWidth: "any"
  })
};
ht.ht = void 0;
var _index$3b = formatDistance$1x;
var _index2$1y = formatLong$N;
var _index3$N = formatRelative$1x;
var _index4$N = localize$M;
var _index5$N = match$M;
ht.ht = {
  code: "ht",
  formatDistance: _index$3b.formatDistance,
  formatLong: _index2$1y.formatLong,
  formatRelative: _index3$N.formatRelative,
  localize: _index4$N.localize,
  match: _index5$N.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var hu = {};
var formatDistance$1v = {};
formatDistance$1v.formatDistance = void 0;
var translations$1 = {
  about: "körülbelül",
  over: "több mint",
  almost: "majdnem",
  lessthan: "kevesebb mint"
};
var withoutSuffixes = {
  xseconds: " másodperc",
  halfaminute: "fél perc",
  xminutes: " perc",
  xhours: " óra",
  xdays: " nap",
  xweeks: " hét",
  xmonths: " hónap",
  xyears: " év"
};
var withSuffixes = {
  xseconds: {
    "-1": " másodperccel ezelőtt",
    1: " másodperc múlva",
    0: " másodperce"
  },
  halfaminute: {
    "-1": "fél perccel ezelőtt",
    1: "fél perc múlva",
    0: "fél perce"
  },
  xminutes: {
    "-1": " perccel ezelőtt",
    1: " perc múlva",
    0: " perce"
  },
  xhours: {
    "-1": " órával ezelőtt",
    1: " óra múlva",
    0: " órája"
  },
  xdays: {
    "-1": " nappal ezelőtt",
    1: " nap múlva",
    0: " napja"
  },
  xweeks: {
    "-1": " héttel ezelőtt",
    1: " hét múlva",
    0: " hete"
  },
  xmonths: {
    "-1": " hónappal ezelőtt",
    1: " hónap múlva",
    0: " hónapja"
  },
  xyears: {
    "-1": " évvel ezelőtt",
    1: " év múlva",
    0: " éve"
  }
};
var formatDistance$1u = function (e, a, t) {
  var r = e.match(/about|over|almost|lessthan/i);
  var n = r ? e.replace(r[0], "") : e;
  var i = (t === null || t === void 0 ? void 0 : t.addSuffix) === true;
  var o = n.toLowerCase();
  var d = (t === null || t === void 0 ? void 0 : t.comparison) || 0;
  var s = i ? withSuffixes[o][d] : withoutSuffixes[o];
  var u = o === "halfaminute" ? s : a + s;
  if (r) {
    var m = r[0].toLowerCase();
    u = translations$1[m] + " " + u;
  }
  return u;
};
formatDistance$1v.formatDistance = formatDistance$1u;
var formatLong$M = {};
formatLong$M.formatLong = void 0;
var _index$3a = buildFormatLongFn;
var dateFormats$M = {
  full: "y. MMMM d., EEEE",
  long: "y. MMMM d.",
  medium: "y. MMM d.",
  short: "y. MM. dd."
};
var timeFormats$M = {
  full: "H:mm:ss zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$M = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$M.formatLong = {
  date: (0, _index$3a.buildFormatLongFn)({
    formats: dateFormats$M,
    defaultWidth: "full"
  }),
  time: (0, _index$3a.buildFormatLongFn)({
    formats: timeFormats$M,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3a.buildFormatLongFn)({
    formats: dateTimeFormats$M,
    defaultWidth: "full"
  })
};
var formatRelative$1v = {};
formatRelative$1v.formatRelative = void 0;
var accusativeWeekdays$4 = ["vasárnap", "hétfőn", "kedden", "szerdán", "csütörtökön", "pénteken", "szombaton"];
function week(e) {
  return function (a) {
    var t = accusativeWeekdays$4[a.getDay()];
    var r = e ? "" : "'múlt' ";
    return "".concat(r, "'").concat(t, "' p'-kor'");
  };
}
var formatRelativeLocale$L = {
  lastWeek: week(false),
  yesterday: "'tegnap' p'-kor'",
  today: "'ma' p'-kor'",
  tomorrow: "'holnap' p'-kor'",
  nextWeek: week(true),
  other: "P"
};
var formatRelative$1u = function (e, a) {
  var t = formatRelativeLocale$L[e];
  if (typeof t === "function") {
    return t(a);
  }
  return t;
};
formatRelative$1v.formatRelative = formatRelative$1u;
var localize$L = {};
localize$L.localize = void 0;
var _index$39 = buildLocalizeFn;
var eraValues$L = {
  narrow: ["ie.", "isz."],
  abbreviated: ["i. e.", "i. sz."],
  wide: ["Krisztus előtt", "időszámításunk szerint"]
};
var quarterValues$L = {
  narrow: ["1.", "2.", "3.", "4."],
  abbreviated: ["1. n.év", "2. n.év", "3. n.év", "4. n.év"],
  wide: ["1. negyedév", "2. negyedév", "3. negyedév", "4. negyedév"]
};
var formattingQuarterValues$3 = {
  narrow: ["I.", "II.", "III.", "IV."],
  abbreviated: ["I. n.év", "II. n.év", "III. n.év", "IV. n.év"],
  wide: ["I. negyedév", "II. negyedév", "III. negyedév", "IV. negyedév"]
};
var monthValues$L = {
  narrow: ["J", "F", "M", "Á", "M", "J", "J", "A", "Sz", "O", "N", "D"],
  abbreviated: ["jan.", "febr.", "márc.", "ápr.", "máj.", "jún.", "júl.", "aug.", "szept.", "okt.", "nov.", "dec."],
  wide: ["január", "február", "március", "április", "május", "június", "július", "augusztus", "szeptember", "október", "november", "december"]
};
var dayValues$L = {
  narrow: ["V", "H", "K", "Sz", "Cs", "P", "Sz"],
  short: ["V", "H", "K", "Sze", "Cs", "P", "Szo"],
  abbreviated: ["V", "H", "K", "Sze", "Cs", "P", "Szo"],
  wide: ["vasárnap", "hétfő", "kedd", "szerda", "csütörtök", "péntek", "szombat"]
};
var dayPeriodValues$L = {
  narrow: {
    am: "de.",
    pm: "du.",
    midnight: "éjfél",
    noon: "dél",
    morning: "reggel",
    afternoon: "du.",
    evening: "este",
    night: "éjjel"
  },
  abbreviated: {
    am: "de.",
    pm: "du.",
    midnight: "éjfél",
    noon: "dél",
    morning: "reggel",
    afternoon: "du.",
    evening: "este",
    night: "éjjel"
  },
  wide: {
    am: "de.",
    pm: "du.",
    midnight: "éjfél",
    noon: "dél",
    morning: "reggel",
    afternoon: "délután",
    evening: "este",
    night: "éjjel"
  }
};
var ordinalNumber$L = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$L.localize = {
  ordinalNumber: ordinalNumber$L,
  era: (0, _index$39.buildLocalizeFn)({
    values: eraValues$L,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$39.buildLocalizeFn)({
    values: quarterValues$L,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    },
    formattingValues: formattingQuarterValues$3,
    defaultFormattingWidth: "wide"
  }),
  month: (0, _index$39.buildLocalizeFn)({
    values: monthValues$L,
    defaultWidth: "wide"
  }),
  day: (0, _index$39.buildLocalizeFn)({
    values: dayValues$L,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$39.buildLocalizeFn)({
    values: dayPeriodValues$L,
    defaultWidth: "wide"
  })
};
var match$L = {};
match$L.match = void 0;
var _index$38 = buildMatchFn;
var _index2$1x = buildMatchPatternFn;
var matchOrdinalNumberPattern$L = /^(\d+)\.?/i;
var parseOrdinalNumberPattern$L = /\d+/i;
var matchEraPatterns$L = {
  narrow: /^(ie\.|isz\.)/i,
  abbreviated: /^(i\.\s?e\.?|b?\s?c\s?e|i\.\s?sz\.?)/i,
  wide: /^(Krisztus előtt|időszámításunk előtt|időszámításunk szerint|i\. sz\.)/i
};
var parseEraPatterns$L = {
  narrow: [/ie/i, /isz/i],
  abbreviated: [/^(i\.?\s?e\.?|b\s?ce)/i, /^(i\.?\s?sz\.?|c\s?e)/i],
  any: [/előtt/i, /(szerint|i. sz.)/i]
};
var matchQuarterPatterns$L = {
  narrow: /^[1234]\.?/i,
  abbreviated: /^[1234]?\.?\s?n\.év/i,
  wide: /^([1234]|I|II|III|IV)?\.?\s?negyedév/i
};
var parseQuarterPatterns$L = {
  any: [/1|I$/i, /2|II$/i, /3|III/i, /4|IV/i]
};
var matchMonthPatterns$L = {
  narrow: /^[jfmaásond]|sz/i,
  abbreviated: /^(jan\.?|febr\.?|márc\.?|ápr\.?|máj\.?|jún\.?|júl\.?|aug\.?|szept\.?|okt\.?|nov\.?|dec\.?)/i,
  wide: /^(január|február|március|április|május|június|július|augusztus|szeptember|október|november|december)/i
};
var parseMonthPatterns$L = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a|á/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s|sz/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^már/i, /^áp/i, /^máj/i, /^jún/i, /^júl/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$L = {
  narrow: /^([vhkpc]|sz|cs|sz)/i,
  short: /^([vhkp]|sze|cs|szo)/i,
  abbreviated: /^([vhkp]|sze|cs|szo)/i,
  wide: /^(vasárnap|hétfő|kedd|szerda|csütörtök|péntek|szombat)/i
};
var parseDayPatterns$L = {
  narrow: [/^v/i, /^h/i, /^k/i, /^sz/i, /^c/i, /^p/i, /^sz/i],
  any: [/^v/i, /^h/i, /^k/i, /^sze/i, /^c/i, /^p/i, /^szo/i]
};
var matchDayPeriodPatterns$L = {
  any: /^((de|du)\.?|éjfél|délután|dél|reggel|este|éjjel)/i
};
var parseDayPeriodPatterns$L = {
  any: {
    am: /^de\.?/i,
    pm: /^du\.?/i,
    midnight: /^éjf/i,
    noon: /^dé/i,
    morning: /reg/i,
    afternoon: /^délu\.?/i,
    evening: /es/i,
    night: /éjj/i
  }
};
match$L.match = {
  ordinalNumber: (0, _index2$1x.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$L,
    parsePattern: parseOrdinalNumberPattern$L,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$38.buildMatchFn)({
    matchPatterns: matchEraPatterns$L,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$L,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$38.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$L,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$L,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$38.buildMatchFn)({
    matchPatterns: matchMonthPatterns$L,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$L,
    defaultParseWidth: "any"
  }),
  day: (0, _index$38.buildMatchFn)({
    matchPatterns: matchDayPatterns$L,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$L,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$38.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$L,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$L,
    defaultParseWidth: "any"
  })
};
hu.hu = void 0;
var _index$37 = formatDistance$1v;
var _index2$1w = formatLong$M;
var _index3$M = formatRelative$1v;
var _index4$M = localize$L;
var _index5$M = match$L;
hu.hu = {
  code: "hu",
  formatDistance: _index$37.formatDistance,
  formatLong: _index2$1w.formatLong,
  formatRelative: _index3$M.formatRelative,
  localize: _index4$M.localize,
  match: _index5$M.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var hy = {};
var formatDistance$1t = {};
formatDistance$1t.formatDistance = void 0;
var formatDistanceLocale$K = {
  lessThanXSeconds: {
    one: "ավելի քիչ քան 1 վայրկյան",
    other: "ավելի քիչ քան {{count}} վայրկյան"
  },
  xSeconds: {
    one: "1 վայրկյան",
    other: "{{count}} վայրկյան"
  },
  halfAMinute: "կես րոպե",
  lessThanXMinutes: {
    one: "ավելի քիչ քան 1 րոպե",
    other: "ավելի քիչ քան {{count}} րոպե"
  },
  xMinutes: {
    one: "1 րոպե",
    other: "{{count}} րոպե"
  },
  aboutXHours: {
    one: "մոտ 1 ժամ",
    other: "մոտ {{count}} ժամ"
  },
  xHours: {
    one: "1 ժամ",
    other: "{{count}} ժամ"
  },
  xDays: {
    one: "1 օր",
    other: "{{count}} օր"
  },
  aboutXWeeks: {
    one: "մոտ 1 շաբաթ",
    other: "մոտ {{count}} շաբաթ"
  },
  xWeeks: {
    one: "1 շաբաթ",
    other: "{{count}} շաբաթ"
  },
  aboutXMonths: {
    one: "մոտ 1 ամիս",
    other: "մոտ {{count}} ամիս"
  },
  xMonths: {
    one: "1 ամիս",
    other: "{{count}} ամիս"
  },
  aboutXYears: {
    one: "մոտ 1 տարի",
    other: "մոտ {{count}} տարի"
  },
  xYears: {
    one: "1 տարի",
    other: "{{count}} տարի"
  },
  overXYears: {
    one: "ավելի քան 1 տարի",
    other: "ավելի քան {{count}} տարի"
  },
  almostXYears: {
    one: "համարյա 1 տարի",
    other: "համարյա {{count}} տարի"
  }
};
var formatDistance$1s = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$K[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + " հետո";
    } else {
      return r + " առաջ";
    }
  }
  return r;
};
formatDistance$1t.formatDistance = formatDistance$1s;
var formatLong$L = {};
formatLong$L.formatLong = void 0;
var _index$36 = buildFormatLongFn;
var dateFormats$L = {
  full: "d MMMM, y, EEEE",
  long: "d MMMM, y",
  medium: "d MMM, y",
  short: "dd.MM.yyyy"
};
var timeFormats$L = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$L = {
  full: "{{date}} 'ժ․'{{time}}",
  long: "{{date}} 'ժ․'{{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$L.formatLong = {
  date: (0, _index$36.buildFormatLongFn)({
    formats: dateFormats$L,
    defaultWidth: "full"
  }),
  time: (0, _index$36.buildFormatLongFn)({
    formats: timeFormats$L,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$36.buildFormatLongFn)({
    formats: dateTimeFormats$L,
    defaultWidth: "full"
  })
};
var formatRelative$1t = {};
formatRelative$1t.formatRelative = void 0;
var formatRelativeLocale$K = {
  lastWeek: "'նախորդ' eeee p'֊ին'",
  yesterday: "'երեկ' p'֊ին'",
  today: "'այսօր' p'֊ին'",
  tomorrow: "'վաղը' p'֊ին'",
  nextWeek: "'հաջորդ' eeee p'֊ին'",
  other: "P"
};
var formatRelative$1s = function (e, a, t, r) {
  return formatRelativeLocale$K[e];
};
formatRelative$1t.formatRelative = formatRelative$1s;
var localize$K = {};
localize$K.localize = void 0;
var _index$35 = buildLocalizeFn;
var eraValues$K = {
  narrow: ["Ք", "Մ"],
  abbreviated: ["ՔԱ", "ՄԹ"],
  wide: ["Քրիստոսից առաջ", "Մեր թվարկության"]
};
var quarterValues$K = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Ք1", "Ք2", "Ք3", "Ք4"],
  wide: ["1֊ին քառորդ", "2֊րդ քառորդ", "3֊րդ քառորդ", "4֊րդ քառորդ"]
};
var monthValues$K = {
  narrow: ["Հ", "Փ", "Մ", "Ա", "Մ", "Հ", "Հ", "Օ", "Ս", "Հ", "Ն", "Դ"],
  abbreviated: ["հուն", "փետ", "մար", "ապր", "մայ", "հուն", "հուլ", "օգս", "սեպ", "հոկ", "նոյ", "դեկ"],
  wide: ["հունվար", "փետրվար", "մարտ", "ապրիլ", "մայիս", "հունիս", "հուլիս", "օգոստոս", "սեպտեմբեր", "հոկտեմբեր", "նոյեմբեր", "դեկտեմբեր"]
};
var dayValues$K = {
  narrow: ["Կ", "Ե", "Ե", "Չ", "Հ", "Ո", "Շ"],
  short: ["կր", "եր", "եք", "չք", "հգ", "ուր", "շբ"],
  abbreviated: ["կիր", "երկ", "երք", "չոր", "հնգ", "ուրբ", "շաբ"],
  wide: ["կիրակի", "երկուշաբթի", "երեքշաբթի", "չորեքշաբթի", "հինգշաբթի", "ուրբաթ", "շաբաթ"]
};
var dayPeriodValues$K = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "կեսգշ",
    noon: "կեսօր",
    morning: "առավոտ",
    afternoon: "ցերեկ",
    evening: "երեկո",
    night: "գիշեր"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "կեսգիշեր",
    noon: "կեսօր",
    morning: "առավոտ",
    afternoon: "ցերեկ",
    evening: "երեկո",
    night: "գիշեր"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "կեսգիշեր",
    noon: "կեսօր",
    morning: "առավոտ",
    afternoon: "ցերեկ",
    evening: "երեկո",
    night: "գիշեր"
  }
};
var formattingDayPeriodValues$C = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "կեսգշ",
    noon: "կեսօր",
    morning: "առավոտը",
    afternoon: "ցերեկը",
    evening: "երեկոյան",
    night: "գիշերը"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "կեսգիշերին",
    noon: "կեսօրին",
    morning: "առավոտը",
    afternoon: "ցերեկը",
    evening: "երեկոյան",
    night: "գիշերը"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "կեսգիշերին",
    noon: "կեսօրին",
    morning: "առավոտը",
    afternoon: "ցերեկը",
    evening: "երեկոյան",
    night: "գիշերը"
  }
};
var ordinalNumber$K = function (e, a) {
  var t = Number(e);
  var r = t % 100;
  if (r < 10) {
    if (r % 10 === 1) {
      return t + "֊ին";
    }
  }
  return t + "֊րդ";
};
localize$K.localize = {
  ordinalNumber: ordinalNumber$K,
  era: (0, _index$35.buildLocalizeFn)({
    values: eraValues$K,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$35.buildLocalizeFn)({
    values: quarterValues$K,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$35.buildLocalizeFn)({
    values: monthValues$K,
    defaultWidth: "wide"
  }),
  day: (0, _index$35.buildLocalizeFn)({
    values: dayValues$K,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$35.buildLocalizeFn)({
    values: dayPeriodValues$K,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$C,
    defaultFormattingWidth: "wide"
  })
};
var match$K = {};
match$K.match = void 0;
var _index$34 = buildMatchFn;
var _index2$1v = buildMatchPatternFn;
var matchOrdinalNumberPattern$K = /^(\d+)((-|֊)?(ին|րդ))?/i;
var parseOrdinalNumberPattern$K = /\d+/i;
var matchEraPatterns$K = {
  narrow: /^(Ք|Մ)/i,
  abbreviated: /^(Ք\.?\s?Ա\.?|Մ\.?\s?Թ\.?\s?Ա\.?|Մ\.?\s?Թ\.?|Ք\.?\s?Հ\.?)/i,
  wide: /^(քրիստոսից առաջ|մեր թվարկությունից առաջ|մեր թվարկության|քրիստոսից հետո)/i
};
var parseEraPatterns$K = {
  any: [/^ք/i, /^մ/i]
};
var matchQuarterPatterns$K = {
  narrow: /^[1234]/i,
  abbreviated: /^ք[1234]/i,
  wide: /^[1234]((-|֊)?(ին|րդ)) քառորդ/i
};
var parseQuarterPatterns$K = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$K = {
  narrow: /^[հփմաօսնդ]/i,
  abbreviated: /^(հուն|փետ|մար|ապր|մայ|հուն|հուլ|օգս|սեպ|հոկ|նոյ|դեկ)/i,
  wide: /^(հունվար|փետրվար|մարտ|ապրիլ|մայիս|հունիս|հուլիս|օգոստոս|սեպտեմբեր|հոկտեմբեր|նոյեմբեր|դեկտեմբեր)/i
};
var parseMonthPatterns$K = {
  narrow: [/^հ/i, /^փ/i, /^մ/i, /^ա/i, /^մ/i, /^հ/i, /^հ/i, /^օ/i, /^ս/i, /^հ/i, /^ն/i, /^դ/i],
  any: [/^հու/i, /^փ/i, /^մար/i, /^ա/i, /^մայ/i, /^հուն/i, /^հուլ/i, /^օ/i, /^ս/i, /^հոկ/i, /^ն/i, /^դ/i]
};
var matchDayPatterns$K = {
  narrow: /^[եչհոշկ]/i,
  short: /^(կր|եր|եք|չք|հգ|ուր|շբ)/i,
  abbreviated: /^(կիր|երկ|երք|չոր|հնգ|ուրբ|շաբ)/i,
  wide: /^(կիրակի|երկուշաբթի|երեքշաբթի|չորեքշաբթի|հինգշաբթի|ուրբաթ|շաբաթ)/i
};
var parseDayPatterns$K = {
  narrow: [/^կ/i, /^ե/i, /^ե/i, /^չ/i, /^հ/i, /^(ո|Ո)/, /^շ/i],
  short: [/^կ/i, /^եր/i, /^եք/i, /^չ/i, /^հ/i, /^(ո|Ո)/, /^շ/i],
  abbreviated: [/^կ/i, /^երկ/i, /^երք/i, /^չ/i, /^հ/i, /^(ո|Ո)/, /^շ/i],
  wide: [/^կ/i, /^երկ/i, /^երե/i, /^չ/i, /^հ/i, /^(ո|Ո)/, /^շ/i]
};
var matchDayPeriodPatterns$K = {
  narrow: /^([ap]|կեսգշ|կեսօր|(առավոտը?|ցերեկը?|երեկո(յան)?|գիշերը?))/i,
  any: /^([ap]\.?\s?m\.?|կեսգիշեր(ին)?|կեսօր(ին)?|(առավոտը?|ցերեկը?|երեկո(յան)?|գիշերը?))/i
};
var parseDayPeriodPatterns$K = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /կեսգիշեր/i,
    noon: /կեսօր/i,
    morning: /առավոտ/i,
    afternoon: /ցերեկ/i,
    evening: /երեկո/i,
    night: /գիշեր/i
  }
};
match$K.match = {
  ordinalNumber: (0, _index2$1v.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$K,
    parsePattern: parseOrdinalNumberPattern$K,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$34.buildMatchFn)({
    matchPatterns: matchEraPatterns$K,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$K,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$34.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$K,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$K,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$34.buildMatchFn)({
    matchPatterns: matchMonthPatterns$K,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$K,
    defaultParseWidth: "any"
  }),
  day: (0, _index$34.buildMatchFn)({
    matchPatterns: matchDayPatterns$K,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$K,
    defaultParseWidth: "wide"
  }),
  dayPeriod: (0, _index$34.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$K,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$K,
    defaultParseWidth: "any"
  })
};
hy.hy = void 0;
var _index$33 = formatDistance$1t;
var _index2$1u = formatLong$L;
var _index3$L = formatRelative$1t;
var _index4$L = localize$K;
var _index5$L = match$K;
hy.hy = {
  code: "hy",
  formatDistance: _index$33.formatDistance,
  formatLong: _index2$1u.formatLong,
  formatRelative: _index3$L.formatRelative,
  localize: _index4$L.localize,
  match: _index5$L.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var id = {};
var formatDistance$1r = {};
formatDistance$1r.formatDistance = void 0;
var formatDistanceLocale$J = {
  lessThanXSeconds: {
    one: "kurang dari 1 detik",
    other: "kurang dari {{count}} detik"
  },
  xSeconds: {
    one: "1 detik",
    other: "{{count}} detik"
  },
  halfAMinute: "setengah menit",
  lessThanXMinutes: {
    one: "kurang dari 1 menit",
    other: "kurang dari {{count}} menit"
  },
  xMinutes: {
    one: "1 menit",
    other: "{{count}} menit"
  },
  aboutXHours: {
    one: "sekitar 1 jam",
    other: "sekitar {{count}} jam"
  },
  xHours: {
    one: "1 jam",
    other: "{{count}} jam"
  },
  xDays: {
    one: "1 hari",
    other: "{{count}} hari"
  },
  aboutXWeeks: {
    one: "sekitar 1 minggu",
    other: "sekitar {{count}} minggu"
  },
  xWeeks: {
    one: "1 minggu",
    other: "{{count}} minggu"
  },
  aboutXMonths: {
    one: "sekitar 1 bulan",
    other: "sekitar {{count}} bulan"
  },
  xMonths: {
    one: "1 bulan",
    other: "{{count}} bulan"
  },
  aboutXYears: {
    one: "sekitar 1 tahun",
    other: "sekitar {{count}} tahun"
  },
  xYears: {
    one: "1 tahun",
    other: "{{count}} tahun"
  },
  overXYears: {
    one: "lebih dari 1 tahun",
    other: "lebih dari {{count}} tahun"
  },
  almostXYears: {
    one: "hampir 1 tahun",
    other: "hampir {{count}} tahun"
  }
};
var formatDistance$1q = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$J[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", a.toString());
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "dalam waktu " + r;
    } else {
      return r + " yang lalu";
    }
  }
  return r;
};
formatDistance$1r.formatDistance = formatDistance$1q;
var formatLong$K = {};
formatLong$K.formatLong = void 0;
var _index$32 = buildFormatLongFn;
var dateFormats$K = {
  full: "EEEE, d MMMM yyyy",
  long: "d MMMM yyyy",
  medium: "d MMM yyyy",
  short: "d/M/yyyy"
};
var timeFormats$K = {
  full: "HH.mm.ss",
  long: "HH.mm.ss",
  medium: "HH.mm",
  short: "HH.mm"
};
var dateTimeFormats$K = {
  full: "{{date}} 'pukul' {{time}}",
  long: "{{date}} 'pukul' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$K.formatLong = {
  date: (0, _index$32.buildFormatLongFn)({
    formats: dateFormats$K,
    defaultWidth: "full"
  }),
  time: (0, _index$32.buildFormatLongFn)({
    formats: timeFormats$K,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$32.buildFormatLongFn)({
    formats: dateTimeFormats$K,
    defaultWidth: "full"
  })
};
var formatRelative$1r = {};
formatRelative$1r.formatRelative = void 0;
var formatRelativeLocale$J = {
  lastWeek: "eeee 'lalu pukul' p",
  yesterday: "'Kemarin pukul' p",
  today: "'Hari ini pukul' p",
  tomorrow: "'Besok pukul' p",
  nextWeek: "eeee 'pukul' p",
  other: "P"
};
var formatRelative$1q = function (e, a, t, r) {
  return formatRelativeLocale$J[e];
};
formatRelative$1r.formatRelative = formatRelative$1q;
var localize$J = {};
localize$J.localize = void 0;
var _index$31 = buildLocalizeFn;
var eraValues$J = {
  narrow: ["SM", "M"],
  abbreviated: ["SM", "M"],
  wide: ["Sebelum Masehi", "Masehi"]
};
var quarterValues$J = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["K1", "K2", "K3", "K4"],
  wide: ["Kuartal ke-1", "Kuartal ke-2", "Kuartal ke-3", "Kuartal ke-4"]
};
var monthValues$J = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agt", "Sep", "Okt", "Nov", "Des"],
  wide: ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]
};
var dayValues$J = {
  narrow: ["M", "S", "S", "R", "K", "J", "S"],
  short: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
  abbreviated: ["Min", "Sen", "Sel", "Rab", "Kam", "Jum", "Sab"],
  wide: ["Minggu", "Senin", "Selasa", "Rabu", "Kamis", "Jumat", "Sabtu"]
};
var dayPeriodValues$J = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "tengah malam",
    noon: "tengah hari",
    morning: "pagi",
    afternoon: "siang",
    evening: "sore",
    night: "malam"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "tengah malam",
    noon: "tengah hari",
    morning: "pagi",
    afternoon: "siang",
    evening: "sore",
    night: "malam"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "tengah malam",
    noon: "tengah hari",
    morning: "pagi",
    afternoon: "siang",
    evening: "sore",
    night: "malam"
  }
};
var formattingDayPeriodValues$B = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "tengah malam",
    noon: "tengah hari",
    morning: "pagi",
    afternoon: "siang",
    evening: "sore",
    night: "malam"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "tengah malam",
    noon: "tengah hari",
    morning: "pagi",
    afternoon: "siang",
    evening: "sore",
    night: "malam"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "tengah malam",
    noon: "tengah hari",
    morning: "pagi",
    afternoon: "siang",
    evening: "sore",
    night: "malam"
  }
};
var ordinalNumber$J = function (e, a) {
  var t = Number(e);
  return "ke-" + t;
};
localize$J.localize = {
  ordinalNumber: ordinalNumber$J,
  era: (0, _index$31.buildLocalizeFn)({
    values: eraValues$J,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$31.buildLocalizeFn)({
    values: quarterValues$J,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$31.buildLocalizeFn)({
    values: monthValues$J,
    defaultWidth: "wide"
  }),
  day: (0, _index$31.buildLocalizeFn)({
    values: dayValues$J,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$31.buildLocalizeFn)({
    values: dayPeriodValues$J,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$B,
    defaultFormattingWidth: "wide"
  })
};
var match$J = {};
match$J.match = void 0;
var _index$30 = buildMatchFn;
var _index2$1t = buildMatchPatternFn;
var matchOrdinalNumberPattern$J = /^ke-(\d+)?/i;
var parseOrdinalNumberPattern$J = /\d+/i;
var matchEraPatterns$J = {
  narrow: /^(sm|m)/i,
  abbreviated: /^(s\.?\s?m\.?|s\.?\s?e\.?\s?u\.?|m\.?|e\.?\s?u\.?)/i,
  wide: /^(sebelum masehi|sebelum era umum|masehi|era umum)/i
};
var parseEraPatterns$J = {
  any: [/^s/i, /^(m|e)/i]
};
var matchQuarterPatterns$J = {
  narrow: /^[1234]/i,
  abbreviated: /^K-?\s[1234]/i,
  wide: /^Kuartal ke-?\s?[1234]/i
};
var parseQuarterPatterns$J = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$J = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|mei|jun|jul|agt|sep|okt|nov|des)/i,
  wide: /^(januari|februari|maret|april|mei|juni|juli|agustus|september|oktober|november|desember)/i
};
var parseMonthPatterns$J = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^ma/i, /^ap/i, /^me/i, /^jun/i, /^jul/i, /^ag/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$J = {
  narrow: /^[srkjm]/i,
  short: /^(min|sen|sel|rab|kam|jum|sab)/i,
  abbreviated: /^(min|sen|sel|rab|kam|jum|sab)/i,
  wide: /^(minggu|senin|selasa|rabu|kamis|jumat|sabtu)/i
};
var parseDayPatterns$J = {
  narrow: [/^m/i, /^s/i, /^s/i, /^r/i, /^k/i, /^j/i, /^s/i],
  any: [/^m/i, /^sen/i, /^sel/i, /^r/i, /^k/i, /^j/i, /^sa/i]
};
var matchDayPeriodPatterns$J = {
  narrow: /^(a|p|tengah m|tengah h|(di(\swaktu)?) (pagi|siang|sore|malam))/i,
  any: /^([ap]\.?\s?m\.?|tengah malam|tengah hari|(di(\swaktu)?) (pagi|siang|sore|malam))/i
};
var parseDayPeriodPatterns$J = {
  any: {
    am: /^a/i,
    pm: /^pm/i,
    midnight: /^tengah m/i,
    noon: /^tengah h/i,
    morning: /pagi/i,
    afternoon: /siang/i,
    evening: /sore/i,
    night: /malam/i
  }
};
match$J.match = {
  ordinalNumber: (0, _index2$1t.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$J,
    parsePattern: parseOrdinalNumberPattern$J,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$30.buildMatchFn)({
    matchPatterns: matchEraPatterns$J,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$J,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$30.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$J,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$J,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$30.buildMatchFn)({
    matchPatterns: matchMonthPatterns$J,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$J,
    defaultParseWidth: "any"
  }),
  day: (0, _index$30.buildMatchFn)({
    matchPatterns: matchDayPatterns$J,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$J,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$30.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$J,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$J,
    defaultParseWidth: "any"
  })
};
id.id = void 0;
var _index$2$ = formatDistance$1r;
var _index2$1s = formatLong$K;
var _index3$K = formatRelative$1r;
var _index4$K = localize$J;
var _index5$K = match$J;
id.id = {
  code: "id",
  formatDistance: _index$2$.formatDistance,
  formatLong: _index2$1s.formatLong,
  formatRelative: _index3$K.formatRelative,
  localize: _index4$K.localize,
  match: _index5$K.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var is = {};
var formatDistance$1p = {};
formatDistance$1p.formatDistance = void 0;
var formatDistanceLocale$I = {
  lessThanXSeconds: {
    one: "minna en 1 sekúnda",
    other: "minna en {{count}} sekúndur"
  },
  xSeconds: {
    one: "1 sekúnda",
    other: "{{count}} sekúndur"
  },
  halfAMinute: "hálf mínúta",
  lessThanXMinutes: {
    one: "minna en 1 mínúta",
    other: "minna en {{count}} mínútur"
  },
  xMinutes: {
    one: "1 mínúta",
    other: "{{count}} mínútur"
  },
  aboutXHours: {
    one: "u.þ.b. 1 klukkustund",
    other: "u.þ.b. {{count}} klukkustundir"
  },
  xHours: {
    one: "1 klukkustund",
    other: "{{count}} klukkustundir"
  },
  xDays: {
    one: "1 dagur",
    other: "{{count}} dagar"
  },
  aboutXWeeks: {
    one: "um viku",
    other: "um {{count}} vikur"
  },
  xWeeks: {
    one: "1 viku",
    other: "{{count}} vikur"
  },
  aboutXMonths: {
    one: "u.þ.b. 1 mánuður",
    other: "u.þ.b. {{count}} mánuðir"
  },
  xMonths: {
    one: "1 mánuður",
    other: "{{count}} mánuðir"
  },
  aboutXYears: {
    one: "u.þ.b. 1 ár",
    other: "u.þ.b. {{count}} ár"
  },
  xYears: {
    one: "1 ár",
    other: "{{count}} ár"
  },
  overXYears: {
    one: "meira en 1 ár",
    other: "meira en {{count}} ár"
  },
  almostXYears: {
    one: "næstum 1 ár",
    other: "næstum {{count}} ár"
  }
};
var formatDistance$1o = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$I[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", a.toString());
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "í " + r;
    } else {
      return r + " síðan";
    }
  }
  return r;
};
formatDistance$1p.formatDistance = formatDistance$1o;
var formatLong$J = {};
formatLong$J.formatLong = void 0;
var _index$2_ = buildFormatLongFn;
var dateFormats$J = {
  full: "EEEE, do MMMM y",
  long: "do MMMM y",
  medium: "do MMM y",
  short: "d.MM.y"
};
var timeFormats$J = {
  full: "'kl'. HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$J = {
  full: "{{date}} 'kl.' {{time}}",
  long: "{{date}} 'kl.' {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$J.formatLong = {
  date: (0, _index$2_.buildFormatLongFn)({
    formats: dateFormats$J,
    defaultWidth: "full"
  }),
  time: (0, _index$2_.buildFormatLongFn)({
    formats: timeFormats$J,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2_.buildFormatLongFn)({
    formats: dateTimeFormats$J,
    defaultWidth: "full"
  })
};
var formatRelative$1p = {};
formatRelative$1p.formatRelative = void 0;
var formatRelativeLocale$I = {
  lastWeek: "'síðasta' dddd 'kl.' p",
  yesterday: "'í gær kl.' p",
  today: "'í dag kl.' p",
  tomorrow: "'á morgun kl.' p",
  nextWeek: "dddd 'kl.' p",
  other: "P"
};
var formatRelative$1o = function (e, a, t, r) {
  return formatRelativeLocale$I[e];
};
formatRelative$1p.formatRelative = formatRelative$1o;
var localize$I = {};
localize$I.localize = void 0;
var _index$2Z = buildLocalizeFn;
var eraValues$I = {
  narrow: ["f.Kr.", "e.Kr."],
  abbreviated: ["f.Kr.", "e.Kr."],
  wide: ["fyrir Krist", "eftir Krist"]
};
var quarterValues$I = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1F", "2F", "3F", "4F"],
  wide: ["1. fjórðungur", "2. fjórðungur", "3. fjórðungur", "4. fjórðungur"]
};
var monthValues$I = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "Á", "S", "Ó", "N", "D"],
  abbreviated: ["jan.", "feb.", "mars", "apríl", "maí", "júní", "júlí", "ágúst", "sept.", "okt.", "nóv.", "des."],
  wide: ["janúar", "febrúar", "mars", "apríl", "maí", "júní", "júlí", "ágúst", "september", "október", "nóvember", "desember"]
};
var dayValues$I = {
  narrow: ["S", "M", "Þ", "M", "F", "F", "L"],
  short: ["Su", "Má", "Þr", "Mi", "Fi", "Fö", "La"],
  abbreviated: ["sun.", "mán.", "þri.", "mið.", "fim.", "fös.", "lau."],
  wide: ["sunnudagur", "mánudagur", "þriðjudagur", "miðvikudagur", "fimmtudagur", "föstudagur", "laugardagur"]
};
var dayPeriodValues$I = {
  narrow: {
    am: "f",
    pm: "e",
    midnight: "miðnætti",
    noon: "hádegi",
    morning: "morgunn",
    afternoon: "síðdegi",
    evening: "kvöld",
    night: "nótt"
  },
  abbreviated: {
    am: "f.h.",
    pm: "e.h.",
    midnight: "miðnætti",
    noon: "hádegi",
    morning: "morgunn",
    afternoon: "síðdegi",
    evening: "kvöld",
    night: "nótt"
  },
  wide: {
    am: "fyrir hádegi",
    pm: "eftir hádegi",
    midnight: "miðnætti",
    noon: "hádegi",
    morning: "morgunn",
    afternoon: "síðdegi",
    evening: "kvöld",
    night: "nótt"
  }
};
var formattingDayPeriodValues$A = {
  narrow: {
    am: "f",
    pm: "e",
    midnight: "á miðnætti",
    noon: "á hádegi",
    morning: "að morgni",
    afternoon: "síðdegis",
    evening: "um kvöld",
    night: "um nótt"
  },
  abbreviated: {
    am: "f.h.",
    pm: "e.h.",
    midnight: "á miðnætti",
    noon: "á hádegi",
    morning: "að morgni",
    afternoon: "síðdegis",
    evening: "um kvöld",
    night: "um nótt"
  },
  wide: {
    am: "fyrir hádegi",
    pm: "eftir hádegi",
    midnight: "á miðnætti",
    noon: "á hádegi",
    morning: "að morgni",
    afternoon: "síðdegis",
    evening: "um kvöld",
    night: "um nótt"
  }
};
var ordinalNumber$I = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$I.localize = {
  ordinalNumber: ordinalNumber$I,
  era: (0, _index$2Z.buildLocalizeFn)({
    values: eraValues$I,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2Z.buildLocalizeFn)({
    values: quarterValues$I,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$2Z.buildLocalizeFn)({
    values: monthValues$I,
    defaultWidth: "wide"
  }),
  day: (0, _index$2Z.buildLocalizeFn)({
    values: dayValues$I,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$2Z.buildLocalizeFn)({
    values: dayPeriodValues$I,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$A,
    defaultFormattingWidth: "wide"
  })
};
var match$I = {};
match$I.match = void 0;
var _index$2Y = buildMatchFn;
var _index2$1r = buildMatchPatternFn;
var matchOrdinalNumberPattern$I = /^(\d+)(\.)?/i;
var parseOrdinalNumberPattern$I = /\d+(\.)?/i;
var matchEraPatterns$I = {
  narrow: /^(f\.Kr\.|e\.Kr\.)/i,
  abbreviated: /^(f\.Kr\.|e\.Kr\.)/i,
  wide: /^(fyrir Krist|eftir Krist)/i
};
var parseEraPatterns$I = {
  any: [/^(f\.Kr\.)/i, /^(e\.Kr\.)/i]
};
var matchQuarterPatterns$I = {
  narrow: /^[1234]\.?/i,
  abbreviated: /^q[1234]\.?/i,
  wide: /^[1234]\.? fjórðungur/i
};
var parseQuarterPatterns$I = {
  any: [/1\.?/i, /2\.?/i, /3\.?/i, /4\.?/i]
};
var matchMonthPatterns$I = {
  narrow: /^[jfmásónd]/i,
  abbreviated: /^(jan\.|feb\.|mars\.|apríl\.|maí|júní|júlí|águst|sep\.|oct\.|nov\.|dec\.)/i,
  wide: /^(januar|febrúar|mars|apríl|maí|júní|júlí|águst|september|október|nóvember|desember)/i
};
var parseMonthPatterns$I = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^á/i, /^s/i, /^ó/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^maí/i, /^jún/i, /^júl/i, /^áu/i, /^s/i, /^ó/i, /^n/i, /^d/i]
};
var matchDayPatterns$I = {
  narrow: /^[smtwf]/i,
  short: /^(su|má|þr|mi|fi|fö|la)/i,
  abbreviated: /^(sun|mán|þri|mið|fim|fös|lau)\.?/i,
  wide: /^(sunnudagur|mánudagur|þriðjudagur|miðvikudagur|fimmtudagur|föstudagur|laugardagur)/i
};
var parseDayPatterns$I = {
  narrow: [/^s/i, /^m/i, /^þ/i, /^m/i, /^f/i, /^f/i, /^l/i],
  any: [/^su/i, /^má/i, /^þr/i, /^mi/i, /^fi/i, /^fö/i, /^la/i]
};
var matchDayPeriodPatterns$I = {
  narrow: /^(f|e|síðdegis|(á|að|um) (morgni|kvöld|nótt|miðnætti))/i,
  any: /^(fyrir hádegi|eftir hádegi|[ef]\.?h\.?|síðdegis|morgunn|(á|að|um) (morgni|kvöld|nótt|miðnætti))/i
};
var parseDayPeriodPatterns$I = {
  any: {
    am: /^f/i,
    pm: /^e/i,
    midnight: /^mi/i,
    noon: /^há/i,
    morning: /morgunn/i,
    afternoon: /síðdegi/i,
    evening: /kvöld/i,
    night: /nótt/i
  }
};
match$I.match = {
  ordinalNumber: (0, _index2$1r.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$I,
    parsePattern: parseOrdinalNumberPattern$I,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$2Y.buildMatchFn)({
    matchPatterns: matchEraPatterns$I,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$I,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$2Y.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$I,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$I,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$2Y.buildMatchFn)({
    matchPatterns: matchMonthPatterns$I,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$I,
    defaultParseWidth: "any"
  }),
  day: (0, _index$2Y.buildMatchFn)({
    matchPatterns: matchDayPatterns$I,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$I,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$2Y.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$I,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$I,
    defaultParseWidth: "any"
  })
};
is.is = void 0;
var _index$2X = formatDistance$1p;
var _index2$1q = formatLong$J;
var _index3$J = formatRelative$1p;
var _index4$J = localize$I;
var _index5$J = match$I;
is.is = {
  code: "is",
  formatDistance: _index$2X.formatDistance,
  formatLong: _index2$1q.formatLong,
  formatRelative: _index3$J.formatRelative,
  localize: _index4$J.localize,
  match: _index5$J.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var it = {};
var formatDistance$1n = {};
formatDistance$1n.formatDistance = void 0;
var formatDistanceLocale$H = {
  lessThanXSeconds: {
    one: "meno di un secondo",
    other: "meno di {{count}} secondi"
  },
  xSeconds: {
    one: "un secondo",
    other: "{{count}} secondi"
  },
  halfAMinute: "alcuni secondi",
  lessThanXMinutes: {
    one: "meno di un minuto",
    other: "meno di {{count}} minuti"
  },
  xMinutes: {
    one: "un minuto",
    other: "{{count}} minuti"
  },
  aboutXHours: {
    one: "circa un'ora",
    other: "circa {{count}} ore"
  },
  xHours: {
    one: "un'ora",
    other: "{{count}} ore"
  },
  xDays: {
    one: "un giorno",
    other: "{{count}} giorni"
  },
  aboutXWeeks: {
    one: "circa una settimana",
    other: "circa {{count}} settimane"
  },
  xWeeks: {
    one: "una settimana",
    other: "{{count}} settimane"
  },
  aboutXMonths: {
    one: "circa un mese",
    other: "circa {{count}} mesi"
  },
  xMonths: {
    one: "un mese",
    other: "{{count}} mesi"
  },
  aboutXYears: {
    one: "circa un anno",
    other: "circa {{count}} anni"
  },
  xYears: {
    one: "un anno",
    other: "{{count}} anni"
  },
  overXYears: {
    one: "più di un anno",
    other: "più di {{count}} anni"
  },
  almostXYears: {
    one: "quasi un anno",
    other: "quasi {{count}} anni"
  }
};
var formatDistance$1m = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$H[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", a.toString());
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "tra " + r;
    } else {
      return r + " fa";
    }
  }
  return r;
};
formatDistance$1n.formatDistance = formatDistance$1m;
var formatLong$I = {};
formatLong$I.formatLong = void 0;
var _index$2W = buildFormatLongFn;
var dateFormats$I = {
  full: "EEEE d MMMM y",
  long: "d MMMM y",
  medium: "d MMM y",
  short: "dd/MM/y"
};
var timeFormats$I = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$I = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$I.formatLong = {
  date: (0, _index$2W.buildFormatLongFn)({
    formats: dateFormats$I,
    defaultWidth: "full"
  }),
  time: (0, _index$2W.buildFormatLongFn)({
    formats: timeFormats$I,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2W.buildFormatLongFn)({
    formats: dateTimeFormats$I,
    defaultWidth: "full"
  })
};
var formatRelative$1n = {};
formatRelative$1n.formatRelative = void 0;
var _index$2V = isSameWeek;
var weekdays$2 = ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"];
function lastWeek$5(e) {
  switch (e) {
    case 0:
      return "'domenica scorsa alle' p";
    default:
      return "'" + weekdays$2[e] + " scorso alle' p";
  }
}
function thisWeek$5(e) {
  return "'" + weekdays$2[e] + " alle' p";
}
function nextWeek$5(e) {
  switch (e) {
    case 0:
      return "'domenica prossima alle' p";
    default:
      return "'" + weekdays$2[e] + " prossimo alle' p";
  }
}
var formatRelativeLocale$H = {
  lastWeek: function (e, a, t) {
    var r = e.getDay();
    if ((0, _index$2V.isSameWeek)(e, a, t)) {
      return thisWeek$5(r);
    } else {
      return lastWeek$5(r);
    }
  },
  yesterday: "'ieri alle' p",
  today: "'oggi alle' p",
  tomorrow: "'domani alle' p",
  nextWeek: function (e, a, t) {
    var r = e.getDay();
    if ((0, _index$2V.isSameWeek)(e, a, t)) {
      return thisWeek$5(r);
    } else {
      return nextWeek$5(r);
    }
  },
  other: "P"
};
var formatRelative$1m = function (e, a, t, r) {
  var n = formatRelativeLocale$H[e];
  if (typeof n === "function") {
    return n(a, t, r);
  }
  return n;
};
formatRelative$1n.formatRelative = formatRelative$1m;
var localize$H = {};
localize$H.localize = void 0;
var _index$2U = buildLocalizeFn;
var eraValues$H = {
  narrow: ["aC", "dC"],
  abbreviated: ["a.C.", "d.C."],
  wide: ["avanti Cristo", "dopo Cristo"]
};
var quarterValues$H = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["T1", "T2", "T3", "T4"],
  wide: ["1º trimestre", "2º trimestre", "3º trimestre", "4º trimestre"]
};
var monthValues$H = {
  narrow: ["G", "F", "M", "A", "M", "G", "L", "A", "S", "O", "N", "D"],
  abbreviated: ["gen", "feb", "mar", "apr", "mag", "giu", "lug", "ago", "set", "ott", "nov", "dic"],
  wide: ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"]
};
var dayValues$H = {
  narrow: ["D", "L", "M", "M", "G", "V", "S"],
  short: ["dom", "lun", "mar", "mer", "gio", "ven", "sab"],
  abbreviated: ["dom", "lun", "mar", "mer", "gio", "ven", "sab"],
  wide: ["domenica", "lunedì", "martedì", "mercoledì", "giovedì", "venerdì", "sabato"]
};
var dayPeriodValues$H = {
  narrow: {
    am: "m.",
    pm: "p.",
    midnight: "mezzanotte",
    noon: "mezzogiorno",
    morning: "mattina",
    afternoon: "pomeriggio",
    evening: "sera",
    night: "notte"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "mezzanotte",
    noon: "mezzogiorno",
    morning: "mattina",
    afternoon: "pomeriggio",
    evening: "sera",
    night: "notte"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "mezzanotte",
    noon: "mezzogiorno",
    morning: "mattina",
    afternoon: "pomeriggio",
    evening: "sera",
    night: "notte"
  }
};
var formattingDayPeriodValues$z = {
  narrow: {
    am: "m.",
    pm: "p.",
    midnight: "mezzanotte",
    noon: "mezzogiorno",
    morning: "di mattina",
    afternoon: "del pomeriggio",
    evening: "di sera",
    night: "di notte"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "mezzanotte",
    noon: "mezzogiorno",
    morning: "di mattina",
    afternoon: "del pomeriggio",
    evening: "di sera",
    night: "di notte"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "mezzanotte",
    noon: "mezzogiorno",
    morning: "di mattina",
    afternoon: "del pomeriggio",
    evening: "di sera",
    night: "di notte"
  }
};
var ordinalNumber$H = function (e, a) {
  var t = Number(e);
  return String(t);
};
localize$H.localize = {
  ordinalNumber: ordinalNumber$H,
  era: (0, _index$2U.buildLocalizeFn)({
    values: eraValues$H,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2U.buildLocalizeFn)({
    values: quarterValues$H,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$2U.buildLocalizeFn)({
    values: monthValues$H,
    defaultWidth: "wide"
  }),
  day: (0, _index$2U.buildLocalizeFn)({
    values: dayValues$H,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$2U.buildLocalizeFn)({
    values: dayPeriodValues$H,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$z,
    defaultFormattingWidth: "wide"
  })
};
var match$H = {};
match$H.match = void 0;
var _index$2T = buildMatchFn;
var _index2$1p = buildMatchPatternFn;
var matchOrdinalNumberPattern$H = /^(\d+)(º)?/i;
var parseOrdinalNumberPattern$H = /\d+/i;
var matchEraPatterns$H = {
  narrow: /^(aC|dC)/i,
  abbreviated: /^(a\.?\s?C\.?|a\.?\s?e\.?\s?v\.?|d\.?\s?C\.?|e\.?\s?v\.?)/i,
  wide: /^(avanti Cristo|avanti Era Volgare|dopo Cristo|Era Volgare)/i
};
var parseEraPatterns$H = {
  any: [/^a/i, /^(d|e)/i]
};
var matchQuarterPatterns$H = {
  narrow: /^[1234]/i,
  abbreviated: /^t[1234]/i,
  wide: /^[1234](º)? trimestre/i
};
var parseQuarterPatterns$H = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$H = {
  narrow: /^[gfmalsond]/i,
  abbreviated: /^(gen|feb|mar|apr|mag|giu|lug|ago|set|ott|nov|dic)/i,
  wide: /^(gennaio|febbraio|marzo|aprile|maggio|giugno|luglio|agosto|settembre|ottobre|novembre|dicembre)/i
};
var parseMonthPatterns$H = {
  narrow: [/^g/i, /^f/i, /^m/i, /^a/i, /^m/i, /^g/i, /^l/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ge/i, /^f/i, /^mar/i, /^ap/i, /^mag/i, /^gi/i, /^l/i, /^ag/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$H = {
  narrow: /^[dlmgvs]/i,
  short: /^(do|lu|ma|me|gi|ve|sa)/i,
  abbreviated: /^(dom|lun|mar|mer|gio|ven|sab)/i,
  wide: /^(domenica|luned[i|ì]|marted[i|ì]|mercoled[i|ì]|gioved[i|ì]|venerd[i|ì]|sabato)/i
};
var parseDayPatterns$H = {
  narrow: [/^d/i, /^l/i, /^m/i, /^m/i, /^g/i, /^v/i, /^s/i],
  any: [/^d/i, /^l/i, /^ma/i, /^me/i, /^g/i, /^v/i, /^s/i]
};
var matchDayPeriodPatterns$H = {
  narrow: /^(a|m\.|p|mezzanotte|mezzogiorno|(di|del) (mattina|pomeriggio|sera|notte))/i,
  any: /^([ap]\.?\s?m\.?|mezzanotte|mezzogiorno|(di|del) (mattina|pomeriggio|sera|notte))/i
};
var parseDayPeriodPatterns$H = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mezza/i,
    noon: /^mezzo/i,
    morning: /mattina/i,
    afternoon: /pomeriggio/i,
    evening: /sera/i,
    night: /notte/i
  }
};
match$H.match = {
  ordinalNumber: (0, _index2$1p.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$H,
    parsePattern: parseOrdinalNumberPattern$H,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$2T.buildMatchFn)({
    matchPatterns: matchEraPatterns$H,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$H,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$2T.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$H,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$H,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$2T.buildMatchFn)({
    matchPatterns: matchMonthPatterns$H,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$H,
    defaultParseWidth: "any"
  }),
  day: (0, _index$2T.buildMatchFn)({
    matchPatterns: matchDayPatterns$H,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$H,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$2T.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$H,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$H,
    defaultParseWidth: "any"
  })
};
it.it = void 0;
var _index$2S = formatDistance$1n;
var _index2$1o = formatLong$I;
var _index3$I = formatRelative$1n;
var _index4$I = localize$H;
var _index5$I = match$H;
it.it = {
  code: "it",
  formatDistance: _index$2S.formatDistance,
  formatLong: _index2$1o.formatLong,
  formatRelative: _index3$I.formatRelative,
  localize: _index4$I.localize,
  match: _index5$I.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var itCH = {};
var formatLong$H = {};
formatLong$H.formatLong = void 0;
var _index$2R = buildFormatLongFn;
var dateFormats$H = {
  full: "EEEE d MMMM y",
  long: "d MMMM y",
  medium: "d MMM y",
  short: "dd.MM.y"
};
var timeFormats$H = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$H = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$H.formatLong = {
  date: (0, _index$2R.buildFormatLongFn)({
    formats: dateFormats$H,
    defaultWidth: "full"
  }),
  time: (0, _index$2R.buildFormatLongFn)({
    formats: timeFormats$H,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2R.buildFormatLongFn)({
    formats: dateTimeFormats$H,
    defaultWidth: "full"
  })
};
itCH.itCH = void 0;
var _index$2Q = formatDistance$1n;
var _index2$1n = formatRelative$1n;
var _index3$H = localize$H;
var _index4$H = match$H;
var _index5$H = formatLong$H;
itCH.itCH = {
  code: "it-CH",
  formatDistance: _index$2Q.formatDistance,
  formatLong: _index5$H.formatLong,
  formatRelative: _index2$1n.formatRelative,
  localize: _index3$H.localize,
  match: _index4$H.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var ja = {};
var formatDistance$1l = {};
formatDistance$1l.formatDistance = void 0;
var formatDistanceLocale$G = {
  lessThanXSeconds: {
    one: "1秒未満",
    other: "{{count}}秒未満",
    oneWithSuffix: "約1秒",
    otherWithSuffix: "約{{count}}秒"
  },
  xSeconds: {
    one: "1秒",
    other: "{{count}}秒"
  },
  halfAMinute: "30秒",
  lessThanXMinutes: {
    one: "1分未満",
    other: "{{count}}分未満",
    oneWithSuffix: "約1分",
    otherWithSuffix: "約{{count}}分"
  },
  xMinutes: {
    one: "1分",
    other: "{{count}}分"
  },
  aboutXHours: {
    one: "約1時間",
    other: "約{{count}}時間"
  },
  xHours: {
    one: "1時間",
    other: "{{count}}時間"
  },
  xDays: {
    one: "1日",
    other: "{{count}}日"
  },
  aboutXWeeks: {
    one: "約1週間",
    other: "約{{count}}週間"
  },
  xWeeks: {
    one: "1週間",
    other: "{{count}}週間"
  },
  aboutXMonths: {
    one: "約1か月",
    other: "約{{count}}か月"
  },
  xMonths: {
    one: "1か月",
    other: "{{count}}か月"
  },
  aboutXYears: {
    one: "約1年",
    other: "約{{count}}年"
  },
  xYears: {
    one: "1年",
    other: "{{count}}年"
  },
  overXYears: {
    one: "1年以上",
    other: "{{count}}年以上"
  },
  almostXYears: {
    one: "1年近く",
    other: "{{count}}年近く"
  }
};
var formatDistance$1k = function (e, a, t) {
  t = t || {};
  var r;
  var n = formatDistanceLocale$G[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    if (t.addSuffix && n.oneWithSuffix) {
      r = n.oneWithSuffix;
    } else {
      r = n.one;
    }
  } else {
    if (t.addSuffix && n.otherWithSuffix) {
      r = n.otherWithSuffix.replace("{{count}}", String(a));
    } else {
      r = n.other.replace("{{count}}", String(a));
    }
  }
  if (t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + "後";
    } else {
      return r + "前";
    }
  }
  return r;
};
formatDistance$1l.formatDistance = formatDistance$1k;
var formatLong$G = {};
formatLong$G.formatLong = void 0;
var _index$2P = buildFormatLongFn;
var dateFormats$G = {
  full: "y年M月d日EEEE",
  long: "y年M月d日",
  medium: "y/MM/dd",
  short: "y/MM/dd"
};
var timeFormats$G = {
  full: "H時mm分ss秒 zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$G = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$G.formatLong = {
  date: (0, _index$2P.buildFormatLongFn)({
    formats: dateFormats$G,
    defaultWidth: "full"
  }),
  time: (0, _index$2P.buildFormatLongFn)({
    formats: timeFormats$G,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2P.buildFormatLongFn)({
    formats: dateTimeFormats$G,
    defaultWidth: "full"
  })
};
var formatRelative$1l = {};
formatRelative$1l.formatRelative = void 0;
var formatRelativeLocale$G = {
  lastWeek: "先週のeeeeのp",
  yesterday: "昨日のp",
  today: "今日のp",
  tomorrow: "明日のp",
  nextWeek: "翌週のeeeeのp",
  other: "P"
};
var formatRelative$1k = function (e, a, t, r) {
  return formatRelativeLocale$G[e];
};
formatRelative$1l.formatRelative = formatRelative$1k;
var localize$G = {};
localize$G.localize = void 0;
var _index$2O = buildLocalizeFn;
var eraValues$G = {
  narrow: ["BC", "AC"],
  abbreviated: ["紀元前", "西暦"],
  wide: ["紀元前", "西暦"]
};
var quarterValues$G = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["第1四半期", "第2四半期", "第3四半期", "第4四半期"]
};
var monthValues$G = {
  narrow: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
  abbreviated: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
  wide: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"]
};
var dayValues$G = {
  narrow: ["日", "月", "火", "水", "木", "金", "土"],
  short: ["日", "月", "火", "水", "木", "金", "土"],
  abbreviated: ["日", "月", "火", "水", "木", "金", "土"],
  wide: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"]
};
var dayPeriodValues$G = {
  narrow: {
    am: "午前",
    pm: "午後",
    midnight: "深夜",
    noon: "正午",
    morning: "朝",
    afternoon: "午後",
    evening: "夜",
    night: "深夜"
  },
  abbreviated: {
    am: "午前",
    pm: "午後",
    midnight: "深夜",
    noon: "正午",
    morning: "朝",
    afternoon: "午後",
    evening: "夜",
    night: "深夜"
  },
  wide: {
    am: "午前",
    pm: "午後",
    midnight: "深夜",
    noon: "正午",
    morning: "朝",
    afternoon: "午後",
    evening: "夜",
    night: "深夜"
  }
};
var formattingDayPeriodValues$y = {
  narrow: {
    am: "午前",
    pm: "午後",
    midnight: "深夜",
    noon: "正午",
    morning: "朝",
    afternoon: "午後",
    evening: "夜",
    night: "深夜"
  },
  abbreviated: {
    am: "午前",
    pm: "午後",
    midnight: "深夜",
    noon: "正午",
    morning: "朝",
    afternoon: "午後",
    evening: "夜",
    night: "深夜"
  },
  wide: {
    am: "午前",
    pm: "午後",
    midnight: "深夜",
    noon: "正午",
    morning: "朝",
    afternoon: "午後",
    evening: "夜",
    night: "深夜"
  }
};
var ordinalNumber$G = function (e, a) {
  var t = Number(e);
  var r = String(a === null || a === void 0 ? void 0 : a.unit);
  switch (r) {
    case "year":
      return "".concat(t, "年");
    case "quarter":
      return "第".concat(t, "四半期");
    case "month":
      return "".concat(t, "月");
    case "week":
      return "第".concat(t, "週");
    case "date":
      return "".concat(t, "日");
    case "hour":
      return "".concat(t, "時");
    case "minute":
      return "".concat(t, "分");
    case "second":
      return "".concat(t, "秒");
    default:
      return "".concat(t);
  }
};
localize$G.localize = {
  ordinalNumber: ordinalNumber$G,
  era: (0, _index$2O.buildLocalizeFn)({
    values: eraValues$G,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2O.buildLocalizeFn)({
    values: quarterValues$G,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return Number(e) - 1;
    }
  }),
  month: (0, _index$2O.buildLocalizeFn)({
    values: monthValues$G,
    defaultWidth: "wide"
  }),
  day: (0, _index$2O.buildLocalizeFn)({
    values: dayValues$G,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$2O.buildLocalizeFn)({
    values: dayPeriodValues$G,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$y,
    defaultFormattingWidth: "wide"
  })
};
var match$G = {};
match$G.match = void 0;
var _index$2N = buildMatchPatternFn;
var _index2$1m = buildMatchFn;
var matchOrdinalNumberPattern$G = /^第?\d+(年|四半期|月|週|日|時|分|秒)?/i;
var parseOrdinalNumberPattern$G = /\d+/i;
var matchEraPatterns$G = {
  narrow: /^(B\.?C\.?|A\.?D\.?)/i,
  abbreviated: /^(紀元[前後]|西暦)/i,
  wide: /^(紀元[前後]|西暦)/i
};
var parseEraPatterns$G = {
  narrow: [/^B/i, /^A/i],
  any: [/^(紀元前)/i, /^(西暦|紀元後)/i]
};
var matchQuarterPatterns$G = {
  narrow: /^[1234]/i,
  abbreviated: /^Q[1234]/i,
  wide: /^第[1234一二三四１２３４]四半期/i
};
var parseQuarterPatterns$G = {
  any: [/(1|一|１)/i, /(2|二|２)/i, /(3|三|３)/i, /(4|四|４)/i]
};
var matchMonthPatterns$G = {
  narrow: /^([123456789]|1[012])/,
  abbreviated: /^([123456789]|1[012])月/i,
  wide: /^([123456789]|1[012])月/i
};
var parseMonthPatterns$G = {
  any: [/^1\D/, /^2/, /^3/, /^4/, /^5/, /^6/, /^7/, /^8/, /^9/, /^10/, /^11/, /^12/]
};
var matchDayPatterns$G = {
  narrow: /^[日月火水木金土]/,
  short: /^[日月火水木金土]/,
  abbreviated: /^[日月火水木金土]/,
  wide: /^[日月火水木金土]曜日/
};
var parseDayPatterns$G = {
  any: [/^日/, /^月/, /^火/, /^水/, /^木/, /^金/, /^土/]
};
var matchDayPeriodPatterns$G = {
  any: /^(AM|PM|午前|午後|正午|深夜|真夜中|夜|朝)/i
};
var parseDayPeriodPatterns$G = {
  any: {
    am: /^(A|午前)/i,
    pm: /^(P|午後)/i,
    midnight: /^深夜|真夜中/i,
    noon: /^正午/i,
    morning: /^朝/i,
    afternoon: /^午後/i,
    evening: /^夜/i,
    night: /^深夜/i
  }
};
match$G.match = {
  ordinalNumber: (0, _index$2N.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$G,
    parsePattern: parseOrdinalNumberPattern$G,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index2$1m.buildMatchFn)({
    matchPatterns: matchEraPatterns$G,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$G,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index2$1m.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$G,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$G,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index2$1m.buildMatchFn)({
    matchPatterns: matchMonthPatterns$G,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$G,
    defaultParseWidth: "any"
  }),
  day: (0, _index2$1m.buildMatchFn)({
    matchPatterns: matchDayPatterns$G,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$G,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index2$1m.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$G,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$G,
    defaultParseWidth: "any"
  })
};
ja.ja = void 0;
var _index$2M = formatDistance$1l;
var _index2$1l = formatLong$G;
var _index3$G = formatRelative$1l;
var _index4$G = localize$G;
var _index5$G = match$G;
ja.ja = {
  code: "ja",
  formatDistance: _index$2M.formatDistance,
  formatLong: _index2$1l.formatLong,
  formatRelative: _index3$G.formatRelative,
  localize: _index4$G.localize,
  match: _index5$G.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var jaHira = {};
var formatDistance$1j = {};
formatDistance$1j.formatDistance = void 0;
var formatDistanceLocale$F = {
  lessThanXSeconds: {
    one: "1びょうみまん",
    other: "{{count}}びょうみまん",
    oneWithSuffix: "やく1びょう",
    otherWithSuffix: "やく{{count}}びょう"
  },
  xSeconds: {
    one: "1びょう",
    other: "{{count}}びょう"
  },
  halfAMinute: "30びょう",
  lessThanXMinutes: {
    one: "1ぷんみまん",
    other: "{{count}}ふんみまん",
    oneWithSuffix: "やく1ぷん",
    otherWithSuffix: "やく{{count}}ふん"
  },
  xMinutes: {
    one: "1ぷん",
    other: "{{count}}ふん"
  },
  aboutXHours: {
    one: "やく1じかん",
    other: "やく{{count}}じかん"
  },
  xHours: {
    one: "1じかん",
    other: "{{count}}じかん"
  },
  xDays: {
    one: "1にち",
    other: "{{count}}にち"
  },
  aboutXWeeks: {
    one: "やく1しゅうかん",
    other: "やく{{count}}しゅうかん"
  },
  xWeeks: {
    one: "1しゅうかん",
    other: "{{count}}しゅうかん"
  },
  aboutXMonths: {
    one: "やく1かげつ",
    other: "やく{{count}}かげつ"
  },
  xMonths: {
    one: "1かげつ",
    other: "{{count}}かげつ"
  },
  aboutXYears: {
    one: "やく1ねん",
    other: "やく{{count}}ねん"
  },
  xYears: {
    one: "1ねん",
    other: "{{count}}ねん"
  },
  overXYears: {
    one: "1ねんいじょう",
    other: "{{count}}ねんいじょう"
  },
  almostXYears: {
    one: "1ねんちかく",
    other: "{{count}}ねんちかく"
  }
};
var formatDistance$1i = function (e, a, t) {
  t = t || {};
  var r;
  var n = formatDistanceLocale$F[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    if (t.addSuffix && n.oneWithSuffix) {
      r = n.oneWithSuffix;
    } else {
      r = n.one;
    }
  } else {
    if (t.addSuffix && n.otherWithSuffix) {
      r = n.otherWithSuffix.replace("{{count}}", String(a));
    } else {
      r = n.other.replace("{{count}}", String(a));
    }
  }
  if (t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + "あと";
    } else {
      return r + "まえ";
    }
  }
  return r;
};
formatDistance$1j.formatDistance = formatDistance$1i;
var formatLong$F = {};
formatLong$F.formatLong = void 0;
var _index$2L = buildFormatLongFn;
var dateFormats$F = {
  full: "yねんMがつdにちEEEE",
  long: "yねんMがつdにち",
  medium: "y/MM/dd",
  short: "y/MM/dd"
};
var timeFormats$F = {
  full: "Hじmmふんssびょう zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$F = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$F.formatLong = {
  date: (0, _index$2L.buildFormatLongFn)({
    formats: dateFormats$F,
    defaultWidth: "full"
  }),
  time: (0, _index$2L.buildFormatLongFn)({
    formats: timeFormats$F,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2L.buildFormatLongFn)({
    formats: dateTimeFormats$F,
    defaultWidth: "full"
  })
};
var formatRelative$1j = {};
formatRelative$1j.formatRelative = void 0;
var formatRelativeLocale$F = {
  lastWeek: "せんしゅうのeeeeのp",
  yesterday: "きのうのp",
  today: "きょうのp",
  tomorrow: "あしたのp",
  nextWeek: "よくしゅうのeeeeのp",
  other: "P"
};
var formatRelative$1i = function (e, a, t, r) {
  return formatRelativeLocale$F[e];
};
formatRelative$1j.formatRelative = formatRelative$1i;
var localize$F = {};
localize$F.localize = void 0;
var _index$2K = buildLocalizeFn;
var eraValues$F = {
  narrow: ["BC", "AC"],
  abbreviated: ["きげんぜん", "せいれき"],
  wide: ["きげんぜん", "せいれき"]
};
var quarterValues$F = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["だい1しはんき", "だい2しはんき", "だい3しはんき", "だい4しはんき"]
};
var monthValues$F = {
  narrow: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
  abbreviated: ["1がつ", "2がつ", "3がつ", "4がつ", "5がつ", "6がつ", "7がつ", "8がつ", "9がつ", "10がつ", "11がつ", "12がつ"],
  wide: ["1がつ", "2がつ", "3がつ", "4がつ", "5がつ", "6がつ", "7がつ", "8がつ", "9がつ", "10がつ", "11がつ", "12がつ"]
};
var dayValues$F = {
  narrow: ["にち", "げつ", "か", "すい", "もく", "きん", "ど"],
  short: ["にち", "げつ", "か", "すい", "もく", "きん", "ど"],
  abbreviated: ["にち", "げつ", "か", "すい", "もく", "きん", "ど"],
  wide: ["にちようび", "げつようび", "かようび", "すいようび", "もくようび", "きんようび", "どようび"]
};
var dayPeriodValues$F = {
  narrow: {
    am: "ごぜん",
    pm: "ごご",
    midnight: "しんや",
    noon: "しょうご",
    morning: "あさ",
    afternoon: "ごご",
    evening: "よる",
    night: "しんや"
  },
  abbreviated: {
    am: "ごぜん",
    pm: "ごご",
    midnight: "しんや",
    noon: "しょうご",
    morning: "あさ",
    afternoon: "ごご",
    evening: "よる",
    night: "しんや"
  },
  wide: {
    am: "ごぜん",
    pm: "ごご",
    midnight: "しんや",
    noon: "しょうご",
    morning: "あさ",
    afternoon: "ごご",
    evening: "よる",
    night: "しんや"
  }
};
var formattingDayPeriodValues$x = {
  narrow: {
    am: "ごぜん",
    pm: "ごご",
    midnight: "しんや",
    noon: "しょうご",
    morning: "あさ",
    afternoon: "ごご",
    evening: "よる",
    night: "しんや"
  },
  abbreviated: {
    am: "ごぜん",
    pm: "ごご",
    midnight: "しんや",
    noon: "しょうご",
    morning: "あさ",
    afternoon: "ごご",
    evening: "よる",
    night: "しんや"
  },
  wide: {
    am: "ごぜん",
    pm: "ごご",
    midnight: "しんや",
    noon: "しょうご",
    morning: "あさ",
    afternoon: "ごご",
    evening: "よる",
    night: "しんや"
  }
};
var ordinalNumber$F = function (e, a) {
  var t = Number(e);
  var r = String(a === null || a === void 0 ? void 0 : a.unit);
  switch (r) {
    case "year":
      return "".concat(t, "ねん");
    case "quarter":
      return "だい".concat(t, "しはんき");
    case "month":
      return "".concat(t, "がつ");
    case "week":
      return "だい".concat(t, "しゅう");
    case "date":
      return "".concat(t, "にち");
    case "hour":
      return "".concat(t, "じ");
    case "minute":
      return "".concat(t, "ふん");
    case "second":
      return "".concat(t, "びょう");
    default:
      return "".concat(t);
  }
};
localize$F.localize = {
  ordinalNumber: ordinalNumber$F,
  era: (0, _index$2K.buildLocalizeFn)({
    values: eraValues$F,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2K.buildLocalizeFn)({
    values: quarterValues$F,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return Number(e) - 1;
    }
  }),
  month: (0, _index$2K.buildLocalizeFn)({
    values: monthValues$F,
    defaultWidth: "wide"
  }),
  day: (0, _index$2K.buildLocalizeFn)({
    values: dayValues$F,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$2K.buildLocalizeFn)({
    values: dayPeriodValues$F,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$x,
    defaultFormattingWidth: "wide"
  })
};
var match$F = {};
match$F.match = void 0;
var _index$2J = buildMatchFn;
var _index2$1k = buildMatchPatternFn;
var matchOrdinalNumberPattern$F = /^だ?い?\d+(ねん|しはんき|がつ|しゅう|にち|じ|ふん|びょう)?/i;
var parseOrdinalNumberPattern$F = /\d+/i;
var matchEraPatterns$F = {
  narrow: /^(B\.?C\.?|A\.?D\.?)/i,
  abbreviated: /^(きげん[前後]|せいれき)/i,
  wide: /^(きげん[前後]|せいれき)/i
};
var parseEraPatterns$F = {
  narrow: [/^B/i, /^A/i],
  any: [/^(きげんぜん)/i, /^(せいれき|きげんご)/i]
};
var matchQuarterPatterns$F = {
  narrow: /^[1234]/i,
  abbreviated: /^Q[1234]/i,
  wide: /^だい[1234一二三四１２３４]しはんき/i
};
var parseQuarterPatterns$F = {
  any: [/(1|一|１)/i, /(2|二|２)/i, /(3|三|３)/i, /(4|四|４)/i]
};
var matchMonthPatterns$F = {
  narrow: /^([123456789]|1[012])/,
  abbreviated: /^([123456789]|1[012])がつ/i,
  wide: /^([123456789]|1[012])がつ/i
};
var parseMonthPatterns$F = {
  any: [/^1\D/, /^2/, /^3/, /^4/, /^5/, /^6/, /^7/, /^8/, /^9/, /^10/, /^11/, /^12/]
};
var matchDayPatterns$F = {
  narrow: /^(にち|げつ|か|すい|もく|きん|ど)/,
  short: /^(にち|げつ|か|すい|もく|きん|ど)/,
  abbreviated: /^(にち|げつ|か|すい|もく|きん|ど)/,
  wide: /^(にち|げつ|か|すい|もく|きん|ど)ようび/
};
var parseDayPatterns$F = {
  any: [/^にち/, /^げつ/, /^か/, /^すい/, /^もく/, /^きん/, /^ど/]
};
var matchDayPeriodPatterns$F = {
  any: /^(AM|PM|ごぜん|ごご|しょうご|しんや|まよなか|よる|あさ)/i
};
var parseDayPeriodPatterns$F = {
  any: {
    am: /^(A|ごぜん)/i,
    pm: /^(P|ごご)/i,
    midnight: /^しんや|まよなか/i,
    noon: /^しょうご/i,
    morning: /^あさ/i,
    afternoon: /^ごご/i,
    evening: /^よる/i,
    night: /^しんや/i
  }
};
match$F.match = {
  ordinalNumber: (0, _index2$1k.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$F,
    parsePattern: parseOrdinalNumberPattern$F,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$2J.buildMatchFn)({
    matchPatterns: matchEraPatterns$F,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$F,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$2J.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$F,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$F,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$2J.buildMatchFn)({
    matchPatterns: matchMonthPatterns$F,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$F,
    defaultParseWidth: "any"
  }),
  day: (0, _index$2J.buildMatchFn)({
    matchPatterns: matchDayPatterns$F,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$F,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$2J.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$F,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$F,
    defaultParseWidth: "any"
  })
};
jaHira.jaHira = void 0;
var _index$2I = formatDistance$1j;
var _index2$1j = formatLong$F;
var _index3$F = formatRelative$1j;
var _index4$F = localize$F;
var _index5$F = match$F;
jaHira.jaHira = {
  code: "ja-Hira",
  formatDistance: _index$2I.formatDistance,
  formatLong: _index2$1j.formatLong,
  formatRelative: _index3$F.formatRelative,
  localize: _index4$F.localize,
  match: _index5$F.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var ka = {};
var formatDistance$1h = {};
formatDistance$1h.formatDistance = void 0;
var formatDistanceLocale$E = {
  lessThanXSeconds: {
    past: "{{count}} წამზე ნაკლები ხნის წინ",
    present: "{{count}} წამზე ნაკლები",
    future: "{{count}} წამზე ნაკლებში"
  },
  xSeconds: {
    past: "{{count}} წამის წინ",
    present: "{{count}} წამი",
    future: "{{count}} წამში"
  },
  halfAMinute: {
    past: "ნახევარი წუთის წინ",
    present: "ნახევარი წუთი",
    future: "ნახევარი წუთში"
  },
  lessThanXMinutes: {
    past: "{{count}} წუთზე ნაკლები ხნის წინ",
    present: "{{count}} წუთზე ნაკლები",
    future: "{{count}} წუთზე ნაკლებში"
  },
  xMinutes: {
    past: "{{count}} წუთის წინ",
    present: "{{count}} წუთი",
    future: "{{count}} წუთში"
  },
  aboutXHours: {
    past: "დაახლოებით {{count}} საათის წინ",
    present: "დაახლოებით {{count}} საათი",
    future: "დაახლოებით {{count}} საათში"
  },
  xHours: {
    past: "{{count}} საათის წინ",
    present: "{{count}} საათი",
    future: "{{count}} საათში"
  },
  xDays: {
    past: "{{count}} დღის წინ",
    present: "{{count}} დღე",
    future: "{{count}} დღეში"
  },
  aboutXWeeks: {
    past: "დაახლოებით {{count}} კვირას წინ",
    present: "დაახლოებით {{count}} კვირა",
    future: "დაახლოებით {{count}} კვირაში"
  },
  xWeeks: {
    past: "{{count}} კვირას კვირა",
    present: "{{count}} კვირა",
    future: "{{count}} კვირაში"
  },
  aboutXMonths: {
    past: "დაახლოებით {{count}} თვის წინ",
    present: "დაახლოებით {{count}} თვე",
    future: "დაახლოებით {{count}} თვეში"
  },
  xMonths: {
    past: "{{count}} თვის წინ",
    present: "{{count}} თვე",
    future: "{{count}} თვეში"
  },
  aboutXYears: {
    past: "დაახლოებით {{count}} წლის წინ",
    present: "დაახლოებით {{count}} წელი",
    future: "დაახლოებით {{count}} წელში"
  },
  xYears: {
    past: "{{count}} წლის წინ",
    present: "{{count}} წელი",
    future: "{{count}} წელში"
  },
  overXYears: {
    past: "{{count}} წელზე მეტი ხნის წინ",
    present: "{{count}} წელზე მეტი",
    future: "{{count}} წელზე მეტი ხნის შემდეგ"
  },
  almostXYears: {
    past: "თითქმის {{count}} წლის წინ",
    present: "თითქმის {{count}} წელი",
    future: "თითქმის {{count}} წელში"
  }
};
var formatDistance$1g = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$E[e];
  if (typeof n === "string") {
    r = n;
  } else if ((t === null || t === void 0 ? void 0 : t.addSuffix) && t.comparison && t.comparison > 0) {
    r = n.future.replace("{{count}}", String(a));
  } else if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    r = n.past.replace("{{count}}", String(a));
  } else {
    r = n.present.replace("{{count}}", String(a));
  }
  return r;
};
formatDistance$1h.formatDistance = formatDistance$1g;
var formatLong$E = {};
formatLong$E.formatLong = void 0;
var _index$2H = buildFormatLongFn;
var dateFormats$E = {
  full: "EEEE, do MMMM, y",
  long: "do, MMMM, y",
  medium: "d, MMM, y",
  short: "dd/MM/yyyy"
};
var timeFormats$E = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$E = {
  full: "{{date}} {{time}}'-ზე'",
  long: "{{date}} {{time}}'-ზე'",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$E.formatLong = {
  date: (0, _index$2H.buildFormatLongFn)({
    formats: dateFormats$E,
    defaultWidth: "full"
  }),
  time: (0, _index$2H.buildFormatLongFn)({
    formats: timeFormats$E,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2H.buildFormatLongFn)({
    formats: dateTimeFormats$E,
    defaultWidth: "full"
  })
};
var formatRelative$1h = {};
formatRelative$1h.formatRelative = void 0;
var formatRelativeLocale$E = {
  lastWeek: "'წინა' eeee p'-ზე'",
  yesterday: "'გუშინ' p'-ზე'",
  today: "'დღეს' p'-ზე'",
  tomorrow: "'ხვალ' p'-ზე'",
  nextWeek: "'შემდეგი' eeee p'-ზე'",
  other: "P"
};
var formatRelative$1g = function (e, a, t, r) {
  return formatRelativeLocale$E[e];
};
formatRelative$1h.formatRelative = formatRelative$1g;
var localize$E = {};
localize$E.localize = void 0;
var _index$2G = buildLocalizeFn;
var eraValues$E = {
  narrow: ["ჩ.წ-მდე", "ჩ.წ"],
  abbreviated: ["ჩვ.წ-მდე", "ჩვ.წ"],
  wide: ["ჩვენს წელთაღრიცხვამდე", "ჩვენი წელთაღრიცხვით"]
};
var quarterValues$E = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1-ლი კვ", "2-ე კვ", "3-ე კვ", "4-ე კვ"],
  wide: ["1-ლი კვარტალი", "2-ე კვარტალი", "3-ე კვარტალი", "4-ე კვარტალი"]
};
var monthValues$E = {
  narrow: ["ია", "თე", "მა", "აპ", "მს", "ვნ", "ვლ", "აგ", "სე", "ოქ", "ნო", "დე"],
  abbreviated: ["იან", "თებ", "მარ", "აპრ", "მაი", "ივნ", "ივლ", "აგვ", "სექ", "ოქტ", "ნოე", "დეკ"],
  wide: ["იანვარი", "თებერვალი", "მარტი", "აპრილი", "მაისი", "ივნისი", "ივლისი", "აგვისტო", "სექტემბერი", "ოქტომბერი", "ნოემბერი", "დეკემბერი"]
};
var dayValues$E = {
  narrow: ["კვ", "ორ", "სა", "ოთ", "ხუ", "პა", "შა"],
  short: ["კვი", "ორშ", "სამ", "ოთხ", "ხუთ", "პარ", "შაბ"],
  abbreviated: ["კვი", "ორშ", "სამ", "ოთხ", "ხუთ", "პარ", "შაბ"],
  wide: ["კვირა", "ორშაბათი", "სამშაბათი", "ოთხშაბათი", "ხუთშაბათი", "პარასკევი", "შაბათი"]
};
var dayPeriodValues$E = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "შუაღამე",
    noon: "შუადღე",
    morning: "დილა",
    afternoon: "საღამო",
    evening: "საღამო",
    night: "ღამე"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "შუაღამე",
    noon: "შუადღე",
    morning: "დილა",
    afternoon: "საღამო",
    evening: "საღამო",
    night: "ღამე"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "შუაღამე",
    noon: "შუადღე",
    morning: "დილა",
    afternoon: "საღამო",
    evening: "საღამო",
    night: "ღამე"
  }
};
var formattingDayPeriodValues$w = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "შუაღამით",
    noon: "შუადღისას",
    morning: "დილით",
    afternoon: "ნაშუადღევს",
    evening: "საღამოს",
    night: "ღამით"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "შუაღამით",
    noon: "შუადღისას",
    morning: "დილით",
    afternoon: "ნაშუადღევს",
    evening: "საღამოს",
    night: "ღამით"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "შუაღამით",
    noon: "შუადღისას",
    morning: "დილით",
    afternoon: "ნაშუადღევს",
    evening: "საღამოს",
    night: "ღამით"
  }
};
var ordinalNumber$E = function (e) {
  var a = Number(e);
  if (a === 1) {
    return a + "-ლი";
  }
  return a + "-ე";
};
localize$E.localize = {
  ordinalNumber: ordinalNumber$E,
  era: (0, _index$2G.buildLocalizeFn)({
    values: eraValues$E,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2G.buildLocalizeFn)({
    values: quarterValues$E,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$2G.buildLocalizeFn)({
    values: monthValues$E,
    defaultWidth: "wide"
  }),
  day: (0, _index$2G.buildLocalizeFn)({
    values: dayValues$E,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$2G.buildLocalizeFn)({
    values: dayPeriodValues$E,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$w,
    defaultFormattingWidth: "wide"
  })
};
var match$E = {};
match$E.match = void 0;
var _index$2F = buildMatchFn;
var _index2$1i = buildMatchPatternFn;
var matchOrdinalNumberPattern$E = /^(\d+)(-ლი|-ე)?/i;
var parseOrdinalNumberPattern$E = /\d+/i;
var matchEraPatterns$E = {
  narrow: /^(ჩვ?\.წ)/i,
  abbreviated: /^(ჩვ?\.წ)/i,
  wide: /^(ჩვენს წელთაღრიცხვამდე|ქრისტეშობამდე|ჩვენი წელთაღრიცხვით|ქრისტეშობიდან)/i
};
var parseEraPatterns$E = {
  any: [/^(ჩვენს წელთაღრიცხვამდე|ქრისტეშობამდე)/i, /^(ჩვენი წელთაღრიცხვით|ქრისტეშობიდან)/i]
};
var matchQuarterPatterns$E = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234]-(ლი|ე)? კვ/i,
  wide: /^[1234]-(ლი|ე)? კვარტალი/i
};
var parseQuarterPatterns$E = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$E = {
  any: /^(ია|თე|მა|აპ|მს|ვნ|ვლ|აგ|სე|ოქ|ნო|დე)/i
};
var parseMonthPatterns$E = {
  any: [/^ია/i, /^თ/i, /^მარ/i, /^აპ/i, /^მაი/i, /^ი?ვნ/i, /^ი?ვლ/i, /^აგ/i, /^ს/i, /^ო/i, /^ნ/i, /^დ/i]
};
var matchDayPatterns$E = {
  narrow: /^(კვ|ორ|სა|ოთ|ხუ|პა|შა)/i,
  short: /^(კვი|ორშ|სამ|ოთხ|ხუთ|პარ|შაბ)/i,
  wide: /^(კვირა|ორშაბათი|სამშაბათი|ოთხშაბათი|ხუთშაბათი|პარასკევი|შაბათი)/i
};
var parseDayPatterns$E = {
  any: [/^კვ/i, /^ორ/i, /^სა/i, /^ოთ/i, /^ხუ/i, /^პა/i, /^შა/i]
};
var matchDayPeriodPatterns$E = {
  any: /^([ap]\.?\s?m\.?|შუაღ|დილ)/i
};
var parseDayPeriodPatterns$E = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^შუაღ/i,
    noon: /^შუადღ/i,
    morning: /^დილ/i,
    afternoon: /ნაშუადღევს/i,
    evening: /საღამო/i,
    night: /ღამ/i
  }
};
match$E.match = {
  ordinalNumber: (0, _index2$1i.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$E,
    parsePattern: parseOrdinalNumberPattern$E,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$2F.buildMatchFn)({
    matchPatterns: matchEraPatterns$E,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$E,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$2F.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$E,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$E,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$2F.buildMatchFn)({
    matchPatterns: matchMonthPatterns$E,
    defaultMatchWidth: "any",
    parsePatterns: parseMonthPatterns$E,
    defaultParseWidth: "any"
  }),
  day: (0, _index$2F.buildMatchFn)({
    matchPatterns: matchDayPatterns$E,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$E,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$2F.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$E,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$E,
    defaultParseWidth: "any"
  })
};
ka.ka = void 0;
var _index$2E = formatDistance$1h;
var _index2$1h = formatLong$E;
var _index3$E = formatRelative$1h;
var _index4$E = localize$E;
var _index5$E = match$E;
ka.ka = {
  code: "ka",
  formatDistance: _index$2E.formatDistance,
  formatLong: _index2$1h.formatLong,
  formatRelative: _index3$E.formatRelative,
  localize: _index4$E.localize,
  match: _index5$E.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var kk = {};
var formatDistance$1f = {};
formatDistance$1f.formatDistance = void 0;
var formatDistanceLocale$D = {
  lessThanXSeconds: {
    regular: {
      one: "1 секундтан аз",
      singularNominative: "{{count}} секундтан аз",
      singularGenitive: "{{count}} секундтан аз",
      pluralGenitive: "{{count}} секундтан аз"
    },
    future: {
      one: "бір секундтан кейін",
      singularNominative: "{{count}} секундтан кейін",
      singularGenitive: "{{count}} секундтан кейін",
      pluralGenitive: "{{count}} секундтан кейін"
    }
  },
  xSeconds: {
    regular: {
      singularNominative: "{{count}} секунд",
      singularGenitive: "{{count}} секунд",
      pluralGenitive: "{{count}} секунд"
    },
    past: {
      singularNominative: "{{count}} секунд бұрын",
      singularGenitive: "{{count}} секунд бұрын",
      pluralGenitive: "{{count}} секунд бұрын"
    },
    future: {
      singularNominative: "{{count}} секундтан кейін",
      singularGenitive: "{{count}} секундтан кейін",
      pluralGenitive: "{{count}} секундтан кейін"
    }
  },
  halfAMinute: function (e) {
    if (e === null || e === void 0 ? void 0 : e.addSuffix) {
      if (e.comparison && e.comparison > 0) {
        return "жарты минут ішінде";
      } else {
        return "жарты минут бұрын";
      }
    }
    return "жарты минут";
  },
  lessThanXMinutes: {
    regular: {
      one: "1 минуттан аз",
      singularNominative: "{{count}} минуттан аз",
      singularGenitive: "{{count}} минуттан аз",
      pluralGenitive: "{{count}} минуттан аз"
    },
    future: {
      one: "минуттан кем ",
      singularNominative: "{{count}} минуттан кем",
      singularGenitive: "{{count}} минуттан кем",
      pluralGenitive: "{{count}} минуттан кем"
    }
  },
  xMinutes: {
    regular: {
      singularNominative: "{{count}} минут",
      singularGenitive: "{{count}} минут",
      pluralGenitive: "{{count}} минут"
    },
    past: {
      singularNominative: "{{count}} минут бұрын",
      singularGenitive: "{{count}} минут бұрын",
      pluralGenitive: "{{count}} минут бұрын"
    },
    future: {
      singularNominative: "{{count}} минуттан кейін",
      singularGenitive: "{{count}} минуттан кейін",
      pluralGenitive: "{{count}} минуттан кейін"
    }
  },
  aboutXHours: {
    regular: {
      singularNominative: "шамамен {{count}} сағат",
      singularGenitive: "шамамен {{count}} сағат",
      pluralGenitive: "шамамен {{count}} сағат"
    },
    future: {
      singularNominative: "шамамен {{count}} сағаттан кейін",
      singularGenitive: "шамамен {{count}} сағаттан кейін",
      pluralGenitive: "шамамен {{count}} сағаттан кейін"
    }
  },
  xHours: {
    regular: {
      singularNominative: "{{count}} сағат",
      singularGenitive: "{{count}} сағат",
      pluralGenitive: "{{count}} сағат"
    }
  },
  xDays: {
    regular: {
      singularNominative: "{{count}} күн",
      singularGenitive: "{{count}} күн",
      pluralGenitive: "{{count}} күн"
    },
    future: {
      singularNominative: "{{count}} күннен кейін",
      singularGenitive: "{{count}} күннен кейін",
      pluralGenitive: "{{count}} күннен кейін"
    }
  },
  aboutXWeeks: {
    type: "weeks",
    one: "шамамен 1 апта",
    other: "шамамен {{count}} апта"
  },
  xWeeks: {
    type: "weeks",
    one: "1 апта",
    other: "{{count}} апта"
  },
  aboutXMonths: {
    regular: {
      singularNominative: "шамамен {{count}} ай",
      singularGenitive: "шамамен {{count}} ай",
      pluralGenitive: "шамамен {{count}} ай"
    },
    future: {
      singularNominative: "шамамен {{count}} айдан кейін",
      singularGenitive: "шамамен {{count}} айдан кейін",
      pluralGenitive: "шамамен {{count}} айдан кейін"
    }
  },
  xMonths: {
    regular: {
      singularNominative: "{{count}} ай",
      singularGenitive: "{{count}} ай",
      pluralGenitive: "{{count}} ай"
    }
  },
  aboutXYears: {
    regular: {
      singularNominative: "шамамен {{count}} жыл",
      singularGenitive: "шамамен {{count}} жыл",
      pluralGenitive: "шамамен {{count}} жыл"
    },
    future: {
      singularNominative: "шамамен {{count}} жылдан кейін",
      singularGenitive: "шамамен {{count}} жылдан кейін",
      pluralGenitive: "шамамен {{count}} жылдан кейін"
    }
  },
  xYears: {
    regular: {
      singularNominative: "{{count}} жыл",
      singularGenitive: "{{count}} жыл",
      pluralGenitive: "{{count}} жыл"
    },
    future: {
      singularNominative: "{{count}} жылдан кейін",
      singularGenitive: "{{count}} жылдан кейін",
      pluralGenitive: "{{count}} жылдан кейін"
    }
  },
  overXYears: {
    regular: {
      singularNominative: "{{count}} жылдан астам",
      singularGenitive: "{{count}} жылдан астам",
      pluralGenitive: "{{count}} жылдан астам"
    },
    future: {
      singularNominative: "{{count}} жылдан астам",
      singularGenitive: "{{count}} жылдан астам",
      pluralGenitive: "{{count}} жылдан астам"
    }
  },
  almostXYears: {
    regular: {
      singularNominative: "{{count}} жылға жақын",
      singularGenitive: "{{count}} жылға жақын",
      pluralGenitive: "{{count}} жылға жақын"
    },
    future: {
      singularNominative: "{{count}} жылдан кейін",
      singularGenitive: "{{count}} жылдан кейін",
      pluralGenitive: "{{count}} жылдан кейін"
    }
  }
};
function declension$4(e, a) {
  if (e.one && a === 1) return e.one;
  var t = a % 10;
  var r = a % 100;
  if (t === 1 && r !== 11) {
    return e.singularNominative.replace("{{count}}", String(a));
  } else if (t >= 2 && t <= 4 && (r < 10 || r > 20)) {
    return e.singularGenitive.replace("{{count}}", String(a));
  } else {
    return e.pluralGenitive.replace("{{count}}", String(a));
  }
}
var formatDistance$1e = function (e, a, t) {
  var r = formatDistanceLocale$D[e];
  if (typeof r === "function") return r(t);
  if (r.type === "weeks") {
    return a === 1 ? r.one : r.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      if (r.future) {
        return declension$4(r.future, a);
      } else {
        return declension$4(r.regular, a) + " кейін";
      }
    } else {
      if (r.past) {
        return declension$4(r.past, a);
      } else {
        return declension$4(r.regular, a) + " бұрын";
      }
    }
  } else {
    return declension$4(r.regular, a);
  }
};
formatDistance$1f.formatDistance = formatDistance$1e;
var formatLong$D = {};
formatLong$D.formatLong = void 0;
var _index$2D = buildFormatLongFn;
var dateFormats$D = {
  full: "EEEE, do MMMM y 'ж.'",
  long: "do MMMM y 'ж.'",
  medium: "d MMM y 'ж.'",
  short: "dd.MM.yyyy"
};
var timeFormats$D = {
  full: "H:mm:ss zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$D = {
  any: "{{date}}, {{time}}"
};
formatLong$D.formatLong = {
  date: (0, _index$2D.buildFormatLongFn)({
    formats: dateFormats$D,
    defaultWidth: "full"
  }),
  time: (0, _index$2D.buildFormatLongFn)({
    formats: timeFormats$D,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2D.buildFormatLongFn)({
    formats: dateTimeFormats$D,
    defaultWidth: "any"
  })
};
var formatRelative$1f = {};
formatRelative$1f.formatRelative = void 0;
var _index$2C = isSameWeek;
var accusativeWeekdays$3 = ["жексенбіде", "дүйсенбіде", "сейсенбіде", "сәрсенбіде", "бейсенбіде", "жұмада", "сенбіде"];
function lastWeek$4(e) {
  var a = accusativeWeekdays$3[e];
  return "'өткен " + a + " сағат' p'-де'";
}
function thisWeek$4(e) {
  var a = accusativeWeekdays$3[e];
  return "'" + a + " сағат' p'-де'";
}
function nextWeek$4(e) {
  var a = accusativeWeekdays$3[e];
  return "'келесі " + a + " сағат' p'-де'";
}
var formatRelativeLocale$D = {
  lastWeek: function (e, a, t) {
    var r = e.getDay();
    if ((0, _index$2C.isSameWeek)(e, a, t)) {
      return thisWeek$4(r);
    } else {
      return lastWeek$4(r);
    }
  },
  yesterday: "'кеше сағат' p'-де'",
  today: "'бүгін сағат' p'-де'",
  tomorrow: "'ертең сағат' p'-де'",
  nextWeek: function (e, a, t) {
    var r = e.getDay();
    if ((0, _index$2C.isSameWeek)(e, a, t)) {
      return thisWeek$4(r);
    } else {
      return nextWeek$4(r);
    }
  },
  other: "P"
};
var formatRelative$1e = function (e, a, t, r) {
  var n = formatRelativeLocale$D[e];
  if (typeof n === "function") {
    return n(a, t, r);
  }
  return n;
};
formatRelative$1f.formatRelative = formatRelative$1e;
var localize$D = {};
localize$D.localize = void 0;
var _index$2B = buildLocalizeFn;
var eraValues$D = {
  narrow: ["б.з.д.", "б.з."],
  abbreviated: ["б.з.д.", "б.з."],
  wide: ["біздің заманымызға дейін", "біздің заманымыз"]
};
var quarterValues$D = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1-ші тоқ.", "2-ші тоқ.", "3-ші тоқ.", "4-ші тоқ."],
  wide: ["1-ші тоқсан", "2-ші тоқсан", "3-ші тоқсан", "4-ші тоқсан"]
};
var monthValues$D = {
  narrow: ["Қ", "А", "Н", "С", "М", "М", "Ш", "Т", "Қ", "Қ", "Қ", "Ж"],
  abbreviated: ["қаң", "ақп", "нау", "сәу", "мам", "мау", "шіл", "там", "қыр", "қаз", "қар", "жел"],
  wide: ["қаңтар", "ақпан", "наурыз", "сәуір", "мамыр", "маусым", "шілде", "тамыз", "қыркүйек", "қазан", "қараша", "желтоқсан"]
};
var formattingMonthValues$9 = {
  narrow: ["Қ", "А", "Н", "С", "М", "М", "Ш", "Т", "Қ", "Қ", "Қ", "Ж"],
  abbreviated: ["қаң", "ақп", "нау", "сәу", "мам", "мау", "шіл", "там", "қыр", "қаз", "қар", "жел"],
  wide: ["қаңтар", "ақпан", "наурыз", "сәуір", "мамыр", "маусым", "шілде", "тамыз", "қыркүйек", "қазан", "қараша", "желтоқсан"]
};
var dayValues$D = {
  narrow: ["Ж", "Д", "С", "С", "Б", "Ж", "С"],
  short: ["жс", "дс", "сс", "ср", "бс", "жм", "сб"],
  abbreviated: ["жс", "дс", "сс", "ср", "бс", "жм", "сб"],
  wide: ["жексенбі", "дүйсенбі", "сейсенбі", "сәрсенбі", "бейсенбі", "жұма", "сенбі"]
};
var dayPeriodValues$D = {
  narrow: {
    am: "ТД",
    pm: "ТК",
    midnight: "түн ортасы",
    noon: "түс",
    morning: "таң",
    afternoon: "күндіз",
    evening: "кеш",
    night: "түн"
  },
  wide: {
    am: "ТД",
    pm: "ТК",
    midnight: "түн ортасы",
    noon: "түс",
    morning: "таң",
    afternoon: "күндіз",
    evening: "кеш",
    night: "түн"
  }
};
var formattingDayPeriodValues$v = {
  narrow: {
    am: "ТД",
    pm: "ТК",
    midnight: "түн ортасында",
    noon: "түс",
    morning: "таң",
    afternoon: "күн",
    evening: "кеш",
    night: "түн"
  },
  wide: {
    am: "ТД",
    pm: "ТК",
    midnight: "түн ортасында",
    noon: "түсте",
    morning: "таңертең",
    afternoon: "күндіз",
    evening: "кеште",
    night: "түнде"
  }
};
var suffixes = {
  0: "-ші",
  1: "-ші",
  2: "-ші",
  3: "-ші",
  4: "-ші",
  5: "-ші",
  6: "-шы",
  7: "-ші",
  8: "-ші",
  9: "-шы",
  10: "-шы",
  20: "-шы",
  30: "-шы",
  40: "-шы",
  50: "-ші",
  60: "-шы",
  70: "-ші",
  80: "-ші",
  90: "-шы",
  100: "-ші"
};
var ordinalNumber$D = function (e, a) {
  var t = Number(e);
  var r = t % 10;
  var n = t >= 100 ? 100 : null;
  var i = suffixes[t] || suffixes[r] || n && suffixes[n] || "";
  return t + i;
};
localize$D.localize = {
  ordinalNumber: ordinalNumber$D,
  era: (0, _index$2B.buildLocalizeFn)({
    values: eraValues$D,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2B.buildLocalizeFn)({
    values: quarterValues$D,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$2B.buildLocalizeFn)({
    values: monthValues$D,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$9,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$2B.buildLocalizeFn)({
    values: dayValues$D,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$2B.buildLocalizeFn)({
    values: dayPeriodValues$D,
    defaultWidth: "any",
    formattingValues: formattingDayPeriodValues$v,
    defaultFormattingWidth: "wide"
  })
};
var match$D = {};
match$D.match = void 0;
var _index$2A = buildMatchFn;
var _index2$1g = buildMatchPatternFn;
var matchOrdinalNumberPattern$D = /^(\d+)(-?(ші|шы))?/i;
var parseOrdinalNumberPattern$D = /\d+/i;
var matchEraPatterns$D = {
  narrow: /^((б )?з\.?\s?д\.?)/i,
  abbreviated: /^((б )?з\.?\s?д\.?)/i,
  wide: /^(біздің заманымызға дейін|біздің заманымыз|біздің заманымыздан)/i
};
var parseEraPatterns$D = {
  any: [/^б/i, /^з/i]
};
var matchQuarterPatterns$D = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234](-?ші)? тоқ.?/i,
  wide: /^[1234](-?ші)? тоқсан/i
};
var parseQuarterPatterns$D = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$D = {
  narrow: /^(қ|а|н|с|м|мау|ш|т|қыр|қаз|қар|ж)/i,
  abbreviated: /^(қаң|ақп|нау|сәу|мам|мау|шіл|там|қыр|қаз|қар|жел)/i,
  wide: /^(қаңтар|ақпан|наурыз|сәуір|мамыр|маусым|шілде|тамыз|қыркүйек|қазан|қараша|желтоқсан)/i
};
var parseMonthPatterns$D = {
  narrow: [/^қ/i, /^а/i, /^н/i, /^с/i, /^м/i, /^м/i, /^ш/i, /^т/i, /^қ/i, /^қ/i, /^қ/i, /^ж/i],
  abbreviated: [/^қаң/i, /^ақп/i, /^нау/i, /^сәу/i, /^мам/i, /^мау/i, /^шіл/i, /^там/i, /^қыр/i, /^қаз/i, /^қар/i, /^жел/i],
  any: [/^қ/i, /^а/i, /^н/i, /^с/i, /^м/i, /^м/i, /^ш/i, /^т/i, /^қ/i, /^қ/i, /^қ/i, /^ж/i]
};
var matchDayPatterns$D = {
  narrow: /^(ж|д|с|с|б|ж|с)/i,
  short: /^(жс|дс|сс|ср|бс|жм|сб)/i,
  wide: /^(жексенбі|дүйсенбі|сейсенбі|сәрсенбі|бейсенбі|жұма|сенбі)/i
};
var parseDayPatterns$D = {
  narrow: [/^ж/i, /^д/i, /^с/i, /^с/i, /^б/i, /^ж/i, /^с/i],
  short: [/^жс/i, /^дс/i, /^сс/i, /^ср/i, /^бс/i, /^жм/i, /^сб/i],
  any: [/^ж[ек]/i, /^д[үй]/i, /^сe[й]/i, /^сә[р]/i, /^б[ей]/i, /^ж[ұм]/i, /^се[н]/i]
};
var matchDayPeriodPatterns$D = {
  narrow: /^Т\.?\s?[ДК]\.?|түн ортасында|((түсте|таңертең|таңда|таңертең|таңмен|таң|күндіз|күн|кеште|кеш|түнде|түн)\.?)/i,
  wide: /^Т\.?\s?[ДК]\.?|түн ортасында|((түсте|таңертең|таңда|таңертең|таңмен|таң|күндіз|күн|кеште|кеш|түнде|түн)\.?)/i,
  any: /^Т\.?\s?[ДК]\.?|түн ортасында|((түсте|таңертең|таңда|таңертең|таңмен|таң|күндіз|күн|кеште|кеш|түнде|түн)\.?)/i
};
var parseDayPeriodPatterns$D = {
  any: {
    am: /^ТД/i,
    pm: /^ТК/i,
    midnight: /^түн орта/i,
    noon: /^күндіз/i,
    morning: /таң/i,
    afternoon: /түс/i,
    evening: /кеш/i,
    night: /түн/i
  }
};
match$D.match = {
  ordinalNumber: (0, _index2$1g.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$D,
    parsePattern: parseOrdinalNumberPattern$D,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$2A.buildMatchFn)({
    matchPatterns: matchEraPatterns$D,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$D,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$2A.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$D,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$D,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$2A.buildMatchFn)({
    matchPatterns: matchMonthPatterns$D,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$D,
    defaultParseWidth: "any"
  }),
  day: (0, _index$2A.buildMatchFn)({
    matchPatterns: matchDayPatterns$D,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$D,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$2A.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$D,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$D,
    defaultParseWidth: "any"
  })
};
kk.kk = void 0;
var _index$2z = formatDistance$1f;
var _index2$1f = formatLong$D;
var _index3$D = formatRelative$1f;
var _index4$D = localize$D;
var _index5$D = match$D;
kk.kk = {
  code: "kk",
  formatDistance: _index$2z.formatDistance,
  formatLong: _index2$1f.formatLong,
  formatRelative: _index3$D.formatRelative,
  localize: _index4$D.localize,
  match: _index5$D.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var km = {};
var formatDistance$1d = {};
formatDistance$1d.formatDistance = void 0;
var formatDistanceLocale$C = {
  lessThanXSeconds: "តិចជាង {{count}} វិនាទី",
  xSeconds: "{{count}} វិនាទី",
  halfAMinute: "កន្លះនាទី",
  lessThanXMinutes: "តិចជាង {{count}} នាទី",
  xMinutes: "{{count}} នាទី",
  aboutXHours: "ប្រហែល {{count}} ម៉ោង",
  xHours: "{{count}} ម៉ោង",
  xDays: "{{count}} ថ្ងៃ",
  aboutXWeeks: "ប្រហែល {{count}} សប្តាហ៍",
  xWeeks: "{{count}} សប្តាហ៍",
  aboutXMonths: "ប្រហែល {{count}} ខែ",
  xMonths: "{{count}} ខែ",
  aboutXYears: "ប្រហែល {{count}} ឆ្នាំ",
  xYears: "{{count}} ឆ្នាំ",
  overXYears: "ជាង {{count}} ឆ្នាំ",
  almostXYears: "ជិត {{count}} ឆ្នាំ"
};
var formatDistance$1c = function (e, a, t) {
  var r = formatDistanceLocale$C[e];
  var n = r;
  if (typeof a === "number") {
    n = n.replace("{{count}}", a.toString());
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "ក្នុងរយៈពេល " + n;
    } else {
      return n + "មុន";
    }
  }
  return n;
};
formatDistance$1d.formatDistance = formatDistance$1c;
var formatLong$C = {};
formatLong$C.formatLong = void 0;
var _index$2y = buildFormatLongFn;
var dateFormats$C = {
  full: "EEEE do MMMM y",
  long: "do MMMM y",
  medium: "d MMM y",
  short: "dd/MM/yyyy"
};
var timeFormats$C = {
  full: "h:mm:ss a",
  long: "h:mm:ss a",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$C = {
  full: "{{date}} 'ម៉ោង' {{time}}",
  long: "{{date}} 'ម៉ោង' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$C.formatLong = {
  date: (0, _index$2y.buildFormatLongFn)({
    formats: dateFormats$C,
    defaultWidth: "full"
  }),
  time: (0, _index$2y.buildFormatLongFn)({
    formats: timeFormats$C,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2y.buildFormatLongFn)({
    formats: dateTimeFormats$C,
    defaultWidth: "full"
  })
};
var formatRelative$1d = {};
formatRelative$1d.formatRelative = void 0;
var formatRelativeLocale$C = {
  lastWeek: "'ថ្ងៃ'eeee'ស​ប្តា​ហ៍​មុនម៉ោង' p",
  yesterday: "'ម្សិលមិញនៅម៉ោង' p",
  today: "'ថ្ងៃនេះម៉ោង' p",
  tomorrow: "'ថ្ងៃស្អែកម៉ោង' p",
  nextWeek: "'ថ្ងៃ'eeee'ស​ប្តា​ហ៍​ក្រោយម៉ោង' p",
  other: "P"
};
var formatRelative$1c = function (e, a, t, r) {
  return formatRelativeLocale$C[e];
};
formatRelative$1d.formatRelative = formatRelative$1c;
var localize$C = {};
localize$C.localize = void 0;
var _index$2x = buildLocalizeFn;
var eraValues$C = {
  narrow: ["ម.គស", "គស"],
  abbreviated: ["មុនគ.ស", "គ.ស"],
  wide: ["មុនគ្រិស្តសករាជ", "នៃគ្រិស្តសករាជ"]
};
var quarterValues$C = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["ត្រីមាសទី 1", "ត្រីមាសទី 2", "ត្រីមាសទី 3", "ត្រីមាសទី 4"]
};
var monthValues$C = {
  narrow: ["ម.ក", "ក.ម", "មិ", "ម.ស", "ឧ.ស", "ម.ថ", "ក.ដ", "សី", "កញ", "តុ", "វិ", "ធ"],
  abbreviated: ["មករា", "កុម្ភៈ", "មីនា", "មេសា", "ឧសភា", "មិថុនា", "កក្កដា", "សីហា", "កញ្ញា", "តុលា", "វិច្ឆិកា", "ធ្នូ"],
  wide: ["មករា", "កុម្ភៈ", "មីនា", "មេសា", "ឧសភា", "មិថុនា", "កក្កដា", "សីហា", "កញ្ញា", "តុលា", "វិច្ឆិកា", "ធ្នូ"]
};
var dayValues$C = {
  narrow: ["អា", "ច", "អ", "ព", "ព្រ", "សុ", "ស"],
  short: ["អា", "ច", "អ", "ព", "ព្រ", "សុ", "ស"],
  abbreviated: ["អា", "ច", "អ", "ព", "ព្រ", "សុ", "ស"],
  wide: ["អាទិត្យ", "ចន្ទ", "អង្គារ", "ពុធ", "ព្រហស្បតិ៍", "សុក្រ", "សៅរ៍"]
};
var dayPeriodValues$C = {
  narrow: {
    am: "ព្រឹក",
    pm: "ល្ងាច",
    midnight: "​ពេលកណ្ដាលអធ្រាត្រ",
    noon: "ពេលថ្ងៃត្រង់",
    morning: "ពេលព្រឹក",
    afternoon: "ពេលរសៀល",
    evening: "ពេលល្ងាច",
    night: "ពេលយប់"
  },
  abbreviated: {
    am: "ព្រឹក",
    pm: "ល្ងាច",
    midnight: "​ពេលកណ្ដាលអធ្រាត្រ",
    noon: "ពេលថ្ងៃត្រង់",
    morning: "ពេលព្រឹក",
    afternoon: "ពេលរសៀល",
    evening: "ពេលល្ងាច",
    night: "ពេលយប់"
  },
  wide: {
    am: "ព្រឹក",
    pm: "ល្ងាច",
    midnight: "​ពេលកណ្ដាលអធ្រាត្រ",
    noon: "ពេលថ្ងៃត្រង់",
    morning: "ពេលព្រឹក",
    afternoon: "ពេលរសៀល",
    evening: "ពេលល្ងាច",
    night: "ពេលយប់"
  }
};
var formattingDayPeriodValues$u = {
  narrow: {
    am: "ព្រឹក",
    pm: "ល្ងាច",
    midnight: "​ពេលកណ្ដាលអធ្រាត្រ",
    noon: "ពេលថ្ងៃត្រង់",
    morning: "ពេលព្រឹក",
    afternoon: "ពេលរសៀល",
    evening: "ពេលល្ងាច",
    night: "ពេលយប់"
  },
  abbreviated: {
    am: "ព្រឹក",
    pm: "ល្ងាច",
    midnight: "​ពេលកណ្ដាលអធ្រាត្រ",
    noon: "ពេលថ្ងៃត្រង់",
    morning: "ពេលព្រឹក",
    afternoon: "ពេលរសៀល",
    evening: "ពេលល្ងាច",
    night: "ពេលយប់"
  },
  wide: {
    am: "ព្រឹក",
    pm: "ល្ងាច",
    midnight: "​ពេលកណ្ដាលអធ្រាត្រ",
    noon: "ពេលថ្ងៃត្រង់",
    morning: "ពេលព្រឹក",
    afternoon: "ពេលរសៀល",
    evening: "ពេលល្ងាច",
    night: "ពេលយប់"
  }
};
var ordinalNumber$C = function (e, a) {
  var t = Number(e);
  return t.toString();
};
localize$C.localize = {
  ordinalNumber: ordinalNumber$C,
  era: (0, _index$2x.buildLocalizeFn)({
    values: eraValues$C,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2x.buildLocalizeFn)({
    values: quarterValues$C,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$2x.buildLocalizeFn)({
    values: monthValues$C,
    defaultWidth: "wide"
  }),
  day: (0, _index$2x.buildLocalizeFn)({
    values: dayValues$C,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$2x.buildLocalizeFn)({
    values: dayPeriodValues$C,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$u,
    defaultFormattingWidth: "wide"
  })
};
var match$C = {};
match$C.match = void 0;
var _index$2w = buildMatchFn;
var _index2$1e = buildMatchPatternFn;
var matchOrdinalNumberPattern$C = /^(\d+)(th|st|nd|rd)?/i;
var parseOrdinalNumberPattern$C = /\d+/i;
var matchEraPatterns$C = {
  narrow: /^(ម\.)?គស/i,
  abbreviated: /^(មុន)?គ\.ស/i,
  wide: /^(មុន|នៃ)គ្រិស្តសករាជ/i
};
var parseEraPatterns$C = {
  any: [/^(ម|មុន)គ\.?ស/i, /^(នៃ)?គ\.?ស/i]
};
var matchQuarterPatterns$C = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^(ត្រីមាស)(ទី)?\s?[1234]/i
};
var parseQuarterPatterns$C = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$C = {
  narrow: /^(ម\.ក|ក\.ម|មិ|ម\.ស|ឧ\.ស|ម\.ថ|ក\.ដ|សី|កញ|តុ|វិ|ធ)/i,
  abbreviated: /^(មករា|កុម្ភៈ|មីនា|មេសា|ឧសភា|មិថុនា|កក្កដា|សីហា|កញ្ញា|តុលា|វិច្ឆិកា|ធ្នូ)/i,
  wide: /^(មករា|កុម្ភៈ|មីនា|មេសា|ឧសភា|មិថុនា|កក្កដា|សីហា|កញ្ញា|តុលា|វិច្ឆិកា|ធ្នូ)/i
};
var parseMonthPatterns$C = {
  narrow: [/^ម\.ក/i, /^ក\.ម/i, /^មិ/i, /^ម\.ស/i, /^ឧ\.ស/i, /^ម\.ថ/i, /^ក\.ដ/i, /^សី/i, /^កញ/i, /^តុ/i, /^វិ/i, /^ធ/i],
  any: [/^មក/i, /^កុ/i, /^មីន/i, /^មេ/i, /^ឧស/i, /^មិថ/i, /^កក/i, /^សី/i, /^កញ/i, /^តុ/i, /^វិច/i, /^ធ/i]
};
var matchDayPatterns$C = {
  narrow: /^(អា|ច|អ|ព|ព្រ|សុ|ស)/i,
  short: /^(អា|ច|អ|ព|ព្រ|សុ|ស)/i,
  abbreviated: /^(អា|ច|អ|ព|ព្រ|សុ|ស)/i,
  wide: /^(អាទិត្យ|ចន្ទ|អង្គារ|ពុធ|ព្រហស្បតិ៍|សុក្រ|សៅរ៍)/i
};
var parseDayPatterns$C = {
  narrow: [/^អា/i, /^ច/i, /^អ/i, /^ព/i, /^ព្រ/i, /^សុ/i, /^ស/i],
  any: [/^អា/i, /^ច/i, /^អ/i, /^ព/i, /^ព្រ/i, /^សុ/i, /^សៅ/i]
};
var matchDayPeriodPatterns$C = {
  narrow: /^(ព្រឹក|ល្ងាច|ពេលព្រឹក|ពេលថ្ងៃត្រង់|ពេលល្ងាច|ពេលរសៀល|ពេលយប់|ពេលកណ្ដាលអធ្រាត្រ)/i,
  any: /^(ព្រឹក|ល្ងាច|ពេលព្រឹក|ពេលថ្ងៃត្រង់|ពេលល្ងាច|ពេលរសៀល|ពេលយប់|ពេលកណ្ដាលអធ្រាត្រ)/i
};
var parseDayPeriodPatterns$C = {
  any: {
    am: /^ព្រឹក/i,
    pm: /^ល្ងាច/i,
    midnight: /^ពេលកណ្ដាលអធ្រាត្រ/i,
    noon: /^ពេលថ្ងៃត្រង់/i,
    morning: /ពេលព្រឹក/i,
    afternoon: /ពេលរសៀល/i,
    evening: /ពេលល្ងាច/i,
    night: /ពេលយប់/i
  }
};
match$C.match = {
  ordinalNumber: (0, _index2$1e.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$C,
    parsePattern: parseOrdinalNumberPattern$C,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$2w.buildMatchFn)({
    matchPatterns: matchEraPatterns$C,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$C,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$2w.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$C,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$C,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$2w.buildMatchFn)({
    matchPatterns: matchMonthPatterns$C,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$C,
    defaultParseWidth: "any"
  }),
  day: (0, _index$2w.buildMatchFn)({
    matchPatterns: matchDayPatterns$C,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$C,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$2w.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$C,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$C,
    defaultParseWidth: "any"
  })
};
km.km = void 0;
var _index$2v = formatDistance$1d;
var _index2$1d = formatLong$C;
var _index3$C = formatRelative$1d;
var _index4$C = localize$C;
var _index5$C = match$C;
km.km = {
  code: "km",
  formatDistance: _index$2v.formatDistance,
  formatLong: _index2$1d.formatLong,
  formatRelative: _index3$C.formatRelative,
  localize: _index4$C.localize,
  match: _index5$C.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var kn = {};
var formatDistance$1b = {};
formatDistance$1b.formatDistance = void 0;
var formatDistanceLocale$B = {
  lessThanXSeconds: {
    one: {
      default: "1 ಸೆಕೆಂಡ್‌ಗಿಂತ ಕಡಿಮೆ",
      future: "1 ಸೆಕೆಂಡ್‌ಗಿಂತ ಕಡಿಮೆ",
      past: "1 ಸೆಕೆಂಡ್‌ಗಿಂತ ಕಡಿಮೆ"
    },
    other: {
      default: "{{count}} ಸೆಕೆಂಡ್‌ಗಿಂತ ಕಡಿಮೆ",
      future: "{{count}} ಸೆಕೆಂಡ್‌ಗಿಂತ ಕಡಿಮೆ",
      past: "{{count}} ಸೆಕೆಂಡ್‌ಗಿಂತ ಕಡಿಮೆ"
    }
  },
  xSeconds: {
    one: {
      default: "1 ಸೆಕೆಂಡ್",
      future: "1 ಸೆಕೆಂಡ್‌ನಲ್ಲಿ",
      past: "1 ಸೆಕೆಂಡ್ ಹಿಂದೆ"
    },
    other: {
      default: "{{count}} ಸೆಕೆಂಡುಗಳು",
      future: "{{count}} ಸೆಕೆಂಡ್‌ಗಳಲ್ಲಿ",
      past: "{{count}} ಸೆಕೆಂಡ್ ಹಿಂದೆ"
    }
  },
  halfAMinute: {
    other: {
      default: "ಅರ್ಧ ನಿಮಿಷ",
      future: "ಅರ್ಧ ನಿಮಿಷದಲ್ಲಿ",
      past: "ಅರ್ಧ ನಿಮಿಷದ ಹಿಂದೆ"
    }
  },
  lessThanXMinutes: {
    one: {
      default: "1 ನಿಮಿಷಕ್ಕಿಂತ ಕಡಿಮೆ",
      future: "1 ನಿಮಿಷಕ್ಕಿಂತ ಕಡಿಮೆ",
      past: "1 ನಿಮಿಷಕ್ಕಿಂತ ಕಡಿಮೆ"
    },
    other: {
      default: "{{count}} ನಿಮಿಷಕ್ಕಿಂತ ಕಡಿಮೆ",
      future: "{{count}} ನಿಮಿಷಕ್ಕಿಂತ ಕಡಿಮೆ",
      past: "{{count}} ನಿಮಿಷಕ್ಕಿಂತ ಕಡಿಮೆ"
    }
  },
  xMinutes: {
    one: {
      default: "1 ನಿಮಿಷ",
      future: "1 ನಿಮಿಷದಲ್ಲಿ",
      past: "1 ನಿಮಿಷದ ಹಿಂದೆ"
    },
    other: {
      default: "{{count}} ನಿಮಿಷಗಳು",
      future: "{{count}} ನಿಮಿಷಗಳಲ್ಲಿ",
      past: "{{count}} ನಿಮಿಷಗಳ ಹಿಂದೆ"
    }
  },
  aboutXHours: {
    one: {
      default: "ಸುಮಾರು 1 ಗಂಟೆ",
      future: "ಸುಮಾರು 1 ಗಂಟೆಯಲ್ಲಿ",
      past: "ಸುಮಾರು 1 ಗಂಟೆ ಹಿಂದೆ"
    },
    other: {
      default: "ಸುಮಾರು {{count}} ಗಂಟೆಗಳು",
      future: "ಸುಮಾರು {{count}} ಗಂಟೆಗಳಲ್ಲಿ",
      past: "ಸುಮಾರು {{count}} ಗಂಟೆಗಳ ಹಿಂದೆ"
    }
  },
  xHours: {
    one: {
      default: "1 ಗಂಟೆ",
      future: "1 ಗಂಟೆಯಲ್ಲಿ",
      past: "1 ಗಂಟೆ ಹಿಂದೆ"
    },
    other: {
      default: "{{count}} ಗಂಟೆಗಳು",
      future: "{{count}} ಗಂಟೆಗಳಲ್ಲಿ",
      past: "{{count}} ಗಂಟೆಗಳ ಹಿಂದೆ"
    }
  },
  xDays: {
    one: {
      default: "1 ದಿನ",
      future: "1 ದಿನದಲ್ಲಿ",
      past: "1 ದಿನದ ಹಿಂದೆ"
    },
    other: {
      default: "{{count}} ದಿನಗಳು",
      future: "{{count}} ದಿನಗಳಲ್ಲಿ",
      past: "{{count}} ದಿನಗಳ ಹಿಂದೆ"
    }
  },
  aboutXMonths: {
    one: {
      default: "ಸುಮಾರು 1 ತಿಂಗಳು",
      future: "ಸುಮಾರು 1 ತಿಂಗಳಲ್ಲಿ",
      past: "ಸುಮಾರು 1 ತಿಂಗಳ ಹಿಂದೆ"
    },
    other: {
      default: "ಸುಮಾರು {{count}} ತಿಂಗಳು",
      future: "ಸುಮಾರು {{count}} ತಿಂಗಳುಗಳಲ್ಲಿ",
      past: "ಸುಮಾರು {{count}} ತಿಂಗಳುಗಳ ಹಿಂದೆ"
    }
  },
  xMonths: {
    one: {
      default: "1 ತಿಂಗಳು",
      future: "1 ತಿಂಗಳಲ್ಲಿ",
      past: "1 ತಿಂಗಳ ಹಿಂದೆ"
    },
    other: {
      default: "{{count}} ತಿಂಗಳು",
      future: "{{count}} ತಿಂಗಳುಗಳಲ್ಲಿ",
      past: "{{count}} ತಿಂಗಳುಗಳ ಹಿಂದೆ"
    }
  },
  aboutXYears: {
    one: {
      default: "ಸುಮಾರು 1 ವರ್ಷ",
      future: "ಸುಮಾರು 1 ವರ್ಷದಲ್ಲಿ",
      past: "ಸುಮಾರು 1 ವರ್ಷದ ಹಿಂದೆ"
    },
    other: {
      default: "ಸುಮಾರು {{count}} ವರ್ಷಗಳು",
      future: "ಸುಮಾರು {{count}} ವರ್ಷಗಳಲ್ಲಿ",
      past: "ಸುಮಾರು {{count}} ವರ್ಷಗಳ ಹಿಂದೆ"
    }
  },
  xYears: {
    one: {
      default: "1 ವರ್ಷ",
      future: "1 ವರ್ಷದಲ್ಲಿ",
      past: "1 ವರ್ಷದ ಹಿಂದೆ"
    },
    other: {
      default: "{{count}} ವರ್ಷಗಳು",
      future: "{{count}} ವರ್ಷಗಳಲ್ಲಿ",
      past: "{{count}} ವರ್ಷಗಳ ಹಿಂದೆ"
    }
  },
  overXYears: {
    one: {
      default: "1 ವರ್ಷದ ಮೇಲೆ",
      future: "1 ವರ್ಷದ ಮೇಲೆ",
      past: "1 ವರ್ಷದ ಮೇಲೆ"
    },
    other: {
      default: "{{count}} ವರ್ಷಗಳ ಮೇಲೆ",
      future: "{{count}} ವರ್ಷಗಳ ಮೇಲೆ",
      past: "{{count}} ವರ್ಷಗಳ ಮೇಲೆ"
    }
  },
  almostXYears: {
    one: {
      default: "ಬಹುತೇಕ 1 ವರ್ಷದಲ್ಲಿ",
      future: "ಬಹುತೇಕ 1 ವರ್ಷದಲ್ಲಿ",
      past: "ಬಹುತೇಕ 1 ವರ್ಷದಲ್ಲಿ"
    },
    other: {
      default: "ಬಹುತೇಕ {{count}} ವರ್ಷಗಳಲ್ಲಿ",
      future: "ಬಹುತೇಕ {{count}} ವರ್ಷಗಳಲ್ಲಿ",
      past: "ಬಹುತೇಕ {{count}} ವರ್ಷಗಳಲ್ಲಿ"
    }
  }
};
function getResultByTense(e, a) {
  if (a === null || a === void 0 ? void 0 : a.addSuffix) {
    if (a.comparison && a.comparison > 0) {
      return e.future;
    } else {
      return e.past;
    }
  }
  return e.default;
}
var formatDistance$1a = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$B[e];
  if (n.one && a === 1) {
    r = getResultByTense(n.one, t);
  } else {
    r = getResultByTense(n.other, t);
  }
  return r.replace("{{count}}", String(a));
};
formatDistance$1b.formatDistance = formatDistance$1a;
var formatLong$B = {};
formatLong$B.formatLong = void 0;
var _index$2u = buildFormatLongFn;
var dateFormats$B = {
  full: "EEEE, MMMM d, y",
  long: "MMMM d, y",
  medium: "MMM d, y",
  short: "d/M/yy"
};
var timeFormats$B = {
  full: "hh:mm:ss a zzzz",
  long: "hh:mm:ss a z",
  medium: "hh:mm:ss a",
  short: "hh:mm a"
};
var dateTimeFormats$B = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$B.formatLong = {
  date: (0, _index$2u.buildFormatLongFn)({
    formats: dateFormats$B,
    defaultWidth: "full"
  }),
  time: (0, _index$2u.buildFormatLongFn)({
    formats: timeFormats$B,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2u.buildFormatLongFn)({
    formats: dateTimeFormats$B,
    defaultWidth: "full"
  })
};
var formatRelative$1b = {};
formatRelative$1b.formatRelative = void 0;
var formatRelativeLocale$B = {
  lastWeek: "'ಕಳೆದ' eeee p 'ಕ್ಕೆ'",
  yesterday: "'ನಿನ್ನೆ' p 'ಕ್ಕೆ'",
  today: "'ಇಂದು' p 'ಕ್ಕೆ'",
  tomorrow: "'ನಾಳೆ' p 'ಕ್ಕೆ'",
  nextWeek: "eeee p 'ಕ್ಕೆ'",
  other: "P"
};
var formatRelative$1a = function (e, a, t, r) {
  return formatRelativeLocale$B[e];
};
formatRelative$1b.formatRelative = formatRelative$1a;
var localize$B = {};
localize$B.localize = void 0;
var _index$2t = buildLocalizeFn;
var eraValues$B = {
  narrow: ["ಕ್ರಿ.ಪೂ", "ಕ್ರಿ.ಶ"],
  abbreviated: ["ಕ್ರಿ.ಪೂ", "ಕ್ರಿ.ಶ"],
  wide: ["ಕ್ರಿಸ್ತ ಪೂರ್ವ", "ಕ್ರಿಸ್ತ ಶಕ"]
};
var quarterValues$B = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["ತ್ರೈ 1", "ತ್ರೈ 2", "ತ್ರೈ 3", "ತ್ರೈ 4"],
  wide: ["1ನೇ ತ್ರೈಮಾಸಿಕ", "2ನೇ ತ್ರೈಮಾಸಿಕ", "3ನೇ ತ್ರೈಮಾಸಿಕ", "4ನೇ ತ್ರೈಮಾಸಿಕ"]
};
var monthValues$B = {
  narrow: ["ಜ", "ಫೆ", "ಮಾ", "ಏ", "ಮೇ", "ಜೂ", "ಜು", "ಆ", "ಸೆ", "ಅ", "ನ", "ಡಿ"],
  abbreviated: ["ಜನ", "ಫೆಬ್ರ", "ಮಾರ್ಚ್", "ಏಪ್ರಿ", "ಮೇ", "ಜೂನ್", "ಜುಲೈ", "ಆಗ", "ಸೆಪ್ಟೆಂ", "ಅಕ್ಟೋ", "ನವೆಂ", "ಡಿಸೆಂ"],
  wide: ["ಜನವರಿ", "ಫೆಬ್ರವರಿ", "ಮಾರ್ಚ್", "ಏಪ್ರಿಲ್", "ಮೇ", "ಜೂನ್", "ಜುಲೈ", "ಆಗಸ್ಟ್", "ಸೆಪ್ಟೆಂಬರ್", "ಅಕ್ಟೋಬರ್", "ನವೆಂಬರ್", "ಡಿಸೆಂಬರ್"]
};
var dayValues$B = {
  narrow: ["ಭಾ", "ಸೋ", "ಮಂ", "ಬು", "ಗು", "ಶು", "ಶ"],
  short: ["ಭಾನು", "ಸೋಮ", "ಮಂಗಳ", "ಬುಧ", "ಗುರು", "ಶುಕ್ರ", "ಶನಿ"],
  abbreviated: ["ಭಾನು", "ಸೋಮ", "ಮಂಗಳ", "ಬುಧ", "ಗುರು", "ಶುಕ್ರ", "ಶನಿ"],
  wide: ["ಭಾನುವಾರ", "ಸೋಮವಾರ", "ಮಂಗಳವಾರ", "ಬುಧವಾರ", "ಗುರುವಾರ", "ಶುಕ್ರವಾರ", "ಶನಿವಾರ"]
};
var dayPeriodValues$B = {
  narrow: {
    am: "ಪೂರ್ವಾಹ್ನ",
    pm: "ಅಪರಾಹ್ನ",
    midnight: "ಮಧ್ಯರಾತ್ರಿ",
    noon: "ಮಧ್ಯಾಹ್ನ",
    morning: "ಬೆಳಗ್ಗೆ",
    afternoon: "ಮಧ್ಯಾಹ್ನ",
    evening: "ಸಂಜೆ",
    night: "ರಾತ್ರಿ"
  },
  abbreviated: {
    am: "ಪೂರ್ವಾಹ್ನ",
    pm: "ಅಪರಾಹ್ನ",
    midnight: "ಮಧ್ಯರಾತ್ರಿ",
    noon: "ಮಧ್ಯಾನ್ಹ",
    morning: "ಬೆಳಗ್ಗೆ",
    afternoon: "ಮಧ್ಯಾನ್ಹ",
    evening: "ಸಂಜೆ",
    night: "ರಾತ್ರಿ"
  },
  wide: {
    am: "ಪೂರ್ವಾಹ್ನ",
    pm: "ಅಪರಾಹ್ನ",
    midnight: "ಮಧ್ಯರಾತ್ರಿ",
    noon: "ಮಧ್ಯಾನ್ಹ",
    morning: "ಬೆಳಗ್ಗೆ",
    afternoon: "ಮಧ್ಯಾನ್ಹ",
    evening: "ಸಂಜೆ",
    night: "ರಾತ್ರಿ"
  }
};
var formattingDayPeriodValues$t = {
  narrow: {
    am: "ಪೂ",
    pm: "ಅ",
    midnight: "ಮಧ್ಯರಾತ್ರಿ",
    noon: "ಮಧ್ಯಾನ್ಹ",
    morning: "ಬೆಳಗ್ಗೆ",
    afternoon: "ಮಧ್ಯಾನ್ಹ",
    evening: "ಸಂಜೆ",
    night: "ರಾತ್ರಿ"
  },
  abbreviated: {
    am: "ಪೂರ್ವಾಹ್ನ",
    pm: "ಅಪರಾಹ್ನ",
    midnight: "ಮಧ್ಯ ರಾತ್ರಿ",
    noon: "ಮಧ್ಯಾನ್ಹ",
    morning: "ಬೆಳಗ್ಗೆ",
    afternoon: "ಮಧ್ಯಾನ್ಹ",
    evening: "ಸಂಜೆ",
    night: "ರಾತ್ರಿ"
  },
  wide: {
    am: "ಪೂರ್ವಾಹ್ನ",
    pm: "ಅಪರಾಹ್ನ",
    midnight: "ಮಧ್ಯ ರಾತ್ರಿ",
    noon: "ಮಧ್ಯಾನ್ಹ",
    morning: "ಬೆಳಗ್ಗೆ",
    afternoon: "ಮಧ್ಯಾನ್ಹ",
    evening: "ಸಂಜೆ",
    night: "ರಾತ್ರಿ"
  }
};
var ordinalNumber$B = function (e, a) {
  var t = Number(e);
  return t + "ನೇ";
};
localize$B.localize = {
  ordinalNumber: ordinalNumber$B,
  era: (0, _index$2t.buildLocalizeFn)({
    values: eraValues$B,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2t.buildLocalizeFn)({
    values: quarterValues$B,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$2t.buildLocalizeFn)({
    values: monthValues$B,
    defaultWidth: "wide"
  }),
  day: (0, _index$2t.buildLocalizeFn)({
    values: dayValues$B,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$2t.buildLocalizeFn)({
    values: dayPeriodValues$B,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$t,
    defaultFormattingWidth: "wide"
  })
};
var match$B = {};
match$B.match = void 0;
var _index$2s = buildMatchFn;
var _index2$1c = buildMatchPatternFn;
var matchOrdinalNumberPattern$B = /^(\d+)(ನೇ|ನೆ)?/i;
var parseOrdinalNumberPattern$B = /\d+/i;
var matchEraPatterns$B = {
  narrow: /^(ಕ್ರಿ.ಪೂ|ಕ್ರಿ.ಶ)/i,
  abbreviated: /^(ಕ್ರಿ\.?\s?ಪೂ\.?|ಕ್ರಿ\.?\s?ಶ\.?|ಪ್ರ\.?\s?ಶ\.?)/i,
  wide: /^(ಕ್ರಿಸ್ತ ಪೂರ್ವ|ಕ್ರಿಸ್ತ ಶಕ|ಪ್ರಸಕ್ತ ಶಕ)/i
};
var parseEraPatterns$B = {
  any: [/^ಪೂ/i, /^(ಶ|ಪ್ರ)/i]
};
var matchQuarterPatterns$B = {
  narrow: /^[1234]/i,
  abbreviated: /^ತ್ರೈ[1234]|ತ್ರೈ [1234]| [1234]ತ್ರೈ/i,
  wide: /^[1234](ನೇ)? ತ್ರೈಮಾಸಿಕ/i
};
var parseQuarterPatterns$B = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$B = {
  narrow: /^(ಜೂ|ಜು|ಜ|ಫೆ|ಮಾ|ಏ|ಮೇ|ಆ|ಸೆ|ಅ|ನ|ಡಿ)/i,
  abbreviated: /^(ಜನ|ಫೆಬ್ರ|ಮಾರ್ಚ್|ಏಪ್ರಿ|ಮೇ|ಜೂನ್|ಜುಲೈ|ಆಗ|ಸೆಪ್ಟೆಂ|ಅಕ್ಟೋ|ನವೆಂ|ಡಿಸೆಂ)/i,
  wide: /^(ಜನವರಿ|ಫೆಬ್ರವರಿ|ಮಾರ್ಚ್|ಏಪ್ರಿಲ್|ಮೇ|ಜೂನ್|ಜುಲೈ|ಆಗಸ್ಟ್|ಸೆಪ್ಟೆಂಬರ್|ಅಕ್ಟೋಬರ್|ನವೆಂಬರ್|ಡಿಸೆಂಬರ್)/i
};
var parseMonthPatterns$B = {
  narrow: [/^ಜ$/i, /^ಫೆ/i, /^ಮಾ/i, /^ಏ/i, /^ಮೇ/i, /^ಜೂ/i, /^ಜು$/i, /^ಆ/i, /^ಸೆ/i, /^ಅ/i, /^ನ/i, /^ಡಿ/i],
  any: [/^ಜನ/i, /^ಫೆ/i, /^ಮಾ/i, /^ಏ/i, /^ಮೇ/i, /^ಜೂನ್/i, /^ಜುಲೈ/i, /^ಆ/i, /^ಸೆ/i, /^ಅ/i, /^ನ/i, /^ಡಿ/i]
};
var matchDayPatterns$B = {
  narrow: /^(ಭಾ|ಸೋ|ಮ|ಬು|ಗು|ಶು|ಶ)/i,
  short: /^(ಭಾನು|ಸೋಮ|ಮಂಗಳ|ಬುಧ|ಗುರು|ಶುಕ್ರ|ಶನಿ)/i,
  abbreviated: /^(ಭಾನು|ಸೋಮ|ಮಂಗಳ|ಬುಧ|ಗುರು|ಶುಕ್ರ|ಶನಿ)/i,
  wide: /^(ಭಾನುವಾರ|ಸೋಮವಾರ|ಮಂಗಳವಾರ|ಬುಧವಾರ|ಗುರುವಾರ|ಶುಕ್ರವಾರ|ಶನಿವಾರ)/i
};
var parseDayPatterns$B = {
  narrow: [/^ಭಾ/i, /^ಸೋ/i, /^ಮ/i, /^ಬು/i, /^ಗು/i, /^ಶು/i, /^ಶ/i],
  any: [/^ಭಾ/i, /^ಸೋ/i, /^ಮ/i, /^ಬು/i, /^ಗು/i, /^ಶು/i, /^ಶ/i]
};
var matchDayPeriodPatterns$B = {
  narrow: /^(ಪೂ|ಅ|ಮಧ್ಯರಾತ್ರಿ|ಮಧ್ಯಾನ್ಹ|ಬೆಳಗ್ಗೆ|ಸಂಜೆ|ರಾತ್ರಿ)/i,
  any: /^(ಪೂರ್ವಾಹ್ನ|ಅಪರಾಹ್ನ|ಮಧ್ಯರಾತ್ರಿ|ಮಧ್ಯಾನ್ಹ|ಬೆಳಗ್ಗೆ|ಸಂಜೆ|ರಾತ್ರಿ)/i
};
var parseDayPeriodPatterns$B = {
  any: {
    am: /^ಪೂ/i,
    pm: /^ಅ/i,
    midnight: /ಮಧ್ಯರಾತ್ರಿ/i,
    noon: /ಮಧ್ಯಾನ್ಹ/i,
    morning: /ಬೆಳಗ್ಗೆ/i,
    afternoon: /ಮಧ್ಯಾನ್ಹ/i,
    evening: /ಸಂಜೆ/i,
    night: /ರಾತ್ರಿ/i
  }
};
match$B.match = {
  ordinalNumber: (0, _index2$1c.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$B,
    parsePattern: parseOrdinalNumberPattern$B,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$2s.buildMatchFn)({
    matchPatterns: matchEraPatterns$B,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$B,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$2s.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$B,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$B,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$2s.buildMatchFn)({
    matchPatterns: matchMonthPatterns$B,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$B,
    defaultParseWidth: "any"
  }),
  day: (0, _index$2s.buildMatchFn)({
    matchPatterns: matchDayPatterns$B,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$B,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$2s.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$B,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$B,
    defaultParseWidth: "any"
  })
};
kn.kn = void 0;
var _index$2r = formatDistance$1b;
var _index2$1b = formatLong$B;
var _index3$B = formatRelative$1b;
var _index4$B = localize$B;
var _index5$B = match$B;
kn.kn = {
  code: "kn",
  formatDistance: _index$2r.formatDistance,
  formatLong: _index2$1b.formatLong,
  formatRelative: _index3$B.formatRelative,
  localize: _index4$B.localize,
  match: _index5$B.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var ko = {};
var formatDistance$19 = {};
formatDistance$19.formatDistance = void 0;
var formatDistanceLocale$A = {
  lessThanXSeconds: {
    one: "1초 미만",
    other: "{{count}}초 미만"
  },
  xSeconds: {
    one: "1초",
    other: "{{count}}초"
  },
  halfAMinute: "30초",
  lessThanXMinutes: {
    one: "1분 미만",
    other: "{{count}}분 미만"
  },
  xMinutes: {
    one: "1분",
    other: "{{count}}분"
  },
  aboutXHours: {
    one: "약 1시간",
    other: "약 {{count}}시간"
  },
  xHours: {
    one: "1시간",
    other: "{{count}}시간"
  },
  xDays: {
    one: "1일",
    other: "{{count}}일"
  },
  aboutXWeeks: {
    one: "약 1주",
    other: "약 {{count}}주"
  },
  xWeeks: {
    one: "1주",
    other: "{{count}}주"
  },
  aboutXMonths: {
    one: "약 1개월",
    other: "약 {{count}}개월"
  },
  xMonths: {
    one: "1개월",
    other: "{{count}}개월"
  },
  aboutXYears: {
    one: "약 1년",
    other: "약 {{count}}년"
  },
  xYears: {
    one: "1년",
    other: "{{count}}년"
  },
  overXYears: {
    one: "1년 이상",
    other: "{{count}}년 이상"
  },
  almostXYears: {
    one: "거의 1년",
    other: "거의 {{count}}년"
  }
};
var formatDistance$18 = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$A[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", a.toString());
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + " 후";
    } else {
      return r + " 전";
    }
  }
  return r;
};
formatDistance$19.formatDistance = formatDistance$18;
var formatLong$A = {};
formatLong$A.formatLong = void 0;
var _index$2q = buildFormatLongFn;
var dateFormats$A = {
  full: "y년 M월 d일 EEEE",
  long: "y년 M월 d일",
  medium: "y.MM.dd",
  short: "y.MM.dd"
};
var timeFormats$A = {
  full: "a H시 mm분 ss초 zzzz",
  long: "a H:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$A = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$A.formatLong = {
  date: (0, _index$2q.buildFormatLongFn)({
    formats: dateFormats$A,
    defaultWidth: "full"
  }),
  time: (0, _index$2q.buildFormatLongFn)({
    formats: timeFormats$A,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2q.buildFormatLongFn)({
    formats: dateTimeFormats$A,
    defaultWidth: "full"
  })
};
var formatRelative$19 = {};
formatRelative$19.formatRelative = void 0;
var formatRelativeLocale$A = {
  lastWeek: "'지난' eeee p",
  yesterday: "'어제' p",
  today: "'오늘' p",
  tomorrow: "'내일' p",
  nextWeek: "'다음' eeee p",
  other: "P"
};
var formatRelative$18 = function (e, a, t, r) {
  return formatRelativeLocale$A[e];
};
formatRelative$19.formatRelative = formatRelative$18;
var localize$A = {};
localize$A.localize = void 0;
var _index$2p = buildLocalizeFn;
var eraValues$A = {
  narrow: ["BC", "AD"],
  abbreviated: ["BC", "AD"],
  wide: ["기원전", "서기"]
};
var quarterValues$A = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1분기", "2분기", "3분기", "4분기"]
};
var monthValues$A = {
  narrow: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
  abbreviated: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"],
  wide: ["1월", "2월", "3월", "4월", "5월", "6월", "7월", "8월", "9월", "10월", "11월", "12월"]
};
var dayValues$A = {
  narrow: ["일", "월", "화", "수", "목", "금", "토"],
  short: ["일", "월", "화", "수", "목", "금", "토"],
  abbreviated: ["일", "월", "화", "수", "목", "금", "토"],
  wide: ["일요일", "월요일", "화요일", "수요일", "목요일", "금요일", "토요일"]
};
var dayPeriodValues$A = {
  narrow: {
    am: "오전",
    pm: "오후",
    midnight: "자정",
    noon: "정오",
    morning: "아침",
    afternoon: "오후",
    evening: "저녁",
    night: "밤"
  },
  abbreviated: {
    am: "오전",
    pm: "오후",
    midnight: "자정",
    noon: "정오",
    morning: "아침",
    afternoon: "오후",
    evening: "저녁",
    night: "밤"
  },
  wide: {
    am: "오전",
    pm: "오후",
    midnight: "자정",
    noon: "정오",
    morning: "아침",
    afternoon: "오후",
    evening: "저녁",
    night: "밤"
  }
};
var formattingDayPeriodValues$s = {
  narrow: {
    am: "오전",
    pm: "오후",
    midnight: "자정",
    noon: "정오",
    morning: "아침",
    afternoon: "오후",
    evening: "저녁",
    night: "밤"
  },
  abbreviated: {
    am: "오전",
    pm: "오후",
    midnight: "자정",
    noon: "정오",
    morning: "아침",
    afternoon: "오후",
    evening: "저녁",
    night: "밤"
  },
  wide: {
    am: "오전",
    pm: "오후",
    midnight: "자정",
    noon: "정오",
    morning: "아침",
    afternoon: "오후",
    evening: "저녁",
    night: "밤"
  }
};
var ordinalNumber$A = function (e, a) {
  var t = Number(e);
  var r = String(a === null || a === void 0 ? void 0 : a.unit);
  switch (r) {
    case "minute":
    case "second":
      return String(t);
    case "date":
      return t + "일";
    default:
      return t + "번째";
  }
};
localize$A.localize = {
  ordinalNumber: ordinalNumber$A,
  era: (0, _index$2p.buildLocalizeFn)({
    values: eraValues$A,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2p.buildLocalizeFn)({
    values: quarterValues$A,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$2p.buildLocalizeFn)({
    values: monthValues$A,
    defaultWidth: "wide"
  }),
  day: (0, _index$2p.buildLocalizeFn)({
    values: dayValues$A,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$2p.buildLocalizeFn)({
    values: dayPeriodValues$A,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$s,
    defaultFormattingWidth: "wide"
  })
};
var match$A = {};
match$A.match = void 0;
var _index$2o = buildMatchFn;
var _index2$1a = buildMatchPatternFn;
var matchOrdinalNumberPattern$A = /^(\d+)(일|번째)?/i;
var parseOrdinalNumberPattern$A = /\d+/i;
var matchEraPatterns$A = {
  narrow: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(기원전|서기)/i
};
var parseEraPatterns$A = {
  any: [/^(bc|기원전)/i, /^(ad|서기)/i]
};
var matchQuarterPatterns$A = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234]사?분기/i
};
var parseQuarterPatterns$A = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$A = {
  narrow: /^(1[012]|[123456789])/,
  abbreviated: /^(1[012]|[123456789])월/i,
  wide: /^(1[012]|[123456789])월/i
};
var parseMonthPatterns$A = {
  any: [/^1월?$/, /^2/, /^3/, /^4/, /^5/, /^6/, /^7/, /^8/, /^9/, /^10/, /^11/, /^12/]
};
var matchDayPatterns$A = {
  narrow: /^[일월화수목금토]/,
  short: /^[일월화수목금토]/,
  abbreviated: /^[일월화수목금토]/,
  wide: /^[일월화수목금토]요일/
};
var parseDayPatterns$A = {
  any: [/^일/, /^월/, /^화/, /^수/, /^목/, /^금/, /^토/]
};
var matchDayPeriodPatterns$A = {
  any: /^(am|pm|오전|오후|자정|정오|아침|저녁|밤)/i
};
var parseDayPeriodPatterns$A = {
  any: {
    am: /^(am|오전)/i,
    pm: /^(pm|오후)/i,
    midnight: /^자정/i,
    noon: /^정오/i,
    morning: /^아침/i,
    afternoon: /^오후/i,
    evening: /^저녁/i,
    night: /^밤/i
  }
};
match$A.match = {
  ordinalNumber: (0, _index2$1a.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$A,
    parsePattern: parseOrdinalNumberPattern$A,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$2o.buildMatchFn)({
    matchPatterns: matchEraPatterns$A,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$A,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$2o.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$A,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$A,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$2o.buildMatchFn)({
    matchPatterns: matchMonthPatterns$A,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$A,
    defaultParseWidth: "any"
  }),
  day: (0, _index$2o.buildMatchFn)({
    matchPatterns: matchDayPatterns$A,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$A,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$2o.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$A,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$A,
    defaultParseWidth: "any"
  })
};
ko.ko = void 0;
var _index$2n = formatDistance$19;
var _index2$19 = formatLong$A;
var _index3$A = formatRelative$19;
var _index4$A = localize$A;
var _index5$A = match$A;
ko.ko = {
  code: "ko",
  formatDistance: _index$2n.formatDistance,
  formatLong: _index2$19.formatLong,
  formatRelative: _index3$A.formatRelative,
  localize: _index4$A.localize,
  match: _index5$A.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var lb = {};
var formatDistance$17 = {};
formatDistance$17.formatDistance = void 0;
var formatDistanceLocale$z = {
  lessThanXSeconds: {
    standalone: {
      one: "manner wéi eng Sekonn",
      other: "manner wéi {{count}} Sekonnen"
    },
    withPreposition: {
      one: "manner wéi enger Sekonn",
      other: "manner wéi {{count}} Sekonnen"
    }
  },
  xSeconds: {
    standalone: {
      one: "eng Sekonn",
      other: "{{count}} Sekonnen"
    },
    withPreposition: {
      one: "enger Sekonn",
      other: "{{count}} Sekonnen"
    }
  },
  halfAMinute: {
    standalone: "eng hallef Minutt",
    withPreposition: "enger hallwer Minutt"
  },
  lessThanXMinutes: {
    standalone: {
      one: "manner wéi eng Minutt",
      other: "manner wéi {{count}} Minutten"
    },
    withPreposition: {
      one: "manner wéi enger Minutt",
      other: "manner wéi {{count}} Minutten"
    }
  },
  xMinutes: {
    standalone: {
      one: "eng Minutt",
      other: "{{count}} Minutten"
    },
    withPreposition: {
      one: "enger Minutt",
      other: "{{count}} Minutten"
    }
  },
  aboutXHours: {
    standalone: {
      one: "ongeféier eng Stonn",
      other: "ongeféier {{count}} Stonnen"
    },
    withPreposition: {
      one: "ongeféier enger Stonn",
      other: "ongeféier {{count}} Stonnen"
    }
  },
  xHours: {
    standalone: {
      one: "eng Stonn",
      other: "{{count}} Stonnen"
    },
    withPreposition: {
      one: "enger Stonn",
      other: "{{count}} Stonnen"
    }
  },
  xDays: {
    standalone: {
      one: "een Dag",
      other: "{{count}} Deeg"
    },
    withPreposition: {
      one: "engem Dag",
      other: "{{count}} Deeg"
    }
  },
  aboutXWeeks: {
    standalone: {
      one: "ongeféier eng Woch",
      other: "ongeféier {{count}} Wochen"
    },
    withPreposition: {
      one: "ongeféier enger Woche",
      other: "ongeféier {{count}} Wochen"
    }
  },
  xWeeks: {
    standalone: {
      one: "eng Woch",
      other: "{{count}} Wochen"
    },
    withPreposition: {
      one: "enger Woch",
      other: "{{count}} Wochen"
    }
  },
  aboutXMonths: {
    standalone: {
      one: "ongeféier ee Mount",
      other: "ongeféier {{count}} Méint"
    },
    withPreposition: {
      one: "ongeféier engem Mount",
      other: "ongeféier {{count}} Méint"
    }
  },
  xMonths: {
    standalone: {
      one: "ee Mount",
      other: "{{count}} Méint"
    },
    withPreposition: {
      one: "engem Mount",
      other: "{{count}} Méint"
    }
  },
  aboutXYears: {
    standalone: {
      one: "ongeféier ee Joer",
      other: "ongeféier {{count}} Joer"
    },
    withPreposition: {
      one: "ongeféier engem Joer",
      other: "ongeféier {{count}} Joer"
    }
  },
  xYears: {
    standalone: {
      one: "ee Joer",
      other: "{{count}} Joer"
    },
    withPreposition: {
      one: "engem Joer",
      other: "{{count}} Joer"
    }
  },
  overXYears: {
    standalone: {
      one: "méi wéi ee Joer",
      other: "méi wéi {{count}} Joer"
    },
    withPreposition: {
      one: "méi wéi engem Joer",
      other: "méi wéi {{count}} Joer"
    }
  },
  almostXYears: {
    standalone: {
      one: "bal ee Joer",
      other: "bal {{count}} Joer"
    },
    withPreposition: {
      one: "bal engem Joer",
      other: "bal {{count}} Joer"
    }
  }
};
var EXCEPTION_CONSONANTS = ["d", "h", "n", "t", "z"];
var VOWELS = ["a,", "e", "i", "o", "u"];
var DIGITS_SPOKEN_N_NEEDED = [0, 1, 2, 3, 8, 9];
var FIRST_TWO_DIGITS_SPOKEN_NO_N_NEEDED = [40, 50, 60, 70];
function isFinalNNeeded(e) {
  var a = e.charAt(0).toLowerCase();
  if (VOWELS.indexOf(a) != -1 || EXCEPTION_CONSONANTS.indexOf(a) != -1) {
    return true;
  }
  var t = e.split(" ")[0];
  var r = parseInt(t);
  if (!isNaN(r) && DIGITS_SPOKEN_N_NEEDED.indexOf(r % 10) != -1 && FIRST_TWO_DIGITS_SPOKEN_NO_N_NEEDED.indexOf(parseInt(t.substring(0, 2))) == -1) {
    return true;
  }
  return false;
}
var formatDistance$16 = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$z[e];
  var i = (t === null || t === void 0 ? void 0 : t.addSuffix) ? n.withPreposition : n.standalone;
  if (typeof i === "string") {
    r = i;
  } else if (a === 1) {
    r = i.one;
  } else {
    r = i.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "a" + (isFinalNNeeded(r) ? "n" : "") + " " + r;
    } else {
      return "viru" + (isFinalNNeeded(r) ? "n" : "") + " " + r;
    }
  }
  return r;
};
formatDistance$17.formatDistance = formatDistance$16;
var formatLong$z = {};
formatLong$z.formatLong = void 0;
var _index$2m = buildFormatLongFn;
var dateFormats$z = {
  full: "EEEE, do MMMM y",
  long: "do MMMM y",
  medium: "do MMM y",
  short: "dd.MM.yy"
};
var timeFormats$z = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$z = {
  full: "{{date}} 'um' {{time}}",
  long: "{{date}} 'um' {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$z.formatLong = {
  date: (0, _index$2m.buildFormatLongFn)({
    formats: dateFormats$z,
    defaultWidth: "full"
  }),
  time: (0, _index$2m.buildFormatLongFn)({
    formats: timeFormats$z,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2m.buildFormatLongFn)({
    formats: dateTimeFormats$z,
    defaultWidth: "full"
  })
};
var formatRelative$17 = {};
formatRelative$17.formatRelative = void 0;
var formatRelativeLocale$z = {
  lastWeek: function (e) {
    var a = e.getDay();
    var t = "'läschte";
    if (a === 2 || a === 4) {
      t += "n";
    }
    t += "' eeee 'um' p";
    return t;
  },
  yesterday: "'gëschter um' p",
  today: "'haut um' p",
  tomorrow: "'moien um' p",
  nextWeek: "eeee 'um' p",
  other: "P"
};
var formatRelative$16 = function (e, a, t, r) {
  var n = formatRelativeLocale$z[e];
  if (typeof n === "function") {
    return n(a);
  }
  return n;
};
formatRelative$17.formatRelative = formatRelative$16;
var localize$z = {};
localize$z.localize = void 0;
var _index$2l = buildLocalizeFn;
var eraValues$z = {
  narrow: ["v.Chr.", "n.Chr."],
  abbreviated: ["v.Chr.", "n.Chr."],
  wide: ["viru Christus", "no Christus"]
};
var quarterValues$z = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1. Quartal", "2. Quartal", "3. Quartal", "4. Quartal"]
};
var monthValues$z = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["Jan", "Feb", "Mäe", "Abr", "Mee", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
  wide: ["Januar", "Februar", "Mäerz", "Abrëll", "Mee", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"]
};
var dayValues$z = {
  narrow: ["S", "M", "D", "M", "D", "F", "S"],
  short: ["So", "Mé", "Dë", "Më", "Do", "Fr", "Sa"],
  abbreviated: ["So.", "Mé.", "Dë.", "Më.", "Do.", "Fr.", "Sa."],
  wide: ["Sonndeg", "Méindeg", "Dënschdeg", "Mëttwoch", "Donneschdeg", "Freideg", "Samschdeg"]
};
var dayPeriodValues$z = {
  narrow: {
    am: "mo.",
    pm: "nomë.",
    midnight: "Mëtternuecht",
    noon: "Mëtteg",
    morning: "Moien",
    afternoon: "Nomëtteg",
    evening: "Owend",
    night: "Nuecht"
  },
  abbreviated: {
    am: "moies",
    pm: "nomëttes",
    midnight: "Mëtternuecht",
    noon: "Mëtteg",
    morning: "Moien",
    afternoon: "Nomëtteg",
    evening: "Owend",
    night: "Nuecht"
  },
  wide: {
    am: "moies",
    pm: "nomëttes",
    midnight: "Mëtternuecht",
    noon: "Mëtteg",
    morning: "Moien",
    afternoon: "Nomëtteg",
    evening: "Owend",
    night: "Nuecht"
  }
};
var formattingDayPeriodValues$r = {
  narrow: {
    am: "mo.",
    pm: "nom.",
    midnight: "Mëtternuecht",
    noon: "mëttes",
    morning: "moies",
    afternoon: "nomëttes",
    evening: "owes",
    night: "nuets"
  },
  abbreviated: {
    am: "moies",
    pm: "nomëttes",
    midnight: "Mëtternuecht",
    noon: "mëttes",
    morning: "moies",
    afternoon: "nomëttes",
    evening: "owes",
    night: "nuets"
  },
  wide: {
    am: "moies",
    pm: "nomëttes",
    midnight: "Mëtternuecht",
    noon: "mëttes",
    morning: "moies",
    afternoon: "nomëttes",
    evening: "owes",
    night: "nuets"
  }
};
var ordinalNumber$z = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$z.localize = {
  ordinalNumber: ordinalNumber$z,
  era: (0, _index$2l.buildLocalizeFn)({
    values: eraValues$z,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2l.buildLocalizeFn)({
    values: quarterValues$z,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$2l.buildLocalizeFn)({
    values: monthValues$z,
    defaultWidth: "wide"
  }),
  day: (0, _index$2l.buildLocalizeFn)({
    values: dayValues$z,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$2l.buildLocalizeFn)({
    values: dayPeriodValues$z,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$r,
    defaultFormattingWidth: "wide"
  })
};
var match$z = {};
match$z.match = void 0;
var _index$2k = buildMatchFn;
var _index2$18 = buildMatchPatternFn;
var matchOrdinalNumberPattern$z = /^(\d+)(\.)?/i;
var parseOrdinalNumberPattern$z = /\d+/i;
var matchEraPatterns$z = {
  narrow: /^(v\.? ?Chr\.?|n\.? ?Chr\.?)/i,
  abbreviated: /^(v\.? ?Chr\.?|n\.? ?Chr\.?)/i,
  wide: /^(viru Christus|virun eiser Zäitrechnung|no Christus|eiser Zäitrechnung)/i
};
var parseEraPatterns$z = {
  any: [/^v/i, /^n/i]
};
var matchQuarterPatterns$z = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](\.)? Quartal/i
};
var parseQuarterPatterns$z = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$z = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mäe|abr|mee|jun|jul|aug|sep|okt|nov|dez)/i,
  wide: /^(januar|februar|mäerz|abrëll|mee|juni|juli|august|september|oktober|november|dezember)/i
};
var parseMonthPatterns$z = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mä/i, /^ab/i, /^me/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$z = {
  narrow: /^[smdf]/i,
  short: /^(so|mé|dë|më|do|fr|sa)/i,
  abbreviated: /^(son?|méi?|dën?|mët?|don?|fre?|sam?)\.?/i,
  wide: /^(sonndeg|méindeg|dënschdeg|mëttwoch|donneschdeg|freideg|samschdeg)/i
};
var parseDayPatterns$z = {
  any: [/^so/i, /^mé/i, /^dë/i, /^më/i, /^do/i, /^f/i, /^sa/i]
};
var matchDayPeriodPatterns$z = {
  narrow: /^(mo\.?|nomë\.?|Mëtternuecht|mëttes|moies|nomëttes|owes|nuets)/i,
  abbreviated: /^(moi\.?|nomët\.?|Mëtternuecht|mëttes|moies|nomëttes|owes|nuets)/i,
  wide: /^(moies|nomëttes|Mëtternuecht|mëttes|moies|nomëttes|owes|nuets)/i
};
var parseDayPeriodPatterns$z = {
  any: {
    am: /^m/i,
    pm: /^n/i,
    midnight: /^Mëtter/i,
    noon: /^mëttes/i,
    morning: /moies/i,
    afternoon: /nomëttes/i,
    evening: /owes/i,
    night: /nuets/i
  }
};
match$z.match = {
  ordinalNumber: (0, _index2$18.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$z,
    parsePattern: parseOrdinalNumberPattern$z,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$2k.buildMatchFn)({
    matchPatterns: matchEraPatterns$z,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$z,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$2k.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$z,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$z,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$2k.buildMatchFn)({
    matchPatterns: matchMonthPatterns$z,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$z,
    defaultParseWidth: "any"
  }),
  day: (0, _index$2k.buildMatchFn)({
    matchPatterns: matchDayPatterns$z,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$z,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$2k.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$z,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$z,
    defaultParseWidth: "any"
  })
};
lb.lb = void 0;
var _index$2j = formatDistance$17;
var _index2$17 = formatLong$z;
var _index3$z = formatRelative$17;
var _index4$z = localize$z;
var _index5$z = match$z;
lb.lb = {
  code: "lb",
  formatDistance: _index$2j.formatDistance,
  formatLong: _index2$17.formatLong,
  formatRelative: _index3$z.formatRelative,
  localize: _index4$z.localize,
  match: _index5$z.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var lt = {};
var formatDistance$15 = {};
formatDistance$15.formatDistance = void 0;
var translations = {
  xseconds_other: "sekundė_sekundžių_sekundes",
  xminutes_one: "minutė_minutės_minutę",
  xminutes_other: "minutės_minučių_minutes",
  xhours_one: "valanda_valandos_valandą",
  xhours_other: "valandos_valandų_valandas",
  xdays_one: "diena_dienos_dieną",
  xdays_other: "dienos_dienų_dienas",
  xweeks_one: "savaitė_savaitės_savaitę",
  xweeks_other: "savaitės_savaičių_savaites",
  xmonths_one: "mėnuo_mėnesio_mėnesį",
  xmonths_other: "mėnesiai_mėnesių_mėnesius",
  xyears_one: "metai_metų_metus",
  xyears_other: "metai_metų_metus",
  about: "apie",
  over: "daugiau nei",
  almost: "beveik",
  lessthan: "mažiau nei"
};
var translateSeconds = function (e, a, t, r) {
  if (!a) {
    return "kelios sekundės";
  } else {
    return r ? "kelių sekundžių" : "kelias sekundes";
  }
};
var translateSingular = function (e, a, t, r) {
  return !a ? forms(t)[0] : r ? forms(t)[1] : forms(t)[2];
};
var translate = function (e, a, t, r) {
  var n = e + " ";
  if (e === 1) {
    return n + translateSingular(e, a, t, r);
  } else if (!a) {
    return n + (special(e) ? forms(t)[1] : forms(t)[0]);
  } else {
    if (r) {
      return n + forms(t)[1];
    } else {
      return n + (special(e) ? forms(t)[1] : forms(t)[2]);
    }
  }
};
function special(e) {
  return e % 10 === 0 || e > 10 && e < 20;
}
function forms(e) {
  return translations[e].split("_");
}
var formatDistanceLocale$y = {
  lessThanXSeconds: {
    one: translateSeconds,
    other: translate
  },
  xSeconds: {
    one: translateSeconds,
    other: translate
  },
  halfAMinute: "pusė minutės",
  lessThanXMinutes: {
    one: translateSingular,
    other: translate
  },
  xMinutes: {
    one: translateSingular,
    other: translate
  },
  aboutXHours: {
    one: translateSingular,
    other: translate
  },
  xHours: {
    one: translateSingular,
    other: translate
  },
  xDays: {
    one: translateSingular,
    other: translate
  },
  aboutXWeeks: {
    one: translateSingular,
    other: translate
  },
  xWeeks: {
    one: translateSingular,
    other: translate
  },
  aboutXMonths: {
    one: translateSingular,
    other: translate
  },
  xMonths: {
    one: translateSingular,
    other: translate
  },
  aboutXYears: {
    one: translateSingular,
    other: translate
  },
  xYears: {
    one: translateSingular,
    other: translate
  },
  overXYears: {
    one: translateSingular,
    other: translate
  },
  almostXYears: {
    one: translateSingular,
    other: translate
  }
};
var formatDistance$14 = function (e, a, t) {
  var r = e.match(/about|over|almost|lessthan/i);
  var n = r ? e.replace(r[0], "") : e;
  var i = (t === null || t === void 0 ? void 0 : t.comparison) !== undefined && t.comparison > 0;
  var o;
  var d = formatDistanceLocale$y[e];
  if (typeof d === "string") {
    o = d;
  } else if (a === 1) {
    o = d.one(a, (t === null || t === void 0 ? void 0 : t.addSuffix) === true, n.toLowerCase() + "_one", i);
  } else {
    o = d.other(a, (t === null || t === void 0 ? void 0 : t.addSuffix) === true, n.toLowerCase() + "_other", i);
  }
  if (r) {
    var s = r[0].toLowerCase();
    o = translations[s] + " " + o;
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "po " + o;
    } else {
      return "prieš " + o;
    }
  }
  return o;
};
formatDistance$15.formatDistance = formatDistance$14;
var formatLong$y = {};
formatLong$y.formatLong = void 0;
var _index$2i = buildFormatLongFn;
var dateFormats$y = {
  full: "y 'm'. MMMM d 'd'., EEEE",
  long: "y 'm'. MMMM d 'd'.",
  medium: "y-MM-dd",
  short: "y-MM-dd"
};
var timeFormats$y = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$y = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$y.formatLong = {
  date: (0, _index$2i.buildFormatLongFn)({
    formats: dateFormats$y,
    defaultWidth: "full"
  }),
  time: (0, _index$2i.buildFormatLongFn)({
    formats: timeFormats$y,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2i.buildFormatLongFn)({
    formats: dateTimeFormats$y,
    defaultWidth: "full"
  })
};
var formatRelative$15 = {};
formatRelative$15.formatRelative = void 0;
var formatRelativeLocale$y = {
  lastWeek: "'Praėjusį' eeee p",
  yesterday: "'Vakar' p",
  today: "'Šiandien' p",
  tomorrow: "'Rytoj' p",
  nextWeek: "eeee p",
  other: "P"
};
var formatRelative$14 = function (e, a, t, r) {
  return formatRelativeLocale$y[e];
};
formatRelative$15.formatRelative = formatRelative$14;
var localize$y = {};
localize$y.localize = void 0;
var _index$2h = buildLocalizeFn;
var eraValues$y = {
  narrow: ["pr. Kr.", "po Kr."],
  abbreviated: ["pr. Kr.", "po Kr."],
  wide: ["prieš Kristų", "po Kristaus"]
};
var quarterValues$y = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["I ketv.", "II ketv.", "III ketv.", "IV ketv."],
  wide: ["I ketvirtis", "II ketvirtis", "III ketvirtis", "IV ketvirtis"]
};
var formattingQuarterValues$2 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["I k.", "II k.", "III k.", "IV k."],
  wide: ["I ketvirtis", "II ketvirtis", "III ketvirtis", "IV ketvirtis"]
};
var monthValues$y = {
  narrow: ["S", "V", "K", "B", "G", "B", "L", "R", "R", "S", "L", "G"],
  abbreviated: ["saus.", "vas.", "kov.", "bal.", "geg.", "birž.", "liep.", "rugp.", "rugs.", "spal.", "lapkr.", "gruod."],
  wide: ["sausis", "vasaris", "kovas", "balandis", "gegužė", "birželis", "liepa", "rugpjūtis", "rugsėjis", "spalis", "lapkritis", "gruodis"]
};
var formattingMonthValues$8 = {
  narrow: ["S", "V", "K", "B", "G", "B", "L", "R", "R", "S", "L", "G"],
  abbreviated: ["saus.", "vas.", "kov.", "bal.", "geg.", "birž.", "liep.", "rugp.", "rugs.", "spal.", "lapkr.", "gruod."],
  wide: ["sausio", "vasario", "kovo", "balandžio", "gegužės", "birželio", "liepos", "rugpjūčio", "rugsėjo", "spalio", "lapkričio", "gruodžio"]
};
var dayValues$y = {
  narrow: ["S", "P", "A", "T", "K", "P", "Š"],
  short: ["Sk", "Pr", "An", "Tr", "Kt", "Pn", "Št"],
  abbreviated: ["sk", "pr", "an", "tr", "kt", "pn", "št"],
  wide: ["sekmadienis", "pirmadienis", "antradienis", "trečiadienis", "ketvirtadienis", "penktadienis", "šeštadienis"]
};
var formattingDayValues$2 = {
  narrow: ["S", "P", "A", "T", "K", "P", "Š"],
  short: ["Sk", "Pr", "An", "Tr", "Kt", "Pn", "Št"],
  abbreviated: ["sk", "pr", "an", "tr", "kt", "pn", "št"],
  wide: ["sekmadienį", "pirmadienį", "antradienį", "trečiadienį", "ketvirtadienį", "penktadienį", "šeštadienį"]
};
var dayPeriodValues$y = {
  narrow: {
    am: "pr. p.",
    pm: "pop.",
    midnight: "vidurnaktis",
    noon: "vidurdienis",
    morning: "rytas",
    afternoon: "diena",
    evening: "vakaras",
    night: "naktis"
  },
  abbreviated: {
    am: "priešpiet",
    pm: "popiet",
    midnight: "vidurnaktis",
    noon: "vidurdienis",
    morning: "rytas",
    afternoon: "diena",
    evening: "vakaras",
    night: "naktis"
  },
  wide: {
    am: "priešpiet",
    pm: "popiet",
    midnight: "vidurnaktis",
    noon: "vidurdienis",
    morning: "rytas",
    afternoon: "diena",
    evening: "vakaras",
    night: "naktis"
  }
};
var formattingDayPeriodValues$q = {
  narrow: {
    am: "pr. p.",
    pm: "pop.",
    midnight: "vidurnaktis",
    noon: "perpiet",
    morning: "rytas",
    afternoon: "popietė",
    evening: "vakaras",
    night: "naktis"
  },
  abbreviated: {
    am: "priešpiet",
    pm: "popiet",
    midnight: "vidurnaktis",
    noon: "perpiet",
    morning: "rytas",
    afternoon: "popietė",
    evening: "vakaras",
    night: "naktis"
  },
  wide: {
    am: "priešpiet",
    pm: "popiet",
    midnight: "vidurnaktis",
    noon: "perpiet",
    morning: "rytas",
    afternoon: "popietė",
    evening: "vakaras",
    night: "naktis"
  }
};
var ordinalNumber$y = function (e, a) {
  var t = Number(e);
  return t + "-oji";
};
localize$y.localize = {
  ordinalNumber: ordinalNumber$y,
  era: (0, _index$2h.buildLocalizeFn)({
    values: eraValues$y,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2h.buildLocalizeFn)({
    values: quarterValues$y,
    defaultWidth: "wide",
    formattingValues: formattingQuarterValues$2,
    defaultFormattingWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$2h.buildLocalizeFn)({
    values: monthValues$y,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$8,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$2h.buildLocalizeFn)({
    values: dayValues$y,
    defaultWidth: "wide",
    formattingValues: formattingDayValues$2,
    defaultFormattingWidth: "wide"
  }),
  dayPeriod: (0, _index$2h.buildLocalizeFn)({
    values: dayPeriodValues$y,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$q,
    defaultFormattingWidth: "wide"
  })
};
var match$y = {};
match$y.match = void 0;
var _index$2g = buildMatchFn;
var _index2$16 = buildMatchPatternFn;
var matchOrdinalNumberPattern$y = /^(\d+)(-oji)?/i;
var parseOrdinalNumberPattern$y = /\d+/i;
var matchEraPatterns$y = {
  narrow: /^p(r|o)\.?\s?(kr\.?|me)/i,
  abbreviated: /^(pr\.\s?(kr\.|m\.\s?e\.)|po\s?kr\.|mūsų eroje)/i,
  wide: /^(prieš Kristų|prieš mūsų erą|po Kristaus|mūsų eroje)/i
};
var parseEraPatterns$y = {
  wide: [/prieš/i, /(po|mūsų)/i],
  any: [/^pr/i, /^(po|m)/i]
};
var matchQuarterPatterns$y = {
  narrow: /^([1234])/i,
  abbreviated: /^(I|II|III|IV)\s?ketv?\.?/i,
  wide: /^(I|II|III|IV)\s?ketvirtis/i
};
var parseQuarterPatterns$y = {
  narrow: [/1/i, /2/i, /3/i, /4/i],
  any: [/I$/i, /II$/i, /III/i, /IV/i]
};
var matchMonthPatterns$y = {
  narrow: /^[svkbglr]/i,
  abbreviated: /^(saus\.|vas\.|kov\.|bal\.|geg\.|birž\.|liep\.|rugp\.|rugs\.|spal\.|lapkr\.|gruod\.)/i,
  wide: /^(sausi(s|o)|vasari(s|o)|kov(a|o)s|balandž?i(s|o)|gegužės?|birželi(s|o)|liep(a|os)|rugpjū(t|č)i(s|o)|rugsėj(is|o)|spali(s|o)|lapkri(t|č)i(s|o)|gruodž?i(s|o))/i
};
var parseMonthPatterns$y = {
  narrow: [/^s/i, /^v/i, /^k/i, /^b/i, /^g/i, /^b/i, /^l/i, /^r/i, /^r/i, /^s/i, /^l/i, /^g/i],
  any: [/^saus/i, /^vas/i, /^kov/i, /^bal/i, /^geg/i, /^birž/i, /^liep/i, /^rugp/i, /^rugs/i, /^spal/i, /^lapkr/i, /^gruod/i]
};
var matchDayPatterns$y = {
  narrow: /^[spatkš]/i,
  short: /^(sk|pr|an|tr|kt|pn|št)/i,
  abbreviated: /^(sk|pr|an|tr|kt|pn|št)/i,
  wide: /^(sekmadien(is|į)|pirmadien(is|į)|antradien(is|į)|trečiadien(is|į)|ketvirtadien(is|į)|penktadien(is|į)|šeštadien(is|į))/i
};
var parseDayPatterns$y = {
  narrow: [/^s/i, /^p/i, /^a/i, /^t/i, /^k/i, /^p/i, /^š/i],
  wide: [/^se/i, /^pi/i, /^an/i, /^tr/i, /^ke/i, /^pe/i, /^še/i],
  any: [/^sk/i, /^pr/i, /^an/i, /^tr/i, /^kt/i, /^pn/i, /^št/i]
};
var matchDayPeriodPatterns$y = {
  narrow: /^(pr.\s?p.|pop.|vidurnaktis|(vidurdienis|perpiet)|rytas|(diena|popietė)|vakaras|naktis)/i,
  any: /^(priešpiet|popiet$|vidurnaktis|(vidurdienis|perpiet)|rytas|(diena|popietė)|vakaras|naktis)/i
};
var parseDayPeriodPatterns$y = {
  narrow: {
    am: /^pr/i,
    pm: /^pop./i,
    midnight: /^vidurnaktis/i,
    noon: /^(vidurdienis|perp)/i,
    morning: /rytas/i,
    afternoon: /(die|popietė)/i,
    evening: /vakaras/i,
    night: /naktis/i
  },
  any: {
    am: /^pr/i,
    pm: /^popiet$/i,
    midnight: /^vidurnaktis/i,
    noon: /^(vidurdienis|perp)/i,
    morning: /rytas/i,
    afternoon: /(die|popietė)/i,
    evening: /vakaras/i,
    night: /naktis/i
  }
};
match$y.match = {
  ordinalNumber: (0, _index2$16.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$y,
    parsePattern: parseOrdinalNumberPattern$y,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$2g.buildMatchFn)({
    matchPatterns: matchEraPatterns$y,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$y,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$2g.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$y,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$y,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$2g.buildMatchFn)({
    matchPatterns: matchMonthPatterns$y,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$y,
    defaultParseWidth: "any"
  }),
  day: (0, _index$2g.buildMatchFn)({
    matchPatterns: matchDayPatterns$y,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$y,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$2g.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$y,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$y,
    defaultParseWidth: "any"
  })
};
lt.lt = void 0;
var _index$2f = formatDistance$15;
var _index2$15 = formatLong$y;
var _index3$y = formatRelative$15;
var _index4$y = localize$y;
var _index5$y = match$y;
lt.lt = {
  code: "lt",
  formatDistance: _index$2f.formatDistance,
  formatLong: _index2$15.formatLong,
  formatRelative: _index3$y.formatRelative,
  localize: _index4$y.localize,
  match: _index5$y.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var lv = {};
var formatDistance$13 = {};
formatDistance$13.formatDistance = void 0;
function buildLocalizeTokenFn$2(e) {
  return function (a, t) {
    if (a === 1) {
      if (t === null || t === void 0 ? void 0 : t.addSuffix) {
        return e.one[0].replace("{{time}}", e.one[2]);
      } else {
        return e.one[0].replace("{{time}}", e.one[1]);
      }
    } else {
      var r = a % 10 === 1 && a % 100 !== 11;
      if (t === null || t === void 0 ? void 0 : t.addSuffix) {
        return e.other[0].replace("{{time}}", r ? e.other[3] : e.other[4]).replace("{{count}}", String(a));
      } else {
        return e.other[0].replace("{{time}}", r ? e.other[1] : e.other[2]).replace("{{count}}", String(a));
      }
    }
  };
}
var formatDistanceLocale$x = {
  lessThanXSeconds: buildLocalizeTokenFn$2({
    one: ["mazāk par {{time}}", "sekundi", "sekundi"],
    other: ["mazāk nekā {{count}} {{time}}", "sekunde", "sekundes", "sekundes", "sekundēm"]
  }),
  xSeconds: buildLocalizeTokenFn$2({
    one: ["1 {{time}}", "sekunde", "sekundes"],
    other: ["{{count}} {{time}}", "sekunde", "sekundes", "sekundes", "sekundēm"]
  }),
  halfAMinute: function (e, a) {
    if (a === null || a === void 0 ? void 0 : a.addSuffix) {
      return "pusminūtes";
    } else {
      return "pusminūte";
    }
  },
  lessThanXMinutes: buildLocalizeTokenFn$2({
    one: ["mazāk par {{time}}", "minūti", "minūti"],
    other: ["mazāk nekā {{count}} {{time}}", "minūte", "minūtes", "minūtes", "minūtēm"]
  }),
  xMinutes: buildLocalizeTokenFn$2({
    one: ["1 {{time}}", "minūte", "minūtes"],
    other: ["{{count}} {{time}}", "minūte", "minūtes", "minūtes", "minūtēm"]
  }),
  aboutXHours: buildLocalizeTokenFn$2({
    one: ["apmēram 1 {{time}}", "stunda", "stundas"],
    other: ["apmēram {{count}} {{time}}", "stunda", "stundas", "stundas", "stundām"]
  }),
  xHours: buildLocalizeTokenFn$2({
    one: ["1 {{time}}", "stunda", "stundas"],
    other: ["{{count}} {{time}}", "stunda", "stundas", "stundas", "stundām"]
  }),
  xDays: buildLocalizeTokenFn$2({
    one: ["1 {{time}}", "diena", "dienas"],
    other: ["{{count}} {{time}}", "diena", "dienas", "dienas", "dienām"]
  }),
  aboutXWeeks: buildLocalizeTokenFn$2({
    one: ["apmēram 1 {{time}}", "nedēļa", "nedēļas"],
    other: ["apmēram {{count}} {{time}}", "nedēļa", "nedēļu", "nedēļas", "nedēļām"]
  }),
  xWeeks: buildLocalizeTokenFn$2({
    one: ["1 {{time}}", "nedēļa", "nedēļas"],
    other: ["{{count}} {{time}}", "nedēļa", "nedēļu", "nedēļas", "nedēļām"]
  }),
  aboutXMonths: buildLocalizeTokenFn$2({
    one: ["apmēram 1 {{time}}", "mēnesis", "mēneša"],
    other: ["apmēram {{count}} {{time}}", "mēnesis", "mēneši", "mēneša", "mēnešiem"]
  }),
  xMonths: buildLocalizeTokenFn$2({
    one: ["1 {{time}}", "mēnesis", "mēneša"],
    other: ["{{count}} {{time}}", "mēnesis", "mēneši", "mēneša", "mēnešiem"]
  }),
  aboutXYears: buildLocalizeTokenFn$2({
    one: ["apmēram 1 {{time}}", "gads", "gada"],
    other: ["apmēram {{count}} {{time}}", "gads", "gadi", "gada", "gadiem"]
  }),
  xYears: buildLocalizeTokenFn$2({
    one: ["1 {{time}}", "gads", "gada"],
    other: ["{{count}} {{time}}", "gads", "gadi", "gada", "gadiem"]
  }),
  overXYears: buildLocalizeTokenFn$2({
    one: ["ilgāk par 1 {{time}}", "gadu", "gadu"],
    other: ["vairāk nekā {{count}} {{time}}", "gads", "gadi", "gada", "gadiem"]
  }),
  almostXYears: buildLocalizeTokenFn$2({
    one: ["gandrīz 1 {{time}}", "gads", "gada"],
    other: ["vairāk nekā {{count}} {{time}}", "gads", "gadi", "gada", "gadiem"]
  })
};
var formatDistance$12 = function (e, a, t) {
  var r = formatDistanceLocale$x[e](a, t);
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "pēc " + r;
    } else {
      return "pirms " + r;
    }
  }
  return r;
};
formatDistance$13.formatDistance = formatDistance$12;
var formatLong$x = {};
formatLong$x.formatLong = void 0;
var _index$2e = buildFormatLongFn;
var dateFormats$x = {
  full: "EEEE, y. 'gada' d. MMMM",
  long: "y. 'gada' d. MMMM",
  medium: "dd.MM.y.",
  short: "dd.MM.y."
};
var timeFormats$x = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$x = {
  full: "{{date}} 'plkst.' {{time}}",
  long: "{{date}} 'plkst.' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$x.formatLong = {
  date: (0, _index$2e.buildFormatLongFn)({
    formats: dateFormats$x,
    defaultWidth: "full"
  }),
  time: (0, _index$2e.buildFormatLongFn)({
    formats: timeFormats$x,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$2e.buildFormatLongFn)({
    formats: dateTimeFormats$x,
    defaultWidth: "full"
  })
};
var formatRelative$13 = {};
formatRelative$13.formatRelative = void 0;
var _index$2d = isSameWeek;
var weekdays$1 = ["svētdienā", "pirmdienā", "otrdienā", "trešdienā", "ceturtdienā", "piektdienā", "sestdienā"];
var formatRelativeLocale$x = {
  lastWeek: function (e, a, t) {
    if ((0, _index$2d.isSameWeek)(e, a, t)) {
      return "eeee 'plkst.' p";
    }
    var r = weekdays$1[e.getDay()];
    return "'Pagājušā " + r + " plkst.' p";
  },
  yesterday: "'Vakar plkst.' p",
  today: "'Šodien plkst.' p",
  tomorrow: "'Rīt plkst.' p",
  nextWeek: function (e, a, t) {
    if ((0, _index$2d.isSameWeek)(e, a, t)) {
      return "eeee 'plkst.' p";
    }
    var r = weekdays$1[e.getDay()];
    return "'Nākamajā " + r + " plkst.' p";
  },
  other: "P"
};
var formatRelative$12 = function (e, a, t, r) {
  var n = formatRelativeLocale$x[e];
  if (typeof n === "function") {
    return n(a, t, r);
  }
  return n;
};
formatRelative$13.formatRelative = formatRelative$12;
var localize$x = {};
localize$x.localize = void 0;
var _index$2c = buildLocalizeFn;
var eraValues$x = {
  narrow: ["p.m.ē", "m.ē"],
  abbreviated: ["p. m. ē.", "m. ē."],
  wide: ["pirms mūsu ēras", "mūsu ērā"]
};
var quarterValues$x = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1. cet.", "2. cet.", "3. cet.", "4. cet."],
  wide: ["pirmais ceturksnis", "otrais ceturksnis", "trešais ceturksnis", "ceturtais ceturksnis"]
};
var formattingQuarterValues$1 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1. cet.", "2. cet.", "3. cet.", "4. cet."],
  wide: ["pirmajā ceturksnī", "otrajā ceturksnī", "trešajā ceturksnī", "ceturtajā ceturksnī"]
};
var monthValues$x = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["janv.", "febr.", "marts", "apr.", "maijs", "jūn.", "jūl.", "aug.", "sept.", "okt.", "nov.", "dec."],
  wide: ["janvāris", "februāris", "marts", "aprīlis", "maijs", "jūnijs", "jūlijs", "augusts", "septembris", "oktobris", "novembris", "decembris"]
};
var formattingMonthValues$7 = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["janv.", "febr.", "martā", "apr.", "maijs", "jūn.", "jūl.", "aug.", "sept.", "okt.", "nov.", "dec."],
  wide: ["janvārī", "februārī", "martā", "aprīlī", "maijā", "jūnijā", "jūlijā", "augustā", "septembrī", "oktobrī", "novembrī", "decembrī"]
};
var dayValues$x = {
  narrow: ["S", "P", "O", "T", "C", "P", "S"],
  short: ["Sv", "P", "O", "T", "C", "Pk", "S"],
  abbreviated: ["svētd.", "pirmd.", "otrd.", "trešd.", "ceturtd.", "piektd.", "sestd."],
  wide: ["svētdiena", "pirmdiena", "otrdiena", "trešdiena", "ceturtdiena", "piektdiena", "sestdiena"]
};
var formattingDayValues$1 = {
  narrow: ["S", "P", "O", "T", "C", "P", "S"],
  short: ["Sv", "P", "O", "T", "C", "Pk", "S"],
  abbreviated: ["svētd.", "pirmd.", "otrd.", "trešd.", "ceturtd.", "piektd.", "sestd."],
  wide: ["svētdienā", "pirmdienā", "otrdienā", "trešdienā", "ceturtdienā", "piektdienā", "sestdienā"]
};
var dayPeriodValues$x = {
  narrow: {
    am: "am",
    pm: "pm",
    midnight: "pusn.",
    noon: "pusd.",
    morning: "rīts",
    afternoon: "diena",
    evening: "vakars",
    night: "nakts"
  },
  abbreviated: {
    am: "am",
    pm: "pm",
    midnight: "pusn.",
    noon: "pusd.",
    morning: "rīts",
    afternoon: "pēcpusd.",
    evening: "vakars",
    night: "nakts"
  },
  wide: {
    am: "am",
    pm: "pm",
    midnight: "pusnakts",
    noon: "pusdienlaiks",
    morning: "rīts",
    afternoon: "pēcpusdiena",
    evening: "vakars",
    night: "nakts"
  }
};
var formattingDayPeriodValues$p = {
  narrow: {
    am: "am",
    pm: "pm",
    midnight: "pusn.",
    noon: "pusd.",
    morning: "rītā",
    afternoon: "dienā",
    evening: "vakarā",
    night: "naktī"
  },
  abbreviated: {
    am: "am",
    pm: "pm",
    midnight: "pusn.",
    noon: "pusd.",
    morning: "rītā",
    afternoon: "pēcpusd.",
    evening: "vakarā",
    night: "naktī"
  },
  wide: {
    am: "am",
    pm: "pm",
    midnight: "pusnaktī",
    noon: "pusdienlaikā",
    morning: "rītā",
    afternoon: "pēcpusdienā",
    evening: "vakarā",
    night: "naktī"
  }
};
var ordinalNumber$x = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$x.localize = {
  ordinalNumber: ordinalNumber$x,
  era: (0, _index$2c.buildLocalizeFn)({
    values: eraValues$x,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2c.buildLocalizeFn)({
    values: quarterValues$x,
    defaultWidth: "wide",
    formattingValues: formattingQuarterValues$1,
    defaultFormattingWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$2c.buildLocalizeFn)({
    values: monthValues$x,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$7,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$2c.buildLocalizeFn)({
    values: dayValues$x,
    defaultWidth: "wide",
    formattingValues: formattingDayValues$1,
    defaultFormattingWidth: "wide"
  }),
  dayPeriod: (0, _index$2c.buildLocalizeFn)({
    values: dayPeriodValues$x,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$p,
    defaultFormattingWidth: "wide"
  })
};
var match$x = {};
match$x.match = void 0;
var _index$2b = buildMatchFn;
var _index2$14 = buildMatchPatternFn;
var matchOrdinalNumberPattern$x = /^(\d+)\./i;
var parseOrdinalNumberPattern$x = /\d+/i;
var matchEraPatterns$x = {
  narrow: /^(p\.m\.ē|m\.ē)/i,
  abbreviated: /^(p\. m\. ē\.|m\. ē\.)/i,
  wide: /^(pirms mūsu ēras|mūsu ērā)/i
};
var parseEraPatterns$x = {
  any: [/^p/i, /^m/i]
};
var matchQuarterPatterns$x = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234](\. cet\.)/i,
  wide: /^(pirma(is|jā)|otra(is|jā)|treša(is|jā)|ceturta(is|jā)) ceturksn(is|ī)/i
};
var parseQuarterPatterns$x = {
  narrow: [/^1/i, /^2/i, /^3/i, /^4/i],
  abbreviated: [/^1/i, /^2/i, /^3/i, /^4/i],
  wide: [/^p/i, /^o/i, /^t/i, /^c/i]
};
var matchMonthPatterns$x = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(janv\.|febr\.|marts|apr\.|maijs|jūn\.|jūl\.|aug\.|sept\.|okt\.|nov\.|dec\.)/i,
  wide: /^(janvār(is|ī)|februār(is|ī)|mart[sā]|aprīl(is|ī)|maij[sā]|jūnij[sā]|jūlij[sā]|august[sā]|septembr(is|ī)|oktobr(is|ī)|novembr(is|ī)|decembr(is|ī))/i
};
var parseMonthPatterns$x = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^mai/i, /^jūn/i, /^jūl/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$x = {
  narrow: /^[spotc]/i,
  short: /^(sv|pi|o|t|c|pk|s)/i,
  abbreviated: /^(svētd\.|pirmd\.|otrd.\|trešd\.|ceturtd\.|piektd\.|sestd\.)/i,
  wide: /^(svētdien(a|ā)|pirmdien(a|ā)|otrdien(a|ā)|trešdien(a|ā)|ceturtdien(a|ā)|piektdien(a|ā)|sestdien(a|ā))/i
};
var parseDayPatterns$x = {
  narrow: [/^s/i, /^p/i, /^o/i, /^t/i, /^c/i, /^p/i, /^s/i],
  any: [/^sv/i, /^pi/i, /^o/i, /^t/i, /^c/i, /^p/i, /^se/i]
};
var matchDayPeriodPatterns$x = {
  narrow: /^(am|pm|pusn\.|pusd\.|rīt(s|ā)|dien(a|ā)|vakar(s|ā)|nakt(s|ī))/,
  abbreviated: /^(am|pm|pusn\.|pusd\.|rīt(s|ā)|pēcpusd\.|vakar(s|ā)|nakt(s|ī))/,
  wide: /^(am|pm|pusnakt(s|ī)|pusdienlaik(s|ā)|rīt(s|ā)|pēcpusdien(a|ā)|vakar(s|ā)|nakt(s|ī))/i
};
var parseDayPeriodPatterns$x = {
  any: {
    am: /^am/i,
    pm: /^pm/i,
    midnight: /^pusn/i,
    noon: /^pusd/i,
    morning: /^r/i,
    afternoon: /^(d|pēc)/i,
    evening: /^v/i,
    night: /^n/i
  }
};
match$x.match = {
  ordinalNumber: (0, _index2$14.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$x,
    parsePattern: parseOrdinalNumberPattern$x,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$2b.buildMatchFn)({
    matchPatterns: matchEraPatterns$x,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$x,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$2b.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$x,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$x,
    defaultParseWidth: "wide",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$2b.buildMatchFn)({
    matchPatterns: matchMonthPatterns$x,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$x,
    defaultParseWidth: "any"
  }),
  day: (0, _index$2b.buildMatchFn)({
    matchPatterns: matchDayPatterns$x,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$x,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$2b.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$x,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$x,
    defaultParseWidth: "any"
  })
};
lv.lv = void 0;
var _index$2a = formatDistance$13;
var _index2$13 = formatLong$x;
var _index3$x = formatRelative$13;
var _index4$x = localize$x;
var _index5$x = match$x;
lv.lv = {
  code: "lv",
  formatDistance: _index$2a.formatDistance,
  formatLong: _index2$13.formatLong,
  formatRelative: _index3$x.formatRelative,
  localize: _index4$x.localize,
  match: _index5$x.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var mk = {};
var formatDistance$11 = {};
formatDistance$11.formatDistance = void 0;
var formatDistanceLocale$w = {
  lessThanXSeconds: {
    one: "помалку од секунда",
    other: "помалку од {{count}} секунди"
  },
  xSeconds: {
    one: "1 секунда",
    other: "{{count}} секунди"
  },
  halfAMinute: "половина минута",
  lessThanXMinutes: {
    one: "помалку од минута",
    other: "помалку од {{count}} минути"
  },
  xMinutes: {
    one: "1 минута",
    other: "{{count}} минути"
  },
  aboutXHours: {
    one: "околу 1 час",
    other: "околу {{count}} часа"
  },
  xHours: {
    one: "1 час",
    other: "{{count}} часа"
  },
  xDays: {
    one: "1 ден",
    other: "{{count}} дена"
  },
  aboutXWeeks: {
    one: "околу 1 недела",
    other: "околу {{count}} месеци"
  },
  xWeeks: {
    one: "1 недела",
    other: "{{count}} недели"
  },
  aboutXMonths: {
    one: "околу 1 месец",
    other: "околу {{count}} недели"
  },
  xMonths: {
    one: "1 месец",
    other: "{{count}} месеци"
  },
  aboutXYears: {
    one: "околу 1 година",
    other: "околу {{count}} години"
  },
  xYears: {
    one: "1 година",
    other: "{{count}} години"
  },
  overXYears: {
    one: "повеќе од 1 година",
    other: "повеќе од {{count}} години"
  },
  almostXYears: {
    one: "безмалку 1 година",
    other: "безмалку {{count}} години"
  }
};
var formatDistance$10 = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$w[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "за " + r;
    } else {
      return "пред " + r;
    }
  }
  return r;
};
formatDistance$11.formatDistance = formatDistance$10;
var formatLong$w = {};
formatLong$w.formatLong = void 0;
var _index$29 = buildFormatLongFn;
var dateFormats$w = {
  full: "EEEE, dd MMMM yyyy",
  long: "dd MMMM yyyy",
  medium: "dd MMM yyyy",
  short: "dd/MM/yyyy"
};
var timeFormats$w = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$w = {
  any: "{{date}} {{time}}"
};
formatLong$w.formatLong = {
  date: (0, _index$29.buildFormatLongFn)({
    formats: dateFormats$w,
    defaultWidth: "full"
  }),
  time: (0, _index$29.buildFormatLongFn)({
    formats: timeFormats$w,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$29.buildFormatLongFn)({
    formats: dateTimeFormats$w,
    defaultWidth: "any"
  })
};
var formatRelative$11 = {};
formatRelative$11.formatRelative = void 0;
var _index$28 = isSameWeek;
var weekdays = ["недела", "понеделник", "вторник", "среда", "четврток", "петок", "сабота"];
function lastWeek$3(e) {
  var a = weekdays[e];
  switch (e) {
    case 0:
    case 3:
    case 6:
      return "'минатата " + a + " во' p";
    case 1:
    case 2:
    case 4:
    case 5:
      return "'минатиот " + a + " во' p";
  }
}
function thisWeek$3(e) {
  var a = weekdays[e];
  switch (e) {
    case 0:
    case 3:
    case 6:
      return "'ова " + a + " вo' p";
    case 1:
    case 2:
    case 4:
    case 5:
      return "'овој " + a + " вo' p";
  }
}
function nextWeek$3(e) {
  var a = weekdays[e];
  switch (e) {
    case 0:
    case 3:
    case 6:
      return "'следната " + a + " вo' p";
    case 1:
    case 2:
    case 4:
    case 5:
      return "'следниот " + a + " вo' p";
  }
}
var formatRelativeLocale$w = {
  lastWeek: function (e, a, t) {
    var r = e.getDay();
    if ((0, _index$28.isSameWeek)(e, a, t)) {
      return thisWeek$3(r);
    } else {
      return lastWeek$3(r);
    }
  },
  yesterday: "'вчера во' p",
  today: "'денес во' p",
  tomorrow: "'утре во' p",
  nextWeek: function (e, a, t) {
    var r = e.getDay();
    if ((0, _index$28.isSameWeek)(e, a, t)) {
      return thisWeek$3(r);
    } else {
      return nextWeek$3(r);
    }
  },
  other: "P"
};
var formatRelative$10 = function (e, a, t, r) {
  var n = formatRelativeLocale$w[e];
  if (typeof n === "function") {
    return n(a, t, r);
  }
  return n;
};
formatRelative$11.formatRelative = formatRelative$10;
var localize$w = {};
localize$w.localize = void 0;
var _index$27 = buildLocalizeFn;
var eraValues$w = {
  narrow: ["пр.н.е.", "н.е."],
  abbreviated: ["пред н. е.", "н. е."],
  wide: ["пред нашата ера", "нашата ера"]
};
var quarterValues$w = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1-ви кв.", "2-ри кв.", "3-ти кв.", "4-ти кв."],
  wide: ["1-ви квартал", "2-ри квартал", "3-ти квартал", "4-ти квартал"]
};
var monthValues$w = {
  abbreviated: ["јан", "фев", "мар", "апр", "мај", "јун", "јул", "авг", "септ", "окт", "ноем", "дек"],
  wide: ["јануари", "февруари", "март", "април", "мај", "јуни", "јули", "август", "септември", "октомври", "ноември", "декември"]
};
var dayValues$w = {
  narrow: ["Н", "П", "В", "С", "Ч", "П", "С"],
  short: ["не", "по", "вт", "ср", "че", "пе", "са"],
  abbreviated: ["нед", "пон", "вто", "сре", "чет", "пет", "саб"],
  wide: ["недела", "понеделник", "вторник", "среда", "четврток", "петок", "сабота"]
};
var dayPeriodValues$w = {
  wide: {
    am: "претпладне",
    pm: "попладне",
    midnight: "полноќ",
    noon: "напладне",
    morning: "наутро",
    afternoon: "попладне",
    evening: "навечер",
    night: "ноќе"
  }
};
var ordinalNumber$w = function (e, a) {
  var t = Number(e);
  var r = t % 100;
  if (r > 20 || r < 10) {
    switch (r % 10) {
      case 1:
        return t + "-ви";
      case 2:
        return t + "-ри";
      case 7:
      case 8:
        return t + "-ми";
    }
  }
  return t + "-ти";
};
localize$w.localize = {
  ordinalNumber: ordinalNumber$w,
  era: (0, _index$27.buildLocalizeFn)({
    values: eraValues$w,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$27.buildLocalizeFn)({
    values: quarterValues$w,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$27.buildLocalizeFn)({
    values: monthValues$w,
    defaultWidth: "wide"
  }),
  day: (0, _index$27.buildLocalizeFn)({
    values: dayValues$w,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$27.buildLocalizeFn)({
    values: dayPeriodValues$w,
    defaultWidth: "wide"
  })
};
var match$w = {};
match$w.match = void 0;
var _index$26 = buildMatchFn;
var _index2$12 = buildMatchPatternFn;
var matchOrdinalNumberPattern$w = /^(\d+)(-?[врмт][и])?/i;
var parseOrdinalNumberPattern$w = /\d+/i;
var matchEraPatterns$w = {
  narrow: /^((пр)?н\.?\s?е\.?)/i,
  abbreviated: /^((пр)?н\.?\s?е\.?)/i,
  wide: /^(пред нашата ера|нашата ера)/i
};
var parseEraPatterns$w = {
  any: [/^п/i, /^н/i]
};
var matchQuarterPatterns$w = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234](-?[врт]?и?)? кв.?/i,
  wide: /^[1234](-?[врт]?и?)? квартал/i
};
var parseQuarterPatterns$w = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchDayPatterns$w = {
  narrow: /^[нпвсч]/i,
  short: /^(не|по|вт|ср|че|пе|са)/i,
  abbreviated: /^(нед|пон|вто|сре|чет|пет|саб)/i,
  wide: /^(недела|понеделник|вторник|среда|четврток|петок|сабота)/i
};
var parseDayPatterns$w = {
  narrow: [/^н/i, /^п/i, /^в/i, /^с/i, /^ч/i, /^п/i, /^с/i],
  any: [/^н[ед]/i, /^п[он]/i, /^вт/i, /^ср/i, /^ч[ет]/i, /^п[ет]/i, /^с[аб]/i]
};
var matchMonthPatterns$w = {
  abbreviated: /^(јан|фев|мар|апр|мај|јун|јул|авг|сеп|окт|ноем|дек)/i,
  wide: /^(јануари|февруари|март|април|мај|јуни|јули|август|септември|октомври|ноември|декември)/i
};
var parseMonthPatterns$w = {
  any: [/^ја/i, /^Ф/i, /^мар/i, /^ап/i, /^мај/i, /^јун/i, /^јул/i, /^ав/i, /^се/i, /^окт/i, /^но/i, /^де/i]
};
var matchDayPeriodPatterns$w = {
  any: /^(претп|попл|полноќ|утро|пладне|вечер|ноќ)/i
};
var parseDayPeriodPatterns$w = {
  any: {
    am: /претпладне/i,
    pm: /попладне/i,
    midnight: /полноќ/i,
    noon: /напладне/i,
    morning: /наутро/i,
    afternoon: /попладне/i,
    evening: /навечер/i,
    night: /ноќе/i
  }
};
match$w.match = {
  ordinalNumber: (0, _index2$12.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$w,
    parsePattern: parseOrdinalNumberPattern$w,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$26.buildMatchFn)({
    matchPatterns: matchEraPatterns$w,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$w,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$26.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$w,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$w,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$26.buildMatchFn)({
    matchPatterns: matchMonthPatterns$w,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$w,
    defaultParseWidth: "any"
  }),
  day: (0, _index$26.buildMatchFn)({
    matchPatterns: matchDayPatterns$w,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$w,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$26.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$w,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$w,
    defaultParseWidth: "any"
  })
};
mk.mk = void 0;
var _index$25 = formatDistance$11;
var _index2$11 = formatLong$w;
var _index3$w = formatRelative$11;
var _index4$w = localize$w;
var _index5$w = match$w;
mk.mk = {
  code: "mk",
  formatDistance: _index$25.formatDistance,
  formatLong: _index2$11.formatLong,
  formatRelative: _index3$w.formatRelative,
  localize: _index4$w.localize,
  match: _index5$w.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var mn = {};
var formatDistance$$ = {};
formatDistance$$.formatDistance = void 0;
var formatDistanceLocale$v = {
  lessThanXSeconds: {
    one: "секунд хүрэхгүй",
    other: "{{count}} секунд хүрэхгүй"
  },
  xSeconds: {
    one: "1 секунд",
    other: "{{count}} секунд"
  },
  halfAMinute: "хагас минут",
  lessThanXMinutes: {
    one: "минут хүрэхгүй",
    other: "{{count}} минут хүрэхгүй"
  },
  xMinutes: {
    one: "1 минут",
    other: "{{count}} минут"
  },
  aboutXHours: {
    one: "ойролцоогоор 1 цаг",
    other: "ойролцоогоор {{count}} цаг"
  },
  xHours: {
    one: "1 цаг",
    other: "{{count}} цаг"
  },
  xDays: {
    one: "1 өдөр",
    other: "{{count}} өдөр"
  },
  aboutXWeeks: {
    one: "ойролцоогоор 1 долоо хоног",
    other: "ойролцоогоор {{count}} долоо хоног"
  },
  xWeeks: {
    one: "1 долоо хоног",
    other: "{{count}} долоо хоног"
  },
  aboutXMonths: {
    one: "ойролцоогоор 1 сар",
    other: "ойролцоогоор {{count}} сар"
  },
  xMonths: {
    one: "1 сар",
    other: "{{count}} сар"
  },
  aboutXYears: {
    one: "ойролцоогоор 1 жил",
    other: "ойролцоогоор {{count}} жил"
  },
  xYears: {
    one: "1 жил",
    other: "{{count}} жил"
  },
  overXYears: {
    one: "1 жил гаран",
    other: "{{count}} жил гаран"
  },
  almostXYears: {
    one: "бараг 1 жил",
    other: "бараг {{count}} жил"
  }
};
var formatDistance$_ = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$v[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    var i = r.split(" ");
    var o = i.pop();
    r = i.join(" ");
    switch (o) {
      case "секунд":
        r += " секундийн";
        break;
      case "минут":
        r += " минутын";
        break;
      case "цаг":
        r += " цагийн";
        break;
      case "өдөр":
        r += " өдрийн";
        break;
      case "сар":
        r += " сарын";
        break;
      case "жил":
        r += " жилийн";
        break;
      case "хоног":
        r += " хоногийн";
        break;
      case "гаран":
        r += " гараны";
        break;
      case "хүрэхгүй":
        r += " хүрэхгүй хугацааны";
        break;
      default:
        r += o + "-н";
    }
    if (t.comparison && t.comparison > 0) {
      return r + " дараа";
    } else {
      return r + " өмнө";
    }
  }
  return r;
};
formatDistance$$.formatDistance = formatDistance$_;
var formatLong$v = {};
formatLong$v.formatLong = void 0;
var _index$24 = buildFormatLongFn;
var dateFormats$v = {
  full: "y 'оны' MMMM'ын' d, EEEE 'гараг'",
  long: "y 'оны' MMMM'ын' d",
  medium: "y 'оны' MMM'ын' d",
  short: "y.MM.dd"
};
var timeFormats$v = {
  full: "H:mm:ss zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$v = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$v.formatLong = {
  date: (0, _index$24.buildFormatLongFn)({
    formats: dateFormats$v,
    defaultWidth: "full"
  }),
  time: (0, _index$24.buildFormatLongFn)({
    formats: timeFormats$v,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$24.buildFormatLongFn)({
    formats: dateTimeFormats$v,
    defaultWidth: "full"
  })
};
var formatRelative$$ = {};
formatRelative$$.formatRelative = void 0;
var formatRelativeLocale$v = {
  lastWeek: "'өнгөрсөн' eeee 'гарагийн' p 'цагт'",
  yesterday: "'өчигдөр' p 'цагт'",
  today: "'өнөөдөр' p 'цагт'",
  tomorrow: "'маргааш' p 'цагт'",
  nextWeek: "'ирэх' eeee 'гарагийн' p 'цагт'",
  other: "P"
};
var formatRelative$_ = function (e, a, t, r) {
  return formatRelativeLocale$v[e];
};
formatRelative$$.formatRelative = formatRelative$_;
var localize$v = {};
localize$v.localize = void 0;
var _index$23 = buildLocalizeFn;
var eraValues$v = {
  narrow: ["НТӨ", "НТ"],
  abbreviated: ["НТӨ", "НТ"],
  wide: ["нийтийн тооллын өмнөх", "нийтийн тооллын"]
};
var quarterValues$v = {
  narrow: ["I", "II", "III", "IV"],
  abbreviated: ["I улирал", "II улирал", "III улирал", "IV улирал"],
  wide: ["1-р улирал", "2-р улирал", "3-р улирал", "4-р улирал"]
};
var monthValues$v = {
  narrow: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"],
  abbreviated: ["1-р сар", "2-р сар", "3-р сар", "4-р сар", "5-р сар", "6-р сар", "7-р сар", "8-р сар", "9-р сар", "10-р сар", "11-р сар", "12-р сар"],
  wide: ["Нэгдүгээр сар", "Хоёрдугаар сар", "Гуравдугаар сар", "Дөрөвдүгээр сар", "Тавдугаар сар", "Зургаадугаар сар", "Долоодугаар сар", "Наймдугаар сар", "Есдүгээр сар", "Аравдугаар сар", "Арваннэгдүгээр сар", "Арван хоёрдугаар сар"]
};
var formattingMonthValues$6 = {
  narrow: ["I", "II", "III", "IV", "V", "VI", "VII", "VIII", "IX", "X", "XI", "XII"],
  abbreviated: ["1-р сар", "2-р сар", "3-р сар", "4-р сар", "5-р сар", "6-р сар", "7-р сар", "8-р сар", "9-р сар", "10-р сар", "11-р сар", "12-р сар"],
  wide: ["нэгдүгээр сар", "хоёрдугаар сар", "гуравдугаар сар", "дөрөвдүгээр сар", "тавдугаар сар", "зургаадугаар сар", "долоодугаар сар", "наймдугаар сар", "есдүгээр сар", "аравдугаар сар", "арваннэгдүгээр сар", "арван хоёрдугаар сар"]
};
var dayValues$v = {
  narrow: ["Н", "Д", "М", "Л", "П", "Б", "Б"],
  short: ["Ня", "Да", "Мя", "Лх", "Пү", "Ба", "Бя"],
  abbreviated: ["Ням", "Дав", "Мяг", "Лха", "Пүр", "Баа", "Бям"],
  wide: ["Ням", "Даваа", "Мягмар", "Лхагва", "Пүрэв", "Баасан", "Бямба"]
};
var formattingDayValues = {
  narrow: ["Н", "Д", "М", "Л", "П", "Б", "Б"],
  short: ["Ня", "Да", "Мя", "Лх", "Пү", "Ба", "Бя"],
  abbreviated: ["Ням", "Дав", "Мяг", "Лха", "Пүр", "Баа", "Бям"],
  wide: ["ням", "даваа", "мягмар", "лхагва", "пүрэв", "баасан", "бямба"]
};
var dayPeriodValues$v = {
  narrow: {
    am: "ү.ө.",
    pm: "ү.х.",
    midnight: "шөнө дунд",
    noon: "үд дунд",
    morning: "өглөө",
    afternoon: "өдөр",
    evening: "орой",
    night: "шөнө"
  },
  abbreviated: {
    am: "ү.ө.",
    pm: "ү.х.",
    midnight: "шөнө дунд",
    noon: "үд дунд",
    morning: "өглөө",
    afternoon: "өдөр",
    evening: "орой",
    night: "шөнө"
  },
  wide: {
    am: "ү.ө.",
    pm: "ү.х.",
    midnight: "шөнө дунд",
    noon: "үд дунд",
    morning: "өглөө",
    afternoon: "өдөр",
    evening: "орой",
    night: "шөнө"
  }
};
var ordinalNumber$v = function (e, a) {
  return String(e);
};
localize$v.localize = {
  ordinalNumber: ordinalNumber$v,
  era: (0, _index$23.buildLocalizeFn)({
    values: eraValues$v,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$23.buildLocalizeFn)({
    values: quarterValues$v,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$23.buildLocalizeFn)({
    values: monthValues$v,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$6,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$23.buildLocalizeFn)({
    values: dayValues$v,
    defaultWidth: "wide",
    formattingValues: formattingDayValues,
    defaultFormattingWidth: "wide"
  }),
  dayPeriod: (0, _index$23.buildLocalizeFn)({
    values: dayPeriodValues$v,
    defaultWidth: "wide"
  })
};
var match$v = {};
match$v.match = void 0;
var _index$22 = buildMatchFn;
var _index2$10 = buildMatchPatternFn;
var matchOrdinalNumberPattern$v = /\d+/i;
var parseOrdinalNumberPattern$v = /\d+/i;
var matchEraPatterns$v = {
  narrow: /^(нтө|нт)/i,
  abbreviated: /^(нтө|нт)/i,
  wide: /^(нийтийн тооллын өмнө|нийтийн тооллын)/i
};
var parseEraPatterns$v = {
  any: [/^(нтө|нийтийн тооллын өмнө)/i, /^(нт|нийтийн тооллын)/i]
};
var matchQuarterPatterns$v = {
  narrow: /^(iv|iii|ii|i)/i,
  abbreviated: /^(iv|iii|ii|i) улирал/i,
  wide: /^[1-4]-р улирал/i
};
var parseQuarterPatterns$v = {
  any: [/^(i(\s|$)|1)/i, /^(ii(\s|$)|2)/i, /^(iii(\s|$)|3)/i, /^(iv(\s|$)|4)/i]
};
var matchMonthPatterns$v = {
  narrow: /^(xii|xi|x|ix|viii|vii|vi|v|iv|iii|ii|i)/i,
  abbreviated: /^(1-р сар|2-р сар|3-р сар|4-р сар|5-р сар|6-р сар|7-р сар|8-р сар|9-р сар|10-р сар|11-р сар|12-р сар)/i,
  wide: /^(нэгдүгээр сар|хоёрдугаар сар|гуравдугаар сар|дөрөвдүгээр сар|тавдугаар сар|зургаадугаар сар|долоодугаар сар|наймдугаар сар|есдүгээр сар|аравдугаар сар|арван нэгдүгээр сар|арван хоёрдугаар сар)/i
};
var parseMonthPatterns$v = {
  narrow: [/^i$/i, /^ii$/i, /^iii$/i, /^iv$/i, /^v$/i, /^vi$/i, /^vii$/i, /^viii$/i, /^ix$/i, /^x$/i, /^xi$/i, /^xii$/i],
  any: [/^(1|нэгдүгээр)/i, /^(2|хоёрдугаар)/i, /^(3|гуравдугаар)/i, /^(4|дөрөвдүгээр)/i, /^(5|тавдугаар)/i, /^(6|зургаадугаар)/i, /^(7|долоодугаар)/i, /^(8|наймдугаар)/i, /^(9|есдүгээр)/i, /^(10|аравдугаар)/i, /^(11|арван нэгдүгээр)/i, /^(12|арван хоёрдугаар)/i]
};
var matchDayPatterns$v = {
  narrow: /^[ндмлпбб]/i,
  short: /^(ня|да|мя|лх|пү|ба|бя)/i,
  abbreviated: /^(ням|дав|мяг|лха|пүр|баа|бям)/i,
  wide: /^(ням|даваа|мягмар|лхагва|пүрэв|баасан|бямба)/i
};
var parseDayPatterns$v = {
  narrow: [/^н/i, /^д/i, /^м/i, /^л/i, /^п/i, /^б/i, /^б/i],
  any: [/^ня/i, /^да/i, /^мя/i, /^лх/i, /^пү/i, /^ба/i, /^бя/i]
};
var matchDayPeriodPatterns$v = {
  narrow: /^(ү\.ө\.|ү\.х\.|шөнө дунд|үд дунд|өглөө|өдөр|орой|шөнө)/i,
  any: /^(ү\.ө\.|ү\.х\.|шөнө дунд|үд дунд|өглөө|өдөр|орой|шөнө)/i
};
var parseDayPeriodPatterns$v = {
  any: {
    am: /^ү\.ө\./i,
    pm: /^ү\.х\./i,
    midnight: /^шөнө дунд/i,
    noon: /^үд дунд/i,
    morning: /өглөө/i,
    afternoon: /өдөр/i,
    evening: /орой/i,
    night: /шөнө/i
  }
};
match$v.match = {
  ordinalNumber: (0, _index2$10.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$v,
    parsePattern: parseOrdinalNumberPattern$v,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$22.buildMatchFn)({
    matchPatterns: matchEraPatterns$v,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$v,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$22.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$v,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$v,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$22.buildMatchFn)({
    matchPatterns: matchMonthPatterns$v,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$v,
    defaultParseWidth: "any"
  }),
  day: (0, _index$22.buildMatchFn)({
    matchPatterns: matchDayPatterns$v,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$v,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$22.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$v,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$v,
    defaultParseWidth: "any"
  })
};
mn.mn = void 0;
var _index$21 = formatDistance$$;
var _index2$$ = formatLong$v;
var _index3$v = formatRelative$$;
var _index4$v = localize$v;
var _index5$v = match$v;
mn.mn = {
  code: "mn",
  formatDistance: _index$21.formatDistance,
  formatLong: _index2$$.formatLong,
  formatRelative: _index3$v.formatRelative,
  localize: _index4$v.localize,
  match: _index5$v.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var ms = {};
var formatDistance$Z = {};
formatDistance$Z.formatDistance = void 0;
var formatDistanceLocale$u = {
  lessThanXSeconds: {
    one: "kurang dari 1 saat",
    other: "kurang dari {{count}} saat"
  },
  xSeconds: {
    one: "1 saat",
    other: "{{count}} saat"
  },
  halfAMinute: "setengah minit",
  lessThanXMinutes: {
    one: "kurang dari 1 minit",
    other: "kurang dari {{count}} minit"
  },
  xMinutes: {
    one: "1 minit",
    other: "{{count}} minit"
  },
  aboutXHours: {
    one: "sekitar 1 jam",
    other: "sekitar {{count}} jam"
  },
  xHours: {
    one: "1 jam",
    other: "{{count}} jam"
  },
  xDays: {
    one: "1 hari",
    other: "{{count}} hari"
  },
  aboutXWeeks: {
    one: "sekitar 1 minggu",
    other: "sekitar {{count}} minggu"
  },
  xWeeks: {
    one: "1 minggu",
    other: "{{count}} minggu"
  },
  aboutXMonths: {
    one: "sekitar 1 bulan",
    other: "sekitar {{count}} bulan"
  },
  xMonths: {
    one: "1 bulan",
    other: "{{count}} bulan"
  },
  aboutXYears: {
    one: "sekitar 1 tahun",
    other: "sekitar {{count}} tahun"
  },
  xYears: {
    one: "1 tahun",
    other: "{{count}} tahun"
  },
  overXYears: {
    one: "lebih dari 1 tahun",
    other: "lebih dari {{count}} tahun"
  },
  almostXYears: {
    one: "hampir 1 tahun",
    other: "hampir {{count}} tahun"
  }
};
var formatDistance$Y = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$u[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "dalam masa " + r;
    } else {
      return r + " yang lalu";
    }
  }
  return r;
};
formatDistance$Z.formatDistance = formatDistance$Y;
var formatLong$u = {};
formatLong$u.formatLong = void 0;
var _index$20 = buildFormatLongFn;
var dateFormats$u = {
  full: "EEEE, d MMMM yyyy",
  long: "d MMMM yyyy",
  medium: "d MMM yyyy",
  short: "d/M/yyyy"
};
var timeFormats$u = {
  full: "HH.mm.ss",
  long: "HH.mm.ss",
  medium: "HH.mm",
  short: "HH.mm"
};
var dateTimeFormats$u = {
  full: "{{date}} 'pukul' {{time}}",
  long: "{{date}} 'pukul' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$u.formatLong = {
  date: (0, _index$20.buildFormatLongFn)({
    formats: dateFormats$u,
    defaultWidth: "full"
  }),
  time: (0, _index$20.buildFormatLongFn)({
    formats: timeFormats$u,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$20.buildFormatLongFn)({
    formats: dateTimeFormats$u,
    defaultWidth: "full"
  })
};
var formatRelative$Z = {};
formatRelative$Z.formatRelative = void 0;
var formatRelativeLocale$u = {
  lastWeek: "eeee 'lepas pada jam' p",
  yesterday: "'Semalam pada jam' p",
  today: "'Hari ini pada jam' p",
  tomorrow: "'Esok pada jam' p",
  nextWeek: "eeee 'pada jam' p",
  other: "P"
};
var formatRelative$Y = function (e, a, t, r) {
  return formatRelativeLocale$u[e];
};
formatRelative$Z.formatRelative = formatRelative$Y;
var localize$u = {};
localize$u.localize = void 0;
var _index$1$ = buildLocalizeFn;
var eraValues$u = {
  narrow: ["SM", "M"],
  abbreviated: ["SM", "M"],
  wide: ["Sebelum Masihi", "Masihi"]
};
var quarterValues$u = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["S1", "S2", "S3", "S4"],
  wide: ["Suku pertama", "Suku kedua", "Suku ketiga", "Suku keempat"]
};
var monthValues$u = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "O", "S", "O", "N", "D"],
  abbreviated: ["Jan", "Feb", "Mac", "Apr", "Mei", "Jun", "Jul", "Ogo", "Sep", "Okt", "Nov", "Dis"],
  wide: ["Januari", "Februari", "Mac", "April", "Mei", "Jun", "Julai", "Ogos", "September", "Oktober", "November", "Disember"]
};
var dayValues$u = {
  narrow: ["A", "I", "S", "R", "K", "J", "S"],
  short: ["Ahd", "Isn", "Sel", "Rab", "Kha", "Jum", "Sab"],
  abbreviated: ["Ahd", "Isn", "Sel", "Rab", "Kha", "Jum", "Sab"],
  wide: ["Ahad", "Isnin", "Selasa", "Rabu", "Khamis", "Jumaat", "Sabtu"]
};
var dayPeriodValues$u = {
  narrow: {
    am: "am",
    pm: "pm",
    midnight: "tgh malam",
    noon: "tgh hari",
    morning: "pagi",
    afternoon: "tengah hari",
    evening: "petang",
    night: "malam"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "tengah malam",
    noon: "tengah hari",
    morning: "pagi",
    afternoon: "tengah hari",
    evening: "petang",
    night: "malam"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "tengah malam",
    noon: "tengah hari",
    morning: "pagi",
    afternoon: "tengah hari",
    evening: "petang",
    night: "malam"
  }
};
var formattingDayPeriodValues$o = {
  narrow: {
    am: "am",
    pm: "pm",
    midnight: "tengah malam",
    noon: "tengah hari",
    morning: "pagi",
    afternoon: "tengah hari",
    evening: "petang",
    night: "malam"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "tengah malam",
    noon: "tengah hari",
    morning: "pagi",
    afternoon: "tengah hari",
    evening: "petang",
    night: "malam"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "tengah malam",
    noon: "tengah hari",
    morning: "pagi",
    afternoon: "tengah hari",
    evening: "petang",
    night: "malam"
  }
};
var ordinalNumber$u = function (e, a) {
  return "ke-" + Number(e);
};
localize$u.localize = {
  ordinalNumber: ordinalNumber$u,
  era: (0, _index$1$.buildLocalizeFn)({
    values: eraValues$u,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1$.buildLocalizeFn)({
    values: quarterValues$u,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1$.buildLocalizeFn)({
    values: monthValues$u,
    defaultWidth: "wide"
  }),
  day: (0, _index$1$.buildLocalizeFn)({
    values: dayValues$u,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$1$.buildLocalizeFn)({
    values: dayPeriodValues$u,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$o,
    defaultFormattingWidth: "wide"
  })
};
var match$u = {};
match$u.match = void 0;
var _index$1_ = buildMatchFn;
var _index2$_ = buildMatchPatternFn;
var matchOrdinalNumberPattern$u = /^ke-(\d+)?/i;
var parseOrdinalNumberPattern$u = /petama|\d+/i;
var matchEraPatterns$u = {
  narrow: /^(sm|m)/i,
  abbreviated: /^(s\.?\s?m\.?|m\.?)/i,
  wide: /^(sebelum masihi|masihi)/i
};
var parseEraPatterns$u = {
  any: [/^s/i, /^(m)/i]
};
var matchQuarterPatterns$u = {
  narrow: /^[1234]/i,
  abbreviated: /^S[1234]/i,
  wide: /Suku (pertama|kedua|ketiga|keempat)/i
};
var parseQuarterPatterns$u = {
  any: [/pertama|1/i, /kedua|2/i, /ketiga|3/i, /keempat|4/i]
};
var matchMonthPatterns$u = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mac|apr|mei|jun|jul|ogo|sep|okt|nov|dis)/i,
  wide: /^(januari|februari|mac|april|mei|jun|julai|ogos|september|oktober|november|disember)/i
};
var parseMonthPatterns$u = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^o/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^ma/i, /^ap/i, /^me/i, /^jun/i, /^jul/i, /^og/i, /^s/i, /^ok/i, /^n/i, /^d/i]
};
var matchDayPatterns$u = {
  narrow: /^[aisrkj]/i,
  short: /^(ahd|isn|sel|rab|kha|jum|sab)/i,
  abbreviated: /^(ahd|isn|sel|rab|kha|jum|sab)/i,
  wide: /^(ahad|isnin|selasa|rabu|khamis|jumaat|sabtu)/i
};
var parseDayPatterns$u = {
  narrow: [/^a/i, /^i/i, /^s/i, /^r/i, /^k/i, /^j/i, /^s/i],
  any: [/^a/i, /^i/i, /^se/i, /^r/i, /^k/i, /^j/i, /^sa/i]
};
var matchDayPeriodPatterns$u = {
  narrow: /^(am|pm|tengah malam|tengah hari|pagi|petang|malam)/i,
  any: /^([ap]\.?\s?m\.?|tengah malam|tengah hari|pagi|petang|malam)/i
};
var parseDayPeriodPatterns$u = {
  any: {
    am: /^a/i,
    pm: /^pm/i,
    midnight: /^tengah m/i,
    noon: /^tengah h/i,
    morning: /pa/i,
    afternoon: /tengah h/i,
    evening: /pe/i,
    night: /m/i
  }
};
match$u.match = {
  ordinalNumber: (0, _index2$_.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$u,
    parsePattern: parseOrdinalNumberPattern$u,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1_.buildMatchFn)({
    matchPatterns: matchEraPatterns$u,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$u,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1_.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$u,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$u,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1_.buildMatchFn)({
    matchPatterns: matchMonthPatterns$u,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$u,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1_.buildMatchFn)({
    matchPatterns: matchDayPatterns$u,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$u,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1_.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$u,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$u,
    defaultParseWidth: "any"
  })
};
ms.ms = void 0;
var _index$1Z = formatDistance$Z;
var _index2$Z = formatLong$u;
var _index3$u = formatRelative$Z;
var _index4$u = localize$u;
var _index5$u = match$u;
ms.ms = {
  code: "ms",
  formatDistance: _index$1Z.formatDistance,
  formatLong: _index2$Z.formatLong,
  formatRelative: _index3$u.formatRelative,
  localize: _index4$u.localize,
  match: _index5$u.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var mt = {};
var formatDistance$X = {};
formatDistance$X.formatDistance = void 0;
var formatDistanceLocale$t = {
  lessThanXSeconds: {
    one: "inqas minn sekonda",
    other: "inqas minn {{count}} sekondi"
  },
  xSeconds: {
    one: "sekonda",
    other: "{{count}} sekondi"
  },
  halfAMinute: "nofs minuta",
  lessThanXMinutes: {
    one: "inqas minn minuta",
    other: "inqas minn {{count}} minuti"
  },
  xMinutes: {
    one: "minuta",
    other: "{{count}} minuti"
  },
  aboutXHours: {
    one: "madwar siegħa",
    other: "madwar {{count}} siegħat"
  },
  xHours: {
    one: "siegħa",
    other: "{{count}} siegħat"
  },
  xDays: {
    one: "ġurnata",
    other: "{{count}} ġranet"
  },
  aboutXWeeks: {
    one: "madwar ġimgħa",
    other: "madwar {{count}} ġimgħat"
  },
  xWeeks: {
    one: "ġimgħa",
    other: "{{count}} ġimgħat"
  },
  aboutXMonths: {
    one: "madwar xahar",
    other: "madwar {{count}} xhur"
  },
  xMonths: {
    one: "xahar",
    other: "{{count}} xhur"
  },
  aboutXYears: {
    one: "madwar sena",
    two: "madwar sentejn",
    other: "madwar {{count}} snin"
  },
  xYears: {
    one: "sena",
    two: "sentejn",
    other: "{{count}} snin"
  },
  overXYears: {
    one: "aktar minn sena",
    two: "aktar minn sentejn",
    other: "aktar minn {{count}} snin"
  },
  almostXYears: {
    one: "kważi sena",
    two: "kważi sentejn",
    other: "kważi {{count}} snin"
  }
};
var formatDistance$W = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$t[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else if (a === 2 && n.two) {
    r = n.two;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "f'" + r;
    } else {
      return r + " ilu";
    }
  }
  return r;
};
formatDistance$X.formatDistance = formatDistance$W;
var formatLong$t = {};
formatLong$t.formatLong = void 0;
var _index$1Y = buildFormatLongFn;
var dateFormats$t = {
  full: "EEEE, d MMMM yyyy",
  long: "d MMMM yyyy",
  medium: "d MMM yyyy",
  short: "dd/MM/yyyy"
};
var timeFormats$t = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$t = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$t.formatLong = {
  date: (0, _index$1Y.buildFormatLongFn)({
    formats: dateFormats$t,
    defaultWidth: "full"
  }),
  time: (0, _index$1Y.buildFormatLongFn)({
    formats: timeFormats$t,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1Y.buildFormatLongFn)({
    formats: dateTimeFormats$t,
    defaultWidth: "full"
  })
};
var formatRelative$X = {};
formatRelative$X.formatRelative = void 0;
var formatRelativeLocale$t = {
  lastWeek: "eeee 'li għadda' 'fil-'p",
  yesterday: "'Il-bieraħ fil-'p",
  today: "'Illum fil-'p",
  tomorrow: "'Għada fil-'p",
  nextWeek: "eeee 'fil-'p",
  other: "P"
};
var formatRelative$W = function (e, a, t, r) {
  return formatRelativeLocale$t[e];
};
formatRelative$X.formatRelative = formatRelative$W;
var localize$t = {};
localize$t.localize = void 0;
var _index$1X = buildLocalizeFn;
var eraValues$t = {
  narrow: ["Q", "W"],
  abbreviated: ["QK", "WK"],
  wide: ["qabel Kristu", "wara Kristu"]
};
var quarterValues$t = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["K1", "K2", "K3", "K4"],
  wide: ["1. kwart", "2. kwart", "3. kwart", "4. kwart"]
};
var monthValues$t = {
  narrow: ["J", "F", "M", "A", "M", "Ġ", "L", "A", "S", "O", "N", "D"],
  abbreviated: ["Jan", "Fra", "Mar", "Apr", "Mej", "Ġun", "Lul", "Aww", "Set", "Ott", "Nov", "Diċ"],
  wide: ["Jannar", "Frar", "Marzu", "April", "Mejju", "Ġunju", "Lulju", "Awwissu", "Settembru", "Ottubru", "Novembru", "Diċembru"]
};
var dayValues$t = {
  narrow: ["Ħ", "T", "T", "E", "Ħ", "Ġ", "S"],
  short: ["Ħa", "Tn", "Tl", "Er", "Ħa", "Ġi", "Si"],
  abbreviated: ["Ħad", "Tne", "Tli", "Erb", "Ħam", "Ġim", "Sib"],
  wide: ["Il-Ħadd", "It-Tnejn", "It-Tlieta", "L-Erbgħa", "Il-Ħamis", "Il-Ġimgħa", "Is-Sibt"]
};
var dayPeriodValues$t = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "nofsillejl",
    noon: "nofsinhar",
    morning: "għodwa",
    afternoon: "wara nofsinhar",
    evening: "filgħaxija",
    night: "lejl"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "nofsillejl",
    noon: "nofsinhar",
    morning: "għodwa",
    afternoon: "wara nofsinhar",
    evening: "filgħaxija",
    night: "lejl"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "nofsillejl",
    noon: "nofsinhar",
    morning: "għodwa",
    afternoon: "wara nofsinhar",
    evening: "filgħaxija",
    night: "lejl"
  }
};
var formattingDayPeriodValues$n = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "f'nofsillejl",
    noon: "f'nofsinhar",
    morning: "filgħodu",
    afternoon: "wara nofsinhar",
    evening: "filgħaxija",
    night: "billejl"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "f'nofsillejl",
    noon: "f'nofsinhar",
    morning: "filgħodu",
    afternoon: "wara nofsinhar",
    evening: "filgħaxija",
    night: "billejl"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "f'nofsillejl",
    noon: "f'nofsinhar",
    morning: "filgħodu",
    afternoon: "wara nofsinhar",
    evening: "filgħaxija",
    night: "billejl"
  }
};
var ordinalNumber$t = function (e, a) {
  var t = Number(e);
  return t + "º";
};
localize$t.localize = {
  ordinalNumber: ordinalNumber$t,
  era: (0, _index$1X.buildLocalizeFn)({
    values: eraValues$t,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1X.buildLocalizeFn)({
    values: quarterValues$t,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1X.buildLocalizeFn)({
    values: monthValues$t,
    defaultWidth: "wide"
  }),
  day: (0, _index$1X.buildLocalizeFn)({
    values: dayValues$t,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$1X.buildLocalizeFn)({
    values: dayPeriodValues$t,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$n,
    defaultFormattingWidth: "wide"
  })
};
var match$t = {};
match$t.match = void 0;
var _index$1W = buildMatchFn;
var _index2$Y = buildMatchPatternFn;
var matchOrdinalNumberPattern$t = /^(\d+)(º)?/i;
var parseOrdinalNumberPattern$t = /\d+/i;
var matchEraPatterns$t = {
  narrow: /^(q|w)/i,
  abbreviated: /^(q\.?\s?k\.?|b\.?\s?c\.?\s?e\.?|w\.?\s?k\.?)/i,
  wide: /^(qabel kristu|before common era|wara kristu|common era)/i
};
var parseEraPatterns$t = {
  any: [/^(q|b)/i, /^(w|c)/i]
};
var matchQuarterPatterns$t = {
  narrow: /^[1234]/i,
  abbreviated: /^k[1234]/i,
  wide: /^[1234](\.)? kwart/i
};
var parseQuarterPatterns$t = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$t = {
  narrow: /^[jfmaglsond]/i,
  abbreviated: /^(jan|fra|mar|apr|mej|ġun|lul|aww|set|ott|nov|diċ)/i,
  wide: /^(jannar|frar|marzu|april|mejju|ġunju|lulju|awwissu|settembru|ottubru|novembru|diċembru)/i
};
var parseMonthPatterns$t = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^ġ/i, /^l/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^mej/i, /^ġ/i, /^l/i, /^aw/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$t = {
  narrow: /^[ħteġs]/i,
  short: /^(ħa|tn|tl|er|ħa|ġi|si)/i,
  abbreviated: /^(ħad|tne|tli|erb|ħam|ġim|sib)/i,
  wide: /^(il-ħadd|it-tnejn|it-tlieta|l-erbgħa|il-ħamis|il-ġimgħa|is-sibt)/i
};
var parseDayPatterns$t = {
  narrow: [/^ħ/i, /^t/i, /^t/i, /^e/i, /^ħ/i, /^ġ/i, /^s/i],
  any: [/^(il-)?ħad/i, /^(it-)?tn/i, /^(it-)?tl/i, /^(l-)?er/i, /^(il-)?ham/i, /^(il-)?ġi/i, /^(is-)?si/i]
};
var matchDayPeriodPatterns$t = {
  narrow: /^(a|p|f'nofsillejl|f'nofsinhar|(ta') (għodwa|wara nofsinhar|filgħaxija|lejl))/i,
  any: /^([ap]\.?\s?m\.?|f'nofsillejl|f'nofsinhar|(ta') (għodwa|wara nofsinhar|filgħaxija|lejl))/i
};
var parseDayPeriodPatterns$t = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^f'nofsillejl/i,
    noon: /^f'nofsinhar/i,
    morning: /għodwa/i,
    afternoon: /wara(\s.*)nofsinhar/i,
    evening: /filgħaxija/i,
    night: /lejl/i
  }
};
match$t.match = {
  ordinalNumber: (0, _index2$Y.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$t,
    parsePattern: parseOrdinalNumberPattern$t,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1W.buildMatchFn)({
    matchPatterns: matchEraPatterns$t,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$t,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1W.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$t,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$t,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1W.buildMatchFn)({
    matchPatterns: matchMonthPatterns$t,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$t,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1W.buildMatchFn)({
    matchPatterns: matchDayPatterns$t,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$t,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1W.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$t,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$t,
    defaultParseWidth: "any"
  })
};
mt.mt = void 0;
var _index$1V = formatDistance$X;
var _index2$X = formatLong$t;
var _index3$t = formatRelative$X;
var _index4$t = localize$t;
var _index5$t = match$t;
mt.mt = {
  code: "mt",
  formatDistance: _index$1V.formatDistance,
  formatLong: _index2$X.formatLong,
  formatRelative: _index3$t.formatRelative,
  localize: _index4$t.localize,
  match: _index5$t.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var nb = {};
var formatDistance$V = {};
formatDistance$V.formatDistance = void 0;
var formatDistanceLocale$s = {
  lessThanXSeconds: {
    one: "mindre enn ett sekund",
    other: "mindre enn {{count}} sekunder"
  },
  xSeconds: {
    one: "ett sekund",
    other: "{{count}} sekunder"
  },
  halfAMinute: "et halvt minutt",
  lessThanXMinutes: {
    one: "mindre enn ett minutt",
    other: "mindre enn {{count}} minutter"
  },
  xMinutes: {
    one: "ett minutt",
    other: "{{count}} minutter"
  },
  aboutXHours: {
    one: "omtrent en time",
    other: "omtrent {{count}} timer"
  },
  xHours: {
    one: "en time",
    other: "{{count}} timer"
  },
  xDays: {
    one: "en dag",
    other: "{{count}} dager"
  },
  aboutXWeeks: {
    one: "omtrent en uke",
    other: "omtrent {{count}} uker"
  },
  xWeeks: {
    one: "en uke",
    other: "{{count}} uker"
  },
  aboutXMonths: {
    one: "omtrent en måned",
    other: "omtrent {{count}} måneder"
  },
  xMonths: {
    one: "en måned",
    other: "{{count}} måneder"
  },
  aboutXYears: {
    one: "omtrent ett år",
    other: "omtrent {{count}} år"
  },
  xYears: {
    one: "ett år",
    other: "{{count}} år"
  },
  overXYears: {
    one: "over ett år",
    other: "over {{count}} år"
  },
  almostXYears: {
    one: "nesten ett år",
    other: "nesten {{count}} år"
  }
};
var formatDistance$U = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$s[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "om " + r;
    } else {
      return r + " siden";
    }
  }
  return r;
};
formatDistance$V.formatDistance = formatDistance$U;
var formatLong$s = {};
formatLong$s.formatLong = void 0;
var _index$1U = buildFormatLongFn;
var dateFormats$s = {
  full: "EEEE d. MMMM y",
  long: "d. MMMM y",
  medium: "d. MMM y",
  short: "dd.MM.y"
};
var timeFormats$s = {
  full: "'kl'. HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$s = {
  full: "{{date}} 'kl.' {{time}}",
  long: "{{date}} 'kl.' {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$s.formatLong = {
  date: (0, _index$1U.buildFormatLongFn)({
    formats: dateFormats$s,
    defaultWidth: "full"
  }),
  time: (0, _index$1U.buildFormatLongFn)({
    formats: timeFormats$s,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1U.buildFormatLongFn)({
    formats: dateTimeFormats$s,
    defaultWidth: "full"
  })
};
var formatRelative$V = {};
formatRelative$V.formatRelative = void 0;
var formatRelativeLocale$s = {
  lastWeek: "'forrige' eeee 'kl.' p",
  yesterday: "'i går kl.' p",
  today: "'i dag kl.' p",
  tomorrow: "'i morgen kl.' p",
  nextWeek: "EEEE 'kl.' p",
  other: "P"
};
var formatRelative$U = function (e, a, t, r) {
  return formatRelativeLocale$s[e];
};
formatRelative$V.formatRelative = formatRelative$U;
var localize$s = {};
localize$s.localize = void 0;
var _index$1T = buildLocalizeFn;
var eraValues$s = {
  narrow: ["f.Kr.", "e.Kr."],
  abbreviated: ["f.Kr.", "e.Kr."],
  wide: ["før Kristus", "etter Kristus"]
};
var quarterValues$s = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1. kvartal", "2. kvartal", "3. kvartal", "4. kvartal"]
};
var monthValues$s = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["jan.", "feb.", "mars", "apr.", "mai", "juni", "juli", "aug.", "sep.", "okt.", "nov.", "des."],
  wide: ["januar", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"]
};
var dayValues$s = {
  narrow: ["S", "M", "T", "O", "T", "F", "L"],
  short: ["sø", "ma", "ti", "on", "to", "fr", "lø"],
  abbreviated: ["søn", "man", "tir", "ons", "tor", "fre", "lør"],
  wide: ["søndag", "mandag", "tirsdag", "onsdag", "torsdag", "fredag", "lørdag"]
};
var dayPeriodValues$s = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "midnatt",
    noon: "middag",
    morning: "på morg.",
    afternoon: "på etterm.",
    evening: "på kvelden",
    night: "på natten"
  },
  abbreviated: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnatt",
    noon: "middag",
    morning: "på morg.",
    afternoon: "på etterm.",
    evening: "på kvelden",
    night: "på natten"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnatt",
    noon: "middag",
    morning: "på morgenen",
    afternoon: "på ettermiddagen",
    evening: "på kvelden",
    night: "på natten"
  }
};
var ordinalNumber$s = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$s.localize = {
  ordinalNumber: ordinalNumber$s,
  era: (0, _index$1T.buildLocalizeFn)({
    values: eraValues$s,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1T.buildLocalizeFn)({
    values: quarterValues$s,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1T.buildLocalizeFn)({
    values: monthValues$s,
    defaultWidth: "wide"
  }),
  day: (0, _index$1T.buildLocalizeFn)({
    values: dayValues$s,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$1T.buildLocalizeFn)({
    values: dayPeriodValues$s,
    defaultWidth: "wide"
  })
};
var match$s = {};
match$s.match = void 0;
var _index$1S = buildMatchFn;
var _index2$W = buildMatchPatternFn;
var matchOrdinalNumberPattern$s = /^(\d+)\.?/i;
var parseOrdinalNumberPattern$s = /\d+/i;
var matchEraPatterns$s = {
  narrow: /^(f\.? ?Kr\.?|fvt\.?|e\.? ?Kr\.?|evt\.?)/i,
  abbreviated: /^(f\.? ?Kr\.?|fvt\.?|e\.? ?Kr\.?|evt\.?)/i,
  wide: /^(før Kristus|før vår tid|etter Kristus|vår tid)/i
};
var parseEraPatterns$s = {
  any: [/^f/i, /^e/i]
};
var matchQuarterPatterns$s = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](\.)? kvartal/i
};
var parseQuarterPatterns$s = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$s = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mars?|apr|mai|juni?|juli?|aug|sep|okt|nov|des)\.?/i,
  wide: /^(januar|februar|mars|april|mai|juni|juli|august|september|oktober|november|desember)/i
};
var parseMonthPatterns$s = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^mai/i, /^jun/i, /^jul/i, /^aug/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$s = {
  narrow: /^[smtofl]/i,
  short: /^(sø|ma|ti|on|to|fr|lø)/i,
  abbreviated: /^(søn|man|tir|ons|tor|fre|lør)/i,
  wide: /^(søndag|mandag|tirsdag|onsdag|torsdag|fredag|lørdag)/i
};
var parseDayPatterns$s = {
  any: [/^s/i, /^m/i, /^ti/i, /^o/i, /^to/i, /^f/i, /^l/i]
};
var matchDayPeriodPatterns$s = {
  narrow: /^(midnatt|middag|(på) (morgenen|ettermiddagen|kvelden|natten)|[ap])/i,
  any: /^([ap]\.?\s?m\.?|midnatt|middag|(på) (morgenen|ettermiddagen|kvelden|natten))/i
};
var parseDayPeriodPatterns$s = {
  any: {
    am: /^a(\.?\s?m\.?)?$/i,
    pm: /^p(\.?\s?m\.?)?$/i,
    midnight: /^midn/i,
    noon: /^midd/i,
    morning: /morgen/i,
    afternoon: /ettermiddag/i,
    evening: /kveld/i,
    night: /natt/i
  }
};
match$s.match = {
  ordinalNumber: (0, _index2$W.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$s,
    parsePattern: parseOrdinalNumberPattern$s,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1S.buildMatchFn)({
    matchPatterns: matchEraPatterns$s,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$s,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1S.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$s,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$s,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1S.buildMatchFn)({
    matchPatterns: matchMonthPatterns$s,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$s,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1S.buildMatchFn)({
    matchPatterns: matchDayPatterns$s,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$s,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1S.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$s,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$s,
    defaultParseWidth: "any"
  })
};
nb.nb = void 0;
var _index$1R = formatDistance$V;
var _index2$V = formatLong$s;
var _index3$s = formatRelative$V;
var _index4$s = localize$s;
var _index5$s = match$s;
nb.nb = {
  code: "nb",
  formatDistance: _index$1R.formatDistance,
  formatLong: _index2$V.formatLong,
  formatRelative: _index3$s.formatRelative,
  localize: _index4$s.localize,
  match: _index5$s.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var nl = {};
var formatDistance$T = {};
formatDistance$T.formatDistance = void 0;
var formatDistanceLocale$r = {
  lessThanXSeconds: {
    one: "minder dan een seconde",
    other: "minder dan {{count}} seconden"
  },
  xSeconds: {
    one: "1 seconde",
    other: "{{count}} seconden"
  },
  halfAMinute: "een halve minuut",
  lessThanXMinutes: {
    one: "minder dan een minuut",
    other: "minder dan {{count}} minuten"
  },
  xMinutes: {
    one: "een minuut",
    other: "{{count}} minuten"
  },
  aboutXHours: {
    one: "ongeveer 1 uur",
    other: "ongeveer {{count}} uur"
  },
  xHours: {
    one: "1 uur",
    other: "{{count}} uur"
  },
  xDays: {
    one: "1 dag",
    other: "{{count}} dagen"
  },
  aboutXWeeks: {
    one: "ongeveer 1 week",
    other: "ongeveer {{count}} weken"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weken"
  },
  aboutXMonths: {
    one: "ongeveer 1 maand",
    other: "ongeveer {{count}} maanden"
  },
  xMonths: {
    one: "1 maand",
    other: "{{count}} maanden"
  },
  aboutXYears: {
    one: "ongeveer 1 jaar",
    other: "ongeveer {{count}} jaar"
  },
  xYears: {
    one: "1 jaar",
    other: "{{count}} jaar"
  },
  overXYears: {
    one: "meer dan 1 jaar",
    other: "meer dan {{count}} jaar"
  },
  almostXYears: {
    one: "bijna 1 jaar",
    other: "bijna {{count}} jaar"
  }
};
var formatDistance$S = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$r[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "over " + r;
    } else {
      return r + " geleden";
    }
  }
  return r;
};
formatDistance$T.formatDistance = formatDistance$S;
var formatLong$r = {};
formatLong$r.formatLong = void 0;
var _index$1Q = buildFormatLongFn;
var dateFormats$r = {
  full: "EEEE d MMMM y",
  long: "d MMMM y",
  medium: "d MMM y",
  short: "dd-MM-y"
};
var timeFormats$r = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$r = {
  full: "{{date}} 'om' {{time}}",
  long: "{{date}} 'om' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$r.formatLong = {
  date: (0, _index$1Q.buildFormatLongFn)({
    formats: dateFormats$r,
    defaultWidth: "full"
  }),
  time: (0, _index$1Q.buildFormatLongFn)({
    formats: timeFormats$r,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1Q.buildFormatLongFn)({
    formats: dateTimeFormats$r,
    defaultWidth: "full"
  })
};
var formatRelative$T = {};
formatRelative$T.formatRelative = void 0;
var formatRelativeLocale$r = {
  lastWeek: "'afgelopen' eeee 'om' p",
  yesterday: "'gisteren om' p",
  today: "'vandaag om' p",
  tomorrow: "'morgen om' p",
  nextWeek: "eeee 'om' p",
  other: "P"
};
var formatRelative$S = function (e, a, t, r) {
  return formatRelativeLocale$r[e];
};
formatRelative$T.formatRelative = formatRelative$S;
var localize$r = {};
localize$r.localize = void 0;
var _index$1P = buildLocalizeFn;
var eraValues$r = {
  narrow: ["v.C.", "n.C."],
  abbreviated: ["v.Chr.", "n.Chr."],
  wide: ["voor Christus", "na Christus"]
};
var quarterValues$r = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["K1", "K2", "K3", "K4"],
  wide: ["1e kwartaal", "2e kwartaal", "3e kwartaal", "4e kwartaal"]
};
var monthValues$r = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["jan.", "feb.", "mrt.", "apr.", "mei", "jun.", "jul.", "aug.", "sep.", "okt.", "nov.", "dec."],
  wide: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"]
};
var dayValues$r = {
  narrow: ["Z", "M", "D", "W", "D", "V", "Z"],
  short: ["zo", "ma", "di", "wo", "do", "vr", "za"],
  abbreviated: ["zon", "maa", "din", "woe", "don", "vri", "zat"],
  wide: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"]
};
var dayPeriodValues$r = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "middernacht",
    noon: "het middaguur",
    morning: "'s ochtends",
    afternoon: "'s middags",
    evening: "'s avonds",
    night: "'s nachts"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "middernacht",
    noon: "het middaguur",
    morning: "'s ochtends",
    afternoon: "'s middags",
    evening: "'s avonds",
    night: "'s nachts"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "middernacht",
    noon: "het middaguur",
    morning: "'s ochtends",
    afternoon: "'s middags",
    evening: "'s avonds",
    night: "'s nachts"
  }
};
var ordinalNumber$r = function (e, a) {
  var t = Number(e);
  return t + "e";
};
localize$r.localize = {
  ordinalNumber: ordinalNumber$r,
  era: (0, _index$1P.buildLocalizeFn)({
    values: eraValues$r,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1P.buildLocalizeFn)({
    values: quarterValues$r,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1P.buildLocalizeFn)({
    values: monthValues$r,
    defaultWidth: "wide"
  }),
  day: (0, _index$1P.buildLocalizeFn)({
    values: dayValues$r,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$1P.buildLocalizeFn)({
    values: dayPeriodValues$r,
    defaultWidth: "wide"
  })
};
var match$r = {};
match$r.match = void 0;
var _index$1O = buildMatchFn;
var _index2$U = buildMatchPatternFn;
var matchOrdinalNumberPattern$r = /^(\d+)e?/i;
var parseOrdinalNumberPattern$r = /\d+/i;
var matchEraPatterns$r = {
  narrow: /^([vn]\.? ?C\.?)/,
  abbreviated: /^([vn]\. ?Chr\.?)/,
  wide: /^((voor|na) Christus)/
};
var parseEraPatterns$r = {
  any: [/^v/, /^n/]
};
var matchQuarterPatterns$r = {
  narrow: /^[1234]/i,
  abbreviated: /^K[1234]/i,
  wide: /^[1234]e kwartaal/i
};
var parseQuarterPatterns$r = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$r = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan.|feb.|mrt.|apr.|mei|jun.|jul.|aug.|sep.|okt.|nov.|dec.)/i,
  wide: /^(januari|februari|maart|april|mei|juni|juli|augustus|september|oktober|november|december)/i
};
var parseMonthPatterns$r = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^jan/i, /^feb/i, /^m(r|a)/i, /^apr/i, /^mei/i, /^jun/i, /^jul/i, /^aug/i, /^sep/i, /^okt/i, /^nov/i, /^dec/i]
};
var matchDayPatterns$r = {
  narrow: /^[zmdwv]/i,
  short: /^(zo|ma|di|wo|do|vr|za)/i,
  abbreviated: /^(zon|maa|din|woe|don|vri|zat)/i,
  wide: /^(zondag|maandag|dinsdag|woensdag|donderdag|vrijdag|zaterdag)/i
};
var parseDayPatterns$r = {
  narrow: [/^z/i, /^m/i, /^d/i, /^w/i, /^d/i, /^v/i, /^z/i],
  any: [/^zo/i, /^ma/i, /^di/i, /^wo/i, /^do/i, /^vr/i, /^za/i]
};
var matchDayPeriodPatterns$r = {
  any: /^(am|pm|middernacht|het middaguur|'s (ochtends|middags|avonds|nachts))/i
};
var parseDayPeriodPatterns$r = {
  any: {
    am: /^am/i,
    pm: /^pm/i,
    midnight: /^middernacht/i,
    noon: /^het middaguur/i,
    morning: /ochtend/i,
    afternoon: /middag/i,
    evening: /avond/i,
    night: /nacht/i
  }
};
match$r.match = {
  ordinalNumber: (0, _index2$U.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$r,
    parsePattern: parseOrdinalNumberPattern$r,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1O.buildMatchFn)({
    matchPatterns: matchEraPatterns$r,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$r,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1O.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$r,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$r,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1O.buildMatchFn)({
    matchPatterns: matchMonthPatterns$r,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$r,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1O.buildMatchFn)({
    matchPatterns: matchDayPatterns$r,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$r,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1O.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$r,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$r,
    defaultParseWidth: "any"
  })
};
nl.nl = void 0;
var _index$1N = formatDistance$T;
var _index2$T = formatLong$r;
var _index3$r = formatRelative$T;
var _index4$r = localize$r;
var _index5$r = match$r;
nl.nl = {
  code: "nl",
  formatDistance: _index$1N.formatDistance,
  formatLong: _index2$T.formatLong,
  formatRelative: _index3$r.formatRelative,
  localize: _index4$r.localize,
  match: _index5$r.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var nlBE = {};
var formatDistance$R = {};
formatDistance$R.formatDistance = void 0;
var formatDistanceLocale$q = {
  lessThanXSeconds: {
    one: "minder dan een seconde",
    other: "minder dan {{count}} seconden"
  },
  xSeconds: {
    one: "1 seconde",
    other: "{{count}} seconden"
  },
  halfAMinute: "een halve minuut",
  lessThanXMinutes: {
    one: "minder dan een minuut",
    other: "minder dan {{count}} minuten"
  },
  xMinutes: {
    one: "een minuut",
    other: "{{count}} minuten"
  },
  aboutXHours: {
    one: "ongeveer 1 uur",
    other: "ongeveer {{count}} uur"
  },
  xHours: {
    one: "1 uur",
    other: "{{count}} uur"
  },
  xDays: {
    one: "1 dag",
    other: "{{count}} dagen"
  },
  aboutXWeeks: {
    one: "ongeveer 1 week",
    other: "ongeveer {{count}} weken"
  },
  xWeeks: {
    one: "1 week",
    other: "{{count}} weken"
  },
  aboutXMonths: {
    one: "ongeveer 1 maand",
    other: "ongeveer {{count}} maanden"
  },
  xMonths: {
    one: "1 maand",
    other: "{{count}} maanden"
  },
  aboutXYears: {
    one: "ongeveer 1 jaar",
    other: "ongeveer {{count}} jaar"
  },
  xYears: {
    one: "1 jaar",
    other: "{{count}} jaar"
  },
  overXYears: {
    one: "meer dan 1 jaar",
    other: "meer dan {{count}} jaar"
  },
  almostXYears: {
    one: "bijna 1 jaar",
    other: "bijna {{count}} jaar"
  }
};
var formatDistance$Q = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$q[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "over " + r;
    } else {
      return r + " geleden";
    }
  }
  return r;
};
formatDistance$R.formatDistance = formatDistance$Q;
var formatLong$q = {};
formatLong$q.formatLong = void 0;
var _index$1M = buildFormatLongFn;
var dateFormats$q = {
  full: "EEEE d MMMM y",
  long: "d MMMM y",
  medium: "d MMM y",
  short: "dd.MM.y"
};
var timeFormats$q = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$q = {
  full: "{{date}} 'om' {{time}}",
  long: "{{date}} 'om' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$q.formatLong = {
  date: (0, _index$1M.buildFormatLongFn)({
    formats: dateFormats$q,
    defaultWidth: "full"
  }),
  time: (0, _index$1M.buildFormatLongFn)({
    formats: timeFormats$q,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1M.buildFormatLongFn)({
    formats: dateTimeFormats$q,
    defaultWidth: "full"
  })
};
var formatRelative$R = {};
formatRelative$R.formatRelative = void 0;
var formatRelativeLocale$q = {
  lastWeek: "'vorige' eeee 'om' p",
  yesterday: "'gisteren om' p",
  today: "'vandaag om' p",
  tomorrow: "'morgen om' p",
  nextWeek: "eeee 'om' p",
  other: "P"
};
var formatRelative$Q = function (e, a, t, r) {
  return formatRelativeLocale$q[e];
};
formatRelative$R.formatRelative = formatRelative$Q;
var localize$q = {};
localize$q.localize = void 0;
var _index$1L = buildLocalizeFn;
var eraValues$q = {
  narrow: ["v.C.", "n.C."],
  abbreviated: ["v.Chr.", "n.Chr."],
  wide: ["voor Christus", "na Christus"]
};
var quarterValues$q = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["K1", "K2", "K3", "K4"],
  wide: ["1e kwartaal", "2e kwartaal", "3e kwartaal", "4e kwartaal"]
};
var monthValues$q = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["jan.", "feb.", "mrt.", "apr.", "mei", "jun.", "jul.", "aug.", "sep.", "okt.", "nov.", "dec."],
  wide: ["januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december"]
};
var dayValues$q = {
  narrow: ["Z", "M", "D", "W", "D", "V", "Z"],
  short: ["zo", "ma", "di", "wo", "do", "vr", "za"],
  abbreviated: ["zon", "maa", "din", "woe", "don", "vri", "zat"],
  wide: ["zondag", "maandag", "dinsdag", "woensdag", "donderdag", "vrijdag", "zaterdag"]
};
var dayPeriodValues$q = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "middernacht",
    noon: "het middag",
    morning: "'s ochtends",
    afternoon: "'s namiddags",
    evening: "'s avonds",
    night: "'s nachts"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "middernacht",
    noon: "het middag",
    morning: "'s ochtends",
    afternoon: "'s namiddags",
    evening: "'s avonds",
    night: "'s nachts"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "middernacht",
    noon: "het middag",
    morning: "'s ochtends",
    afternoon: "'s namiddags",
    evening: "'s avonds",
    night: "'s nachts"
  }
};
var ordinalNumber$q = function (e, a) {
  var t = Number(e);
  return t + "e";
};
localize$q.localize = {
  ordinalNumber: ordinalNumber$q,
  era: (0, _index$1L.buildLocalizeFn)({
    values: eraValues$q,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1L.buildLocalizeFn)({
    values: quarterValues$q,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1L.buildLocalizeFn)({
    values: monthValues$q,
    defaultWidth: "wide"
  }),
  day: (0, _index$1L.buildLocalizeFn)({
    values: dayValues$q,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$1L.buildLocalizeFn)({
    values: dayPeriodValues$q,
    defaultWidth: "wide"
  })
};
var match$q = {};
match$q.match = void 0;
var _index$1K = buildMatchFn;
var _index2$S = buildMatchPatternFn;
var matchOrdinalNumberPattern$q = /^(\d+)e?/i;
var parseOrdinalNumberPattern$q = /\d+/i;
var matchEraPatterns$q = {
  narrow: /^([vn]\.? ?C\.?)/,
  abbreviated: /^([vn]\. ?Chr\.?)/,
  wide: /^((voor|na) Christus)/
};
var parseEraPatterns$q = {
  any: [/^v/, /^n/]
};
var matchQuarterPatterns$q = {
  narrow: /^[1234]/i,
  abbreviated: /^K[1234]/i,
  wide: /^[1234]e kwartaal/i
};
var parseQuarterPatterns$q = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$q = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan.|feb.|mrt.|apr.|mei|jun.|jul.|aug.|sep.|okt.|nov.|dec.)/i,
  wide: /^(januari|februari|maart|april|mei|juni|juli|augustus|september|oktober|november|december)/i
};
var parseMonthPatterns$q = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^jan/i, /^feb/i, /^m(r|a)/i, /^apr/i, /^mei/i, /^jun/i, /^jul/i, /^aug/i, /^sep/i, /^okt/i, /^nov/i, /^dec/i]
};
var matchDayPatterns$q = {
  narrow: /^[zmdwv]/i,
  short: /^(zo|ma|di|wo|do|vr|za)/i,
  abbreviated: /^(zon|maa|din|woe|don|vri|zat)/i,
  wide: /^(zondag|maandag|dinsdag|woensdag|donderdag|vrijdag|zaterdag)/i
};
var parseDayPatterns$q = {
  narrow: [/^z/i, /^m/i, /^d/i, /^w/i, /^d/i, /^v/i, /^z/i],
  any: [/^zo/i, /^ma/i, /^di/i, /^wo/i, /^do/i, /^vr/i, /^za/i]
};
var matchDayPeriodPatterns$q = {
  any: /^(am|pm|middernacht|het middaguur|'s (ochtends|middags|avonds|nachts))/i
};
var parseDayPeriodPatterns$q = {
  any: {
    am: /^am/i,
    pm: /^pm/i,
    midnight: /^middernacht/i,
    noon: /^het middaguur/i,
    morning: /ochtend/i,
    afternoon: /middag/i,
    evening: /avond/i,
    night: /nacht/i
  }
};
match$q.match = {
  ordinalNumber: (0, _index2$S.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$q,
    parsePattern: parseOrdinalNumberPattern$q,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1K.buildMatchFn)({
    matchPatterns: matchEraPatterns$q,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$q,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1K.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$q,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$q,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1K.buildMatchFn)({
    matchPatterns: matchMonthPatterns$q,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$q,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1K.buildMatchFn)({
    matchPatterns: matchDayPatterns$q,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$q,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1K.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$q,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$q,
    defaultParseWidth: "any"
  })
};
nlBE.nlBE = void 0;
var _index$1J = formatDistance$R;
var _index2$R = formatLong$q;
var _index3$q = formatRelative$R;
var _index4$q = localize$q;
var _index5$q = match$q;
nlBE.nlBE = {
  code: "nl-BE",
  formatDistance: _index$1J.formatDistance,
  formatLong: _index2$R.formatLong,
  formatRelative: _index3$q.formatRelative,
  localize: _index4$q.localize,
  match: _index5$q.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var nn = {};
var formatDistance$P = {};
formatDistance$P.formatDistance = void 0;
var formatDistanceLocale$p = {
  lessThanXSeconds: {
    one: "mindre enn eitt sekund",
    other: "mindre enn {{count}} sekund"
  },
  xSeconds: {
    one: "eitt sekund",
    other: "{{count}} sekund"
  },
  halfAMinute: "eit halvt minutt",
  lessThanXMinutes: {
    one: "mindre enn eitt minutt",
    other: "mindre enn {{count}} minutt"
  },
  xMinutes: {
    one: "eitt minutt",
    other: "{{count}} minutt"
  },
  aboutXHours: {
    one: "omtrent ein time",
    other: "omtrent {{count}} timar"
  },
  xHours: {
    one: "ein time",
    other: "{{count}} timar"
  },
  xDays: {
    one: "ein dag",
    other: "{{count}} dagar"
  },
  aboutXWeeks: {
    one: "omtrent ei veke",
    other: "omtrent {{count}} veker"
  },
  xWeeks: {
    one: "ei veke",
    other: "{{count}} veker"
  },
  aboutXMonths: {
    one: "omtrent ein månad",
    other: "omtrent {{count}} månader"
  },
  xMonths: {
    one: "ein månad",
    other: "{{count}} månader"
  },
  aboutXYears: {
    one: "omtrent eitt år",
    other: "omtrent {{count}} år"
  },
  xYears: {
    one: "eitt år",
    other: "{{count}} år"
  },
  overXYears: {
    one: "over eitt år",
    other: "over {{count}} år"
  },
  almostXYears: {
    one: "nesten eitt år",
    other: "nesten {{count}} år"
  }
};
var wordMapping$1 = ["null", "ein", "to", "tre", "fire", "fem", "seks", "sju", "åtte", "ni", "ti", "elleve", "tolv"];
var formatDistance$O = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$p[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", a < 13 ? wordMapping$1[a] : String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "om " + r;
    } else {
      return r + " sidan";
    }
  }
  return r;
};
formatDistance$P.formatDistance = formatDistance$O;
var formatLong$p = {};
formatLong$p.formatLong = void 0;
var _index$1I = buildFormatLongFn;
var dateFormats$p = {
  full: "EEEE d. MMMM y",
  long: "d. MMMM y",
  medium: "d. MMM y",
  short: "dd.MM.y"
};
var timeFormats$p = {
  full: "'kl'. HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$p = {
  full: "{{date}} 'kl.' {{time}}",
  long: "{{date}} 'kl.' {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$p.formatLong = {
  date: (0, _index$1I.buildFormatLongFn)({
    formats: dateFormats$p,
    defaultWidth: "full"
  }),
  time: (0, _index$1I.buildFormatLongFn)({
    formats: timeFormats$p,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1I.buildFormatLongFn)({
    formats: dateTimeFormats$p,
    defaultWidth: "full"
  })
};
var formatRelative$P = {};
formatRelative$P.formatRelative = void 0;
var formatRelativeLocale$p = {
  lastWeek: "'førre' eeee 'kl.' p",
  yesterday: "'i går kl.' p",
  today: "'i dag kl.' p",
  tomorrow: "'i morgon kl.' p",
  nextWeek: "EEEE 'kl.' p",
  other: "P"
};
var formatRelative$O = function (e, a, t, r) {
  return formatRelativeLocale$p[e];
};
formatRelative$P.formatRelative = formatRelative$O;
var localize$p = {};
localize$p.localize = void 0;
var _index$1H = buildLocalizeFn;
var eraValues$p = {
  narrow: ["f.Kr.", "e.Kr."],
  abbreviated: ["f.Kr.", "e.Kr."],
  wide: ["før Kristus", "etter Kristus"]
};
var quarterValues$p = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1. kvartal", "2. kvartal", "3. kvartal", "4. kvartal"]
};
var monthValues$p = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["jan.", "feb.", "mars", "apr.", "mai", "juni", "juli", "aug.", "sep.", "okt.", "nov.", "des."],
  wide: ["januar", "februar", "mars", "april", "mai", "juni", "juli", "august", "september", "oktober", "november", "desember"]
};
var dayValues$p = {
  narrow: ["S", "M", "T", "O", "T", "F", "L"],
  short: ["su", "må", "ty", "on", "to", "fr", "lau"],
  abbreviated: ["sun", "mån", "tys", "ons", "tor", "fre", "laur"],
  wide: ["sundag", "måndag", "tysdag", "onsdag", "torsdag", "fredag", "laurdag"]
};
var dayPeriodValues$p = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "midnatt",
    noon: "middag",
    morning: "på morg.",
    afternoon: "på etterm.",
    evening: "på kvelden",
    night: "på natta"
  },
  abbreviated: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnatt",
    noon: "middag",
    morning: "på morg.",
    afternoon: "på etterm.",
    evening: "på kvelden",
    night: "på natta"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "midnatt",
    noon: "middag",
    morning: "på morgonen",
    afternoon: "på ettermiddagen",
    evening: "på kvelden",
    night: "på natta"
  }
};
var ordinalNumber$p = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$p.localize = {
  ordinalNumber: ordinalNumber$p,
  era: (0, _index$1H.buildLocalizeFn)({
    values: eraValues$p,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1H.buildLocalizeFn)({
    values: quarterValues$p,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1H.buildLocalizeFn)({
    values: monthValues$p,
    defaultWidth: "wide"
  }),
  day: (0, _index$1H.buildLocalizeFn)({
    values: dayValues$p,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$1H.buildLocalizeFn)({
    values: dayPeriodValues$p,
    defaultWidth: "wide"
  })
};
var match$p = {};
match$p.match = void 0;
var _index$1G = buildMatchFn;
var _index2$Q = buildMatchPatternFn;
var matchOrdinalNumberPattern$p = /^(\d+)\.?/i;
var parseOrdinalNumberPattern$p = /\d+/i;
var matchEraPatterns$p = {
  narrow: /^(f\.? ?Kr\.?|fvt\.?|e\.? ?Kr\.?|evt\.?)/i,
  abbreviated: /^(f\.? ?Kr\.?|fvt\.?|e\.? ?Kr\.?|evt\.?)/i,
  wide: /^(før Kristus|før vår tid|etter Kristus|vår tid)/i
};
var parseEraPatterns$p = {
  any: [/^f/i, /^e/i]
};
var matchQuarterPatterns$p = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](\.)? kvartal/i
};
var parseQuarterPatterns$p = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$p = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mars?|apr|mai|juni?|juli?|aug|sep|okt|nov|des)\.?/i,
  wide: /^(januar|februar|mars|april|mai|juni|juli|august|september|oktober|november|desember)/i
};
var parseMonthPatterns$p = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^mai/i, /^jun/i, /^jul/i, /^aug/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$p = {
  narrow: /^[smtofl]/i,
  short: /^(su|må|ty|on|to|fr|la)/i,
  abbreviated: /^(sun|mån|tys|ons|tor|fre|laur)/i,
  wide: /^(sundag|måndag|tysdag|onsdag|torsdag|fredag|laurdag)/i
};
var parseDayPatterns$p = {
  any: [/^s/i, /^m/i, /^ty/i, /^o/i, /^to/i, /^f/i, /^l/i]
};
var matchDayPeriodPatterns$p = {
  narrow: /^(midnatt|middag|(på) (morgonen|ettermiddagen|kvelden|natta)|[ap])/i,
  any: /^([ap]\.?\s?m\.?|midnatt|middag|(på) (morgonen|ettermiddagen|kvelden|natta))/i
};
var parseDayPeriodPatterns$p = {
  any: {
    am: /^a(\.?\s?m\.?)?$/i,
    pm: /^p(\.?\s?m\.?)?$/i,
    midnight: /^midn/i,
    noon: /^midd/i,
    morning: /morgon/i,
    afternoon: /ettermiddag/i,
    evening: /kveld/i,
    night: /natt/i
  }
};
match$p.match = {
  ordinalNumber: (0, _index2$Q.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$p,
    parsePattern: parseOrdinalNumberPattern$p,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1G.buildMatchFn)({
    matchPatterns: matchEraPatterns$p,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$p,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1G.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$p,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$p,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1G.buildMatchFn)({
    matchPatterns: matchMonthPatterns$p,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$p,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1G.buildMatchFn)({
    matchPatterns: matchDayPatterns$p,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$p,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1G.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$p,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$p,
    defaultParseWidth: "any"
  })
};
nn.nn = void 0;
var _index$1F = formatDistance$P;
var _index2$P = formatLong$p;
var _index3$p = formatRelative$P;
var _index4$p = localize$p;
var _index5$p = match$p;
nn.nn = {
  code: "nn",
  formatDistance: _index$1F.formatDistance,
  formatLong: _index2$P.formatLong,
  formatRelative: _index3$p.formatRelative,
  localize: _index4$p.localize,
  match: _index5$p.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var oc = {};
var formatDistance$N = {};
formatDistance$N.formatDistance = void 0;
var formatDistanceLocale$o = {
  lessThanXSeconds: {
    one: "mens d’una segonda",
    other: "mens de {{count}} segondas"
  },
  xSeconds: {
    one: "1 segonda",
    other: "{{count}} segondas"
  },
  halfAMinute: "30 segondas",
  lessThanXMinutes: {
    one: "mens d’una minuta",
    other: "mens de {{count}} minutas"
  },
  xMinutes: {
    one: "1 minuta",
    other: "{{count}} minutas"
  },
  aboutXHours: {
    one: "environ 1 ora",
    other: "environ {{count}} oras"
  },
  xHours: {
    one: "1 ora",
    other: "{{count}} oras"
  },
  xDays: {
    one: "1 jorn",
    other: "{{count}} jorns"
  },
  aboutXWeeks: {
    one: "environ 1 setmana",
    other: "environ {{count}} setmanas"
  },
  xWeeks: {
    one: "1 setmana",
    other: "{{count}} setmanas"
  },
  aboutXMonths: {
    one: "environ 1 mes",
    other: "environ {{count}} meses"
  },
  xMonths: {
    one: "1 mes",
    other: "{{count}} meses"
  },
  aboutXYears: {
    one: "environ 1 an",
    other: "environ {{count}} ans"
  },
  xYears: {
    one: "1 an",
    other: "{{count}} ans"
  },
  overXYears: {
    one: "mai d’un an",
    other: "mai de {{count}} ans"
  },
  almostXYears: {
    one: "gaireben un an",
    other: "gaireben {{count}} ans"
  }
};
var formatDistance$M = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$o[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "d’aquí " + r;
    } else {
      return "fa " + r;
    }
  }
  return r;
};
formatDistance$N.formatDistance = formatDistance$M;
var formatLong$o = {};
formatLong$o.formatLong = void 0;
var _index$1E = buildFormatLongFn;
var dateFormats$o = {
  full: "EEEE d 'de' MMMM y",
  long: "d 'de' MMMM y",
  medium: "d MMM y",
  short: "dd/MM/y"
};
var timeFormats$o = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$o = {
  full: "{{date}} 'a' {{time}}",
  long: "{{date}} 'a' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$o.formatLong = {
  date: (0, _index$1E.buildFormatLongFn)({
    formats: dateFormats$o,
    defaultWidth: "full"
  }),
  time: (0, _index$1E.buildFormatLongFn)({
    formats: timeFormats$o,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1E.buildFormatLongFn)({
    formats: dateTimeFormats$o,
    defaultWidth: "full"
  })
};
var formatRelative$N = {};
formatRelative$N.formatRelative = void 0;
var formatRelativeLocale$o = {
  lastWeek: "eeee 'passat a' p",
  yesterday: "'ièr a' p",
  today: "'uèi a' p",
  tomorrow: "'deman a' p",
  nextWeek: "eeee 'a' p",
  other: "P"
};
var formatRelative$M = function (e, a, t, r) {
  return formatRelativeLocale$o[e];
};
formatRelative$N.formatRelative = formatRelative$M;
var localize$o = {};
localize$o.localize = void 0;
var _index$1D = buildLocalizeFn;
var eraValues$o = {
  narrow: ["ab. J.C.", "apr. J.C."],
  abbreviated: ["ab. J.C.", "apr. J.C."],
  wide: ["abans Jèsus-Crist", "après Jèsus-Crist"]
};
var quarterValues$o = {
  narrow: ["T1", "T2", "T3", "T4"],
  abbreviated: ["1èr trim.", "2nd trim.", "3en trim.", "4en trim."],
  wide: ["1èr trimèstre", "2nd trimèstre", "3en trimèstre", "4en trimèstre"]
};
var monthValues$o = {
  narrow: ["GN", "FB", "MÇ", "AB", "MA", "JN", "JL", "AG", "ST", "OC", "NV", "DC"],
  abbreviated: ["gen.", "febr.", "març", "abr.", "mai", "junh", "jul.", "ag.", "set.", "oct.", "nov.", "dec."],
  wide: ["genièr", "febrièr", "març", "abril", "mai", "junh", "julhet", "agost", "setembre", "octòbre", "novembre", "decembre"]
};
var dayValues$o = {
  narrow: ["dg.", "dl.", "dm.", "dc.", "dj.", "dv.", "ds."],
  short: ["dg.", "dl.", "dm.", "dc.", "dj.", "dv.", "ds."],
  abbreviated: ["dg.", "dl.", "dm.", "dc.", "dj.", "dv.", "ds."],
  wide: ["dimenge", "diluns", "dimars", "dimècres", "dijòus", "divendres", "dissabte"]
};
var dayPeriodValues$o = {
  narrow: {
    am: "am",
    pm: "pm",
    midnight: "mièjanuèch",
    noon: "miègjorn",
    morning: "matin",
    afternoon: "aprèp-miègjorn",
    evening: "vèspre",
    night: "nuèch"
  },
  abbreviated: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "mièjanuèch",
    noon: "miègjorn",
    morning: "matin",
    afternoon: "aprèp-miègjorn",
    evening: "vèspre",
    night: "nuèch"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "mièjanuèch",
    noon: "miègjorn",
    morning: "matin",
    afternoon: "aprèp-miègjorn",
    evening: "vèspre",
    night: "nuèch"
  }
};
var formattingDayPeriodValues$m = {
  narrow: {
    am: "am",
    pm: "pm",
    midnight: "mièjanuèch",
    noon: "miègjorn",
    morning: "del matin",
    afternoon: "de l’aprèp-miègjorn",
    evening: "del ser",
    night: "de la nuèch"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "mièjanuèch",
    noon: "miègjorn",
    morning: "del matin",
    afternoon: "de l’aprèp-miègjorn",
    evening: "del ser",
    night: "de la nuèch"
  },
  wide: {
    am: "ante meridiem",
    pm: "post meridiem",
    midnight: "mièjanuèch",
    noon: "miègjorn",
    morning: "del matin",
    afternoon: "de l’aprèp-miègjorn",
    evening: "del ser",
    night: "de la nuèch"
  }
};
var ordinalNumber$o = function (e, a) {
  var t = Number(e);
  var r = a === null || a === void 0 ? void 0 : a.unit;
  var n;
  switch (t) {
    case 1:
      n = "èr";
      break;
    case 2:
      n = "nd";
      break;
    default:
      n = "en";
  }
  if (r === "year" || r === "week" || r === "hour" || r === "minute" || r === "second") {
    n += "a";
  }
  return t + n;
};
localize$o.localize = {
  ordinalNumber: ordinalNumber$o,
  era: (0, _index$1D.buildLocalizeFn)({
    values: eraValues$o,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1D.buildLocalizeFn)({
    values: quarterValues$o,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1D.buildLocalizeFn)({
    values: monthValues$o,
    defaultWidth: "wide"
  }),
  day: (0, _index$1D.buildLocalizeFn)({
    values: dayValues$o,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$1D.buildLocalizeFn)({
    values: dayPeriodValues$o,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$m,
    defaultFormattingWidth: "wide"
  })
};
var match$o = {};
match$o.match = void 0;
var _index$1C = buildMatchFn;
var _index2$O = buildMatchPatternFn;
var matchOrdinalNumberPattern$o = /^(\d+)(èr|nd|en)?[a]?/i;
var parseOrdinalNumberPattern$o = /\d+/i;
var matchEraPatterns$o = {
  narrow: /^(ab\.J\.C|apr\.J\.C|apr\.J\.-C)/i,
  abbreviated: /^(ab\.J\.-C|ab\.J-C|apr\.J\.-C|apr\.J-C|ap\.J-C)/i,
  wide: /^(abans Jèsus-Crist|après Jèsus-Crist)/i
};
var parseEraPatterns$o = {
  any: [/^ab/i, /^ap/i]
};
var matchQuarterPatterns$o = {
  narrow: /^T[1234]/i,
  abbreviated: /^[1234](èr|nd|en)? trim\.?/i,
  wide: /^[1234](èr|nd|en)? trimèstre/i
};
var parseQuarterPatterns$o = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$o = {
  narrow: /^(GN|FB|MÇ|AB|MA|JN|JL|AG|ST|OC|NV|DC)/i,
  abbreviated: /^(gen|febr|març|abr|mai|junh|jul|ag|set|oct|nov|dec)\.?/i,
  wide: /^(genièr|febrièr|març|abril|mai|junh|julhet|agost|setembre|octòbre|novembre|decembre)/i
};
var parseMonthPatterns$o = {
  any: [/^g/i, /^f/i, /^ma[r?]|MÇ/i, /^ab/i, /^ma[i?]/i, /^ju[n?]|JN/i, /^ju[l?]|JL/i, /^ag/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$o = {
  narrow: /^d[glmcjvs]\.?/i,
  short: /^d[glmcjvs]\.?/i,
  abbreviated: /^d[glmcjvs]\.?/i,
  wide: /^(dimenge|diluns|dimars|dimècres|dijòus|divendres|dissabte)/i
};
var parseDayPatterns$o = {
  narrow: [/^dg/i, /^dl/i, /^dm/i, /^dc/i, /^dj/i, /^dv/i, /^ds/i],
  short: [/^dg/i, /^dl/i, /^dm/i, /^dc/i, /^dj/i, /^dv/i, /^ds/i],
  abbreviated: [/^dg/i, /^dl/i, /^dm/i, /^dc/i, /^dj/i, /^dv/i, /^ds/i],
  any: [/^dg|dime/i, /^dl|dil/i, /^dm|dima/i, /^dc|dimè/i, /^dj|dij/i, /^dv|div/i, /^ds|dis/i]
};
var matchDayPeriodPatterns$o = {
  any: /(^(a\.?m|p\.?m))|(ante meridiem|post meridiem)|((del |de la |de l’)(matin|aprèp-miègjorn|vèspre|ser|nuèch))/i
};
var parseDayPeriodPatterns$o = {
  any: {
    am: /(^a)|ante meridiem/i,
    pm: /(^p)|post meridiem/i,
    midnight: /^mièj/i,
    noon: /^mièg/i,
    morning: /matin/i,
    afternoon: /aprèp-miègjorn/i,
    evening: /vèspre|ser/i,
    night: /nuèch/i
  }
};
match$o.match = {
  ordinalNumber: (0, _index2$O.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$o,
    parsePattern: parseOrdinalNumberPattern$o,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1C.buildMatchFn)({
    matchPatterns: matchEraPatterns$o,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$o,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1C.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$o,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$o,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1C.buildMatchFn)({
    matchPatterns: matchMonthPatterns$o,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$o,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1C.buildMatchFn)({
    matchPatterns: matchDayPatterns$o,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$o,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1C.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$o,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$o,
    defaultParseWidth: "any"
  })
};
oc.oc = void 0;
var _index$1B = formatDistance$N;
var _index2$N = formatLong$o;
var _index3$o = formatRelative$N;
var _index4$o = localize$o;
var _index5$o = match$o;
oc.oc = {
  code: "oc",
  formatDistance: _index$1B.formatDistance,
  formatLong: _index2$N.formatLong,
  formatRelative: _index3$o.formatRelative,
  localize: _index4$o.localize,
  match: _index5$o.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var pl = {};
var formatDistance$L = {};
formatDistance$L.formatDistance = void 0;
var formatDistanceLocale$n = {
  lessThanXSeconds: {
    one: {
      regular: "mniej niż sekunda",
      past: "mniej niż sekundę",
      future: "mniej niż sekundę"
    },
    twoFour: "mniej niż {{count}} sekundy",
    other: "mniej niż {{count}} sekund"
  },
  xSeconds: {
    one: {
      regular: "sekunda",
      past: "sekundę",
      future: "sekundę"
    },
    twoFour: "{{count}} sekundy",
    other: "{{count}} sekund"
  },
  halfAMinute: {
    one: "pół minuty",
    twoFour: "pół minuty",
    other: "pół minuty"
  },
  lessThanXMinutes: {
    one: {
      regular: "mniej niż minuta",
      past: "mniej niż minutę",
      future: "mniej niż minutę"
    },
    twoFour: "mniej niż {{count}} minuty",
    other: "mniej niż {{count}} minut"
  },
  xMinutes: {
    one: {
      regular: "minuta",
      past: "minutę",
      future: "minutę"
    },
    twoFour: "{{count}} minuty",
    other: "{{count}} minut"
  },
  aboutXHours: {
    one: {
      regular: "około godziny",
      past: "około godziny",
      future: "około godzinę"
    },
    twoFour: "około {{count}} godziny",
    other: "około {{count}} godzin"
  },
  xHours: {
    one: {
      regular: "godzina",
      past: "godzinę",
      future: "godzinę"
    },
    twoFour: "{{count}} godziny",
    other: "{{count}} godzin"
  },
  xDays: {
    one: {
      regular: "dzień",
      past: "dzień",
      future: "1 dzień"
    },
    twoFour: "{{count}} dni",
    other: "{{count}} dni"
  },
  aboutXWeeks: {
    one: "około tygodnia",
    twoFour: "około {{count}} tygodni",
    other: "około {{count}} tygodni"
  },
  xWeeks: {
    one: "tydzień",
    twoFour: "{{count}} tygodnie",
    other: "{{count}} tygodni"
  },
  aboutXMonths: {
    one: "około miesiąc",
    twoFour: "około {{count}} miesiące",
    other: "około {{count}} miesięcy"
  },
  xMonths: {
    one: "miesiąc",
    twoFour: "{{count}} miesiące",
    other: "{{count}} miesięcy"
  },
  aboutXYears: {
    one: "około rok",
    twoFour: "około {{count}} lata",
    other: "około {{count}} lat"
  },
  xYears: {
    one: "rok",
    twoFour: "{{count}} lata",
    other: "{{count}} lat"
  },
  overXYears: {
    one: "ponad rok",
    twoFour: "ponad {{count}} lata",
    other: "ponad {{count}} lat"
  },
  almostXYears: {
    one: "prawie rok",
    twoFour: "prawie {{count}} lata",
    other: "prawie {{count}} lat"
  }
};
function declensionGroup$1(e, a) {
  if (a === 1) {
    return e.one;
  }
  var t = a % 100;
  if (t <= 20 && t > 10) {
    return e.other;
  }
  var r = t % 10;
  if (r >= 2 && r <= 4) {
    return e.twoFour;
  }
  return e.other;
}
function declension$3(e, a, t) {
  var r = declensionGroup$1(e, a);
  var n = typeof r === "string" ? r : r[t];
  return n.replace("{{count}}", String(a));
}
var formatDistance$K = function (e, a, t) {
  var r = formatDistanceLocale$n[e];
  if (!(t === null || t === void 0 ? void 0 : t.addSuffix)) {
    return declension$3(r, a, "regular");
  }
  if (t.comparison && t.comparison > 0) {
    return "za " + declension$3(r, a, "future");
  } else {
    return declension$3(r, a, "past") + " temu";
  }
};
formatDistance$L.formatDistance = formatDistance$K;
var formatLong$n = {};
formatLong$n.formatLong = void 0;
var _index$1A = buildFormatLongFn;
var dateFormats$n = {
  full: "EEEE, do MMMM y",
  long: "do MMMM y",
  medium: "do MMM y",
  short: "dd.MM.y"
};
var timeFormats$n = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$n = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$n.formatLong = {
  date: (0, _index$1A.buildFormatLongFn)({
    formats: dateFormats$n,
    defaultWidth: "full"
  }),
  time: (0, _index$1A.buildFormatLongFn)({
    formats: timeFormats$n,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1A.buildFormatLongFn)({
    formats: dateTimeFormats$n,
    defaultWidth: "full"
  })
};
var formatRelative$L = {};
formatRelative$L.formatRelative = void 0;
var _index$1z = isSameWeek;
var adjectivesLastWeek = {
  masculine: "ostatni",
  feminine: "ostatnia"
};
var adjectivesThisWeek = {
  masculine: "ten",
  feminine: "ta"
};
var adjectivesNextWeek = {
  masculine: "następny",
  feminine: "następna"
};
var dayGrammaticalGender = {
  0: "feminine",
  1: "masculine",
  2: "masculine",
  3: "feminine",
  4: "masculine",
  5: "masculine",
  6: "feminine"
};
function dayAndTimeWithAdjective(e, a, t, r) {
  var n;
  if ((0, _index$1z.isSameWeek)(a, t, r)) {
    n = adjectivesThisWeek;
  } else if (e === "lastWeek") {
    n = adjectivesLastWeek;
  } else if (e === "nextWeek") {
    n = adjectivesNextWeek;
  } else {
    throw new Error("Cannot determine adjectives for token ".concat(e));
  }
  var i = a.getDay();
  var o = dayGrammaticalGender[i];
  var d = n[o];
  return "'".concat(d, "' eeee 'o' p");
}
var formatRelativeLocale$n = {
  lastWeek: dayAndTimeWithAdjective,
  yesterday: "'wczoraj o' p",
  today: "'dzisiaj o' p",
  tomorrow: "'jutro o' p",
  nextWeek: dayAndTimeWithAdjective,
  other: "P"
};
var formatRelative$K = function (e, a, t, r) {
  var n = formatRelativeLocale$n[e];
  if (typeof n === "function") {
    return n(e, a, t, r);
  }
  return n;
};
formatRelative$L.formatRelative = formatRelative$K;
var localize$n = {};
localize$n.localize = void 0;
var _index$1y = buildLocalizeFn;
var eraValues$n = {
  narrow: ["p.n.e.", "n.e."],
  abbreviated: ["p.n.e.", "n.e."],
  wide: ["przed naszą erą", "naszej ery"]
};
var quarterValues$n = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["I kw.", "II kw.", "III kw.", "IV kw."],
  wide: ["I kwartał", "II kwartał", "III kwartał", "IV kwartał"]
};
var monthValues$n = {
  narrow: ["S", "L", "M", "K", "M", "C", "L", "S", "W", "P", "L", "G"],
  abbreviated: ["sty", "lut", "mar", "kwi", "maj", "cze", "lip", "sie", "wrz", "paź", "lis", "gru"],
  wide: ["styczeń", "luty", "marzec", "kwiecień", "maj", "czerwiec", "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"]
};
var monthFormattingValues = {
  narrow: ["s", "l", "m", "k", "m", "c", "l", "s", "w", "p", "l", "g"],
  abbreviated: ["sty", "lut", "mar", "kwi", "maj", "cze", "lip", "sie", "wrz", "paź", "lis", "gru"],
  wide: ["stycznia", "lutego", "marca", "kwietnia", "maja", "czerwca", "lipca", "sierpnia", "września", "października", "listopada", "grudnia"]
};
var dayValues$n = {
  narrow: ["N", "P", "W", "Ś", "C", "P", "S"],
  short: ["nie", "pon", "wto", "śro", "czw", "pią", "sob"],
  abbreviated: ["niedz.", "pon.", "wt.", "śr.", "czw.", "pt.", "sob."],
  wide: ["niedziela", "poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota"]
};
var dayFormattingValues = {
  narrow: ["n", "p", "w", "ś", "c", "p", "s"],
  short: ["nie", "pon", "wto", "śro", "czw", "pią", "sob"],
  abbreviated: ["niedz.", "pon.", "wt.", "śr.", "czw.", "pt.", "sob."],
  wide: ["niedziela", "poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota"]
};
var dayPeriodValues$n = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "półn.",
    noon: "poł",
    morning: "rano",
    afternoon: "popoł.",
    evening: "wiecz.",
    night: "noc"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "północ",
    noon: "południe",
    morning: "rano",
    afternoon: "popołudnie",
    evening: "wieczór",
    night: "noc"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "północ",
    noon: "południe",
    morning: "rano",
    afternoon: "popołudnie",
    evening: "wieczór",
    night: "noc"
  }
};
var dayPeriodFormattingValues = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "o półn.",
    noon: "w poł.",
    morning: "rano",
    afternoon: "po poł.",
    evening: "wiecz.",
    night: "w nocy"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "o północy",
    noon: "w południe",
    morning: "rano",
    afternoon: "po południu",
    evening: "wieczorem",
    night: "w nocy"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "o północy",
    noon: "w południe",
    morning: "rano",
    afternoon: "po południu",
    evening: "wieczorem",
    night: "w nocy"
  }
};
var ordinalNumber$n = function (e, a) {
  return String(e);
};
localize$n.localize = {
  ordinalNumber: ordinalNumber$n,
  era: (0, _index$1y.buildLocalizeFn)({
    values: eraValues$n,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1y.buildLocalizeFn)({
    values: quarterValues$n,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1y.buildLocalizeFn)({
    values: monthValues$n,
    defaultWidth: "wide",
    formattingValues: monthFormattingValues,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$1y.buildLocalizeFn)({
    values: dayValues$n,
    defaultWidth: "wide",
    formattingValues: dayFormattingValues,
    defaultFormattingWidth: "wide"
  }),
  dayPeriod: (0, _index$1y.buildLocalizeFn)({
    values: dayPeriodValues$n,
    defaultWidth: "wide",
    formattingValues: dayPeriodFormattingValues,
    defaultFormattingWidth: "wide"
  })
};
var match$n = {};
match$n.match = void 0;
var _index$1x = buildMatchFn;
var _index2$M = buildMatchPatternFn;
var matchOrdinalNumberPattern$n = /^(\d+)?/i;
var parseOrdinalNumberPattern$n = /\d+/i;
var matchEraPatterns$n = {
  narrow: /^(p\.?\s*n\.?\s*e\.?\s*|n\.?\s*e\.?\s*)/i,
  abbreviated: /^(p\.?\s*n\.?\s*e\.?\s*|n\.?\s*e\.?\s*)/i,
  wide: /^(przed\s*nasz(ą|a)\s*er(ą|a)|naszej\s*ery)/i
};
var parseEraPatterns$n = {
  any: [/^p/i, /^n/i]
};
var matchQuarterPatterns$n = {
  narrow: /^[1234]/i,
  abbreviated: /^(I|II|III|IV)\s*kw\.?/i,
  wide: /^(I|II|III|IV)\s*kwarta(ł|l)/i
};
var parseQuarterPatterns$n = {
  narrow: [/1/i, /2/i, /3/i, /4/i],
  any: [/^I kw/i, /^II kw/i, /^III kw/i, /^IV kw/i]
};
var matchMonthPatterns$n = {
  narrow: /^[slmkcwpg]/i,
  abbreviated: /^(sty|lut|mar|kwi|maj|cze|lip|sie|wrz|pa(ź|z)|lis|gru)/i,
  wide: /^(stycznia|stycze(ń|n)|lutego|luty|marca|marzec|kwietnia|kwiecie(ń|n)|maja|maj|czerwca|czerwiec|lipca|lipiec|sierpnia|sierpie(ń|n)|wrze(ś|s)nia|wrzesie(ń|n)|pa(ź|z)dziernika|pa(ź|z)dziernik|listopada|listopad|grudnia|grudzie(ń|n))/i
};
var parseMonthPatterns$n = {
  narrow: [/^s/i, /^l/i, /^m/i, /^k/i, /^m/i, /^c/i, /^l/i, /^s/i, /^w/i, /^p/i, /^l/i, /^g/i],
  any: [/^st/i, /^lu/i, /^mar/i, /^k/i, /^maj/i, /^c/i, /^lip/i, /^si/i, /^w/i, /^p/i, /^lis/i, /^g/i]
};
var matchDayPatterns$n = {
  narrow: /^[npwścs]/i,
  short: /^(nie|pon|wto|(ś|s)ro|czw|pi(ą|a)|sob)/i,
  abbreviated: /^(niedz|pon|wt|(ś|s)r|czw|pt|sob)\.?/i,
  wide: /^(niedziela|poniedzia(ł|l)ek|wtorek|(ś|s)roda|czwartek|pi(ą|a)tek|sobota)/i
};
var parseDayPatterns$n = {
  narrow: [/^n/i, /^p/i, /^w/i, /^ś/i, /^c/i, /^p/i, /^s/i],
  abbreviated: [/^n/i, /^po/i, /^w/i, /^(ś|s)r/i, /^c/i, /^pt/i, /^so/i],
  any: [/^n/i, /^po/i, /^w/i, /^(ś|s)r/i, /^c/i, /^pi/i, /^so/i]
};
var matchDayPeriodPatterns$n = {
  narrow: /^(^a$|^p$|pó(ł|l)n\.?|o\s*pó(ł|l)n\.?|po(ł|l)\.?|w\s*po(ł|l)\.?|po\s*po(ł|l)\.?|rano|wiecz\.?|noc|w\s*nocy)/i,
  any: /^(am|pm|pó(ł|l)noc|o\s*pó(ł|l)nocy|po(ł|l)udnie|w\s*po(ł|l)udnie|popo(ł|l)udnie|po\s*po(ł|l)udniu|rano|wieczór|wieczorem|noc|w\s*nocy)/i
};
var parseDayPeriodPatterns$n = {
  narrow: {
    am: /^a$/i,
    pm: /^p$/i,
    midnight: /pó(ł|l)n/i,
    noon: /po(ł|l)/i,
    morning: /rano/i,
    afternoon: /po\s*po(ł|l)/i,
    evening: /wiecz/i,
    night: /noc/i
  },
  any: {
    am: /^am/i,
    pm: /^pm/i,
    midnight: /pó(ł|l)n/i,
    noon: /po(ł|l)/i,
    morning: /rano/i,
    afternoon: /po\s*po(ł|l)/i,
    evening: /wiecz/i,
    night: /noc/i
  }
};
match$n.match = {
  ordinalNumber: (0, _index2$M.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$n,
    parsePattern: parseOrdinalNumberPattern$n,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1x.buildMatchFn)({
    matchPatterns: matchEraPatterns$n,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$n,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1x.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$n,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$n,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1x.buildMatchFn)({
    matchPatterns: matchMonthPatterns$n,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$n,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1x.buildMatchFn)({
    matchPatterns: matchDayPatterns$n,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$n,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1x.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$n,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$n,
    defaultParseWidth: "any"
  })
};
pl.pl = void 0;
var _index$1w = formatDistance$L;
var _index2$L = formatLong$n;
var _index3$n = formatRelative$L;
var _index4$n = localize$n;
var _index5$n = match$n;
pl.pl = {
  code: "pl",
  formatDistance: _index$1w.formatDistance,
  formatLong: _index2$L.formatLong,
  formatRelative: _index3$n.formatRelative,
  localize: _index4$n.localize,
  match: _index5$n.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var pt = {};
var formatDistance$J = {};
formatDistance$J.formatDistance = void 0;
var formatDistanceLocale$m = {
  lessThanXSeconds: {
    one: "menos de um segundo",
    other: "menos de {{count}} segundos"
  },
  xSeconds: {
    one: "1 segundo",
    other: "{{count}} segundos"
  },
  halfAMinute: "meio minuto",
  lessThanXMinutes: {
    one: "menos de um minuto",
    other: "menos de {{count}} minutos"
  },
  xMinutes: {
    one: "1 minuto",
    other: "{{count}} minutos"
  },
  aboutXHours: {
    one: "aproximadamente 1 hora",
    other: "aproximadamente {{count}} horas"
  },
  xHours: {
    one: "1 hora",
    other: "{{count}} horas"
  },
  xDays: {
    one: "1 dia",
    other: "{{count}} dias"
  },
  aboutXWeeks: {
    one: "aproximadamente 1 semana",
    other: "aproximadamente {{count}} semanas"
  },
  xWeeks: {
    one: "1 semana",
    other: "{{count}} semanas"
  },
  aboutXMonths: {
    one: "aproximadamente 1 mês",
    other: "aproximadamente {{count}} meses"
  },
  xMonths: {
    one: "1 mês",
    other: "{{count}} meses"
  },
  aboutXYears: {
    one: "aproximadamente 1 ano",
    other: "aproximadamente {{count}} anos"
  },
  xYears: {
    one: "1 ano",
    other: "{{count}} anos"
  },
  overXYears: {
    one: "mais de 1 ano",
    other: "mais de {{count}} anos"
  },
  almostXYears: {
    one: "quase 1 ano",
    other: "quase {{count}} anos"
  }
};
var formatDistance$I = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$m[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "daqui a " + r;
    } else {
      return "há " + r;
    }
  }
  return r;
};
formatDistance$J.formatDistance = formatDistance$I;
var formatLong$m = {};
formatLong$m.formatLong = void 0;
var _index$1v = buildFormatLongFn;
var dateFormats$m = {
  full: "EEEE, d 'de' MMMM 'de' y",
  long: "d 'de' MMMM 'de' y",
  medium: "d 'de' MMM 'de' y",
  short: "dd/MM/y"
};
var timeFormats$m = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$m = {
  full: "{{date}} 'às' {{time}}",
  long: "{{date}} 'às' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$m.formatLong = {
  date: (0, _index$1v.buildFormatLongFn)({
    formats: dateFormats$m,
    defaultWidth: "full"
  }),
  time: (0, _index$1v.buildFormatLongFn)({
    formats: timeFormats$m,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1v.buildFormatLongFn)({
    formats: dateTimeFormats$m,
    defaultWidth: "full"
  })
};
var formatRelative$J = {};
formatRelative$J.formatRelative = void 0;
var formatRelativeLocale$m = {
  lastWeek: function (e) {
    var a = e.getDay();
    var t = a === 0 || a === 6 ? "último" : "última";
    return "'" + t + "' eeee 'às' p";
  },
  yesterday: "'ontem às' p",
  today: "'hoje às' p",
  tomorrow: "'amanhã às' p",
  nextWeek: "eeee 'às' p",
  other: "P"
};
var formatRelative$I = function (e, a, t, r) {
  var n = formatRelativeLocale$m[e];
  if (typeof n === "function") {
    return n(a);
  }
  return n;
};
formatRelative$J.formatRelative = formatRelative$I;
var localize$m = {};
localize$m.localize = void 0;
var _index$1u = buildLocalizeFn;
var eraValues$m = {
  narrow: ["aC", "dC"],
  abbreviated: ["a.C.", "d.C."],
  wide: ["antes de Cristo", "depois de Cristo"]
};
var quarterValues$m = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["T1", "T2", "T3", "T4"],
  wide: ["1º trimestre", "2º trimestre", "3º trimestre", "4º trimestre"]
};
var monthValues$m = {
  narrow: ["j", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
  abbreviated: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
  wide: ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"]
};
var dayValues$m = {
  narrow: ["d", "s", "t", "q", "q", "s", "s"],
  short: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
  abbreviated: ["dom", "seg", "ter", "qua", "qui", "sex", "sáb"],
  wide: ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"]
};
var dayPeriodValues$m = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "meia-noite",
    noon: "meio-dia",
    morning: "manhã",
    afternoon: "tarde",
    evening: "noite",
    night: "madrugada"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "meia-noite",
    noon: "meio-dia",
    morning: "manhã",
    afternoon: "tarde",
    evening: "noite",
    night: "madrugada"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "meia-noite",
    noon: "meio-dia",
    morning: "manhã",
    afternoon: "tarde",
    evening: "noite",
    night: "madrugada"
  }
};
var formattingDayPeriodValues$l = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "meia-noite",
    noon: "meio-dia",
    morning: "da manhã",
    afternoon: "da tarde",
    evening: "da noite",
    night: "da madrugada"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "meia-noite",
    noon: "meio-dia",
    morning: "da manhã",
    afternoon: "da tarde",
    evening: "da noite",
    night: "da madrugada"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "meia-noite",
    noon: "meio-dia",
    morning: "da manhã",
    afternoon: "da tarde",
    evening: "da noite",
    night: "da madrugada"
  }
};
var ordinalNumber$m = function (e, a) {
  var t = Number(e);
  return t + "º";
};
localize$m.localize = {
  ordinalNumber: ordinalNumber$m,
  era: (0, _index$1u.buildLocalizeFn)({
    values: eraValues$m,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1u.buildLocalizeFn)({
    values: quarterValues$m,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1u.buildLocalizeFn)({
    values: monthValues$m,
    defaultWidth: "wide"
  }),
  day: (0, _index$1u.buildLocalizeFn)({
    values: dayValues$m,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$1u.buildLocalizeFn)({
    values: dayPeriodValues$m,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$l,
    defaultFormattingWidth: "wide"
  })
};
var match$m = {};
match$m.match = void 0;
var _index$1t = buildMatchFn;
var _index2$K = buildMatchPatternFn;
var matchOrdinalNumberPattern$m = /^(\d+)(º|ª)?/i;
var parseOrdinalNumberPattern$m = /\d+/i;
var matchEraPatterns$m = {
  narrow: /^(ac|dc|a|d)/i,
  abbreviated: /^(a\.?\s?c\.?|a\.?\s?e\.?\s?c\.?|d\.?\s?c\.?|e\.?\s?c\.?)/i,
  wide: /^(antes de cristo|antes da era comum|depois de cristo|era comum)/i
};
var parseEraPatterns$m = {
  any: [/^ac/i, /^dc/i],
  wide: [/^(antes de cristo|antes da era comum)/i, /^(depois de cristo|era comum)/i]
};
var matchQuarterPatterns$m = {
  narrow: /^[1234]/i,
  abbreviated: /^T[1234]/i,
  wide: /^[1234](º|ª)? trimestre/i
};
var parseQuarterPatterns$m = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$m = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|fev|mar|abr|mai|jun|jul|ago|set|out|nov|dez)/i,
  wide: /^(janeiro|fevereiro|março|abril|maio|junho|julho|agosto|setembro|outubro|novembro|dezembro)/i
};
var parseMonthPatterns$m = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ab/i, /^mai/i, /^jun/i, /^jul/i, /^ag/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$m = {
  narrow: /^[dstq]/i,
  short: /^(dom|seg|ter|qua|qui|sex|s[áa]b)/i,
  abbreviated: /^(dom|seg|ter|qua|qui|sex|s[áa]b)/i,
  wide: /^(domingo|segunda-?\s?feira|terça-?\s?feira|quarta-?\s?feira|quinta-?\s?feira|sexta-?\s?feira|s[áa]bado)/i
};
var parseDayPatterns$m = {
  narrow: [/^d/i, /^s/i, /^t/i, /^q/i, /^q/i, /^s/i, /^s/i],
  any: [/^d/i, /^seg/i, /^t/i, /^qua/i, /^qui/i, /^sex/i, /^s[áa]/i]
};
var matchDayPeriodPatterns$m = {
  narrow: /^(a|p|meia-?\s?noite|meio-?\s?dia|(da) (manh[ãa]|tarde|noite|madrugada))/i,
  any: /^([ap]\.?\s?m\.?|meia-?\s?noite|meio-?\s?dia|(da) (manh[ãa]|tarde|noite|madrugada))/i
};
var parseDayPeriodPatterns$m = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^meia/i,
    noon: /^meio/i,
    morning: /manh[ãa]/i,
    afternoon: /tarde/i,
    evening: /noite/i,
    night: /madrugada/i
  }
};
match$m.match = {
  ordinalNumber: (0, _index2$K.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$m,
    parsePattern: parseOrdinalNumberPattern$m,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1t.buildMatchFn)({
    matchPatterns: matchEraPatterns$m,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$m,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1t.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$m,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$m,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1t.buildMatchFn)({
    matchPatterns: matchMonthPatterns$m,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$m,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1t.buildMatchFn)({
    matchPatterns: matchDayPatterns$m,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$m,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1t.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$m,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$m,
    defaultParseWidth: "any"
  })
};
pt.pt = void 0;
var _index$1s = formatDistance$J;
var _index2$J = formatLong$m;
var _index3$m = formatRelative$J;
var _index4$m = localize$m;
var _index5$m = match$m;
pt.pt = {
  code: "pt",
  formatDistance: _index$1s.formatDistance,
  formatLong: _index2$J.formatLong,
  formatRelative: _index3$m.formatRelative,
  localize: _index4$m.localize,
  match: _index5$m.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var ptBR = {};
var formatDistance$H = {};
formatDistance$H.formatDistance = void 0;
var formatDistanceLocale$l = {
  lessThanXSeconds: {
    one: "menos de um segundo",
    other: "menos de {{count}} segundos"
  },
  xSeconds: {
    one: "1 segundo",
    other: "{{count}} segundos"
  },
  halfAMinute: "meio minuto",
  lessThanXMinutes: {
    one: "menos de um minuto",
    other: "menos de {{count}} minutos"
  },
  xMinutes: {
    one: "1 minuto",
    other: "{{count}} minutos"
  },
  aboutXHours: {
    one: "cerca de 1 hora",
    other: "cerca de {{count}} horas"
  },
  xHours: {
    one: "1 hora",
    other: "{{count}} horas"
  },
  xDays: {
    one: "1 dia",
    other: "{{count}} dias"
  },
  aboutXWeeks: {
    one: "cerca de 1 semana",
    other: "cerca de {{count}} semanas"
  },
  xWeeks: {
    one: "1 semana",
    other: "{{count}} semanas"
  },
  aboutXMonths: {
    one: "cerca de 1 mês",
    other: "cerca de {{count}} meses"
  },
  xMonths: {
    one: "1 mês",
    other: "{{count}} meses"
  },
  aboutXYears: {
    one: "cerca de 1 ano",
    other: "cerca de {{count}} anos"
  },
  xYears: {
    one: "1 ano",
    other: "{{count}} anos"
  },
  overXYears: {
    one: "mais de 1 ano",
    other: "mais de {{count}} anos"
  },
  almostXYears: {
    one: "quase 1 ano",
    other: "quase {{count}} anos"
  }
};
var formatDistance$G = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$l[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "em " + r;
    } else {
      return "há " + r;
    }
  }
  return r;
};
formatDistance$H.formatDistance = formatDistance$G;
var formatLong$l = {};
formatLong$l.formatLong = void 0;
var _index$1r = buildFormatLongFn;
var dateFormats$l = {
  full: "EEEE, d 'de' MMMM 'de' y",
  long: "d 'de' MMMM 'de' y",
  medium: "d MMM y",
  short: "dd/MM/yyyy"
};
var timeFormats$l = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$l = {
  full: "{{date}} 'às' {{time}}",
  long: "{{date}} 'às' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$l.formatLong = {
  date: (0, _index$1r.buildFormatLongFn)({
    formats: dateFormats$l,
    defaultWidth: "full"
  }),
  time: (0, _index$1r.buildFormatLongFn)({
    formats: timeFormats$l,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1r.buildFormatLongFn)({
    formats: dateTimeFormats$l,
    defaultWidth: "full"
  })
};
var formatRelative$H = {};
formatRelative$H.formatRelative = void 0;
var formatRelativeLocale$l = {
  lastWeek: function (e) {
    var a = e.getDay();
    var t = a === 0 || a === 6 ? "último" : "última";
    return "'" + t + "' eeee 'às' p";
  },
  yesterday: "'ontem às' p",
  today: "'hoje às' p",
  tomorrow: "'amanhã às' p",
  nextWeek: "eeee 'às' p",
  other: "P"
};
var formatRelative$G = function (e, a, t, r) {
  var n = formatRelativeLocale$l[e];
  if (typeof n === "function") {
    return n(a);
  }
  return n;
};
formatRelative$H.formatRelative = formatRelative$G;
var localize$l = {};
localize$l.localize = void 0;
var _index$1q = buildLocalizeFn;
var eraValues$l = {
  narrow: ["AC", "DC"],
  abbreviated: ["AC", "DC"],
  wide: ["antes de cristo", "depois de cristo"]
};
var quarterValues$l = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["T1", "T2", "T3", "T4"],
  wide: ["1º trimestre", "2º trimestre", "3º trimestre", "4º trimestre"]
};
var monthValues$l = {
  narrow: ["j", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
  abbreviated: ["jan", "fev", "mar", "abr", "mai", "jun", "jul", "ago", "set", "out", "nov", "dez"],
  wide: ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"]
};
var dayValues$l = {
  narrow: ["D", "S", "T", "Q", "Q", "S", "S"],
  short: ["dom", "seg", "ter", "qua", "qui", "sex", "sab"],
  abbreviated: ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"],
  wide: ["domingo", "segunda-feira", "terça-feira", "quarta-feira", "quinta-feira", "sexta-feira", "sábado"]
};
var dayPeriodValues$l = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mn",
    noon: "md",
    morning: "manhã",
    afternoon: "tarde",
    evening: "tarde",
    night: "noite"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "meia-noite",
    noon: "meio-dia",
    morning: "manhã",
    afternoon: "tarde",
    evening: "tarde",
    night: "noite"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "meia-noite",
    noon: "meio-dia",
    morning: "manhã",
    afternoon: "tarde",
    evening: "tarde",
    night: "noite"
  }
};
var formattingDayPeriodValues$k = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mn",
    noon: "md",
    morning: "da manhã",
    afternoon: "da tarde",
    evening: "da tarde",
    night: "da noite"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "meia-noite",
    noon: "meio-dia",
    morning: "da manhã",
    afternoon: "da tarde",
    evening: "da tarde",
    night: "da noite"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "meia-noite",
    noon: "meio-dia",
    morning: "da manhã",
    afternoon: "da tarde",
    evening: "da tarde",
    night: "da noite"
  }
};
var ordinalNumber$l = function (e, a) {
  var t = Number(e);
  if ((a === null || a === void 0 ? void 0 : a.unit) === "week") {
    return t + "ª";
  }
  return t + "º";
};
localize$l.localize = {
  ordinalNumber: ordinalNumber$l,
  era: (0, _index$1q.buildLocalizeFn)({
    values: eraValues$l,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1q.buildLocalizeFn)({
    values: quarterValues$l,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1q.buildLocalizeFn)({
    values: monthValues$l,
    defaultWidth: "wide"
  }),
  day: (0, _index$1q.buildLocalizeFn)({
    values: dayValues$l,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$1q.buildLocalizeFn)({
    values: dayPeriodValues$l,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$k,
    defaultFormattingWidth: "wide"
  })
};
var match$l = {};
match$l.match = void 0;
var _index$1p = buildMatchFn;
var _index2$I = buildMatchPatternFn;
var matchOrdinalNumberPattern$l = /^(\d+)[ºªo]?/i;
var parseOrdinalNumberPattern$l = /\d+/i;
var matchEraPatterns$l = {
  narrow: /^(ac|dc|a|d)/i,
  abbreviated: /^(a\.?\s?c\.?|d\.?\s?c\.?)/i,
  wide: /^(antes de cristo|depois de cristo)/i
};
var parseEraPatterns$l = {
  any: [/^ac/i, /^dc/i],
  wide: [/^antes de cristo/i, /^depois de cristo/i]
};
var matchQuarterPatterns$l = {
  narrow: /^[1234]/i,
  abbreviated: /^T[1234]/i,
  wide: /^[1234](º)? trimestre/i
};
var parseQuarterPatterns$l = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$l = {
  narrow: /^[jfmajsond]/i,
  abbreviated: /^(jan|fev|mar|abr|mai|jun|jul|ago|set|out|nov|dez)/i,
  wide: /^(janeiro|fevereiro|março|abril|maio|junho|julho|agosto|setembro|outubro|novembro|dezembro)/i
};
var parseMonthPatterns$l = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^fev/i, /^mar/i, /^abr/i, /^mai/i, /^jun/i, /^jul/i, /^ago/i, /^set/i, /^out/i, /^nov/i, /^dez/i]
};
var matchDayPatterns$l = {
  narrow: /^(dom|[23456]ª?|s[aá]b)/i,
  short: /^(dom|[23456]ª?|s[aá]b)/i,
  abbreviated: /^(dom|seg|ter|qua|qui|sex|s[aá]b)/i,
  wide: /^(domingo|(segunda|ter[cç]a|quarta|quinta|sexta)([- ]feira)?|s[aá]bado)/i
};
var parseDayPatterns$l = {
  short: [/^d/i, /^2/i, /^3/i, /^4/i, /^5/i, /^6/i, /^s[aá]/i],
  narrow: [/^d/i, /^2/i, /^3/i, /^4/i, /^5/i, /^6/i, /^s[aá]/i],
  any: [/^d/i, /^seg/i, /^t/i, /^qua/i, /^qui/i, /^sex/i, /^s[aá]b/i]
};
var matchDayPeriodPatterns$l = {
  narrow: /^(a|p|mn|md|(da) (manhã|tarde|noite))/i,
  any: /^([ap]\.?\s?m\.?|meia[-\s]noite|meio[-\s]dia|(da) (manhã|tarde|noite))/i
};
var parseDayPeriodPatterns$l = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mn|^meia[-\s]noite/i,
    noon: /^md|^meio[-\s]dia/i,
    morning: /manhã/i,
    afternoon: /tarde/i,
    evening: /tarde/i,
    night: /noite/i
  }
};
match$l.match = {
  ordinalNumber: (0, _index2$I.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$l,
    parsePattern: parseOrdinalNumberPattern$l,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1p.buildMatchFn)({
    matchPatterns: matchEraPatterns$l,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$l,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1p.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$l,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$l,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1p.buildMatchFn)({
    matchPatterns: matchMonthPatterns$l,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$l,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1p.buildMatchFn)({
    matchPatterns: matchDayPatterns$l,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$l,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1p.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$l,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$l,
    defaultParseWidth: "any"
  })
};
ptBR.ptBR = void 0;
var _index$1o = formatDistance$H;
var _index2$H = formatLong$l;
var _index3$l = formatRelative$H;
var _index4$l = localize$l;
var _index5$l = match$l;
ptBR.ptBR = {
  code: "pt-BR",
  formatDistance: _index$1o.formatDistance,
  formatLong: _index2$H.formatLong,
  formatRelative: _index3$l.formatRelative,
  localize: _index4$l.localize,
  match: _index5$l.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var ro = {};
var formatDistance$F = {};
formatDistance$F.formatDistance = void 0;
var formatDistanceLocale$k = {
  lessThanXSeconds: {
    one: "mai puțin de o secundă",
    other: "mai puțin de {{count}} secunde"
  },
  xSeconds: {
    one: "1 secundă",
    other: "{{count}} secunde"
  },
  halfAMinute: "jumătate de minut",
  lessThanXMinutes: {
    one: "mai puțin de un minut",
    other: "mai puțin de {{count}} minute"
  },
  xMinutes: {
    one: "1 minut",
    other: "{{count}} minute"
  },
  aboutXHours: {
    one: "circa 1 oră",
    other: "circa {{count}} ore"
  },
  xHours: {
    one: "1 oră",
    other: "{{count}} ore"
  },
  xDays: {
    one: "1 zi",
    other: "{{count}} zile"
  },
  aboutXWeeks: {
    one: "circa o săptămână",
    other: "circa {{count}} săptămâni"
  },
  xWeeks: {
    one: "1 săptămână",
    other: "{{count}} săptămâni"
  },
  aboutXMonths: {
    one: "circa 1 lună",
    other: "circa {{count}} luni"
  },
  xMonths: {
    one: "1 lună",
    other: "{{count}} luni"
  },
  aboutXYears: {
    one: "circa 1 an",
    other: "circa {{count}} ani"
  },
  xYears: {
    one: "1 an",
    other: "{{count}} ani"
  },
  overXYears: {
    one: "peste 1 an",
    other: "peste {{count}} ani"
  },
  almostXYears: {
    one: "aproape 1 an",
    other: "aproape {{count}} ani"
  }
};
var formatDistance$E = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$k[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "în " + r;
    } else {
      return r + " în urmă";
    }
  }
  return r;
};
formatDistance$F.formatDistance = formatDistance$E;
var formatLong$k = {};
formatLong$k.formatLong = void 0;
var _index$1n = buildFormatLongFn;
var dateFormats$k = {
  full: "EEEE, d MMMM yyyy",
  long: "d MMMM yyyy",
  medium: "d MMM yyyy",
  short: "dd.MM.yyyy"
};
var timeFormats$k = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$k = {
  full: "{{date}} 'la' {{time}}",
  long: "{{date}} 'la' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$k.formatLong = {
  date: (0, _index$1n.buildFormatLongFn)({
    formats: dateFormats$k,
    defaultWidth: "full"
  }),
  time: (0, _index$1n.buildFormatLongFn)({
    formats: timeFormats$k,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1n.buildFormatLongFn)({
    formats: dateTimeFormats$k,
    defaultWidth: "full"
  })
};
var formatRelative$F = {};
formatRelative$F.formatRelative = void 0;
var formatRelativeLocale$k = {
  lastWeek: "eeee 'trecută la' p",
  yesterday: "'ieri la' p",
  today: "'astăzi la' p",
  tomorrow: "'mâine la' p",
  nextWeek: "eeee 'viitoare la' p",
  other: "P"
};
var formatRelative$E = function (e, a, t, r) {
  return formatRelativeLocale$k[e];
};
formatRelative$F.formatRelative = formatRelative$E;
var localize$k = {};
localize$k.localize = void 0;
var _index$1m = buildLocalizeFn;
var eraValues$k = {
  narrow: ["Î", "D"],
  abbreviated: ["Î.d.C.", "D.C."],
  wide: ["Înainte de Cristos", "După Cristos"]
};
var quarterValues$k = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["T1", "T2", "T3", "T4"],
  wide: ["primul trimestru", "al doilea trimestru", "al treilea trimestru", "al patrulea trimestru"]
};
var monthValues$k = {
  narrow: ["I", "F", "M", "A", "M", "I", "I", "A", "S", "O", "N", "D"],
  abbreviated: ["ian", "feb", "mar", "apr", "mai", "iun", "iul", "aug", "sep", "oct", "noi", "dec"],
  wide: ["ianuarie", "februarie", "martie", "aprilie", "mai", "iunie", "iulie", "august", "septembrie", "octombrie", "noiembrie", "decembrie"]
};
var dayValues$k = {
  narrow: ["d", "l", "m", "m", "j", "v", "s"],
  short: ["du", "lu", "ma", "mi", "jo", "vi", "sâ"],
  abbreviated: ["dum", "lun", "mar", "mie", "joi", "vin", "sâm"],
  wide: ["duminică", "luni", "marți", "miercuri", "joi", "vineri", "sâmbătă"]
};
var dayPeriodValues$k = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mn",
    noon: "ami",
    morning: "dim",
    afternoon: "da",
    evening: "s",
    night: "n"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "miezul nopții",
    noon: "amiază",
    morning: "dimineață",
    afternoon: "după-amiază",
    evening: "seară",
    night: "noapte"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "miezul nopții",
    noon: "amiază",
    morning: "dimineață",
    afternoon: "după-amiază",
    evening: "seară",
    night: "noapte"
  }
};
var formattingDayPeriodValues$j = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "mn",
    noon: "amiază",
    morning: "dimineață",
    afternoon: "după-amiază",
    evening: "seară",
    night: "noapte"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "miezul nopții",
    noon: "amiază",
    morning: "dimineață",
    afternoon: "după-amiază",
    evening: "seară",
    night: "noapte"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "miezul nopții",
    noon: "amiază",
    morning: "dimineață",
    afternoon: "după-amiază",
    evening: "seară",
    night: "noapte"
  }
};
var ordinalNumber$k = function (e, a) {
  return String(e);
};
localize$k.localize = {
  ordinalNumber: ordinalNumber$k,
  era: (0, _index$1m.buildLocalizeFn)({
    values: eraValues$k,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1m.buildLocalizeFn)({
    values: quarterValues$k,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1m.buildLocalizeFn)({
    values: monthValues$k,
    defaultWidth: "wide"
  }),
  day: (0, _index$1m.buildLocalizeFn)({
    values: dayValues$k,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$1m.buildLocalizeFn)({
    values: dayPeriodValues$k,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$j,
    defaultFormattingWidth: "wide"
  })
};
var match$k = {};
match$k.match = void 0;
var _index$1l = buildMatchFn;
var _index2$G = buildMatchPatternFn;
var matchOrdinalNumberPattern$k = /^(\d+)?/i;
var parseOrdinalNumberPattern$k = /\d+/i;
var matchEraPatterns$k = {
  narrow: /^(Î|D)/i,
  abbreviated: /^(Î\.?\s?d\.?\s?C\.?|Î\.?\s?e\.?\s?n\.?|D\.?\s?C\.?|e\.?\s?n\.?)/i,
  wide: /^(Înainte de Cristos|Înaintea erei noastre|După Cristos|Era noastră)/i
};
var parseEraPatterns$k = {
  any: [/^ÎC/i, /^DC/i],
  wide: [/^(Înainte de Cristos|Înaintea erei noastre)/i, /^(După Cristos|Era noastră)/i]
};
var matchQuarterPatterns$k = {
  narrow: /^[1234]/i,
  abbreviated: /^T[1234]/i,
  wide: /^trimestrul [1234]/i
};
var parseQuarterPatterns$k = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$k = {
  narrow: /^[ifmaasond]/i,
  abbreviated: /^(ian|feb|mar|apr|mai|iun|iul|aug|sep|oct|noi|dec)/i,
  wide: /^(ianuarie|februarie|martie|aprilie|mai|iunie|iulie|august|septembrie|octombrie|noiembrie|decembrie)/i
};
var parseMonthPatterns$k = {
  narrow: [/^i/i, /^f/i, /^m/i, /^a/i, /^m/i, /^i/i, /^i/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ia/i, /^f/i, /^mar/i, /^ap/i, /^mai/i, /^iun/i, /^iul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$k = {
  narrow: /^[dlmjvs]/i,
  short: /^(d|l|ma|mi|j|v|s)/i,
  abbreviated: /^(dum|lun|mar|mie|jo|vi|sâ)/i,
  wide: /^(duminica|luni|marţi|miercuri|joi|vineri|sâmbătă)/i
};
var parseDayPatterns$k = {
  narrow: [/^d/i, /^l/i, /^m/i, /^m/i, /^j/i, /^v/i, /^s/i],
  any: [/^d/i, /^l/i, /^ma/i, /^mi/i, /^j/i, /^v/i, /^s/i]
};
var matchDayPeriodPatterns$k = {
  narrow: /^(a|p|mn|a|(dimineaţa|după-amiaza|seara|noaptea))/i,
  any: /^([ap]\.?\s?m\.?|miezul nopții|amiaza|(dimineaţa|după-amiaza|seara|noaptea))/i
};
var parseDayPeriodPatterns$k = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^mn/i,
    noon: /amiaza/i,
    morning: /dimineaţa/i,
    afternoon: /după-amiaza/i,
    evening: /seara/i,
    night: /noaptea/i
  }
};
match$k.match = {
  ordinalNumber: (0, _index2$G.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$k,
    parsePattern: parseOrdinalNumberPattern$k,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1l.buildMatchFn)({
    matchPatterns: matchEraPatterns$k,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$k,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1l.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$k,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$k,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1l.buildMatchFn)({
    matchPatterns: matchMonthPatterns$k,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$k,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1l.buildMatchFn)({
    matchPatterns: matchDayPatterns$k,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$k,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1l.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$k,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$k,
    defaultParseWidth: "any"
  })
};
ro.ro = void 0;
var _index$1k = formatDistance$F;
var _index2$F = formatLong$k;
var _index3$k = formatRelative$F;
var _index4$k = localize$k;
var _index5$k = match$k;
ro.ro = {
  code: "ro",
  formatDistance: _index$1k.formatDistance,
  formatLong: _index2$F.formatLong,
  formatRelative: _index3$k.formatRelative,
  localize: _index4$k.localize,
  match: _index5$k.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var ru = {};
var formatDistance$D = {};
formatDistance$D.formatDistance = void 0;
function declension$2(e, a) {
  if (e.one !== undefined && a === 1) {
    return e.one;
  }
  var t = a % 10;
  var r = a % 100;
  if (t === 1 && r !== 11) {
    return e.singularNominative.replace("{{count}}", String(a));
  } else if (t >= 2 && t <= 4 && (r < 10 || r > 20)) {
    return e.singularGenitive.replace("{{count}}", String(a));
  } else {
    return e.pluralGenitive.replace("{{count}}", String(a));
  }
}
function buildLocalizeTokenFn$1(e) {
  return function (a, t) {
    if (t === null || t === void 0 ? void 0 : t.addSuffix) {
      if (t.comparison && t.comparison > 0) {
        if (e.future) {
          return declension$2(e.future, a);
        } else {
          return "через " + declension$2(e.regular, a);
        }
      } else {
        if (e.past) {
          return declension$2(e.past, a);
        } else {
          return declension$2(e.regular, a) + " назад";
        }
      }
    } else {
      return declension$2(e.regular, a);
    }
  };
}
var formatDistanceLocale$j = {
  lessThanXSeconds: buildLocalizeTokenFn$1({
    regular: {
      one: "меньше секунды",
      singularNominative: "меньше {{count}} секунды",
      singularGenitive: "меньше {{count}} секунд",
      pluralGenitive: "меньше {{count}} секунд"
    },
    future: {
      one: "меньше, чем через секунду",
      singularNominative: "меньше, чем через {{count}} секунду",
      singularGenitive: "меньше, чем через {{count}} секунды",
      pluralGenitive: "меньше, чем через {{count}} секунд"
    }
  }),
  xSeconds: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "{{count}} секунда",
      singularGenitive: "{{count}} секунды",
      pluralGenitive: "{{count}} секунд"
    },
    past: {
      singularNominative: "{{count}} секунду назад",
      singularGenitive: "{{count}} секунды назад",
      pluralGenitive: "{{count}} секунд назад"
    },
    future: {
      singularNominative: "через {{count}} секунду",
      singularGenitive: "через {{count}} секунды",
      pluralGenitive: "через {{count}} секунд"
    }
  }),
  halfAMinute: function (e, a) {
    if (a === null || a === void 0 ? void 0 : a.addSuffix) {
      if (a.comparison && a.comparison > 0) {
        return "через полминуты";
      } else {
        return "полминуты назад";
      }
    }
    return "полминуты";
  },
  lessThanXMinutes: buildLocalizeTokenFn$1({
    regular: {
      one: "меньше минуты",
      singularNominative: "меньше {{count}} минуты",
      singularGenitive: "меньше {{count}} минут",
      pluralGenitive: "меньше {{count}} минут"
    },
    future: {
      one: "меньше, чем через минуту",
      singularNominative: "меньше, чем через {{count}} минуту",
      singularGenitive: "меньше, чем через {{count}} минуты",
      pluralGenitive: "меньше, чем через {{count}} минут"
    }
  }),
  xMinutes: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "{{count}} минута",
      singularGenitive: "{{count}} минуты",
      pluralGenitive: "{{count}} минут"
    },
    past: {
      singularNominative: "{{count}} минуту назад",
      singularGenitive: "{{count}} минуты назад",
      pluralGenitive: "{{count}} минут назад"
    },
    future: {
      singularNominative: "через {{count}} минуту",
      singularGenitive: "через {{count}} минуты",
      pluralGenitive: "через {{count}} минут"
    }
  }),
  aboutXHours: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "около {{count}} часа",
      singularGenitive: "около {{count}} часов",
      pluralGenitive: "около {{count}} часов"
    },
    future: {
      singularNominative: "приблизительно через {{count}} час",
      singularGenitive: "приблизительно через {{count}} часа",
      pluralGenitive: "приблизительно через {{count}} часов"
    }
  }),
  xHours: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "{{count}} час",
      singularGenitive: "{{count}} часа",
      pluralGenitive: "{{count}} часов"
    }
  }),
  xDays: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "{{count}} день",
      singularGenitive: "{{count}} дня",
      pluralGenitive: "{{count}} дней"
    }
  }),
  aboutXWeeks: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "около {{count}} недели",
      singularGenitive: "около {{count}} недель",
      pluralGenitive: "около {{count}} недель"
    },
    future: {
      singularNominative: "приблизительно через {{count}} неделю",
      singularGenitive: "приблизительно через {{count}} недели",
      pluralGenitive: "приблизительно через {{count}} недель"
    }
  }),
  xWeeks: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "{{count}} неделя",
      singularGenitive: "{{count}} недели",
      pluralGenitive: "{{count}} недель"
    }
  }),
  aboutXMonths: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "около {{count}} месяца",
      singularGenitive: "около {{count}} месяцев",
      pluralGenitive: "около {{count}} месяцев"
    },
    future: {
      singularNominative: "приблизительно через {{count}} месяц",
      singularGenitive: "приблизительно через {{count}} месяца",
      pluralGenitive: "приблизительно через {{count}} месяцев"
    }
  }),
  xMonths: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "{{count}} месяц",
      singularGenitive: "{{count}} месяца",
      pluralGenitive: "{{count}} месяцев"
    }
  }),
  aboutXYears: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "около {{count}} года",
      singularGenitive: "около {{count}} лет",
      pluralGenitive: "около {{count}} лет"
    },
    future: {
      singularNominative: "приблизительно через {{count}} год",
      singularGenitive: "приблизительно через {{count}} года",
      pluralGenitive: "приблизительно через {{count}} лет"
    }
  }),
  xYears: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "{{count}} год",
      singularGenitive: "{{count}} года",
      pluralGenitive: "{{count}} лет"
    }
  }),
  overXYears: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "больше {{count}} года",
      singularGenitive: "больше {{count}} лет",
      pluralGenitive: "больше {{count}} лет"
    },
    future: {
      singularNominative: "больше, чем через {{count}} год",
      singularGenitive: "больше, чем через {{count}} года",
      pluralGenitive: "больше, чем через {{count}} лет"
    }
  }),
  almostXYears: buildLocalizeTokenFn$1({
    regular: {
      singularNominative: "почти {{count}} год",
      singularGenitive: "почти {{count}} года",
      pluralGenitive: "почти {{count}} лет"
    },
    future: {
      singularNominative: "почти через {{count}} год",
      singularGenitive: "почти через {{count}} года",
      pluralGenitive: "почти через {{count}} лет"
    }
  })
};
var formatDistance$C = function (e, a, t) {
  return formatDistanceLocale$j[e](a, t);
};
formatDistance$D.formatDistance = formatDistance$C;
var formatLong$j = {};
formatLong$j.formatLong = void 0;
var _index$1j = buildFormatLongFn;
var dateFormats$j = {
  full: "EEEE, d MMMM y 'г.'",
  long: "d MMMM y 'г.'",
  medium: "d MMM y 'г.'",
  short: "dd.MM.y"
};
var timeFormats$j = {
  full: "H:mm:ss zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$j = {
  any: "{{date}}, {{time}}"
};
formatLong$j.formatLong = {
  date: (0, _index$1j.buildFormatLongFn)({
    formats: dateFormats$j,
    defaultWidth: "full"
  }),
  time: (0, _index$1j.buildFormatLongFn)({
    formats: timeFormats$j,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1j.buildFormatLongFn)({
    formats: dateTimeFormats$j,
    defaultWidth: "any"
  })
};
var formatRelative$D = {};
formatRelative$D.formatRelative = void 0;
var _index$1i = isSameWeek;
var accusativeWeekdays$2 = ["воскресенье", "понедельник", "вторник", "среду", "четверг", "пятницу", "субботу"];
function lastWeek$2(e) {
  var a = accusativeWeekdays$2[e];
  switch (e) {
    case 0:
      return "'в прошлое " + a + " в' p";
    case 1:
    case 2:
    case 4:
      return "'в прошлый " + a + " в' p";
    case 3:
    case 5:
    case 6:
      return "'в прошлую " + a + " в' p";
  }
}
function thisWeek$2(e) {
  var a = accusativeWeekdays$2[e];
  if (e === 2) {
    return "'во " + a + " в' p";
  } else {
    return "'в " + a + " в' p";
  }
}
function nextWeek$2(e) {
  var a = accusativeWeekdays$2[e];
  switch (e) {
    case 0:
      return "'в следующее " + a + " в' p";
    case 1:
    case 2:
    case 4:
      return "'в следующий " + a + " в' p";
    case 3:
    case 5:
    case 6:
      return "'в следующую " + a + " в' p";
  }
}
var formatRelativeLocale$j = {
  lastWeek: function (e, a, t) {
    var r = e.getDay();
    if ((0, _index$1i.isSameWeek)(e, a, t)) {
      return thisWeek$2(r);
    } else {
      return lastWeek$2(r);
    }
  },
  yesterday: "'вчера в' p",
  today: "'сегодня в' p",
  tomorrow: "'завтра в' p",
  nextWeek: function (e, a, t) {
    var r = e.getDay();
    if ((0, _index$1i.isSameWeek)(e, a, t)) {
      return thisWeek$2(r);
    } else {
      return nextWeek$2(r);
    }
  },
  other: "P"
};
var formatRelative$C = function (e, a, t, r) {
  var n = formatRelativeLocale$j[e];
  if (typeof n === "function") {
    return n(a, t, r);
  }
  return n;
};
formatRelative$D.formatRelative = formatRelative$C;
var localize$j = {};
localize$j.localize = void 0;
var _index$1h = buildLocalizeFn;
var eraValues$j = {
  narrow: ["до н.э.", "н.э."],
  abbreviated: ["до н. э.", "н. э."],
  wide: ["до нашей эры", "нашей эры"]
};
var quarterValues$j = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1-й кв.", "2-й кв.", "3-й кв.", "4-й кв."],
  wide: ["1-й квартал", "2-й квартал", "3-й квартал", "4-й квартал"]
};
var monthValues$j = {
  narrow: ["Я", "Ф", "М", "А", "М", "И", "И", "А", "С", "О", "Н", "Д"],
  abbreviated: ["янв.", "фев.", "март", "апр.", "май", "июнь", "июль", "авг.", "сент.", "окт.", "нояб.", "дек."],
  wide: ["январь", "февраль", "март", "апрель", "май", "июнь", "июль", "август", "сентябрь", "октябрь", "ноябрь", "декабрь"]
};
var formattingMonthValues$5 = {
  narrow: ["Я", "Ф", "М", "А", "М", "И", "И", "А", "С", "О", "Н", "Д"],
  abbreviated: ["янв.", "фев.", "мар.", "апр.", "мая", "июн.", "июл.", "авг.", "сент.", "окт.", "нояб.", "дек."],
  wide: ["января", "февраля", "марта", "апреля", "мая", "июня", "июля", "августа", "сентября", "октября", "ноября", "декабря"]
};
var dayValues$j = {
  narrow: ["В", "П", "В", "С", "Ч", "П", "С"],
  short: ["вс", "пн", "вт", "ср", "чт", "пт", "сб"],
  abbreviated: ["вск", "пнд", "втр", "срд", "чтв", "птн", "суб"],
  wide: ["воскресенье", "понедельник", "вторник", "среда", "четверг", "пятница", "суббота"]
};
var dayPeriodValues$j = {
  narrow: {
    am: "ДП",
    pm: "ПП",
    midnight: "полн.",
    noon: "полд.",
    morning: "утро",
    afternoon: "день",
    evening: "веч.",
    night: "ночь"
  },
  abbreviated: {
    am: "ДП",
    pm: "ПП",
    midnight: "полн.",
    noon: "полд.",
    morning: "утро",
    afternoon: "день",
    evening: "веч.",
    night: "ночь"
  },
  wide: {
    am: "ДП",
    pm: "ПП",
    midnight: "полночь",
    noon: "полдень",
    morning: "утро",
    afternoon: "день",
    evening: "вечер",
    night: "ночь"
  }
};
var formattingDayPeriodValues$i = {
  narrow: {
    am: "ДП",
    pm: "ПП",
    midnight: "полн.",
    noon: "полд.",
    morning: "утра",
    afternoon: "дня",
    evening: "веч.",
    night: "ночи"
  },
  abbreviated: {
    am: "ДП",
    pm: "ПП",
    midnight: "полн.",
    noon: "полд.",
    morning: "утра",
    afternoon: "дня",
    evening: "веч.",
    night: "ночи"
  },
  wide: {
    am: "ДП",
    pm: "ПП",
    midnight: "полночь",
    noon: "полдень",
    morning: "утра",
    afternoon: "дня",
    evening: "вечера",
    night: "ночи"
  }
};
var ordinalNumber$j = function (e, a) {
  var t = Number(e);
  var r = a === null || a === void 0 ? void 0 : a.unit;
  var n;
  if (r === "date") {
    n = "-е";
  } else if (r === "week" || r === "minute" || r === "second") {
    n = "-я";
  } else {
    n = "-й";
  }
  return t + n;
};
localize$j.localize = {
  ordinalNumber: ordinalNumber$j,
  era: (0, _index$1h.buildLocalizeFn)({
    values: eraValues$j,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1h.buildLocalizeFn)({
    values: quarterValues$j,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1h.buildLocalizeFn)({
    values: monthValues$j,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$5,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$1h.buildLocalizeFn)({
    values: dayValues$j,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$1h.buildLocalizeFn)({
    values: dayPeriodValues$j,
    defaultWidth: "any",
    formattingValues: formattingDayPeriodValues$i,
    defaultFormattingWidth: "wide"
  })
};
var match$j = {};
match$j.match = void 0;
var _index$1g = buildMatchFn;
var _index2$E = buildMatchPatternFn;
var matchOrdinalNumberPattern$j = /^(\d+)(-?(е|я|й|ое|ье|ая|ья|ый|ой|ий|ый))?/i;
var parseOrdinalNumberPattern$j = /\d+/i;
var matchEraPatterns$j = {
  narrow: /^((до )?н\.?\s?э\.?)/i,
  abbreviated: /^((до )?н\.?\s?э\.?)/i,
  wide: /^(до нашей эры|нашей эры|наша эра)/i
};
var parseEraPatterns$j = {
  any: [/^д/i, /^н/i]
};
var matchQuarterPatterns$j = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234](-?[ыои]?й?)? кв.?/i,
  wide: /^[1234](-?[ыои]?й?)? квартал/i
};
var parseQuarterPatterns$j = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$j = {
  narrow: /^[яфмаисонд]/i,
  abbreviated: /^(янв|фев|март?|апр|ма[йя]|июн[ья]?|июл[ья]?|авг|сент?|окт|нояб?|дек)\.?/i,
  wide: /^(январ[ья]|феврал[ья]|марта?|апрел[ья]|ма[йя]|июн[ья]|июл[ья]|августа?|сентябр[ья]|октябр[ья]|октябр[ья]|ноябр[ья]|декабр[ья])/i
};
var parseMonthPatterns$j = {
  narrow: [/^я/i, /^ф/i, /^м/i, /^а/i, /^м/i, /^и/i, /^и/i, /^а/i, /^с/i, /^о/i, /^н/i, /^я/i],
  any: [/^я/i, /^ф/i, /^мар/i, /^ап/i, /^ма[йя]/i, /^июн/i, /^июл/i, /^ав/i, /^с/i, /^о/i, /^н/i, /^д/i]
};
var matchDayPatterns$j = {
  narrow: /^[впсч]/i,
  short: /^(вс|во|пн|по|вт|ср|чт|че|пт|пя|сб|су)\.?/i,
  abbreviated: /^(вск|вос|пнд|пон|втр|вто|срд|сре|чтв|чет|птн|пят|суб).?/i,
  wide: /^(воскресень[ея]|понедельника?|вторника?|сред[аы]|четверга?|пятниц[аы]|суббот[аы])/i
};
var parseDayPatterns$j = {
  narrow: [/^в/i, /^п/i, /^в/i, /^с/i, /^ч/i, /^п/i, /^с/i],
  any: [/^в[ос]/i, /^п[он]/i, /^в/i, /^ср/i, /^ч/i, /^п[ят]/i, /^с[уб]/i]
};
var matchDayPeriodPatterns$j = {
  narrow: /^([дп]п|полн\.?|полд\.?|утр[оа]|день|дня|веч\.?|ноч[ьи])/i,
  abbreviated: /^([дп]п|полн\.?|полд\.?|утр[оа]|день|дня|веч\.?|ноч[ьи])/i,
  wide: /^([дп]п|полночь|полдень|утр[оа]|день|дня|вечера?|ноч[ьи])/i
};
var parseDayPeriodPatterns$j = {
  any: {
    am: /^дп/i,
    pm: /^пп/i,
    midnight: /^полн/i,
    noon: /^полд/i,
    morning: /^у/i,
    afternoon: /^д[ен]/i,
    evening: /^в/i,
    night: /^н/i
  }
};
match$j.match = {
  ordinalNumber: (0, _index2$E.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$j,
    parsePattern: parseOrdinalNumberPattern$j,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1g.buildMatchFn)({
    matchPatterns: matchEraPatterns$j,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$j,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1g.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$j,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$j,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1g.buildMatchFn)({
    matchPatterns: matchMonthPatterns$j,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$j,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1g.buildMatchFn)({
    matchPatterns: matchDayPatterns$j,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$j,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1g.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$j,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$j,
    defaultParseWidth: "any"
  })
};
ru.ru = void 0;
var _index$1f = formatDistance$D;
var _index2$D = formatLong$j;
var _index3$j = formatRelative$D;
var _index4$j = localize$j;
var _index5$j = match$j;
ru.ru = {
  code: "ru",
  formatDistance: _index$1f.formatDistance,
  formatLong: _index2$D.formatLong,
  formatRelative: _index3$j.formatRelative,
  localize: _index4$j.localize,
  match: _index5$j.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var se = {};
var formatDistance$B = {};
formatDistance$B.formatDistance = void 0;
var formatDistanceLocale$i = {
  lessThanXSeconds: {
    one: "unnit go ovtta sekundda",
    other: "unnit go {{count}} sekundda"
  },
  xSeconds: {
    one: "sekundda",
    other: "{{count}} sekundda"
  },
  halfAMinute: "bealle minuhta",
  lessThanXMinutes: {
    one: "unnit go bealle minuhta",
    other: "unnit go {{count}} minuhta"
  },
  xMinutes: {
    one: "minuhta",
    other: "{{count}} minuhta"
  },
  aboutXHours: {
    one: "sullii ovtta diimmu",
    other: "sullii {{count}} diimmu"
  },
  xHours: {
    one: "diimmu",
    other: "{{count}} diimmu"
  },
  xDays: {
    one: "beaivvi",
    other: "{{count}} beaivvi"
  },
  aboutXWeeks: {
    one: "sullii ovtta vahku",
    other: "sullii {{count}} vahku"
  },
  xWeeks: {
    one: "vahku",
    other: "{{count}} vahku"
  },
  aboutXMonths: {
    one: "sullii ovtta mánu",
    other: "sullii {{count}} mánu"
  },
  xMonths: {
    one: "mánu",
    other: "{{count}} mánu"
  },
  aboutXYears: {
    one: "sullii ovtta jagi",
    other: "sullii {{count}} jagi"
  },
  xYears: {
    one: "jagi",
    other: "{{count}} jagi"
  },
  overXYears: {
    one: "guhkit go jagi",
    other: "guhkit go {{count}} jagi"
  },
  almostXYears: {
    one: "measta jagi",
    other: "measta {{count}} jagi"
  }
};
var formatDistance$A = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$i[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "geahčen " + r;
    } else {
      return r + " áigi";
    }
  }
  return r;
};
formatDistance$B.formatDistance = formatDistance$A;
var formatLong$i = {};
formatLong$i.formatLong = void 0;
var _index$1e = buildFormatLongFn;
var dateFormats$i = {
  full: "EEEE MMMM d. 'b.' y",
  long: "MMMM d. 'b.' y",
  medium: "MMM d. 'b.' y",
  short: "dd.MM.y"
};
var timeFormats$i = {
  full: "'dii.' HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$i = {
  full: "{{date}} 'dii.' {{time}}",
  long: "{{date}} 'dii.' {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$i.formatLong = {
  date: (0, _index$1e.buildFormatLongFn)({
    formats: dateFormats$i,
    defaultWidth: "full"
  }),
  time: (0, _index$1e.buildFormatLongFn)({
    formats: timeFormats$i,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1e.buildFormatLongFn)({
    formats: dateTimeFormats$i,
    defaultWidth: "full"
  })
};
var formatRelative$B = {};
formatRelative$B.formatRelative = void 0;
var formatRelativeLocale$i = {
  lastWeek: "'ovddit' eeee 'dii.' p",
  yesterday: "'ikte dii.' p",
  today: "'odne dii.' p",
  tomorrow: "'ihtin dii.' p",
  nextWeek: "EEEE 'dii.' p",
  other: "P"
};
var formatRelative$A = function (e, a, t, r) {
  return formatRelativeLocale$i[e];
};
formatRelative$B.formatRelative = formatRelative$A;
var localize$i = {};
localize$i.localize = void 0;
var _index$1d = buildLocalizeFn;
var eraValues$i = {
  narrow: ["o.Kr.", "m.Kr."],
  abbreviated: ["o.Kr.", "m.Kr."],
  wide: ["ovdal Kristusa", "maŋŋel Kristusa"]
};
var quarterValues$i = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1. kvartála", "2. kvartála", "3. kvartála", "4. kvartála"]
};
var monthValues$i = {
  narrow: ["O", "G", "N", "C", "M", "G", "S", "B", "Č", "G", "S", "J"],
  abbreviated: ["ođđa", "guov", "njuk", "cuo", "mies", "geas", "suoi", "borg", "čakč", "golg", "skáb", "juov"],
  wide: ["ođđajagemánnu", "guovvamánnu", "njukčamánnu", "cuoŋománnu", "miessemánnu", "geassemánnu", "suoidnemánnu", "borgemánnu", "čakčamánnu", "golggotmánnu", "skábmamánnu", "juovlamánnu"]
};
var dayValues$i = {
  narrow: ["S", "V", "M", "G", "D", "B", "L"],
  short: ["sotn", "vuos", "maŋ", "gask", "duor", "bear", "láv"],
  abbreviated: ["sotn", "vuos", "maŋ", "gask", "duor", "bear", "láv"],
  wide: ["sotnabeaivi", "vuossárga", "maŋŋebárga", "gaskavahkku", "duorastat", "bearjadat", "lávvardat"]
};
var dayPeriodValues$i = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "gaskaidja",
    noon: "gaskabeaivi",
    morning: "iđđes",
    afternoon: "maŋŋel gaska.",
    evening: "eahkes",
    night: "ihkku"
  },
  abbreviated: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "gaskaidja",
    noon: "gaskabeaivvi",
    morning: "iđđes",
    afternoon: "maŋŋel gaskabea.",
    evening: "eahkes",
    night: "ihkku"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "gaskaidja",
    noon: "gaskabeavvi",
    morning: "iđđes",
    afternoon: "maŋŋel gaskabeaivvi",
    evening: "eahkes",
    night: "ihkku"
  }
};
var ordinalNumber$i = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$i.localize = {
  ordinalNumber: ordinalNumber$i,
  era: (0, _index$1d.buildLocalizeFn)({
    values: eraValues$i,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$1d.buildLocalizeFn)({
    values: quarterValues$i,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$1d.buildLocalizeFn)({
    values: monthValues$i,
    defaultWidth: "wide"
  }),
  day: (0, _index$1d.buildLocalizeFn)({
    values: dayValues$i,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$1d.buildLocalizeFn)({
    values: dayPeriodValues$i,
    defaultWidth: "wide"
  })
};
var match$i = {};
match$i.match = void 0;
var _index$1c = buildMatchFn;
var _index2$C = buildMatchPatternFn;
var matchOrdinalNumberPattern$i = /^(\d+)\.?/i;
var parseOrdinalNumberPattern$i = /\d+/i;
var matchEraPatterns$i = {
  narrow: /^(o\.? ?Kr\.?|m\.? ?Kr\.?)/i,
  abbreviated: /^(o\.? ?Kr\.?|m\.? ?Kr\.?)/i,
  wide: /^(ovdal Kristusa|ovdal min áiggi|maŋŋel Kristusa|min áigi)/i
};
var parseEraPatterns$i = {
  any: [/^o/i, /^m/i]
};
var matchQuarterPatterns$i = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](\.)? kvartála/i
};
var parseQuarterPatterns$i = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$i = {
  narrow: /^[ogncmsbčj]/i,
  abbreviated: /^(ođđa|guov|njuk|cuo|mies|geas|suoi|borg|čakč|golg|skáb|juov)\.?/i,
  wide: /^(ođđajagemánnu|guovvamánnu|njukčamánnu|cuoŋománnu|miessemánnu|geassemánnu|suoidnemánnu|borgemánnu|čakčamánnu|golggotmánnu|skábmamánnu|juovlamánnu)/i
};
var parseMonthPatterns$i = {
  narrow: [/^o/i, /^g/i, /^n/i, /^c/i, /^m/i, /^g/i, /^s/i, /^b/i, /^č/i, /^g/i, /^s/i, /^j/i],
  any: [/^o/i, /^gu/i, /^n/i, /^c/i, /^m/i, /^ge/i, /^su/i, /^b/i, /^č/i, /^go/i, /^sk/i, /^j/i]
};
var matchDayPatterns$i = {
  narrow: /^[svmgdbl]/i,
  short: /^(sotn|vuos|maŋ|gask|duor|bear|láv)/i,
  abbreviated: /^(sotn|vuos|maŋ|gask|duor|bear|láv)/i,
  wide: /^(sotnabeaivi|vuossárga|maŋŋebárga|gaskavahkku|duorastat|bearjadat|lávvardat)/i
};
var parseDayPatterns$i = {
  any: [/^s/i, /^v/i, /^m/i, /^g/i, /^d/i, /^b/i, /^l/i]
};
var matchDayPeriodPatterns$i = {
  narrow: /^(gaskaidja|gaskabeaivvi|(på) (iđđes|maŋŋel gaskabeaivvi|eahkes|ihkku)|[ap])/i,
  any: /^([ap]\.?\s?m\.?|gaskaidja|gaskabeaivvi|(på) (iđđes|maŋŋel gaskabeaivvi|eahkes|ihkku))/i
};
var parseDayPeriodPatterns$i = {
  any: {
    am: /^a(\.?\s?m\.?)?$/i,
    pm: /^p(\.?\s?m\.?)?$/i,
    midnight: /^gaskai/i,
    noon: /^gaskab/i,
    morning: /iđđes/i,
    afternoon: /maŋŋel gaskabeaivvi/i,
    evening: /eahkes/i,
    night: /ihkku/i
  }
};
match$i.match = {
  ordinalNumber: (0, _index2$C.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$i,
    parsePattern: parseOrdinalNumberPattern$i,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1c.buildMatchFn)({
    matchPatterns: matchEraPatterns$i,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$i,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1c.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$i,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$i,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1c.buildMatchFn)({
    matchPatterns: matchMonthPatterns$i,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$i,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1c.buildMatchFn)({
    matchPatterns: matchDayPatterns$i,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$i,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1c.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$i,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$i,
    defaultParseWidth: "any"
  })
};
se.se = void 0;
var _index$1b = formatDistance$B;
var _index2$B = formatLong$i;
var _index3$i = formatRelative$B;
var _index4$i = localize$i;
var _index5$i = match$i;
se.se = {
  code: "se",
  formatDistance: _index$1b.formatDistance,
  formatLong: _index2$B.formatLong,
  formatRelative: _index3$i.formatRelative,
  localize: _index4$i.localize,
  match: _index5$i.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var sk = {};
var formatDistance$z = {};
formatDistance$z.formatDistance = void 0;
function declensionGroup(e, a) {
  if (a === 1 && e.one) {
    return e.one;
  }
  if (a >= 2 && a <= 4 && e.twoFour) {
    return e.twoFour;
  }
  return e.other;
}
function declension$1(e, a, t) {
  var r = declensionGroup(e, a);
  var n = r[t];
  return n.replace("{{count}}", String(a));
}
function extractPreposition(e) {
  var a = ["lessThan", "about", "over", "almost"].filter(function (a) {
    return !!e.match(new RegExp("^" + a));
  });
  return a[0];
}
function prefixPreposition(e) {
  var a = "";
  if (e === "almost") {
    a = "takmer";
  }
  if (e === "about") {
    a = "približne";
  }
  return a.length > 0 ? a + " " : "";
}
function suffixPreposition(e) {
  var a = "";
  if (e === "lessThan") {
    a = "menej než";
  }
  if (e === "over") {
    a = "viac než";
  }
  return a.length > 0 ? a + " " : "";
}
function lowercaseFirstLetter(e) {
  return e.charAt(0).toLowerCase() + e.slice(1);
}
var formatDistanceLocale$h = {
  xSeconds: {
    one: {
      present: "sekunda",
      past: "sekundou",
      future: "sekundu"
    },
    twoFour: {
      present: "{{count}} sekundy",
      past: "{{count}} sekundami",
      future: "{{count}} sekundy"
    },
    other: {
      present: "{{count}} sekúnd",
      past: "{{count}} sekundami",
      future: "{{count}} sekúnd"
    }
  },
  halfAMinute: {
    other: {
      present: "pol minúty",
      past: "pol minútou",
      future: "pol minúty"
    }
  },
  xMinutes: {
    one: {
      present: "minúta",
      past: "minútou",
      future: "minútu"
    },
    twoFour: {
      present: "{{count}} minúty",
      past: "{{count}} minútami",
      future: "{{count}} minúty"
    },
    other: {
      present: "{{count}} minút",
      past: "{{count}} minútami",
      future: "{{count}} minút"
    }
  },
  xHours: {
    one: {
      present: "hodina",
      past: "hodinou",
      future: "hodinu"
    },
    twoFour: {
      present: "{{count}} hodiny",
      past: "{{count}} hodinami",
      future: "{{count}} hodiny"
    },
    other: {
      present: "{{count}} hodín",
      past: "{{count}} hodinami",
      future: "{{count}} hodín"
    }
  },
  xDays: {
    one: {
      present: "deň",
      past: "dňom",
      future: "deň"
    },
    twoFour: {
      present: "{{count}} dni",
      past: "{{count}} dňami",
      future: "{{count}} dni"
    },
    other: {
      present: "{{count}} dní",
      past: "{{count}} dňami",
      future: "{{count}} dní"
    }
  },
  xWeeks: {
    one: {
      present: "týždeň",
      past: "týždňom",
      future: "týždeň"
    },
    twoFour: {
      present: "{{count}} týždne",
      past: "{{count}} týždňami",
      future: "{{count}} týždne"
    },
    other: {
      present: "{{count}} týždňov",
      past: "{{count}} týždňami",
      future: "{{count}} týždňov"
    }
  },
  xMonths: {
    one: {
      present: "mesiac",
      past: "mesiacom",
      future: "mesiac"
    },
    twoFour: {
      present: "{{count}} mesiace",
      past: "{{count}} mesiacmi",
      future: "{{count}} mesiace"
    },
    other: {
      present: "{{count}} mesiacov",
      past: "{{count}} mesiacmi",
      future: "{{count}} mesiacov"
    }
  },
  xYears: {
    one: {
      present: "rok",
      past: "rokom",
      future: "rok"
    },
    twoFour: {
      present: "{{count}} roky",
      past: "{{count}} rokmi",
      future: "{{count}} roky"
    },
    other: {
      present: "{{count}} rokov",
      past: "{{count}} rokmi",
      future: "{{count}} rokov"
    }
  }
};
var formatDistance$y = function (e, a, t) {
  var r = extractPreposition(e) || "";
  var n = lowercaseFirstLetter(e.substring(r.length));
  var i = formatDistanceLocale$h[n];
  if (!(t === null || t === void 0 ? void 0 : t.addSuffix)) {
    return prefixPreposition(r) + suffixPreposition(r) + declension$1(i, a, "present");
  }
  if (t.comparison && t.comparison > 0) {
    return prefixPreposition(r) + "o " + suffixPreposition(r) + declension$1(i, a, "future");
  } else {
    return prefixPreposition(r) + "pred " + suffixPreposition(r) + declension$1(i, a, "past");
  }
};
formatDistance$z.formatDistance = formatDistance$y;
var formatLong$h = {};
formatLong$h.formatLong = void 0;
var _index$1a = buildFormatLongFn;
var dateFormats$h = {
  full: "EEEE d. MMMM y",
  long: "d. MMMM y",
  medium: "d. M. y",
  short: "d. M. y"
};
var timeFormats$h = {
  full: "H:mm:ss zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$h = {
  full: "{{date}}, {{time}}",
  long: "{{date}}, {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$h.formatLong = {
  date: (0, _index$1a.buildFormatLongFn)({
    formats: dateFormats$h,
    defaultWidth: "full"
  }),
  time: (0, _index$1a.buildFormatLongFn)({
    formats: timeFormats$h,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$1a.buildFormatLongFn)({
    formats: dateTimeFormats$h,
    defaultWidth: "full"
  })
};
var formatRelative$z = {};
formatRelative$z.formatRelative = void 0;
var _index$19 = isSameWeek;
var accusativeWeekdays$1 = ["nedeľu", "pondelok", "utorok", "stredu", "štvrtok", "piatok", "sobotu"];
function lastWeek$1(e) {
  var a = accusativeWeekdays$1[e];
  switch (e) {
    case 0:
    case 3:
    case 6:
      return "'minulú " + a + " o' p";
    default:
      return "'minulý' eeee 'o' p";
  }
}
function thisWeek$1(e) {
  var a = accusativeWeekdays$1[e];
  if (e === 4) {
    return "'vo' eeee 'o' p";
  } else {
    return "'v " + a + " o' p";
  }
}
function nextWeek$1(e) {
  var a = accusativeWeekdays$1[e];
  switch (e) {
    case 0:
    case 4:
    case 6:
      return "'budúcu " + a + " o' p";
    default:
      return "'budúci' eeee 'o' p";
  }
}
var formatRelativeLocale$h = {
  lastWeek: function (e, a, t) {
    var r = e.getDay();
    if ((0, _index$19.isSameWeek)(e, a, t)) {
      return thisWeek$1(r);
    } else {
      return lastWeek$1(r);
    }
  },
  yesterday: "'včera o' p",
  today: "'dnes o' p",
  tomorrow: "'zajtra o' p",
  nextWeek: function (e, a, t) {
    var r = e.getDay();
    if ((0, _index$19.isSameWeek)(e, a, t)) {
      return thisWeek$1(r);
    } else {
      return nextWeek$1(r);
    }
  },
  other: "P"
};
var formatRelative$y = function (e, a, t, r) {
  var n = formatRelativeLocale$h[e];
  if (typeof n === "function") {
    return n(a, t, r);
  }
  return n;
};
formatRelative$z.formatRelative = formatRelative$y;
var localize$h = {};
localize$h.localize = void 0;
var _index$18 = buildLocalizeFn;
var eraValues$h = {
  narrow: ["pred Kr.", "po Kr."],
  abbreviated: ["pred Kr.", "po Kr."],
  wide: ["pred Kristom", "po Kristovi"]
};
var quarterValues$h = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1. štvrťrok", "2. štvrťrok", "3. štvrťrok", "4. štvrťrok"]
};
var monthValues$h = {
  narrow: ["j", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
  abbreviated: ["jan", "feb", "mar", "apr", "máj", "jún", "júl", "aug", "sep", "okt", "nov", "dec"],
  wide: ["január", "február", "marec", "apríl", "máj", "jún", "júl", "august", "september", "október", "november", "december"]
};
var formattingMonthValues$4 = {
  narrow: ["j", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
  abbreviated: ["jan", "feb", "mar", "apr", "máj", "jún", "júl", "aug", "sep", "okt", "nov", "dec"],
  wide: ["januára", "februára", "marca", "apríla", "mája", "júna", "júla", "augusta", "septembra", "októbra", "novembra", "decembra"]
};
var dayValues$h = {
  narrow: ["n", "p", "u", "s", "š", "p", "s"],
  short: ["ne", "po", "ut", "st", "št", "pi", "so"],
  abbreviated: ["ne", "po", "ut", "st", "št", "pi", "so"],
  wide: ["nedeľa", "pondelok", "utorok", "streda", "štvrtok", "piatok", "sobota"]
};
var dayPeriodValues$h = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "poln.",
    noon: "pol.",
    morning: "ráno",
    afternoon: "pop.",
    evening: "več.",
    night: "noc"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "poln.",
    noon: "pol.",
    morning: "ráno",
    afternoon: "popol.",
    evening: "večer",
    night: "noc"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "polnoc",
    noon: "poludnie",
    morning: "ráno",
    afternoon: "popoludnie",
    evening: "večer",
    night: "noc"
  }
};
var formattingDayPeriodValues$h = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "o poln.",
    noon: "nap.",
    morning: "ráno",
    afternoon: "pop.",
    evening: "več.",
    night: "v n."
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "o poln.",
    noon: "napol.",
    morning: "ráno",
    afternoon: "popol.",
    evening: "večer",
    night: "v noci"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "o polnoci",
    noon: "napoludnie",
    morning: "ráno",
    afternoon: "popoludní",
    evening: "večer",
    night: "v noci"
  }
};
var ordinalNumber$h = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$h.localize = {
  ordinalNumber: ordinalNumber$h,
  era: (0, _index$18.buildLocalizeFn)({
    values: eraValues$h,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$18.buildLocalizeFn)({
    values: quarterValues$h,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$18.buildLocalizeFn)({
    values: monthValues$h,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$4,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$18.buildLocalizeFn)({
    values: dayValues$h,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$18.buildLocalizeFn)({
    values: dayPeriodValues$h,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$h,
    defaultFormattingWidth: "wide"
  })
};
var match$h = {};
match$h.match = void 0;
var _index$17 = buildMatchFn;
var _index2$A = buildMatchPatternFn;
var matchOrdinalNumberPattern$h = /^(\d+)\.?/i;
var parseOrdinalNumberPattern$h = /\d+/i;
var matchEraPatterns$h = {
  narrow: /^(pred Kr\.|pred n\. l\.|po Kr\.|n\. l\.)/i,
  abbreviated: /^(pred Kr\.|pred n\. l\.|po Kr\.|n\. l\.)/i,
  wide: /^(pred Kristom|pred na[šs][íi]m letopo[čc]tom|po Kristovi|n[áa][šs]ho letopo[čc]tu)/i
};
var parseEraPatterns$h = {
  any: [/^pr/i, /^(po|n)/i]
};
var matchQuarterPatterns$h = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234]\. [šs]tvr[ťt]rok/i
};
var parseQuarterPatterns$h = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$h = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar|apr|m[áa]j|j[úu]n|j[úu]l|aug|sep|okt|nov|dec)/i,
  wide: /^(janu[áa]ra?|febru[áa]ra?|(marec|marca)|apr[íi]la?|m[áa]ja?|j[úu]na?|j[úu]la?|augusta?|(september|septembra)|(okt[óo]ber|okt[óo]bra)|(november|novembra)|(december|decembra))/i
};
var parseMonthPatterns$h = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^m[áa]j/i, /^j[úu]n/i, /^j[úu]l/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$h = {
  narrow: /^[npusšp]/i,
  short: /^(ne|po|ut|st|št|pi|so)/i,
  abbreviated: /^(ne|po|ut|st|št|pi|so)/i,
  wide: /^(nede[ľl]a|pondelok|utorok|streda|[šs]tvrtok|piatok|sobota])/i
};
var parseDayPatterns$h = {
  narrow: [/^n/i, /^p/i, /^u/i, /^s/i, /^š/i, /^p/i, /^s/i],
  any: [/^n/i, /^po/i, /^u/i, /^st/i, /^(št|stv)/i, /^pi/i, /^so/i]
};
var matchDayPeriodPatterns$h = {
  narrow: /^(am|pm|(o )?poln\.?|(nap\.?|pol\.?)|r[áa]no|pop\.?|ve[čc]\.?|(v n\.?|noc))/i,
  abbreviated: /^(am|pm|(o )?poln\.?|(napol\.?|pol\.?)|r[áa]no|pop\.?|ve[čc]er|(v )?noci?)/i,
  any: /^(am|pm|(o )?polnoci?|(na)?poludnie|r[áa]no|popoludn(ie|í|i)|ve[čc]er|(v )?noci?)/i
};
var parseDayPeriodPatterns$h = {
  any: {
    am: /^am/i,
    pm: /^pm/i,
    midnight: /poln/i,
    noon: /^(nap|(na)?pol(\.|u))/i,
    morning: /^r[áa]no/i,
    afternoon: /^pop/i,
    evening: /^ve[čc]/i,
    night: /^(noc|v n\.)/i
  }
};
match$h.match = {
  ordinalNumber: (0, _index2$A.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$h,
    parsePattern: parseOrdinalNumberPattern$h,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$17.buildMatchFn)({
    matchPatterns: matchEraPatterns$h,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$h,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$17.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$h,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$h,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$17.buildMatchFn)({
    matchPatterns: matchMonthPatterns$h,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$h,
    defaultParseWidth: "any"
  }),
  day: (0, _index$17.buildMatchFn)({
    matchPatterns: matchDayPatterns$h,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$h,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$17.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$h,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$h,
    defaultParseWidth: "any"
  })
};
sk.sk = void 0;
var _index$16 = formatDistance$z;
var _index2$z = formatLong$h;
var _index3$h = formatRelative$z;
var _index4$h = localize$h;
var _index5$h = match$h;
sk.sk = {
  code: "sk",
  formatDistance: _index$16.formatDistance,
  formatLong: _index2$z.formatLong,
  formatRelative: _index3$h.formatRelative,
  localize: _index4$h.localize,
  match: _index5$h.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var sl = {};
var formatDistance$x = {};
formatDistance$x.formatDistance = void 0;
function isPluralType$1(e) {
  return e.one !== undefined;
}
var formatDistanceLocale$g = {
  lessThanXSeconds: {
    present: {
      one: "manj kot {{count}} sekunda",
      two: "manj kot {{count}} sekundi",
      few: "manj kot {{count}} sekunde",
      other: "manj kot {{count}} sekund"
    },
    past: {
      one: "manj kot {{count}} sekundo",
      two: "manj kot {{count}} sekundama",
      few: "manj kot {{count}} sekundami",
      other: "manj kot {{count}} sekundami"
    },
    future: {
      one: "manj kot {{count}} sekundo",
      two: "manj kot {{count}} sekundi",
      few: "manj kot {{count}} sekunde",
      other: "manj kot {{count}} sekund"
    }
  },
  xSeconds: {
    present: {
      one: "{{count}} sekunda",
      two: "{{count}} sekundi",
      few: "{{count}} sekunde",
      other: "{{count}} sekund"
    },
    past: {
      one: "{{count}} sekundo",
      two: "{{count}} sekundama",
      few: "{{count}} sekundami",
      other: "{{count}} sekundami"
    },
    future: {
      one: "{{count}} sekundo",
      two: "{{count}} sekundi",
      few: "{{count}} sekunde",
      other: "{{count}} sekund"
    }
  },
  halfAMinute: "pol minute",
  lessThanXMinutes: {
    present: {
      one: "manj kot {{count}} minuta",
      two: "manj kot {{count}} minuti",
      few: "manj kot {{count}} minute",
      other: "manj kot {{count}} minut"
    },
    past: {
      one: "manj kot {{count}} minuto",
      two: "manj kot {{count}} minutama",
      few: "manj kot {{count}} minutami",
      other: "manj kot {{count}} minutami"
    },
    future: {
      one: "manj kot {{count}} minuto",
      two: "manj kot {{count}} minuti",
      few: "manj kot {{count}} minute",
      other: "manj kot {{count}} minut"
    }
  },
  xMinutes: {
    present: {
      one: "{{count}} minuta",
      two: "{{count}} minuti",
      few: "{{count}} minute",
      other: "{{count}} minut"
    },
    past: {
      one: "{{count}} minuto",
      two: "{{count}} minutama",
      few: "{{count}} minutami",
      other: "{{count}} minutami"
    },
    future: {
      one: "{{count}} minuto",
      two: "{{count}} minuti",
      few: "{{count}} minute",
      other: "{{count}} minut"
    }
  },
  aboutXHours: {
    present: {
      one: "približno {{count}} ura",
      two: "približno {{count}} uri",
      few: "približno {{count}} ure",
      other: "približno {{count}} ur"
    },
    past: {
      one: "približno {{count}} uro",
      two: "približno {{count}} urama",
      few: "približno {{count}} urami",
      other: "približno {{count}} urami"
    },
    future: {
      one: "približno {{count}} uro",
      two: "približno {{count}} uri",
      few: "približno {{count}} ure",
      other: "približno {{count}} ur"
    }
  },
  xHours: {
    present: {
      one: "{{count}} ura",
      two: "{{count}} uri",
      few: "{{count}} ure",
      other: "{{count}} ur"
    },
    past: {
      one: "{{count}} uro",
      two: "{{count}} urama",
      few: "{{count}} urami",
      other: "{{count}} urami"
    },
    future: {
      one: "{{count}} uro",
      two: "{{count}} uri",
      few: "{{count}} ure",
      other: "{{count}} ur"
    }
  },
  xDays: {
    present: {
      one: "{{count}} dan",
      two: "{{count}} dni",
      few: "{{count}} dni",
      other: "{{count}} dni"
    },
    past: {
      one: "{{count}} dnem",
      two: "{{count}} dnevoma",
      few: "{{count}} dnevi",
      other: "{{count}} dnevi"
    },
    future: {
      one: "{{count}} dan",
      two: "{{count}} dni",
      few: "{{count}} dni",
      other: "{{count}} dni"
    }
  },
  aboutXWeeks: {
    one: "približno {{count}} teden",
    two: "približno {{count}} tedna",
    few: "približno {{count}} tedne",
    other: "približno {{count}} tednov"
  },
  xWeeks: {
    one: "{{count}} teden",
    two: "{{count}} tedna",
    few: "{{count}} tedne",
    other: "{{count}} tednov"
  },
  aboutXMonths: {
    present: {
      one: "približno {{count}} mesec",
      two: "približno {{count}} meseca",
      few: "približno {{count}} mesece",
      other: "približno {{count}} mesecev"
    },
    past: {
      one: "približno {{count}} mesecem",
      two: "približno {{count}} mesecema",
      few: "približno {{count}} meseci",
      other: "približno {{count}} meseci"
    },
    future: {
      one: "približno {{count}} mesec",
      two: "približno {{count}} meseca",
      few: "približno {{count}} mesece",
      other: "približno {{count}} mesecev"
    }
  },
  xMonths: {
    present: {
      one: "{{count}} mesec",
      two: "{{count}} meseca",
      few: "{{count}} meseci",
      other: "{{count}} mesecev"
    },
    past: {
      one: "{{count}} mesecem",
      two: "{{count}} mesecema",
      few: "{{count}} meseci",
      other: "{{count}} meseci"
    },
    future: {
      one: "{{count}} mesec",
      two: "{{count}} meseca",
      few: "{{count}} mesece",
      other: "{{count}} mesecev"
    }
  },
  aboutXYears: {
    present: {
      one: "približno {{count}} leto",
      two: "približno {{count}} leti",
      few: "približno {{count}} leta",
      other: "približno {{count}} let"
    },
    past: {
      one: "približno {{count}} letom",
      two: "približno {{count}} letoma",
      few: "približno {{count}} leti",
      other: "približno {{count}} leti"
    },
    future: {
      one: "približno {{count}} leto",
      two: "približno {{count}} leti",
      few: "približno {{count}} leta",
      other: "približno {{count}} let"
    }
  },
  xYears: {
    present: {
      one: "{{count}} leto",
      two: "{{count}} leti",
      few: "{{count}} leta",
      other: "{{count}} let"
    },
    past: {
      one: "{{count}} letom",
      two: "{{count}} letoma",
      few: "{{count}} leti",
      other: "{{count}} leti"
    },
    future: {
      one: "{{count}} leto",
      two: "{{count}} leti",
      few: "{{count}} leta",
      other: "{{count}} let"
    }
  },
  overXYears: {
    present: {
      one: "več kot {{count}} leto",
      two: "več kot {{count}} leti",
      few: "več kot {{count}} leta",
      other: "več kot {{count}} let"
    },
    past: {
      one: "več kot {{count}} letom",
      two: "več kot {{count}} letoma",
      few: "več kot {{count}} leti",
      other: "več kot {{count}} leti"
    },
    future: {
      one: "več kot {{count}} leto",
      two: "več kot {{count}} leti",
      few: "več kot {{count}} leta",
      other: "več kot {{count}} let"
    }
  },
  almostXYears: {
    present: {
      one: "skoraj {{count}} leto",
      two: "skoraj {{count}} leti",
      few: "skoraj {{count}} leta",
      other: "skoraj {{count}} let"
    },
    past: {
      one: "skoraj {{count}} letom",
      two: "skoraj {{count}} letoma",
      few: "skoraj {{count}} leti",
      other: "skoraj {{count}} leti"
    },
    future: {
      one: "skoraj {{count}} leto",
      two: "skoraj {{count}} leti",
      few: "skoraj {{count}} leta",
      other: "skoraj {{count}} let"
    }
  }
};
function getFormFromCount(e) {
  switch (e % 100) {
    case 1:
      return "one";
    case 2:
      return "two";
    case 3:
    case 4:
      return "few";
    default:
      return "other";
  }
}
var formatDistance$w = function (e, a, t) {
  var r = "";
  var n = "present";
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      n = "future";
      r = "čez ";
    } else {
      n = "past";
      r = "pred ";
    }
  }
  var i = formatDistanceLocale$g[e];
  if (typeof i === "string") {
    r += i;
  } else {
    var o = getFormFromCount(a);
    if (isPluralType$1(i)) {
      r += i[o].replace("{{count}}", String(a));
    } else {
      r += i[n][o].replace("{{count}}", String(a));
    }
  }
  return r;
};
formatDistance$x.formatDistance = formatDistance$w;
var formatLong$g = {};
formatLong$g.formatLong = void 0;
var _index$15 = buildFormatLongFn;
var dateFormats$g = {
  full: "EEEE, dd. MMMM y",
  long: "dd. MMMM y",
  medium: "d. MMM y",
  short: "d. MM. yy"
};
var timeFormats$g = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$g = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$g.formatLong = {
  date: (0, _index$15.buildFormatLongFn)({
    formats: dateFormats$g,
    defaultWidth: "full"
  }),
  time: (0, _index$15.buildFormatLongFn)({
    formats: timeFormats$g,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$15.buildFormatLongFn)({
    formats: dateTimeFormats$g,
    defaultWidth: "full"
  })
};
var formatRelative$x = {};
formatRelative$x.formatRelative = void 0;
var formatRelativeLocale$g = {
  lastWeek: function (e) {
    var a = e.getDay();
    switch (a) {
      case 0:
        return "'prejšnjo nedeljo ob' p";
      case 3:
        return "'prejšnjo sredo ob' p";
      case 6:
        return "'prejšnjo soboto ob' p";
      default:
        return "'prejšnji' EEEE 'ob' p";
    }
  },
  yesterday: "'včeraj ob' p",
  today: "'danes ob' p",
  tomorrow: "'jutri ob' p",
  nextWeek: function (e) {
    var a = e.getDay();
    switch (a) {
      case 0:
        return "'naslednjo nedeljo ob' p";
      case 3:
        return "'naslednjo sredo ob' p";
      case 6:
        return "'naslednjo soboto ob' p";
      default:
        return "'naslednji' EEEE 'ob' p";
    }
  },
  other: "P"
};
var formatRelative$w = function (e, a, t, r) {
  var n = formatRelativeLocale$g[e];
  if (typeof n === "function") {
    return n(a);
  }
  return n;
};
formatRelative$x.formatRelative = formatRelative$w;
var localize$g = {};
localize$g.localize = void 0;
var _index$14 = buildLocalizeFn;
var eraValues$g = {
  narrow: ["pr. n. št.", "po n. št."],
  abbreviated: ["pr. n. št.", "po n. št."],
  wide: ["pred našim štetjem", "po našem štetju"]
};
var quarterValues$g = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1. čet.", "2. čet.", "3. čet.", "4. čet."],
  wide: ["1. četrtletje", "2. četrtletje", "3. četrtletje", "4. četrtletje"]
};
var monthValues$g = {
  narrow: ["j", "f", "m", "a", "m", "j", "j", "a", "s", "o", "n", "d"],
  abbreviated: ["jan.", "feb.", "mar.", "apr.", "maj", "jun.", "jul.", "avg.", "sep.", "okt.", "nov.", "dec."],
  wide: ["januar", "februar", "marec", "april", "maj", "junij", "julij", "avgust", "september", "oktober", "november", "december"]
};
var dayValues$g = {
  narrow: ["n", "p", "t", "s", "č", "p", "s"],
  short: ["ned.", "pon.", "tor.", "sre.", "čet.", "pet.", "sob."],
  abbreviated: ["ned.", "pon.", "tor.", "sre.", "čet.", "pet.", "sob."],
  wide: ["nedelja", "ponedeljek", "torek", "sreda", "četrtek", "petek", "sobota"]
};
var dayPeriodValues$g = {
  narrow: {
    am: "d",
    pm: "p",
    midnight: "24.00",
    noon: "12.00",
    morning: "j",
    afternoon: "p",
    evening: "v",
    night: "n"
  },
  abbreviated: {
    am: "dop.",
    pm: "pop.",
    midnight: "poln.",
    noon: "pold.",
    morning: "jut.",
    afternoon: "pop.",
    evening: "več.",
    night: "noč"
  },
  wide: {
    am: "dop.",
    pm: "pop.",
    midnight: "polnoč",
    noon: "poldne",
    morning: "jutro",
    afternoon: "popoldne",
    evening: "večer",
    night: "noč"
  }
};
var formattingDayPeriodValues$g = {
  narrow: {
    am: "d",
    pm: "p",
    midnight: "24.00",
    noon: "12.00",
    morning: "zj",
    afternoon: "p",
    evening: "zv",
    night: "po"
  },
  abbreviated: {
    am: "dop.",
    pm: "pop.",
    midnight: "opoln.",
    noon: "opold.",
    morning: "zjut.",
    afternoon: "pop.",
    evening: "zveč.",
    night: "ponoči"
  },
  wide: {
    am: "dop.",
    pm: "pop.",
    midnight: "opolnoči",
    noon: "opoldne",
    morning: "zjutraj",
    afternoon: "popoldan",
    evening: "zvečer",
    night: "ponoči"
  }
};
var ordinalNumber$g = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$g.localize = {
  ordinalNumber: ordinalNumber$g,
  era: (0, _index$14.buildLocalizeFn)({
    values: eraValues$g,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$14.buildLocalizeFn)({
    values: quarterValues$g,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$14.buildLocalizeFn)({
    values: monthValues$g,
    defaultWidth: "wide"
  }),
  day: (0, _index$14.buildLocalizeFn)({
    values: dayValues$g,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$14.buildLocalizeFn)({
    values: dayPeriodValues$g,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$g,
    defaultFormattingWidth: "wide"
  })
};
var match$g = {};
match$g.match = void 0;
var _index$13 = buildMatchFn;
var _index2$y = buildMatchPatternFn;
var matchOrdinalNumberPattern$g = /^(\d+)\./i;
var parseOrdinalNumberPattern$g = /\d+/i;
var matchEraPatterns$g = {
  abbreviated: /^(pr\. n\. št\.|po n\. št\.)/i,
  wide: /^(pred Kristusom|pred na[sš]im [sš]tetjem|po Kristusu|po na[sš]em [sš]tetju|na[sš]ega [sš]tetja)/i
};
var parseEraPatterns$g = {
  any: [/^pr/i, /^(po|na[sš]em)/i]
};
var matchQuarterPatterns$g = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234]\.\s?[čc]et\.?/i,
  wide: /^[1234]\. [čc]etrtletje/i
};
var parseQuarterPatterns$g = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$g = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan\.|feb\.|mar\.|apr\.|maj|jun\.|jul\.|avg\.|sep\.|okt\.|nov\.|dec\.)/i,
  wide: /^(januar|februar|marec|april|maj|junij|julij|avgust|september|oktober|november|december)/i
};
var parseMonthPatterns$g = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  abbreviated: [/^ja/i, /^fe/i, /^mar/i, /^ap/i, /^maj/i, /^jun/i, /^jul/i, /^av/i, /^s/i, /^o/i, /^n/i, /^d/i],
  wide: [/^ja/i, /^fe/i, /^mar/i, /^ap/i, /^maj/i, /^jun/i, /^jul/i, /^av/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$g = {
  narrow: /^[nptsčc]/i,
  short: /^(ned\.|pon\.|tor\.|sre\.|[cč]et\.|pet\.|sob\.)/i,
  abbreviated: /^(ned\.|pon\.|tor\.|sre\.|[cč]et\.|pet\.|sob\.)/i,
  wide: /^(nedelja|ponedeljek|torek|sreda|[cč]etrtek|petek|sobota)/i
};
var parseDayPatterns$g = {
  narrow: [/^n/i, /^p/i, /^t/i, /^s/i, /^[cč]/i, /^p/i, /^s/i],
  any: [/^n/i, /^po/i, /^t/i, /^sr/i, /^[cč]/i, /^pe/i, /^so/i]
};
var matchDayPeriodPatterns$g = {
  narrow: /^(d|po?|z?v|n|z?j|24\.00|12\.00)/i,
  any: /^(dop\.|pop\.|o?poln(\.|o[cč]i?)|o?pold(\.|ne)|z?ve[cč](\.|er)|(po)?no[cč]i?|popold(ne|an)|jut(\.|ro)|zjut(\.|raj))/i
};
var parseDayPeriodPatterns$g = {
  narrow: {
    am: /^d/i,
    pm: /^p/i,
    midnight: /^24/i,
    noon: /^12/i,
    morning: /^(z?j)/i,
    afternoon: /^p/i,
    evening: /^(z?v)/i,
    night: /^(n|po)/i
  },
  any: {
    am: /^dop\./i,
    pm: /^pop\./i,
    midnight: /^o?poln/i,
    noon: /^o?pold/i,
    morning: /j/i,
    afternoon: /^pop\./i,
    evening: /^z?ve/i,
    night: /(po)?no/i
  }
};
match$g.match = {
  ordinalNumber: (0, _index2$y.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$g,
    parsePattern: parseOrdinalNumberPattern$g,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$13.buildMatchFn)({
    matchPatterns: matchEraPatterns$g,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$g,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$13.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$g,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$g,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$13.buildMatchFn)({
    matchPatterns: matchMonthPatterns$g,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$g,
    defaultParseWidth: "wide"
  }),
  day: (0, _index$13.buildMatchFn)({
    matchPatterns: matchDayPatterns$g,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$g,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$13.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$g,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$g,
    defaultParseWidth: "any"
  })
};
sl.sl = void 0;
var _index$12 = formatDistance$x;
var _index2$x = formatLong$g;
var _index3$g = formatRelative$x;
var _index4$g = localize$g;
var _index5$g = match$g;
sl.sl = {
  code: "sl",
  formatDistance: _index$12.formatDistance,
  formatLong: _index2$x.formatLong,
  formatRelative: _index3$g.formatRelative,
  localize: _index4$g.localize,
  match: _index5$g.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var sq = {};
var formatDistance$v = {};
formatDistance$v.formatDistance = void 0;
var formatDistanceLocale$f = {
  lessThanXSeconds: {
    one: "më pak se një sekondë",
    other: "më pak se {{count}} sekonda"
  },
  xSeconds: {
    one: "1 sekondë",
    other: "{{count}} sekonda"
  },
  halfAMinute: "gjysëm minuti",
  lessThanXMinutes: {
    one: "më pak se një minute",
    other: "më pak se {{count}} minuta"
  },
  xMinutes: {
    one: "1 minutë",
    other: "{{count}} minuta"
  },
  aboutXHours: {
    one: "rreth 1 orë",
    other: "rreth {{count}} orë"
  },
  xHours: {
    one: "1 orë",
    other: "{{count}} orë"
  },
  xDays: {
    one: "1 ditë",
    other: "{{count}} ditë"
  },
  aboutXWeeks: {
    one: "rreth 1 javë",
    other: "rreth {{count}} javë"
  },
  xWeeks: {
    one: "1 javë",
    other: "{{count}} javë"
  },
  aboutXMonths: {
    one: "rreth 1 muaj",
    other: "rreth {{count}} muaj"
  },
  xMonths: {
    one: "1 muaj",
    other: "{{count}} muaj"
  },
  aboutXYears: {
    one: "rreth 1 vit",
    other: "rreth {{count}} vite"
  },
  xYears: {
    one: "1 vit",
    other: "{{count}} vite"
  },
  overXYears: {
    one: "mbi 1 vit",
    other: "mbi {{count}} vite"
  },
  almostXYears: {
    one: "pothuajse 1 vit",
    other: "pothuajse {{count}} vite"
  }
};
var formatDistance$u = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$f[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "në " + r;
    } else {
      return r + " më parë";
    }
  }
  return r;
};
formatDistance$v.formatDistance = formatDistance$u;
var formatLong$f = {};
formatLong$f.formatLong = void 0;
var _index$11 = buildFormatLongFn;
var dateFormats$f = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
};
var timeFormats$f = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$f = {
  full: "{{date}} 'në' {{time}}",
  long: "{{date}} 'në' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$f.formatLong = {
  date: (0, _index$11.buildFormatLongFn)({
    formats: dateFormats$f,
    defaultWidth: "full"
  }),
  time: (0, _index$11.buildFormatLongFn)({
    formats: timeFormats$f,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$11.buildFormatLongFn)({
    formats: dateTimeFormats$f,
    defaultWidth: "full"
  })
};
var formatRelative$v = {};
formatRelative$v.formatRelative = void 0;
var formatRelativeLocale$f = {
  lastWeek: "'të' eeee 'e shkuar në' p",
  yesterday: "'dje në' p",
  today: "'sot në' p",
  tomorrow: "'nesër në' p",
  nextWeek: "eeee 'at' p",
  other: "P"
};
var formatRelative$u = function (e, a, t, r) {
  return formatRelativeLocale$f[e];
};
formatRelative$v.formatRelative = formatRelative$u;
var localize$f = {};
localize$f.localize = void 0;
var _index$10 = buildLocalizeFn;
var eraValues$f = {
  narrow: ["P", "M"],
  abbreviated: ["PK", "MK"],
  wide: ["Para Krishtit", "Mbas Krishtit"]
};
var quarterValues$f = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["4-mujori I", "4-mujori II", "4-mujori III", "4-mujori IV"]
};
var monthValues$f = {
  narrow: ["J", "S", "M", "P", "M", "Q", "K", "G", "S", "T", "N", "D"],
  abbreviated: ["Jan", "Shk", "Mar", "Pri", "Maj", "Qer", "Kor", "Gus", "Sht", "Tet", "Nën", "Dhj"],
  wide: ["Janar", "Shkurt", "Mars", "Prill", "Maj", "Qershor", "Korrik", "Gusht", "Shtator", "Tetor", "Nëntor", "Dhjetor"]
};
var dayValues$f = {
  narrow: ["D", "H", "M", "M", "E", "P", "S"],
  short: ["Di", "Hë", "Ma", "Më", "En", "Pr", "Sh"],
  abbreviated: ["Die", "Hën", "Mar", "Mër", "Enj", "Pre", "Sht"],
  wide: ["Dielë", "Hënë", "Martë", "Mërkurë", "Enjte", "Premte", "Shtunë"]
};
var dayPeriodValues$f = {
  narrow: {
    am: "p",
    pm: "m",
    midnight: "m",
    noon: "d",
    morning: "mëngjes",
    afternoon: "dite",
    evening: "mbrëmje",
    night: "natë"
  },
  abbreviated: {
    am: "PD",
    pm: "MD",
    midnight: "mesnëtë",
    noon: "drek",
    morning: "mëngjes",
    afternoon: "mbasdite",
    evening: "mbrëmje",
    night: "natë"
  },
  wide: {
    am: "p.d.",
    pm: "m.d.",
    midnight: "mesnëtë",
    noon: "drek",
    morning: "mëngjes",
    afternoon: "mbasdite",
    evening: "mbrëmje",
    night: "natë"
  }
};
var formattingDayPeriodValues$f = {
  narrow: {
    am: "p",
    pm: "m",
    midnight: "m",
    noon: "d",
    morning: "në mëngjes",
    afternoon: "në mbasdite",
    evening: "në mbrëmje",
    night: "në mesnatë"
  },
  abbreviated: {
    am: "PD",
    pm: "MD",
    midnight: "mesnatë",
    noon: "drek",
    morning: "në mëngjes",
    afternoon: "në mbasdite",
    evening: "në mbrëmje",
    night: "në mesnatë"
  },
  wide: {
    am: "p.d.",
    pm: "m.d.",
    midnight: "mesnatë",
    noon: "drek",
    morning: "në mëngjes",
    afternoon: "në mbasdite",
    evening: "në mbrëmje",
    night: "në mesnatë"
  }
};
var ordinalNumber$f = function (e, a) {
  var t = Number(e);
  if ((a === null || a === void 0 ? void 0 : a.unit) === "hour") return String(t);
  if (t === 1) return t + "-rë";
  if (t === 4) return t + "t";
  return t + "-të";
};
localize$f.localize = {
  ordinalNumber: ordinalNumber$f,
  era: (0, _index$10.buildLocalizeFn)({
    values: eraValues$f,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$10.buildLocalizeFn)({
    values: quarterValues$f,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$10.buildLocalizeFn)({
    values: monthValues$f,
    defaultWidth: "wide"
  }),
  day: (0, _index$10.buildLocalizeFn)({
    values: dayValues$f,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$10.buildLocalizeFn)({
    values: dayPeriodValues$f,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$f,
    defaultFormattingWidth: "wide"
  })
};
var match$f = {};
match$f.match = void 0;
var _index$$ = buildMatchFn;
var _index2$w = buildMatchPatternFn;
var matchOrdinalNumberPattern$f = /^(\d+)(-rë|-të|t|)?/i;
var parseOrdinalNumberPattern$f = /\d+/i;
var matchEraPatterns$f = {
  narrow: /^(p|m)/i,
  abbreviated: /^(b\.?\s?c\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?)/i,
  wide: /^(para krishtit|mbas krishtit)/i
};
var parseEraPatterns$f = {
  any: [/^b/i, /^(p|m)/i]
};
var matchQuarterPatterns$f = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234]-mujori (i{1,3}|iv)/i
};
var parseQuarterPatterns$f = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$f = {
  narrow: /^[jsmpqkftnd]/i,
  abbreviated: /^(jan|shk|mar|pri|maj|qer|kor|gus|sht|tet|nën|dhj)/i,
  wide: /^(janar|shkurt|mars|prill|maj|qershor|korrik|gusht|shtator|tetor|nëntor|dhjetor)/i
};
var parseMonthPatterns$f = {
  narrow: [/^j/i, /^s/i, /^m/i, /^p/i, /^m/i, /^q/i, /^k/i, /^g/i, /^s/i, /^t/i, /^n/i, /^d/i],
  any: [/^ja/i, /^shk/i, /^mar/i, /^pri/i, /^maj/i, /^qer/i, /^kor/i, /^gu/i, /^sht/i, /^tet/i, /^n/i, /^d/i]
};
var matchDayPatterns$f = {
  narrow: /^[dhmeps]/i,
  short: /^(di|hë|ma|më|en|pr|sh)/i,
  abbreviated: /^(die|hën|mar|mër|enj|pre|sht)/i,
  wide: /^(dielë|hënë|martë|mërkurë|enjte|premte|shtunë)/i
};
var parseDayPatterns$f = {
  narrow: [/^d/i, /^h/i, /^m/i, /^m/i, /^e/i, /^p/i, /^s/i],
  any: [/^d/i, /^h/i, /^ma/i, /^më/i, /^e/i, /^p/i, /^s/i]
};
var matchDayPeriodPatterns$f = {
  narrow: /^(p|m|me|në (mëngjes|mbasdite|mbrëmje|mesnatë))/i,
  any: /^([pm]\.?\s?d\.?|drek|në (mëngjes|mbasdite|mbrëmje|mesnatë))/i
};
var parseDayPeriodPatterns$f = {
  any: {
    am: /^p/i,
    pm: /^m/i,
    midnight: /^me/i,
    noon: /^dr/i,
    morning: /mëngjes/i,
    afternoon: /mbasdite/i,
    evening: /mbrëmje/i,
    night: /natë/i
  }
};
match$f.match = {
  ordinalNumber: (0, _index2$w.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$f,
    parsePattern: parseOrdinalNumberPattern$f,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$$.buildMatchFn)({
    matchPatterns: matchEraPatterns$f,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$f,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$$.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$f,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$f,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$$.buildMatchFn)({
    matchPatterns: matchMonthPatterns$f,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$f,
    defaultParseWidth: "any"
  }),
  day: (0, _index$$.buildMatchFn)({
    matchPatterns: matchDayPatterns$f,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$f,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$$.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$f,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$f,
    defaultParseWidth: "any"
  })
};
sq.sq = void 0;
var _index$_ = formatDistance$v;
var _index2$v = formatLong$f;
var _index3$f = formatRelative$v;
var _index4$f = localize$f;
var _index5$f = match$f;
sq.sq = {
  code: "sq",
  formatDistance: _index$_.formatDistance,
  formatLong: _index2$v.formatLong,
  formatRelative: _index3$f.formatRelative,
  localize: _index4$f.localize,
  match: _index5$f.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var sr = {};
var formatDistance$t = {};
formatDistance$t.formatDistance = void 0;
var formatDistanceLocale$e = {
  lessThanXSeconds: {
    one: {
      standalone: "мање од 1 секунде",
      withPrepositionAgo: "мање од 1 секунде",
      withPrepositionIn: "мање од 1 секунду"
    },
    dual: "мање од {{count}} секунде",
    other: "мање од {{count}} секунди"
  },
  xSeconds: {
    one: {
      standalone: "1 секунда",
      withPrepositionAgo: "1 секунде",
      withPrepositionIn: "1 секунду"
    },
    dual: "{{count}} секунде",
    other: "{{count}} секунди"
  },
  halfAMinute: "пола минуте",
  lessThanXMinutes: {
    one: {
      standalone: "мање од 1 минуте",
      withPrepositionAgo: "мање од 1 минуте",
      withPrepositionIn: "мање од 1 минуту"
    },
    dual: "мање од {{count}} минуте",
    other: "мање од {{count}} минута"
  },
  xMinutes: {
    one: {
      standalone: "1 минута",
      withPrepositionAgo: "1 минуте",
      withPrepositionIn: "1 минуту"
    },
    dual: "{{count}} минуте",
    other: "{{count}} минута"
  },
  aboutXHours: {
    one: {
      standalone: "око 1 сат",
      withPrepositionAgo: "око 1 сат",
      withPrepositionIn: "око 1 сат"
    },
    dual: "око {{count}} сата",
    other: "око {{count}} сати"
  },
  xHours: {
    one: {
      standalone: "1 сат",
      withPrepositionAgo: "1 сат",
      withPrepositionIn: "1 сат"
    },
    dual: "{{count}} сата",
    other: "{{count}} сати"
  },
  xDays: {
    one: {
      standalone: "1 дан",
      withPrepositionAgo: "1 дан",
      withPrepositionIn: "1 дан"
    },
    dual: "{{count}} дана",
    other: "{{count}} дана"
  },
  aboutXWeeks: {
    one: {
      standalone: "око 1 недељу",
      withPrepositionAgo: "око 1 недељу",
      withPrepositionIn: "око 1 недељу"
    },
    dual: "око {{count}} недеље",
    other: "око {{count}} недеље"
  },
  xWeeks: {
    one: {
      standalone: "1 недељу",
      withPrepositionAgo: "1 недељу",
      withPrepositionIn: "1 недељу"
    },
    dual: "{{count}} недеље",
    other: "{{count}} недеље"
  },
  aboutXMonths: {
    one: {
      standalone: "око 1 месец",
      withPrepositionAgo: "око 1 месец",
      withPrepositionIn: "око 1 месец"
    },
    dual: "око {{count}} месеца",
    other: "око {{count}} месеци"
  },
  xMonths: {
    one: {
      standalone: "1 месец",
      withPrepositionAgo: "1 месец",
      withPrepositionIn: "1 месец"
    },
    dual: "{{count}} месеца",
    other: "{{count}} месеци"
  },
  aboutXYears: {
    one: {
      standalone: "око 1 годину",
      withPrepositionAgo: "око 1 годину",
      withPrepositionIn: "око 1 годину"
    },
    dual: "око {{count}} године",
    other: "око {{count}} година"
  },
  xYears: {
    one: {
      standalone: "1 година",
      withPrepositionAgo: "1 године",
      withPrepositionIn: "1 годину"
    },
    dual: "{{count}} године",
    other: "{{count}} година"
  },
  overXYears: {
    one: {
      standalone: "преко 1 годину",
      withPrepositionAgo: "преко 1 годину",
      withPrepositionIn: "преко 1 годину"
    },
    dual: "преко {{count}} године",
    other: "преко {{count}} година"
  },
  almostXYears: {
    one: {
      standalone: "готово 1 годину",
      withPrepositionAgo: "готово 1 годину",
      withPrepositionIn: "готово 1 годину"
    },
    dual: "готово {{count}} године",
    other: "готово {{count}} година"
  }
};
var formatDistance$s = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$e[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    if (t === null || t === void 0 ? void 0 : t.addSuffix) {
      if (t.comparison && t.comparison > 0) {
        r = n.one.withPrepositionIn;
      } else {
        r = n.one.withPrepositionAgo;
      }
    } else {
      r = n.one.standalone;
    }
  } else if (a % 10 > 1 && a % 10 < 5 && String(a).substr(-2, 1) !== "1") {
    r = n.dual.replace("{{count}}", String(a));
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "за " + r;
    } else {
      return "пре " + r;
    }
  }
  return r;
};
formatDistance$t.formatDistance = formatDistance$s;
var formatLong$e = {};
formatLong$e.formatLong = void 0;
var _index$Z = buildFormatLongFn;
var dateFormats$e = {
  full: "EEEE, d. MMMM yyyy.",
  long: "d. MMMM yyyy.",
  medium: "d. MMM yy.",
  short: "dd. MM. yy."
};
var timeFormats$e = {
  full: "HH:mm:ss (zzzz)",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$e = {
  full: "{{date}} 'у' {{time}}",
  long: "{{date}} 'у' {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$e.formatLong = {
  date: (0, _index$Z.buildFormatLongFn)({
    formats: dateFormats$e,
    defaultWidth: "full"
  }),
  time: (0, _index$Z.buildFormatLongFn)({
    formats: timeFormats$e,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$Z.buildFormatLongFn)({
    formats: dateTimeFormats$e,
    defaultWidth: "full"
  })
};
var formatRelative$t = {};
formatRelative$t.formatRelative = void 0;
var formatRelativeLocale$e = {
  lastWeek: function (e) {
    var a = e.getDay();
    switch (a) {
      case 0:
        return "'прошле недеље у' p";
      case 3:
        return "'прошле среде у' p";
      case 6:
        return "'прошле суботе у' p";
      default:
        return "'прошли' EEEE 'у' p";
    }
  },
  yesterday: "'јуче у' p",
  today: "'данас у' p",
  tomorrow: "'сутра у' p",
  nextWeek: function (e) {
    var a = e.getDay();
    switch (a) {
      case 0:
        return "'следеће недеље у' p";
      case 3:
        return "'следећу среду у' p";
      case 6:
        return "'следећу суботу у' p";
      default:
        return "'следећи' EEEE 'у' p";
    }
  },
  other: "P"
};
var formatRelative$s = function (e, a, t, r) {
  var n = formatRelativeLocale$e[e];
  if (typeof n === "function") {
    return n(a);
  }
  return n;
};
formatRelative$t.formatRelative = formatRelative$s;
var localize$e = {};
localize$e.localize = void 0;
var _index$Y = buildLocalizeFn;
var eraValues$e = {
  narrow: ["пр.н.е.", "АД"],
  abbreviated: ["пр. Хр.", "по. Хр."],
  wide: ["Пре Христа", "После Христа"]
};
var quarterValues$e = {
  narrow: ["1.", "2.", "3.", "4."],
  abbreviated: ["1. кв.", "2. кв.", "3. кв.", "4. кв."],
  wide: ["1. квартал", "2. квартал", "3. квартал", "4. квартал"]
};
var monthValues$e = {
  narrow: ["1.", "2.", "3.", "4.", "5.", "6.", "7.", "8.", "9.", "10.", "11.", "12."],
  abbreviated: ["јан", "феб", "мар", "апр", "мај", "јун", "јул", "авг", "сеп", "окт", "нов", "дец"],
  wide: ["јануар", "фебруар", "март", "април", "мај", "јун", "јул", "август", "септембар", "октобар", "новембар", "децембар"]
};
var formattingMonthValues$3 = {
  narrow: ["1.", "2.", "3.", "4.", "5.", "6.", "7.", "8.", "9.", "10.", "11.", "12."],
  abbreviated: ["јан", "феб", "мар", "апр", "мај", "јун", "јул", "авг", "сеп", "окт", "нов", "дец"],
  wide: ["јануар", "фебруар", "март", "април", "мај", "јун", "јул", "август", "септембар", "октобар", "новембар", "децембар"]
};
var dayValues$e = {
  narrow: ["Н", "П", "У", "С", "Ч", "П", "С"],
  short: ["нед", "пон", "уто", "сре", "чет", "пет", "суб"],
  abbreviated: ["нед", "пон", "уто", "сре", "чет", "пет", "суб"],
  wide: ["недеља", "понедељак", "уторак", "среда", "четвртак", "петак", "субота"]
};
var formattingDayPeriodValues$e = {
  narrow: {
    am: "АМ",
    pm: "ПМ",
    midnight: "поноћ",
    noon: "подне",
    morning: "ујутру",
    afternoon: "поподне",
    evening: "увече",
    night: "ноћу"
  },
  abbreviated: {
    am: "АМ",
    pm: "ПМ",
    midnight: "поноћ",
    noon: "подне",
    morning: "ујутру",
    afternoon: "поподне",
    evening: "увече",
    night: "ноћу"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "поноћ",
    noon: "подне",
    morning: "ујутру",
    afternoon: "после подне",
    evening: "увече",
    night: "ноћу"
  }
};
var dayPeriodValues$e = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "поноћ",
    noon: "подне",
    morning: "ујутру",
    afternoon: "поподне",
    evening: "увече",
    night: "ноћу"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "поноћ",
    noon: "подне",
    morning: "ујутру",
    afternoon: "поподне",
    evening: "увече",
    night: "ноћу"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "поноћ",
    noon: "подне",
    morning: "ујутру",
    afternoon: "после подне",
    evening: "увече",
    night: "ноћу"
  }
};
var ordinalNumber$e = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$e.localize = {
  ordinalNumber: ordinalNumber$e,
  era: (0, _index$Y.buildLocalizeFn)({
    values: eraValues$e,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$Y.buildLocalizeFn)({
    values: quarterValues$e,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$Y.buildLocalizeFn)({
    values: monthValues$e,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$3,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$Y.buildLocalizeFn)({
    values: dayValues$e,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$Y.buildLocalizeFn)({
    values: dayPeriodValues$e,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$e,
    defaultFormattingWidth: "wide"
  })
};
var match$e = {};
match$e.match = void 0;
var _index$X = buildMatchFn;
var _index2$u = buildMatchPatternFn;
var matchOrdinalNumberPattern$e = /^(\d+)\./i;
var parseOrdinalNumberPattern$e = /\d+/i;
var matchEraPatterns$e = {
  narrow: /^(пр\.н\.е\.|АД)/i,
  abbreviated: /^(пр\.\s?Хр\.|по\.\s?Хр\.)/i,
  wide: /^(Пре Христа|пре нове ере|После Христа|нова ера)/i
};
var parseEraPatterns$e = {
  any: [/^пр/i, /^(по|нова)/i]
};
var matchQuarterPatterns$e = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234]\.\s?кв\.?/i,
  wide: /^[1234]\. квартал/i
};
var parseQuarterPatterns$e = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$e = {
  narrow: /^(10|11|12|[123456789])\./i,
  abbreviated: /^(јан|феб|мар|апр|мај|јун|јул|авг|сеп|окт|нов|дец)/i,
  wide: /^((јануар|јануара)|(фебруар|фебруара)|(март|марта)|(април|априла)|(мја|маја)|(јун|јуна)|(јул|јула)|(август|августа)|(септембар|септембра)|(октобар|октобра)|(новембар|новембра)|(децембар|децембра))/i
};
var parseMonthPatterns$e = {
  narrow: [/^1/i, /^2/i, /^3/i, /^4/i, /^5/i, /^6/i, /^7/i, /^8/i, /^9/i, /^10/i, /^11/i, /^12/i],
  any: [/^ја/i, /^ф/i, /^мар/i, /^ап/i, /^мај/i, /^јун/i, /^јул/i, /^авг/i, /^с/i, /^о/i, /^н/i, /^д/i]
};
var matchDayPatterns$e = {
  narrow: /^[пусчн]/i,
  short: /^(нед|пон|уто|сре|чет|пет|суб)/i,
  abbreviated: /^(нед|пон|уто|сре|чет|пет|суб)/i,
  wide: /^(недеља|понедељак|уторак|среда|четвртак|петак|субота)/i
};
var parseDayPatterns$e = {
  narrow: [/^п/i, /^у/i, /^с/i, /^ч/i, /^п/i, /^с/i, /^н/i],
  any: [/^нед/i, /^пон/i, /^уто/i, /^сре/i, /^чет/i, /^пет/i, /^суб/i]
};
var matchDayPeriodPatterns$e = {
  any: /^(ам|пм|поноћ|(по)?подне|увече|ноћу|после подне|ујутру)/i
};
var parseDayPeriodPatterns$e = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^поно/i,
    noon: /^под/i,
    morning: /ујутру/i,
    afternoon: /(после\s|по)+подне/i,
    evening: /(увече)/i,
    night: /(ноћу)/i
  }
};
match$e.match = {
  ordinalNumber: (0, _index2$u.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$e,
    parsePattern: parseOrdinalNumberPattern$e,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$X.buildMatchFn)({
    matchPatterns: matchEraPatterns$e,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$e,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$X.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$e,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$e,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$X.buildMatchFn)({
    matchPatterns: matchMonthPatterns$e,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$e,
    defaultParseWidth: "any"
  }),
  day: (0, _index$X.buildMatchFn)({
    matchPatterns: matchDayPatterns$e,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$e,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$X.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$e,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$e,
    defaultParseWidth: "any"
  })
};
sr.sr = void 0;
var _index$W = formatDistance$t;
var _index2$t = formatLong$e;
var _index3$e = formatRelative$t;
var _index4$e = localize$e;
var _index5$e = match$e;
sr.sr = {
  code: "sr",
  formatDistance: _index$W.formatDistance,
  formatLong: _index2$t.formatLong,
  formatRelative: _index3$e.formatRelative,
  localize: _index4$e.localize,
  match: _index5$e.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var srLatn = {};
var formatDistance$r = {};
formatDistance$r.formatDistance = void 0;
var formatDistanceLocale$d = {
  lessThanXSeconds: {
    one: {
      standalone: "manje od 1 sekunde",
      withPrepositionAgo: "manje od 1 sekunde",
      withPrepositionIn: "manje od 1 sekundu"
    },
    dual: "manje od {{count}} sekunde",
    other: "manje od {{count}} sekundi"
  },
  xSeconds: {
    one: {
      standalone: "1 sekunda",
      withPrepositionAgo: "1 sekunde",
      withPrepositionIn: "1 sekundu"
    },
    dual: "{{count}} sekunde",
    other: "{{count}} sekundi"
  },
  halfAMinute: "pola minute",
  lessThanXMinutes: {
    one: {
      standalone: "manje od 1 minute",
      withPrepositionAgo: "manje od 1 minute",
      withPrepositionIn: "manje od 1 minutu"
    },
    dual: "manje od {{count}} minute",
    other: "manje od {{count}} minuta"
  },
  xMinutes: {
    one: {
      standalone: "1 minuta",
      withPrepositionAgo: "1 minute",
      withPrepositionIn: "1 minutu"
    },
    dual: "{{count}} minute",
    other: "{{count}} minuta"
  },
  aboutXHours: {
    one: {
      standalone: "oko 1 sat",
      withPrepositionAgo: "oko 1 sat",
      withPrepositionIn: "oko 1 sat"
    },
    dual: "oko {{count}} sata",
    other: "oko {{count}} sati"
  },
  xHours: {
    one: {
      standalone: "1 sat",
      withPrepositionAgo: "1 sat",
      withPrepositionIn: "1 sat"
    },
    dual: "{{count}} sata",
    other: "{{count}} sati"
  },
  xDays: {
    one: {
      standalone: "1 dan",
      withPrepositionAgo: "1 dan",
      withPrepositionIn: "1 dan"
    },
    dual: "{{count}} dana",
    other: "{{count}} dana"
  },
  aboutXWeeks: {
    one: {
      standalone: "oko 1 nedelju",
      withPrepositionAgo: "oko 1 nedelju",
      withPrepositionIn: "oko 1 nedelju"
    },
    dual: "oko {{count}} nedelje",
    other: "oko {{count}} nedelje"
  },
  xWeeks: {
    one: {
      standalone: "1 nedelju",
      withPrepositionAgo: "1 nedelju",
      withPrepositionIn: "1 nedelju"
    },
    dual: "{{count}} nedelje",
    other: "{{count}} nedelje"
  },
  aboutXMonths: {
    one: {
      standalone: "oko 1 mesec",
      withPrepositionAgo: "oko 1 mesec",
      withPrepositionIn: "oko 1 mesec"
    },
    dual: "oko {{count}} meseca",
    other: "oko {{count}} meseci"
  },
  xMonths: {
    one: {
      standalone: "1 mesec",
      withPrepositionAgo: "1 mesec",
      withPrepositionIn: "1 mesec"
    },
    dual: "{{count}} meseca",
    other: "{{count}} meseci"
  },
  aboutXYears: {
    one: {
      standalone: "oko 1 godinu",
      withPrepositionAgo: "oko 1 godinu",
      withPrepositionIn: "oko 1 godinu"
    },
    dual: "oko {{count}} godine",
    other: "oko {{count}} godina"
  },
  xYears: {
    one: {
      standalone: "1 godina",
      withPrepositionAgo: "1 godine",
      withPrepositionIn: "1 godinu"
    },
    dual: "{{count}} godine",
    other: "{{count}} godina"
  },
  overXYears: {
    one: {
      standalone: "preko 1 godinu",
      withPrepositionAgo: "preko 1 godinu",
      withPrepositionIn: "preko 1 godinu"
    },
    dual: "preko {{count}} godine",
    other: "preko {{count}} godina"
  },
  almostXYears: {
    one: {
      standalone: "gotovo 1 godinu",
      withPrepositionAgo: "gotovo 1 godinu",
      withPrepositionIn: "gotovo 1 godinu"
    },
    dual: "gotovo {{count}} godine",
    other: "gotovo {{count}} godina"
  }
};
var formatDistance$q = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$d[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    if (t === null || t === void 0 ? void 0 : t.addSuffix) {
      if (t.comparison && t.comparison > 0) {
        r = n.one.withPrepositionIn;
      } else {
        r = n.one.withPrepositionAgo;
      }
    } else {
      r = n.one.standalone;
    }
  } else if (a % 10 > 1 && a % 10 < 5 && String(a).substr(-2, 1) !== "1") {
    r = n.dual.replace("{{count}}", String(a));
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "za " + r;
    } else {
      return "pre " + r;
    }
  }
  return r;
};
formatDistance$r.formatDistance = formatDistance$q;
var formatLong$d = {};
formatLong$d.formatLong = void 0;
var _index$V = buildFormatLongFn;
var dateFormats$d = {
  full: "EEEE, d. MMMM yyyy.",
  long: "d. MMMM yyyy.",
  medium: "d. MMM yy.",
  short: "dd. MM. yy."
};
var timeFormats$d = {
  full: "HH:mm:ss (zzzz)",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$d = {
  full: "{{date}} 'u' {{time}}",
  long: "{{date}} 'u' {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$d.formatLong = {
  date: (0, _index$V.buildFormatLongFn)({
    formats: dateFormats$d,
    defaultWidth: "full"
  }),
  time: (0, _index$V.buildFormatLongFn)({
    formats: timeFormats$d,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$V.buildFormatLongFn)({
    formats: dateTimeFormats$d,
    defaultWidth: "full"
  })
};
var formatRelative$r = {};
formatRelative$r.formatRelative = void 0;
var formatRelativeLocale$d = {
  lastWeek: function (e) {
    switch (e.getDay()) {
      case 0:
        return "'prošle nedelje u' p";
      case 3:
        return "'prošle srede u' p";
      case 6:
        return "'prošle subote u' p";
      default:
        return "'prošli' EEEE 'u' p";
    }
  },
  yesterday: "'juče u' p",
  today: "'danas u' p",
  tomorrow: "'sutra u' p",
  nextWeek: function (e) {
    switch (e.getDay()) {
      case 0:
        return "'sledeće nedelje u' p";
      case 3:
        return "'sledeću sredu u' p";
      case 6:
        return "'sledeću subotu u' p";
      default:
        return "'sledeći' EEEE 'u' p";
    }
  },
  other: "P"
};
var formatRelative$q = function (e, a, t, r) {
  var n = formatRelativeLocale$d[e];
  if (typeof n === "function") {
    return n(a);
  }
  return n;
};
formatRelative$r.formatRelative = formatRelative$q;
var localize$d = {};
localize$d.localize = void 0;
var _index$U = buildLocalizeFn;
var eraValues$d = {
  narrow: ["pr.n.e.", "AD"],
  abbreviated: ["pr. Hr.", "po. Hr."],
  wide: ["Pre Hrista", "Posle Hrista"]
};
var quarterValues$d = {
  narrow: ["1.", "2.", "3.", "4."],
  abbreviated: ["1. kv.", "2. kv.", "3. kv.", "4. kv."],
  wide: ["1. kvartal", "2. kvartal", "3. kvartal", "4. kvartal"]
};
var monthValues$d = {
  narrow: ["1.", "2.", "3.", "4.", "5.", "6.", "7.", "8.", "9.", "10.", "11.", "12."],
  abbreviated: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "avg", "sep", "okt", "nov", "dec"],
  wide: ["januar", "februar", "mart", "april", "maj", "jun", "jul", "avgust", "septembar", "oktobar", "novembar", "decembar"]
};
var formattingMonthValues$2 = {
  narrow: ["1.", "2.", "3.", "4.", "5.", "6.", "7.", "8.", "9.", "10.", "11.", "12."],
  abbreviated: ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "avg", "sep", "okt", "nov", "dec"],
  wide: ["januar", "februar", "mart", "april", "maj", "jun", "jul", "avgust", "septembar", "oktobar", "novembar", "decembar"]
};
var dayValues$d = {
  narrow: ["N", "P", "U", "S", "Č", "P", "S"],
  short: ["ned", "pon", "uto", "sre", "čet", "pet", "sub"],
  abbreviated: ["ned", "pon", "uto", "sre", "čet", "pet", "sub"],
  wide: ["nedelja", "ponedeljak", "utorak", "sreda", "četvrtak", "petak", "subota"]
};
var formattingDayPeriodValues$d = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutru",
    afternoon: "popodne",
    evening: "uveče",
    night: "noću"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutru",
    afternoon: "popodne",
    evening: "uveče",
    night: "noću"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutru",
    afternoon: "posle podne",
    evening: "uveče",
    night: "noću"
  }
};
var dayPeriodValues$d = {
  narrow: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutru",
    afternoon: "popodne",
    evening: "uveče",
    night: "noću"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutru",
    afternoon: "popodne",
    evening: "uveče",
    night: "noću"
  },
  wide: {
    am: "AM",
    pm: "PM",
    midnight: "ponoć",
    noon: "podne",
    morning: "ujutru",
    afternoon: "posle podne",
    evening: "uveče",
    night: "noću"
  }
};
var ordinalNumber$d = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$d.localize = {
  ordinalNumber: ordinalNumber$d,
  era: (0, _index$U.buildLocalizeFn)({
    values: eraValues$d,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$U.buildLocalizeFn)({
    values: quarterValues$d,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$U.buildLocalizeFn)({
    values: monthValues$d,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$2,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$U.buildLocalizeFn)({
    values: dayValues$d,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$U.buildLocalizeFn)({
    values: dayPeriodValues$d,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$d,
    defaultFormattingWidth: "wide"
  })
};
var match$d = {};
match$d.match = void 0;
var _index$T = buildMatchFn;
var _index2$s = buildMatchPatternFn;
var matchOrdinalNumberPattern$d = /^(\d+)\./i;
var parseOrdinalNumberPattern$d = /\d+/i;
var matchEraPatterns$d = {
  narrow: /^(pr\.n\.e\.|AD)/i,
  abbreviated: /^(pr\.\s?Hr\.|po\.\s?Hr\.)/i,
  wide: /^(Pre Hrista|pre nove ere|Posle Hrista|nova era)/i
};
var parseEraPatterns$d = {
  any: [/^pr/i, /^(po|nova)/i]
};
var matchQuarterPatterns$d = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234]\.\s?kv\.?/i,
  wide: /^[1234]\. kvartal/i
};
var parseQuarterPatterns$d = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$d = {
  narrow: /^(10|11|12|[123456789])\./i,
  abbreviated: /^(jan|feb|mar|apr|maj|jun|jul|avg|sep|okt|nov|dec)/i,
  wide: /^((januar|januara)|(februar|februara)|(mart|marta)|(april|aprila)|(maj|maja)|(jun|juna)|(jul|jula)|(avgust|avgusta)|(septembar|septembra)|(oktobar|oktobra)|(novembar|novembra)|(decembar|decembra))/i
};
var parseMonthPatterns$d = {
  narrow: [/^1/i, /^2/i, /^3/i, /^4/i, /^5/i, /^6/i, /^7/i, /^8/i, /^9/i, /^10/i, /^11/i, /^12/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^maj/i, /^jun/i, /^jul/i, /^avg/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$d = {
  narrow: /^[npusčc]/i,
  short: /^(ned|pon|uto|sre|(čet|cet)|pet|sub)/i,
  abbreviated: /^(ned|pon|uto|sre|(čet|cet)|pet|sub)/i,
  wide: /^(nedelja|ponedeljak|utorak|sreda|(četvrtak|cetvrtak)|petak|subota)/i
};
var parseDayPatterns$d = {
  narrow: [/^s/i, /^m/i, /^t/i, /^w/i, /^t/i, /^f/i, /^s/i],
  any: [/^su/i, /^m/i, /^tu/i, /^w/i, /^th/i, /^f/i, /^sa/i]
};
var matchDayPeriodPatterns$d = {
  any: /^(am|pm|ponoc|ponoć|(po)?podne|uvece|uveče|noću|posle podne|ujutru)/i
};
var parseDayPeriodPatterns$d = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^pono/i,
    noon: /^pod/i,
    morning: /jutro/i,
    afternoon: /(posle\s|po)+podne/i,
    evening: /(uvece|uveče)/i,
    night: /(nocu|noću)/i
  }
};
match$d.match = {
  ordinalNumber: (0, _index2$s.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$d,
    parsePattern: parseOrdinalNumberPattern$d,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$T.buildMatchFn)({
    matchPatterns: matchEraPatterns$d,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$d,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$T.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$d,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$d,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$T.buildMatchFn)({
    matchPatterns: matchMonthPatterns$d,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$d,
    defaultParseWidth: "any"
  }),
  day: (0, _index$T.buildMatchFn)({
    matchPatterns: matchDayPatterns$d,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$d,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$T.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$d,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$d,
    defaultParseWidth: "any"
  })
};
srLatn.srLatn = void 0;
var _index$S = formatDistance$r;
var _index2$r = formatLong$d;
var _index3$d = formatRelative$r;
var _index4$d = localize$d;
var _index5$d = match$d;
srLatn.srLatn = {
  code: "sr-Latn",
  formatDistance: _index$S.formatDistance,
  formatLong: _index2$r.formatLong,
  formatRelative: _index3$d.formatRelative,
  localize: _index4$d.localize,
  match: _index5$d.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var sv = {};
var formatDistance$p = {};
formatDistance$p.formatDistance = void 0;
var formatDistanceLocale$c = {
  lessThanXSeconds: {
    one: "mindre än en sekund",
    other: "mindre än {{count}} sekunder"
  },
  xSeconds: {
    one: "en sekund",
    other: "{{count}} sekunder"
  },
  halfAMinute: "en halv minut",
  lessThanXMinutes: {
    one: "mindre än en minut",
    other: "mindre än {{count}} minuter"
  },
  xMinutes: {
    one: "en minut",
    other: "{{count}} minuter"
  },
  aboutXHours: {
    one: "ungefär en timme",
    other: "ungefär {{count}} timmar"
  },
  xHours: {
    one: "en timme",
    other: "{{count}} timmar"
  },
  xDays: {
    one: "en dag",
    other: "{{count}} dagar"
  },
  aboutXWeeks: {
    one: "ungefär en vecka",
    other: "ungefär {{count}} veckor"
  },
  xWeeks: {
    one: "en vecka",
    other: "{{count}} veckor"
  },
  aboutXMonths: {
    one: "ungefär en månad",
    other: "ungefär {{count}} månader"
  },
  xMonths: {
    one: "en månad",
    other: "{{count}} månader"
  },
  aboutXYears: {
    one: "ungefär ett år",
    other: "ungefär {{count}} år"
  },
  xYears: {
    one: "ett år",
    other: "{{count}} år"
  },
  overXYears: {
    one: "över ett år",
    other: "över {{count}} år"
  },
  almostXYears: {
    one: "nästan ett år",
    other: "nästan {{count}} år"
  }
};
var wordMapping = ["noll", "en", "två", "tre", "fyra", "fem", "sex", "sju", "åtta", "nio", "tio", "elva", "tolv"];
var formatDistance$o = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$c[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", a < 13 ? wordMapping[a] : String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return "om " + r;
    } else {
      return r + " sedan";
    }
  }
  return r;
};
formatDistance$p.formatDistance = formatDistance$o;
var formatLong$c = {};
formatLong$c.formatLong = void 0;
var _index$R = buildFormatLongFn;
var dateFormats$c = {
  full: "EEEE d MMMM y",
  long: "d MMMM y",
  medium: "d MMM y",
  short: "y-MM-dd"
};
var timeFormats$c = {
  full: "'kl'. HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$c = {
  full: "{{date}} 'kl.' {{time}}",
  long: "{{date}} 'kl.' {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$c.formatLong = {
  date: (0, _index$R.buildFormatLongFn)({
    formats: dateFormats$c,
    defaultWidth: "full"
  }),
  time: (0, _index$R.buildFormatLongFn)({
    formats: timeFormats$c,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$R.buildFormatLongFn)({
    formats: dateTimeFormats$c,
    defaultWidth: "full"
  })
};
var formatRelative$p = {};
formatRelative$p.formatRelative = void 0;
var formatRelativeLocale$c = {
  lastWeek: "'i' EEEE's kl.' p",
  yesterday: "'igår kl.' p",
  today: "'idag kl.' p",
  tomorrow: "'imorgon kl.' p",
  nextWeek: "EEEE 'kl.' p",
  other: "P"
};
var formatRelative$o = function (e, a, t, r) {
  return formatRelativeLocale$c[e];
};
formatRelative$p.formatRelative = formatRelative$o;
var localize$c = {};
localize$c.localize = void 0;
var _index$Q = buildLocalizeFn;
var eraValues$c = {
  narrow: ["f.Kr.", "e.Kr."],
  abbreviated: ["f.Kr.", "e.Kr."],
  wide: ["före Kristus", "efter Kristus"]
};
var quarterValues$c = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["1:a kvartalet", "2:a kvartalet", "3:e kvartalet", "4:e kvartalet"]
};
var monthValues$c = {
  narrow: ["J", "F", "M", "A", "M", "J", "J", "A", "S", "O", "N", "D"],
  abbreviated: ["jan.", "feb.", "mars", "apr.", "maj", "juni", "juli", "aug.", "sep.", "okt.", "nov.", "dec."],
  wide: ["januari", "februari", "mars", "april", "maj", "juni", "juli", "augusti", "september", "oktober", "november", "december"]
};
var dayValues$c = {
  narrow: ["S", "M", "T", "O", "T", "F", "L"],
  short: ["sö", "må", "ti", "on", "to", "fr", "lö"],
  abbreviated: ["sön", "mån", "tis", "ons", "tors", "fre", "lör"],
  wide: ["söndag", "måndag", "tisdag", "onsdag", "torsdag", "fredag", "lördag"]
};
var dayPeriodValues$c = {
  narrow: {
    am: "fm",
    pm: "em",
    midnight: "midnatt",
    noon: "middag",
    morning: "morg.",
    afternoon: "efterm.",
    evening: "kväll",
    night: "natt"
  },
  abbreviated: {
    am: "f.m.",
    pm: "e.m.",
    midnight: "midnatt",
    noon: "middag",
    morning: "morgon",
    afternoon: "efterm.",
    evening: "kväll",
    night: "natt"
  },
  wide: {
    am: "förmiddag",
    pm: "eftermiddag",
    midnight: "midnatt",
    noon: "middag",
    morning: "morgon",
    afternoon: "eftermiddag",
    evening: "kväll",
    night: "natt"
  }
};
var formattingDayPeriodValues$c = {
  narrow: {
    am: "fm",
    pm: "em",
    midnight: "midnatt",
    noon: "middag",
    morning: "på morg.",
    afternoon: "på efterm.",
    evening: "på kvällen",
    night: "på natten"
  },
  abbreviated: {
    am: "fm",
    pm: "em",
    midnight: "midnatt",
    noon: "middag",
    morning: "på morg.",
    afternoon: "på efterm.",
    evening: "på kvällen",
    night: "på natten"
  },
  wide: {
    am: "fm",
    pm: "em",
    midnight: "midnatt",
    noon: "middag",
    morning: "på morgonen",
    afternoon: "på eftermiddagen",
    evening: "på kvällen",
    night: "på natten"
  }
};
var ordinalNumber$c = function (e, a) {
  var t = Number(e);
  var r = t % 100;
  if (r > 20 || r < 10) {
    switch (r % 10) {
      case 1:
      case 2:
        return t + ":a";
    }
  }
  return t + ":e";
};
localize$c.localize = {
  ordinalNumber: ordinalNumber$c,
  era: (0, _index$Q.buildLocalizeFn)({
    values: eraValues$c,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$Q.buildLocalizeFn)({
    values: quarterValues$c,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$Q.buildLocalizeFn)({
    values: monthValues$c,
    defaultWidth: "wide"
  }),
  day: (0, _index$Q.buildLocalizeFn)({
    values: dayValues$c,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$Q.buildLocalizeFn)({
    values: dayPeriodValues$c,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$c,
    defaultFormattingWidth: "wide"
  })
};
var match$c = {};
match$c.match = void 0;
var _index$P = buildMatchFn;
var _index2$q = buildMatchPatternFn;
var matchOrdinalNumberPattern$c = /^(\d+)(:a|:e)?/i;
var parseOrdinalNumberPattern$c = /\d+/i;
var matchEraPatterns$c = {
  narrow: /^(f\.? ?Kr\.?|f\.? ?v\.? ?t\.?|e\.? ?Kr\.?|v\.? ?t\.?)/i,
  abbreviated: /^(f\.? ?Kr\.?|f\.? ?v\.? ?t\.?|e\.? ?Kr\.?|v\.? ?t\.?)/i,
  wide: /^(före Kristus|före vår tid|efter Kristus|vår tid)/i
};
var parseEraPatterns$c = {
  any: [/^f/i, /^[ev]/i]
};
var matchQuarterPatterns$c = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](:a|:e)? kvartalet/i
};
var parseQuarterPatterns$c = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$c = {
  narrow: /^[jfmasond]/i,
  abbreviated: /^(jan|feb|mar[s]?|apr|maj|jun[i]?|jul[i]?|aug|sep|okt|nov|dec)\.?/i,
  wide: /^(januari|februari|mars|april|maj|juni|juli|augusti|september|oktober|november|december)/i
};
var parseMonthPatterns$c = {
  narrow: [/^j/i, /^f/i, /^m/i, /^a/i, /^m/i, /^j/i, /^j/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ja/i, /^f/i, /^mar/i, /^ap/i, /^maj/i, /^jun/i, /^jul/i, /^au/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$c = {
  narrow: /^[smtofl]/i,
  short: /^(sö|må|ti|on|to|fr|lö)/i,
  abbreviated: /^(sön|mån|tis|ons|tors|fre|lör)/i,
  wide: /^(söndag|måndag|tisdag|onsdag|torsdag|fredag|lördag)/i
};
var parseDayPatterns$c = {
  any: [/^s/i, /^m/i, /^ti/i, /^o/i, /^to/i, /^f/i, /^l/i]
};
var matchDayPeriodPatterns$c = {
  any: /^([fe]\.?\s?m\.?|midn(att)?|midd(ag)?|(på) (morgonen|eftermiddagen|kvällen|natten))/i
};
var parseDayPeriodPatterns$c = {
  any: {
    am: /^f/i,
    pm: /^e/i,
    midnight: /^midn/i,
    noon: /^midd/i,
    morning: /morgon/i,
    afternoon: /eftermiddag/i,
    evening: /kväll/i,
    night: /natt/i
  }
};
match$c.match = {
  ordinalNumber: (0, _index2$q.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$c,
    parsePattern: parseOrdinalNumberPattern$c,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$P.buildMatchFn)({
    matchPatterns: matchEraPatterns$c,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$c,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$P.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$c,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$c,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$P.buildMatchFn)({
    matchPatterns: matchMonthPatterns$c,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$c,
    defaultParseWidth: "any"
  }),
  day: (0, _index$P.buildMatchFn)({
    matchPatterns: matchDayPatterns$c,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$c,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$P.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$c,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$c,
    defaultParseWidth: "any"
  })
};
sv.sv = void 0;
var _index$O = formatDistance$p;
var _index2$p = formatLong$c;
var _index3$c = formatRelative$p;
var _index4$c = localize$c;
var _index5$c = match$c;
sv.sv = {
  code: "sv",
  formatDistance: _index$O.formatDistance,
  formatLong: _index2$p.formatLong,
  formatRelative: _index3$c.formatRelative,
  localize: _index4$c.localize,
  match: _index5$c.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var ta = {};
var formatDistance$n = {};
formatDistance$n.formatDistance = void 0;
function isPluralType(e) {
  return e.one !== undefined;
}
var formatDistanceLocale$b = {
  lessThanXSeconds: {
    one: {
      default: "ஒரு வினாடிக்கு குறைவாக",
      in: "ஒரு வினாடிக்குள்",
      ago: "ஒரு வினாடிக்கு முன்பு"
    },
    other: {
      default: "{{count}} வினாடிகளுக்கு குறைவாக",
      in: "{{count}} வினாடிகளுக்குள்",
      ago: "{{count}} வினாடிகளுக்கு முன்பு"
    }
  },
  xSeconds: {
    one: {
      default: "1 வினாடி",
      in: "1 வினாடியில்",
      ago: "1 வினாடி முன்பு"
    },
    other: {
      default: "{{count}} விநாடிகள்",
      in: "{{count}} வினாடிகளில்",
      ago: "{{count}} விநாடிகளுக்கு முன்பு"
    }
  },
  halfAMinute: {
    default: "அரை நிமிடம்",
    in: "அரை நிமிடத்தில்",
    ago: "அரை நிமிடம் முன்பு"
  },
  lessThanXMinutes: {
    one: {
      default: "ஒரு நிமிடத்திற்கும் குறைவாக",
      in: "ஒரு நிமிடத்திற்குள்",
      ago: "ஒரு நிமிடத்திற்கு முன்பு"
    },
    other: {
      default: "{{count}} நிமிடங்களுக்கும் குறைவாக",
      in: "{{count}} நிமிடங்களுக்குள்",
      ago: "{{count}} நிமிடங்களுக்கு முன்பு"
    }
  },
  xMinutes: {
    one: {
      default: "1 நிமிடம்",
      in: "1 நிமிடத்தில்",
      ago: "1 நிமிடம் முன்பு"
    },
    other: {
      default: "{{count}} நிமிடங்கள்",
      in: "{{count}} நிமிடங்களில்",
      ago: "{{count}} நிமிடங்களுக்கு முன்பு"
    }
  },
  aboutXHours: {
    one: {
      default: "சுமார் 1 மணி நேரம்",
      in: "சுமார் 1 மணி நேரத்தில்",
      ago: "சுமார் 1 மணி நேரத்திற்கு முன்பு"
    },
    other: {
      default: "சுமார் {{count}} மணி நேரம்",
      in: "சுமார் {{count}} மணி நேரத்திற்கு முன்பு",
      ago: "சுமார் {{count}} மணி நேரத்தில்"
    }
  },
  xHours: {
    one: {
      default: "1 மணி நேரம்",
      in: "1 மணி நேரத்தில்",
      ago: "1 மணி நேரத்திற்கு முன்பு"
    },
    other: {
      default: "{{count}} மணி நேரம்",
      in: "{{count}} மணி நேரத்தில்",
      ago: "{{count}} மணி நேரத்திற்கு முன்பு"
    }
  },
  xDays: {
    one: {
      default: "1 நாள்",
      in: "1 நாளில்",
      ago: "1 நாள் முன்பு"
    },
    other: {
      default: "{{count}} நாட்கள்",
      in: "{{count}} நாட்களில்",
      ago: "{{count}} நாட்களுக்கு முன்பு"
    }
  },
  aboutXWeeks: {
    one: {
      default: "சுமார் 1 வாரம்",
      in: "சுமார் 1 வாரத்தில்",
      ago: "சுமார் 1 வாரம் முன்பு"
    },
    other: {
      default: "சுமார் {{count}} வாரங்கள்",
      in: "சுமார் {{count}} வாரங்களில்",
      ago: "சுமார் {{count}} வாரங்களுக்கு முன்பு"
    }
  },
  xWeeks: {
    one: {
      default: "1 வாரம்",
      in: "1 வாரத்தில்",
      ago: "1 வாரம் முன்பு"
    },
    other: {
      default: "{{count}} வாரங்கள்",
      in: "{{count}} வாரங்களில்",
      ago: "{{count}} வாரங்களுக்கு முன்பு"
    }
  },
  aboutXMonths: {
    one: {
      default: "சுமார் 1 மாதம்",
      in: "சுமார் 1 மாதத்தில்",
      ago: "சுமார் 1 மாதத்திற்கு முன்பு"
    },
    other: {
      default: "சுமார் {{count}} மாதங்கள்",
      in: "சுமார் {{count}} மாதங்களில்",
      ago: "சுமார் {{count}} மாதங்களுக்கு முன்பு"
    }
  },
  xMonths: {
    one: {
      default: "1 மாதம்",
      in: "1 மாதத்தில்",
      ago: "1 மாதம் முன்பு"
    },
    other: {
      default: "{{count}} மாதங்கள்",
      in: "{{count}} மாதங்களில்",
      ago: "{{count}} மாதங்களுக்கு முன்பு"
    }
  },
  aboutXYears: {
    one: {
      default: "சுமார் 1 வருடம்",
      in: "சுமார் 1 ஆண்டில்",
      ago: "சுமார் 1 வருடம் முன்பு"
    },
    other: {
      default: "சுமார் {{count}} ஆண்டுகள்",
      in: "சுமார் {{count}} ஆண்டுகளில்",
      ago: "சுமார் {{count}} ஆண்டுகளுக்கு முன்பு"
    }
  },
  xYears: {
    one: {
      default: "1 வருடம்",
      in: "1 ஆண்டில்",
      ago: "1 வருடம் முன்பு"
    },
    other: {
      default: "{{count}} ஆண்டுகள்",
      in: "{{count}} ஆண்டுகளில்",
      ago: "{{count}} ஆண்டுகளுக்கு முன்பு"
    }
  },
  overXYears: {
    one: {
      default: "1 வருடத்திற்கு மேல்",
      in: "1 வருடத்திற்கும் மேலாக",
      ago: "1 வருடம் முன்பு"
    },
    other: {
      default: "{{count}} ஆண்டுகளுக்கும் மேலாக",
      in: "{{count}} ஆண்டுகளில்",
      ago: "{{count}} ஆண்டுகளுக்கு முன்பு"
    }
  },
  almostXYears: {
    one: {
      default: "கிட்டத்தட்ட 1 வருடம்",
      in: "கிட்டத்தட்ட 1 ஆண்டில்",
      ago: "கிட்டத்தட்ட 1 வருடம் முன்பு"
    },
    other: {
      default: "கிட்டத்தட்ட {{count}} ஆண்டுகள்",
      in: "கிட்டத்தட்ட {{count}} ஆண்டுகளில்",
      ago: "கிட்டத்தட்ட {{count}} ஆண்டுகளுக்கு முன்பு"
    }
  }
};
var formatDistance$m = function (e, a, t) {
  var r = (t === null || t === void 0 ? void 0 : t.addSuffix) ? t.comparison && t.comparison > 0 ? "in" : "ago" : "default";
  var n = formatDistanceLocale$b[e];
  if (!isPluralType(n)) return n[r];
  if (a === 1) {
    return n.one[r];
  } else {
    return n.other[r].replace("{{count}}", String(a));
  }
};
formatDistance$n.formatDistance = formatDistance$m;
var formatLong$b = {};
formatLong$b.formatLong = void 0;
var _index$N = buildFormatLongFn;
var dateFormats$b = {
  full: "EEEE, d MMMM, y",
  long: "d MMMM, y",
  medium: "d MMM, y",
  short: "d/M/yy"
};
var timeFormats$b = {
  full: "a h:mm:ss zzzz",
  long: "a h:mm:ss z",
  medium: "a h:mm:ss",
  short: "a h:mm"
};
var dateTimeFormats$b = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$b.formatLong = {
  date: (0, _index$N.buildFormatLongFn)({
    formats: dateFormats$b,
    defaultWidth: "full"
  }),
  time: (0, _index$N.buildFormatLongFn)({
    formats: timeFormats$b,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$N.buildFormatLongFn)({
    formats: dateTimeFormats$b,
    defaultWidth: "full"
  })
};
var formatRelative$n = {};
formatRelative$n.formatRelative = void 0;
var formatRelativeLocale$b = {
  lastWeek: "'கடந்த' eeee p 'மணிக்கு'",
  yesterday: "'நேற்று ' p 'மணிக்கு'",
  today: "'இன்று ' p 'மணிக்கு'",
  tomorrow: "'நாளை ' p 'மணிக்கு'",
  nextWeek: "eeee p 'மணிக்கு'",
  other: "P"
};
var formatRelative$m = function (e, a, t, r) {
  return formatRelativeLocale$b[e];
};
formatRelative$n.formatRelative = formatRelative$m;
var localize$b = {};
localize$b.localize = void 0;
var _index$M = buildLocalizeFn;
var eraValues$b = {
  narrow: ["கி.மு.", "கி.பி."],
  abbreviated: ["கி.மு.", "கி.பி."],
  wide: ["கிறிஸ்துவுக்கு முன்", "அன்னோ டோமினி"]
};
var quarterValues$b = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["காலா.1", "காலா.2", "காலா.3", "காலா.4"],
  wide: ["ஒன்றாம் காலாண்டு", "இரண்டாம் காலாண்டு", "மூன்றாம் காலாண்டு", "நான்காம் காலாண்டு"]
};
var monthValues$b = {
  narrow: ["ஜ", "பி", "மா", "ஏ", "மே", "ஜூ", "ஜூ", "ஆ", "செ", "அ", "ந", "டி"],
  abbreviated: ["ஜன.", "பிப்.", "மார்.", "ஏப்.", "மே", "ஜூன்", "ஜூலை", "ஆக.", "செப்.", "அக்.", "நவ.", "டிச."],
  wide: ["ஜனவரி", "பிப்ரவரி", "மார்ச்", "ஏப்ரல்", "மே", "ஜூன்", "ஜூலை", "ஆகஸ்ட்", "செப்டம்பர்", "அக்டோபர்", "நவம்பர்", "டிசம்பர்"]
};
var dayValues$b = {
  narrow: ["ஞா", "தி", "செ", "பு", "வி", "வெ", "ச"],
  short: ["ஞா", "தி", "செ", "பு", "வி", "வெ", "ச"],
  abbreviated: ["ஞாயி.", "திங்.", "செவ்.", "புத.", "வியா.", "வெள்.", "சனி"],
  wide: ["ஞாயிறு", "திங்கள்", "செவ்வாய்", "புதன்", "வியாழன்", "வெள்ளி", "சனி"]
};
var dayPeriodValues$b = {
  narrow: {
    am: "மு.ப",
    pm: "பி.ப",
    midnight: "நள்.",
    noon: "நண்.",
    morning: "கா.",
    afternoon: "மதி.",
    evening: "மா.",
    night: "இர."
  },
  abbreviated: {
    am: "முற்பகல்",
    pm: "பிற்பகல்",
    midnight: "நள்ளிரவு",
    noon: "நண்பகல்",
    morning: "காலை",
    afternoon: "மதியம்",
    evening: "மாலை",
    night: "இரவு"
  },
  wide: {
    am: "முற்பகல்",
    pm: "பிற்பகல்",
    midnight: "நள்ளிரவு",
    noon: "நண்பகல்",
    morning: "காலை",
    afternoon: "மதியம்",
    evening: "மாலை",
    night: "இரவு"
  }
};
var formattingDayPeriodValues$b = {
  narrow: {
    am: "மு.ப",
    pm: "பி.ப",
    midnight: "நள்.",
    noon: "நண்.",
    morning: "கா.",
    afternoon: "மதி.",
    evening: "மா.",
    night: "இர."
  },
  abbreviated: {
    am: "முற்பகல்",
    pm: "பிற்பகல்",
    midnight: "நள்ளிரவு",
    noon: "நண்பகல்",
    morning: "காலை",
    afternoon: "மதியம்",
    evening: "மாலை",
    night: "இரவு"
  },
  wide: {
    am: "முற்பகல்",
    pm: "பிற்பகல்",
    midnight: "நள்ளிரவு",
    noon: "நண்பகல்",
    morning: "காலை",
    afternoon: "மதியம்",
    evening: "மாலை",
    night: "இரவு"
  }
};
var ordinalNumber$b = function (e, a) {
  return String(e);
};
localize$b.localize = {
  ordinalNumber: ordinalNumber$b,
  era: (0, _index$M.buildLocalizeFn)({
    values: eraValues$b,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$M.buildLocalizeFn)({
    values: quarterValues$b,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$M.buildLocalizeFn)({
    values: monthValues$b,
    defaultWidth: "wide"
  }),
  day: (0, _index$M.buildLocalizeFn)({
    values: dayValues$b,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$M.buildLocalizeFn)({
    values: dayPeriodValues$b,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$b,
    defaultFormattingWidth: "wide"
  })
};
var match$b = {};
match$b.match = void 0;
var _index$L = buildMatchFn;
var _index2$o = buildMatchPatternFn;
var matchOrdinalNumberPattern$b = /^(\d+)(வது)?/i;
var parseOrdinalNumberPattern$b = /\d+/i;
var matchEraPatterns$b = {
  narrow: /^(கி.மு.|கி.பி.)/i,
  abbreviated: /^(கி\.?\s?மு\.?|கி\.?\s?பி\.?)/,
  wide: /^(கிறிஸ்துவுக்கு\sமுன்|அன்னோ\sடோமினி)/i
};
var parseEraPatterns$b = {
  any: [/கி\.?\s?மு\.?/, /கி\.?\s?பி\.?/]
};
var matchQuarterPatterns$b = {
  narrow: /^[1234]/i,
  abbreviated: /^காலா.[1234]/i,
  wide: /^(ஒன்றாம்|இரண்டாம்|மூன்றாம்|நான்காம்) காலாண்டு/i
};
var parseQuarterPatterns$b = {
  narrow: [/1/i, /2/i, /3/i, /4/i],
  any: [/(1|காலா.1|ஒன்றாம்)/i, /(2|காலா.2|இரண்டாம்)/i, /(3|காலா.3|மூன்றாம்)/i, /(4|காலா.4|நான்காம்)/i]
};
var matchMonthPatterns$b = {
  narrow: /^(ஜ|பி|மா|ஏ|மே|ஜூ|ஆ|செ|அ|ந|டி)$/i,
  abbreviated: /^(ஜன.|பிப்.|மார்.|ஏப்.|மே|ஜூன்|ஜூலை|ஆக.|செப்.|அக்.|நவ.|டிச.)/i,
  wide: /^(ஜனவரி|பிப்ரவரி|மார்ச்|ஏப்ரல்|மே|ஜூன்|ஜூலை|ஆகஸ்ட்|செப்டம்பர்|அக்டோபர்|நவம்பர்|டிசம்பர்)/i
};
var parseMonthPatterns$b = {
  narrow: [/^ஜ$/i, /^பி/i, /^மா/i, /^ஏ/i, /^மே/i, /^ஜூ/i, /^ஜூ/i, /^ஆ/i, /^செ/i, /^அ/i, /^ந/i, /^டி/i],
  any: [/^ஜன/i, /^பி/i, /^மா/i, /^ஏ/i, /^மே/i, /^ஜூன்/i, /^ஜூலை/i, /^ஆ/i, /^செ/i, /^அ/i, /^ந/i, /^டி/i]
};
var matchDayPatterns$b = {
  narrow: /^(ஞா|தி|செ|பு|வி|வெ|ச)/i,
  short: /^(ஞா|தி|செ|பு|வி|வெ|ச)/i,
  abbreviated: /^(ஞாயி.|திங்.|செவ்.|புத.|வியா.|வெள்.|சனி)/i,
  wide: /^(ஞாயிறு|திங்கள்|செவ்வாய்|புதன்|வியாழன்|வெள்ளி|சனி)/i
};
var parseDayPatterns$b = {
  narrow: [/^ஞா/i, /^தி/i, /^செ/i, /^பு/i, /^வி/i, /^வெ/i, /^ச/i],
  any: [/^ஞா/i, /^தி/i, /^செ/i, /^பு/i, /^வி/i, /^வெ/i, /^ச/i]
};
var matchDayPeriodPatterns$b = {
  narrow: /^(மு.ப|பி.ப|நள்|நண்|காலை|மதியம்|மாலை|இரவு)/i,
  any: /^(மு.ப|பி.ப|முற்பகல்|பிற்பகல்|நள்ளிரவு|நண்பகல்|காலை|மதியம்|மாலை|இரவு)/i
};
var parseDayPeriodPatterns$b = {
  any: {
    am: /^மு/i,
    pm: /^பி/i,
    midnight: /^நள்/i,
    noon: /^நண்/i,
    morning: /காலை/i,
    afternoon: /மதியம்/i,
    evening: /மாலை/i,
    night: /இரவு/i
  }
};
match$b.match = {
  ordinalNumber: (0, _index2$o.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$b,
    parsePattern: parseOrdinalNumberPattern$b,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$L.buildMatchFn)({
    matchPatterns: matchEraPatterns$b,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$b,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$L.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$b,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$b,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$L.buildMatchFn)({
    matchPatterns: matchMonthPatterns$b,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$b,
    defaultParseWidth: "any"
  }),
  day: (0, _index$L.buildMatchFn)({
    matchPatterns: matchDayPatterns$b,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$b,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$L.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$b,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$b,
    defaultParseWidth: "any"
  })
};
ta.ta = void 0;
var _index$K = formatDistance$n;
var _index2$n = formatLong$b;
var _index3$b = formatRelative$n;
var _index4$b = localize$b;
var _index5$b = match$b;
ta.ta = {
  code: "ta",
  formatDistance: _index$K.formatDistance,
  formatLong: _index2$n.formatLong,
  formatRelative: _index3$b.formatRelative,
  localize: _index4$b.localize,
  match: _index5$b.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var te = {};
var formatDistance$l = {};
formatDistance$l.formatDistance = void 0;
var formatDistanceLocale$a = {
  lessThanXSeconds: {
    standalone: {
      one: "సెకను కన్నా తక్కువ",
      other: "{{count}} సెకన్ల కన్నా తక్కువ"
    },
    withPreposition: {
      one: "సెకను",
      other: "{{count}} సెకన్ల"
    }
  },
  xSeconds: {
    standalone: {
      one: "ఒక సెకను",
      other: "{{count}} సెకన్ల"
    },
    withPreposition: {
      one: "ఒక సెకను",
      other: "{{count}} సెకన్ల"
    }
  },
  halfAMinute: {
    standalone: "అర నిమిషం",
    withPreposition: "అర నిమిషం"
  },
  lessThanXMinutes: {
    standalone: {
      one: "ఒక నిమిషం కన్నా తక్కువ",
      other: "{{count}} నిమిషాల కన్నా తక్కువ"
    },
    withPreposition: {
      one: "ఒక నిమిషం",
      other: "{{count}} నిమిషాల"
    }
  },
  xMinutes: {
    standalone: {
      one: "ఒక నిమిషం",
      other: "{{count}} నిమిషాలు"
    },
    withPreposition: {
      one: "ఒక నిమిషం",
      other: "{{count}} నిమిషాల"
    }
  },
  aboutXHours: {
    standalone: {
      one: "సుమారు ఒక గంట",
      other: "సుమారు {{count}} గంటలు"
    },
    withPreposition: {
      one: "సుమారు ఒక గంట",
      other: "సుమారు {{count}} గంటల"
    }
  },
  xHours: {
    standalone: {
      one: "ఒక గంట",
      other: "{{count}} గంటలు"
    },
    withPreposition: {
      one: "ఒక గంట",
      other: "{{count}} గంటల"
    }
  },
  xDays: {
    standalone: {
      one: "ఒక రోజు",
      other: "{{count}} రోజులు"
    },
    withPreposition: {
      one: "ఒక రోజు",
      other: "{{count}} రోజుల"
    }
  },
  aboutXWeeks: {
    standalone: {
      one: "సుమారు ఒక వారం",
      other: "సుమారు {{count}} వారాలు"
    },
    withPreposition: {
      one: "సుమారు ఒక వారం",
      other: "సుమారు {{count}} వారాలల"
    }
  },
  xWeeks: {
    standalone: {
      one: "ఒక వారం",
      other: "{{count}} వారాలు"
    },
    withPreposition: {
      one: "ఒక వారం",
      other: "{{count}} వారాలల"
    }
  },
  aboutXMonths: {
    standalone: {
      one: "సుమారు ఒక నెల",
      other: "సుమారు {{count}} నెలలు"
    },
    withPreposition: {
      one: "సుమారు ఒక నెల",
      other: "సుమారు {{count}} నెలల"
    }
  },
  xMonths: {
    standalone: {
      one: "ఒక నెల",
      other: "{{count}} నెలలు"
    },
    withPreposition: {
      one: "ఒక నెల",
      other: "{{count}} నెలల"
    }
  },
  aboutXYears: {
    standalone: {
      one: "సుమారు ఒక సంవత్సరం",
      other: "సుమారు {{count}} సంవత్సరాలు"
    },
    withPreposition: {
      one: "సుమారు ఒక సంవత్సరం",
      other: "సుమారు {{count}} సంవత్సరాల"
    }
  },
  xYears: {
    standalone: {
      one: "ఒక సంవత్సరం",
      other: "{{count}} సంవత్సరాలు"
    },
    withPreposition: {
      one: "ఒక సంవత్సరం",
      other: "{{count}} సంవత్సరాల"
    }
  },
  overXYears: {
    standalone: {
      one: "ఒక సంవత్సరం పైగా",
      other: "{{count}} సంవత్సరాలకు పైగా"
    },
    withPreposition: {
      one: "ఒక సంవత్సరం",
      other: "{{count}} సంవత్సరాల"
    }
  },
  almostXYears: {
    standalone: {
      one: "దాదాపు ఒక సంవత్సరం",
      other: "దాదాపు {{count}} సంవత్సరాలు"
    },
    withPreposition: {
      one: "దాదాపు ఒక సంవత్సరం",
      other: "దాదాపు {{count}} సంవత్సరాల"
    }
  }
};
var formatDistance$k = function (e, a, t) {
  var r;
  var n = (t === null || t === void 0 ? void 0 : t.addSuffix) ? formatDistanceLocale$a[e].withPreposition : formatDistanceLocale$a[e].standalone;
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + "లో";
    } else {
      return r + " క్రితం";
    }
  }
  return r;
};
formatDistance$l.formatDistance = formatDistance$k;
var formatLong$a = {};
formatLong$a.formatLong = void 0;
var _index$J = buildFormatLongFn;
var dateFormats$a = {
  full: "d, MMMM y, EEEE",
  long: "d MMMM, y",
  medium: "d MMM, y",
  short: "dd-MM-yy"
};
var timeFormats$a = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$a = {
  full: "{{date}} {{time}}'కి'",
  long: "{{date}} {{time}}'కి'",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$a.formatLong = {
  date: (0, _index$J.buildFormatLongFn)({
    formats: dateFormats$a,
    defaultWidth: "full"
  }),
  time: (0, _index$J.buildFormatLongFn)({
    formats: timeFormats$a,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$J.buildFormatLongFn)({
    formats: dateTimeFormats$a,
    defaultWidth: "full"
  })
};
var formatRelative$l = {};
formatRelative$l.formatRelative = void 0;
var formatRelativeLocale$a = {
  lastWeek: "'గత' eeee p",
  yesterday: "'నిన్న' p",
  today: "'ఈ రోజు' p",
  tomorrow: "'రేపు' p",
  nextWeek: "'తదుపరి' eeee p",
  other: "P"
};
var formatRelative$k = function (e, a, t, r) {
  return formatRelativeLocale$a[e];
};
formatRelative$l.formatRelative = formatRelative$k;
var localize$a = {};
localize$a.localize = void 0;
var _index$I = buildLocalizeFn;
var eraValues$a = {
  narrow: ["క్రీ.పూ.", "క్రీ.శ."],
  abbreviated: ["క్రీ.పూ.", "క్రీ.శ."],
  wide: ["క్రీస్తు పూర్వం", "క్రీస్తుశకం"]
};
var quarterValues$a = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["త్రై1", "త్రై2", "త్రై3", "త్రై4"],
  wide: ["1వ త్రైమాసికం", "2వ త్రైమాసికం", "3వ త్రైమాసికం", "4వ త్రైమాసికం"]
};
var monthValues$a = {
  narrow: ["జ", "ఫి", "మా", "ఏ", "మే", "జూ", "జు", "ఆ", "సె", "అ", "న", "డి"],
  abbreviated: ["జన", "ఫిబ్ర", "మార్చి", "ఏప్రి", "మే", "జూన్", "జులై", "ఆగ", "సెప్టెం", "అక్టో", "నవం", "డిసెం"],
  wide: ["జనవరి", "ఫిబ్రవరి", "మార్చి", "ఏప్రిల్", "మే", "జూన్", "జులై", "ఆగస్టు", "సెప్టెంబర్", "అక్టోబర్", "నవంబర్", "డిసెంబర్"]
};
var dayValues$a = {
  narrow: ["ఆ", "సో", "మ", "బు", "గు", "శు", "శ"],
  short: ["ఆది", "సోమ", "మంగళ", "బుధ", "గురు", "శుక్ర", "శని"],
  abbreviated: ["ఆది", "సోమ", "మంగళ", "బుధ", "గురు", "శుక్ర", "శని"],
  wide: ["ఆదివారం", "సోమవారం", "మంగళవారం", "బుధవారం", "గురువారం", "శుక్రవారం", "శనివారం"]
};
var dayPeriodValues$a = {
  narrow: {
    am: "పూర్వాహ్నం",
    pm: "అపరాహ్నం",
    midnight: "అర్ధరాత్రి",
    noon: "మిట్టమధ్యాహ్నం",
    morning: "ఉదయం",
    afternoon: "మధ్యాహ్నం",
    evening: "సాయంత్రం",
    night: "రాత్రి"
  },
  abbreviated: {
    am: "పూర్వాహ్నం",
    pm: "అపరాహ్నం",
    midnight: "అర్ధరాత్రి",
    noon: "మిట్టమధ్యాహ్నం",
    morning: "ఉదయం",
    afternoon: "మధ్యాహ్నం",
    evening: "సాయంత్రం",
    night: "రాత్రి"
  },
  wide: {
    am: "పూర్వాహ్నం",
    pm: "అపరాహ్నం",
    midnight: "అర్ధరాత్రి",
    noon: "మిట్టమధ్యాహ్నం",
    morning: "ఉదయం",
    afternoon: "మధ్యాహ్నం",
    evening: "సాయంత్రం",
    night: "రాత్రి"
  }
};
var formattingDayPeriodValues$a = {
  narrow: {
    am: "పూర్వాహ్నం",
    pm: "అపరాహ్నం",
    midnight: "అర్ధరాత్రి",
    noon: "మిట్టమధ్యాహ్నం",
    morning: "ఉదయం",
    afternoon: "మధ్యాహ్నం",
    evening: "సాయంత్రం",
    night: "రాత్రి"
  },
  abbreviated: {
    am: "పూర్వాహ్నం",
    pm: "అపరాహ్నం",
    midnight: "అర్ధరాత్రి",
    noon: "మిట్టమధ్యాహ్నం",
    morning: "ఉదయం",
    afternoon: "మధ్యాహ్నం",
    evening: "సాయంత్రం",
    night: "రాత్రి"
  },
  wide: {
    am: "పూర్వాహ్నం",
    pm: "అపరాహ్నం",
    midnight: "అర్ధరాత్రి",
    noon: "మిట్టమధ్యాహ్నం",
    morning: "ఉదయం",
    afternoon: "మధ్యాహ్నం",
    evening: "సాయంత్రం",
    night: "రాత్రి"
  }
};
var ordinalNumber$a = function (e, a) {
  var t = Number(e);
  return t + "వ";
};
localize$a.localize = {
  ordinalNumber: ordinalNumber$a,
  era: (0, _index$I.buildLocalizeFn)({
    values: eraValues$a,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$I.buildLocalizeFn)({
    values: quarterValues$a,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$I.buildLocalizeFn)({
    values: monthValues$a,
    defaultWidth: "wide"
  }),
  day: (0, _index$I.buildLocalizeFn)({
    values: dayValues$a,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$I.buildLocalizeFn)({
    values: dayPeriodValues$a,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$a,
    defaultFormattingWidth: "wide"
  })
};
var match$a = {};
match$a.match = void 0;
var _index$H = buildMatchFn;
var _index2$m = buildMatchPatternFn;
var matchOrdinalNumberPattern$a = /^(\d+)(వ)?/i;
var parseOrdinalNumberPattern$a = /\d+/i;
var matchEraPatterns$a = {
  narrow: /^(క్రీ\.పూ\.|క్రీ\.శ\.)/i,
  abbreviated: /^(క్రీ\.?\s?పూ\.?|ప్ర\.?\s?శ\.?\s?పూ\.?|క్రీ\.?\s?శ\.?|సా\.?\s?శ\.?)/i,
  wide: /^(క్రీస్తు పూర్వం|ప్రస్తుత శకానికి పూర్వం|క్రీస్తు శకం|ప్రస్తుత శకం)/i
};
var parseEraPatterns$a = {
  any: [/^(పూ|శ)/i, /^సా/i]
};
var matchQuarterPatterns$a = {
  narrow: /^[1234]/i,
  abbreviated: /^త్రై[1234]/i,
  wide: /^[1234](వ)? త్రైమాసికం/i
};
var parseQuarterPatterns$a = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$a = {
  narrow: /^(జూ|జు|జ|ఫి|మా|ఏ|మే|ఆ|సె|అ|న|డి)/i,
  abbreviated: /^(జన|ఫిబ్ర|మార్చి|ఏప్రి|మే|జూన్|జులై|ఆగ|సెప్|అక్టో|నవ|డిసె)/i,
  wide: /^(జనవరి|ఫిబ్రవరి|మార్చి|ఏప్రిల్|మే|జూన్|జులై|ఆగస్టు|సెప్టెంబర్|అక్టోబర్|నవంబర్|డిసెంబర్)/i
};
var parseMonthPatterns$a = {
  narrow: [/^జ/i, /^ఫి/i, /^మా/i, /^ఏ/i, /^మే/i, /^జూ/i, /^జు/i, /^ఆ/i, /^సె/i, /^అ/i, /^న/i, /^డి/i],
  any: [/^జన/i, /^ఫి/i, /^మా/i, /^ఏ/i, /^మే/i, /^జూన్/i, /^జులై/i, /^ఆగ/i, /^సె/i, /^అ/i, /^న/i, /^డి/i]
};
var matchDayPatterns$a = {
  narrow: /^(ఆ|సో|మ|బు|గు|శు|శ)/i,
  short: /^(ఆది|సోమ|మం|బుధ|గురు|శుక్ర|శని)/i,
  abbreviated: /^(ఆది|సోమ|మం|బుధ|గురు|శుక్ర|శని)/i,
  wide: /^(ఆదివారం|సోమవారం|మంగళవారం|బుధవారం|గురువారం|శుక్రవారం|శనివారం)/i
};
var parseDayPatterns$a = {
  narrow: [/^ఆ/i, /^సో/i, /^మ/i, /^బు/i, /^గు/i, /^శు/i, /^శ/i],
  any: [/^ఆది/i, /^సోమ/i, /^మం/i, /^బుధ/i, /^గురు/i, /^శుక్ర/i, /^శని/i]
};
var matchDayPeriodPatterns$a = {
  narrow: /^(పూర్వాహ్నం|అపరాహ్నం|అర్ధరాత్రి|మిట్టమధ్యాహ్నం|ఉదయం|మధ్యాహ్నం|సాయంత్రం|రాత్రి)/i,
  any: /^(పూర్వాహ్నం|అపరాహ్నం|అర్ధరాత్రి|మిట్టమధ్యాహ్నం|ఉదయం|మధ్యాహ్నం|సాయంత్రం|రాత్రి)/i
};
var parseDayPeriodPatterns$a = {
  any: {
    am: /^పూర్వాహ్నం/i,
    pm: /^అపరాహ్నం/i,
    midnight: /^అర్ధ/i,
    noon: /^మిట్ట/i,
    morning: /ఉదయం/i,
    afternoon: /మధ్యాహ్నం/i,
    evening: /సాయంత్రం/i,
    night: /రాత్రి/i
  }
};
match$a.match = {
  ordinalNumber: (0, _index2$m.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$a,
    parsePattern: parseOrdinalNumberPattern$a,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$H.buildMatchFn)({
    matchPatterns: matchEraPatterns$a,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$a,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$H.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$a,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$a,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$H.buildMatchFn)({
    matchPatterns: matchMonthPatterns$a,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$a,
    defaultParseWidth: "any"
  }),
  day: (0, _index$H.buildMatchFn)({
    matchPatterns: matchDayPatterns$a,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$a,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$H.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$a,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$a,
    defaultParseWidth: "any"
  })
};
te.te = void 0;
var _index$G = formatDistance$l;
var _index2$l = formatLong$a;
var _index3$a = formatRelative$l;
var _index4$a = localize$a;
var _index5$a = match$a;
te.te = {
  code: "te",
  formatDistance: _index$G.formatDistance,
  formatLong: _index2$l.formatLong,
  formatRelative: _index3$a.formatRelative,
  localize: _index4$a.localize,
  match: _index5$a.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var th = {};
var formatDistance$j = {};
formatDistance$j.formatDistance = void 0;
var formatDistanceLocale$9 = {
  lessThanXSeconds: {
    one: "น้อยกว่า 1 วินาที",
    other: "น้อยกว่า {{count}} วินาที"
  },
  xSeconds: {
    one: "1 วินาที",
    other: "{{count}} วินาที"
  },
  halfAMinute: "ครึ่งนาที",
  lessThanXMinutes: {
    one: "น้อยกว่า 1 นาที",
    other: "น้อยกว่า {{count}} นาที"
  },
  xMinutes: {
    one: "1 นาที",
    other: "{{count}} นาที"
  },
  aboutXHours: {
    one: "ประมาณ 1 ชั่วโมง",
    other: "ประมาณ {{count}} ชั่วโมง"
  },
  xHours: {
    one: "1 ชั่วโมง",
    other: "{{count}} ชั่วโมง"
  },
  xDays: {
    one: "1 วัน",
    other: "{{count}} วัน"
  },
  aboutXWeeks: {
    one: "ประมาณ 1 สัปดาห์",
    other: "ประมาณ {{count}} สัปดาห์"
  },
  xWeeks: {
    one: "1 สัปดาห์",
    other: "{{count}} สัปดาห์"
  },
  aboutXMonths: {
    one: "ประมาณ 1 เดือน",
    other: "ประมาณ {{count}} เดือน"
  },
  xMonths: {
    one: "1 เดือน",
    other: "{{count}} เดือน"
  },
  aboutXYears: {
    one: "ประมาณ 1 ปี",
    other: "ประมาณ {{count}} ปี"
  },
  xYears: {
    one: "1 ปี",
    other: "{{count}} ปี"
  },
  overXYears: {
    one: "มากกว่า 1 ปี",
    other: "มากกว่า {{count}} ปี"
  },
  almostXYears: {
    one: "เกือบ 1 ปี",
    other: "เกือบ {{count}} ปี"
  }
};
var formatDistance$i = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$9[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      if (e === "halfAMinute") {
        return "ใน" + r;
      } else {
        return "ใน " + r;
      }
    } else {
      return r + "ที่ผ่านมา";
    }
  }
  return r;
};
formatDistance$j.formatDistance = formatDistance$i;
var formatLong$9 = {};
formatLong$9.formatLong = void 0;
var _index$F = buildFormatLongFn;
var dateFormats$9 = {
  full: "วันEEEEที่ do MMMM y",
  long: "do MMMM y",
  medium: "d MMM y",
  short: "dd/MM/yyyy"
};
var timeFormats$9 = {
  full: "H:mm:ss น. zzzz",
  long: "H:mm:ss น. z",
  medium: "H:mm:ss น.",
  short: "H:mm น."
};
var dateTimeFormats$9 = {
  full: "{{date}} 'เวลา' {{time}}",
  long: "{{date}} 'เวลา' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$9.formatLong = {
  date: (0, _index$F.buildFormatLongFn)({
    formats: dateFormats$9,
    defaultWidth: "full"
  }),
  time: (0, _index$F.buildFormatLongFn)({
    formats: timeFormats$9,
    defaultWidth: "medium"
  }),
  dateTime: (0, _index$F.buildFormatLongFn)({
    formats: dateTimeFormats$9,
    defaultWidth: "full"
  })
};
var formatRelative$j = {};
formatRelative$j.formatRelative = void 0;
var formatRelativeLocale$9 = {
  lastWeek: "eeee'ที่แล้วเวลา' p",
  yesterday: "'เมื่อวานนี้เวลา' p",
  today: "'วันนี้เวลา' p",
  tomorrow: "'พรุ่งนี้เวลา' p",
  nextWeek: "eeee 'เวลา' p",
  other: "P"
};
var formatRelative$i = function (e, a, t, r) {
  return formatRelativeLocale$9[e];
};
formatRelative$j.formatRelative = formatRelative$i;
var localize$9 = {};
localize$9.localize = void 0;
var _index$E = buildLocalizeFn;
var eraValues$9 = {
  narrow: ["B", "คศ"],
  abbreviated: ["BC", "ค.ศ."],
  wide: ["ปีก่อนคริสตกาล", "คริสต์ศักราช"]
};
var quarterValues$9 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["ไตรมาสแรก", "ไตรมาสที่สอง", "ไตรมาสที่สาม", "ไตรมาสที่สี่"]
};
var dayValues$9 = {
  narrow: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
  short: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
  abbreviated: ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
  wide: ["อาทิตย์", "จันทร์", "อังคาร", "พุธ", "พฤหัสบดี", "ศุกร์", "เสาร์"]
};
var monthValues$9 = {
  narrow: ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
  abbreviated: ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
  wide: ["มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"]
};
var dayPeriodValues$9 = {
  narrow: {
    am: "ก่อนเที่ยง",
    pm: "หลังเที่ยง",
    midnight: "เที่ยงคืน",
    noon: "เที่ยง",
    morning: "เช้า",
    afternoon: "บ่าย",
    evening: "เย็น",
    night: "กลางคืน"
  },
  abbreviated: {
    am: "ก่อนเที่ยง",
    pm: "หลังเที่ยง",
    midnight: "เที่ยงคืน",
    noon: "เที่ยง",
    morning: "เช้า",
    afternoon: "บ่าย",
    evening: "เย็น",
    night: "กลางคืน"
  },
  wide: {
    am: "ก่อนเที่ยง",
    pm: "หลังเที่ยง",
    midnight: "เที่ยงคืน",
    noon: "เที่ยง",
    morning: "เช้า",
    afternoon: "บ่าย",
    evening: "เย็น",
    night: "กลางคืน"
  }
};
var formattingDayPeriodValues$9 = {
  narrow: {
    am: "ก่อนเที่ยง",
    pm: "หลังเที่ยง",
    midnight: "เที่ยงคืน",
    noon: "เที่ยง",
    morning: "ตอนเช้า",
    afternoon: "ตอนกลางวัน",
    evening: "ตอนเย็น",
    night: "ตอนกลางคืน"
  },
  abbreviated: {
    am: "ก่อนเที่ยง",
    pm: "หลังเที่ยง",
    midnight: "เที่ยงคืน",
    noon: "เที่ยง",
    morning: "ตอนเช้า",
    afternoon: "ตอนกลางวัน",
    evening: "ตอนเย็น",
    night: "ตอนกลางคืน"
  },
  wide: {
    am: "ก่อนเที่ยง",
    pm: "หลังเที่ยง",
    midnight: "เที่ยงคืน",
    noon: "เที่ยง",
    morning: "ตอนเช้า",
    afternoon: "ตอนกลางวัน",
    evening: "ตอนเย็น",
    night: "ตอนกลางคืน"
  }
};
var ordinalNumber$9 = function (e, a) {
  return String(e);
};
localize$9.localize = {
  ordinalNumber: ordinalNumber$9,
  era: (0, _index$E.buildLocalizeFn)({
    values: eraValues$9,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$E.buildLocalizeFn)({
    values: quarterValues$9,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$E.buildLocalizeFn)({
    values: monthValues$9,
    defaultWidth: "wide"
  }),
  day: (0, _index$E.buildLocalizeFn)({
    values: dayValues$9,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$E.buildLocalizeFn)({
    values: dayPeriodValues$9,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$9,
    defaultFormattingWidth: "wide"
  })
};
var match$9 = {};
match$9.match = void 0;
var _index$D = buildMatchFn;
var _index2$k = buildMatchPatternFn;
var matchOrdinalNumberPattern$9 = /^\d+/i;
var parseOrdinalNumberPattern$9 = /\d+/i;
var matchEraPatterns$9 = {
  narrow: /^([bB]|[aA]|คศ)/i,
  abbreviated: /^([bB]\.?\s?[cC]\.?|b\.?\s?c\.?\s?e\.?|a\.?\s?d\.?|c\.?\s?e\.?|ค\.?ศ\.?)/i,
  wide: /^(ก่อนคริสตกาล|คริสต์ศักราช|คริสตกาล)/i
};
var parseEraPatterns$9 = {
  any: [/^[bB]/i, /^(^[aA]|ค\.?ศ\.?|คริสตกาล|คริสต์ศักราช|)/i]
};
var matchQuarterPatterns$9 = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^ไตรมาส(ที่)? ?[1234]/i
};
var parseQuarterPatterns$9 = {
  any: [/(1|แรก|หนึ่ง)/i, /(2|สอง)/i, /(3|สาม)/i, /(4|สี่)/i]
};
var matchMonthPatterns$9 = {
  narrow: /^(ม\.?ค\.?|ก\.?พ\.?|มี\.?ค\.?|เม\.?ย\.?|พ\.?ค\.?|มิ\.?ย\.?|ก\.?ค\.?|ส\.?ค\.?|ก\.?ย\.?|ต\.?ค\.?|พ\.?ย\.?|ธ\.?ค\.?)/i,
  abbreviated: /^(ม\.?ค\.?|ก\.?พ\.?|มี\.?ค\.?|เม\.?ย\.?|พ\.?ค\.?|มิ\.?ย\.?|ก\.?ค\.?|ส\.?ค\.?|ก\.?ย\.?|ต\.?ค\.?|พ\.?ย\.?|ธ\.?ค\.?')/i,
  wide: /^(มกราคม|กุมภาพันธ์|มีนาคม|เมษายน|พฤษภาคม|มิถุนายน|กรกฎาคม|สิงหาคม|กันยายน|ตุลาคม|พฤศจิกายน|ธันวาคม)/i
};
var parseMonthPatterns$9 = {
  wide: [/^มก/i, /^กุม/i, /^มี/i, /^เม/i, /^พฤษ/i, /^มิ/i, /^กรก/i, /^ส/i, /^กัน/i, /^ต/i, /^พฤศ/i, /^ธ/i],
  any: [/^ม\.?ค\.?/i, /^ก\.?พ\.?/i, /^มี\.?ค\.?/i, /^เม\.?ย\.?/i, /^พ\.?ค\.?/i, /^มิ\.?ย\.?/i, /^ก\.?ค\.?/i, /^ส\.?ค\.?/i, /^ก\.?ย\.?/i, /^ต\.?ค\.?/i, /^พ\.?ย\.?/i, /^ธ\.?ค\.?/i]
};
var matchDayPatterns$9 = {
  narrow: /^(อา\.?|จ\.?|อ\.?|พฤ\.?|พ\.?|ศ\.?|ส\.?)/i,
  short: /^(อา\.?|จ\.?|อ\.?|พฤ\.?|พ\.?|ศ\.?|ส\.?)/i,
  abbreviated: /^(อา\.?|จ\.?|อ\.?|พฤ\.?|พ\.?|ศ\.?|ส\.?)/i,
  wide: /^(อาทิตย์|จันทร์|อังคาร|พุธ|พฤหัสบดี|ศุกร์|เสาร์)/i
};
var parseDayPatterns$9 = {
  wide: [/^อา/i, /^จั/i, /^อั/i, /^พุธ/i, /^พฤ/i, /^ศ/i, /^เส/i],
  any: [/^อา/i, /^จ/i, /^อ/i, /^พ(?!ฤ)/i, /^พฤ/i, /^ศ/i, /^ส/i]
};
var matchDayPeriodPatterns$9 = {
  any: /^(ก่อนเที่ยง|หลังเที่ยง|เที่ยงคืน|เที่ยง|(ตอน.*?)?.*(เที่ยง|เช้า|บ่าย|เย็น|กลางคืน))/i
};
var parseDayPeriodPatterns$9 = {
  any: {
    am: /^ก่อนเที่ยง/i,
    pm: /^หลังเที่ยง/i,
    midnight: /^เที่ยงคืน/i,
    noon: /^เที่ยง/i,
    morning: /เช้า/i,
    afternoon: /บ่าย/i,
    evening: /เย็น/i,
    night: /กลางคืน/i
  }
};
match$9.match = {
  ordinalNumber: (0, _index2$k.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$9,
    parsePattern: parseOrdinalNumberPattern$9,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$D.buildMatchFn)({
    matchPatterns: matchEraPatterns$9,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$9,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$D.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$9,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$9,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$D.buildMatchFn)({
    matchPatterns: matchMonthPatterns$9,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$9,
    defaultParseWidth: "any"
  }),
  day: (0, _index$D.buildMatchFn)({
    matchPatterns: matchDayPatterns$9,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$9,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$D.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$9,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$9,
    defaultParseWidth: "any"
  })
};
th.th = void 0;
var _index$C = formatDistance$j;
var _index2$j = formatLong$9;
var _index3$9 = formatRelative$j;
var _index4$9 = localize$9;
var _index5$9 = match$9;
th.th = {
  code: "th",
  formatDistance: _index$C.formatDistance,
  formatLong: _index2$j.formatLong,
  formatRelative: _index3$9.formatRelative,
  localize: _index4$9.localize,
  match: _index5$9.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var tr = {};
var formatDistance$h = {};
formatDistance$h.formatDistance = void 0;
var formatDistanceLocale$8 = {
  lessThanXSeconds: {
    one: "bir saniyeden az",
    other: "{{count}} saniyeden az"
  },
  xSeconds: {
    one: "1 saniye",
    other: "{{count}} saniye"
  },
  halfAMinute: "yarım dakika",
  lessThanXMinutes: {
    one: "bir dakikadan az",
    other: "{{count}} dakikadan az"
  },
  xMinutes: {
    one: "1 dakika",
    other: "{{count}} dakika"
  },
  aboutXHours: {
    one: "yaklaşık 1 saat",
    other: "yaklaşık {{count}} saat"
  },
  xHours: {
    one: "1 saat",
    other: "{{count}} saat"
  },
  xDays: {
    one: "1 gün",
    other: "{{count}} gün"
  },
  aboutXWeeks: {
    one: "yaklaşık 1 hafta",
    other: "yaklaşık {{count}} hafta"
  },
  xWeeks: {
    one: "1 hafta",
    other: "{{count}} hafta"
  },
  aboutXMonths: {
    one: "yaklaşık 1 ay",
    other: "yaklaşık {{count}} ay"
  },
  xMonths: {
    one: "1 ay",
    other: "{{count}} ay"
  },
  aboutXYears: {
    one: "yaklaşık 1 yıl",
    other: "yaklaşık {{count}} yıl"
  },
  xYears: {
    one: "1 yıl",
    other: "{{count}} yıl"
  },
  overXYears: {
    one: "1 yıldan fazla",
    other: "{{count}} yıldan fazla"
  },
  almostXYears: {
    one: "neredeyse 1 yıl",
    other: "neredeyse {{count}} yıl"
  }
};
var formatDistance$g = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$8[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", a.toString());
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + " sonra";
    } else {
      return r + " önce";
    }
  }
  return r;
};
formatDistance$h.formatDistance = formatDistance$g;
var formatLong$8 = {};
formatLong$8.formatLong = void 0;
var _index$B = buildFormatLongFn;
var dateFormats$8 = {
  full: "d MMMM y EEEE",
  long: "d MMMM y",
  medium: "d MMM y",
  short: "dd.MM.yyyy"
};
var timeFormats$8 = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$8 = {
  full: "{{date}} 'saat' {{time}}",
  long: "{{date}} 'saat' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$8.formatLong = {
  date: (0, _index$B.buildFormatLongFn)({
    formats: dateFormats$8,
    defaultWidth: "full"
  }),
  time: (0, _index$B.buildFormatLongFn)({
    formats: timeFormats$8,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$B.buildFormatLongFn)({
    formats: dateTimeFormats$8,
    defaultWidth: "full"
  })
};
var formatRelative$h = {};
formatRelative$h.formatRelative = void 0;
var formatRelativeLocale$8 = {
  lastWeek: "'geçen hafta' eeee 'saat' p",
  yesterday: "'dün saat' p",
  today: "'bugün saat' p",
  tomorrow: "'yarın saat' p",
  nextWeek: "eeee 'saat' p",
  other: "P"
};
var formatRelative$g = function (e, a, t, r) {
  return formatRelativeLocale$8[e];
};
formatRelative$h.formatRelative = formatRelative$g;
var localize$8 = {};
localize$8.localize = void 0;
var _index$A = buildLocalizeFn;
var eraValues$8 = {
  narrow: ["MÖ", "MS"],
  abbreviated: ["MÖ", "MS"],
  wide: ["Milattan Önce", "Milattan Sonra"]
};
var quarterValues$8 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1Ç", "2Ç", "3Ç", "4Ç"],
  wide: ["İlk çeyrek", "İkinci Çeyrek", "Üçüncü çeyrek", "Son çeyrek"]
};
var monthValues$8 = {
  narrow: ["O", "Ş", "M", "N", "M", "H", "T", "A", "E", "E", "K", "A"],
  abbreviated: ["Oca", "Şub", "Mar", "Nis", "May", "Haz", "Tem", "Ağu", "Eyl", "Eki", "Kas", "Ara"],
  wide: ["Ocak", "Şubat", "Mart", "Nisan", "Mayıs", "Haziran", "Temmuz", "Ağustos", "Eylül", "Ekim", "Kasım", "Aralık"]
};
var dayValues$8 = {
  narrow: ["P", "P", "S", "Ç", "P", "C", "C"],
  short: ["Pz", "Pt", "Sa", "Ça", "Pe", "Cu", "Ct"],
  abbreviated: ["Paz", "Pzt", "Sal", "Çar", "Per", "Cum", "Cts"],
  wide: ["Pazar", "Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi"]
};
var dayPeriodValues$8 = {
  narrow: {
    am: "öö",
    pm: "ös",
    midnight: "gy",
    noon: "ö",
    morning: "sa",
    afternoon: "ös",
    evening: "ak",
    night: "ge"
  },
  abbreviated: {
    am: "ÖÖ",
    pm: "ÖS",
    midnight: "gece yarısı",
    noon: "öğle",
    morning: "sabah",
    afternoon: "öğleden sonra",
    evening: "akşam",
    night: "gece"
  },
  wide: {
    am: "Ö.Ö.",
    pm: "Ö.S.",
    midnight: "gece yarısı",
    noon: "öğle",
    morning: "sabah",
    afternoon: "öğleden sonra",
    evening: "akşam",
    night: "gece"
  }
};
var formattingDayPeriodValues$8 = {
  narrow: {
    am: "öö",
    pm: "ös",
    midnight: "gy",
    noon: "ö",
    morning: "sa",
    afternoon: "ös",
    evening: "ak",
    night: "ge"
  },
  abbreviated: {
    am: "ÖÖ",
    pm: "ÖS",
    midnight: "gece yarısı",
    noon: "öğlen",
    morning: "sabahleyin",
    afternoon: "öğleden sonra",
    evening: "akşamleyin",
    night: "geceleyin"
  },
  wide: {
    am: "ö.ö.",
    pm: "ö.s.",
    midnight: "gece yarısı",
    noon: "öğlen",
    morning: "sabahleyin",
    afternoon: "öğleden sonra",
    evening: "akşamleyin",
    night: "geceleyin"
  }
};
var ordinalNumber$8 = function (e, a) {
  var t = Number(e);
  return t + ".";
};
localize$8.localize = {
  ordinalNumber: ordinalNumber$8,
  era: (0, _index$A.buildLocalizeFn)({
    values: eraValues$8,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$A.buildLocalizeFn)({
    values: quarterValues$8,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return Number(e) - 1;
    }
  }),
  month: (0, _index$A.buildLocalizeFn)({
    values: monthValues$8,
    defaultWidth: "wide"
  }),
  day: (0, _index$A.buildLocalizeFn)({
    values: dayValues$8,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$A.buildLocalizeFn)({
    values: dayPeriodValues$8,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$8,
    defaultFormattingWidth: "wide"
  })
};
var match$8 = {};
match$8.match = void 0;
var _index$z = buildMatchFn;
var _index2$i = buildMatchPatternFn;
var matchOrdinalNumberPattern$8 = /^(\d+)(\.)?/i;
var parseOrdinalNumberPattern$8 = /\d+/i;
var matchEraPatterns$8 = {
  narrow: /^(mö|ms)/i,
  abbreviated: /^(mö|ms)/i,
  wide: /^(milattan önce|milattan sonra)/i
};
var parseEraPatterns$8 = {
  any: [/(^mö|^milattan önce)/i, /(^ms|^milattan sonra)/i]
};
var matchQuarterPatterns$8 = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234]ç/i,
  wide: /^((i|İ)lk|(i|İ)kinci|üçüncü|son) çeyrek/i
};
var parseQuarterPatterns$8 = {
  any: [/1/i, /2/i, /3/i, /4/i],
  abbreviated: [/1ç/i, /2ç/i, /3ç/i, /4ç/i],
  wide: [/^(i|İ)lk çeyrek/i, /(i|İ)kinci çeyrek/i, /üçüncü çeyrek/i, /son çeyrek/i]
};
var matchMonthPatterns$8 = {
  narrow: /^[oşmnhtaek]/i,
  abbreviated: /^(oca|şub|mar|nis|may|haz|tem|ağu|eyl|eki|kas|ara)/i,
  wide: /^(ocak|şubat|mart|nisan|mayıs|haziran|temmuz|ağustos|eylül|ekim|kasım|aralık)/i
};
var parseMonthPatterns$8 = {
  narrow: [/^o/i, /^ş/i, /^m/i, /^n/i, /^m/i, /^h/i, /^t/i, /^a/i, /^e/i, /^e/i, /^k/i, /^a/i],
  any: [/^o/i, /^ş/i, /^mar/i, /^n/i, /^may/i, /^h/i, /^t/i, /^ağ/i, /^ey/i, /^ek/i, /^k/i, /^ar/i]
};
var matchDayPatterns$8 = {
  narrow: /^[psçc]/i,
  short: /^(pz|pt|sa|ça|pe|cu|ct)/i,
  abbreviated: /^(paz|pzt|sal|çar|per|cum|cts)/i,
  wide: /^(pazar(?!tesi)|pazartesi|salı|çarşamba|perşembe|cuma(?!rtesi)|cumartesi)/i
};
var parseDayPatterns$8 = {
  narrow: [/^p/i, /^p/i, /^s/i, /^ç/i, /^p/i, /^c/i, /^c/i],
  any: [/^pz/i, /^pt/i, /^sa/i, /^ça/i, /^pe/i, /^cu/i, /^ct/i],
  wide: [/^pazar(?!tesi)/i, /^pazartesi/i, /^salı/i, /^çarşamba/i, /^perşembe/i, /^cuma(?!rtesi)/i, /^cumartesi/i]
};
var matchDayPeriodPatterns$8 = {
  narrow: /^(öö|ös|gy|ö|sa|ös|ak|ge)/i,
  any: /^(ö\.?\s?[ös]\.?|öğleden sonra|gece yarısı|öğle|(sabah|öğ|akşam|gece)(leyin))/i
};
var parseDayPeriodPatterns$8 = {
  any: {
    am: /^ö\.?ö\.?/i,
    pm: /^ö\.?s\.?/i,
    midnight: /^(gy|gece yarısı)/i,
    noon: /^öğ/i,
    morning: /^sa/i,
    afternoon: /^öğleden sonra/i,
    evening: /^ak/i,
    night: /^ge/i
  }
};
match$8.match = {
  ordinalNumber: (0, _index2$i.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$8,
    parsePattern: parseOrdinalNumberPattern$8,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$z.buildMatchFn)({
    matchPatterns: matchEraPatterns$8,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$8,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$z.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$8,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$8,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$z.buildMatchFn)({
    matchPatterns: matchMonthPatterns$8,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$8,
    defaultParseWidth: "any"
  }),
  day: (0, _index$z.buildMatchFn)({
    matchPatterns: matchDayPatterns$8,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$8,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$z.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$8,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$8,
    defaultParseWidth: "any"
  })
};
tr.tr = void 0;
var _index$y = formatDistance$h;
var _index2$h = formatLong$8;
var _index3$8 = formatRelative$h;
var _index4$8 = localize$8;
var _index5$8 = match$8;
tr.tr = {
  code: "tr",
  formatDistance: _index$y.formatDistance,
  formatLong: _index2$h.formatLong,
  formatRelative: _index3$8.formatRelative,
  localize: _index4$8.localize,
  match: _index5$8.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var ug = {};
var formatDistance$f = {};
formatDistance$f.formatDistance = void 0;
var formatDistanceLocale$7 = {
  lessThanXSeconds: {
    one: "بىر سىكۇنت ئىچىدە",
    other: "سىكۇنت ئىچىدە {{count}}"
  },
  xSeconds: {
    one: "بىر سىكۇنت",
    other: "سىكۇنت {{count}}"
  },
  halfAMinute: "يىرىم مىنۇت",
  lessThanXMinutes: {
    one: "بىر مىنۇت ئىچىدە",
    other: "مىنۇت ئىچىدە {{count}}"
  },
  xMinutes: {
    one: "بىر مىنۇت",
    other: "مىنۇت {{count}}"
  },
  aboutXHours: {
    one: "تەخمىنەن بىر سائەت",
    other: "سائەت {{count}} تەخمىنەن"
  },
  xHours: {
    one: "بىر سائەت",
    other: "سائەت {{count}}"
  },
  xDays: {
    one: "بىر كۈن",
    other: "كۈن {{count}}"
  },
  aboutXWeeks: {
    one: "تەخمىنەن بىرھەپتە",
    other: "ھەپتە {{count}} تەخمىنەن"
  },
  xWeeks: {
    one: "بىرھەپتە",
    other: "ھەپتە {{count}}"
  },
  aboutXMonths: {
    one: "تەخمىنەن بىر ئاي",
    other: "ئاي {{count}} تەخمىنەن"
  },
  xMonths: {
    one: "بىر ئاي",
    other: "ئاي {{count}}"
  },
  aboutXYears: {
    one: "تەخمىنەن بىر يىل",
    other: "يىل {{count}} تەخمىنەن"
  },
  xYears: {
    one: "بىر يىل",
    other: "يىل {{count}}"
  },
  overXYears: {
    one: "بىر يىلدىن ئارتۇق",
    other: "يىلدىن ئارتۇق {{count}}"
  },
  almostXYears: {
    one: "ئاساسەن بىر يىل",
    other: "يىل {{count}} ئاساسەن"
  }
};
var formatDistance$e = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$7[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r;
    } else {
      return r + " بولدى";
    }
  }
  return r;
};
formatDistance$f.formatDistance = formatDistance$e;
var formatLong$7 = {};
formatLong$7.formatLong = void 0;
var _index$x = buildFormatLongFn;
var dateFormats$7 = {
  full: "EEEE, MMMM do, y",
  long: "MMMM do, y",
  medium: "MMM d, y",
  short: "MM/dd/yyyy"
};
var timeFormats$7 = {
  full: "h:mm:ss a zzzz",
  long: "h:mm:ss a z",
  medium: "h:mm:ss a",
  short: "h:mm a"
};
var dateTimeFormats$7 = {
  full: "{{date}} 'دە' {{time}}",
  long: "{{date}} 'دە' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$7.formatLong = {
  date: (0, _index$x.buildFormatLongFn)({
    formats: dateFormats$7,
    defaultWidth: "full"
  }),
  time: (0, _index$x.buildFormatLongFn)({
    formats: timeFormats$7,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$x.buildFormatLongFn)({
    formats: dateTimeFormats$7,
    defaultWidth: "full"
  })
};
var formatRelative$f = {};
formatRelative$f.formatRelative = void 0;
var formatRelativeLocale$7 = {
  lastWeek: "'ئ‍ۆتكەن' eeee 'دە' p",
  yesterday: "'تۈنۈگۈن دە' p",
  today: "'بۈگۈن دە' p",
  tomorrow: "'ئەتە دە' p",
  nextWeek: "eeee 'دە' p",
  other: "P"
};
var formatRelative$e = function (e, a, t, r) {
  return formatRelativeLocale$7[e];
};
formatRelative$f.formatRelative = formatRelative$e;
var localize$7 = {};
localize$7.localize = void 0;
var _index$w = buildLocalizeFn;
var eraValues$7 = {
  narrow: ["ب", "ك"],
  abbreviated: ["ب", "ك"],
  wide: ["مىيلادىدىن بۇرۇن", "مىيلادىدىن كىيىن"]
};
var quarterValues$7 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1", "2", "3", "4"],
  wide: ["بىرىنجى چارەك", "ئىككىنجى چارەك", "ئۈچىنجى چارەك", "تۆتىنجى چارەك"]
};
var monthValues$7 = {
  narrow: ["ي", "ف", "م", "ا", "م", "ى", "ى", "ا", "س", "ۆ", "ن", "د"],
  abbreviated: ["يانۋار", "فېۋىرال", "مارت", "ئاپرىل", "ماي", "ئىيۇن", "ئىيول", "ئاۋغۇست", "سىنتەبىر", "ئۆكتەبىر", "نويابىر", "دىكابىر"],
  wide: ["يانۋار", "فېۋىرال", "مارت", "ئاپرىل", "ماي", "ئىيۇن", "ئىيول", "ئاۋغۇست", "سىنتەبىر", "ئۆكتەبىر", "نويابىر", "دىكابىر"]
};
var dayValues$7 = {
  narrow: ["ي", "د", "س", "چ", "پ", "ج", "ش"],
  short: ["ي", "د", "س", "چ", "پ", "ج", "ش"],
  abbreviated: ["يەكشەنبە", "دۈشەنبە", "سەيشەنبە", "چارشەنبە", "پەيشەنبە", "جۈمە", "شەنبە"],
  wide: ["يەكشەنبە", "دۈشەنبە", "سەيشەنبە", "چارشەنبە", "پەيشەنبە", "جۈمە", "شەنبە"]
};
var dayPeriodValues$7 = {
  narrow: {
    am: "ئە",
    pm: "چ",
    midnight: "ك",
    noon: "چ",
    morning: "ئەتىگەن",
    afternoon: "چۈشتىن كىيىن",
    evening: "ئاخشىم",
    night: "كىچە"
  },
  abbreviated: {
    am: "ئە",
    pm: "چ",
    midnight: "ك",
    noon: "چ",
    morning: "ئەتىگەن",
    afternoon: "چۈشتىن كىيىن",
    evening: "ئاخشىم",
    night: "كىچە"
  },
  wide: {
    am: "ئە",
    pm: "چ",
    midnight: "ك",
    noon: "چ",
    morning: "ئەتىگەن",
    afternoon: "چۈشتىن كىيىن",
    evening: "ئاخشىم",
    night: "كىچە"
  }
};
var formattingDayPeriodValues$7 = {
  narrow: {
    am: "ئە",
    pm: "چ",
    midnight: "ك",
    noon: "چ",
    morning: "ئەتىگەندە",
    afternoon: "چۈشتىن كىيىن",
    evening: "ئاخشامدا",
    night: "كىچىدە"
  },
  abbreviated: {
    am: "ئە",
    pm: "چ",
    midnight: "ك",
    noon: "چ",
    morning: "ئەتىگەندە",
    afternoon: "چۈشتىن كىيىن",
    evening: "ئاخشامدا",
    night: "كىچىدە"
  },
  wide: {
    am: "ئە",
    pm: "چ",
    midnight: "ك",
    noon: "چ",
    morning: "ئەتىگەندە",
    afternoon: "چۈشتىن كىيىن",
    evening: "ئاخشامدا",
    night: "كىچىدە"
  }
};
var ordinalNumber$7 = function (e, a) {
  return String(e);
};
localize$7.localize = {
  ordinalNumber: ordinalNumber$7,
  era: (0, _index$w.buildLocalizeFn)({
    values: eraValues$7,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$w.buildLocalizeFn)({
    values: quarterValues$7,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$w.buildLocalizeFn)({
    values: monthValues$7,
    defaultWidth: "wide"
  }),
  day: (0, _index$w.buildLocalizeFn)({
    values: dayValues$7,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$w.buildLocalizeFn)({
    values: dayPeriodValues$7,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$7,
    defaultFormattingWidth: "wide"
  })
};
var match$7 = {};
match$7.match = void 0;
var _index$v = buildMatchFn;
var _index2$g = buildMatchPatternFn;
var matchOrdinalNumberPattern$7 = /^(\d+)(th|st|nd|rd)?/i;
var parseOrdinalNumberPattern$7 = /\d+/i;
var matchEraPatterns$7 = {
  narrow: /^(ب|ك)/i,
  wide: /^(مىيلادىدىن بۇرۇن|مىيلادىدىن كىيىن)/i
};
var parseEraPatterns$7 = {
  any: [/^بۇرۇن/i, /^كىيىن/i]
};
var matchQuarterPatterns$7 = {
  narrow: /^[1234]/i,
  abbreviated: /^چ[1234]/i,
  wide: /^چارەك [1234]/i
};
var parseQuarterPatterns$7 = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$7 = {
  narrow: /^[يفمئامئ‍ئاسۆند]/i,
  abbreviated: /^(يانۋار|فېۋىرال|مارت|ئاپرىل|ماي|ئىيۇن|ئىيول|ئاۋغۇست|سىنتەبىر|ئۆكتەبىر|نويابىر|دىكابىر)/i,
  wide: /^(يانۋار|فېۋىرال|مارت|ئاپرىل|ماي|ئىيۇن|ئىيول|ئاۋغۇست|سىنتەبىر|ئۆكتەبىر|نويابىر|دىكابىر)/i
};
var parseMonthPatterns$7 = {
  narrow: [/^ي/i, /^ف/i, /^م/i, /^ا/i, /^م/i, /^ى‍/i, /^ى‍/i, /^ا‍/i, /^س/i, /^ۆ/i, /^ن/i, /^د/i],
  any: [/^يان/i, /^فېۋ/i, /^مار/i, /^ئاپ/i, /^ماي/i, /^ئىيۇن/i, /^ئىيول/i, /^ئاۋ/i, /^سىن/i, /^ئۆك/i, /^نوي/i, /^دىك/i]
};
var matchDayPatterns$7 = {
  narrow: /^[دسچپجشي]/i,
  short: /^(يە|دۈ|سە|چا|پە|جۈ|شە)/i,
  abbreviated: /^(يە|دۈ|سە|چا|پە|جۈ|شە)/i,
  wide: /^(يەكشەنبە|دۈشەنبە|سەيشەنبە|چارشەنبە|پەيشەنبە|جۈمە|شەنبە)/i
};
var parseDayPatterns$7 = {
  narrow: [/^ي/i, /^د/i, /^س/i, /^چ/i, /^پ/i, /^ج/i, /^ش/i],
  any: [/^ي/i, /^د/i, /^س/i, /^چ/i, /^پ/i, /^ج/i, /^ش/i]
};
var matchDayPeriodPatterns$7 = {
  narrow: /^(ئە|چ|ك|چ|(دە|ئەتىگەن) ( ئە‍|چۈشتىن كىيىن|ئاخشىم|كىچە))/i,
  any: /^(ئە|چ|ك|چ|(دە|ئەتىگەن) ( ئە‍|چۈشتىن كىيىن|ئاخشىم|كىچە))/i
};
var parseDayPeriodPatterns$7 = {
  any: {
    am: /^ئە/i,
    pm: /^چ/i,
    midnight: /^ك/i,
    noon: /^چ/i,
    morning: /ئەتىگەن/i,
    afternoon: /چۈشتىن كىيىن/i,
    evening: /ئاخشىم/i,
    night: /كىچە/i
  }
};
match$7.match = {
  ordinalNumber: (0, _index2$g.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$7,
    parsePattern: parseOrdinalNumberPattern$7,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$v.buildMatchFn)({
    matchPatterns: matchEraPatterns$7,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$7,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$v.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$7,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$7,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$v.buildMatchFn)({
    matchPatterns: matchMonthPatterns$7,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$7,
    defaultParseWidth: "any"
  }),
  day: (0, _index$v.buildMatchFn)({
    matchPatterns: matchDayPatterns$7,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$7,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$v.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$7,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$7,
    defaultParseWidth: "any"
  })
};
ug.ug = void 0;
var _index$u = formatDistance$f;
var _index2$f = formatLong$7;
var _index3$7 = formatRelative$f;
var _index4$7 = localize$7;
var _index5$7 = match$7;
ug.ug = {
  code: "ug",
  formatDistance: _index$u.formatDistance,
  formatLong: _index2$f.formatLong,
  formatRelative: _index3$7.formatRelative,
  localize: _index4$7.localize,
  match: _index5$7.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var uk = {};
var formatDistance$d = {};
formatDistance$d.formatDistance = void 0;
function declension(e, a) {
  if (e.one !== undefined && a === 1) {
    return e.one;
  }
  var t = a % 10;
  var r = a % 100;
  if (t === 1 && r !== 11) {
    return e.singularNominative.replace("{{count}}", String(a));
  } else if (t >= 2 && t <= 4 && (r < 10 || r > 20)) {
    return e.singularGenitive.replace("{{count}}", String(a));
  } else {
    return e.pluralGenitive.replace("{{count}}", String(a));
  }
}
function buildLocalizeTokenFn(e) {
  return function (a, t) {
    if (t && t.addSuffix) {
      if (t.comparison && t.comparison > 0) {
        if (e.future) {
          return declension(e.future, a);
        } else {
          return "за " + declension(e.regular, a);
        }
      } else {
        if (e.past) {
          return declension(e.past, a);
        } else {
          return declension(e.regular, a) + " тому";
        }
      }
    } else {
      return declension(e.regular, a);
    }
  };
}
var halfAtMinute = function (e, a) {
  if (a && a.addSuffix) {
    if (a.comparison && a.comparison > 0) {
      return "за півхвилини";
    } else {
      return "півхвилини тому";
    }
  }
  return "півхвилини";
};
var formatDistanceLocale$6 = {
  lessThanXSeconds: buildLocalizeTokenFn({
    regular: {
      one: "менше секунди",
      singularNominative: "менше {{count}} секунди",
      singularGenitive: "менше {{count}} секунд",
      pluralGenitive: "менше {{count}} секунд"
    },
    future: {
      one: "менше, ніж за секунду",
      singularNominative: "менше, ніж за {{count}} секунду",
      singularGenitive: "менше, ніж за {{count}} секунди",
      pluralGenitive: "менше, ніж за {{count}} секунд"
    }
  }),
  xSeconds: buildLocalizeTokenFn({
    regular: {
      singularNominative: "{{count}} секунда",
      singularGenitive: "{{count}} секунди",
      pluralGenitive: "{{count}} секунд"
    },
    past: {
      singularNominative: "{{count}} секунду тому",
      singularGenitive: "{{count}} секунди тому",
      pluralGenitive: "{{count}} секунд тому"
    },
    future: {
      singularNominative: "за {{count}} секунду",
      singularGenitive: "за {{count}} секунди",
      pluralGenitive: "за {{count}} секунд"
    }
  }),
  halfAMinute: halfAtMinute,
  lessThanXMinutes: buildLocalizeTokenFn({
    regular: {
      one: "менше хвилини",
      singularNominative: "менше {{count}} хвилини",
      singularGenitive: "менше {{count}} хвилин",
      pluralGenitive: "менше {{count}} хвилин"
    },
    future: {
      one: "менше, ніж за хвилину",
      singularNominative: "менше, ніж за {{count}} хвилину",
      singularGenitive: "менше, ніж за {{count}} хвилини",
      pluralGenitive: "менше, ніж за {{count}} хвилин"
    }
  }),
  xMinutes: buildLocalizeTokenFn({
    regular: {
      singularNominative: "{{count}} хвилина",
      singularGenitive: "{{count}} хвилини",
      pluralGenitive: "{{count}} хвилин"
    },
    past: {
      singularNominative: "{{count}} хвилину тому",
      singularGenitive: "{{count}} хвилини тому",
      pluralGenitive: "{{count}} хвилин тому"
    },
    future: {
      singularNominative: "за {{count}} хвилину",
      singularGenitive: "за {{count}} хвилини",
      pluralGenitive: "за {{count}} хвилин"
    }
  }),
  aboutXHours: buildLocalizeTokenFn({
    regular: {
      singularNominative: "близько {{count}} години",
      singularGenitive: "близько {{count}} годин",
      pluralGenitive: "близько {{count}} годин"
    },
    future: {
      singularNominative: "приблизно за {{count}} годину",
      singularGenitive: "приблизно за {{count}} години",
      pluralGenitive: "приблизно за {{count}} годин"
    }
  }),
  xHours: buildLocalizeTokenFn({
    regular: {
      singularNominative: "{{count}} годину",
      singularGenitive: "{{count}} години",
      pluralGenitive: "{{count}} годин"
    }
  }),
  xDays: buildLocalizeTokenFn({
    regular: {
      singularNominative: "{{count}} день",
      singularGenitive: "{{count}} днi",
      pluralGenitive: "{{count}} днів"
    }
  }),
  aboutXWeeks: buildLocalizeTokenFn({
    regular: {
      singularNominative: "близько {{count}} тижня",
      singularGenitive: "близько {{count}} тижнів",
      pluralGenitive: "близько {{count}} тижнів"
    },
    future: {
      singularNominative: "приблизно за {{count}} тиждень",
      singularGenitive: "приблизно за {{count}} тижні",
      pluralGenitive: "приблизно за {{count}} тижнів"
    }
  }),
  xWeeks: buildLocalizeTokenFn({
    regular: {
      singularNominative: "{{count}} тиждень",
      singularGenitive: "{{count}} тижні",
      pluralGenitive: "{{count}} тижнів"
    }
  }),
  aboutXMonths: buildLocalizeTokenFn({
    regular: {
      singularNominative: "близько {{count}} місяця",
      singularGenitive: "близько {{count}} місяців",
      pluralGenitive: "близько {{count}} місяців"
    },
    future: {
      singularNominative: "приблизно за {{count}} місяць",
      singularGenitive: "приблизно за {{count}} місяці",
      pluralGenitive: "приблизно за {{count}} місяців"
    }
  }),
  xMonths: buildLocalizeTokenFn({
    regular: {
      singularNominative: "{{count}} місяць",
      singularGenitive: "{{count}} місяці",
      pluralGenitive: "{{count}} місяців"
    }
  }),
  aboutXYears: buildLocalizeTokenFn({
    regular: {
      singularNominative: "близько {{count}} року",
      singularGenitive: "близько {{count}} років",
      pluralGenitive: "близько {{count}} років"
    },
    future: {
      singularNominative: "приблизно за {{count}} рік",
      singularGenitive: "приблизно за {{count}} роки",
      pluralGenitive: "приблизно за {{count}} років"
    }
  }),
  xYears: buildLocalizeTokenFn({
    regular: {
      singularNominative: "{{count}} рік",
      singularGenitive: "{{count}} роки",
      pluralGenitive: "{{count}} років"
    }
  }),
  overXYears: buildLocalizeTokenFn({
    regular: {
      singularNominative: "більше {{count}} року",
      singularGenitive: "більше {{count}} років",
      pluralGenitive: "більше {{count}} років"
    },
    future: {
      singularNominative: "більше, ніж за {{count}} рік",
      singularGenitive: "більше, ніж за {{count}} роки",
      pluralGenitive: "більше, ніж за {{count}} років"
    }
  }),
  almostXYears: buildLocalizeTokenFn({
    regular: {
      singularNominative: "майже {{count}} рік",
      singularGenitive: "майже {{count}} роки",
      pluralGenitive: "майже {{count}} років"
    },
    future: {
      singularNominative: "майже за {{count}} рік",
      singularGenitive: "майже за {{count}} роки",
      pluralGenitive: "майже за {{count}} років"
    }
  })
};
var formatDistance$c = function (e, a, t) {
  t = t || {};
  return formatDistanceLocale$6[e](a, t);
};
formatDistance$d.formatDistance = formatDistance$c;
var formatLong$6 = {};
formatLong$6.formatLong = void 0;
var _index$t = buildFormatLongFn;
var dateFormats$6 = {
  full: "EEEE, do MMMM y 'р.'",
  long: "do MMMM y 'р.'",
  medium: "d MMM y 'р.'",
  short: "dd.MM.y"
};
var timeFormats$6 = {
  full: "H:mm:ss zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$6 = {
  full: "{{date}} 'о' {{time}}",
  long: "{{date}} 'о' {{time}}",
  medium: "{{date}}, {{time}}",
  short: "{{date}}, {{time}}"
};
formatLong$6.formatLong = {
  date: (0, _index$t.buildFormatLongFn)({
    formats: dateFormats$6,
    defaultWidth: "full"
  }),
  time: (0, _index$t.buildFormatLongFn)({
    formats: timeFormats$6,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$t.buildFormatLongFn)({
    formats: dateTimeFormats$6,
    defaultWidth: "full"
  })
};
var formatRelative$d = {};
formatRelative$d.formatRelative = void 0;
var _index$s = isSameWeek;
var _index2$e = toDate;
var accusativeWeekdays = ["неділю", "понеділок", "вівторок", "середу", "четвер", "п’ятницю", "суботу"];
function lastWeek(e) {
  var a = accusativeWeekdays[e];
  switch (e) {
    case 0:
    case 3:
    case 5:
    case 6:
      return "'у минулу " + a + " о' p";
    case 1:
    case 2:
    case 4:
      return "'у минулий " + a + " о' p";
  }
}
function thisWeek(e) {
  var a = accusativeWeekdays[e];
  return "'у " + a + " о' p";
}
function nextWeek(e) {
  var a = accusativeWeekdays[e];
  switch (e) {
    case 0:
    case 3:
    case 5:
    case 6:
      return "'у наступну " + a + " о' p";
    case 1:
    case 2:
    case 4:
      return "'у наступний " + a + " о' p";
  }
}
var lastWeekFormat = function (e, a, t) {
  var r = (0, _index2$e.toDate)(e);
  var n = r.getDay();
  if ((0, _index$s.isSameWeek)(r, a, t)) {
    return thisWeek(n);
  } else {
    return lastWeek(n);
  }
};
var nextWeekFormat = function (e, a, t) {
  var r = (0, _index2$e.toDate)(e);
  var n = r.getDay();
  if ((0, _index$s.isSameWeek)(r, a, t)) {
    return thisWeek(n);
  } else {
    return nextWeek(n);
  }
};
var formatRelativeLocale$6 = {
  lastWeek: lastWeekFormat,
  yesterday: "'вчора о' p",
  today: "'сьогодні о' p",
  tomorrow: "'завтра о' p",
  nextWeek: nextWeekFormat,
  other: "P"
};
var formatRelative$c = function (e, a, t, r) {
  var n = formatRelativeLocale$6[e];
  if (typeof n === "function") {
    return n(a, t, r);
  }
  return n;
};
formatRelative$d.formatRelative = formatRelative$c;
var localize$6 = {};
localize$6.localize = void 0;
var _index$r = buildLocalizeFn;
var eraValues$6 = {
  narrow: ["до н.е.", "н.е."],
  abbreviated: ["до н. е.", "н. е."],
  wide: ["до нашої ери", "нашої ери"]
};
var quarterValues$6 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1-й кв.", "2-й кв.", "3-й кв.", "4-й кв."],
  wide: ["1-й квартал", "2-й квартал", "3-й квартал", "4-й квартал"]
};
var monthValues$6 = {
  narrow: ["С", "Л", "Б", "К", "Т", "Ч", "Л", "С", "В", "Ж", "Л", "Г"],
  abbreviated: ["січ.", "лют.", "берез.", "квіт.", "трав.", "черв.", "лип.", "серп.", "верес.", "жовт.", "листоп.", "груд."],
  wide: ["січень", "лютий", "березень", "квітень", "травень", "червень", "липень", "серпень", "вересень", "жовтень", "листопад", "грудень"]
};
var formattingMonthValues$1 = {
  narrow: ["С", "Л", "Б", "К", "Т", "Ч", "Л", "С", "В", "Ж", "Л", "Г"],
  abbreviated: ["січ.", "лют.", "берез.", "квіт.", "трав.", "черв.", "лип.", "серп.", "верес.", "жовт.", "листоп.", "груд."],
  wide: ["січня", "лютого", "березня", "квітня", "травня", "червня", "липня", "серпня", "вересня", "жовтня", "листопада", "грудня"]
};
var dayValues$6 = {
  narrow: ["Н", "П", "В", "С", "Ч", "П", "С"],
  short: ["нд", "пн", "вт", "ср", "чт", "пт", "сб"],
  abbreviated: ["нед", "пон", "вів", "сер", "чтв", "птн", "суб"],
  wide: ["неділя", "понеділок", "вівторок", "середа", "четвер", "п’ятниця", "субота"]
};
var dayPeriodValues$6 = {
  narrow: {
    am: "ДП",
    pm: "ПП",
    midnight: "півн.",
    noon: "пол.",
    morning: "ранок",
    afternoon: "день",
    evening: "веч.",
    night: "ніч"
  },
  abbreviated: {
    am: "ДП",
    pm: "ПП",
    midnight: "півн.",
    noon: "пол.",
    morning: "ранок",
    afternoon: "день",
    evening: "веч.",
    night: "ніч"
  },
  wide: {
    am: "ДП",
    pm: "ПП",
    midnight: "північ",
    noon: "полудень",
    morning: "ранок",
    afternoon: "день",
    evening: "вечір",
    night: "ніч"
  }
};
var formattingDayPeriodValues$6 = {
  narrow: {
    am: "ДП",
    pm: "ПП",
    midnight: "півн.",
    noon: "пол.",
    morning: "ранку",
    afternoon: "дня",
    evening: "веч.",
    night: "ночі"
  },
  abbreviated: {
    am: "ДП",
    pm: "ПП",
    midnight: "півн.",
    noon: "пол.",
    morning: "ранку",
    afternoon: "дня",
    evening: "веч.",
    night: "ночі"
  },
  wide: {
    am: "ДП",
    pm: "ПП",
    midnight: "північ",
    noon: "полудень",
    morning: "ранку",
    afternoon: "дня",
    evening: "веч.",
    night: "ночі"
  }
};
var ordinalNumber$6 = function (e, a) {
  var t = String(a === null || a === void 0 ? void 0 : a.unit);
  var r = Number(e);
  var n;
  if (t === "date") {
    if (r === 3 || r === 23) {
      n = "-є";
    } else {
      n = "-е";
    }
  } else if (t === "minute" || t === "second" || t === "hour") {
    n = "-а";
  } else {
    n = "-й";
  }
  return r + n;
};
localize$6.localize = {
  ordinalNumber: ordinalNumber$6,
  era: (0, _index$r.buildLocalizeFn)({
    values: eraValues$6,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$r.buildLocalizeFn)({
    values: quarterValues$6,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$r.buildLocalizeFn)({
    values: monthValues$6,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues$1,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$r.buildLocalizeFn)({
    values: dayValues$6,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$r.buildLocalizeFn)({
    values: dayPeriodValues$6,
    defaultWidth: "any",
    formattingValues: formattingDayPeriodValues$6,
    defaultFormattingWidth: "wide"
  })
};
var match$6 = {};
match$6.match = void 0;
var _index$q = buildMatchFn;
var _index2$d = buildMatchPatternFn;
var matchOrdinalNumberPattern$6 = /^(\d+)(-?(е|й|є|а|я))?/i;
var parseOrdinalNumberPattern$6 = /\d+/i;
var matchEraPatterns$6 = {
  narrow: /^((до )?н\.?\s?е\.?)/i,
  abbreviated: /^((до )?н\.?\s?е\.?)/i,
  wide: /^(до нашої ери|нашої ери|наша ера)/i
};
var parseEraPatterns$6 = {
  any: [/^д/i, /^н/i]
};
var matchQuarterPatterns$6 = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234](-?[иі]?й?)? кв.?/i,
  wide: /^[1234](-?[иі]?й?)? квартал/i
};
var parseQuarterPatterns$6 = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$6 = {
  narrow: /^[слбктчвжг]/i,
  abbreviated: /^(січ|лют|бер(ез)?|квіт|трав|черв|лип|серп|вер(ес)?|жовт|лис(топ)?|груд)\.?/i,
  wide: /^(січень|січня|лютий|лютого|березень|березня|квітень|квітня|травень|травня|червня|червень|липень|липня|серпень|серпня|вересень|вересня|жовтень|жовтня|листопад[а]?|грудень|грудня)/i
};
var parseMonthPatterns$6 = {
  narrow: [/^с/i, /^л/i, /^б/i, /^к/i, /^т/i, /^ч/i, /^л/i, /^с/i, /^в/i, /^ж/i, /^л/i, /^г/i],
  any: [/^сі/i, /^лю/i, /^б/i, /^к/i, /^т/i, /^ч/i, /^лип/i, /^се/i, /^в/i, /^ж/i, /^лис/i, /^г/i]
};
var matchDayPatterns$6 = {
  narrow: /^[нпвсч]/i,
  short: /^(нд|пн|вт|ср|чт|пт|сб)\.?/i,
  abbreviated: /^(нед|пон|вів|сер|че?тв|птн?|суб)\.?/i,
  wide: /^(неділ[яі]|понеділ[ок][ка]|вівтор[ок][ка]|серед[аи]|четвер(га)?|п\W*?ятниц[яі]|субот[аи])/i
};
var parseDayPatterns$6 = {
  narrow: [/^н/i, /^п/i, /^в/i, /^с/i, /^ч/i, /^п/i, /^с/i],
  any: [/^н/i, /^п[он]/i, /^в/i, /^с[ер]/i, /^ч/i, /^п\W*?[ят]/i, /^с[уб]/i]
};
var matchDayPeriodPatterns$6 = {
  narrow: /^([дп]п|півн\.?|пол\.?|ранок|ранку|день|дня|веч\.?|ніч|ночі)/i,
  abbreviated: /^([дп]п|півн\.?|пол\.?|ранок|ранку|день|дня|веч\.?|ніч|ночі)/i,
  wide: /^([дп]п|північ|полудень|ранок|ранку|день|дня|вечір|вечора|ніч|ночі)/i
};
var parseDayPeriodPatterns$6 = {
  any: {
    am: /^дп/i,
    pm: /^пп/i,
    midnight: /^півн/i,
    noon: /^пол/i,
    morning: /^р/i,
    afternoon: /^д[ен]/i,
    evening: /^в/i,
    night: /^н/i
  }
};
match$6.match = {
  ordinalNumber: (0, _index2$d.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$6,
    parsePattern: parseOrdinalNumberPattern$6,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$q.buildMatchFn)({
    matchPatterns: matchEraPatterns$6,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$6,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$q.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$6,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$6,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$q.buildMatchFn)({
    matchPatterns: matchMonthPatterns$6,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$6,
    defaultParseWidth: "any"
  }),
  day: (0, _index$q.buildMatchFn)({
    matchPatterns: matchDayPatterns$6,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$6,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$q.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$6,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$6,
    defaultParseWidth: "any"
  })
};
uk.uk = void 0;
var _index$p = formatDistance$d;
var _index2$c = formatLong$6;
var _index3$6 = formatRelative$d;
var _index4$6 = localize$6;
var _index5$6 = match$6;
uk.uk = {
  code: "uk",
  formatDistance: _index$p.formatDistance,
  formatLong: _index2$c.formatLong,
  formatRelative: _index3$6.formatRelative,
  localize: _index4$6.localize,
  match: _index5$6.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var uz = {};
var formatDistance$b = {};
formatDistance$b.formatDistance = void 0;
var formatDistanceLocale$5 = {
  lessThanXSeconds: {
    one: "sekunddan kam",
    other: "{{count}} sekunddan kam"
  },
  xSeconds: {
    one: "1 sekund",
    other: "{{count}} sekund"
  },
  halfAMinute: "yarim minut",
  lessThanXMinutes: {
    one: "bir minutdan kam",
    other: "{{count}} minutdan kam"
  },
  xMinutes: {
    one: "1 minut",
    other: "{{count}} minut"
  },
  aboutXHours: {
    one: "tahminan 1 soat",
    other: "tahminan {{count}} soat"
  },
  xHours: {
    one: "1 soat",
    other: "{{count}} soat"
  },
  xDays: {
    one: "1 kun",
    other: "{{count}} kun"
  },
  aboutXWeeks: {
    one: "tahminan 1 hafta",
    other: "tahminan {{count}} hafta"
  },
  xWeeks: {
    one: "1 hafta",
    other: "{{count}} hafta"
  },
  aboutXMonths: {
    one: "tahminan 1 oy",
    other: "tahminan {{count}} oy"
  },
  xMonths: {
    one: "1 oy",
    other: "{{count}} oy"
  },
  aboutXYears: {
    one: "tahminan 1 yil",
    other: "tahminan {{count}} yil"
  },
  xYears: {
    one: "1 yil",
    other: "{{count}} yil"
  },
  overXYears: {
    one: "1 yildan ko'p",
    other: "{{count}} yildan ko'p"
  },
  almostXYears: {
    one: "deyarli 1 yil",
    other: "deyarli {{count}} yil"
  }
};
var formatDistance$a = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$5[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + " dan keyin";
    } else {
      return r + " oldin";
    }
  }
  return r;
};
formatDistance$b.formatDistance = formatDistance$a;
var formatLong$5 = {};
formatLong$5.formatLong = void 0;
var _index$o = buildFormatLongFn;
var dateFormats$5 = {
  full: "EEEE, do MMMM, y",
  long: "do MMMM, y",
  medium: "d MMM, y",
  short: "dd/MM/yyyy"
};
var timeFormats$5 = {
  full: "h:mm:ss zzzz",
  long: "h:mm:ss z",
  medium: "h:mm:ss",
  short: "h:mm"
};
var dateTimeFormats$5 = {
  any: "{{date}}, {{time}}"
};
formatLong$5.formatLong = {
  date: (0, _index$o.buildFormatLongFn)({
    formats: dateFormats$5,
    defaultWidth: "full"
  }),
  time: (0, _index$o.buildFormatLongFn)({
    formats: timeFormats$5,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$o.buildFormatLongFn)({
    formats: dateTimeFormats$5,
    defaultWidth: "any"
  })
};
var formatRelative$b = {};
formatRelative$b.formatRelative = void 0;
var formatRelativeLocale$5 = {
  lastWeek: "'oldingi' eeee p 'da'",
  yesterday: "'kecha' p 'da'",
  today: "'bugun' p 'da'",
  tomorrow: "'ertaga' p 'da'",
  nextWeek: "eeee p 'da'",
  other: "P"
};
var formatRelative$a = function (e, a, t, r) {
  return formatRelativeLocale$5[e];
};
formatRelative$b.formatRelative = formatRelative$a;
var localize$5 = {};
localize$5.localize = void 0;
var _index$n = buildLocalizeFn;
var eraValues$5 = {
  narrow: ["M.A", "M."],
  abbreviated: ["M.A", "M."],
  wide: ["Miloddan Avvalgi", "Milodiy"]
};
var quarterValues$5 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["CH.1", "CH.2", "CH.3", "CH.4"],
  wide: ["1-chi chorak", "2-chi chorak", "3-chi chorak", "4-chi chorak"]
};
var monthValues$5 = {
  narrow: ["Y", "F", "M", "A", "M", "I", "I", "A", "S", "O", "N", "D"],
  abbreviated: ["Yan", "Fev", "Mar", "Apr", "May", "Iyun", "Iyul", "Avg", "Sen", "Okt", "Noy", "Dek"],
  wide: ["Yanvar", "Fevral", "Mart", "Aprel", "May", "Iyun", "Iyul", "Avgust", "Sentabr", "Oktabr", "Noyabr", "Dekabr"]
};
var dayValues$5 = {
  narrow: ["Y", "D", "S", "CH", "P", "J", "SH"],
  short: ["Ya", "Du", "Se", "Cho", "Pa", "Ju", "Sha"],
  abbreviated: ["Yak", "Dush", "Sesh", "Chor", "Pay", "Jum", "Shan"],
  wide: ["Yakshanba", "Dushanba", "Seshanba", "Chorshanba", "Payshanba", "Juma", "Shanba"]
};
var dayPeriodValues$5 = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "y.t",
    noon: "p.",
    morning: "ertalab",
    afternoon: "tushdan keyin",
    evening: "kechqurun",
    night: "tun"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "yarim tun",
    noon: "peshin",
    morning: "ertalab",
    afternoon: "tushdan keyin",
    evening: "kechqurun",
    night: "tun"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "yarim tun",
    noon: "peshin",
    morning: "ertalab",
    afternoon: "tushdan keyin",
    evening: "kechqurun",
    night: "tun"
  }
};
var formattingDayPeriodValues$5 = {
  narrow: {
    am: "a",
    pm: "p",
    midnight: "y.t",
    noon: "p.",
    morning: "ertalab",
    afternoon: "tushdan keyin",
    evening: "kechqurun",
    night: "tun"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "yarim tun",
    noon: "peshin",
    morning: "ertalab",
    afternoon: "tushdan keyin",
    evening: "kechqurun",
    night: "tun"
  },
  wide: {
    am: "a.m.",
    pm: "p.m.",
    midnight: "yarim tun",
    noon: "peshin",
    morning: "ertalab",
    afternoon: "tushdan keyin",
    evening: "kechqurun",
    night: "tun"
  }
};
var ordinalNumber$5 = function (e, a) {
  return String(e);
};
localize$5.localize = {
  ordinalNumber: ordinalNumber$5,
  era: (0, _index$n.buildLocalizeFn)({
    values: eraValues$5,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$n.buildLocalizeFn)({
    values: quarterValues$5,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$n.buildLocalizeFn)({
    values: monthValues$5,
    defaultWidth: "wide"
  }),
  day: (0, _index$n.buildLocalizeFn)({
    values: dayValues$5,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$n.buildLocalizeFn)({
    values: dayPeriodValues$5,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$5,
    defaultFormattingWidth: "wide"
  })
};
var match$5 = {};
match$5.match = void 0;
var _index$m = buildMatchFn;
var _index2$b = buildMatchPatternFn;
var matchOrdinalNumberPattern$5 = /^(\d+)(chi)?/i;
var parseOrdinalNumberPattern$5 = /\d+/i;
var matchEraPatterns$5 = {
  narrow: /^(m\.a|m\.)/i,
  abbreviated: /^(m\.a\.?\s?m\.?)/i,
  wide: /^(miloddan avval|miloddan keyin)/i
};
var parseEraPatterns$5 = {
  any: [/^b/i, /^(a|c)/i]
};
var matchQuarterPatterns$5 = {
  narrow: /^[1234]/i,
  abbreviated: /^q[1234]/i,
  wide: /^[1234](chi)? chorak/i
};
var parseQuarterPatterns$5 = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$5 = {
  narrow: /^[yfmasond]/i,
  abbreviated: /^(yan|fev|mar|apr|may|iyun|iyul|avg|sen|okt|noy|dek)/i,
  wide: /^(yanvar|fevral|mart|aprel|may|iyun|iyul|avgust|sentabr|oktabr|noyabr|dekabr)/i
};
var parseMonthPatterns$5 = {
  narrow: [/^y/i, /^f/i, /^m/i, /^a/i, /^m/i, /^i/i, /^i/i, /^a/i, /^s/i, /^o/i, /^n/i, /^d/i],
  any: [/^ya/i, /^f/i, /^mar/i, /^ap/i, /^may/i, /^iyun/i, /^iyul/i, /^av/i, /^s/i, /^o/i, /^n/i, /^d/i]
};
var matchDayPatterns$5 = {
  narrow: /^[ydschj]/i,
  short: /^(ya|du|se|cho|pa|ju|sha)/i,
  abbreviated: /^(yak|dush|sesh|chor|pay|jum|shan)/i,
  wide: /^(yakshanba|dushanba|seshanba|chorshanba|payshanba|juma|shanba)/i
};
var parseDayPatterns$5 = {
  narrow: [/^y/i, /^d/i, /^s/i, /^ch/i, /^p/i, /^j/i, /^sh/i],
  any: [/^ya/i, /^d/i, /^se/i, /^ch/i, /^p/i, /^j/i, /^sh/i]
};
var matchDayPeriodPatterns$5 = {
  narrow: /^(a|p|y\.t|p| (ertalab|tushdan keyin|kechqurun|tun))/i,
  any: /^([ap]\.?\s?m\.?|yarim tun|peshin| (ertalab|tushdan keyin|kechqurun|tun))/i
};
var parseDayPeriodPatterns$5 = {
  any: {
    am: /^a/i,
    pm: /^p/i,
    midnight: /^y\.t/i,
    noon: /^pe/i,
    morning: /ertalab/i,
    afternoon: /tushdan keyin/i,
    evening: /kechqurun/i,
    night: /tun/i
  }
};
match$5.match = {
  ordinalNumber: (0, _index2$b.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$5,
    parsePattern: parseOrdinalNumberPattern$5,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$m.buildMatchFn)({
    matchPatterns: matchEraPatterns$5,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$5,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$m.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$5,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$5,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$m.buildMatchFn)({
    matchPatterns: matchMonthPatterns$5,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$5,
    defaultParseWidth: "any"
  }),
  day: (0, _index$m.buildMatchFn)({
    matchPatterns: matchDayPatterns$5,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$5,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$m.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$5,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$5,
    defaultParseWidth: "any"
  })
};
uz.uz = void 0;
var _index$l = formatDistance$b;
var _index2$a = formatLong$5;
var _index3$5 = formatRelative$b;
var _index4$5 = localize$5;
var _index5$5 = match$5;
uz.uz = {
  code: "uz",
  formatDistance: _index$l.formatDistance,
  formatLong: _index2$a.formatLong,
  formatRelative: _index3$5.formatRelative,
  localize: _index4$5.localize,
  match: _index5$5.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var uzCyrl = {};
var formatDistance$9 = {};
formatDistance$9.formatDistance = void 0;
var formatDistanceLocale$4 = {
  lessThanXSeconds: {
    one: "1 сониядан кам",
    other: "{{count}} сониядан кам"
  },
  xSeconds: {
    one: "1 сония",
    other: "{{count}} сония"
  },
  halfAMinute: "ярим дақиқа",
  lessThanXMinutes: {
    one: "1 дақиқадан кам",
    other: "{{count}} дақиқадан кам"
  },
  xMinutes: {
    one: "1 дақиқа",
    other: "{{count}} дақиқа"
  },
  aboutXHours: {
    one: "тахминан 1 соат",
    other: "тахминан {{count}} соат"
  },
  xHours: {
    one: "1 соат",
    other: "{{count}} соат"
  },
  xDays: {
    one: "1 кун",
    other: "{{count}} кун"
  },
  aboutXWeeks: {
    one: "тахминан 1 хафта",
    other: "тахминан {{count}} хафта"
  },
  xWeeks: {
    one: "1 хафта",
    other: "{{count}} хафта"
  },
  aboutXMonths: {
    one: "тахминан 1 ой",
    other: "тахминан {{count}} ой"
  },
  xMonths: {
    one: "1 ой",
    other: "{{count}} ой"
  },
  aboutXYears: {
    one: "тахминан 1 йил",
    other: "тахминан {{count}} йил"
  },
  xYears: {
    one: "1 йил",
    other: "{{count}} йил"
  },
  overXYears: {
    one: "1 йилдан кўп",
    other: "{{count}} йилдан кўп"
  },
  almostXYears: {
    one: "деярли 1 йил",
    other: "деярли {{count}} йил"
  }
};
var formatDistance$8 = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$4[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + "дан кейин";
    } else {
      return r + " олдин";
    }
  }
  return r;
};
formatDistance$9.formatDistance = formatDistance$8;
var formatLong$4 = {};
formatLong$4.formatLong = void 0;
var _index$k = buildFormatLongFn;
var dateFormats$4 = {
  full: "EEEE, do MMMM, y",
  long: "do MMMM, y",
  medium: "d MMM, y",
  short: "dd/MM/yyyy"
};
var timeFormats$4 = {
  full: "H:mm:ss zzzz",
  long: "H:mm:ss z",
  medium: "H:mm:ss",
  short: "H:mm"
};
var dateTimeFormats$4 = {
  any: "{{date}}, {{time}}"
};
formatLong$4.formatLong = {
  date: (0, _index$k.buildFormatLongFn)({
    formats: dateFormats$4,
    defaultWidth: "full"
  }),
  time: (0, _index$k.buildFormatLongFn)({
    formats: timeFormats$4,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$k.buildFormatLongFn)({
    formats: dateTimeFormats$4,
    defaultWidth: "any"
  })
};
var formatRelative$9 = {};
formatRelative$9.formatRelative = void 0;
var formatRelativeLocale$4 = {
  lastWeek: "'ўтган' eeee p 'да'",
  yesterday: "'кеча' p 'да'",
  today: "'бугун' p 'да'",
  tomorrow: "'эртага' p 'да'",
  nextWeek: "eeee p 'да'",
  other: "P"
};
var formatRelative$8 = function (e, a, t, r) {
  return formatRelativeLocale$4[e];
};
formatRelative$9.formatRelative = formatRelative$8;
var localize$4 = {};
localize$4.localize = void 0;
var _index$j = buildLocalizeFn;
var eraValues$4 = {
  narrow: ["М.А", "М"],
  abbreviated: ["М.А", "М"],
  wide: ["Милоддан Аввалги", "Милодий"]
};
var quarterValues$4 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["1-чор.", "2-чор.", "3-чор.", "4-чор."],
  wide: ["1-чорак", "2-чорак", "3-чорак", "4-чорак"]
};
var monthValues$4 = {
  narrow: ["Я", "Ф", "М", "А", "М", "И", "И", "А", "С", "О", "Н", "Д"],
  abbreviated: ["янв", "фев", "мар", "апр", "май", "июн", "июл", "авг", "сен", "окт", "ноя", "дек"],
  wide: ["январ", "феврал", "март", "апрел", "май", "июн", "июл", "август", "сентабр", "октабр", "ноябр", "декабр"]
};
var dayValues$4 = {
  narrow: ["Я", "Д", "С", "Ч", "П", "Ж", "Ш"],
  short: ["як", "ду", "се", "чо", "па", "жу", "ша"],
  abbreviated: ["якш", "душ", "сеш", "чор", "пай", "жум", "шан"],
  wide: ["якшанба", "душанба", "сешанба", "чоршанба", "пайшанба", "жума", "шанба"]
};
var dayPeriodValues$4 = {
  any: {
    am: "П.О.",
    pm: "П.К.",
    midnight: "ярим тун",
    noon: "пешин",
    morning: "эрталаб",
    afternoon: "пешиндан кейин",
    evening: "кечаси",
    night: "тун"
  }
};
var formattingDayPeriodValues$4 = {
  any: {
    am: "П.О.",
    pm: "П.К.",
    midnight: "ярим тун",
    noon: "пешин",
    morning: "эрталаб",
    afternoon: "пешиндан кейин",
    evening: "кечаси",
    night: "тун"
  }
};
var ordinalNumber$4 = function (e, a) {
  return String(e);
};
localize$4.localize = {
  ordinalNumber: ordinalNumber$4,
  era: (0, _index$j.buildLocalizeFn)({
    values: eraValues$4,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$j.buildLocalizeFn)({
    values: quarterValues$4,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$j.buildLocalizeFn)({
    values: monthValues$4,
    defaultWidth: "wide"
  }),
  day: (0, _index$j.buildLocalizeFn)({
    values: dayValues$4,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$j.buildLocalizeFn)({
    values: dayPeriodValues$4,
    defaultWidth: "any",
    formattingValues: formattingDayPeriodValues$4,
    defaultFormattingWidth: "any"
  })
};
var match$4 = {};
match$4.match = void 0;
var _index$i = buildMatchFn;
var _index2$9 = buildMatchPatternFn;
var matchOrdinalNumberPattern$4 = /^(\d+)(чи)?/i;
var parseOrdinalNumberPattern$4 = /\d+/i;
var matchEraPatterns$4 = {
  narrow: /^(м\.а|м\.)/i,
  abbreviated: /^(м\.а|м\.)/i,
  wide: /^(милоддан аввал|милоддан кейин)/i
};
var parseEraPatterns$4 = {
  any: [/^м/i, /^а/i]
};
var matchQuarterPatterns$4 = {
  narrow: /^[1234]/i,
  abbreviated: /^[1234]-чор./i,
  wide: /^[1234]-чорак/i
};
var parseQuarterPatterns$4 = {
  any: [/1/i, /2/i, /3/i, /4/i]
};
var matchMonthPatterns$4 = {
  narrow: /^[яфмамииасонд]/i,
  abbreviated: /^(янв|фев|мар|апр|май|июн|июл|авг|сен|окт|ноя|дек)/i,
  wide: /^(январ|феврал|март|апрел|май|июн|июл|август|сентабр|октабр|ноябр|декабр)/i
};
var parseMonthPatterns$4 = {
  narrow: [/^я/i, /^ф/i, /^м/i, /^а/i, /^м/i, /^и/i, /^и/i, /^а/i, /^с/i, /^о/i, /^н/i, /^д/i],
  any: [/^я/i, /^ф/i, /^мар/i, /^ап/i, /^май/i, /^июн/i, /^июл/i, /^ав/i, /^с/i, /^о/i, /^н/i, /^д/i]
};
var matchDayPatterns$4 = {
  narrow: /^[ядсчпжш]/i,
  short: /^(як|ду|се|чо|па|жу|ша)/i,
  abbreviated: /^(якш|душ|сеш|чор|пай|жум|шан)/i,
  wide: /^(якшанба|душанба|сешанба|чоршанба|пайшанба|жума|шанба)/i
};
var parseDayPatterns$4 = {
  narrow: [/^я/i, /^д/i, /^с/i, /^ч/i, /^п/i, /^ж/i, /^ш/i],
  any: [/^як/i, /^ду/i, /^се/i, /^чор/i, /^пай/i, /^жу/i, /^шан/i]
};
var matchDayPeriodPatterns$4 = {
  any: /^(п\.о\.|п\.к\.|ярим тун|пешиндан кейин|(эрталаб|пешиндан кейин|кечаси|тун))/i
};
var parseDayPeriodPatterns$4 = {
  any: {
    am: /^п\.о\./i,
    pm: /^п\.к\./i,
    midnight: /^ярим тун/i,
    noon: /^пешиндан кейин/i,
    morning: /эрталаб/i,
    afternoon: /пешиндан кейин/i,
    evening: /кечаси/i,
    night: /тун/i
  }
};
match$4.match = {
  ordinalNumber: (0, _index2$9.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$4,
    parsePattern: parseOrdinalNumberPattern$4,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$i.buildMatchFn)({
    matchPatterns: matchEraPatterns$4,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$4,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$i.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$4,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$4,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$i.buildMatchFn)({
    matchPatterns: matchMonthPatterns$4,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$4,
    defaultParseWidth: "any"
  }),
  day: (0, _index$i.buildMatchFn)({
    matchPatterns: matchDayPatterns$4,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$4,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$i.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$4,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$4,
    defaultParseWidth: "any"
  })
};
uzCyrl.uzCyrl = void 0;
var _index$h = formatDistance$9;
var _index2$8 = formatLong$4;
var _index3$4 = formatRelative$9;
var _index4$4 = localize$4;
var _index5$4 = match$4;
uzCyrl.uzCyrl = {
  code: "uz-Cyrl",
  formatDistance: _index$h.formatDistance,
  formatLong: _index2$8.formatLong,
  formatRelative: _index3$4.formatRelative,
  localize: _index4$4.localize,
  match: _index5$4.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var vi = {};
var formatDistance$7 = {};
formatDistance$7.formatDistance = void 0;
var formatDistanceLocale$3 = {
  lessThanXSeconds: {
    one: "dưới 1 giây",
    other: "dưới {{count}} giây"
  },
  xSeconds: {
    one: "1 giây",
    other: "{{count}} giây"
  },
  halfAMinute: "nửa phút",
  lessThanXMinutes: {
    one: "dưới 1 phút",
    other: "dưới {{count}} phút"
  },
  xMinutes: {
    one: "1 phút",
    other: "{{count}} phút"
  },
  aboutXHours: {
    one: "khoảng 1 giờ",
    other: "khoảng {{count}} giờ"
  },
  xHours: {
    one: "1 giờ",
    other: "{{count}} giờ"
  },
  xDays: {
    one: "1 ngày",
    other: "{{count}} ngày"
  },
  aboutXWeeks: {
    one: "khoảng 1 tuần",
    other: "khoảng {{count}} tuần"
  },
  xWeeks: {
    one: "1 tuần",
    other: "{{count}} tuần"
  },
  aboutXMonths: {
    one: "khoảng 1 tháng",
    other: "khoảng {{count}} tháng"
  },
  xMonths: {
    one: "1 tháng",
    other: "{{count}} tháng"
  },
  aboutXYears: {
    one: "khoảng 1 năm",
    other: "khoảng {{count}} năm"
  },
  xYears: {
    one: "1 năm",
    other: "{{count}} năm"
  },
  overXYears: {
    one: "hơn 1 năm",
    other: "hơn {{count}} năm"
  },
  almostXYears: {
    one: "gần 1 năm",
    other: "gần {{count}} năm"
  }
};
var formatDistance$6 = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$3[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + " nữa";
    } else {
      return r + " trước";
    }
  }
  return r;
};
formatDistance$7.formatDistance = formatDistance$6;
var formatLong$3 = {};
formatLong$3.formatLong = void 0;
var _index$g = buildFormatLongFn;
var dateFormats$3 = {
  full: "EEEE, 'ngày' d MMMM 'năm' y",
  long: "'ngày' d MMMM 'năm' y",
  medium: "d MMM 'năm' y",
  short: "dd/MM/y"
};
var timeFormats$3 = {
  full: "HH:mm:ss zzzz",
  long: "HH:mm:ss z",
  medium: "HH:mm:ss",
  short: "HH:mm"
};
var dateTimeFormats$3 = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$3.formatLong = {
  date: (0, _index$g.buildFormatLongFn)({
    formats: dateFormats$3,
    defaultWidth: "full"
  }),
  time: (0, _index$g.buildFormatLongFn)({
    formats: timeFormats$3,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$g.buildFormatLongFn)({
    formats: dateTimeFormats$3,
    defaultWidth: "full"
  })
};
var formatRelative$7 = {};
formatRelative$7.formatRelative = void 0;
var formatRelativeLocale$3 = {
  lastWeek: "eeee 'tuần trước vào lúc' p",
  yesterday: "'hôm qua vào lúc' p",
  today: "'hôm nay vào lúc' p",
  tomorrow: "'ngày mai vào lúc' p",
  nextWeek: "eeee 'tới vào lúc' p",
  other: "P"
};
var formatRelative$6 = function (e, a, t, r) {
  return formatRelativeLocale$3[e];
};
formatRelative$7.formatRelative = formatRelative$6;
var localize$3 = {};
localize$3.localize = void 0;
var _index$f = buildLocalizeFn;
var eraValues$3 = {
  narrow: ["TCN", "SCN"],
  abbreviated: ["trước CN", "sau CN"],
  wide: ["trước Công Nguyên", "sau Công Nguyên"]
};
var quarterValues$3 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["Quý 1", "Quý 2", "Quý 3", "Quý 4"]
};
var formattingQuarterValues = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["Q1", "Q2", "Q3", "Q4"],
  wide: ["quý I", "quý II", "quý III", "quý IV"]
};
var monthValues$3 = {
  narrow: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
  abbreviated: ["Thg 1", "Thg 2", "Thg 3", "Thg 4", "Thg 5", "Thg 6", "Thg 7", "Thg 8", "Thg 9", "Thg 10", "Thg 11", "Thg 12"],
  wide: ["Tháng Một", "Tháng Hai", "Tháng Ba", "Tháng Tư", "Tháng Năm", "Tháng Sáu", "Tháng Bảy", "Tháng Tám", "Tháng Chín", "Tháng Mười", "Tháng Mười Một", "Tháng Mười Hai"]
};
var formattingMonthValues = {
  narrow: ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"],
  abbreviated: ["thg 1", "thg 2", "thg 3", "thg 4", "thg 5", "thg 6", "thg 7", "thg 8", "thg 9", "thg 10", "thg 11", "thg 12"],
  wide: ["tháng 01", "tháng 02", "tháng 03", "tháng 04", "tháng 05", "tháng 06", "tháng 07", "tháng 08", "tháng 09", "tháng 10", "tháng 11", "tháng 12"]
};
var dayValues$3 = {
  narrow: ["CN", "T2", "T3", "T4", "T5", "T6", "T7"],
  short: ["CN", "Th 2", "Th 3", "Th 4", "Th 5", "Th 6", "Th 7"],
  abbreviated: ["CN", "Thứ 2", "Thứ 3", "Thứ 4", "Thứ 5", "Thứ 6", "Thứ 7"],
  wide: ["Chủ Nhật", "Thứ Hai", "Thứ Ba", "Thứ Tư", "Thứ Năm", "Thứ Sáu", "Thứ Bảy"]
};
var dayPeriodValues$3 = {
  narrow: {
    am: "am",
    pm: "pm",
    midnight: "nửa đêm",
    noon: "tr",
    morning: "sg",
    afternoon: "ch",
    evening: "tối",
    night: "đêm"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "nửa đêm",
    noon: "trưa",
    morning: "sáng",
    afternoon: "chiều",
    evening: "tối",
    night: "đêm"
  },
  wide: {
    am: "SA",
    pm: "CH",
    midnight: "nửa đêm",
    noon: "trưa",
    morning: "sáng",
    afternoon: "chiều",
    evening: "tối",
    night: "đêm"
  }
};
var formattingDayPeriodValues$3 = {
  narrow: {
    am: "am",
    pm: "pm",
    midnight: "nửa đêm",
    noon: "tr",
    morning: "sg",
    afternoon: "ch",
    evening: "tối",
    night: "đêm"
  },
  abbreviated: {
    am: "AM",
    pm: "PM",
    midnight: "nửa đêm",
    noon: "trưa",
    morning: "sáng",
    afternoon: "chiều",
    evening: "tối",
    night: "đêm"
  },
  wide: {
    am: "SA",
    pm: "CH",
    midnight: "nửa đêm",
    noon: "giữa trưa",
    morning: "vào buổi sáng",
    afternoon: "vào buổi chiều",
    evening: "vào buổi tối",
    night: "vào ban đêm"
  }
};
var ordinalNumber$3 = function (e, a) {
  var t = Number(e);
  var r = a === null || a === void 0 ? void 0 : a.unit;
  if (r === "quarter") {
    switch (t) {
      case 1:
        return "I";
      case 2:
        return "II";
      case 3:
        return "III";
      case 4:
        return "IV";
    }
  } else if (r === "day") {
    switch (t) {
      case 1:
        return "thứ 2";
      case 2:
        return "thứ 3";
      case 3:
        return "thứ 4";
      case 4:
        return "thứ 5";
      case 5:
        return "thứ 6";
      case 6:
        return "thứ 7";
      case 7:
        return "chủ nhật";
    }
  } else if (r === "week") {
    if (t === 1) {
      return "thứ nhất";
    } else {
      return "thứ " + t;
    }
  } else if (r === "dayOfYear") {
    if (t === 1) {
      return "đầu tiên";
    } else {
      return "thứ " + t;
    }
  }
  return String(t);
};
localize$3.localize = {
  ordinalNumber: ordinalNumber$3,
  era: (0, _index$f.buildLocalizeFn)({
    values: eraValues$3,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$f.buildLocalizeFn)({
    values: quarterValues$3,
    defaultWidth: "wide",
    formattingValues: formattingQuarterValues,
    defaultFormattingWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$f.buildLocalizeFn)({
    values: monthValues$3,
    defaultWidth: "wide",
    formattingValues: formattingMonthValues,
    defaultFormattingWidth: "wide"
  }),
  day: (0, _index$f.buildLocalizeFn)({
    values: dayValues$3,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$f.buildLocalizeFn)({
    values: dayPeriodValues$3,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$3,
    defaultFormattingWidth: "wide"
  })
};
var match$3 = {};
match$3.match = void 0;
var _index$e = buildMatchFn;
var _index2$7 = buildMatchPatternFn;
var matchOrdinalNumberPattern$3 = /^(\d+)/i;
var parseOrdinalNumberPattern$3 = /\d+/i;
var matchEraPatterns$3 = {
  narrow: /^(tcn|scn)/i,
  abbreviated: /^(trước CN|sau CN)/i,
  wide: /^(trước Công Nguyên|sau Công Nguyên)/i
};
var parseEraPatterns$3 = {
  any: [/^t/i, /^s/i]
};
var matchQuarterPatterns$3 = {
  narrow: /^([1234]|i{1,3}v?)/i,
  abbreviated: /^q([1234]|i{1,3}v?)/i,
  wide: /^quý ([1234]|i{1,3}v?)/i
};
var parseQuarterPatterns$3 = {
  any: [/(1|i)$/i, /(2|ii)$/i, /(3|iii)$/i, /(4|iv)$/i]
};
var matchMonthPatterns$3 = {
  narrow: /^(0?[2-9]|10|11|12|0?1)/i,
  abbreviated: /^thg[ _]?(0?[1-9](?!\d)|10|11|12)/i,
  wide: /^tháng ?(Một|Hai|Ba|Tư|Năm|Sáu|Bảy|Tám|Chín|Mười|Mười ?Một|Mười ?Hai|0?[1-9](?!\d)|10|11|12)/i
};
var parseMonthPatterns$3 = {
  narrow: [/0?1$/i, /0?2/i, /3/, /4/, /5/, /6/, /7/, /8/, /9/, /10/, /11/, /12/],
  abbreviated: [/^thg[ _]?0?1(?!\d)/i, /^thg[ _]?0?2/i, /^thg[ _]?0?3/i, /^thg[ _]?0?4/i, /^thg[ _]?0?5/i, /^thg[ _]?0?6/i, /^thg[ _]?0?7/i, /^thg[ _]?0?8/i, /^thg[ _]?0?9/i, /^thg[ _]?10/i, /^thg[ _]?11/i, /^thg[ _]?12/i],
  wide: [/^tháng ?(Một|0?1(?!\d))/i, /^tháng ?(Hai|0?2)/i, /^tháng ?(Ba|0?3)/i, /^tháng ?(Tư|0?4)/i, /^tháng ?(Năm|0?5)/i, /^tháng ?(Sáu|0?6)/i, /^tháng ?(Bảy|0?7)/i, /^tháng ?(Tám|0?8)/i, /^tháng ?(Chín|0?9)/i, /^tháng ?(Mười|10)/i, /^tháng ?(Mười ?Một|11)/i, /^tháng ?(Mười ?Hai|12)/i]
};
var matchDayPatterns$3 = {
  narrow: /^(CN|T2|T3|T4|T5|T6|T7)/i,
  short: /^(CN|Th ?2|Th ?3|Th ?4|Th ?5|Th ?6|Th ?7)/i,
  abbreviated: /^(CN|Th ?2|Th ?3|Th ?4|Th ?5|Th ?6|Th ?7)/i,
  wide: /^(Chủ ?Nhật|Chúa ?Nhật|thứ ?Hai|thứ ?Ba|thứ ?Tư|thứ ?Năm|thứ ?Sáu|thứ ?Bảy)/i
};
var parseDayPatterns$3 = {
  narrow: [/CN/i, /2/i, /3/i, /4/i, /5/i, /6/i, /7/i],
  short: [/CN/i, /2/i, /3/i, /4/i, /5/i, /6/i, /7/i],
  abbreviated: [/CN/i, /2/i, /3/i, /4/i, /5/i, /6/i, /7/i],
  wide: [/(Chủ|Chúa) ?Nhật/i, /Hai/i, /Ba/i, /Tư/i, /Năm/i, /Sáu/i, /Bảy/i]
};
var matchDayPeriodPatterns$3 = {
  narrow: /^(a|p|nửa đêm|trưa|(giờ) (sáng|chiều|tối|đêm))/i,
  abbreviated: /^(am|pm|nửa đêm|trưa|(giờ) (sáng|chiều|tối|đêm))/i,
  wide: /^(ch[^i]*|sa|nửa đêm|trưa|(giờ) (sáng|chiều|tối|đêm))/i
};
var parseDayPeriodPatterns$3 = {
  any: {
    am: /^(a|sa)/i,
    pm: /^(p|ch[^i]*)/i,
    midnight: /nửa đêm/i,
    noon: /trưa/i,
    morning: /sáng/i,
    afternoon: /chiều/i,
    evening: /tối/i,
    night: /^đêm/i
  }
};
match$3.match = {
  ordinalNumber: (0, _index2$7.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$3,
    parsePattern: parseOrdinalNumberPattern$3,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$e.buildMatchFn)({
    matchPatterns: matchEraPatterns$3,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$3,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$e.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$3,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$3,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$e.buildMatchFn)({
    matchPatterns: matchMonthPatterns$3,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$3,
    defaultParseWidth: "wide"
  }),
  day: (0, _index$e.buildMatchFn)({
    matchPatterns: matchDayPatterns$3,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$3,
    defaultParseWidth: "wide"
  }),
  dayPeriod: (0, _index$e.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$3,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPeriodPatterns$3,
    defaultParseWidth: "any"
  })
};
vi.vi = void 0;
var _index$d = formatDistance$7;
var _index2$6 = formatLong$3;
var _index3$3 = formatRelative$7;
var _index4$3 = localize$3;
var _index5$3 = match$3;
vi.vi = {
  code: "vi",
  formatDistance: _index$d.formatDistance,
  formatLong: _index2$6.formatLong,
  formatRelative: _index3$3.formatRelative,
  localize: _index4$3.localize,
  match: _index5$3.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 1
  }
};
var zhCN = {};
var formatDistance$5 = {};
formatDistance$5.formatDistance = void 0;
var formatDistanceLocale$2 = {
  lessThanXSeconds: {
    one: "不到 1 秒",
    other: "不到 {{count}} 秒"
  },
  xSeconds: {
    one: "1 秒",
    other: "{{count}} 秒"
  },
  halfAMinute: "半分钟",
  lessThanXMinutes: {
    one: "不到 1 分钟",
    other: "不到 {{count}} 分钟"
  },
  xMinutes: {
    one: "1 分钟",
    other: "{{count}} 分钟"
  },
  xHours: {
    one: "1 小时",
    other: "{{count}} 小时"
  },
  aboutXHours: {
    one: "大约 1 小时",
    other: "大约 {{count}} 小时"
  },
  xDays: {
    one: "1 天",
    other: "{{count}} 天"
  },
  aboutXWeeks: {
    one: "大约 1 个星期",
    other: "大约 {{count}} 个星期"
  },
  xWeeks: {
    one: "1 个星期",
    other: "{{count}} 个星期"
  },
  aboutXMonths: {
    one: "大约 1 个月",
    other: "大约 {{count}} 个月"
  },
  xMonths: {
    one: "1 个月",
    other: "{{count}} 个月"
  },
  aboutXYears: {
    one: "大约 1 年",
    other: "大约 {{count}} 年"
  },
  xYears: {
    one: "1 年",
    other: "{{count}} 年"
  },
  overXYears: {
    one: "超过 1 年",
    other: "超过 {{count}} 年"
  },
  almostXYears: {
    one: "将近 1 年",
    other: "将近 {{count}} 年"
  }
};
var formatDistance$4 = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$2[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + "内";
    } else {
      return r + "前";
    }
  }
  return r;
};
formatDistance$5.formatDistance = formatDistance$4;
var formatLong$2 = {};
formatLong$2.formatLong = void 0;
var _index$c = buildFormatLongFn;
var dateFormats$2 = {
  full: "y'年'M'月'd'日' EEEE",
  long: "y'年'M'月'd'日'",
  medium: "yyyy-MM-dd",
  short: "yy-MM-dd"
};
var timeFormats$2 = {
  full: "zzzz a h:mm:ss",
  long: "z a h:mm:ss",
  medium: "a h:mm:ss",
  short: "a h:mm"
};
var dateTimeFormats$2 = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$2.formatLong = {
  date: (0, _index$c.buildFormatLongFn)({
    formats: dateFormats$2,
    defaultWidth: "full"
  }),
  time: (0, _index$c.buildFormatLongFn)({
    formats: timeFormats$2,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$c.buildFormatLongFn)({
    formats: dateTimeFormats$2,
    defaultWidth: "full"
  })
};
var formatRelative$5 = {};
formatRelative$5.formatRelative = void 0;
var _index$b = isSameWeek;
function checkWeek(e, a, t) {
  var r = "eeee p";
  if ((0, _index$b.isSameWeek)(e, a, t)) {
    return r;
  } else if (e.getTime() > a.getTime()) {
    return "'下个'" + r;
  }
  return "'上个'" + r;
}
var formatRelativeLocale$2 = {
  lastWeek: checkWeek,
  yesterday: "'昨天' p",
  today: "'今天' p",
  tomorrow: "'明天' p",
  nextWeek: checkWeek,
  other: "PP p"
};
var formatRelative$4 = function (e, a, t, r) {
  var n = formatRelativeLocale$2[e];
  if (typeof n === "function") {
    return n(a, t, r);
  }
  return n;
};
formatRelative$5.formatRelative = formatRelative$4;
var localize$2 = {};
localize$2.localize = void 0;
var _index$a = buildLocalizeFn;
var eraValues$2 = {
  narrow: ["前", "公元"],
  abbreviated: ["前", "公元"],
  wide: ["公元前", "公元"]
};
var quarterValues$2 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["第一季", "第二季", "第三季", "第四季"],
  wide: ["第一季度", "第二季度", "第三季度", "第四季度"]
};
var monthValues$2 = {
  narrow: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", "十一", "十二"],
  abbreviated: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
  wide: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
};
var dayValues$2 = {
  narrow: ["日", "一", "二", "三", "四", "五", "六"],
  short: ["日", "一", "二", "三", "四", "五", "六"],
  abbreviated: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
  wide: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
};
var dayPeriodValues$2 = {
  narrow: {
    am: "上",
    pm: "下",
    midnight: "凌晨",
    noon: "午",
    morning: "早",
    afternoon: "下午",
    evening: "晚",
    night: "夜"
  },
  abbreviated: {
    am: "上午",
    pm: "下午",
    midnight: "凌晨",
    noon: "中午",
    morning: "早晨",
    afternoon: "中午",
    evening: "晚上",
    night: "夜间"
  },
  wide: {
    am: "上午",
    pm: "下午",
    midnight: "凌晨",
    noon: "中午",
    morning: "早晨",
    afternoon: "中午",
    evening: "晚上",
    night: "夜间"
  }
};
var formattingDayPeriodValues$2 = {
  narrow: {
    am: "上",
    pm: "下",
    midnight: "凌晨",
    noon: "午",
    morning: "早",
    afternoon: "下午",
    evening: "晚",
    night: "夜"
  },
  abbreviated: {
    am: "上午",
    pm: "下午",
    midnight: "凌晨",
    noon: "中午",
    morning: "早晨",
    afternoon: "中午",
    evening: "晚上",
    night: "夜间"
  },
  wide: {
    am: "上午",
    pm: "下午",
    midnight: "凌晨",
    noon: "中午",
    morning: "早晨",
    afternoon: "中午",
    evening: "晚上",
    night: "夜间"
  }
};
var ordinalNumber$2 = function (e, a) {
  var t = Number(e);
  switch (a === null || a === void 0 ? void 0 : a.unit) {
    case "date":
      return t.toString() + "日";
    case "hour":
      return t.toString() + "时";
    case "minute":
      return t.toString() + "分";
    case "second":
      return t.toString() + "秒";
    default:
      return "第 " + t.toString();
  }
};
localize$2.localize = {
  ordinalNumber: ordinalNumber$2,
  era: (0, _index$a.buildLocalizeFn)({
    values: eraValues$2,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$a.buildLocalizeFn)({
    values: quarterValues$2,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$a.buildLocalizeFn)({
    values: monthValues$2,
    defaultWidth: "wide"
  }),
  day: (0, _index$a.buildLocalizeFn)({
    values: dayValues$2,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$a.buildLocalizeFn)({
    values: dayPeriodValues$2,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$2,
    defaultFormattingWidth: "wide"
  })
};
var match$2 = {};
match$2.match = void 0;
var _index$9 = buildMatchFn;
var _index2$5 = buildMatchPatternFn;
var matchOrdinalNumberPattern$2 = /^(第\s*)?\d+(日|时|分|秒)?/i;
var parseOrdinalNumberPattern$2 = /\d+/i;
var matchEraPatterns$2 = {
  narrow: /^(前)/i,
  abbreviated: /^(前)/i,
  wide: /^(公元前|公元)/i
};
var parseEraPatterns$2 = {
  any: [/^(前)/i, /^(公元)/i]
};
var matchQuarterPatterns$2 = {
  narrow: /^[1234]/i,
  abbreviated: /^第[一二三四]刻/i,
  wide: /^第[一二三四]刻钟/i
};
var parseQuarterPatterns$2 = {
  any: [/(1|一)/i, /(2|二)/i, /(3|三)/i, /(4|四)/i]
};
var matchMonthPatterns$2 = {
  narrow: /^(一|二|三|四|五|六|七|八|九|十[二一])/i,
  abbreviated: /^(一|二|三|四|五|六|七|八|九|十[二一]|\d|1[12])月/i,
  wide: /^(一|二|三|四|五|六|七|八|九|十[二一])月/i
};
var parseMonthPatterns$2 = {
  narrow: [/^一/i, /^二/i, /^三/i, /^四/i, /^五/i, /^六/i, /^七/i, /^八/i, /^九/i, /^十(?!(一|二))/i, /^十一/i, /^十二/i],
  any: [/^一|1/i, /^二|2/i, /^三|3/i, /^四|4/i, /^五|5/i, /^六|6/i, /^七|7/i, /^八|8/i, /^九|9/i, /^十(?!(一|二))|10/i, /^十一|11/i, /^十二|12/i]
};
var matchDayPatterns$2 = {
  narrow: /^[一二三四五六日]/i,
  short: /^[一二三四五六日]/i,
  abbreviated: /^周[一二三四五六日]/i,
  wide: /^星期[一二三四五六日]/i
};
var parseDayPatterns$2 = {
  any: [/日/i, /一/i, /二/i, /三/i, /四/i, /五/i, /六/i]
};
var matchDayPeriodPatterns$2 = {
  any: /^(上午?|下午?|午夜|[中正]午|早上?|下午|晚上?|凌晨|)/i
};
var parseDayPeriodPatterns$2 = {
  any: {
    am: /^上午?/i,
    pm: /^下午?/i,
    midnight: /^午夜/i,
    noon: /^[中正]午/i,
    morning: /^早上/i,
    afternoon: /^下午/i,
    evening: /^晚上?/i,
    night: /^凌晨/i
  }
};
match$2.match = {
  ordinalNumber: (0, _index2$5.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$2,
    parsePattern: parseOrdinalNumberPattern$2,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$9.buildMatchFn)({
    matchPatterns: matchEraPatterns$2,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$2,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$9.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$2,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$2,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$9.buildMatchFn)({
    matchPatterns: matchMonthPatterns$2,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$2,
    defaultParseWidth: "any"
  }),
  day: (0, _index$9.buildMatchFn)({
    matchPatterns: matchDayPatterns$2,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$2,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$9.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$2,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$2,
    defaultParseWidth: "any"
  })
};
zhCN.zhCN = void 0;
var _index$8 = formatDistance$5;
var _index2$4 = formatLong$2;
var _index3$2 = formatRelative$5;
var _index4$2 = localize$2;
var _index5$2 = match$2;
zhCN.zhCN = {
  code: "zh-CN",
  formatDistance: _index$8.formatDistance,
  formatLong: _index2$4.formatLong,
  formatRelative: _index3$2.formatRelative,
  localize: _index4$2.localize,
  match: _index5$2.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
var zhHK = {};
var formatDistance$3 = {};
formatDistance$3.formatDistance = void 0;
var formatDistanceLocale$1 = {
  lessThanXSeconds: {
    one: "少於 1 秒",
    other: "少於 {{count}} 秒"
  },
  xSeconds: {
    one: "1 秒",
    other: "{{count}} 秒"
  },
  halfAMinute: "半分鐘",
  lessThanXMinutes: {
    one: "少於 1 分鐘",
    other: "少於 {{count}} 分鐘"
  },
  xMinutes: {
    one: "1 分鐘",
    other: "{{count}} 分鐘"
  },
  xHours: {
    one: "1 小時",
    other: "{{count}} 小時"
  },
  aboutXHours: {
    one: "大約 1 小時",
    other: "大約 {{count}} 小時"
  },
  xDays: {
    one: "1 天",
    other: "{{count}} 天"
  },
  aboutXWeeks: {
    one: "大約 1 個星期",
    other: "大約 {{count}} 個星期"
  },
  xWeeks: {
    one: "1 個星期",
    other: "{{count}} 個星期"
  },
  aboutXMonths: {
    one: "大約 1 個月",
    other: "大約 {{count}} 個月"
  },
  xMonths: {
    one: "1 個月",
    other: "{{count}} 個月"
  },
  aboutXYears: {
    one: "大約 1 年",
    other: "大約 {{count}} 年"
  },
  xYears: {
    one: "1 年",
    other: "{{count}} 年"
  },
  overXYears: {
    one: "超過 1 年",
    other: "超過 {{count}} 年"
  },
  almostXYears: {
    one: "將近 1 年",
    other: "將近 {{count}} 年"
  }
};
var formatDistance$2 = function (e, a, t) {
  var r;
  var n = formatDistanceLocale$1[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + "內";
    } else {
      return r + "前";
    }
  }
  return r;
};
formatDistance$3.formatDistance = formatDistance$2;
var formatLong$1 = {};
formatLong$1.formatLong = void 0;
var _index$7 = buildFormatLongFn;
var dateFormats$1 = {
  full: "y'年'M'月'd'日' EEEE",
  long: "y'年'M'月'd'日'",
  medium: "yyyy-MM-dd",
  short: "yy-MM-dd"
};
var timeFormats$1 = {
  full: "zzzz a h:mm:ss",
  long: "z a h:mm:ss",
  medium: "a h:mm:ss",
  short: "a h:mm"
};
var dateTimeFormats$1 = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong$1.formatLong = {
  date: (0, _index$7.buildFormatLongFn)({
    formats: dateFormats$1,
    defaultWidth: "full"
  }),
  time: (0, _index$7.buildFormatLongFn)({
    formats: timeFormats$1,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$7.buildFormatLongFn)({
    formats: dateTimeFormats$1,
    defaultWidth: "full"
  })
};
var formatRelative$3 = {};
formatRelative$3.formatRelative = void 0;
var formatRelativeLocale$1 = {
  lastWeek: "'上個'eeee p",
  yesterday: "'昨天' p",
  today: "'今天' p",
  tomorrow: "'明天' p",
  nextWeek: "'下個'eeee p",
  other: "P"
};
var formatRelative$2 = function (e, a, t, r) {
  return formatRelativeLocale$1[e];
};
formatRelative$3.formatRelative = formatRelative$2;
var localize$1 = {};
localize$1.localize = void 0;
var _index$6 = buildLocalizeFn;
var eraValues$1 = {
  narrow: ["前", "公元"],
  abbreviated: ["前", "公元"],
  wide: ["公元前", "公元"]
};
var quarterValues$1 = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["第一季", "第二季", "第三季", "第四季"],
  wide: ["第一季度", "第二季度", "第三季度", "第四季度"]
};
var monthValues$1 = {
  narrow: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", "十一", "十二"],
  abbreviated: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
  wide: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
};
var dayValues$1 = {
  narrow: ["日", "一", "二", "三", "四", "五", "六"],
  short: ["日", "一", "二", "三", "四", "五", "六"],
  abbreviated: ["週日", "週一", "週二", "週三", "週四", "週五", "週六"],
  wide: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
};
var dayPeriodValues$1 = {
  narrow: {
    am: "上",
    pm: "下",
    midnight: "午夜",
    noon: "晌",
    morning: "早",
    afternoon: "午",
    evening: "晚",
    night: "夜"
  },
  abbreviated: {
    am: "上午",
    pm: "下午",
    midnight: "午夜",
    noon: "中午",
    morning: "上午",
    afternoon: "下午",
    evening: "晚上",
    night: "夜晚"
  },
  wide: {
    am: "上午",
    pm: "下午",
    midnight: "午夜",
    noon: "中午",
    morning: "上午",
    afternoon: "下午",
    evening: "晚上",
    night: "夜晚"
  }
};
var formattingDayPeriodValues$1 = {
  narrow: {
    am: "上",
    pm: "下",
    midnight: "午夜",
    noon: "晌",
    morning: "早",
    afternoon: "午",
    evening: "晚",
    night: "夜"
  },
  abbreviated: {
    am: "上午",
    pm: "下午",
    midnight: "午夜",
    noon: "中午",
    morning: "上午",
    afternoon: "下午",
    evening: "晚上",
    night: "夜晚"
  },
  wide: {
    am: "上午",
    pm: "下午",
    midnight: "午夜",
    noon: "中午",
    morning: "上午",
    afternoon: "下午",
    evening: "晚上",
    night: "夜晚"
  }
};
var ordinalNumber$1 = function (e, a) {
  var t = Number(e);
  switch (a === null || a === void 0 ? void 0 : a.unit) {
    case "date":
      return t + "日";
    case "hour":
      return t + "時";
    case "minute":
      return t + "分";
    case "second":
      return t + "秒";
    default:
      return "第 " + t;
  }
};
localize$1.localize = {
  ordinalNumber: ordinalNumber$1,
  era: (0, _index$6.buildLocalizeFn)({
    values: eraValues$1,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$6.buildLocalizeFn)({
    values: quarterValues$1,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$6.buildLocalizeFn)({
    values: monthValues$1,
    defaultWidth: "wide"
  }),
  day: (0, _index$6.buildLocalizeFn)({
    values: dayValues$1,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$6.buildLocalizeFn)({
    values: dayPeriodValues$1,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues$1,
    defaultFormattingWidth: "wide"
  })
};
var match$1 = {};
match$1.match = void 0;
var _index$5 = buildMatchFn;
var _index2$3 = buildMatchPatternFn;
var matchOrdinalNumberPattern$1 = /^(第\s*)?\d+(日|時|分|秒)?/i;
var parseOrdinalNumberPattern$1 = /\d+/i;
var matchEraPatterns$1 = {
  narrow: /^(前)/i,
  abbreviated: /^(前)/i,
  wide: /^(公元前|公元)/i
};
var parseEraPatterns$1 = {
  any: [/^(前)/i, /^(公元)/i]
};
var matchQuarterPatterns$1 = {
  narrow: /^[1234]/i,
  abbreviated: /^第[一二三四]季/i,
  wide: /^第[一二三四]季度/i
};
var parseQuarterPatterns$1 = {
  any: [/(1|一)/i, /(2|二)/i, /(3|三)/i, /(4|四)/i]
};
var matchMonthPatterns$1 = {
  narrow: /^(一|二|三|四|五|六|七|八|九|十[二一])/i,
  abbreviated: /^(一|二|三|四|五|六|七|八|九|十[二一]|\d|1[12])月/i,
  wide: /^(一|二|三|四|五|六|七|八|九|十[二一])月/i
};
var parseMonthPatterns$1 = {
  narrow: [/^一/i, /^二/i, /^三/i, /^四/i, /^五/i, /^六/i, /^七/i, /^八/i, /^九/i, /^十(?!(一|二))/i, /^十一/i, /^十二/i],
  any: [/^一|1/i, /^二|2/i, /^三|3/i, /^四|4/i, /^五|5/i, /^六|6/i, /^七|7/i, /^八|8/i, /^九|9/i, /^十(?!(一|二))|10/i, /^十一|11/i, /^十二|12/i]
};
var matchDayPatterns$1 = {
  narrow: /^[一二三四五六日]/i,
  short: /^[一二三四五六日]/i,
  abbreviated: /^週[一二三四五六日]/i,
  wide: /^星期[一二三四五六日]/i
};
var parseDayPatterns$1 = {
  any: [/日/i, /一/i, /二/i, /三/i, /四/i, /五/i, /六/i]
};
var matchDayPeriodPatterns$1 = {
  any: /^(上午?|下午?|午夜|[中正]午|早上?|下午|晚上?|凌晨)/i
};
var parseDayPeriodPatterns$1 = {
  any: {
    am: /^上午?/i,
    pm: /^下午?/i,
    midnight: /^午夜/i,
    noon: /^[中正]午/i,
    morning: /^早上/i,
    afternoon: /^下午/i,
    evening: /^晚上?/i,
    night: /^凌晨/i
  }
};
match$1.match = {
  ordinalNumber: (0, _index2$3.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern$1,
    parsePattern: parseOrdinalNumberPattern$1,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$5.buildMatchFn)({
    matchPatterns: matchEraPatterns$1,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns$1,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$5.buildMatchFn)({
    matchPatterns: matchQuarterPatterns$1,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns$1,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$5.buildMatchFn)({
    matchPatterns: matchMonthPatterns$1,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns$1,
    defaultParseWidth: "any"
  }),
  day: (0, _index$5.buildMatchFn)({
    matchPatterns: matchDayPatterns$1,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns$1,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$5.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns$1,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns$1,
    defaultParseWidth: "any"
  })
};
zhHK.zhHK = void 0;
var _index$4 = formatDistance$3;
var _index2$2 = formatLong$1;
var _index3$1 = formatRelative$3;
var _index4$1 = localize$1;
var _index5$1 = match$1;
zhHK.zhHK = {
  code: "zh-HK",
  formatDistance: _index$4.formatDistance,
  formatLong: _index2$2.formatLong,
  formatRelative: _index3$1.formatRelative,
  localize: _index4$1.localize,
  match: _index5$1.match,
  options: {
    weekStartsOn: 0,
    firstWeekContainsDate: 1
  }
};
var zhTW = {};
var formatDistance$1 = {};
formatDistance$1.formatDistance = void 0;
var formatDistanceLocale = {
  lessThanXSeconds: {
    one: "少於 1 秒",
    other: "少於 {{count}} 秒"
  },
  xSeconds: {
    one: "1 秒",
    other: "{{count}} 秒"
  },
  halfAMinute: "半分鐘",
  lessThanXMinutes: {
    one: "少於 1 分鐘",
    other: "少於 {{count}} 分鐘"
  },
  xMinutes: {
    one: "1 分鐘",
    other: "{{count}} 分鐘"
  },
  xHours: {
    one: "1 小時",
    other: "{{count}} 小時"
  },
  aboutXHours: {
    one: "大約 1 小時",
    other: "大約 {{count}} 小時"
  },
  xDays: {
    one: "1 天",
    other: "{{count}} 天"
  },
  aboutXWeeks: {
    one: "大約 1 個星期",
    other: "大約 {{count}} 個星期"
  },
  xWeeks: {
    one: "1 個星期",
    other: "{{count}} 個星期"
  },
  aboutXMonths: {
    one: "大約 1 個月",
    other: "大約 {{count}} 個月"
  },
  xMonths: {
    one: "1 個月",
    other: "{{count}} 個月"
  },
  aboutXYears: {
    one: "大約 1 年",
    other: "大約 {{count}} 年"
  },
  xYears: {
    one: "1 年",
    other: "{{count}} 年"
  },
  overXYears: {
    one: "超過 1 年",
    other: "超過 {{count}} 年"
  },
  almostXYears: {
    one: "將近 1 年",
    other: "將近 {{count}} 年"
  }
};
var formatDistance = function (e, a, t) {
  var r;
  var n = formatDistanceLocale[e];
  if (typeof n === "string") {
    r = n;
  } else if (a === 1) {
    r = n.one;
  } else {
    r = n.other.replace("{{count}}", String(a));
  }
  if (t === null || t === void 0 ? void 0 : t.addSuffix) {
    if (t.comparison && t.comparison > 0) {
      return r + "內";
    } else {
      return r + "前";
    }
  }
  return r;
};
formatDistance$1.formatDistance = formatDistance;
var formatLong = {};
formatLong.formatLong = void 0;
var _index$3 = buildFormatLongFn;
var dateFormats = {
  full: "y'年'M'月'd'日' EEEE",
  long: "y'年'M'月'd'日'",
  medium: "yyyy-MM-dd",
  short: "yy-MM-dd"
};
var timeFormats = {
  full: "zzzz a h:mm:ss",
  long: "z a h:mm:ss",
  medium: "a h:mm:ss",
  short: "a h:mm"
};
var dateTimeFormats = {
  full: "{{date}} {{time}}",
  long: "{{date}} {{time}}",
  medium: "{{date}} {{time}}",
  short: "{{date}} {{time}}"
};
formatLong.formatLong = {
  date: (0, _index$3.buildFormatLongFn)({
    formats: dateFormats,
    defaultWidth: "full"
  }),
  time: (0, _index$3.buildFormatLongFn)({
    formats: timeFormats,
    defaultWidth: "full"
  }),
  dateTime: (0, _index$3.buildFormatLongFn)({
    formats: dateTimeFormats,
    defaultWidth: "full"
  })
};
var formatRelative$1 = {};
formatRelative$1.formatRelative = void 0;
var formatRelativeLocale = {
  lastWeek: "'上個'eeee p",
  yesterday: "'昨天' p",
  today: "'今天' p",
  tomorrow: "'明天' p",
  nextWeek: "'下個'eeee p",
  other: "P"
};
var formatRelative = function (e, a, t, r) {
  return formatRelativeLocale[e];
};
formatRelative$1.formatRelative = formatRelative;
var localize = {};
localize.localize = void 0;
var _index$2 = buildLocalizeFn;
var eraValues = {
  narrow: ["前", "公元"],
  abbreviated: ["前", "公元"],
  wide: ["公元前", "公元"]
};
var quarterValues = {
  narrow: ["1", "2", "3", "4"],
  abbreviated: ["第一刻", "第二刻", "第三刻", "第四刻"],
  wide: ["第一刻鐘", "第二刻鐘", "第三刻鐘", "第四刻鐘"]
};
var monthValues = {
  narrow: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", "十一", "十二"],
  abbreviated: ["1月", "2月", "3月", "4月", "5月", "6月", "7月", "8月", "9月", "10月", "11月", "12月"],
  wide: ["一月", "二月", "三月", "四月", "五月", "六月", "七月", "八月", "九月", "十月", "十一月", "十二月"]
};
var dayValues = {
  narrow: ["日", "一", "二", "三", "四", "五", "六"],
  short: ["日", "一", "二", "三", "四", "五", "六"],
  abbreviated: ["週日", "週一", "週二", "週三", "週四", "週五", "週六"],
  wide: ["星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六"]
};
var dayPeriodValues = {
  narrow: {
    am: "上",
    pm: "下",
    midnight: "凌晨",
    noon: "午",
    morning: "早",
    afternoon: "下午",
    evening: "晚",
    night: "夜"
  },
  abbreviated: {
    am: "上午",
    pm: "下午",
    midnight: "凌晨",
    noon: "中午",
    morning: "早晨",
    afternoon: "中午",
    evening: "晚上",
    night: "夜間"
  },
  wide: {
    am: "上午",
    pm: "下午",
    midnight: "凌晨",
    noon: "中午",
    morning: "早晨",
    afternoon: "中午",
    evening: "晚上",
    night: "夜間"
  }
};
var formattingDayPeriodValues = {
  narrow: {
    am: "上",
    pm: "下",
    midnight: "凌晨",
    noon: "午",
    morning: "早",
    afternoon: "下午",
    evening: "晚",
    night: "夜"
  },
  abbreviated: {
    am: "上午",
    pm: "下午",
    midnight: "凌晨",
    noon: "中午",
    morning: "早晨",
    afternoon: "中午",
    evening: "晚上",
    night: "夜間"
  },
  wide: {
    am: "上午",
    pm: "下午",
    midnight: "凌晨",
    noon: "中午",
    morning: "早晨",
    afternoon: "中午",
    evening: "晚上",
    night: "夜間"
  }
};
var ordinalNumber = function (e, a) {
  var t = Number(e);
  switch (a === null || a === void 0 ? void 0 : a.unit) {
    case "date":
      return t + "日";
    case "hour":
      return t + "時";
    case "minute":
      return t + "分";
    case "second":
      return t + "秒";
    default:
      return "第 " + t;
  }
};
localize.localize = {
  ordinalNumber: ordinalNumber,
  era: (0, _index$2.buildLocalizeFn)({
    values: eraValues,
    defaultWidth: "wide"
  }),
  quarter: (0, _index$2.buildLocalizeFn)({
    values: quarterValues,
    defaultWidth: "wide",
    argumentCallback: function (e) {
      return e - 1;
    }
  }),
  month: (0, _index$2.buildLocalizeFn)({
    values: monthValues,
    defaultWidth: "wide"
  }),
  day: (0, _index$2.buildLocalizeFn)({
    values: dayValues,
    defaultWidth: "wide"
  }),
  dayPeriod: (0, _index$2.buildLocalizeFn)({
    values: dayPeriodValues,
    defaultWidth: "wide",
    formattingValues: formattingDayPeriodValues,
    defaultFormattingWidth: "wide"
  })
};
var match = {};
match.match = void 0;
var _index$1 = buildMatchFn;
var _index2$1 = buildMatchPatternFn;
var matchOrdinalNumberPattern = /^(第\s*)?\d+(日|時|分|秒)?/i;
var parseOrdinalNumberPattern = /\d+/i;
var matchEraPatterns = {
  narrow: /^(前)/i,
  abbreviated: /^(前)/i,
  wide: /^(公元前|公元)/i
};
var parseEraPatterns = {
  any: [/^(前)/i, /^(公元)/i]
};
var matchQuarterPatterns = {
  narrow: /^[1234]/i,
  abbreviated: /^第[一二三四]刻/i,
  wide: /^第[一二三四]刻鐘/i
};
var parseQuarterPatterns = {
  any: [/(1|一)/i, /(2|二)/i, /(3|三)/i, /(4|四)/i]
};
var matchMonthPatterns = {
  narrow: /^(一|二|三|四|五|六|七|八|九|十[二一])/i,
  abbreviated: /^(一|二|三|四|五|六|七|八|九|十[二一]|\d|1[12])月/i,
  wide: /^(一|二|三|四|五|六|七|八|九|十[二一])月/i
};
var parseMonthPatterns = {
  narrow: [/^一/i, /^二/i, /^三/i, /^四/i, /^五/i, /^六/i, /^七/i, /^八/i, /^九/i, /^十(?!(一|二))/i, /^十一/i, /^十二/i],
  any: [/^一|1/i, /^二|2/i, /^三|3/i, /^四|4/i, /^五|5/i, /^六|6/i, /^七|7/i, /^八|8/i, /^九|9/i, /^十(?!(一|二))|10/i, /^十一|11/i, /^十二|12/i]
};
var matchDayPatterns = {
  narrow: /^[一二三四五六日]/i,
  short: /^[一二三四五六日]/i,
  abbreviated: /^週[一二三四五六日]/i,
  wide: /^星期[一二三四五六日]/i
};
var parseDayPatterns = {
  any: [/日/i, /一/i, /二/i, /三/i, /四/i, /五/i, /六/i]
};
var matchDayPeriodPatterns = {
  any: /^(上午?|下午?|午夜|[中正]午|早上?|下午|晚上?|凌晨)/i
};
var parseDayPeriodPatterns = {
  any: {
    am: /^上午?/i,
    pm: /^下午?/i,
    midnight: /^午夜/i,
    noon: /^[中正]午/i,
    morning: /^早上/i,
    afternoon: /^下午/i,
    evening: /^晚上?/i,
    night: /^凌晨/i
  }
};
match.match = {
  ordinalNumber: (0, _index2$1.buildMatchPatternFn)({
    matchPattern: matchOrdinalNumberPattern,
    parsePattern: parseOrdinalNumberPattern,
    valueCallback: function (e) {
      return parseInt(e, 10);
    }
  }),
  era: (0, _index$1.buildMatchFn)({
    matchPatterns: matchEraPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseEraPatterns,
    defaultParseWidth: "any"
  }),
  quarter: (0, _index$1.buildMatchFn)({
    matchPatterns: matchQuarterPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseQuarterPatterns,
    defaultParseWidth: "any",
    valueCallback: function (e) {
      return e + 1;
    }
  }),
  month: (0, _index$1.buildMatchFn)({
    matchPatterns: matchMonthPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseMonthPatterns,
    defaultParseWidth: "any"
  }),
  day: (0, _index$1.buildMatchFn)({
    matchPatterns: matchDayPatterns,
    defaultMatchWidth: "wide",
    parsePatterns: parseDayPatterns,
    defaultParseWidth: "any"
  }),
  dayPeriod: (0, _index$1.buildMatchFn)({
    matchPatterns: matchDayPeriodPatterns,
    defaultMatchWidth: "any",
    parsePatterns: parseDayPeriodPatterns,
    defaultParseWidth: "any"
  })
};
zhTW.zhTW = void 0;
var _index = formatDistance$1;
var _index2 = formatLong;
var _index3 = formatRelative$1;
var _index4 = localize;
var _index5 = match;
zhTW.zhTW = {
  code: "zh-TW",
  formatDistance: _index.formatDistance,
  formatLong: _index2.formatLong,
  formatRelative: _index3.formatRelative,
  localize: _index4.localize,
  match: _index5.match,
  options: {
    weekStartsOn: 1,
    firstWeekContainsDate: 4
  }
};
(function (e) {
  var a = af;
  Object.keys(a).forEach(function (t) {
    if (t === "default" || t === "__esModule") return;
    if (t in e && e[t] === a[t]) return;
    Object.defineProperty(e, t, {
      enumerable: true,
      get: function () {
        return a[t];
      }
    });
  });
  var t = ar;
  Object.keys(t).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === t[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return t[a];
      }
    });
  });
  var r = arDZ;
  Object.keys(r).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === r[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return r[a];
      }
    });
  });
  var n = arEG;
  Object.keys(n).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === n[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return n[a];
      }
    });
  });
  var i = arMA;
  Object.keys(i).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === i[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return i[a];
      }
    });
  });
  var o = arSA;
  Object.keys(o).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === o[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return o[a];
      }
    });
  });
  var d = arTN;
  Object.keys(d).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === d[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return d[a];
      }
    });
  });
  var s = az;
  Object.keys(s).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === s[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return s[a];
      }
    });
  });
  var u = be;
  Object.keys(u).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === u[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return u[a];
      }
    });
  });
  var m = beTarask;
  Object.keys(m).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === m[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return m[a];
      }
    });
  });
  var l = bg;
  Object.keys(l).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === l[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return l[a];
      }
    });
  });
  var h = bn;
  Object.keys(h).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === h[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return h[a];
      }
    });
  });
  var c = bs;
  Object.keys(c).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === c[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return c[a];
      }
    });
  });
  var v = ca;
  Object.keys(v).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === v[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return v[a];
      }
    });
  });
  var $ = ckb;
  Object.keys($).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === $[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return $[a];
      }
    });
  });
  var f = cs;
  Object.keys(f).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === f[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return f[a];
      }
    });
  });
  var g = cy;
  Object.keys(g).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === g[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return g[a];
      }
    });
  });
  var p = da;
  Object.keys(p).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === p[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return p[a];
      }
    });
  });
  var b = de;
  Object.keys(b).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === b[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return b[a];
      }
    });
  });
  var P = deAT;
  Object.keys(P).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === P[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return P[a];
      }
    });
  });
  var y = el;
  Object.keys(y).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === y[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return y[a];
      }
    });
  });
  var w = enAU;
  Object.keys(w).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === w[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return w[a];
      }
    });
  });
  var x = enCA;
  Object.keys(x).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === x[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return x[a];
      }
    });
  });
  var M = enGB;
  Object.keys(M).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === M[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return M[a];
      }
    });
  });
  var _ = enIE;
  Object.keys(_).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === _[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return _[a];
      }
    });
  });
  var k = enIN;
  Object.keys(k).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === k[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return k[a];
      }
    });
  });
  var W = enNZ;
  Object.keys(W).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === W[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return W[a];
      }
    });
  });
  var D = enUS;
  Object.keys(D).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === D[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return D[a];
      }
    });
  });
  var j = enZA;
  Object.keys(j).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === j[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return j[a];
      }
    });
  });
  var z = eo;
  Object.keys(z).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === z[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return z[a];
      }
    });
  });
  var F = es;
  Object.keys(F).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === F[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return F[a];
      }
    });
  });
  var V = et;
  Object.keys(V).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === V[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return V[a];
      }
    });
  });
  var L = eu;
  Object.keys(L).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === L[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return L[a];
      }
    });
  });
  var N = faIR;
  Object.keys(N).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === N[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return N[a];
      }
    });
  });
  var T = fi;
  Object.keys(T).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === T[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return T[a];
      }
    });
  });
  var E = fr;
  Object.keys(E).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === E[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return E[a];
      }
    });
  });
  var R = frCA;
  Object.keys(R).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === R[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return R[a];
      }
    });
  });
  var S = frCH;
  Object.keys(S).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === S[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return S[a];
      }
    });
  });
  var O = fy;
  Object.keys(O).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === O[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return O[a];
      }
    });
  });
  var H = gd;
  Object.keys(H).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === H[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return H[a];
      }
    });
  });
  var C = gl;
  Object.keys(C).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === C[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return C[a];
      }
    });
  });
  var X = gu;
  Object.keys(X).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === X[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return X[a];
      }
    });
  });
  var A = he;
  Object.keys(A).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === A[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return A[a];
      }
    });
  });
  var q = hi;
  Object.keys(q).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === q[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return q[a];
      }
    });
  });
  var Q = hr;
  Object.keys(Q).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Q[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Q[a];
      }
    });
  });
  var I = ht;
  Object.keys(I).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === I[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return I[a];
      }
    });
  });
  var G = hu;
  Object.keys(G).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === G[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return G[a];
      }
    });
  });
  var Y = hy;
  Object.keys(Y).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Y[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Y[a];
      }
    });
  });
  var K = id;
  Object.keys(K).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === K[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return K[a];
      }
    });
  });
  var J = is;
  Object.keys(J).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === J[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return J[a];
      }
    });
  });
  var B = it;
  Object.keys(B).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === B[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return B[a];
      }
    });
  });
  var Z = itCH;
  Object.keys(Z).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Z[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Z[a];
      }
    });
  });
  var U = ja;
  Object.keys(U).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === U[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return U[a];
      }
    });
  });
  var ee = jaHira;
  Object.keys(ee).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === ee[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return ee[a];
      }
    });
  });
  var ae = ka;
  Object.keys(ae).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === ae[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return ae[a];
      }
    });
  });
  var re = kk;
  Object.keys(re).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === re[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return re[a];
      }
    });
  });
  var ne = km;
  Object.keys(ne).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === ne[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return ne[a];
      }
    });
  });
  var ie = kn;
  Object.keys(ie).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === ie[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return ie[a];
      }
    });
  });
  var oe = ko;
  Object.keys(oe).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === oe[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return oe[a];
      }
    });
  });
  var ue = lb;
  Object.keys(ue).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === ue[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return ue[a];
      }
    });
  });
  var me = lt;
  Object.keys(me).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === me[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return me[a];
      }
    });
  });
  var le = lv;
  Object.keys(le).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === le[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return le[a];
      }
    });
  });
  var ce = mk;
  Object.keys(ce).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === ce[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return ce[a];
      }
    });
  });
  var ve = mn;
  Object.keys(ve).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === ve[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return ve[a];
      }
    });
  });
  var $e = ms;
  Object.keys($e).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === $e[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return $e[a];
      }
    });
  });
  var fe = mt;
  Object.keys(fe).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === fe[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return fe[a];
      }
    });
  });
  var ge = nb;
  Object.keys(ge).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === ge[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return ge[a];
      }
    });
  });
  var pe = nl;
  Object.keys(pe).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === pe[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return pe[a];
      }
    });
  });
  var Pe = nlBE;
  Object.keys(Pe).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Pe[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Pe[a];
      }
    });
  });
  var ye = nn;
  Object.keys(ye).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === ye[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return ye[a];
      }
    });
  });
  var we = oc;
  Object.keys(we).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === we[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return we[a];
      }
    });
  });
  var xe = pl;
  Object.keys(xe).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === xe[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return xe[a];
      }
    });
  });
  var Me = pt;
  Object.keys(Me).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Me[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Me[a];
      }
    });
  });
  var _e = ptBR;
  Object.keys(_e).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === _e[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return _e[a];
      }
    });
  });
  var ke = ro;
  Object.keys(ke).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === ke[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return ke[a];
      }
    });
  });
  var We = ru;
  Object.keys(We).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === We[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return We[a];
      }
    });
  });
  var De = se;
  Object.keys(De).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === De[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return De[a];
      }
    });
  });
  var je = sk;
  Object.keys(je).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === je[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return je[a];
      }
    });
  });
  var ze = sl;
  Object.keys(ze).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === ze[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return ze[a];
      }
    });
  });
  var Fe = sq;
  Object.keys(Fe).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Fe[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Fe[a];
      }
    });
  });
  var Ve = sr;
  Object.keys(Ve).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Ve[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Ve[a];
      }
    });
  });
  var Le = srLatn;
  Object.keys(Le).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Le[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Le[a];
      }
    });
  });
  var Ne = sv;
  Object.keys(Ne).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Ne[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Ne[a];
      }
    });
  });
  var Te = ta;
  Object.keys(Te).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Te[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Te[a];
      }
    });
  });
  var Ee = te;
  Object.keys(Ee).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Ee[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Ee[a];
      }
    });
  });
  var Re = th;
  Object.keys(Re).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Re[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Re[a];
      }
    });
  });
  var Se = tr;
  Object.keys(Se).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Se[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Se[a];
      }
    });
  });
  var Oe = ug;
  Object.keys(Oe).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Oe[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Oe[a];
      }
    });
  });
  var He = uk;
  Object.keys(He).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === He[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return He[a];
      }
    });
  });
  var Ce = uz;
  Object.keys(Ce).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Ce[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Ce[a];
      }
    });
  });
  var Xe = uzCyrl;
  Object.keys(Xe).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Xe[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Xe[a];
      }
    });
  });
  var Ae = vi;
  Object.keys(Ae).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Ae[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Ae[a];
      }
    });
  });
  var qe = zhCN;
  Object.keys(qe).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === qe[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return qe[a];
      }
    });
  });
  var Qe = zhHK;
  Object.keys(Qe).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Qe[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Qe[a];
      }
    });
  });
  var Ie = zhTW;
  Object.keys(Ie).forEach(function (a) {
    if (a === "default" || a === "__esModule") return;
    if (a in e && e[a] === Ie[a]) return;
    Object.defineProperty(e, a, {
      enumerable: true,
      get: function () {
        return Ie[a];
      }
    });
  });
})(locale);
var THREE = 3;
var DATE_SEPARATOR = " - ";
var getStringifiedFormat = function (e) {
  var a = "";
  e.forEach(function (e) {
    var t = e.type,
      r = e.value;
    if (t === "year") {
      a += "y".repeat(r.length);
    } else if (t === "month") {
      if (Number.isNaN(+r)) {
        a += r.length <= THREE ? "MMM" : "MMMM";
      } else {
        a += "M".repeat(r.length);
      }
    } else if (t === "day") {
      a += "d".repeat(r.length);
    } else if (t === "literal") {
      a += r;
    } else ;
  });
  return a;
};
var getBrowserDateFormat = function (e, a) {
  if (a === void 0) {
    a = "dd/MM/yyyy";
  }
  try {
    var t = new Intl.DateTimeFormat(e || []);
    return getStringifiedFormat(t.formatToParts(new Date(new Date().getFullYear(), 1, 1)));
  } catch (e) {
    return a;
  }
};
var getDateFnsLocale = function (e) {
  if (e === void 0) {
    e = locale.enUS;
  }
  return e.code;
};
var FORMAT_MAP = {
  hour12: "hh",
  hour24: "HH",
  minute: "mm",
  dayPeriod: "aaa"
};
var handleLocation = function (e, a) {
  if (a === void 0) {
    a = locale.enUS;
  }
  if (e) {
    return null;
  }
  return a;
};
var defineFormat = function (e, a) {
  if (e) {
    return "".concat(FORMAT_MAP.hour24, ":").concat(FORMAT_MAP.minute);
  }
  var t = new Intl.DateTimeFormat([], {
    hour: "numeric",
    minute: "numeric"
  });
  var r = t.formatToParts(new Date(a));
  var n = Object.keys(r).some(function (e) {
    return r[e].type === "dayPeriod";
  });
  var i = "";
  for (var o = 0, d = Object.keys(r); o < d.length; o++) {
    var s = d[o];
    if (r[s].type === "hour") {
      if (n) {
        i += FORMAT_MAP.hour12;
      } else {
        i += FORMAT_MAP.hour24;
      }
    } else if (r[s].type === "literal") {
      i += r[s].value;
    } else {
      i += FORMAT_MAP[r[s].type];
    }
  }
  return i;
};
var isTimeEqual = function (e, a) {
  if (!dateFns.isValid(e) && !dateFns.isValid(a)) {
    return true;
  } else if (dateFns.isValid(e) !== dateFns.isValid(a)) {
    return false;
  } else {
    var t = dateFns.getHours(e) === dateFns.getHours(a);
    var r = dateFns.getMinutes(e) === dateFns.getMinutes(a);
    return t && r;
  }
};
var moveTimeToToday = function (e, a) {
  return dateFns.subMinutes(dateFns.set(a, {
    hours: e.getUTCHours(),
    minutes: e.getUTCMinutes(),
    seconds: 0,
    milliseconds: 0
  }), e.getTimezoneOffset()).getTime();
};
var moveTimeToTodayUTC = function (e, a) {
  return dateFns.set(a, {
    hours: e.getUTCHours(),
    minutes: e.getUTCMinutes(),
    seconds: 0,
    milliseconds: 0
  }).getTime();
};
var getTimeInterval = function (e, a, t) {
  return dateFns.eachMinuteOfInterval({
    start: a,
    end: t
  }, {
    step: e
  }).map(function (e) {
    return e.getTime();
  });
};
var startOfDayUTC = function (e) {
  var a = new Date(e);
  return dateFns.subMinutes(dateFns.startOfDay(a), a.getTimezoneOffset()).getTime();
};
var endOfDayUTC = function (e) {
  var a = new Date(e);
  return dateFns.subMinutes(dateFns.endOfDay(a), a.getTimezoneOffset()).getTime();
};
var ERROR_MESSAGES = {
  NOT_SELECTABLE: "value-not-selectable",
  PATTERN_MISMATCH: "pattern-mismatch",
  TYPE_MISMATCH: "type-mismatch",
  MAX_VAL: "range-overflow",
  MIN_VAL: "range-underflow",
  MIN_LENGTH: "too-short",
  MAX_LENGTH: "too-long",
  REQUIRED: "value-missing"
};
var regexEmail = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
var regexUrl = /^((https?|ftp):)?\/\/(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;
var Validator = function () {
  function e() {
    this._locale = locale.enUS;
  }
  e.prototype.type = function (e) {
    this._type = e;
    return this;
  };
  e.prototype.required = function () {
    this._required = true;
    return this;
  };
  e.prototype.optional = function () {
    this._required = false;
    return this;
  };
  e.prototype.minLength = function (e) {
    this._minLength = isPositiveNumber(e) ? +e : undefined;
    return this;
  };
  e.prototype.maxLength = function (e) {
    this._maxLength = isPositiveNumber(e) ? +e : undefined;
    return this;
  };
  e.prototype.min = function (e) {
    this._minValue = isNumber(e) ? +e : undefined;
    return this;
  };
  e.prototype.max = function (e) {
    this._maxValue = isNumber(e) ? +e : undefined;
    return this;
  };
  e.prototype.pattern = function (e) {
    this._pattern = e;
    return this;
  };
  e.prototype.locale = function (e) {
    this._locale = e;
    return this;
  };
  e.prototype.notOneOf = function (e) {
    this._notSelectableValues = Array.isArray(e) ? e : [];
    return this;
  };
  e.prototype.oneOf = function (e) {
    this._selectableValues = Array.isArray(e) ? e : [];
    return this;
  };
  e.prototype.validate = function (e, a) {
    if (this.hasRequiredError(e)) {
      return [ERROR_MESSAGES.REQUIRED];
    }
    if (!this.checkType(e)) {
      if (this._type === "number" && a && !a.badInput && e.length === 0) {
        return [];
      } else {
        return [ERROR_MESSAGES.TYPE_MISMATCH];
      }
    }
    var t = [];
    var r = this.getTypedValue(e);
    switch (this._type) {
      case "time":
        t.push.apply(t, this.getPatternError(e));
        if (t.length !== 0 || e === "") {
          return t;
        }
        t.push.apply(t, this.getMinValError(r));
        t.push.apply(t, this.getMaxValError(r));
        if (t.length > 0) {
          return t;
        }
        t.push.apply(t, this.getTimeNotSelectableError(r));
        break;
      case "date":
        t.push.apply(t, this.getPatternError(e));
        if (t.length !== 0) {
          return t;
        }
        t.push.apply(t, this.getMinValError(r));
        t.push.apply(t, this.getMaxValError(r));
        t.push.apply(t, this.getDateNotSelectableError(r));
        break;
      case "select":
        t.push.apply(t, this.getMinLengthError(r));
        t.push.apply(t, this.getMaxLengthError(r));
        t.push.apply(t, this.getNotSelectableError(e));
        break;
      default:
        t.push.apply(t, this.getPatternError(r));
        t.push.apply(t, this.getMinLengthError(r));
        t.push.apply(t, this.getMaxLengthError(r));
        t.push.apply(t, this.getMinValError(r));
        t.push.apply(t, this.getMaxValError(r));
        t.push.apply(t, this.getNotSelectableError(e));
    }
    return t;
  };
  e.prototype.getTypedValue = function (e) {
    var a = this;
    if (["text", "textarea", "email", "password", "url", "tel"].includes(this._type)) {
      return "".concat(e);
    } else if (Array.isArray(e)) {
      return e;
    } else if (this._type === "date") {
      var t = typeof e === "string" ? e.split(DATE_SEPARATOR) : e;
      return Array.isArray(t) ? t.map(function (e) {
        return dateFns.parse(e, a._pattern, new Date(a._minValue), {
          locale: a._locale
        });
      }) : dateFns.parse(e, this._pattern, new Date(this._minValue), {
        locale: this._locale
      });
    } else if (this._type === "time") {
      return dateFns.subMinutes(dateFns.parse(e, this._pattern, dateFns.startOfDay(new Date()), {
        locale: this._locale
      }), new Date().getTimezoneOffset());
    } else if (this._type === "number") {
      return isNumber(e) ? +e : Number.NaN;
    } else {
      return e;
    }
  };
  e.prototype.checkType = function (e) {
    switch (this._type) {
      case "number":
        {
          return isNumber(e);
        }
      case "email":
        {
          return regexEmail.test("".concat(e));
        }
      case "url":
        {
          return regexUrl.test("".concat(e));
        }
      case "text":
      case "textarea":
      case "password":
      case "tel":
        {
          return isDefined(e) ? typeof e === "string" : true;
        }
      case "select":
        {
          return Array.isArray(e);
        }
      default:
        {
          return true;
        }
    }
  };
  e.prototype.hasRequiredError = function (e) {
    return this._required ? !(e === null || e === void 0 ? void 0 : e.length) : false;
  };
  e.prototype.getMinLengthError = function (e) {
    if (["email", "password", "url", "tel", "textarea", "text"].includes(this._type)) {
      return this.eligibleToMinMaxLengthCheck("min") ? e.length < this._minLength ? ["too-short"] : [] : [];
    } else if (this._type === "select") {
      return this.eligibleToMinMaxLengthCheck("min") && Array.isArray(e) ? e.length < this._minLength ? ["too-short"] : [] : [];
    } else {
      return [];
    }
  };
  e.prototype.getMaxLengthError = function (e) {
    if (["email", "password", "url", "tel", "textarea", "text"].includes(this._type)) {
      return this.eligibleToMinMaxLengthCheck("max") ? e.length > this._maxLength ? ["too-long"] : [] : [];
    } else if (this._type === "select") {
      return this.eligibleToMinMaxLengthCheck("max") && Array.isArray(e) ? e.length > this._maxLength ? ["too-long"] : [] : [];
    } else {
      return [];
    }
  };
  e.prototype.getMinValError = function (e) {
    var a = this;
    switch (this._type) {
      case "date":
        {
          var t = this.eligibleToMinMaxValueCheck("max") ? Array.isArray(e) ? e.some(function (e) {
            return e < a._minValue;
          }) : e < this._minValue : false;
          return t ? [ERROR_MESSAGES.MIN_VAL] : [];
        }
      case "time":
      case "number":
        {
          return this.eligibleToMinMaxValueCheck("min") ? e < this._minValue ? [ERROR_MESSAGES.MIN_VAL] : [] : [];
        }
      default:
        {
          return [];
        }
    }
  };
  e.prototype.getMaxValError = function (e) {
    var a = this;
    switch (this._type) {
      case "date":
        {
          var t = this.eligibleToMinMaxValueCheck("max") ? Array.isArray(e) ? e.some(function (e) {
            return e > a._maxValue;
          }) : e > this._maxValue : false;
          return t ? [ERROR_MESSAGES.MAX_VAL] : [];
        }
      case "time":
      case "number":
        {
          return this.eligibleToMinMaxValueCheck("max") ? e > this._maxValue ? [ERROR_MESSAGES.MAX_VAL] : [] : [];
        }
      default:
        {
          return [];
        }
    }
  };
  e.prototype.hasTimePatternError = function (e) {
    return e && !dateFns.isMatch(e === null || e === void 0 ? void 0 : e.toString(), this._pattern, {
      locale: this._locale
    });
  };
  e.prototype.hasDatePatternError = function (e) {
    var a = this;
    var t = typeof e === "string" && e.split(DATE_SEPARATOR);
    if (Array.isArray(t)) {
      return t.some(function (e) {
        return e && !dateFns.isMatch(e === null || e === void 0 ? void 0 : e.toString(), a._pattern, {
          locale: a._locale
        });
      });
    } else if (Array.isArray(e)) {
      return e.some(function (e) {
        return e && !dateFns.isMatch(e === null || e === void 0 ? void 0 : e.toString(), a._pattern, {
          locale: a._locale
        });
      });
    } else {
      return e && !dateFns.isMatch(e === null || e === void 0 ? void 0 : e.toString(), this._pattern, {
        locale: this._locale
      });
    }
  };
  e.prototype.getPatternError = function (e) {
    var a;
    if (["text", "email", "password", "url", "tel", "number"].includes(this._type)) {
      var t = typeof this._pattern === "string" ? new RegExp(this._pattern) : this._pattern;
      a = t ? !t.test("".concat(e)) : false;
    } else if (this._type === "time") {
      a = this.hasTimePatternError(e);
    } else if (this._type === "date") {
      a = this.hasDatePatternError(e);
    } else {
      a = false;
    }
    return a ? [ERROR_MESSAGES.PATTERN_MISMATCH] : [];
  };
  e.prototype.getNotSelectableError = function (e) {
    return this.isOneOfNotSelectableValues(e) || this.isNotOneOfSelectableValues(e) ? [ERROR_MESSAGES.NOT_SELECTABLE] : [];
  };
  e.prototype.isOneOfNotSelectableValues = function (e) {
    var a = this;
    var t;
    return ((t = this._notSelectableValues) === null || t === void 0 ? void 0 : t.length) > 0 && e ? this._notSelectableValues.some(function (t) {
      return a.isSame(t, e);
    }) : false;
  };
  e.prototype.isNotOneOfSelectableValues = function (e) {
    var a = this;
    var t;
    return ((t = this._selectableValues) === null || t === void 0 ? void 0 : t.length) > 0 && e ? !this._selectableValues.some(function (t) {
      return a.isSame(t, e);
    }) : false;
  };
  e.prototype.eligibleToMinMaxLengthCheck = function (e) {
    var a = isPositiveNumber(this._minLength);
    var t = isPositiveNumber(this._maxLength);
    if (a && t) {
      return this._maxLength > this._minLength;
    } else {
      return e === "min" ? a : t;
    }
  };
  e.prototype.eligibleToMinMaxValueCheck = function (e) {
    var a = isNumber(this._minValue);
    var t = isNumber(this._maxValue);
    if (a && t) {
      return this._maxValue > this._minValue;
    } else {
      return e === "min" ? a : t;
    }
  };
  e.prototype.isSame = function (e, a) {
    var t = this;
    if (Array.isArray(a)) {
      return a.some(function (a) {
        return t.isSame(e, a);
      });
    } else {
      var r = e instanceof Date ? e.getTime() : e;
      var n = a instanceof Date ? a.getTime() : a;
      return r === n;
    }
  };
  e.prototype.getDateNotSelectableError = function (e) {
    var a = this;
    var t;
    var r = [];
    var n = ((t = this._notSelectableValues) === null || t === void 0 ? void 0 : t.length) > 0 ? this._notSelectableValues.map(function (e) {
      return typeof e === "string" ? dateFns.parse(e, a._pattern, new Date(a._minValue), {
        locale: a._locale
      }) : e;
    }) : [];
    Array.isArray(e) ? e.forEach(function (t, i) {
      return n.some(function (r) {
        var n = dateFns.isAfter(r, t) && i < e.length - 1 && dateFns.isBefore(r, e[i + 1]);
        return a.isSame(r, t) || n;
      }) && r.push(ERROR_MESSAGES.NOT_SELECTABLE);
    }) : this.isOneOfNotSelectableValues(e) && r.push(ERROR_MESSAGES.NOT_SELECTABLE);
    return r;
  };
  e.prototype.getTimeNotSelectableError = function (e) {
    var a = this._notSelectableValues.some(function (a) {
      return isTimeEqual(a, new Date(e));
    });
    var t = this._selectableValues.length > 0 && !this._selectableValues.some(function (a) {
      return isTimeEqual(a, new Date(e));
    });
    return a || t ? [ERROR_MESSAGES.NOT_SELECTABLE] : [];
  };
  return e;
}();
var ELEMENT_READY_DURATION = 10;
var ELEMENT_READY_COUNTER = 50;
var SUBMIT_BUTTON_SELECTOR = 'button[type="submit"]';
function OnClosestFormSubmit() {
  return function (e, a) {
    var t = e.disconnectedCallback,
      r = e.connectedCallback;
    var n = "".concat(a, "-listener");
    function i(e) {
      var t = e[a];
      if (!e[n]) {
        e[n] = function (a) {
          t.call(e, a);
        };
      }
    }
    e.connectedCallback = function () {
      var e = this;
      i(this);
      var a = getElement(this);
      var t = a === null || a === void 0 ? void 0 : a.closest("form");
      t && isElementReady(function () {
        return t === null || t === void 0 ? void 0 : t.querySelectorAll(SUBMIT_BUTTON_SELECTOR).length;
      }, ELEMENT_READY_COUNTER, ELEMENT_READY_DURATION).then(function () {
        var a = t.querySelectorAll(SUBMIT_BUTTON_SELECTOR);
        a.forEach(function (a) {
          return a.removeEventListener("click", e[n]);
        });
        a.forEach(function (a) {
          return a.addEventListener("click", e[n]);
        });
      });
      return r === null || r === void 0 ? void 0 : r.call(this);
    };
    e.disconnectedCallback = function () {
      var e = this;
      var a;
      var r = getElement(this);
      var i = r === null || r === void 0 ? void 0 : r.closest("form");
      (a = i === null || i === void 0 ? void 0 : i.querySelectorAll(SUBMIT_BUTTON_SELECTOR)) === null || a === void 0 ? void 0 : a.forEach(function (a) {
        return a.removeEventListener("click", e[n]);
      });
      return t === null || t === void 0 ? void 0 : t.call(this);
    };
  };
}
export { DATE_SEPARATOR as D, FORMAT_MAP as F, OnClosestFormSubmit as O, Validator as V, getDateFnsLocale as a, moveTimeToTodayUTC as b, getTimeInterval as c, defineFormat as d, endOfDayUTC as e, getBrowserDateFormat as g, handleLocation as h, isTimeEqual as i, moveTimeToToday as m, startOfDayUTC as s };